import { FC, useEffect, useMemo, useState } from "react"
import { CalendarEvent, LocationWithDistance, PageProps } from "../types";
import { Box, Grid, List, ListItem, ListItemText, MenuItem, Switch, TextField } from "@mui/material";
import { useTicketApi } from "../../../providers/TicketApiProvider";
import { useApi } from "../../../providers/Api";
import { DateTime } from "luxon";
import Calendar from "../partials/Calendar";
import { AzureCalendar } from "../../../types/azure";
import { CoreUser } from "../../../types/core";
import { TechMenu } from "../partials/TechMenu";
import { DistanceMatrixResponse, GoogleMapsDuration, GoolgeMapsDistance } from "../../../types/google";
import { AutotaskCompanyLocations } from "../../../types/companylocations";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { getBaseTimes, roundDriveTime } from "../utils";
import { useSchedulingWizard } from "../SchedulingWizardProvider";
import { eventsToMakeNew, eventsToMakeRelative } from "./Page2.utils";


const EventContent : FC<{event : any , locations : LocationWithDistance[]}> = ({event , locations}) => {
    const timeOptions = { hour: 'numeric', minute: '2-digit', hour12: true };
    const location = locations.find( (l) => Number(l.id) === Number(event.extendedProps.stop_autotaskLocationId));

    return <div className="fc-event-main-frame">
        <div className="fc-event-time">{event.start.toLocaleTimeString([], timeOptions)} - {event.end.toLocaleTimeString([], timeOptions)}</div>
        <div className="fc-event-title-container">
            <div className="fc-event-title fc-sticky">
                {event.title}
                
            </div>
            {Boolean(location) && <div style={{fontSize: '10px' , position: 'absolute' , right: 0 , bottom: 0}}>{location?.duration.text} from new event</div>}
        </div>
    </div>
}



 const Page2 : FC<PageProps> = ({ state , setItem , ticket }) => {
    
    const { locationsWithDistance , events , users , changedEvents , eventsToRemove } = useSchedulingWizard();
    const [ selectedTechs , setSelectedTechs] = useState<string[]>(["cole@coulee.tech" , "alawrence@coulee.tech"]);

    
    

    const fullCalendarEvents = useMemo( () => {
        const eventsWithParticipants = events.map( (event) => {
            const extra : Record<string,any> = {};
            if( event.subject?.toLocaleLowerCase().trim() == "van available" ){
                extra.display = 'background';
            }
            if( event.drive_id ){
                extra.display = 'background';
                extra.backgroundColor = 'black';
            }
            if( event.stop_autotaskLocationId ){
                //extra.backgroundColor = '#00ccff';
                extra.color = 'green';
                // extra.borderColor = '#333';
                // extra.textColor = '#333';
            }
            if( event.isAllDay){
                extra.allDay = true;
            }
            const res = { ...event , resourceId : event.mailbox , title : event.subject  , ...extra , extendedProps : event }
            return res;
        })
        return [ ...eventsWithParticipants.flat() ];
    } , [JSON.stringify( events )]);

    const fullCalendarResources = useMemo( () => {
            const u =  users.map( (resource) => { 
                const r : Record<string,any> = {
                ...resource,
                id: resource.email.toLowerCase(),
                title: `${resource.firstName}`,
                email : resource.email,
                
            } 
            if( resource.workDayStart && resource.workDayEnd ){
                r.businessHours = {
                    startTime: resource.workDayStart,
                    endTime: resource.workDayEnd
                }
            }
            return r;
        });
        return [ ...u  ]
    } , [JSON.stringify( users )]);


    const dateClick = ( event : any ) => {
        const tech = event?.resource?.id;
        
        const date = event?.date;
        setItem( 'scheduledTechId' , users.find( (u) => u.email === tech)?.id );
        setItem( 'scheduledDate' , date.toISOString() );
        setItem( 'addToStopId' , null);
        setItem( 'realitiveToStopId' , null);
    }
    
    const scheduledDateDayJs = useMemo( () => {        
        return dayjs( state.scheduledDate || '');
    } , [state.scheduledDate]);

    useEffect( () => {
        const selectedTech = users.find( (u) => u.id === state.scheduledTechId );
        const location = locationsWithDistance.find( (l) => l.id === selectedTech?.autotaskLocationId );
        if( location ){
            setItem('homeLocation' , location);
        } else {
            setItem('homeLocation' , null);
        }
    } , [state.scheduledTechId  , JSON.stringify(users) , JSON.stringify(locationsWithDistance) ]);

    const filteredFullCalendarEvents = useMemo( () => {
        const ids = [...changedEvents , ...eventsToRemove ].map( (e) => e.id);
        return fullCalendarEvents.filter( (event) => !ids.includes(event.id) );
    } , [JSON.stringify( changedEvents), JSON.stringify( fullCalendarEvents), JSON.stringify( eventsToRemove)]);

    const eventsToMake = useMemo( () => {
        const eventsToMake : Record<string,any>[] = [];
        const selectedTech = users.find( (u) => u.id === state.scheduledTechId );
        const techHomeLocation = locationsWithDistance.find( (l) => l.id === selectedTech?.autotaskLocationId );

        const relativeLocation = locationsWithDistance.find( (l) => l.id === state.realitiveToStopId);

        const relativeStop = fullCalendarEvents.find( (e) => Number(e.stop_id) === Number( state.realitiveToStopId ));

        if( selectedTech && techHomeLocation && !Boolean(state.addToStopId) ){
            eventsToMake.push( ...eventsToMakeNew( techHomeLocation , state , selectedTech ));
        }
        if( selectedTech && techHomeLocation && relativeLocation ){
            switch( state.whenToAdd){
                case 'start' :                     
                    eventsToMake.push( ...eventsToMakeRelative( techHomeLocation , relativeLocation , state , selectedTech , relativeStop?.start || '' , 'driveend' ));
                    break;
                case 'end' : 
                    eventsToMake.push( ...eventsToMakeRelative( relativeLocation , techHomeLocation , state , selectedTech , relativeStop?.end || '' , 'drivestart' ));
                    break;
            }
        }
        if( Boolean(changedEvents.length) ){
            const changedEventIds = changedEvents.map( (e) => e.id);
            const updatedEvents = fullCalendarEvents.filter( (e) => changedEventIds.includes(e.id)).map( (e) => {
                const changedEvent = changedEvents.find( (ce) => ce.id === e.id);
                const extra : Record<string,any> = {};
                if( e.stop_id){
                    extra.color = '#00ff00';
                    extra.textColor = '#333';
                }
                return { ...e , ...changedEvent , ...extra};
            });
            return updatedEvents;
        }

        return eventsToMake;
    } , [state.scheduledTechId , state.scheduledDate , users , locationsWithDistance , state.estimatedTimeRequired , state.addToStopId , JSON.stringify( fullCalendarEvents), JSON.stringify( changedEvents) , state.whenToAdd , state.realitiveToStopId ]);


    const eventClickedSameLocation = ( info: any ) => {
        const event = info.event.extendedProps;
        
        if( event.mailbox){
            setItem( 'scheduledTechId' , users.find( (u) => u.email === event.mailbox)?.id );
            // setItem( 'scheduledDate' , info.date.toISOString() );
        }
        if( event.stop_id ){
            setItem('addToStopId' , event.stop_id);
            setItem('whenToAdd' , 'end');
            return;
        }
    }

    const eventClickedDifferentLocation = ( info: any ) => {
        const event = info.event.extendedProps;
        setItem( 'scheduledTechId' , users.find( (u) => u.email === event.mailbox)?.id );
        setItem( 'realitiveToStopId' , Number(event.stop_autotaskLocationId));
        setItem('addToStopId' , null);
        setItem('whenToAdd' , 'end');
    }


    const eventClick = ( info : any) => { 

        if( Number( info.event.extendedProps.stop_autotaskLocationId) === Number( state.locationId)){
            console.log( 'same location' , Number( info.event.extendedProps.stop_autotaskLocationId));
            eventClickedSameLocation(info);
            return;
        }
        if( Boolean( info.event.extendedProps.stop_autotaskLocationId)){
            eventClickedDifferentLocation(info);
            return;
        }

        

    }
    useEffect( () => {} , [state.addToStopId])


    useEffect( () => {
        if( eventsToMake.length > 0 && !state.readyForSubmit ){
            setItem('readyForSubmit' , true);
        }
        if( eventsToMake.length === 0 && state.readyForSubmit ){
            setItem('readyForSubmit' , false);
        }
    } , [eventsToMake.length , state.readyForSubmit]);
    useEffect( () => {
        setItem(  'eventsToMake' , eventsToMake);
    } , [JSON.stringify( eventsToMake)]);
    return  <>
    <Grid container sx={{padding: 2}} spacing={2}>
        <Grid item xs={6}>
            <Grid container  spacing={2}>
                <Grid item xs={12}>
                    <TechMenu users={users} selectedTechs={selectedTechs} setSelectedTechs={setSelectedTechs} />
                </Grid>
                <Grid item xs={12}>
                    <Calendar
                    eventContent={({event}: any)=>{
                        return <EventContent event={event} locations={locationsWithDistance} />
                    }}
                    eventClick={eventClick} dateClick={dateClick} resources={fullCalendarResources.filter( (resource) => selectedTechs.includes(resource.id))} 
                    events={[...filteredFullCalendarEvents , ...eventsToMake ]} />
                    
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={6}>
            <Grid container  spacing={2}>
                <Grid item xs={12}>
                    <List>
                        {locationsWithDistance.filter(l => l.id === 0).map( l => {
                            return <ListItem key={l.id }>
                                <ListItemText primary={`${l.city}`} secondary={`Drive time one way : ${l.duration.text}`} />
                            </ListItem>
                        })}
                    </List>
                </Grid>
                <Grid item xs={12}>
                    <TextField InputLabelProps={{shrink: true}} fullWidth label="Tech" value={state.scheduledTechId || 'SELECT ONE'} onChange={(e) => setItem('scheduledTechId' , e.target.value)} select>
                        <MenuItem value="SELECT ONE">SELECT ONE</MenuItem>
                        {users.map( (u) => {
                            return <MenuItem key={u.id} value={u.id}>{u.firstName}</MenuItem>
                        })}
                    </TextField>
                </Grid>
                {!Boolean( state.addToStopId) && !Boolean(state.realitiveToStopId) && <Grid item xs={12}>
                    <DateTimePicker sx={{width: '100%'}} label="Leave Time" value={scheduledDateDayJs} onChange={(time) => setItem("scheduledDate" , time?.toISOString() || '')} />
                </Grid>}
                { (Boolean( state.addToStopId) || Boolean(state.realitiveToStopId)) &&<Grid item xs={12}>
                 <TextField InputLabelProps={{shrink: true}} fullWidth label="When to add Time" value={state.whenToAdd || 'SELECT ONE'} onChange={(e) => {
                const val = e.target.value === 'SELECT ONE' ? null : e.target.value;
                setItem('whenToAdd' , val); 
            }} select>
                        <MenuItem value="SELECT ONE">SELECT ONE</MenuItem>
                        <MenuItem value="start">Start</MenuItem>
                        <MenuItem value="end">End</MenuItem>
                    </TextField>
                
            </Grid>}
            </Grid>
            <Grid item xs={12}>
                    <TextField
                        sx={{ marginTop: 2 }}
                        InputLabelProps={{shrink: true}}
                        fullWidth label="Estimated Time Required ( in hours, without drive time )" 
                        type="number"
                        value={state.estimatedTimeRequired || 0} 
                        onChange={(e) => setItem("estimatedTimeRequired" , e.target.value)}
                    />
                </Grid>
           
        </Grid>
        
    </Grid></>
 }

 export default Page2;

