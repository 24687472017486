import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUsersScheduleQueryVariables = Types.Exact<{
  coreUserId: Types.Scalars['String']['input'];
  scheduleInstanceDay: Types.Scalars['String']['input'];
}>;


export type GetUsersScheduleQuery = { __typename?: 'Query', findScheduleInstancePaginated: { __typename?: 'PaginatedScheduleInstance', data?: Array<{ __typename?: 'ScheduleInstance', id?: number | null, scheduleInstanceDay?: string | null, available?: boolean | null, title?: string | null, description?: string | null, startDateTime?: any | null, endDateTime?: any | null, coreUserId?: string | null, conflictDetected?: boolean | null, schedulePlanId?: number | null, SchedulePlan?: { __typename?: 'SchedulePlan', name?: string | null } | null, AzureCalendar?: { __typename?: 'AzureCalendar', subject?: string | null, bodyPreview: string } | null, AutotaskTickets?: { __typename?: 'AutotaskTickets', title: string, ticketNumber: string, id?: number | null } | null }> | null } };


export const GetUsersScheduleDocument = gql`
    query GetUsersSchedule($coreUserId: String!, $scheduleInstanceDay: String!) {
  findScheduleInstancePaginated(
    coreUserId: {eq: $coreUserId}
    scheduleInstanceDay: {eq: $scheduleInstanceDay}
  ) {
    data {
      id
      scheduleInstanceDay
      available
      title
      description
      startDateTime
      endDateTime
      coreUserId
      conflictDetected
      SchedulePlan {
        name
      }
      AzureCalendar {
        subject
        bodyPreview
      }
      AutotaskTickets {
        title
        ticketNumber
        id
      }
      schedulePlanId
    }
  }
}
    `;

/**
 * __useGetUsersScheduleQuery__
 *
 * To run a query within a React component, call `useGetUsersScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersScheduleQuery({
 *   variables: {
 *      coreUserId: // value for 'coreUserId'
 *      scheduleInstanceDay: // value for 'scheduleInstanceDay'
 *   },
 * });
 */
export function useGetUsersScheduleQuery(baseOptions: Apollo.QueryHookOptions<GetUsersScheduleQuery, GetUsersScheduleQueryVariables> & ({ variables: GetUsersScheduleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersScheduleQuery, GetUsersScheduleQueryVariables>(GetUsersScheduleDocument, options);
      }
export function useGetUsersScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersScheduleQuery, GetUsersScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersScheduleQuery, GetUsersScheduleQueryVariables>(GetUsersScheduleDocument, options);
        }
export function useGetUsersScheduleSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUsersScheduleQuery, GetUsersScheduleQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersScheduleQuery, GetUsersScheduleQueryVariables>(GetUsersScheduleDocument, options);
        }
export type GetUsersScheduleQueryHookResult = ReturnType<typeof useGetUsersScheduleQuery>;
export type GetUsersScheduleLazyQueryHookResult = ReturnType<typeof useGetUsersScheduleLazyQuery>;
export type GetUsersScheduleSuspenseQueryHookResult = ReturnType<typeof useGetUsersScheduleSuspenseQuery>;
export type GetUsersScheduleQueryResult = Apollo.QueryResult<GetUsersScheduleQuery, GetUsersScheduleQueryVariables>;