import {useTheme, Autocomplete, Button, ButtonGroup, Card, Grid, Step, StepButton, Stepper, TextField, Tooltip, Typography, styled } from "@mui/material"
import { useState , FC , useEffect } from "react"
import { InventoryAdjustment, InventoryItem } from "./types";
import { Label } from "@mui/icons-material";
import { useApi } from "../../react-submodules/providers/Api/Api";


type IAFProps = {
    inventoryId?: number;
    onSubmit : ( input : Omit<InventoryAdjustment , 'id' | 'createdAt'> ) => any  }

const GridItem = styled(Grid)( {
    paddingBottom: 16
})


const steps = ['Direction', 'Applied', 'Info'];

export const InventoryAdjustmentForm :  FC<IAFProps> = ({ onSubmit , inventoryId : initialInventoryId }) => {
    const theme = useTheme();
    const [ inventory , setInventory] = useState<null| InventoryItem[] >( null );
    const [ inventoryItem , setInventoryItem ] = useState<InventoryItem|null>(null);
    const [ locations , setLocations] = useState<null| Array<{
        id: number,
        name: string
    }> >( null );
    
    const api = useApi();
    useEffect( () => {
        if( api.ready ){
            api.get( `/inventorymanagement/locations` ).then( ( results : { data : any }  ) => {
                setLocations( results.data );
                
                
            } )
        }
    } , [  api.ready ]);
    useEffect( () => {
        if( api.ready ){
            api.get( `/inventorymanagement/inventory` ).then( ( results : { data : any }  ) => {
                if( initialInventoryId){
                    const newInventoryItem = results?.data?.find( (d: any ) => {
                        return d.id === initialInventoryId;
                    });
                    if( newInventoryItem ){
                        setInventoryItem( newInventoryItem);
                        setInventoryId( initialInventoryId );
                    }
                    
                }
                setInventory( results.data );
                
            } )
        }
    } , [  api.ready ]);

    const [ description , setDescription ] = useState<string>('');
    const [ poNumber , setPoNumber ] = useState<string>('');
    const [ applied , setApplied ] = useState<boolean | null >(null);
    const [ direction , setDirection ] = useState<'inbound' | 'outbound' | null >(null);
    const [ inventoryId , setInventoryId ] = useState< InventoryAdjustment['inventoryId'] > ( initialInventoryId || null );
    
    const [ locationId , setLocationId ] = useState< InventoryAdjustment['locationId'] > ( null );
    const [ quantity , setQuantity ] = useState< InventoryAdjustment['quantity'] > ( null );

    const [activeStep, setActiveStep] = useState(0);    
    const [ internalCost , setinternalCost] = useState<null| number >( null );    

    return <>
      
    <Grid container sx={{minWidth: '500px', paddingLeft: 2 , paddingRight: 2, paddingBottom: 2}} >
    <Grid item sm={12}>
    <Stepper nonLinear activeStep={activeStep}>        
        <Step  key="Direction" completed={direction !== null }>
            <StepButton color="green" onClick={() => setActiveStep(0)}>
                Direction
            </StepButton>
        </Step>
        <Step key="Applied" completed={applied !== null }>
            <StepButton color="inherit" onClick={() => setActiveStep(1)}>
            Applied
            </StepButton>
        </Step>
        <Step key="Info" completed={ Boolean( quantity && locationId )}>
            <StepButton color="inherit" onClick={() => setActiveStep(2)}>
            Info
            </StepButton>
        </Step>
      </Stepper>
    </Grid>

{/**
     * Direction
     */
     activeStep === 0 && (<>
     <GridItem item sm={12} sx={{marginTop: 4, width: '500px'}}>
        <Grid container spacing={3}>
            <Grid item sm={6}>
                <Card
                onClick={()=>{
                    setDirection('inbound');
                    setActiveStep(1);
                }}
                sx={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,                    
                    textAlign: 'center',
                    minHeight: '150px',
                    cursor: 'pointer',
                    '&:hover' : {
                        background: theme.palette.secondary.light,
                    }
                }}>
                    <Typography variant="h6">Inbound</Typography>
                    <Typography variant="body1">Ordering from a vendor to us </Typography>
                    <Typography variant="body2">( this will add items to inventory )</Typography>
                </Card>
            </Grid>
            <Grid item sm={6}>
                <Card
                onClick={()=>{
                    setDirection('outbound');
                    setActiveStep(1);
                }}
                sx={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,                    
                    textAlign: 'center',
                    minHeight: '150px',
                    cursor: 'pointer',
                    '&:hover' : {
                        background: theme.palette.secondary.light,
                    }
                }}>
                    <Typography variant="h6">Outbound</Typography>
                    <Typography variant="body1">A Client ordering from us</Typography>
                    <Typography variant="body2">( this will take items out of inventory )</Typography>
                </Card>
            </Grid>
        </Grid>
     </GridItem>
     
     </> )}
{/**
     * Applied
     */
     activeStep === 1 && (<>
        <GridItem item sm={12} sx={{marginTop: 4, width: '500px'}}>
           <Grid container spacing={3}>
               <Grid item sm={6}>
                   <Card
                   onClick={()=>{
                        setApplied(true);
                       setActiveStep(2);
                   }}
                   sx={{
                       background: theme.palette.secondary.main,
                       color: theme.palette.secondary.contrastText,                    
                       textAlign: 'center',
                       minHeight: '150px',
                       cursor: 'pointer',
                       '&:hover' : {
                           background: theme.palette.secondary.light,
                       }
                   }}>
                       <Typography variant="h6">Apply to Inventory</Typography>
                       <Typography variant="body2">{ direction === 'outbound' ? "Remove" : "Add" } item(s) right away</Typography>
                   </Card>
               </Grid>
               <Grid item sm={6}>
                   <Card
                   onClick={()=>{
                       setApplied(false);
                       setActiveStep(2);
                   }}
                   sx={{
                       background: theme.palette.secondary.main,
                       color: theme.palette.secondary.contrastText,                    
                       textAlign: 'center',
                       minHeight: '150px',
                       cursor: 'pointer',
                       '&:hover' : {
                           background: theme.palette.secondary.light,
                       }
                   }}>
                       <Typography variant="h6">Pending</Typography>
                       <Typography variant="body2">{ direction === 'outbound' ? "Set to allocated for now" : "Set to on order for now" }</Typography>
                   </Card>
               </Grid>
           </Grid>
        </GridItem>
        
        </> )}


    {/**
     * Info Section
     */
     activeStep === 2 && (<>
        <GridItem item sm={12} sx={{marginTop: 4}}>
        { !initialInventoryId && inventory && <Autocomplete
      disablePortal
      onChange={( e , v) => { v?.value  && setInventoryId( v?.value )}}
      defaultValue={inventoryItem && { value : inventoryItem.id, label : inventoryItem.name }}
      options={inventory ? inventory.map( i => { return {
        value : i.id, label : i.name
      } }) : []}
      renderInput={(params) => <TextField  {...params} label="Inventory" />}
    /> }
        </GridItem>
        <GridItem item sm={12}>
        {locations && <Autocomplete
      disablePortal
      
      onChange={( e , v) => { v?.value  && setLocationId( v?.value )}}
      options={locations ? locations.map( i => { return {
        value : i.id, label : i.name
      } }) : []}
      renderInput={(params) => <TextField  {...params} label="Location" />}
    /> }
        </GridItem>
        <GridItem item sm={12}>
            <TextField  sx={{marginTop: 2}} fullWidth label="Quantity" value={quantity} type="number" onChange={( v) => { setQuantity( parseInt(v.target.value))}} />
        </GridItem>
        { direction === "inbound" && <GridItem item sm={12}>
            <TextField  sx={{marginTop: 2}} fullWidth label="internalCost" value={internalCost} type="number" onChange={( v) => { setinternalCost( parseFloat(v.target.value))}} />
        </GridItem> }
        <GridItem item sm={12}>
        <TextField multiline minRows={3}  sx={{marginTop: 2}} fullWidth label="Description" value={description} onChange={( v) => { setDescription( v.target.value)}} />
        </GridItem>
        <GridItem item sm={12}>
            <TextField  fullWidth label="PO Number / Ticket Number" value={poNumber} onChange={( v) => { setPoNumber( v.target.value)}} />
        </GridItem>
        
        <Grid item sm={12} sx={{marginTop: 2, textAlign: 'end'}}>
            <Button onClick={()=>{
                if( !direction ){
                    setActiveStep( 0);
                }
                direction && onSubmit({
                    description, poNumber , status : applied ? 'applied' : 'pending' , direction , inventoryId , locationId , quantity
                });
            }} variant='contained'>Submit</Button>
            
        </Grid>
        </>)
    }
    </Grid></>
}