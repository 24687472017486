
import { Box, Button, Grid, InputAdornment, Fab as MuiFab, Paper, styled, TextField, Typography } from '@mui/material';
import { useEffect, useState, useRef, FC, useMemo } from 'react';
import { io, Socket } from 'socket.io-client';
import { useUser } from '../../providers/User';
import { ChatInput, ChatMessage } from '../../../coretypes/ai.types';
import { Icon, InlineIcon } from '@iconify/react';
import { MarkdownView } from '../../MarkdownView';
import { TAiChat } from '../../../coretypes/ai/chat';
import { gql, useQuery } from '@apollo/client';
import { IPaginatedType } from '../../../coretypes/graphql/QueryResponse';
import { NestedPick } from '../../../coretypes/graphql/DeepPartial';
import { TAiChatMessage } from '../../../coretypes/ai/chatMessages';
import { useSocket } from '../../../providers/SocketIo';
import { LabeledBox } from '../LabeldBox';

const CHAT_MESSAGES = gql`
query ChatMessages($chatId: Float!) {
    findAIChatMessagesPaginated(chatId: { eq : $chatId }) {
        data {
            id
            role
            message
            user {
                id
                firstName
                lastName
            }
        }
    }
}
`;

const CHAT_QUERY = gql`
query Chat($chatId: Float!) {
    getAIChatById(id: $chatId) {
        id
        name
        ticketId
    }
}
`;

type ChatResponse = {
    getAIChatById: Pick<TAiChat , 'id' | 'name' | 'ticketId'>
}

type ChatMessagesResponse = {
    findAIChatMessagesPaginated: IPaginatedType<NestedPick<TAiChatMessage , {
      id: number;
      message: string;
      role: string;
      user: {
        id: string;
        firstName: string;
        lastName: string;
      }
    }>> 
    }


export const ChatBox : FC<{ chatId: number }>= ({ chatId }) => {
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const socket = useSocket();
    const { data: chatData } = useQuery<ChatResponse>(CHAT_QUERY, { variables: { chatId } });

    const { data , refetch: refetchChatMessages } = useQuery<ChatMessagesResponse>(CHAT_MESSAGES, { variables: { chatId } });
    const [ message, setMessage ] = useState('');
    const messages = data?.findAIChatMessagesPaginated?.data || [];
    const [ withContext , setWithContext ] = useState(false);
    useEffect(()=>{
        if( !chatData?.getAIChatById?.ticketId ){
            setWithContext(false);
        } else {
            setWithContext(true);
        }
    },[chatData?.getAIChatById?.ticketId]);
    useEffect(() => {
        if( !socket ){
            return;
        }  
      function onChatResponse(value: any) {
        refetchChatMessages();
      }
  
      socket.on('ai.chat.response', onChatResponse);
  
      return () => {
        socket.off('ai.chat.response', onChatResponse);
      };
    }, [socket]);
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    
    const payload : ChatInput = {
        chatId: chatId,
        prompt: message,
        runWithContexts: withContext ? ['ticket'] : undefined,
        ticketId: chatData?.getAIChatById?.ticketId || undefined,
        prependQueryData: chatData?.getAIChatById?.ticketId ? [`query ChatSearch( $ticketId : Float! ) {
  getAutotaskTicketsById(id: $ticketId ) {
    id
    title
    description
    dueDateTime
    nextActionDate
    nextActionTime
    contactlessStart
    userDefinedFields
    autotaskTicketQueue {
      name
    }
    tech {
        firstName
      	lastName
      	email
    }
    contact {
      firstName
      lastName
      emailAddress
    }
    autotaskCompany {
      companyName
      phone
    }
    autotaskTicketStatus {
      name
    }
    timeclocks {
      user {
        firstName
        lastName
        email
      }
      start
      end
      message
    }
  }
}`] : undefined
    }
    const sendMessage = async () => {
        socket?.emit( 'ai.chat' , payload );
        setMessage('');
    }
    const length = useMemo( () => {
        return message.split('\n').length;
    },[message]);
    return <>
    <Box sx={{ p: 1, flexGrow: 1, overflowY: 'auto' }} ref={chatContainerRef}>
        <Grid container spacing={2}>
            
            
        { messages.map( (message, index) => (
            <Grid item xs={12} key={index}>
                <LabeledBox label={message?.user?.firstName ? `${message?.user?.firstName} ${message?.user?.lastName}` : message?.role}>
                    <MarkdownView>{message.message}</MarkdownView>
                </LabeledBox>
            </Grid>
        )) }
        <div ref={chatContainerRef} />
        </Grid>
        </Box>
        <Box sx={{ p: 1 }}>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <TextField 
          size="small"
          fullWidth 
          value={message} 
          onChange={(e) => setMessage(e.target.value)}  
          multiline 
          rows={length}
          InputProps={{
            endAdornment: (
                <InputAdornment position="end">                    
                    <Button size="small" variant="outlined" onClick={sendMessage}>
                        <InlineIcon icon="heroicons-solid:chat"></InlineIcon>&nbsp;Send
                    </Button>
                </InputAdornment>
            ),
        }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              sendMessage();
            }
          }}
        />
        </Grid>
        </Grid>
      </Box>
      </>
}