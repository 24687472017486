import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TechFinderUsersQueryVariables = Types.Exact<{
  coreUserId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type TechFinderUsersQuery = { __typename?: 'Query', findCoreUsersPaginated: { __typename?: 'PaginatedCoreUsers', data?: Array<{ __typename?: 'CoreUsers', id?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null, isRoleTriage?: boolean | null, isRoleTier1?: boolean | null, isRoleTier2FirstUp?: boolean | null, isRoleTier2?: boolean | null, isScheduler?: boolean | null, isOnCall?: boolean | null, canContactCustomers?: boolean | null, bvoipExtention?: number | null, techTier?: number | null, activeScheduleInstances?: Array<{ __typename?: 'ScheduleInstance', id?: number | null, title?: string | null, available?: boolean | null, schedulePlanId?: number | null, SchedulePlan?: { __typename?: 'SchedulePlan', name?: string | null } | null, AzureCalendar?: { __typename?: 'AzureCalendar', subject?: string | null } | null }> | null, activeTimeclocks?: Array<{ __typename?: 'CoreTimeclock', id?: number | null, start?: any | null, ticketId?: number | null, todoId?: number | null, coreUserId?: string | null, isDispatchedTicket?: boolean | null, message?: string | null, fromRole?: string | null, isPayroll?: boolean | null, ticket?: { __typename?: 'AutotaskTickets', ticketNumber: string, priority?: number | null, title: string, id?: number | null, autotaskTicketStatus?: { __typename?: 'AutotaskTicketStatuses', name: string } | null } | null }> | null }> | null } };


export const TechFinderUsersDocument = gql`
    query TechFinderUsers($coreUserId: String) {
  findCoreUsersPaginated(
    limit: 300
    coreCompanyId: {eq: 4}
    active: {eq: true}
    isTech: {eq: true}
    order: {firstName: ASC}
    id: {eq: $coreUserId}
  ) {
    data {
      id
      email
      firstName
      lastName
      isRoleTriage
      isRoleTier1
      isRoleTier2FirstUp
      isRoleTier2
      isScheduler
      isOnCall
      canContactCustomers
      bvoipExtention
      techTier
      activeScheduleInstances {
        id
        title
        SchedulePlan {
          name
        }
        AzureCalendar {
          subject
        }
        available
        schedulePlanId
      }
      activeTimeclocks {
        id
        start
        ticketId
        todoId
        coreUserId
        isDispatchedTicket
        message
        fromRole
        ticket {
          ticketNumber
          priority
          title
          id
          autotaskTicketStatus {
            name
          }
        }
        isPayroll
      }
    }
  }
}
    `;

/**
 * __useTechFinderUsersQuery__
 *
 * To run a query within a React component, call `useTechFinderUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechFinderUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechFinderUsersQuery({
 *   variables: {
 *      coreUserId: // value for 'coreUserId'
 *   },
 * });
 */
export function useTechFinderUsersQuery(baseOptions?: Apollo.QueryHookOptions<TechFinderUsersQuery, TechFinderUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TechFinderUsersQuery, TechFinderUsersQueryVariables>(TechFinderUsersDocument, options);
      }
export function useTechFinderUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TechFinderUsersQuery, TechFinderUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TechFinderUsersQuery, TechFinderUsersQueryVariables>(TechFinderUsersDocument, options);
        }
export function useTechFinderUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TechFinderUsersQuery, TechFinderUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TechFinderUsersQuery, TechFinderUsersQueryVariables>(TechFinderUsersDocument, options);
        }
export type TechFinderUsersQueryHookResult = ReturnType<typeof useTechFinderUsersQuery>;
export type TechFinderUsersLazyQueryHookResult = ReturnType<typeof useTechFinderUsersLazyQuery>;
export type TechFinderUsersSuspenseQueryHookResult = ReturnType<typeof useTechFinderUsersSuspenseQuery>;
export type TechFinderUsersQueryResult = Apollo.QueryResult<TechFinderUsersQuery, TechFinderUsersQueryVariables>;