import { Button, Container, Paper, TextField } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { StyledTable, TableColumnDef } from "../../react-submodules/components/Table";
import { useEffect, useState } from "react";

import { useApi } from "../../react-submodules/providers/Api/Api";
import { useNavigate } from "react-router-dom";

const columns : TableColumnDef[] = [
    {
        field: 'name',
        flex: 1,
        headerName: 'Form Name'
    },
    {
        field: 'identifier',
        flex: 1,
        headerName: 'Identifier'
    },
    {
        field: 'description',
        flex: 2,
        headerName: 'Description'
    }
]
export const FormBuilderSearch = () => {
    const nav = useNavigate();
    const [ search, setSearch ] = useState( '' );
    const [ forms  , setForms ] = useState( [] );
    const api = useApi();
    useEffect(() => {
        const getData = setTimeout(() => {
          api.get( '/forms' , { 'name:like' : search } ).then( ( results ) => {
            setForms( results.data );
          });
        }, 500)
    
        return () => clearTimeout(getData)
      }, [search]);

    const createForm = async () => {
        // Example form data, adjust according to your needs
        const newForm = {
            name: "New Form",
            identifier: "new_form",
            description: "This is a new form."
        };

        const result = await api.post('/forms', newForm);
        if (result.data && result.data.id) {
            nav(`/formbuilder/${result.data.id}`);
        }
    };

    return <>
        <Container sx={{marginTop: 2 }}>
            <Paper elevation={2} sx={{padding: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <TextField fullWidth label="Search" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                <Button variant="contained" onClick={createForm} sx={{ ml: 2 }}>Create Form</Button>
            </Paper>
        </Container>
        { forms.length > 0 && (
            <Container sx={{marginTop: 2 }}>
                <Paper>
                    <StyledTable
                        onClick={(row) => {
                            nav(`/formbuilder/${row.id}`);
                        }}
                        rows={forms}
                        columns={columns}
                    />
                </Paper>
            </Container>
        )}
    </>
}
