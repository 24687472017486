import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DownloadFileQueryVariables = Types.Exact<{
  key: Types.Scalars['String']['input'];
}>;


export type DownloadFileQuery = { __typename?: 'Query', findCoreFilePaginated: { __typename?: 'PaginatedCoreFile', totalCount: number, page: number, limit: number, hash?: string | null, data?: Array<{ __typename?: 'CoreFile', signedUrl?: { __typename?: 'SignedUrl', url: string } | null }> | null } };


export const DownloadFileDocument = gql`
    query DownloadFile($key: String!) {
  findCoreFilePaginated(key: {like: $key}, limit: 5000) {
    data {
      signedUrl {
        url
      }
    }
    totalCount
    page
    limit
    hash
  }
}
    `;

/**
 * __useDownloadFileQuery__
 *
 * To run a query within a React component, call `useDownloadFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadFileQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useDownloadFileQuery(baseOptions: Apollo.QueryHookOptions<DownloadFileQuery, DownloadFileQueryVariables> & ({ variables: DownloadFileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadFileQuery, DownloadFileQueryVariables>(DownloadFileDocument, options);
      }
export function useDownloadFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadFileQuery, DownloadFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadFileQuery, DownloadFileQueryVariables>(DownloadFileDocument, options);
        }
export function useDownloadFileSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DownloadFileQuery, DownloadFileQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DownloadFileQuery, DownloadFileQueryVariables>(DownloadFileDocument, options);
        }
export type DownloadFileQueryHookResult = ReturnType<typeof useDownloadFileQuery>;
export type DownloadFileLazyQueryHookResult = ReturnType<typeof useDownloadFileLazyQuery>;
export type DownloadFileSuspenseQueryHookResult = ReturnType<typeof useDownloadFileSuspenseQuery>;
export type DownloadFileQueryResult = Apollo.QueryResult<DownloadFileQuery, DownloadFileQueryVariables>;