import { Box, Card, CardContent, CardHeader, Grid, Switch, TextField } from "@mui/material"
import { Handle, Position } from "reactflow"
import { useNodeDataProperty, useRecipie } from "../../../../providers/Recipies.provider";
import { LabeledBox } from "../../../../react-submodules/components/LabeldBox";

export const StartNode = ({ id , data } : any ) => {
    const [ title , setTitle ] = useNodeDataProperty( id , 'title');
    const [ independentStart , setIndependentStart ] = useNodeDataProperty<boolean>( id , 'independentStart' , false );
    return <Box>
        <Card sx={{maxWidth: '300px'}}>
            <CardHeader className="drag-handle" title="Start" />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField sx={{padding: '2px'}} size="small" label="Title" value={title} onChange={(e) => {
                            
                            setTitle( e.target.value );
                        }} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <LabeledBox label="Independent Start">
                            <Switch checked={independentStart} onChange={(e) => {
                                setIndependentStart( e.target.checked );
                            }} />
                        </LabeledBox>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        <Handle
          type="source"
          position={Position.Right}
          id={`handle-source`}
        />
    </Box>
}