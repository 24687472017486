import { Alert, Box, Button, Divider, FormControlLabel, Grid, Switch, Tab, TextField } from "@mui/material"
import { FC, useEffect, useMemo, useState } from "react"

import { ErrorBoundary } from "../ErrorBoundry"
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab"
import { QueueTicket } from "../../types/ticketqueue"
import { TicketUpdateField } from "./Fields"
import { useApi } from "../../providers/Api/Api"
import { useMounted } from "../../hooks/useMounted"
import { useTicket } from "../../providers/TicketProvider"
import { useTicketApi } from "../../providers/TicketApiProvider"
import { useTimeclock } from "../../providers/Timeclock"
import { MarkdownEditor } from "../MarkdownEditor/MarkdownEditor"


const actionFields = [ "status", "nextActionDate" , "nextActionNote" , "assignedResourceID" , "priority" , "queue" , "contactlessStart" ];


export const TicketUpdateForm : FC<{ onClose: () => any , fields : Array<keyof QueueTicket> , updates? : Record<string,any>} > = ({ onClose ,fields , updates : propUpdates  }) => {

    
    const loaded = useMounted();
    const [ updates , setUpdates] = useState< Record<string,any>>(propUpdates || {});
    const [ message , setMessage] = useState( '' );
    const [ clockOut , setClockOut ] = useState( true );
    const { ticket , ticketPunch } = useTicket();
    const { refresh : refreshTimeclock } = useTimeclock();

    const [ updating , setUpdating ] = useState( false );
    
    
    const { getNextTicket } = useTicketApi();
    const api = useApi();
    useEffect( () => {
        setUpdates( propUpdates || {});
    } , [ JSON.stringify( propUpdates )]);


    const patchTicket = async ( gotoNextTicket?: boolean  ) => {
        setUpdating(true);
        // Update has to be before time punch, in case of changing company
        await api.patch(`/autotask/updateTicket/${ticket?.id}` ,updates);
        
        if( ticketPunch && clockOut ){
            await api.post('/core/timeclock/clockout' , {
                id: ticketPunch.id,
                message, 
                ticketUpdateData : updates
            });
            refreshTimeclock();
        }
        if(gotoNextTicket ){
            getNextTicket()
        } else {
            window.location.reload();
        }

        onClose();
        if( loaded() ){
            setUpdating(false);
        }
        
    }
    const [ tab , setTab ] = useState( ["new" , "customer note added"].includes( ticket?.status?.toLowerCase() || '' ) ? "Ticket Info" : "Action Info" );

    const ticketInfoFields = fields.filter( f => !actionFields.includes( f ) );
    const usedActionFields  = fields.filter( f => actionFields.includes( f ) );
    const canGoNext = useMemo( () => {
        if( !ticketInfoFields.includes( 'contactID' ) ){
            return true;
        }
        return Boolean( updates.contactID ) || Boolean( ticket?.contactID );
    } , [ updates , ticket ]);


    return <Grid container>
        <TabContext value={tab}>
            
            <Grid item xs={12}>
                <TabList onChange={(e , v) => { setTab(v) }} 
                        centered 
                        variant="standard" 
                        textColor="secondary"
                        sx={{
                            minHeight: '30px',
                            height: '30px',
                            backgroundColor: '#f0f0f0', // Light gray background
                            borderRadius: '4px 4px 0 0', // Rounded top corners
                            '& .MuiTab-root': {
                                color: '#333', // Dark text for contrast
                                fontSize: '12px',
                                minHeight: '30px',
                                height: '30px',
                                '&.Mui-selected': {
                                    color: 'secondary.contrastText', // Use primary color for selected tab
                                    backgroundColor: 'secondary.main', // White background for selected tab
                                },
                            },
                        }}>
                    <Tab value="Ticket Info" label="Ticket Info" />
                    <Tab value="Action Info" label="Action Info" disabled={!canGoNext} />
                </TabList>
            </Grid>
            <TabPanel sx={{ width: '100%' }} value="Ticket Info">
            { !canGoNext && <Grid item xs={12}>
                <Alert sx={{marginBottom: 2}} severity="warning">
                    You must enter a contact to continue.
                </Alert>
            </Grid>}
                 {ticketInfoFields.map( ( field ) => {
                return <Grid sx={{marginBottom: 2}} key={field} item xs={12}>
                    <ErrorBoundary><TicketUpdateField 
                        currentTicket={{...ticket , ...updates}}  field={field} 
                        value={updates[field] !== undefined ? updates[field] : ticket?.[field] } 
                        onChange={( value ) => {
                            setUpdates( { ...updates , [field] : value })
                        }} 
                        updateField={( ufield , value ) => {
                            setUpdates( { ...updates , [ufield] : value })
                        }}
                        /></ErrorBoundary></Grid>
            
            })}
            <Grid sx={{marginBottom: 2}} item xs={12}>
                <Button variant="contained" color="info" onClick={()=>{
                    setTab( 'Action Info' );
                }} disabled={!canGoNext}>Next Page</Button>
            </Grid>
            </TabPanel>
            <TabPanel sx={{ width: '100%' }} value="Action Info">

            {usedActionFields.map( ( field ) => {
                return <Grid sx={{marginBottom: 2 , width: '100%'}} key={field} item xs={12}><ErrorBoundary><TicketUpdateField 
                currentTicket={{...ticket , ...updates}}  field={field} 
                value={updates[field] !== undefined ? updates[field] : ticket?.[field] } 
                onChange={( value ) => {
                    setUpdates( { ...updates , [field] : value })
                }} 
                updateField={( ufield , value ) => {
                    setUpdates( { ...updates , [ufield] : value })
                }}
                /></ErrorBoundary></Grid>
            
            })}

            {ticketPunch && <>
                
            <Grid sx={{marginBottom: 2 , borderTop : '1px solid #aaa'}} item xs={12}>
                
            <FormControlLabel
          control={
            <Switch color="success" checked={clockOut} onChange={(e)=>{
                setClockOut( e.target.checked );
            }} />
          }
          label="Clock Out"
        />
                
                </Grid>
            <Grid sx={{marginBottom: 2}} item xs={12}>
                <MarkdownEditor label="Time Entry Message" markdown={message} onChange={setMessage} /></Grid></> }
                <Grid item xs={12}>
        <Box sx={{'display' : 'flex' , flexDirection: 'row' , height: '100%'}}>
        <Box flex={1}>&nbsp;</Box>
        <LoadingButton loading={updating} sx={{marginRight: 1}} color="warning" onClick={()=>{
                patchTicket( false);
            }} variant="contained">
                Update and Stay
            </LoadingButton>
            <LoadingButton loading={updating} onClick={()=>{
                patchTicket( true);
            }} variant="contained" color="success">
                Update and Next
            </LoadingButton>
            </Box>
        </Grid>
                </TabPanel>
        
        </TabContext>
    </Grid>
}