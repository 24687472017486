
import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from "react";

import { useApi } from "./Api/Api";
import { useDataObject } from "../hooks/useDataQuery";

export type TThemes =  'forest' | 'royal' | 'blacktie' | 'couleetech';

export type TUserRoles = 'isRoleTriage' | 'isRoleTier1' | 'isRoleTier2FirstUp' | 'isRoleTier2' | 'isScheduler' | 'isSherpaChampion';
export const UserRolesList : TUserRoles[]  = ['isRoleTriage' ,'isRoleTier1' , 'isRoleTier2FirstUp' , 'isRoleTier2' , 'isScheduler' , 'isSherpaChampion']

type CoreUsers  = {
    id: string;
    theme: TThemes;
    email: string;
    canContactCustomers: boolean | null;
    isTech: boolean | null;
    techTier: number | null;
    autotaskId: number | null;
    isDispatchable: boolean | null;    
    isSherpaChampion: boolean | null;
} & Record< TUserRoles , boolean | null >;

interface UserProfileProvider {
    userProfile: CoreUsers | null;
    updateUserProfile: (up: Partial<CoreUsers>) => any;
    refresh: () => null
}
const UserProfileContext = createContext<UserProfileProvider>({
    userProfile: null,
    updateUserProfile: (up: Partial<CoreUsers>) => null,
    refresh: () => null
});
export const useUserProfile = () => useContext(UserProfileContext);

export const UserProfileProvider :React.FC<PropsWithChildren> = ( { children}) => {
    const api = useApi();
    const { data : userProfile, loading  , refresh } = useDataObject<CoreUsers>('/core/myprofile');

    const [ tick , setTick ] = useState( 1 );

    useEffect( () => {
        refresh();
    } , [tick] );
    
    useEffect( () => {
        if( !api.online || !api.ready ){
            return;
        }
        setTick( pv => pv * -1);
        const iv = setInterval( () => {
            setTick( pv => pv * -1);
        }, 1000 * 10 );
        return () => {
            clearInterval( iv );
        }
    } , [api.ready , api.online ])

    const updateUserProfile = (update: Partial<CoreUsers>) => {
        if( !api.online ){
            return;
        }
        api.put('/core/myprofile' , update).then( () => {
            refresh();
        });
    }

    return (
        <UserProfileContext.Provider value={{
            userProfile : userProfile?.id ? userProfile : null ,
            updateUserProfile,
            refresh
        }}>{children}</UserProfileContext.Provider>
    )
}