import React, { createContext, useContext, useState } from "react";

import { RecipeAction } from "../../coretypes/recipe/actions";
import { useApi } from "./Api/Api";

export type RecipeContextDTO = {
    values?: Record<string, any>;
    resultHandleId?: string | number;
    
} & Record<string, any >


type ActionResponse = {
    actions: RecipeAction[]
};

type ExtraActionContext = {
     formInstanceId? : string 
}

interface RecipeApiProvider  {
    submitRecipe: ( instanceId: number | string , context: RecipeContextDTO , extra? : ExtraActionContext ) => Promise<void>;
    removeAction: ( action: RecipeAction ) => void;
    startRecipe: ( recipeId: number | string , context: RecipeContextDTO , extra? : ExtraActionContext ) => Promise<void>;
    addAction: ( action: RecipeAction ) => void;
    actions: RecipeAction[]
}

const RecipeApiContext = createContext<RecipeApiProvider>({
    submitRecipe: async () => {},
    removeAction: () => {},
    startRecipe: async () => {},
    addAction: () => {},
    actions: []
});
export const useRecipeApi = () => useContext(RecipeApiContext);

export const RecipeApiProvider = ({ children }: { children: React.ReactNode }) => {
    const api = useApi();
    const [ actions , setActions ] = useState<RecipeAction[]>([]);

    const removeAction = ( action: RecipeAction ) => {
        setActions( currentActions => currentActions.filter( a => a.id !== action.id ) );
    }
    const addAction = ( action: RecipeAction ) => {
        setActions( currentActions => [ ...currentActions , action ] );
    }
    const submitRecipe = async ( instanceId: number | string , context: RecipeContextDTO , extra?: ExtraActionContext  ) => {
        await api.post<ActionResponse>(`/recipe/instance/${instanceId}/submit` , context ).then( ({ data } )=> {
            const action = actions.find( a => +a.formInstanceId === parseInt( extra?.formInstanceId  || "0" ));
            setActions( currentActions =>  [ ...currentActions , ...(data.actions || []) ] );
            if( action ){
                removeAction( action );
            }
        });
    }
    const startRecipe = async ( recipeId: number | string , context: RecipeContextDTO , extra?: ExtraActionContext  ) => {
        const {data} = await api.post<ActionResponse>(`/recipe/${recipeId}/start` , context );
        if( data?.actions ){
            setActions( currentActions =>  [ ...currentActions , ...data.actions ] );
        }
    }

    return <RecipeApiContext.Provider value={{ startRecipe,  submitRecipe , actions : JSON.parse(JSON.stringify(actions)) , removeAction , addAction }}>{children}</RecipeApiContext.Provider>;
}
export const withRecipeApiProvider = (Component: React.ComponentType) => {
    return (props: any) => (
        <RecipeApiProvider>
            <Component {...props} />
        </RecipeApiProvider>
    );
};


