import { FC } from "react"
import sanitizeHtml from 'sanitize-html';
export const HtmlView : FC<{html : string}> = ({ html}) => {
    const sanitizeOptions = {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
        allowedAttributes: {
            ...sanitizeHtml.defaults.allowedAttributes,
            img: ['src', 'alt']
        },
        allowedSchemes: ['https'],
        allowedSchemesByTag: {
            img: ['https']
        },
        allowedSchemesAppliedToAttributes: ['src'],
        allowedHostnames: ['s3.us-central-1.wasabisys.com']
    };
    return <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(html, sanitizeOptions) }} />
}