import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FileSizeQueryVariables = Types.Exact<{
  key: Types.Scalars['String']['input'];
}>;


export type FileSizeQuery = { __typename?: 'Query', findCoreFilePaginated: { __typename?: 'PaginatedCoreFile', totalCount: number, page: number, limit: number, hash?: string | null, data?: Array<{ __typename?: 'CoreFile', size: number }> | null } };


export const FileSizeDocument = gql`
    query FileSize($key: String!) {
  findCoreFilePaginated(key: {like: $key}, limit: 5000) {
    data {
      size
    }
    totalCount
    page
    limit
    hash
  }
}
    `;

/**
 * __useFileSizeQuery__
 *
 * To run a query within a React component, call `useFileSizeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileSizeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileSizeQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useFileSizeQuery(baseOptions: Apollo.QueryHookOptions<FileSizeQuery, FileSizeQueryVariables> & ({ variables: FileSizeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileSizeQuery, FileSizeQueryVariables>(FileSizeDocument, options);
      }
export function useFileSizeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileSizeQuery, FileSizeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileSizeQuery, FileSizeQueryVariables>(FileSizeDocument, options);
        }
export function useFileSizeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FileSizeQuery, FileSizeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FileSizeQuery, FileSizeQueryVariables>(FileSizeDocument, options);
        }
export type FileSizeQueryHookResult = ReturnType<typeof useFileSizeQuery>;
export type FileSizeLazyQueryHookResult = ReturnType<typeof useFileSizeLazyQuery>;
export type FileSizeSuspenseQueryHookResult = ReturnType<typeof useFileSizeSuspenseQuery>;
export type FileSizeQueryResult = Apollo.QueryResult<FileSizeQuery, FileSizeQueryVariables>;