import * as uuid from 'uuid';

export function getColorFromString( value : string | number , namespace = "default"  , saturation : number  = 50, lightness : number = 75, defaultColor : string  = '#000000') {
    const string = uuid.v5( `${value}` , namespace);
  if (`${value}`.trim().length < 3) {
      return defaultColor;
    }
    
    // Calculate the hue value based on the first three characters of the string
    const hue = string.charCodeAt(0) * string.charCodeAt(1) * string.charCodeAt(2);
  
    // Convert the saturation and lightness values from percentages to decimals between 0 and 1
    const s = saturation / 100;
    const l = lightness / 100;
  
    // Calculate the alpha value
    const a = s * Math.min(l, 1 - l);
  
    // Calculate the red, green, and blue values
    const f = (n : number ) => {
      const k = (n + hue / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0');
    };
  
    // Return the hex color code
    const hexCode = `#${f(0)}${f(8)}${f(4)}`;
    return hexCode || defaultColor;
  }