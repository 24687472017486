import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MergedTicketInfoQueryVariables = Types.Exact<{
  mergedTicketId: Types.Scalars['Int']['input'];
}>;


export type MergedTicketInfoQuery = { __typename?: 'Query', getAutotaskTicketsById: { __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, title: string, mergedDate?: any | null, lastActivityDate: any, aisummary?: string | null, description?: string | null, mergedByUserId?: string | null } };


export const MergedTicketInfoDocument = gql`
    query MergedTicketInfo($mergedTicketId: Int!) {
  getAutotaskTicketsById(id: $mergedTicketId) {
    id
    ticketNumber
    title
    mergedDate
    lastActivityDate
    aisummary
    description
    mergedByUserId
  }
}
    `;

/**
 * __useMergedTicketInfoQuery__
 *
 * To run a query within a React component, call `useMergedTicketInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMergedTicketInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMergedTicketInfoQuery({
 *   variables: {
 *      mergedTicketId: // value for 'mergedTicketId'
 *   },
 * });
 */
export function useMergedTicketInfoQuery(baseOptions: Apollo.QueryHookOptions<MergedTicketInfoQuery, MergedTicketInfoQueryVariables> & ({ variables: MergedTicketInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MergedTicketInfoQuery, MergedTicketInfoQueryVariables>(MergedTicketInfoDocument, options);
      }
export function useMergedTicketInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MergedTicketInfoQuery, MergedTicketInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MergedTicketInfoQuery, MergedTicketInfoQueryVariables>(MergedTicketInfoDocument, options);
        }
export function useMergedTicketInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MergedTicketInfoQuery, MergedTicketInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MergedTicketInfoQuery, MergedTicketInfoQueryVariables>(MergedTicketInfoDocument, options);
        }
export type MergedTicketInfoQueryHookResult = ReturnType<typeof useMergedTicketInfoQuery>;
export type MergedTicketInfoLazyQueryHookResult = ReturnType<typeof useMergedTicketInfoLazyQuery>;
export type MergedTicketInfoSuspenseQueryHookResult = ReturnType<typeof useMergedTicketInfoSuspenseQuery>;
export type MergedTicketInfoQueryResult = Apollo.QueryResult<MergedTicketInfoQuery, MergedTicketInfoQueryVariables>;