import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TicketEmailsForAttachmentsQueryVariables = Types.Exact<{
  autotaskTicketId: Types.Scalars['Float']['input'];
}>;


export type TicketEmailsForAttachmentsQuery = { __typename?: 'Query', findAzureEmailPaginated: { __typename?: 'PaginatedAzureEmail', data?: Array<{ __typename?: 'AzureEmail', AzureEmailAttachments?: Array<{ __typename?: 'AzureEmailAttachment', id?: string | null, isInline?: boolean | null, name?: string | null, contentType?: string | null, contentLocation?: string | null }> | null }> | null } };


export const TicketEmailsForAttachmentsDocument = gql`
    query TicketEmailsForAttachments($autotaskTicketId: Float!) {
  findAzureEmailPaginated(autotaskTicketId: {eq: $autotaskTicketId}, limit: 1000) {
    data {
      AzureEmailAttachments {
        id
        isInline
        name
        contentType
        contentLocation
      }
    }
  }
}
    `;

/**
 * __useTicketEmailsForAttachmentsQuery__
 *
 * To run a query within a React component, call `useTicketEmailsForAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketEmailsForAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketEmailsForAttachmentsQuery({
 *   variables: {
 *      autotaskTicketId: // value for 'autotaskTicketId'
 *   },
 * });
 */
export function useTicketEmailsForAttachmentsQuery(baseOptions: Apollo.QueryHookOptions<TicketEmailsForAttachmentsQuery, TicketEmailsForAttachmentsQueryVariables> & ({ variables: TicketEmailsForAttachmentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TicketEmailsForAttachmentsQuery, TicketEmailsForAttachmentsQueryVariables>(TicketEmailsForAttachmentsDocument, options);
      }
export function useTicketEmailsForAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketEmailsForAttachmentsQuery, TicketEmailsForAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TicketEmailsForAttachmentsQuery, TicketEmailsForAttachmentsQueryVariables>(TicketEmailsForAttachmentsDocument, options);
        }
export function useTicketEmailsForAttachmentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TicketEmailsForAttachmentsQuery, TicketEmailsForAttachmentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TicketEmailsForAttachmentsQuery, TicketEmailsForAttachmentsQueryVariables>(TicketEmailsForAttachmentsDocument, options);
        }
export type TicketEmailsForAttachmentsQueryHookResult = ReturnType<typeof useTicketEmailsForAttachmentsQuery>;
export type TicketEmailsForAttachmentsLazyQueryHookResult = ReturnType<typeof useTicketEmailsForAttachmentsLazyQuery>;
export type TicketEmailsForAttachmentsSuspenseQueryHookResult = ReturnType<typeof useTicketEmailsForAttachmentsSuspenseQuery>;
export type TicketEmailsForAttachmentsQueryResult = Apollo.QueryResult<TicketEmailsForAttachmentsQuery, TicketEmailsForAttachmentsQueryVariables>;