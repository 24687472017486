import { MenuItem, TextField } from "@mui/material";
import { FC } from "react";
import { useGetCoreUsersQuery } from "../gql/GetCoreUsers.generated";

export const UserPicker : FC<{
    value: string | null;
    onChange?: (value: string | null) => void;
}> = ({ value , onChange }) => {
    const { data } = useGetCoreUsersQuery({
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: false
    });
    return <TextField fullWidth select label="User" value={value|| 'unset'} onChange={(e : any ) => onChange?.(e.target.value === 'unset' ? null : e.target.value)}>
        <MenuItem value="unset">Select one</MenuItem>
        {(data?.findCoreUsersPaginated.data ?? []).map(u => <MenuItem key={u.id} value={u.id || 'unset'}>{u.firstName} {u.lastName}</MenuItem>)}
    </TextField>;
}