import { FC, useState } from "react"
import { ContractsInstances } from "../../../../coretypes/contracts/contracts.dto"
import { CoreContact } from "../../../../coretypes/contacts/contacts.dto"
import { IconButton, useTheme } from "@mui/material"
import { Icon } from "@iconify/react"
import { useApi } from "../../../../react-submodules/providers/Api"
import { DEFAULT_CONTRACT_ICON, DEFAULT_CONTRACT_ICON_COLOR } from "../../../../react-submodules/constances/icons"
import { useMounted } from "../../../../react-submodules/hooks/useMounted"

export const ContractCell : FC<{ contract : ContractsInstances[] , contact : CoreContact , refresh : () => void }> = ({
    contract , contact , refresh
}) => {
    const loaded = useMounted();
    const theme = useTheme();
    
    const api = useApi();
    const usableContracts = contract; // Assuming all contracts are usable for contacts
    const [ updating , setUpdating ] = useState<boolean | number >( false );
    const toggleContract = ( id : number ) => {
        setUpdating( id );
        api.post( `/contracts/instances/${id}/${contact.contracts?.[id] ? 'unassign' : 'assign'}` , { coreContactId : contact.id } ).then( async () => {
            await refresh();
            setTimeout( () => {
                loaded() && setUpdating( false );
            }, 10000 );
        } );
    }
    
    return <>
        { usableContracts.map( c => {
            let color = contact.contracts?.[c.id] ? (c.contract_iconColor || DEFAULT_CONTRACT_ICON_COLOR) : undefined;
            if( updating == c.id ) {
                color = theme.palette.warning.main;
            }
            return <IconButton key={c.id} onClick={()=>{toggleContract( c.id );}} size="small" sx={{
                color: color
            }}><Icon icon={c.contract_icon || DEFAULT_CONTRACT_ICON} /></IconButton>
        })}
    </>
}
