import { TTimelineItem, isEmailItem, isFormItem, isOgNoteItem, isTimeclockItem } from "./types";

import { FormItem } from "./FormItem";
import { NoteItem } from "./NoteItem";
import { EmailItem } from "./EmailItem";
import { TimeclockItem } from "./TimeclockItem";

export const TimelineItem : React.FC<{item : TTimelineItem}> = ({ item }) => {
    if( isTimeclockItem( item ) ){
        return <TimeclockItem timeclock={item.timeclock} />
    }
    if( isOgNoteItem( item ) ){
        return <NoteItem note={item.note} />
    }
    if( isFormItem( item ) ){
        return <FormItem form={item.form} />
    }
    if( isEmailItem( item ) ){
        return <EmailItem email={item.email} />
    }
    return <></>
}

