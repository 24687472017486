import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CoreUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CoreUsersQuery = { __typename?: 'Query', findCoreUsersPaginated: { __typename?: 'PaginatedCoreUsers', data?: Array<{ __typename?: 'CoreUsers', id?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null, autotaskId?: number | null }> | null } };


export const CoreUsersDocument = gql`
    query CoreUsers {
  findCoreUsersPaginated(
    limit: 3000
    coreCompanyId: {eq: 4}
    active: {eq: true}
    autotaskId: {notnull: true}
  ) {
    data {
      id
      email
      firstName
      lastName
      autotaskId
    }
  }
}
    `;

/**
 * __useCoreUsersQuery__
 *
 * To run a query within a React component, call `useCoreUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoreUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoreUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoreUsersQuery(baseOptions?: Apollo.QueryHookOptions<CoreUsersQuery, CoreUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoreUsersQuery, CoreUsersQueryVariables>(CoreUsersDocument, options);
      }
export function useCoreUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoreUsersQuery, CoreUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoreUsersQuery, CoreUsersQueryVariables>(CoreUsersDocument, options);
        }
export function useCoreUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CoreUsersQuery, CoreUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CoreUsersQuery, CoreUsersQueryVariables>(CoreUsersDocument, options);
        }
export type CoreUsersQueryHookResult = ReturnType<typeof useCoreUsersQuery>;
export type CoreUsersLazyQueryHookResult = ReturnType<typeof useCoreUsersLazyQuery>;
export type CoreUsersSuspenseQueryHookResult = ReturnType<typeof useCoreUsersSuspenseQuery>;
export type CoreUsersQueryResult = Apollo.QueryResult<CoreUsersQuery, CoreUsersQueryVariables>;