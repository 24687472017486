
import { Box, Button, ButtonGroup } from "@mui/material";
import { FC, useMemo } from "react"
import { LabeledBox } from "../../../react-submodules/components/LabeldBox";

const daysOfTheMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
const g1 = [1, 2, 3, 4, 5, 6, 7]
const g2 = [8, 9, 10, 11, 12, 13, 14]
const g3 = [15, 16, 17, 18, 19, 20, 21]
const g4 = [22, 23, 24, 25, 26, 27, 28]
const g5 = [29, 30, 31]
export const DaysOfTheMonthEdit : FC<{ row : Record<string, any> , value : any , onChangeField : (field: string, value: any) => void }> = ({ row , value , onChangeField }) => {
    
    const selectedDays = useMemo(() => {
        if( !value || !Array.isArray(value) ) {
            return daysOfTheMonth;
        }
        return value;
    }, [value]);
    const toggleDay = (day: number) => {
        const newDays = [...selectedDays];
        if( newDays.includes(day) ) {
            newDays.splice(newDays.indexOf(day), 1);
        } else {
            newDays.push(day);
        }
        onChangeField('startTimeDay_daysOfMonth', newDays);
    }
    return <LabeledBox label="Days of the Month">
         <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            gap: 0.25 
        }}>
            <Box sx={{ width: '18rem'}}>
            <ButtonGroup>{g1.map(day => <Button sx={{ width: '2rem' }} key={day} variant="contained" color={selectedDays.includes(day) ? 'success' : 'inherit'} onClick={() => toggleDay(day)} >{day}</Button>)}</ButtonGroup>
            <ButtonGroup>{g2.map(day => <Button sx={{ width: '2rem' }} key={day} variant="contained" color={selectedDays.includes(day) ? 'success' : 'inherit'} onClick={() => toggleDay(day)} >{day}</Button>)}</ButtonGroup>
            <ButtonGroup>{g3.map(day => <Button sx={{ width: '2rem' }} key={day} variant="contained" color={selectedDays.includes(day) ? 'success' : 'inherit'} onClick={() => toggleDay(day)} >{day}</Button>)}</ButtonGroup>
            <ButtonGroup>{g4.map(day => <Button sx={{ width: '2rem' }} key={day} variant="contained" color={selectedDays.includes(day) ? 'success' : 'inherit'} onClick={() => toggleDay(day)} >{day}</Button>)}</ButtonGroup>
            <ButtonGroup>{g5.map(day => <Button sx={{ width: '2rem' }} key={day} variant="contained" color={selectedDays.includes(day) ? 'success' : 'inherit'} onClick={() => toggleDay(day)} >{day}</Button>)}</ButtonGroup>
        </Box>
        </Box>
        </LabeledBox>
}