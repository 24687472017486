import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetParentOnTicketMutationVariables = Types.Exact<{
  childId: Types.Scalars['Int']['input'];
  parentId: Types.Scalars['Int']['input'];
}>;


export type SetParentOnTicketMutation = { __typename?: 'Mutation', updateAutotaskTickets: { __typename?: 'AutotaskTickets', id?: number | null, ParentTicket?: { __typename?: 'AutotaskTickets', id?: number | null } | null } };


export const SetParentOnTicketDocument = gql`
    mutation SetParentOnTicket($childId: Int!, $parentId: Int!) {
  updateAutotaskTickets(id: $childId, parentTicketId: $parentId) {
    id
    ParentTicket {
      id
    }
  }
}
    `;
export type SetParentOnTicketMutationFn = Apollo.MutationFunction<SetParentOnTicketMutation, SetParentOnTicketMutationVariables>;

/**
 * __useSetParentOnTicketMutation__
 *
 * To run a mutation, you first call `useSetParentOnTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetParentOnTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setParentOnTicketMutation, { data, loading, error }] = useSetParentOnTicketMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useSetParentOnTicketMutation(baseOptions?: Apollo.MutationHookOptions<SetParentOnTicketMutation, SetParentOnTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetParentOnTicketMutation, SetParentOnTicketMutationVariables>(SetParentOnTicketDocument, options);
      }
export type SetParentOnTicketMutationHookResult = ReturnType<typeof useSetParentOnTicketMutation>;
export type SetParentOnTicketMutationResult = Apollo.MutationResult<SetParentOnTicketMutation>;
export type SetParentOnTicketMutationOptions = Apollo.BaseMutationOptions<SetParentOnTicketMutation, SetParentOnTicketMutationVariables>;