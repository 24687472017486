import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TicketSearchTabQueryVariables = Types.Exact<{
  coreCompanyId?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  autotaskContactId?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  status?: Types.InputMaybe<Types.Scalars['Float']['input']>;
}>;


export type TicketSearchTabQuery = { __typename?: 'Query', findAutotaskTicketsPaginated: { __typename?: 'PaginatedAutotaskTickets', totalCount: number, page: number, limit: number, data?: Array<{ __typename?: 'AutotaskTickets', id?: number | null, aisummary?: string | null, title: string, description?: string | null, status: number, priority?: number | null, ticketNumber: string, autotaskTicketStatus?: { __typename?: 'AutotaskTicketStatuses', name: string } | null }> | null } };


export const TicketSearchTabDocument = gql`
    query TicketSearchTab($coreCompanyId: Float, $autotaskContactId: Float, $page: Int, $search: String, $limit: Int, $status: Float) {
  findAutotaskTicketsPaginated(
    contactID: {eq: $autotaskContactId}
    coreCompanyId: {eq: $coreCompanyId}
    status: {eq: $status, not: 5}
    archived: {eq: false}
    limit: $limit
    page: $page
    search: $search
  ) {
    data {
      id
      aisummary
      title
      description
      status
      priority
      ticketNumber
      autotaskTicketStatus {
        name
      }
    }
    totalCount
    page
    limit
  }
}
    `;

/**
 * __useTicketSearchTabQuery__
 *
 * To run a query within a React component, call `useTicketSearchTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketSearchTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketSearchTabQuery({
 *   variables: {
 *      coreCompanyId: // value for 'coreCompanyId'
 *      autotaskContactId: // value for 'autotaskContactId'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useTicketSearchTabQuery(baseOptions?: Apollo.QueryHookOptions<TicketSearchTabQuery, TicketSearchTabQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TicketSearchTabQuery, TicketSearchTabQueryVariables>(TicketSearchTabDocument, options);
      }
export function useTicketSearchTabLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketSearchTabQuery, TicketSearchTabQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TicketSearchTabQuery, TicketSearchTabQueryVariables>(TicketSearchTabDocument, options);
        }
export function useTicketSearchTabSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TicketSearchTabQuery, TicketSearchTabQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TicketSearchTabQuery, TicketSearchTabQueryVariables>(TicketSearchTabDocument, options);
        }
export type TicketSearchTabQueryHookResult = ReturnType<typeof useTicketSearchTabQuery>;
export type TicketSearchTabLazyQueryHookResult = ReturnType<typeof useTicketSearchTabLazyQuery>;
export type TicketSearchTabSuspenseQueryHookResult = ReturnType<typeof useTicketSearchTabSuspenseQuery>;
export type TicketSearchTabQueryResult = Apollo.QueryResult<TicketSearchTabQuery, TicketSearchTabQueryVariables>;