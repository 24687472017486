import { z } from 'zod';

export const RocketcyberIncidentsSchema = z.object({
  id: z.number(),
  title: z.string().max(255),
  description: z.string(),
  remediation: z.string(),
  resolvedAt: z.date().nullable(),
  publishedAt: z.date().nullable(),
  createdAt: z.date(),
  status: z.string().max(50),
  accountId: z.number(),
  eventCount: z.number(),
  autotaskTicketId: z.number().nullable(),
});