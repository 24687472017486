import { Dialog, DialogTitle, DialogContent, Typography, Box, Grid, TextField, DialogActions, Button, Alert } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { QueueTicket } from "../../types/ticketqueue"
import { useApi } from "../../providers/Api"
import { Company } from "../TicketPartials/Company"
import { StyledTable } from "../Table"
import { FormControlLabel, Switch } from "@mui/material"
import { MarkdownView } from "../../MarkdownView"

export const MergeTicketWizard : FC<{
    fromTicketId: number
    onClose: () => void
    onMerge: (toTicketId: number) => void
    open: boolean
}> = ({ fromTicketId, onClose, onMerge, open }) => {
    const api = useApi();
    const [ fromTicket , setFromTicket ] = useState<QueueTicket | null>(null);
    const [ toTicket , setToTicket ] = useState<QueueTicket | null>(null);
    const [ companyId, setCompanyId ] = useState<number | null>(null);
    const [ tickets, setTickets ] = useState<QueueTicket[]>([]);
    const [ search , setSearch ] = useState<string>('');
    const [ allTickets, setAllTickets ] = useState<boolean>(false);
    
    useEffect(() => {
        api.get<QueueTicket>(`/ticketqueue/ticket/${fromTicketId}`).then(({ data }) => setFromTicket(data))
    }, [fromTicketId])
    useEffect(() => {
        if (fromTicket) {
            setCompanyId(fromTicket.companyID)
        }
    }, [fromTicket?.companyID]);

    useEffect( () => {
        if( companyId === null ){
            return;
        }
        api.get<QueueTicket[]>(`/ticketqueue/tickets`, { 'id:not': fromTicketId, companyID: companyId, search, allTickets: allTickets, page: 0, limit: 20  }).then(({ data }) => setTickets(data))
    }, [companyId, search, allTickets]);

    const mergeTicket = () => {
        if( !toTicket ){
            return;
        }
        if( !fromTicketId ){
            return;
        }
        api.post(`/core/tickets/merge`, { from: fromTicketId, to: toTicket?.id ?? 0 }).then(() => onMerge(toTicket?.id ?? 0))
    }
    return <Dialog maxWidth="lg" open={open} onClose={onClose}>
        <DialogTitle>Merge Ticket</DialogTitle>
        <DialogContent sx={{minWidth: '900px' , maxHeight: '80vh' , overflowY: 'auto'}}>
            { !toTicket && <>
            <Grid container spacing={2}>
                <Grid item xs={6} sx={{mt: 2}}>
                    <Company companyID={companyId || undefined} edit={true} onChange={(company) => setCompanyId(company?.id ?? null)} />
                </Grid>
                <Grid item xs={6} sx={{mt: 2}}>
                    <FormControlLabel label="All Tickets" control={<Switch checked={allTickets} onChange={(e) => setAllTickets(e.target.checked)} />} />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth label="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
                </Grid>
            </Grid>
            <StyledTable onClick={(row) => setToTicket(row)} rows={tickets} columns={[
                  {
                    field: 'ticketNumber',
                    flex: 1,
                    sortable: true,
                    headerName: 'Ticket Number'
                    
                },{
                    field: 'title',
                    flex: 1,
                    sortable: true,
                    headerName: 'Title'
                    
                },
                {
                    field: 'status',
                    flex: 1,
                    sortable: true,
                    headerName: 'Status'
                    
                }
            ]} />
            </>}
            { toTicket && <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert severity="warning">Do you really want to merge ticket {toTicket.ticketNumber} into ticket {fromTicket?.ticketNumber}? This can not be un done</Alert>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6">From Ticket</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6">To Ticket</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {fromTicket?.title}
                    </Grid>
                    <Grid item xs={6}>
                        {toTicket.title}
                    </Grid>
                    <Grid item xs={6}>
                        <MarkdownView>{fromTicket?.description}</MarkdownView>
                    </Grid>
                    <Grid item xs={6}>
                        <MarkdownView>{toTicket.description}</MarkdownView>
                    </Grid>
                </Grid>
            </>}
        </DialogContent>
        <DialogActions>
            { toTicket && <>
            <Button onClick={() => setToTicket(null)}>Back</Button>
            <Button color="error" variant="contained" onClick={() => mergeTicket()}>Merge</Button>
            </>}
        </DialogActions>
    </Dialog>
}