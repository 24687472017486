import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetChildTicketsQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetChildTicketsQuery = { __typename?: 'Query', getAutotaskTicketsById: { __typename?: 'AutotaskTickets', id?: number | null, ChildTickets?: Array<{ __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, title: string }> | null } };


export const GetChildTicketsDocument = gql`
    query GetChildTickets($id: Int!) {
  getAutotaskTicketsById(id: $id) {
    id
    ChildTickets {
      id
      ticketNumber
      title
    }
  }
}
    `;

/**
 * __useGetChildTicketsQuery__
 *
 * To run a query within a React component, call `useGetChildTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChildTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChildTicketsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChildTicketsQuery(baseOptions: Apollo.QueryHookOptions<GetChildTicketsQuery, GetChildTicketsQueryVariables> & ({ variables: GetChildTicketsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChildTicketsQuery, GetChildTicketsQueryVariables>(GetChildTicketsDocument, options);
      }
export function useGetChildTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChildTicketsQuery, GetChildTicketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChildTicketsQuery, GetChildTicketsQueryVariables>(GetChildTicketsDocument, options);
        }
export function useGetChildTicketsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetChildTicketsQuery, GetChildTicketsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetChildTicketsQuery, GetChildTicketsQueryVariables>(GetChildTicketsDocument, options);
        }
export type GetChildTicketsQueryHookResult = ReturnType<typeof useGetChildTicketsQuery>;
export type GetChildTicketsLazyQueryHookResult = ReturnType<typeof useGetChildTicketsLazyQuery>;
export type GetChildTicketsSuspenseQueryHookResult = ReturnType<typeof useGetChildTicketsSuspenseQuery>;
export type GetChildTicketsQueryResult = Apollo.QueryResult<GetChildTicketsQuery, GetChildTicketsQueryVariables>;