import { Alert, Box, Switch } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { TicketApiProvider, useTicketApi } from "../../react-submodules/providers/TicketApiProvider";
import { useNavigate, useParams } from "react-router-dom";

import { CreateTicket } from "../../layouts/tools/CreateTicket";
import { QueueTicket } from "../../react-submodules/types/ticketqueue";
import { TicketFullCard } from "../../react-submodules/components/TicketFullCard/TicketFullCard";
import { TicketSidepanel } from "./TicketSidepanel";
import { TimeclockProvider } from "../../react-submodules/providers/Timeclock";
import { useApi } from "../../react-submodules/providers/Api/Api";
import { useLayout } from "../../react-submodules/providers/LayoutProvider";
import { useQueueParams } from "../../react-submodules/hooks/useQueueParams";
import { useMounted } from "../../react-submodules/hooks/useMounted";

export const TicketQueue = () => {
    const api = useApi();
    
    const { queueId , ticketId , changeTicket } = useQueueParams();

    const [ selectedTicket , setSelectedTicket ] = useState< QueueTicket | null >( null );
    
    const [ refreshNextTicket , setRefreshNextTicket ] = useState(0);

    const nextTicket =  () => {
        // If we have not gotten the selected ticket yet, we should not try to get the next ticket
        if( selectedTicket === null && ticketId ) return;
        setRefreshNextTicket( pv => {
            if( pv === 0 ){
                return 1;
            }
            return pv * -1 
        } );
    }
    
    useEffect( () => {
        if( !ticketId ) {
            setSelectedTicket( null );
            return;
        };
        api.get<QueueTicket>(`/ticketqueue/ticket/${ticketId}`).then( ( { data }) => {
            setSelectedTicket( data );
        });
    }, [ ticketId ]);
    useEffect( () => {
        let loaded = true;
        if( api.ready === false ) return;
            api.get<QueueTicket>(`/ticketqueue/queue/${queueId}/nextTicket`).then( ( { data }) => {
                if( loaded && ( !ticketId || refreshNextTicket !== 0 ) && data?.id ){
                    changeTicket(`${data.id}`);
                }
            })
        return () => {
            loaded = false;
        }
    }, [ refreshNextTicket , api.ready  ])
    
    return <TicketApiProvider queueId={queueId} getNextTicket={() => {
        nextTicket();
    }}><TimeclockProvider><TicketQueueInner selectedTicket={selectedTicket} /></TimeclockProvider></TicketApiProvider>
}



export const TicketQueueInner : FC<{ selectedTicket : QueueTicket | null ,  }> = ({selectedTicket}) => {
    const { getNextTicket , nextTicket , firstLoad   } = useTicketApi();
    const [ loading , setLoading ] = useState( false );
    const mounted = useMounted();
    
    
    useEffect( () => {
        if( !selectedTicket && nextTicket ){
            getNextTicket( true );
        }
    } , [ JSON.stringify(selectedTicket), JSON.stringify( nextTicket) ])
    const { addButton , removeButton } = useLayout();

    
    useEffect( () => {
        addButton( {            
            key: 'pauseQueue',
            button_type: 'active_queue_toggle'
        });
        addButton( {            
            key: 'user_roles',
            button_type: 'user_roles'
        });
        addButton( {            
            key: 'my_questions',
            button_type: 'my_questions'
        });
        addButton( {            
            key: 'ticket_search',
            button_type: 'ticket_search'
        });
        return () => {
            removeButton( 'pauseQueue' );
            removeButton( 'user_roles' );
            removeButton( 'my_questions' );
            removeButton( 'ticket_search' );
        }
    }, [ selectedTicket  ]);

    useEffect(() => {
        setLoading( true );
        setTimeout( () => {
            if( mounted() ){
                setLoading( false );
            }
        } , 200 );
    }, [
        selectedTicket?.id || false
    ])


    return <>
            <CreateTicket />
            {!loading && <>
            <Box sx={{display: 'flex'  , flexDirection: 'row' }}>
            { !selectedTicket && <>
                
                { ( !firstLoad && !nextTicket ) ? <Box >
                    
                <Alert color="success">It looks like all the tickets are handled, I will let you know if more come in</Alert>
                <Box component="img" title="Happy Image" src="/happy.png" sx={{maxWidth: '800px', borderRadius: '20px'}} />
                </Box> : <Box>
                    <Alert color="info">Please wait while we check for tickets</Alert>
                </Box> }
            </>}
        <Box flexGrow={1} sx={{padding: 0.5 , height: 'calc( 100vh  - 120px )' }}>
            { selectedTicket && <TicketSidepanel ticket={selectedTicket} /> }
        </Box>
        <Box flexGrow={1} sx={{padding: 0.5 , height: 'calc( 100vh  - 120px )' }}>
            { selectedTicket && <TicketFullCard ticket={selectedTicket} />}
        </Box>
        </Box>
        </>}
    </>
}