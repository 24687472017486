import { Alert, Button, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, ListSubheader, MenuItem, Switch, TextField, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { TodoType, todoTypes } from "./NewTodoDialog";
import { MarkdownEditor } from "../MarkdownEditor/MarkdownEditor";
import { useTicketApi } from "../../providers/TicketApiProvider";

export const NewQuestion : FC<{
    submit : () => any,
    title : string,
    setTitle : (title : string) => any,
    description : string,
    setDescription : (description : string) => any,
    type : TodoType,
    setType : (type : TodoType) => any,
    dueDate : string | null,
    setDueDate : (dueDate : string | null) => any,
    ticketBlocking : boolean,
    setTicketBlocking : (ticketBlocking : boolean) => any,
    groupId : number | null,
    setGroup : (groupId : number | null) => any,
    userId : string | null,
    setUser : (userId : string | null) => any
}> = ({ submit , title , setTitle , description , setDescription , type , setType , dueDate , setDueDate , ticketBlocking , setTicketBlocking , groupId , setGroup , userId , setUser }) => {
    const { groups , users } = useTicketApi();
    const assignableGroups = useMemo( () => {
        return groups.map( g => {
            return {                
                value : `group:${g.id}`,
                text : g.name

            }
        });
    } , [JSON.stringify( groups ) ]);

    const assignableUsers = useMemo( () => {
        return users.map( u => ({
            value : `user:${u.id}`,
            text : `${u.firstName} ${u.lastName}`
        }));
    } , [JSON.stringify( users )]);
    const assignedTo = useMemo( () => {
        if( userId){
            return `user:${userId}`;
        }
        if( groupId){
            return `group:${groupId}`;
        }
        return null;
    } , [groupId , userId ]);

    const setAssignedTo = (assignedTo : string | null) => {
        if( assignedTo && assignedTo.startsWith("user:")){
            setUser( assignedTo.split(":")[1] );
            setGroup( null);
            return;
        }
        if( assignedTo && assignedTo.startsWith("group:")){
            setGroup( parseInt( assignedTo.split(":")[1] ) || null );
            setUser( null);
            return;
        }
        setUser( null);
        setGroup( null);
    }

    return <>
    
    <DialogTitle>New Question</DialogTitle>
        <DialogContent sx={{
            width: '800px'
        
        }}>
            
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <TextField error={title === ""} sx={{marginTop: 1}} required fullWidth size="small" label="Title" value={title} 
                        onChange={(e)=>setTitle(e.target.value)} />
                </Grid>
                <Grid item sm={6}>
                    <TextField error={!assignedTo} required sx={{marginTop: 1 , marginBottom: 1}} fullWidth size="small"  label="Assigned To" value={assignedTo} select
                        onChange={(e)=>setAssignedTo(  `${e.target.value}` || null )} >
                        <ListSubheader>Groups</ListSubheader>
                        {assignableGroups.map( t => <MenuItem sx={{marginLeft: 2}} key={t.value} value={t.value}>{t.text}</MenuItem>)}
                        <ListSubheader>Users</ListSubheader>
                        {assignableUsers.map( t => <MenuItem sx={{marginLeft: 2}} key={t.value} value={t.value}>{t.text}</MenuItem>)}
                    </TextField>
                </Grid>
                <Grid item sm={6}>
                    <FormControlLabel
                        control={
                            <Switch disabled={type === "task"} color="success" checked={ticketBlocking} onChange={(e)=>{
                                setTicketBlocking( e.target.checked );
                                }} />
                        }
                        label="Ticket Blocking"
                    />
                </Grid>
                
                <Grid item sm={12}>
                    <MarkdownEditor markdown={description} onChange={setDescription} />
                </Grid>
            </Grid>
            { ticketBlocking && <Alert color="warning">This is ticket blocking, it will not show back up in the queue as workable until its answered ( or a specified amount of time passes) </Alert> }
            { dueDate && <Alert color="info">This todo will not use the Ticket's Next Action Date ( Clear the date to use the Tickets NAD )</Alert> }
            <Button onClick={()=>{submit();}} color="success"  variant="contained" size="small" disabled={( !groupId && !userId ) || title === ""}>Create Todo</Button>
        </DialogContent>
    </>
}

export default NewQuestion;

