import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";

type TButtonTypes = 'active_queue_toggle' | 'user_roles' | 'my_questions' | 'ticket_search';


type LayoutButtonProps = {
    key: string;
    label?: string;
    onClick?: () => any;
    button_type?: TButtonTypes
}
type LayoutButtonTrue = {
    key: string;
    label: string;
    onClick: () => any;
}

type LayoutButtonRaw = {
    key: string;
    button_type: TButtonTypes
}


export type LayoutButton = LayoutButtonProps & (LayoutButtonTrue | LayoutButtonRaw);
interface LayoutProvider {
    buttons: LayoutButton[];
    addButton: ( button: LayoutButton ) => any;
    removeButton: ( key: string ) => any;
}
const LayoutContext = createContext<LayoutProvider>({
    buttons: [],
    addButton: () => {},
    removeButton: () => {}
});
export const useLayout = () => useContext(LayoutContext);

export const LayoutProvider :React.FC<PropsWithChildren > = ( { children}) => {
    const [ buttons , setButtons ] = useState<LayoutProvider['buttons']>([]);
    const addButton = ( button: LayoutButton ) => {
        setButtons( ( buttons ) => {
            return [
                ...buttons,
                button
            ]
        })
    }
    const removeButton = ( key: string ) => {
        setButtons( ( buttons ) => {
            return buttons.filter( ( button ) => button.key !== key )
        })
    }

    return (
        <LayoutContext.Provider value={{
            buttons ,addButton , removeButton
        }}>{children}</LayoutContext.Provider>
    )
}