import { Box, Button, DialogActions, DialogContentText, Typography } from "@mui/material";
import { DialogContent } from "@mui/material";
import { DialogTitle } from "@mui/material";
import { Dialog } from "@mui/material";
import { ActiveMessageChannel } from "./types";
import { useUpdateCoreMessageChannelMutation } from "./gql/UpdateMessageChannel.generated";
import { CoreMessageChannelResult } from "../../generated/graphql";
import { TicketView } from "../../react-submodules/components/TicketView/TicketView";

export const TransferTicket = ({ messageChannel , refresh }: { messageChannel: ActiveMessageChannel , refresh : () => any}) => {
    const [updateMessageChannel] = useUpdateCoreMessageChannelMutation({
        onCompleted: () => {
            refresh();
        }
    });
    const fromUser = messageChannel.CreatedByUser;
    
    return <>
    <Dialog maxWidth="lg" open={true} onClose={() => {}}>
        <DialogTitle>Ticket {messageChannel?.AutotaskTicket?.ticketNumber} is being transferred</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {`${fromUser?.firstName} ${fromUser?.lastName} is transferring ticket ${messageChannel?.AutotaskTicket?.ticketNumber} to you.`}
            </DialogContentText>
            <TicketView id={messageChannel?.AutotaskTicket?.id || 0} />
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="error" onClick={() => {
                updateMessageChannel({
                    variables: {
                        id: messageChannel.id || 0,
                        result: CoreMessageChannelResult.Rejected
                    }
                });
            }}>Decline</Button>
            <Button onAuxClick={()=>{
                updateMessageChannel({
                    variables: {
                        id: messageChannel.id || 0,
                        result: CoreMessageChannelResult.Accepted
                    }
                }).then(() => {
                    window.open(`/ticketqueue/all/${messageChannel?.AutotaskTicket?.id}` , `ticket_${messageChannel?.AutotaskTicket?.id}`);
                });
            }} variant="contained" color="success" onClick={() => {
                updateMessageChannel({
                    variables: {
                        id: messageChannel.id || 0,
                        result: CoreMessageChannelResult.Accepted
                    }
                }).then(() => {
                    window.location.href = `/ticketqueue/all/${messageChannel?.AutotaskTicket?.id}`;
                });
            }}>Accept</Button>
        </DialogActions>
    </Dialog>
    </>;
}