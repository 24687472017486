
import { InlineIcon } from "@iconify/react";
import {useTheme, Button, ButtonProps, ListItemIcon, ListItemText, Menu, MenuItem, Theme } from "@mui/material"
import { FC, useState } from "react";


export type MenuItemProps = {
    label: string
    onClick: () => void,
    icon?: string;
    iconColor?: string;
}

const getColor = (color: string , theme : Theme) => {
    
    switch( color ){
        case 'primary':
            return theme.palette.primary.main;
        case 'secondary':
            return theme.palette.secondary.main;
        case 'error':
            return theme.palette.error.main;
        case 'warning':
            return theme.palette.warning.main;
        case 'info':
            return theme.palette.info.main;
        case 'success':
            return theme.palette.success.main;
        case 'text':
            return theme.palette.text.primary;
        default:
            return color;
    }
}


export const MenuSelect : FC<{
    icon?: string
    title?: string    
    menuItems: MenuItemProps[]
} & Omit<ButtonProps, 'onClick'> > = ({icon, title, menuItems , ...props}) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return <>
        <div>
      <Button
        startIcon={icon ? <InlineIcon icon={icon} /> : undefined}
        variant="contained"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        {...props}
      >{title}</Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuItems.map((item, index) => (
            <MenuItem key={index} onClick={()=>{
                item.onClick();
                handleClose();
             }}>{item.icon ? <ListItemIcon sx={{
                color: item.iconColor ? getColor(item.iconColor , theme) : undefined
             }}><InlineIcon icon={item.icon} /></ListItemIcon> : null}<ListItemText>{item.label}</ListItemText></MenuItem>
        ))}
      </Menu>
    </div>
    </>
}

