import { Box, Button, Container, Paper, TextField } from "@mui/material";
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { StyledTable, TableColumnDef } from "../../react-submodules/components/Table/Table";

import { Icon } from "@iconify/react";
import LoopIcon from '@mui/icons-material/Loop';
import { SyncEnabledToggle } from "./SyncEnabledToggle";
import { SyncIntervalButton } from "./SyncIntervalButton";
import { TCoreDataSync } from "../../datatypes/CoreDataSync";
import { useApi } from "../../react-submodules/providers/Api/Api"
import { useDataQuery } from "../../react-submodules/hooks/useDataQuery";
import { useQueueParams } from "../../react-submodules/hooks/useQueueParams";
import { useSearchParam } from "../../react-submodules/utils/reactrouter";

const SettingsButton : FunctionComponent<{
    row: any
}>= ( { row } ) => {
    const api = useApi();
    
    const [ syncing , setSyncing ] = useState( row.syncing)
    useEffect( () => {
        if( syncing !== row.syncing ){
            setSyncing( row.syncing )
        }
    }, [ row.syncing ] )

    const sync = useCallback( async () => {
        setSyncing( true );
        await api.get( `/core/sync/${row.key}` );
        setSyncing( false );
    } , [api.ready , api ]);
    return <Button disabled={syncing} onClick={()=>{
        sync()
    } } variant="contained">{ syncing && <LoopIcon sx={{
        animation: "spin 2s linear infinite",
        "@keyframes spin": {
          "0%": {
            transform: "rotate(360deg)",
          },
          "100%": {
            transform: "rotate(0deg)",
          },
        },
      }}><Icon icon="material-symbols:sync-outline"  /></LoopIcon> } {syncing ? "Syncing" : "Sync"} {row.label}</Button>
}

const columns :  TableColumnDef<TCoreDataSync>[] = [
    {
        field: 'name',
        flex: 1, 
        headerName: 'Endpoint',
        
    },
    {
        field: 'lastSync',
        flex: 1, 
        headerName: 'Last Sync',
        Element: ( { cell , row } ) => {
            return <>{cell ? new Date( cell ).toLocaleString() : 'Never'}</>
        }
    },
    {
        field: 'syncIntervalMs',
        flex: 1,
        headerName: 'Sync Interval',
        Element: ( { cell , row  , refresh} ) => {
            return <SyncIntervalButton cell={cell} row={row} refresh={()=>{
                refresh && refresh();
            }} />
        }   
    }
    , {
        field: 'key',
        flex: 1,
        headerName: ' ',
        Element: ( { cell , row } ) => {
            return <SettingsButton row={row} />
        }
    },
    {
        field: 'syncEnabled',
        flex: 1,
        headerName: 'Enabled',
        Element: ( { cell , row , refresh } ) => {
            return <SyncEnabledToggle cell={cell} row={row} refresh={refresh} />
        }
    }
];

export const Settings = () => {
    const { data , refresh } = useDataQuery<TCoreDataSync>( `/core/datasyncs` );
    const [ search , setSearch ] = useSearchParam<string>( "search" );
    useEffect( () => {
        const interval = setInterval( () => {
            refresh();
        }, 3000 )
        return () => {
            clearInterval( interval );
        }
    });
    const useableData = useMemo( () => {
        return data.filter( d => d.name.toLowerCase().includes( (search || "").toLowerCase() ) );
    } , [ data , search ] );
    return <Container>
        <h1>Sync Settings</h1>

        <Paper>
            <Box sx={{padding: 1}}><TextField fullWidth size="small" value={search} onChange={(e)=>{setSearch(e.target.value)}} label="Search" /></Box>
            <StyledTable refresh={()=>{refresh()}} indexField='key' rows={useableData} columns={columns} />
        </Paper>
    </Container>
}