import { useEffect, useState } from "react";
import { useApi } from "../react-submodules/providers/Api/Api";
import { StyledTable, TableColumnDef } from "../react-submodules/components/Table";
import { useNavigate } from "react-router-dom";

type QueueData = {
    name: string;
    key: string;
    queueName: string;
    queryDescription: string;
    technical: boolean;
};
const columns : Array<TableColumnDef<QueueData>> = [
    {
        field: 'name',
    } 
]

export const TicketQueues = () => {
    const api = useApi();
    const [queues , setQueues] = useState([]);
    const nav = useNavigate();
    useEffect( () => { 
        if( api.ready === false ) return;
        api.get('/ticketqueue/queues').then( ( { data }) => {
            setQueues( data );
        })
     } , [ api.ready]);
    return <><StyledTable<QueueData> rows={queues} columns={columns} onClick={(row)=>{
        nav(`/ticketqueue/${row.key}`);
    }} /></>
}