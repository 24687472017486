import { BeEdge, BeNode } from "../react-submodules/types/recipes";
import { Edge, Node } from "reactflow";

const processData = (node: BeNode): Record<string,any> => {
    const data : Record<string,any> = node.data || {};
    if( node.type === "todo" && !data.completeOptions ){
        data.completeOptions = [];
    }
    return data
  }
  export const beNodetoReactFlowNode = (beNode: BeNode): Node => {
    return {
      id: beNode.id,
      type: beNode.type || 'unknown',
      position: { x: beNode.positionX, y: beNode.positionY },
      data: processData(beNode ),
      dragHandle: '.drag-handle'
    };
  }

  export const beEdgeToReactFlowEdge = (beEdge: BeEdge) : Edge => {
    return {
      id: beEdge.id,
      source: beEdge.sourceNodeId,
      sourceHandle: beEdge.sourceHandle,
      target: beEdge.targetNodeId,
      targetHandle: beEdge.targetHandle,
      type: beEdge.type,
      data: beEdge.data,
    };
  }


  export const reactFlowNodeToBeNode = (node: Node, recipeId: number): BeNode => {
    return {
      id: node.id,
      recipeId,
      title: node.data.title,
      type: node.type || null,
      positionX: node.position.x,
      positionY: node.position.y,
      data: node.data,
    };
  }

  export const reactFlowNodeToBeEdge = (node: Edge, recipeId: number): BeEdge => {
    return {
        id: node.id,
        recipeId,
        title: node?.data?.title || '',
        type: node.type || 'smoothstep',
        sourceNodeId: node.source,
        sourceHandle: node.sourceHandle || '',
        targetNodeId: node.target,
        targetHandle: node.targetHandle || '',
        data: node.data,
    };
  }