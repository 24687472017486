import { useMemo, useState } from "react";
import { useGetTicketsForHealthQuery } from "./GetTicketsForHealth.generated";
import { DateTime } from "luxon";
import { PieChart } from "@mui/x-charts";
import { StyledTable } from "../../../../react-submodules/components/Table";
import { Button, Grid } from "@mui/material";
import { TotalTicketGraph } from "../../../TechOverview/TotalTicketGraph";
const ignoreTicketQueues : number[] = [ 0, 29683483, 29683487, 29683491, 29683488 ];

export const TicketHealth = () => {
    const { data } = useGetTicketsForHealthQuery();
    const [ ticketFilter , setTicketFilter ] = useState<string | null>( null );
    const today = DateTime.now().startOf('day');
    const goodData = useMemo(() => {
        return (data?.findAutotaskTicketsPaginated?.data?.filter(d => !d.archived ).filter(d => !ignoreTicketQueues.includes(d.autotaskTicketQueue?.id ?? 0)) ?? [] ).map( d => {
            const nextActionDate = d.nextActionDate ? DateTime.fromISO(d.nextActionDate).plus({ days: 2 }) : null;
            let nandStatus = 'unset';
            if (nextActionDate) {
                const diffInDays = today.diff(nextActionDate, 'days').days;
                if (diffInDays <= 0) {
                    nandStatus = 'good';
                } else if (diffInDays === 1) {
                    nandStatus = '1dayold';
                } else if (diffInDays <= 3) {
                    nandStatus = '2to3daysold';
                } else {
                    nandStatus = 'over3daysold';
                }
            }
            return {
                ...d,
                nandStatus,
                nextActionDate : nextActionDate?.toFormat('yyyy-MM-dd')
            }
        });
    }, [data]);

    const graphData = useMemo(() => {

        // Initialize counters
        const counts = {
            good: 0,
            '1dayold': 0,
            '2to3daysold': 0,
            'over3daysold': 0,
            'unset': 0
        };

        // Count tickets in each category
        goodData.forEach((d) => {
            // @ts-ignore
            counts[d.nandStatus]++;
            
        });

        // Transform to desired output format
        return [
            { id: 'good', value: counts.good, label: 'Good' },
            { id: '1dayold', value: counts['1dayold'], label: 'One day old' },
            { id: '2to3daysold', value: counts['2to3daysold'], label: 'Two to three days old' },
            { id: 'over3daysold', value: counts.over3daysold, label: 'Over three days old' },
            { id: 'unset', value: counts.unset, label: 'Unset' }
        ];
    }, [goodData, today]);
    const filteredData = useMemo(() => {
        if( ticketFilter ) {
            return goodData.filter( d => d.nandStatus === ticketFilter );
        }
        return goodData;
    }, [goodData, ticketFilter]);
    return <>
    <Grid container spacing={2}>
        <Grid item md={6}>
    <PieChart
  series={[
    {
      data: graphData,
    },
  ]}
  onItemClick={(e , g , item : any ) => {
    if( item.id === ticketFilter ) {
        setTicketFilter( null );
    } else {
        setTicketFilter( item.id );
    }
  }}
  width={500}
  height={200}
/>
</Grid>
<Grid item md={6}><TotalTicketGraph width={500} height={300} /></Grid>
</Grid>
<StyledTable
    columns={[{
        field: 'ticketNumber',
        Element: ( { row }) => {
            return <Button variant="contained" onClick={() => {
                window.open(`https://enlightenedmsp.com/ticketqueue/all/${row.id}`, '_blank');
            }}>{row.ticketNumber}</Button>;
        }
    } , 
    {
        field: 'title'
    } , {
        field: 'autotaskTicketQueue.name',
        Element: ( { row }) => {
            return <>{row.autotaskTicketQueue?.name}</>;
        }
    } , 
    {
        field: 'nextActionDate',
        Element: ( { row }) => {
            if( !row.nextActionDate ) {
                return <>Unset</>;
            }
            return <>{row.nextActionDate}</>;
        }
    } , 
    {
        field: 'archived'
    }]}
    rows={filteredData}
/>
</>;
}