import { MenuItem, TextField } from "@mui/material";
import { FC, useMemo } from "react";
import { useCoreUsersQuery } from "./gql/CoreUsers.generated";
import { BadgeAndName } from "../../../components/TicketPartials/BadgeAndName";
import CircularProgress from "@mui/material/CircularProgress";

type WithResource = {
  assignedUserId?: string | null;
}

export const GqlTechMenu = <T extends WithResource>({
  ticket,
  edit,
  onChange,
}: {
  ticket: T;
  edit?: boolean;
  onChange?: (current: T | ((prev: T) => T)) => void;
}) => {
  const { data, loading: loadingResources, error } = useCoreUsersQuery({
    fetchPolicy: 'cache-and-network'
  });
  const loading = loadingResources && (data?.findCoreUsersPaginated.data || []).length === 0;
  
  const resources = data?.findCoreUsersPaginated.data || [];
  
  const mappedResources = useMemo(() => {
    return resources
      .filter(r => r.autotaskId) // Filter out resources without an autotaskId
      .map(r => ({
        ...r,
        label: `${r.firstName || ''} ${r.lastName || ''}`,
        value: r.id
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [resources]);
  
  const selectedResource = useMemo(() => {
    return resources.find(r => r.id === ticket.assignedUserId);
  }, [resources, ticket.assignedUserId]);
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Convert empty string to null, otherwise convert to number
    const userId = value === "" ? null : value;
    
    if (onChange) {
      onChange(current => ({
        ...current,
        assignedUserId: userId
      }));
    }
  };
  
  if (loading) {
    return <CircularProgress size={24} />;
  }
  
  if (error) {
    return <div>Error loading resources</div>;
  }
  
  if (!edit) {
    return selectedResource ? (
      <BadgeAndName 
        displayName={`${selectedResource.firstName || ''} ${selectedResource.lastName || ''}`} 
        colorkey={selectedResource.email || undefined} 
      />
    ) : null;
  }
  
  // Convert the assignedUserId to a string for the TextField value
  const textFieldValue = ticket.assignedUserId !== null && 
                         ticket.assignedUserId !== undefined ? 
                         String(ticket.assignedUserId) : '';
  
  return (
    <TextField 
      label="Tech" 
      size="small" 
      fullWidth 
      select 
      value={textFieldValue} 
      onChange={handleChange}
    >
      <MenuItem value="">Unassigned</MenuItem>
      {mappedResources.map((resource) => {
        // Ensure resource.id is defined and convert to string
        const resourceId = resource.id ? String(resource.id) : '';
        return (
          <MenuItem key={resource.id} value={resourceId}>
            <BadgeAndName 
              displayName={`${resource.firstName || ''} ${resource.lastName || ''}`} 
              colorkey={resource.email || undefined} 
            />
          </MenuItem>
        );
      })}
    </TextField>
  );
}; 