import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateScheduleInstanceMutationVariables = Types.Exact<{
  available: Types.Scalars['Boolean']['input'];
  startDateTime?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  endDateTime?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  scheduleInstanceDay?: Types.InputMaybe<Types.Scalars['String']['input']>;
  overidePlan?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  coreUserId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  title?: Types.InputMaybe<Types.Scalars['String']['input']>;
  description?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type CreateScheduleInstanceMutation = { __typename?: 'Mutation', createScheduleInstance: { __typename?: 'ScheduleInstance', id?: number | null, scheduleInstanceDay?: string | null, startDateTime?: any | null, endDateTime?: any | null, available?: boolean | null, overidePlan?: boolean | null, description?: string | null } };


export const CreateScheduleInstanceDocument = gql`
    mutation CreateScheduleInstance($available: Boolean!, $startDateTime: Date, $endDateTime: Date, $scheduleInstanceDay: String, $overidePlan: Boolean, $coreUserId: String, $title: String, $description: String) {
  createScheduleInstance(
    available: $available
    startDateTime: $startDateTime
    endDateTime: $endDateTime
    scheduleInstanceDay: $scheduleInstanceDay
    overidePlan: $overidePlan
    coreUserId: $coreUserId
    title: $title
    description: $description
  ) {
    id
    scheduleInstanceDay
    startDateTime
    endDateTime
    available
    overidePlan
    description
  }
}
    `;
export type CreateScheduleInstanceMutationFn = Apollo.MutationFunction<CreateScheduleInstanceMutation, CreateScheduleInstanceMutationVariables>;

/**
 * __useCreateScheduleInstanceMutation__
 *
 * To run a mutation, you first call `useCreateScheduleInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleInstanceMutation, { data, loading, error }] = useCreateScheduleInstanceMutation({
 *   variables: {
 *      available: // value for 'available'
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      scheduleInstanceDay: // value for 'scheduleInstanceDay'
 *      overidePlan: // value for 'overidePlan'
 *      coreUserId: // value for 'coreUserId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateScheduleInstanceMutation(baseOptions?: Apollo.MutationHookOptions<CreateScheduleInstanceMutation, CreateScheduleInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScheduleInstanceMutation, CreateScheduleInstanceMutationVariables>(CreateScheduleInstanceDocument, options);
      }
export type CreateScheduleInstanceMutationHookResult = ReturnType<typeof useCreateScheduleInstanceMutation>;
export type CreateScheduleInstanceMutationResult = Apollo.MutationResult<CreateScheduleInstanceMutation>;
export type CreateScheduleInstanceMutationOptions = Apollo.BaseMutationOptions<CreateScheduleInstanceMutation, CreateScheduleInstanceMutationVariables>;