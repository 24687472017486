import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTicketSummaryQueryVariables = Types.Exact<{
  coreCompanyId?: Types.InputMaybe<Types.NumberProp>;
  startDate?: Types.InputMaybe<Types.TimeQueryProp>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetTicketSummaryQuery = { __typename?: 'Query', findAutotaskTicketsPaginated: { __typename?: 'PaginatedAutotaskTickets', totalCount: number, page: number, limit: number, data?: Array<{ __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, status: number, aisummary?: string | null, autotaskTicketStatus?: { __typename?: 'AutotaskTicketStatuses', name: string } | null, contact?: { __typename?: 'AutotaskContacts', emailAddress: string, firstName: string, lastName: string } | null }> | null } };


export const GetTicketSummaryDocument = gql`
    query GetTicketSummary($coreCompanyId: NumberProp, $startDate: TimeQueryProp, $page: Int, $limit: Int) {
  findAutotaskTicketsPaginated(
    coreCompanyId: $coreCompanyId
    lastActivityDate: $startDate
    status: {not: 5}
    page: $page
    limit: $limit
  ) {
    data {
      id
      ticketNumber
      status
      autotaskTicketStatus {
        name
      }
      contact {
        emailAddress
        firstName
        lastName
      }
      aisummary
    }
    totalCount
    page
    limit
  }
}
    `;

/**
 * __useGetTicketSummaryQuery__
 *
 * To run a query within a React component, call `useGetTicketSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketSummaryQuery({
 *   variables: {
 *      coreCompanyId: // value for 'coreCompanyId'
 *      startDate: // value for 'startDate'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetTicketSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetTicketSummaryQuery, GetTicketSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTicketSummaryQuery, GetTicketSummaryQueryVariables>(GetTicketSummaryDocument, options);
      }
export function useGetTicketSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTicketSummaryQuery, GetTicketSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTicketSummaryQuery, GetTicketSummaryQueryVariables>(GetTicketSummaryDocument, options);
        }
export function useGetTicketSummarySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTicketSummaryQuery, GetTicketSummaryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTicketSummaryQuery, GetTicketSummaryQueryVariables>(GetTicketSummaryDocument, options);
        }
export type GetTicketSummaryQueryHookResult = ReturnType<typeof useGetTicketSummaryQuery>;
export type GetTicketSummaryLazyQueryHookResult = ReturnType<typeof useGetTicketSummaryLazyQuery>;
export type GetTicketSummarySuspenseQueryHookResult = ReturnType<typeof useGetTicketSummarySuspenseQuery>;
export type GetTicketSummaryQueryResult = Apollo.QueryResult<GetTicketSummaryQuery, GetTicketSummaryQueryVariables>;