import { useState } from "react";
import { useApi } from "../react-submodules/providers/Api/Api";
import { Button, Paper } from "@mui/material";
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
import { HtmlView } from "../react-submodules/components/HtmlView";
import { ChatBubble } from "../react-submodules/components/ChatBubble/ChatBubble";


export const Test = () => {
    const api = useApi();
    

    const handleClick = async () => {
        const response = await api.get('/azure/account/test');
        console.log( response );
    }

    return <>
        <Button onClick={ handleClick }>Test</Button>
    </>
}