import { FC, useState } from 'react';
import { InventoryLocation } from './types'
import { Button, Container, Grid, TextField } from '@mui/material';
export const LocationForm : FC<Partial<InventoryLocation> & { onSubmit : (
    input : Omit<InventoryLocation , 'id'> & Partial<InventoryLocation>
) => any }> = ( input )=>{
    const [ id , setId ] = useState<number|null>( input.id || null);
    const [ name , setName ] = useState< string>( input.name || '');
    const [ description , setDescription ] = useState< string>( input.description || '');
    return <>
    <Grid container sx={{minWidth: '500px', paddingLeft: 2 , paddingRight: 2, paddingBottom: 2}} >
        <Grid item sm={12}>
        <TextField  fullWidth label="Name" value={name} onChange={( v) => { setName( v.target.value)}} />
        </Grid><Grid item sm={12}>
        <TextField multiline minRows={3}  sx={{marginTop: 2}} fullWidth label="Description" value={description} onChange={( v) => { setDescription( v.target.value)}} />
        </Grid>
        <Grid item sm={12} sx={{marginTop: 2, textAlign: 'end'}}>
            <Button onClick={()=>{
                input.onSubmit( { id : id || undefined , name , description})
            }} variant='contained'>Submit</Button>
        </Grid>
    </Grid>
    </>
}