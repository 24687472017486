import React, { useEffect, useState, useRef } from 'react';
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, CircularProgress, Typography } from '@mui/material';
import { InlineIcon } from '@iconify/react';
import { QueuesToColor, QueuesToIcon } from '../../../components/TicketPartials/queues.utils';
import { useTicketQueuesQuery } from './gql/TicketQueues.generated';
// IMPORTANT: Run 'npm run generate' to generate the TypeScript types for this query
// Then uncomment the following import:
// import { useTicketQueuesQuery } from './gql/TicketQueues.generated';

interface QueueType {
  label: string;
  value: string;
  isActive: boolean;
  isSystem: boolean;
  sortOrder: number;
  parentValue: string;
  isDefaultValue: boolean;
}

interface GqlQueueProps {
  ticket: any;
  edit?: boolean;
  onChange?: (ticket: any) => void;
  error?: string;
  iconOnly?: boolean;
}

export const GqlQueue: React.FC<GqlQueueProps> = ({ ticket, edit = false, onChange, error, iconOnly = false }) => {
  // IMPORTANT: After running 'npm run generate', replace this temporary implementation
  // with the actual GraphQL query:
  // const { data, loading, error: queryError } = useTicketQueuesQuery();
  
  // Temporary implementation until code generation is run
  const loading = false;
  const queryError = null;
  
  // This is a temporary placeholder - the actual data will come from the GraphQL query
  // after running 'npm run generate'
  const { data: queueData } = useTicketQueuesQuery({
    fetchPolicy: 'cache-and-network'
  });
  
  const [value, setValue] = useState<string>('');
  const prevTicketIdRef = useRef<string | number | null>(null);

  useEffect(() => {
    // Only reset when ticket ID changes (different ticket loaded)
    const currentTicketId = ticket?.id;
    if (prevTicketIdRef.current !== currentTicketId) {
      // Convert queueID to string if it exists
      setValue(ticket?.queueID ? String(ticket.queueID) : '');
      prevTicketIdRef.current = currentTicketId;
    }
  }, [ticket?.queueID]);

  const handleChange = (e: SelectChangeEvent<string>) => {
    const newValue = e.target.value;
    setValue(newValue);
    
    if (onChange) {
      onChange({
        ...ticket,
        queueID: newValue === '' ? null : Number(newValue),
      });
    }
  };

  // Use all queues from the API - backend already handles filtering and sorting
  const queues = queueData?.TicketQueues || [];

  if (!edit) {
    const selectedQueue = queues.find((queue: QueueType) => queue.value === value);
    return (
      <Typography>
        <InlineIcon 
          color={QueuesToColor(selectedQueue?.label || '')} 
          icon={QueuesToIcon(selectedQueue?.label || '')} 
        />
        &nbsp;{!iconOnly && (selectedQueue?.label || 'None')}
      </Typography>
    );
  }

  if (loading) {
    return <CircularProgress size={24} />;
  }

  if (queryError) {
    return <div>Error loading queues</div>;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormControl fullWidth error={!!error}>
          <InputLabel id="queue-select-label">Queue</InputLabel>
          <Select
            labelId="queue-select-label"
            id="queue-select"
            value={value}
            onChange={handleChange}
            label="Queue"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {queues.map((queue: QueueType) => (
              <MenuItem key={queue.value} value={queue.value} sx={{display: 'flex', flexDirection: 'row'}}>
                <Typography>
                  <InlineIcon 
                    color={QueuesToColor(queue.label)} 
                    icon={QueuesToIcon(queue.label)} 
                  />
                  &nbsp;{queue.label}
                </Typography>
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      </Grid>
    </Grid>
  );
}; 