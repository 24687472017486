import { AppBar, Box, Button, Card, CardContent, CardHeader, Container, Grid, Toolbar, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"

import { AutotaskWidget } from "./AutotaskWidget";
import { AzureWidget } from "./AzureWidget";
import { ContractOverview } from "../../react-submodules/components/ContractOverview/ContractOverview";
import { DattoRmmWidget } from "./DattoRMMWidget";
import { FilesView } from "../../react-submodules/components/FilesView/FilesView";
import { ItglueWidget } from "./ItglueWidget";
import { SettingsWidget } from "./SettingsWidget";
import { useApi } from "../../react-submodules/providers/Api/Api";
import { ModuleLinks } from "./ModuleLinks";

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
}))

// https://dev.emsp.couleetech.network/company/3
export const Company = () => {
    const params = useParams<{id: string}>();
    const nav = useNavigate();
    const api = useApi();
    const [ company , setCompany ] = useState<any>( null );
    useEffect( () => {
        if( api.ready ){
            api.get( `/core/company/${params.id}` ).then( ( results ) => {
                setCompany( results.data );
            } )
        }
    } , [ params.id , api.ready ])
    return <Container>
        <AppBar position="static" color="info" sx={{marginBottom: 2}}>
            
            <Toolbar variant="dense" >
                <Box sx={{marginRight: 2}}>V2 Contracts</Box>
                <Button sx={{marginRight: 2}} color="secondary" variant="contained" onClick={()=>{
                    nav(`/company/${params.id}/report/DeviceV2`);
                }}>Device Report</Button>
                <Button sx={{marginRight: 2}} color="secondary" variant="contained" onClick={()=>{
                    nav(`/company/${params.id}/report/Contacts`);
                }}>Contacts</Button>
                <Button sx={{marginRight: 2}} color="secondary" variant="contained" onClick={()=>{
                    nav(`/company/${params.id}/contracts`);
                }}>Contracts</Button>
                <Button sx={{marginRight: 2}} color="secondary" variant="contained" onClick={()=>{
                    nav(`/company/${params.id}/report/Locations`);
                }}>Locations</Button>
                { company?.azureTenantIds?.length > 0 && <Button sx={{marginRight: 2}} color="primary" variant="contained" onClick={()=>{
                    for( const id of company.azureTenantIds ){
                        api.post( `/azure/syncMfaStatus`, { azureTenantId : id } ).then( () => {
                            console.log( `Synced ${id}` );
                        } );
                    }
                    /*api.post( `/azure/syncMfaStatus` ).then( () => {
                        alert('Synced');
                    } ); */
                }}>Sync</Button> }
            </Toolbar>
        </AppBar>
        {/*<AppBar position="static" color="primary" sx={{marginBottom: 2}}>
            <Toolbar variant="dense" >
                <Box sx={{marginRight: 2}}>Old Contracts</Box>
                <Button sx={{marginRight: 2}} color="secondary" variant="contained" onClick={()=>{
                    nav(`/company/${params.id}/report/Device`);
                }}>Device Report</Button>
                <Button  sx={{marginRight: 2}}  color="secondary" variant="contained" onClick={()=>{
                    nav(`/company/${params.id}/report/Tickets`);
                }}>Ticket Report</Button>
                <Button sx={{marginRight: 2}} color="secondary" variant="contained" onClick={()=>{
                    nav(`/company/${params.id}/report/Billing`);
                }}>Billing Report</Button>
            </Toolbar>
        </AppBar> */}
        
        <Grid container spacing={2} >
            <Grid item xs={6}>
                <Card>
                    <StyledCardHeader title="Settings" />
                    <CardContent>
                        { params.id !== undefined && <SettingsWidget companyId={params.id} /> }
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card>
                    <StyledCardHeader title="Module Links" />
                    <CardContent>
                        { params.id !== undefined && <ModuleLinks companyId={params.id} /> }
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <StyledCardHeader title="Files" />
                    <CardContent>
                        {params.id !== undefined && <FilesView coreCompanyId={parseInt(params.id, 10)} />}
                    </CardContent>
                </Card>
            </Grid>
            {/*<Grid item xs={12}>
                <Card>
                    <StyledCardHeader title="Contract Overview" />
                    <CardContent>
                       { params.id !== undefined && <ContractOverview companyId={params.id} />}
                    </CardContent>
                </Card>
            </Grid> */}
            <Grid item xs={6}>
                <Card>
                    <StyledCardHeader title="Autotask" />
                    <CardContent>
                        {company?.autotaskIds?.map( (id :number  ) => {
                        return <AutotaskWidget key={id} autotaskId={id} /> 
                    }) }
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
            <Card>
                    <StyledCardHeader title="Azure" />
                    <CardContent>
                    {company?.azureTenantIds?.map( (id :string  ) => {
                        return<AzureWidget key={id} azureTenantId={id}  />
                    }) }
                    
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card>
                    <StyledCardHeader title="ITGlue"/>
                    <CardContent>
                    {company?.itglueIds?.map( (id :number  ) => {
                        return<ItglueWidget key={id} itglueOrganizationId={id}  />
                    }) }
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
            <Card>
                    <StyledCardHeader title="DattoRMM" />
                    <CardContent>
                    {company?.dattoRmmSiteIds?.map( (id :number  ) => {
                        return<DattoRmmWidget key={id} dattoSiteId={id}  />
                    }) }
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </Container>
}