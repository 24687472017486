import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, MenuItem, TextField } from "@mui/material";
import { useNodeDataProperty } from "../../../../providers/Recipies.provider";
import { QueueTicket } from "../../../../react-submodules/types/ticketqueue";
import { Handle, Position } from "reactflow";
import { FC, Fragment, useMemo, useState } from "react";
import { TicketUpdateField, allUpdateFields } from "../../../../react-submodules/components/TicketUpdateForm/Fields";
import { NodeElement } from "../../types";
import { Icon } from "@iconify/react";

export const TicketEditNode : NodeElement = ({ id , data } ) => {
    const [ticket, setTicket] = useNodeDataProperty<Partial<QueueTicket>>( id , "ticket" , {} );
    
    const [ fields , setFields] = useNodeDataProperty<Array<keyof QueueTicket>>( id , "fields" , [] );
    const [ fieldToAdd , setFieldToAdd ] = useState<keyof QueueTicket>();
    const availbleFields = useMemo( () => {
        return allUpdateFields.filter( ( key ) => !fields.includes( key ) );
    } , [ ticket , fields ] );


    const updateTicketField = ( field : keyof QueueTicket , value : any ) => {
        setTicket(  { ...ticket , [field] : value } );
    }

    const addField = () => {
        if( fieldToAdd ){
            setFields( [ ...fields , fieldToAdd ] );
            setFieldToAdd( undefined );
        }
    }

    const removeField = ( field : keyof QueueTicket ) => {
        setFields( fields.filter( ( f ) => f !== field ) );        
        setTicket(  { ...ticket , [field] : undefined } );
    }

    const handleCopy = () => {
        navigator.clipboard.writeText( JSON.stringify( { defaultData : data , id : 'ticketCreation' , title : data.title } , null , 2 ) );
      }

    return <Box>
        <Card sx={{maxWidth: '600px'}}>
            <CardHeader className="drag-handle" title="Ticket Edit"  action={
          <IconButton aria-label="copy" onClick={handleCopy}>
            <Icon icon="mdi:content-copy" />
          </IconButton>
        } />
            <CardContent>
                <Grid container sx={{width: '550px'}} spacing={2}>
                    <Grid item sm={8}>
                        <TextField select label="Field" fullWidth value={fieldToAdd} onChange={(e) => setFieldToAdd(e.target.value as keyof QueueTicket)} >
                            {availbleFields.map( ( field ) => (
                                <MenuItem key={field} value={field}>{field}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item sm={4}>
                        <Button onClick={addField}>Add Field</Button>
                    </Grid>
                    { fields.map( ( field ) => (
                        <Fragment key={field}>
                        <Grid item sm={10}>
                            <TicketUpdateField 
                        currentTicket={{ ...ticket}}  field={field} 
                        value={ticket[field]} 
                        onChange={( value ) => {
                            updateTicketField( field , value );
                        }}  
                        updateField={( ufield , value ) => {
                            updateTicketField( field , value );
                        }}
                                />
                            </Grid>
                            <Grid item sm={2}>
                                <Button onClick={() => removeField( field )}>Remove</Button>
                            </Grid>
                        </Fragment>
                    ))}
                </Grid>
            </CardContent>
        </Card>
        <Handle
          type="target"
          position={Position.Left}
          id={`handle-target`}
        />
    </Box>
};