import { Container, List, ListItem, Alert as MAlert, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";

import { CoreTimeclock } from "../../../react-submodules/providers/Timeclock";
import { QueueTicket } from "../../../react-submodules/types/ticketqueue";
import { useApi } from "../../../react-submodules/providers/Api/Api";
import useSound from 'use-sound';

interface OpsComplianceAlert {
    id: string;
    alertType: string;
    active: boolean;
    coreUserId: string;
    userMessageTitle: string;
    userMessageBody: string;
    email: string;
}
const AlertNotWorkingDispatch: FC<{ alert : OpsComplianceAlert , allPunches : CoreTimeclock[] }> = ({ alert , allPunches }) => {
        const api = useApi();
        const [ scheduledTicket, setScheduledTicket ] = useState<QueueTicket | null>(null);
        const [ clockedInTickets , setClockedInTickets ] = useState<QueueTicket[]>([]);
        
        useEffect( () => {
            api.get<QueueTicket>(`/ticketqueue/queue/user/${alert.coreUserId}/nextTicket`)
                .then(({ data }) => {
                    console.log({data});
                    setScheduledTicket(data);
                })
                .catch((error) => {
                    console.error("Failed to fetch scheduled ticket", error);
                });
                api.get<QueueTicket[]>(`/ticketqueue/queue/user/${alert.coreUserId}/clockedInTickets`)
                .then(({ data }) => {
                    setClockedInTickets(data);
                })
                .catch((error) => {
                    console.error("Failed to fetch scheduled ticket", error);
                });
        } , [alert.coreUserId]);
        const latestPunchs = allPunches.filter( p => !!p.end ).filter( p => p.coreUserId === alert.coreUserId ).sort( (a,b) => new Date(a.end || '').getTime() - new Date(b.end || '').getTime() ).reverse().slice( 0 , 3 );
        return <MAlert key={alert.id} severity="warning" style={{ marginBottom: '10px' }}>
        <Typography variant="h6">{alert.email}: {alert.userMessageTitle}</Typography>
        <Typography>{alert.userMessageBody}</Typography>
        <Typography>Schedule For </Typography>
        <List><ListItem>{scheduledTicket?.ticketNumber} - {scheduledTicket?.title}</ListItem></List>
        <Typography>Clocked Into <List>
        {clockedInTickets.map( t  => <ListItem key={t.ticketNumber} >{t?.ticketNumber} - {t?.title} </ListItem>) }
            </List></Typography>
        <Typography>Last 3 Punchs : 
        <List>
            {latestPunchs.map( p  => <ListItem key={p.id}>{new Date(p.start || '').toLocaleTimeString()} - {new Date(p.end || '').toLocaleTimeString()} : {p.at_title}</ListItem>) }
            </List>

        </Typography>
    </MAlert>
}

const Alert : FC<{ alert : OpsComplianceAlert , allPunches : CoreTimeclock[] }> = ({ alert , allPunches }) => {
    switch( alert.alertType ) {
        case 'NOT_WORKING_DISPATCHED':
            return <AlertNotWorkingDispatch alert={alert} allPunches={allPunches.filter( p => p.coreUserId === alert.coreUserId )} />
        default:
            return <MAlert>{alert.alertType}</MAlert>
    }
}


export const Alerts: FC = () => {
    const api = useApi();
    const [alerts, setAlerts] = useState<OpsComplianceAlert[]>([]);

    const [ refreshTicket , setRefreshTicket ] = useState(1);


    const [ allPunches , setAllPunches ] = useState<CoreTimeclock[]>([]);

    useEffect( () => {
        api.get<OpsComplianceAlert[]>('/opscompliance/activeAlerts')
            .then(({ data }) => {
                setAlerts(data);
            });
    }, [refreshTicket]);
    
    useEffect( () => {
        if( alerts.length === 0 ) return;
        api.get<CoreTimeclock[]>('/core/timeclock/todays')
        .then(({ data }) => {
            setAllPunches(data);
        });
    }, [refreshTicket , allPunches.length]);

    useEffect(() => {
        const interval = setInterval( () => {
            setRefreshTicket( iv => iv *  -1 );
        }, 1000 * 15 );
        return () => clearInterval(interval);
    }, []);

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Active Alerts
            </Typography>
            {alerts.length > 0 ? (
                alerts.map((alert) => (
                    <Alert allPunches={allPunches} key={alert.id} alert={alert} />
                ))
            ) : (
                <Typography>No active alerts.</Typography>
            )}
        </Container>
    );
};