import { FC, useEffect, useState} from 'react'
import { NewComputerType } from './NewComputerWizard'
import { MinimumInventory } from '../../types/inventory.dto'
import { InventoryCard } from '../InventoryCard/InventoryCard'
import { Grid } from '@mui/material'
export const SelectComputer : FC<{
    onSelect: ( id : number ) => any,
    type: NewComputerType,
    inventory: MinimumInventory[],
    allowMicro?: boolean;
}> = ({ type , onSelect , inventory , allowMicro}) =>{
    const [ primaryInventory , setPrimaryInventory ] = useState<null | MinimumInventory & { image: string } > (null);
    const [ additionalInventory , setAdditionalInventory ] = useState< Array< MinimumInventory & { image: string } > > ([]);
    useEffect( () => {
        const sff = inventory.find( i => i.id === 1);
        const laptop = inventory.find( i => i.id === 6);
        const micro = inventory.find( i => i.id === 7);
        const tower = inventory.find( i => i.id === 5);
    
        const cleanedInventory : Array< MinimumInventory & { image: string }> = [];
    
        switch( type ){
            case "desktop":
                sff && setPrimaryInventory( { image: 'https://s3.us-central-1.wasabisys.com/enlightenedmsp/op7010sff-plus-csy-00015rf000-bk.png', ...sff});
                laptop && cleanedInventory.push( { image: 'https://s3.us-central-1.wasabisys.com/enlightenedmsp/notebook-latitude-15-5540-nt-rgb-silver-gallery-1.png', ...laptop});
                tower && cleanedInventory.push( { image: 'https://s3.us-central-1.wasabisys.com/enlightenedmsp/desktop-optiplex-7010-mt-black-gallery-1.png', ...tower});
                allowMicro && micro && cleanedInventory.push( { image: 'https://s3.us-central-1.wasabisys.com/enlightenedmsp/desktop-optiplex-7010-mff-black-gallery-2.png', ...micro});
                break;
            case 'laptop':            
                laptop && setPrimaryInventory( { image: 'https://s3.us-central-1.wasabisys.com/enlightenedmsp/notebook-latitude-15-5540-nt-rgb-silver-gallery-1.png', ...laptop});
                sff && cleanedInventory.push( { image: 'https://s3.us-central-1.wasabisys.com/enlightenedmsp/op7010sff-plus-csy-00015rf000-bk.png', ...sff});
                tower && cleanedInventory.push( { image: 'https://s3.us-central-1.wasabisys.com/enlightenedmsp/desktop-optiplex-7010-mt-black-gallery-1.png', ...tower});
                allowMicro && micro && cleanedInventory.push( { image: 'https://s3.us-central-1.wasabisys.com/enlightenedmsp/desktop-optiplex-7010-mff-black-gallery-2.png', ...micro});
                break;
        }
        setAdditionalInventory( cleanedInventory);

    },  [JSON.stringify( inventory) , type])
    
    


    return<>
        <Grid container>
            <Grid item md={8}>
            { primaryInventory && <InventoryCard size="large" action={
        {
            title: "Select",
            onClick: () => {
                onSelect( primaryInventory.id)
            },
            variant: 'contained',
            color: 'secondary'
        }
    } inventory={primaryInventory} /> }
            </Grid>
            <Grid item md={4}>
                {additionalInventory.map( inv => {
                    return <InventoryCard size='small'
                    key={inv.id}
                    action={
                        {
                            title: "Select",
                            onClick: () => {
                                onSelect( inv.id )
                            },
                            variant: 'contained',
                            color: 'primary'
                        }
                    }
                    inventory={inv} />
                })}
            
            </Grid>
        </Grid>
    </>
}