import { FunctionComponent, useEffect, useState } from "react"
import { useApi } from "../../react-submodules/providers/Api/Api";
import { Alert, Badge, Box, Chip, Divider, Typography, useTheme } from "@mui/material";
import { Label } from "@mui/icons-material";


type AutotaskSummaryType = {
    devices: Array<{
        profileType: string;
        count: number;
    }>,
    users: Array<{
        tenantId: string;
        count: number;
        type: string;
    }>
}

const SectionDevicer : FunctionComponent<{ title : string}> = ({ title }) => {
    const theme = useTheme();
    return <Box sx={{
        background: theme.palette.secondary.main, color: theme.palette.secondary.contrastText, padding: 1, borderRadius: 1

    }}><Typography variant="body1">{title}</Typography></Box>
}

export const AzureWidget : FunctionComponent<{ azureTenantId: string|null }> = ({ azureTenantId }) => {
    const api = useApi();
    
    const [ summary , setSummary ] = useState<AutotaskSummaryType | null>( null );
    useEffect( () => {
        if( api.ready && azureTenantId){
            api.get( `/azure/summary` , {
                tenantId : azureTenantId
            } ).then( ( results ) => {
                setSummary( results.data );
            });
        }
    } , [azureTenantId , api.ready]);
    if( azureTenantId === null){
        return <Alert severity="warning">Azure is not configured</Alert>
    }
    return <>
        
        <Box >
        <SectionDevicer title="Devices" />
        <Divider />
        <Box sx={{margin: 2}}>
        {summary?.devices.map( ( device ) => {
          return <Badge  max={9999} key={device.profileType} sx={{margin: 1}} badgeContent={device.count} color="primary"><Chip color="info" label={device.profileType} /></Badge>
        }) }
        </Box>
        </Box>
        <Box >
        <SectionDevicer title="Users" />
        <Divider />
        <Box sx={{margin: 2}}>
        {summary?.users.map( ( configItem ) => {
          return <Badge   max={9999} key={configItem.type || 'Unspecified'} sx={{margin: 1}} badgeContent={configItem.count} color="primary"><Chip  color="info" label={configItem.type || 'Unspecified'} /></Badge>
        }) }
        </Box>
        </Box>
    </>
}