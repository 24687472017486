export type Contract = {
    id: number;
    name: string;
    internalDescription: string;
    publicDescription: string;
    unitsMeasured: string;
    unitPrice: number;
    additionalPrice: number;
    icon?: string;
    iconColor?: string;
}
export const measuringUnits = [
    'COMPANY',
    'WORKSTATIONS' , // deviceId
    'SERVERS' ,  // deviceId
    'USERS' ,  // coreContactId
    'EMAIL_ACCOUNT' , // coreContactId
    'EMAIL_DOMAIN' , 
    'LOCATIONS' , //coreLocationId
    'DATTO_BCDR_CLOUD_UNITS' 
]