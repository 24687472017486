import { FC } from 'react';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { MinimumInventory } from '../../types/inventory.dto';
import { Box, Button, Card, CardActionArea, CardContent, CardHeader, CardMedia, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { Inventory } from '@mui/icons-material';

type SIZES = 'small' | 'medium' | 'large'

const defaultImageSize  : Record<SIZES , number> = {
    'small' : 100,
    'medium' : 200,
    'large' : 300
}

const defaultWidthSize  : Record<SIZES , number> = {
    'small' : 400,
    'medium' : 500,
    'large' : 800
}

const defaultMaxHeight  : Record<SIZES , number> = {
    'small' : 117,
    'medium' : 200,
    'large' : 300
}

type Action = {
    title: string;
    onClick: () => any;
    variant?: 'contained' | 'outlined' | 'text';
    color?: 'primary' | 'secondary' | 'success' | 'info'
}

export const InventoryCard : FC<{
    inventory: MinimumInventory
    imageSize?: number
    highlight?: boolean
    size?: SIZES;
    actions?: Action[];
    action?: Action;
    customAction?: JSX.Element
}> = ({ inventory , imageSize , highlight , size , actions , action , customAction  }) => {
    if( !imageSize ){
        imageSize = defaultImageSize[size || 'medium'];
    }
    
    

    const theme = useTheme();

    return <Card sx={{
        maxWidth: `${defaultWidthSize[size || 'medium']}px`,
        margin: 1,
        border:  `3px solid ${highlight ? theme.palette.secondary.light : theme.palette.primary.light}`,
        position: 'relative'
    }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Grid container>
            <Grid item flex={0} sx={{margin: 'auto'}}>
                { inventory.image && <Box sx={{
                    width: imageSize, height: imageSize, margin: 1
                    
                }}>
                <Box component="img" src={inventory.image} sx={{
                    magin: 'auto',
                    objectFit: 'contain',
                    width: imageSize,
                    height: imageSize,
                    maxHeight: {
                        xs: imageSize
                    },
                    maxWidth: {
                        xs: imageSize
                    }
                }} />
                </Box> }
            </Grid>
            <Grid item flex={1} >
                    <Box sx={{'display' : 'flex' , flexDirection: 'column' , height: '100%'}}>
                        <Box flex={1}>
                        <CardContent sx={{ overflow: 'hidden' , height: `${defaultMaxHeight[ size || 'medium']}px` , textOverflow: 'ellipsis' }}>
                        <Grid container>
                            <Grid item flex={1}>
                            <Tooltip title={<Markdown remarkPlugins={[remarkGfm]}>{inventory.description}</Markdown>}><Typography variant="h6">{inventory.name}</Typography></Tooltip>
                            </Grid>
                            <Grid item flex={0}>
                            <Typography variant='h6' sx={{fontSize: 16}}>${inventory.price?.toFixed(2)}</Typography>
                            </Grid>
                        </Grid>
                        { size !== 'small' && <>
                            <Markdown remarkPlugins={[remarkGfm]}>{inventory.description}</Markdown>
                        </>}
                        
                    </CardContent>
                    </Box>
            { ( actions || action || customAction ) && <Grid container sx={{padding: 1 }} flex={0}>
                <Grid item flex={1} sx={{margin: 'auto'}}>&nbsp;</Grid>
                <Grid item flex={0} sx={{margin: 'auto'}}>
                <CardActionArea>
                    { [ ...( actions || []) , ...( action? [action] : []) ].map( (action , index) => {
                        return <Button color={action.color} variant={action.variant} key={`${action.title}${index}`} onClick={action.onClick}>{action.title}</Button>
                    })}
                    {customAction}
                        </CardActionArea> 
                </Grid>
                </Grid> }

                
        </Box>
            </Grid>
            </Grid>
        </Box>
       
    </Card>
}