import { Box, MenuItem, TextField, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { FieldPicklist, useTicketApi } from "../../providers/TicketApiProvider";

import { BadgeAndName } from "./BadgeAndName";
import { InlineIcon } from "@iconify/react";

const p = [
    {"label": "High", "value": "1", "isActive": true, "isSystem": false, "sortOrder": 3, "parentValue": "", "isDefaultValue": false}, 
    {"label": "Medium", "value": "2", "isActive": true, "isSystem": false, "sortOrder": 5, "parentValue": "", "isDefaultValue": false},
     {"label": "Low", "value": "3", "isActive": true, "isSystem": false, "sortOrder": 6, "parentValue": "", "isDefaultValue": false},
      {"label": "Critical", "value": "4", "isActive": true, "isSystem": false, "sortOrder": 1, "parentValue": "", "isDefaultValue": false}, 
      {"label": "Unspecified", "value": "5", "isActive": true, "isSystem": false, "sortOrder": 2, "parentValue": "", "isDefaultValue": false}, 
      {"label": "Med High", "value": "6", "isActive": false, "isSystem": false, "sortOrder": 4, "parentValue": "", "isDefaultValue": false}];
      const priorityToIcon = ( priority: number ) => {
        switch( priority ){
            // Critical
            case 4:
                return 'tabler:urgent';
            // Unspecified
            case 5:
                return 'mingcute:file-unknown-fill';
            // High
            case 1:
                return 'healthicons:high-bars';
            // Med High
            case 6:
                return 'uiw:warning';
            // Medium
            case 2:
                return 'healthicons:medium-bars';
            // Low
            case 3:
                return 'healthicons:low-bars';
            case 7:
                return 'material-symbols:interactive-space-rounded';
            default:
                return 'mingcute:file-unknown-fill';
        }
  }
      const priorityToColor = ( priority: number ) => {
            switch( priority ){
                // Critical
                case 4:
                    return '#df1f3b';
                // Unspecified
                case 5:
                    return '#666666';
                // High
                case 1:
                    return '#df1f3b';
                // Med High
                case 6:
                    return '#f4b900';
                // Medium
                case 2:
                    return '#0cc433';
                // Low
                case 3:
                    return '#558fcc';
                case 7:
                    return '#558fcc';
                default:
                    return 'error';
            }
      }

export const Priority : FC<{
    iconOnly?: boolean;
    edit?: boolean;
    priority?: number | null;
    onChange?: (priority: FieldPicklist) => any;
}> = ({ priority , edit , onChange , iconOnly }) => {
    const {priorities} = useTicketApi();
    const priorityItem = useMemo(() => {
        return  priorities.find( p => p.value === `${priority}`);
    } , [ JSON.stringify( priorities ) , priority])
    if( !edit ){
        return <Typography><InlineIcon color={priorityToColor( parseInt( priorityItem?.value || '') )} icon={priorityToIcon(  parseInt( priorityItem?.value || '') )} />&nbsp;{!iconOnly && priorityItem?.label}</Typography>
    }
    return <TextField
    size="small"
    fullWidth
    select
    label="Priority"
    value={priorityItem?.value}
    
    error={!priority || priority === 5}

    onChange={(val)=>{
      const newStatus = priorities.find( ( s ) => {
        return s.value === val.target.value || s.label === val.target.value;
    } );
      onChange && onChange( newStatus as any );
    }}
  >
    {priorities.sort( ( a , b ) => {
        return a.sortOrder - b.sortOrder;
    }).map((option) => (
      <MenuItem key={option.value} value={option.value} sx={{display: 'flex' , flexDirection: 'row'}}>
        <Typography><InlineIcon color={priorityToColor( parseInt( option.value) )} icon={priorityToIcon(  parseInt( option.value) )} />&nbsp;{option.label}</Typography> 
      </MenuItem>
    ))}
  </TextField>
}