import { Button, Grid } from "@mui/material"
import { useState } from "react";
import { NewComputerWizard } from "../../react-submodules/components/NewComputerWizard/NewComputerWizard";
import { useApi } from "../../react-submodules/providers/Api/Api";

export const GenerateQuote = () => {
    const [ quoteType , setQuoteType ] = useState<null|'laptop' | 'desktop'>(null);
    const api = useApi();
    return <>
    <Button onClick={()=>{
        api.post('/autotask/createTicket/4', {
            title: "TESTING",
            description: `TEST
Multi-Line Ticket

TEST`

        });
    }}>Test</Button>
    <Button variant="contained" onClick={()=>{
        setQuoteType( 'laptop');
    }}>From a Laptop</Button>
    <Button variant="contained" onClick={()=>{
        setQuoteType( 'desktop');
    }} >From a Desktop</Button>
    
  
    </>
}