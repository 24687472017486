import { FC, useCallback, useEffect, useMemo, useState } from "react";

import FullCalendar/*, {
  CalendarOptions,
  EventInput,
  EventSourceFunc,
} */ from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import luxon3Plugin from "@fullcalendar/luxon3";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';

// import { DurationUnit } from "luxon";



type TCalendarProps  ={
    resources: any;
    events: any;
    eventClick?: any;
    dateClick?: any;
    initalView?: any;
    fullCalendarOptions?: any;
    eventContent?: any;
}

const Calendar : FC<TCalendarProps> = ({
    resources, events, eventClick, initalView, fullCalendarOptions , dateClick, eventContent}) => {
  return (
      <FullCalendar
        eventMinWidth={1}
        eventContent={eventContent}
        initialView={initalView || "resourceTimeGridDay"}
        titleFormat={{
          weekday: 'short', // Mon
          month: 'short',   // Jun
          day: 'numeric',   // 3
        }}
        headerToolbar={{
          right: "prev,next",
        }}
        editable={false}
        dateClick={(item)=>{
          dateClick && dateClick(item);
        }}
        
        plugins={[resourceTimeGridPlugin, resourceTimelinePlugin, luxon3Plugin, interactionPlugin]}
        resources={resources}
        resorceGroupField="resourceId"
        contentHeight="auto"
        
        stickyFooterScrollbar={true}
        stickyHeaderDates={true}
        resourceOrder="title"
        slotMinTime="04:00:00"
        slotMaxTime="18:00:00"
        eventClick={(...p) => {
          eventClick && eventClick(...p);
        }}
        events={events}
        
        {...(fullCalendarOptions || {})}
        schedulerLicenseKey="0535070809-fcs-1715174460"
      />
  );
}
export default Calendar;

