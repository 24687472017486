import { Switch } from "@mui/material";
import { TCoreDataSync } from "../../datatypes/CoreDataSync";
import { FunctionComponent } from "react";
import { useApi } from "../../react-submodules/providers/Api/Api";

export const SyncEnabledToggle : FunctionComponent<{ cell : boolean , row : TCoreDataSync , refresh?: () => any }> = ({ cell, row, refresh }) => {
    const api = useApi();
    return <Switch color="primary" checked={cell} onChange={async (e) => { 

        await api.put( `/core/datasync/${row.key}` , {
            syncEnabled: !cell
        } );
        refresh && refresh();

    }} />;
}