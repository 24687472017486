import 'react-toastify/dist/ReactToastify.css';

import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';


import axios from 'axios';
import { error } from "console";
import { useUser } from "../User";
import { useKeycloak } from '../Keycloak';
import { ApiContext, CommonOuput, ValidDataTypes } from './Api.common';
import { getApiEndpoint } from './api.utils';

const sleep = ( ms: number ) => new Promise( ( resolve ) => setTimeout( resolve , ms ) );

export const useApi = () => useContext(ApiContext);




export const ApiProvider :React.FC<PropsWithChildren & { allowOffline?: boolean} > = ( {children , allowOffline}) => {
    const user = useUser();
    const { offline} = useKeycloak();
    const API_ENDPOINT = getApiEndpoint();
    const handleErrors = ( error: any , retryCount = 0  ) => {
        if( error?.response?.data?.message === "jwt expired" ){
            if( retryCount === 0 ){
                return true;
            }
            toast.error( "User token expired Please refresh the page" );
        } else {
            toast.error( error?.response?.data?.message || error?.message );
        }
        console.log( error);
        return false;
    }
    useEffect( () => {
        // @ts-ignore
        window.apiProviderToken = user.token;
    } , [ user.token ]);



    const [ ready, setReady ] = useState( false );
    useEffect( () => {
        const isReady = user.token !== '';
        if( ready !== isReady ) {
            setReady( isReady );
        }
    }, [ user.token ] );
    const get =   async ( path : string , params?: Record<string,ValidDataTypes> , retryCount = 0 ) : Promise<CommonOuput> => {
        // @ts-ignore
        const Authorization = `Bearer ${window.apiProviderToken ||user.token}`;
        let retryable  = false;
        const results = await axios.get<CommonOuput>( `${API_ENDPOINT}${path}` , {
            headers: {
                Authorization
            },
            params
        } ).catch( ( error ) => {
                retryable =  handleErrors( error);
                
        });
        if( retryable && !results?.data ){
            await sleep( 100);
            return get( path , params , retryCount + 1 );
        }

        return results?.data || {
            data: [] as unknown as any,
            error: 'No data'
        };
    } 
    const deleteCall =   async ( path : string , params?: Record<string,ValidDataTypes> , retryCount = 0 ) : Promise<CommonOuput> => {
        // @ts-ignore
        const Authorization = `Bearer ${window.apiProviderToken ||user.token}`;
        let retryable  = false;
        const results = await axios.delete<CommonOuput>( `${API_ENDPOINT}${path}` , {
            headers: {
                Authorization
            },
            params
        } ).catch( ( error ) => {
                retryable =  handleErrors( error);
                
        });
        if( retryable && !results?.data ){
            await sleep( 100);
            return get( path , params , retryCount + 1 );
        }

        return results?.data || {
            data: [] as unknown as any,
            error: 'No data'
        };
    } 

    const put =  async ( path : string , params?: Record<string, ValidDataTypes> ) => {
        // @ts-ignore
        const Authorization = `Bearer ${window.apiProviderToken ||user.token}`;
        const results = await axios.put<CommonOuput>( `${API_ENDPOINT}${path}` , params , {
            headers: {
                Authorization
            }
        } ).catch( ( error ) => {
            handleErrors( error);
        });
        return results?.data || {
            data: [] as unknown as any,
            error: 'No data'
        };
    }
    const post =   async ( path : string , params?: Record<string, ValidDataTypes> ) => {
        // @ts-ignore
        const Authorization = `Bearer ${window.apiProviderToken ||user.token}`;
        const results = await axios.post<CommonOuput>( `${API_ENDPOINT}${path}` , params , {
            headers: {
                Authorization
            }
        } ).catch( ( error ) => {
            handleErrors( error);
        });
        return results?.data || {
            data: [] as unknown as any,
            error: 'No data'
        };
    } 
    const patch =   async ( path : string , params?: Record<string, ValidDataTypes> ) => {
        // @ts-ignore
        const Authorization = `Bearer ${window.apiProviderToken ||user.token}`;
        const results = await axios.patch<CommonOuput>( `${API_ENDPOINT}${path}` , params , {
            headers: {
                Authorization
            }
        } ).catch( ( error ) => {
            handleErrors( error);
        });
        return results?.data || {
            data: [] as unknown as any,
            error: 'No data'
        };
    } 

    const fileUploaderUrl = `${API_ENDPOINT}/files`;
    if( !ready || offline ){
        if( !allowOffline ){
            return <></>
        }
        return <ApiContext.Provider value={{ online: false , fileUploaderUrl : '' ,  token : '' ,post : async ( url ) => {
            console.error(`You are in offline mode, cannot post to ${url}`)
            throw new Error(`You are in offline mode, cannot post to ${url}`)
        } ,  
        get :  async ( url ) => {
            console.error(`You are in offline mode, cannot get ${url}`)
            throw new Error(`You are in offline mode, cannot get ${url}`)
        } 
         , put :  async () => {
            throw new Error("You are in offline mode")
        } , delete :  async () => {
            throw new Error("You are in offline mode")
        } , patch :  async () => {
            throw new Error("You are in offline mode")
        } , ready : false  }}><ToastContainer />{children}</ApiContext.Provider>
    }

    return (
        <ApiContext.Provider value={{ online: true , fileUploaderUrl ,  token : user.token ||'' ,post ,  get , put , delete : deleteCall, patch , ready }}><ToastContainer />{children}</ApiContext.Provider>
    )
}

