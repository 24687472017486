import { FC, useState } from "react";
import MarkdownCore from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm'
import { styled, IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const SC = {
    Wrapper: styled('div')<{ size: 'small' | 'medium' | 'large' }>(({ size }) => ({
      fontSize: size === 'small' ? '0.875rem' : 'inherit',
    })),
    P: styled('p')(() => ({
      whiteSpace: 'pre-wrap',
    })),
    CopyButton: styled(IconButton)(({ theme }) => ({
      position: 'absolute',
      top: 5,
      right: 5,
      color: theme.palette.grey[300],
    })),
    CodeWrapper: styled('div')({
      position: 'relative',
    }),
  };

export const MarkdownView : FC<{children: any , size?: 'small' | 'medium' | 'large' }> = ({children , size = 'small'}) => {
    const [copiedCode, setCopiedCode] = useState<string | null>(null);

    const handleCopy = (code: string) => {
        navigator.clipboard.writeText(code);
        setCopiedCode(code);
        setTimeout(() => setCopiedCode(null), 2000);
    };

    return (
      <SC.Wrapper size={size}>
        <MarkdownCore components={{
            p({ className, children, ...props }) {
              return <SC.P>{children}</SC.P>;
            }, 
            code({ node, inline, className, children, ...props }: any) {
              const match = /language-(\w+)/.exec(className || '');
              const code = String(children).replace(/\n$/, '');

              if (!inline) {
                return (
                  <SC.CodeWrapper>
                    <SyntaxHighlighter 
                      style={dracula} 
                      PreTag="div" 
                      language={match ? match[1] : 'text'} 
                      {...props}
                    >
                      {code}
                    </SyntaxHighlighter>
                    <Tooltip title={copiedCode === code ? "Copied!" : "Copy code"}>
                      <SC.CopyButton onClick={() => handleCopy(code)} size="small">
                        <ContentCopyIcon fontSize="small" />
                      </SC.CopyButton>
                    </Tooltip>
                  </SC.CodeWrapper>
                );
              } else {
                return (
                  <code className={className} {...props}>
                    {children}
                  </code>
                );
              }
            },
          }}
          rehypePlugins={[rehypeRaw]}
           remarkPlugins={[remarkGfm]}>{children}</MarkdownCore>
      </SC.Wrapper>
    );
}