import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react"
import { useApi } from "../../../providers/Api/Api"



import { TUserStatus } from "../../../types/opscompliance"
import { Tooltip, useTheme } from "@mui/material";
import { GoodTicketGuage } from "./DailyKpi/PercentageGuage";
import { StyledTable } from "../../Table";
import Markdown from "react-markdown";
import { MarkdownView } from "../../../MarkdownView";
export type TDailyKpiUserStatusCategory = "Good"| "Not Working"| "Not Dispatchable"| "Not working Dispatched"| "Other";
export type TDailyKpiData = {
    clockinKpi : Array<TUserStatus & {
        minutes : number
        status : TDailyKpiUserStatusCategory
    }>
    ticketStatusPercentages : Record<TDailyKpiUserStatusCategory  , number >
}

const TARGET_GOOD_PERCENTAGE = 50;




export const DailyKpi : FC<PropsWithChildren<{
    date: string,
    coreUserId?: string,
    acknowlegementRequired: boolean,
    setAcknowledgementRequired: (value: boolean) => void
}>> = ({ date , coreUserId , acknowlegementRequired , setAcknowledgementRequired }) => {
    const api = useApi()
    const theme = useTheme();
    const [ showDetails , setShowDetails ] = useState( false );
    const [data , setData] = useState<TDailyKpiData | null>( null );
    useEffect( () => {
        api.get<TDailyKpiData>("/reports/tech/dailykpi" , {
            date,
            coreUserId
        }).then( (res) => {
            setData( res.data );
        });
    }, [date, coreUserId]);

    const goodPercentage = useMemo( () => {
        return data?.ticketStatusPercentages?.Good || 0;
    } , [data]);

    useEffect( () => {
      if( data && data.ticketStatusPercentages.Good < TARGET_GOOD_PERCENTAGE && !acknowlegementRequired ){
        setAcknowledgementRequired( true );
      }
    } , [ acknowlegementRequired , data ]);





    if( !data ){
        return <>Loading...</>
    }
    return     <><GoodTicketGuage
    toggleDetails={() => setShowDetails(!showDetails)}
    showDetails={showDetails}
    value={goodPercentage} target={TARGET_GOOD_PERCENTAGE} label={`Time on correct ticket for ${new Date(date).toLocaleDateString()}`} helper={`### A few tips to improve
* Make sure you are dispatchable
* Make sure you are working on the correct ticket

### How the correct ticket is calculated
* If you are on a call, that time is considered good
* if you are dispatchable and working on the dispatched ticket, that time is considered good
`}  />

{showDetails && <StyledTable  rows={data.clockinKpi} columns={[
  {
    field: 'category',
    flex: 1,
    headerName: 'Cat',
    Element: ({cell , row }) => <><Tooltip title={<MarkdownView>{`* is isDispatchable : ${row.isDispatchable ? "yes" : "no"}
* is isTimwoodsClockedIn : ${row.isTimwoodsClockedIn ? "yes" : "no"}
* is isWorkingDispatched : ${row.isWorkingDispatched ? "yes" : "no"}
* is isOnCall : ${row.isOnCall ? "yes" : "no"}
* is isWorking : ${row.isWorking ? "yes" : "no"}`}</MarkdownView>}>{cell}</Tooltip></>      
},
    {
      field: 'start',
      flex: 1,
      headerName: 'Start',
      Element: ({cell}) => <>{new Date(cell).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}</>      
  },
  {
    field: 'end',
    flex: 1,
    headerName: 'End',
    Element: ({cell}) => <>{new Date(cell).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}</>      
},
{
  field: 'minutes',
  flex: 1,
  headerName: 'minutes',
  Element: ({cell}) => <>{cell}m</>      
}
]}></StyledTable>}
</>
}

