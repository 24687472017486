import { Edge, Node } from "reactflow"
import { z } from "zod"
import { AzureEmailSchema } from "../../../../react-submodules/types/azure"
import { GetContext } from "../../types"
import { baseCoreRecipeContextSchema } from "../utils"

export const getEmailReceivedContext : GetContext = ( nodeId: string , nodes: Node[] , edges: Edge[] ) => {
    return baseCoreRecipeContextSchema.extend({
        context: z.literal('email'),
        emailId: z.string(),
        email: AzureEmailSchema.extend( {
            from: z.string(),
            to: z.string( { 
                description: 'The email address of the recipient joined by ;'
            })
        })
    });
}