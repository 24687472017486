import { useEffect, useMemo, useState } from "react"
import { useApi } from "../../react-submodules/providers/Api/Api";
import { Button, Dialog, DialogContent, DialogTitle, Paper, TextField, Theme, Typography, useTheme } from "@mui/material";
import { ResourceCalendar } from "../../react-submodules/components/calendar/Calendar";
import FullCalendar from "@fullcalendar/react";

import interactionPlugin from "@fullcalendar/interaction";
import luxon3Plugin from "@fullcalendar/luxon3";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { CoreUser } from "../../react-submodules/types/core";
import { MarkdownView } from "../../react-submodules/MarkdownView";
import { useInterval } from "../../react-submodules/hooks/useInterval";
import { TUserStatus } from "../../react-submodules/types/opscompliance";

const eventToColor = ( event : TUserStatus , theme : Theme ) => {
    if( event.isOnCall) {
        return theme.palette.success.dark;
    }
    if( event.isWorkingDispatched) {
        return theme.palette.success.main;
    }
    if( event.isWorking) {
        return theme.palette.warning.light;
    }
    if( event.isDispatchable) {
        return theme.palette.error.main;
    }
    return theme.palette.error.dark;
}


export const UserStatusCalendar = () => {
    const api = useApi();
    const theme = useTheme();
    const [ data , setData ] = useState<TUserStatus[]>( [] );
    const [ users , setUsers] = useState<CoreUser[]>( [] );

    const [ refreshTick , setRefreshTick ] = useState( 1 );
    useEffect( () => {
        const intv = setInterval( () => {
            setRefreshTick( rt => rt * -1 );
        } , 60 * 1000 );
        return () => {
            clearInterval( intv );
        }
    } , [])
    useEffect( () => {
        api.get<TUserStatus[]>('/opscompliance/userStatusCalendar').then( ({data}) => {
            setData( data );
        });
        api.get<CoreUser[]>('/core/users' , {'email:like' : 'coulee.tech'}).then( (res) => {
            setUsers( res.data.filter( ( u ) => u.techTier !== null ) );
        });
    } , [refreshTick]);
    const resources = useMemo( () => {
        return users.map( d => { 

            return { 
            id : d.id , 
            eventBackgroundColor : theme.palette.primary.main,
            eventOverlap: false,
            title : `${d.firstName} ${d.lastName.substring( 0 , 1 )}`, } 
        });
    } , [ users ]);

    const mappedEvents = useMemo( () => {
        return data.filter( d => d.isTimwoodsClockedIn ).map( d => {
                const end = d.end ? new Date(d.end) : new Date() ;
                const workedSeconds = ( end.getTime() - new Date( d.start ).getTime() ) / 1000;
                return { 
                    id : d.id , 
                    title : ` ` , 
                    
                    start : new Date(d.start) , 
                    end  ,
                    resourceId: d.coreUserId,
                    backgroundColor : eventToColor( d , theme ),
                    data : d,
                    workedSeconds
                }}).filter( d => {
                    if( !d.data.isWorking && d.workedSeconds < 60 * 1000 * 5 ) {
                        return false;
                    }
                    return true;
                });
    } , [ data ]);
  return <>
  <FullCalendar
        initialView={"resourceTimeline"}
        headerToolbar={{
          right:
            "prev,next today resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth",
        }}
        editable={true}
        plugins={[resourceTimelinePlugin, luxon3Plugin, interactionPlugin]}
        resources={resources}
        contentHeight="auto"
        stickyFooterScrollbar={true}
        stickyHeaderDates={true}
        resourceOrder="title"
        eventClick={(...p) => {
            /* setSelectedEvent( p[0].event ); */
        }}
        events={mappedEvents}
        resourceAreaWidth="20%"
        resourceGroupField="techTier"
        schedulerLicenseKey="0535070809-fcs-1715174460"
        
        views={{
          resourceTimelineTwoDay: {
            type: "resourceTimeline",
            dayCount: 2,
          },
          resourceTimelineThreeDay: {
            type: "resourceTimeline",
            dayCount: 3,
          },
          resourceTimelineFourDay: {
            type: "resourceTimeline",
            dayCount: 4,
          },
        }}
      />
  </>;
};

