import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCoreDeviceSoftwareMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  description?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ignore?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type UpdateCoreDeviceSoftwareMutation = { __typename?: 'Mutation', updateCoreDeviceSoftware: { __typename?: 'CoreDeviceSoftware', id?: number | null, name: string, description?: string | null, vendor?: string | null, ignore: boolean, installMethod?: string | null, installMethodId?: string | null, aliases?: Array<string> | null } };


export const UpdateCoreDeviceSoftwareDocument = gql`
    mutation UpdateCoreDeviceSoftware($id: Int!, $description: String, $ignore: Boolean) {
  updateCoreDeviceSoftware(id: $id, description: $description, ignore: $ignore) {
    id
    name
    description
    vendor
    ignore
    installMethod
    installMethodId
    aliases
  }
}
    `;
export type UpdateCoreDeviceSoftwareMutationFn = Apollo.MutationFunction<UpdateCoreDeviceSoftwareMutation, UpdateCoreDeviceSoftwareMutationVariables>;

/**
 * __useUpdateCoreDeviceSoftwareMutation__
 *
 * To run a mutation, you first call `useUpdateCoreDeviceSoftwareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoreDeviceSoftwareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoreDeviceSoftwareMutation, { data, loading, error }] = useUpdateCoreDeviceSoftwareMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      ignore: // value for 'ignore'
 *   },
 * });
 */
export function useUpdateCoreDeviceSoftwareMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCoreDeviceSoftwareMutation, UpdateCoreDeviceSoftwareMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCoreDeviceSoftwareMutation, UpdateCoreDeviceSoftwareMutationVariables>(UpdateCoreDeviceSoftwareDocument, options);
      }
export type UpdateCoreDeviceSoftwareMutationHookResult = ReturnType<typeof useUpdateCoreDeviceSoftwareMutation>;
export type UpdateCoreDeviceSoftwareMutationResult = Apollo.MutationResult<UpdateCoreDeviceSoftwareMutation>;
export type UpdateCoreDeviceSoftwareMutationOptions = Apollo.BaseMutationOptions<UpdateCoreDeviceSoftwareMutation, UpdateCoreDeviceSoftwareMutationVariables>;