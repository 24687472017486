import { FC } from "react"
import { FormFieldText } from "./FormFieldText"
import { FormFieldTextArea } from "./FormFieldTextArea"
import { FormField as TFormField } from "../../../../coretypes/formfields"

export const CoreFormField : FC<{ readonly?: boolean, field : TFormField , value : any , onChange: ( value : any ) => void }> = ({ readonly, field , value , onChange }) => {
    switch( field.type){
        case "TEXT" : return <FormFieldText readonly={readonly} field={field} value={value} onChange={onChange} />
        case "TEXTAREA" : return <FormFieldTextArea readonly={readonly} field={field} value={value} onChange={onChange} />
    }
    return <>NOT YET IMPLEMENTED</>
}

