import { FunctionComponent } from "react";
import { TCoreDataSync } from "../../datatypes/CoreDataSync";
import { FormControl, InputLabel, MenuItem, TextField } from "@mui/material";
import { useApi } from "../../react-submodules/providers/Api/Api";

const presetIntervals = [
    {
        label: 'Every 15 seconds',
        value: 15 * 1000
    },
    {
        label: 'Every minute',
        value: 60 * 1000
    },
    {
        label: 'Every 5 minutes',
        value: 5 * 60 * 1000
    },
    {
        label: 'Every 15 minutes',
        value: 15 * 60 * 1000
    },
    {
        label: 'Every 30 minutes',
        value: 30 * 60 * 1000
    },
    {
        label: 'Every Hour',
        value: 60 * 60 * 1000
    },
    {
        label: 'Every Three Hours',
        value: 3 * 60 * 60 * 1000
    },
    {
        label: 'Every Six Hours',
        value: 6 * 60 * 60 * 1000
    },
    {
        label: 'Every 12 Hours',
        value: 12 * 60 * 60 * 1000
    },
    {
        label: 'Every Day',
        value: 24 * 60 * 60 * 1000
    },
]


export const SyncIntervalButton : FunctionComponent<{ cell : number , row : TCoreDataSync , refresh: () => any }> = ({ cell , row , refresh}) => {
    const api = useApi();

    return <TextField
    select
    label="Sync Interval"
    value={cell}
    onChange={async (e)=>{
        api.put( `/core/datasync/${row.key}` , {
            syncIntervalMs: e.target.value
        } ).then( ()=>{
            refresh();
        });
    }}
    
  >{ presetIntervals.map( ( { label , value } ) => {
    return <MenuItem key={value} value={value}>
    {label}
  </MenuItem>
  } ) }
  </TextField>;
} 