import { useEdges, useNodes } from "reactflow";
import { getContextRetreaver } from "./Nodes/utils";



export const useNodeContext = ( nodeId: string ) => {
    const nodes = useNodes();
    const edges = useEdges();
    const node = nodes.find( n => n.id === nodeId );
    
    const getContext = () => {
        const getContext = getContextRetreaver( node?.type || "" );
        return getContext( nodeId , nodes , edges );
    }
    return { getContext };
}