import { Container } from "@mui/material"
import { PaginatedGraphqlQueryTable } from "../../../react-submodules/components/PaginatedGraphqlQueryTable/PaginatedGraphqlQueryTable";

export const FeedbackScores = () => {
    
        return  <Container>
        <PaginatedGraphqlQueryTable baseName="FeedbackScore"  columns={[
            { field: 'id', type: 'Float', autoIncrement: true, createRequired: false , primaryKey: true}, 
            { field: 'name', type: 'String', createRequired: true},
            { field: 'description', type: 'String', createRequired: true},
            { field: 'customerRespTitle', type: 'String', createRequired: true},
            { field: 'customerResp', type: 'String', createRequired: true},
            ]} />
    </Container>
    
}