import { FunctionComponent, useEffect, useState } from "react"
import { useApi } from "../../react-submodules/providers/Api/Api";
import { Alert, Badge, Box, Chip, Divider, Typography, useTheme } from "@mui/material";
import { Label } from "@mui/icons-material";


type AutotaskSummaryType = {
    tickets: Array<{
        id: number;
        statusName: string;
        count: number;
    }>,
    configurationItems: Array<{
        configurationItemType: number | null;
        count: number;
        categoryName: string;
    }>
}

const SectionDevicer : FunctionComponent<{ title : string}> = ({ title }) => {
    const theme = useTheme();
    return <Box sx={{
        background: theme.palette.secondary.main, color: theme.palette.secondary.contrastText, padding: 1, borderRadius: 1

    }}><Typography variant="body1">{title}</Typography></Box>
}

export const AutotaskWidget : FunctionComponent<{ autotaskId: number | null }> = ({ autotaskId }) => {
    const api = useApi();
    
    const [ summary , setSummary ] = useState<AutotaskSummaryType | null>( null );
    useEffect( () => {
        if( api.ready && typeof autotaskId === 'number'){
            api.get( `/autotask/summary` , {
                companyID: autotaskId
            } ).then( ( results ) => {
                setSummary( results.data );
            });
        }
    } , [autotaskId , api.ready]);
    if( autotaskId === null){
        return <Alert severity="warning">Autotask is not configured</Alert>
    }
    return <>
        
        <Box >
        <SectionDevicer title="Tickets" />
        <Divider />
        <Box sx={{margin: 2}}>
        {summary?.tickets.map( ( ticket ) => {
          return <Badge  max={9999} key={ticket.statusName|| 'Default'} sx={{margin: 1}} badgeContent={ticket.count} color="primary"><Chip color="info" label={ticket.statusName} /></Badge>
        }) }
        </Box>
        </Box>
        <Box >
        <SectionDevicer title="Configuration Items" />
        <Divider />
        <Box sx={{margin: 2}}>
        {summary?.configurationItems.map( ( configItem ) => {
          return <Badge  max={9999} key={configItem.categoryName || JSON.stringify(configItem)} sx={{margin: 1}} badgeContent={configItem.count} color="primary"><Chip  color="info" label={configItem.categoryName || 'Unspecified'} /></Badge>
        }) }
        </Box>
        </Box>
    </>
}