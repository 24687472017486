import { createContext, useContext, useEffect, useState, useMemo } from "react";
import { CoreNotificationDto } from "../../coretypes/core.dto";
import { useApi } from "./Api/Api";

interface CoreNotificationProvider {
    notifications: CoreNotificationDto[];
    refresh: () => void;
}

const CoreNotificationContext = createContext<CoreNotificationProvider>(
    {
        notifications: [],
        refresh: () => {}
    }
);
export const useCoreNotifications = () => {
    return useContext(CoreNotificationContext);
}
export const CoreNotificationProvider = ({children}: {children: React.ReactNode}) => {
    const api = useApi();
    const [notifications, setNotifications] = useState<CoreNotificationDto[]>([]);
    const [ tick , setTick ] = useState(1);
    
    const refresh = useMemo(() => () => {
        setTick(tick => tick * -1);
    }, []);
    
    useEffect(() => {
        api.get<CoreNotificationDto[]>('/core/notifications' ).then( ({data}) => {
            setNotifications(data);
        });
    },[tick]);
    
    useEffect( () => {
        const intv = setInterval( () => { setTick(tick => tick * -1); } , 60 * 1000 );
        return () => {
            clearInterval(intv);
        }
    },[]);

    // Memoize the provider value to prevent unnecessary re-renders
    const providerValue = useMemo(() => ({
        notifications,
        refresh
    }), [notifications, refresh]);

    return <CoreNotificationContext.Provider value={providerValue}>
        {children}
    </CoreNotificationContext.Provider>
}

