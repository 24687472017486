import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCoreMessageChannelMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  result: Types.CoreMessageChannelResult;
}>;


export type UpdateCoreMessageChannelMutation = { __typename?: 'Mutation', updateCoreMessageChannel: { __typename?: 'CoreMessageChannel', id?: number | null, acknowledged?: boolean | null, result?: Types.CoreMessageChannelResult | null } };


export const UpdateCoreMessageChannelDocument = gql`
    mutation UpdateCoreMessageChannel($id: Int!, $result: CoreMessageChannelResult!) {
  updateCoreMessageChannel(id: $id, acknowledged: true, result: $result) {
    id
    acknowledged
    result
  }
}
    `;
export type UpdateCoreMessageChannelMutationFn = Apollo.MutationFunction<UpdateCoreMessageChannelMutation, UpdateCoreMessageChannelMutationVariables>;

/**
 * __useUpdateCoreMessageChannelMutation__
 *
 * To run a mutation, you first call `useUpdateCoreMessageChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoreMessageChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoreMessageChannelMutation, { data, loading, error }] = useUpdateCoreMessageChannelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      result: // value for 'result'
 *   },
 * });
 */
export function useUpdateCoreMessageChannelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCoreMessageChannelMutation, UpdateCoreMessageChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCoreMessageChannelMutation, UpdateCoreMessageChannelMutationVariables>(UpdateCoreMessageChannelDocument, options);
      }
export type UpdateCoreMessageChannelMutationHookResult = ReturnType<typeof useUpdateCoreMessageChannelMutation>;
export type UpdateCoreMessageChannelMutationResult = Apollo.MutationResult<UpdateCoreMessageChannelMutation>;
export type UpdateCoreMessageChannelMutationOptions = Apollo.BaseMutationOptions<UpdateCoreMessageChannelMutation, UpdateCoreMessageChannelMutationVariables>;