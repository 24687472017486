import { gql, useQuery } from "@apollo/client"
import { Box , Button, Typography } from "@mui/material"
import { useState } from "react"
import { StyledTable, TableColumnDef } from "../../react-submodules/components/Table"
import { useApi } from "../../react-submodules/providers/Api"

const DEVICES_QUERY = gql`query FindDattormmDevicesPaginated {
    findDattormmDevicesPaginated(
        operatingSystem: { like: "windows" }, 
        archived: { eq: false }, 
        lastSeen: { gte: "2024-05-01" }, 
        limit: 100000, 
        page: 0 ) {
        data {
            site {
                coreCompany {
                    name
                    instances{
                        id
                        contractId
                    }
                }
            }
            id
            hostname
            lastSeen
            udf11
            patchStatus
            operatingSystem
            antivirusStatus
            antivirusProduct
            patchesInstalled
            lastReboot
            rebootRequired
            online
            patchesApprovedPending
            patchesNotApproved
            assignments {
                instanceId
                instance {
                    contractId
                    contract { 
                        name
                    }
                }
            }
        }
        totalCount
        page
        limit
    }
}`
type Device = {
    id: number;
    site: {
        coreCompany: {
            name: string
            instances: {
                id: string
                contractId: number
            }[]
        }
    }
    hostname: string
    lastSeen: string
    udf11: string
    patchStatus: string
    operatingSystem: string
    antivirusStatus: string
    antivirusProduct: string
    patchesInstalled: string
    lastReboot: string
    rebootRequired: string
    online: string
    patchesApprovedPending: string
    patchesNotApproved: string
    assignments: {
        instanceId: string
        instance: { 
            contractId: number
            contract: { 
                name: string
            }
        }
    }[]
}

type DataToFix = {
    hostname: string
    deviceId: number
    companyName: string
    instanceId: string
    contractId: number
}

type ContractType = 'server' | 'workstation';
const FULL_MANAGED_CONTRACT_IDS: Record<ContractType, number> = { server: 4 , workstation: 1 };
const SECURITY_MANAGED_CONTRACT_IDS: Record<ContractType, number> = { server: 6 , workstation: 2 };


const columns: TableColumnDef<DataToFix>[] = [
    {
        field: 'hostname',
        headerName: 'Hostname',
    },
    {
        field: 'deviceId',
        headerName: 'Device ID',
    },
    {
        field: 'companyName',
        headerName: 'Company Name',
    },
    {
        field: 'instanceId',
        headerName: 'Instance ID',
    },
    {
        field: 'contractId',
        headerName: 'Contract ID',
    },
];

const buildFullManagedData = ( device: Device , type: ContractType ) => {
    if( device.assignments.length > 0 ){
        return [];
    }
    const fixedData: DataToFix[] = [];
    const fullManaged = device?.site?.coreCompany?.instances?.find( i => i.contractId === FULL_MANAGED_CONTRACT_IDS[type] );
    const securityManaged = device?.site?.coreCompany?.instances?.find( i => i.contractId === SECURITY_MANAGED_CONTRACT_IDS[type] );
    
    if( fullManaged && device.udf11 === 'full_managed' ){
        fixedData.push({
            hostname: device.hostname,
            deviceId: device.id,
            companyName: device.site.coreCompany.name,
            instanceId: fullManaged.id,
            contractId: fullManaged.contractId,
        });
    }
    if( securityManaged && ['full_managed' , 'security_only'].includes( device.udf11 ) ){
        fixedData.push({
            hostname: device.hostname,
            deviceId: device.id,
            companyName: device.site.coreCompany.name,
            instanceId: securityManaged.id,
            contractId: securityManaged.contractId,
        });
    }
    if( !['full_managed' , 'security_only' , null , undefined].includes( device.udf11 )){
        console.log( "Device" , device.hostname , device.udf11 );
    }
    return fixedData;
}


export const ContractFix = () => {
    const api = useApi();
    const { data , loading } = useQuery<{findDattormmDevicesPaginated: {data: Device[]}}>(DEVICES_QUERY );
    const [ dataToFix, setDataToFix ] = useState<DataToFix[]>([]);
    const createFixedData = () => {
        const fd: DataToFix[] = [];  
        for( const device of data?.findDattormmDevicesPaginated.data || [] ){
            
           if( device.operatingSystem.toLocaleLowerCase().includes('server')){
            fd.push(...buildFullManagedData( device , 'server' ));
           }else{
            fd.push(...buildFullManagedData( device , 'workstation' ));
           }
        } 
        setDataToFix( fd );

        
    }   
    const runFix = async () => {
        for await ( const d of dataToFix ){
            await api.post( `/contracts/instances/${d.instanceId}/assign` , { deviceId: d.deviceId } );
        }
    }
    return <Box>
        <Typography>
            Contract Fix
        </Typography>
        <Button variant="contained" color="secondary" onClick={() => {
            createFixedData()
        }}>
            Create Fixed Data
        </Button>
        <Button variant="contained" color="secondary" onClick={() => {
            runFix()
        }}>
            Run Fix
        </Button>
        <StyledTable rows={dataToFix} columns={columns} />
    </Box>
}