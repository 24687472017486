import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPhonesCallsForTimelineQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetPhonesCallsForTimelineQuery = { __typename?: 'Query', getPhonesCallsById: { __typename?: 'PhonesCalls', id?: number | null, startTime?: any | null, endTime?: any | null, direction?: string | null, fromNumber?: string | null, toNumber?: string | null, extention?: string | null, PhoneCallLegs?: Array<{ __typename?: 'PhonesCallLegs', id?: number | null, extention?: string | null, startTime?: any | null, endTime?: any | null, transcription?: string | null, downloadUrl?: string | null, recordingLocalPath?: string | null, CoreUser?: { __typename?: 'CoreUsers', email?: string | null, firstName?: string | null, lastName?: string | null, id?: string | null } | null, Contact?: { __typename?: 'CoreContact', firstName?: string | null, lastName?: string | null, email?: string | null } | null }> | null, CoreUser?: { __typename?: 'CoreUsers', email?: string | null, firstName?: string | null, lastName?: string | null } | null, CoreCompany?: { __typename?: 'CoreCompanies', name: string, id?: number | null } | null, Contact?: { __typename?: 'CoreContact', firstName?: string | null, lastName?: string | null, email?: string | null, id?: number | null } | null } };


export const GetPhonesCallsForTimelineDocument = gql`
    query GetPhonesCallsForTimeline($id: Int!) {
  getPhonesCallsById(id: $id) {
    id
    startTime
    endTime
    direction
    fromNumber
    toNumber
    extention
    PhoneCallLegs {
      id
      extention
      startTime
      endTime
      transcription
      downloadUrl
      recordingLocalPath
      CoreUser {
        email
        firstName
        lastName
        id
      }
      Contact {
        firstName
        lastName
        email
      }
    }
    CoreUser {
      email
      firstName
      lastName
    }
    CoreCompany {
      name
      id
    }
    Contact {
      firstName
      lastName
      email
      id
    }
  }
}
    `;

/**
 * __useGetPhonesCallsForTimelineQuery__
 *
 * To run a query within a React component, call `useGetPhonesCallsForTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhonesCallsForTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhonesCallsForTimelineQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPhonesCallsForTimelineQuery(baseOptions: Apollo.QueryHookOptions<GetPhonesCallsForTimelineQuery, GetPhonesCallsForTimelineQueryVariables> & ({ variables: GetPhonesCallsForTimelineQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPhonesCallsForTimelineQuery, GetPhonesCallsForTimelineQueryVariables>(GetPhonesCallsForTimelineDocument, options);
      }
export function useGetPhonesCallsForTimelineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhonesCallsForTimelineQuery, GetPhonesCallsForTimelineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPhonesCallsForTimelineQuery, GetPhonesCallsForTimelineQueryVariables>(GetPhonesCallsForTimelineDocument, options);
        }
export function useGetPhonesCallsForTimelineSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPhonesCallsForTimelineQuery, GetPhonesCallsForTimelineQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPhonesCallsForTimelineQuery, GetPhonesCallsForTimelineQueryVariables>(GetPhonesCallsForTimelineDocument, options);
        }
export type GetPhonesCallsForTimelineQueryHookResult = ReturnType<typeof useGetPhonesCallsForTimelineQuery>;
export type GetPhonesCallsForTimelineLazyQueryHookResult = ReturnType<typeof useGetPhonesCallsForTimelineLazyQuery>;
export type GetPhonesCallsForTimelineSuspenseQueryHookResult = ReturnType<typeof useGetPhonesCallsForTimelineSuspenseQuery>;
export type GetPhonesCallsForTimelineQueryResult = Apollo.QueryResult<GetPhonesCallsForTimelineQuery, GetPhonesCallsForTimelineQueryVariables>;