import { FC } from "react";
import { Edge, Node } from "reactflow";
import { z } from "zod";
import recipeNodes from "./Nodes";

export type NodeElement<T = Record<string, any>> = FC<{ id: string; data: T }>;

export type GetContext = ( nodeId: string , nodes: Node[] , edges: Edge[] ) => z.ZodType<any>;

export type NodeDefinition<T = Record<string, any>> = {
    id: string;
    title: string;
    element: NodeElement<T>;
    defaultData?: T;
    getContext?: GetContext;
}
export type JsonNodeDefinition = Pick<NodeDefinition , 'id' | 'title' | 'defaultData' > & Partial<NodeDefinition>;

export const isJsonNodeDefinition = ( input : any ) : input is JsonNodeDefinition => {
    if( typeof input !== "object" || input === null ){
        return false;
    }
    const validIds = recipeNodes.map( node => node.id );
    return input.id && input.title && validIds.includes( input.id );
}