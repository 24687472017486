import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, TextField, Typography } from "@mui/material"
import { useApiSearchResults } from "../../../hooks/useApiSearchResults";
import { ContractsInstances } from "../../../coretypes/contracts/contracts.dto";
import { FC, useEffect, useMemo, useState } from "react";
import { Contract } from "../../Contracts/types";
import { StyledTable } from "../../../react-submodules/components/Table";
import { useApi } from "../../../react-submodules/providers/Api";
import { useParams } from "react-router-dom";

const ContractMatrixCell : FC<{
    contracts: Contract[],
    contractId: number,
    value: number,
    setContractQuantity: ( contractId: number, quantity: number ) => void
}> = ({ contracts, contractId, value, setContractQuantity }) => {
    const contract = contracts.find( c => c.id === contractId );
    
    const [ price, setPrice ] = useState( value * (contract?.unitPrice ?? 0) );
    useEffect( () => {
        const unitPrice = contract?.unitPrice ?? 0;
        if( !unitPrice ) {
            return;
        }
        const units = Math.round( price / unitPrice);
        setContractQuantity( contractId, units );
    }, [ price, contract?.unitPrice ] );

    const truePrice = useMemo( () => {
        return value * (contract?.unitPrice ?? 0);
    }, [ value, contract?.unitPrice ] );
    return <TextField InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        endAdornment: <InputAdornment position="end">${truePrice||0}</InputAdornment>
    }}  size="small" type="number" value={price} onChange={(e) => {
        setPrice( Number(e.target.value) );
    }} />
}

export const AddFromMatrix : FC<{
    onClose: () => void
}> = ({ onClose }) => {
    const { id : coreCompanyId } = useParams<{ id: string }>();
    const [ contracts ] = useApiSearchResults<Contract>(`/contracts/search`);
    const api = useApi();
    const [ open , setOpen ] = useState( false );
    const [ contractQuantity, setContractQuantityCore ] = useState<{ [key: number]: number }>({});
    const setContractQuantity = ( contractId: number, quantity: number ) => {
        setContractQuantityCore( cq => {  return  { ...cq, [contractId]: quantity } });
    }
    const newContracts = useMemo(() =>{
        const keys = Object.keys( contractQuantity ).filter( k => contractQuantity[k as any] > 0 );
        return keys.map( k => {
            const contract = contracts.find( c => c.id === Number( k ) );
            return {
                ...contract,
                quantity: contractQuantity[k as any ]
            }
        } );
    } , [JSON.stringify( contractQuantity ) , JSON.stringify( contracts )]);


    const createContracts = () => {
        Promise.all(
            newContracts.map( c => {
                return api.post('/contracts/instances/create', {
                contractId: c.id,
                coreCompanyId: parseInt(`${coreCompanyId}`),
                approvedUnits: c.quantity,
                isActive: true
            }) } 
            )
        ).then(() => {
            setOpen( false );
            onClose();
        }) 
    }
    return <>
    <Button variant="contained" onClick={() => setOpen( true )}>Add from matrix</Button>
    <Dialog maxWidth="lg" open={open} onClose={() => {
        setOpen( false );
    }}>
        <DialogTitle>Add from matrix</DialogTitle>
        <DialogContent sx={{ width: "800px" }}>
        <Grid container spacing={1} >
            <Grid item sm={3}>
                &nbsp;
            </Grid>
            <Grid item sm={3}>
                Company
            </Grid>
            <Grid item sm={3}>
                Server
            </Grid>
            <Grid item sm={3}>
                Desktop
            </Grid>

            <Grid item sm={3}>
                Managed IT Support
            </Grid>
            <Grid item sm={3}>
                <ContractMatrixCell contracts={contracts} contractId={3} value={typeof contractQuantity[3] === undefined ? 1 : contractQuantity[3] } setContractQuantity={setContractQuantity} />
            </Grid>
            <Grid item sm={3}>
                <ContractMatrixCell contracts={contracts} contractId={4} value={typeof contractQuantity[4] === undefined ? 1 : contractQuantity[4] } setContractQuantity={setContractQuantity} />
            </Grid>
            <Grid item sm={3}>
                <ContractMatrixCell contracts={contracts} contractId={1} value={typeof contractQuantity[5] === undefined ? 1 : contractQuantity[1] } setContractQuantity={setContractQuantity} />
            </Grid>
            

            <Grid item sm={3}>
                Cyber Security
            </Grid>
            <Grid item sm={3}>
                <ContractMatrixCell contracts={contracts} contractId={5} value={typeof contractQuantity[5] === undefined ? 1 : contractQuantity[5] } setContractQuantity={setContractQuantity} />
            </Grid>
            <Grid item sm={3}>
                <ContractMatrixCell contracts={contracts} contractId={6} value={typeof contractQuantity[6] === undefined ? 1 : contractQuantity[6] } setContractQuantity={setContractQuantity} />
            </Grid>
            <Grid item sm={3}>
                <ContractMatrixCell contracts={contracts} contractId={2} value={typeof contractQuantity[2] === undefined ? 1 : contractQuantity[2] } setContractQuantity={setContractQuantity} />
            </Grid>

            <Grid item sm={3}>
                Business Continuity
            </Grid>
            <Grid item sm={3}>
                -
            </Grid>
            <Grid item sm={3}>
                <ContractMatrixCell contracts={contracts} contractId={9} value={typeof contractQuantity[9] === undefined ? 1 : contractQuantity[9] } setContractQuantity={setContractQuantity} />
            </Grid>
            <Grid item sm={3}>
            <ContractMatrixCell contracts={contracts} contractId={8} value={typeof contractQuantity[8] === undefined ? 1 : contractQuantity[8] } setContractQuantity={setContractQuantity} />
            </Grid>
        </Grid>    
        <StyledTable rows={newContracts} columns={[
            {
                field: 'name',
                flex: 1,
                sortable: true,
                headerName: 'Name'
                
            },
            {
                field: 'unitPrice',
                flex: 1,
                sortable: true,
                headerName: 'unitPrice'
                
            },
            {
                field: 'quantity',
                flex: 1,
                sortable: true,
                headerName: 'quantity'
                
            },
        ]} />
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={() => {
                createContracts();
            }}>Create</Button>
        </DialogActions>
    </Dialog></>
}