import { Box, Card, CardContent, CardHeader, TextField } from "@mui/material"
import { Handle, Position } from "reactflow"
import { useNodeDataProperty } from "../../../providers/Recipies.provider";


export const EmailCategorization = ({ id , data } : {id : string , data : any  }) => {
    const [ title , setTitle ] = useNodeDataProperty( id , 'title');
    const [ category , setCategory ] = useNodeDataProperty( id , 'category');
    return <Box>
        <Card sx={{maxWidth: '900px'}}>
            <CardHeader className="drag-handle" title="Email Categorization" />
            <CardContent>
                <TextField sx={{padding: '2px'}} size="small" label="Title" value={title} onChange={(e) => {
                    
                    setTitle( e.target.value );
                }} fullWidth />
                <TextField sx={{padding: '2px'}} size="small" label="Category" value={category} onChange={(e) => {
                    setCategory( e.target.value );
                }} fullWidth />
            </CardContent>
        </Card>
        <Handle
          type="target"
          position={Position.Left}
          id={`handle-target`}
        />
        <Handle
          type="source"
          position={Position.Right}
          id={`handle-source`}
        />
    </Box>

}