import { Box, List, ListItem, ListItemText, Typography, useTheme } from "@mui/material";
import { CoreTimeclock, useTimeclock } from "../../react-submodules/providers/Timeclock";
import { FC, useEffect, useMemo, useState } from "react";

import { QueueTicket } from "../../react-submodules/types/ticketqueue";
import { useQueueParams } from "../../react-submodules/hooks/useQueueParams";
import { useTicketApi } from "../../react-submodules/providers/TicketApiProvider";

const PunchItem: FC<{ punch: CoreTimeclock , now : number , selected : boolean }> = ({ punch , now , selected }) => {
    const theme = useTheme();
    const { changeTicket } = useQueueParams();
    // const { } = useTicketApi();
    const punchTime = useMemo( () => {
        if( !punch ) return null;
        const start = punch?.start ? new Date( punch.start ).getTime() : 0;
        const secondsFull = (now - start) / 1000;
        const seconds = secondsFull < 0 ? 0 : secondsFull;
        const minutes = Math.floor( seconds / 60 ) % 60;
        const hours = Math.floor( seconds / 60 / 60 );
        if( hours ) {
            return `${hours}h ${minutes}m`
        }
        return `${minutes}m`;
      } , [ punch?.start , now ] );


    return <Box sx={{ 
        background : selected ? theme.palette.info.main : theme.palette.warning.light ,
        padding: 1,
        borderRadius: 2,
        marginBottom: 1,
    }} onClick={()=>{
        changeTicket(`${punch.ticketId}`);
    }}>
        <Box>
            <Typography variant="body1">{punchTime}</Typography>
            <Typography variant="body2">{punch.at_title}</Typography>
        </Box>
    </Box>  
}


export const TicketSidepanel: FC<{ ticket: QueueTicket | null }> = ({ ticket }) => {
    const { activePunches } = useTimeclock();
    const [ now , setNow ] = useState( new Date().getTime() );
    useEffect( () => {
        const interval = setInterval( () => {
            setNow( new Date().getTime() );
        }, 15000);
        return () => {
            clearInterval( interval );
        }
    }, []);
    return <List sx={{width: '200px'}}>
        { activePunches.map( p => <PunchItem now={now} key={p.id} punch={p} selected={p.ticketId === ticket?.id} />
        ) }
    </List>
}