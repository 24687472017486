import { FC, useState } from "react"
import { IPaginatedType } from "../../../coretypes/graphql/QueryResponse";
import { NestedPick } from "../../../coretypes/graphql/DeepPartial";
import { TAiChat } from "../../../coretypes/ai/chat";
import { gql, useMutation, useQuery } from "@apollo/client";
import { List, ListItem, ListItemText, TextField, InputAdornment, Button, Box } from "@mui/material";
import { useUser } from "../../providers/User";
import { useParams } from "react-router-dom";
const CHAT_SEARCH = gql`
query ChatSearch {
  findAIChatPaginated {
    data {
      id
      name
      ticket {
        id
        title
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
}
`;

type ChatResponse = {
findAIChatPaginated: {
    data: Array<{
        id: number;
        name: string;
        user: {
            id: string;
            firstName: string;
            lastName: string;
        };
        ticket?: {
            id: number;
            title?: string;
        };
    }>;
} 
}


const CREATE_CHAT = gql`
mutation CreateChat($name: String, $ticketId: Float, $coreUserId: String) {
  createAIChat(name: $name, ticketId: $ticketId, coreUserId: $coreUserId) {
    id
  }
}
`;

export const ChatList : FC<{
    onSelect: (chat: number) => void
}> = ({onSelect}) => {
    const { ticketId } = useParams();
    const { data, refetch } = useQuery<ChatResponse>(CHAT_SEARCH);
    const [createChat] = useMutation(CREATE_CHAT , {
        onCompleted: ( item ) => {
            // refetch();
            onSelect(item.createAIChat.id);
        }
    });
    const [ name , setName ] = useState<string>('');
    const user = useUser();
    const createChatForUser = () =>{
        createChat({
            variables: {
                name,
                coreUserId: user?.sub ?? null
            }
        });
    }
    const createChatForTicket = () =>{
        createChat({
            variables: {
                name,
                ticketId: parseInt(ticketId ?? '0'),
                coreUserId: user?.sub ?? null
            }
        });
    }

    return <>
    <Box sx={{m : 2 , width : 'calc(100% - 32px)'}}><TextField 
        label="Chat Name" 
        size="small"
        value={name} 
        fullWidth
        onChange={(e) => setName(e.target.value)}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <Button onClick={() => createChatForUser() } size="small" variant="outlined" style={{ marginRight: '8px' }}>
                        For Me
                    </Button>
                    { ticketId && <Button size="small" variant="outlined" onClick={createChatForTicket}>
                        For Ticket
                    </Button> }
                </InputAdornment>
            ),
        }}
    />
    </Box>
    <List>
        <ListItem>
            
        </ListItem>
        {data?.findAIChatPaginated?.data.map((chat) => (
            <ListItem key={chat.id} onClick={() => onSelect(chat.id)}>
                <ListItemText primary={chat.name || 'Untitled Chat'} secondary={chat.ticket?.title} />
            </ListItem>
        ))}
    </List></>
}