import { Box, Card,  TextField, CardContent, Typography, Button, Chip, Switch, Grid } from "@mui/material";
import { FC, useState } from "react";
import { StyledTab } from "../StyledTab";
import { StyledTable } from "../../Table";
import { StatusToColor } from "../../TicketPartials/status.util";
import { LabeledBox } from "../../LabeldBox";
import { useTicketEmailsForAttachmentsQuery } from "./gql/TicketEmailsForAttachments.generated";
import { AttachmentChip } from "../../AttachmentChip";

export const AttachmentsTabPanel : FC<{
    ticketId : number;
    value : string;
}> = ({ ticketId , value }) => {
    const { data } = useTicketEmailsForAttachmentsQuery({
        variables: {
            autotaskTicketId: ticketId,

        }
    });
    const allAttachments = ( data?.findAzureEmailPaginated?.data || [] ).flatMap( e => e.AzureEmailAttachments || [] ).filter( a => a.isInline === false );
    return <StyledTab label="Attachments">
    <Box>
        {allAttachments.map(a => {
            return <AttachmentChip attachment={{
                name: a.name || '',
                contentLocation: a.contentLocation || '',
                isInline: a.isInline || false
            }} key={a.id} />
        })}
    </Box>
</StyledTab>
}