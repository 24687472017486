import { Box, Card,  TextField, CardContent, Typography, Button, Chip, Switch, Grid } from "@mui/material";
import { FC, useState } from "react";
import { useTicketSearchTabQuery } from "./gql/TicketSearchTab.generated";
import { StyledTab } from "../StyledTab";
import { StyledTable } from "../../Table";
import { StatusToColor } from "../../TicketPartials/status.util";
import { LabeledBox } from "../../LabeldBox";

export const TicketSearchTabPanel : FC<{
    coreCompanyId? : number;
    autotaskContactId? : number;
    coreContactId? : number;
    value : string;
    skipWidth? : boolean;
}> = ({ coreCompanyId , autotaskContactId , coreContactId , value , skipWidth = false }) => {
    const [search, setSearch] = useState('');
    const [ status , setStatus ] = useState<number | null>(null);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const { data , loading , refetch } = useTicketSearchTabQuery({
        variables: {
            coreCompanyId,
            autotaskContactId,
            coreContactId,
            search,
            limit,
            page,
            status
        },
        fetchPolicy: 'cache-and-network',
    });
    return <StyledTab label={value} sx={{overflow: 'auto' , height: '90%' , width: skipWidth ? '100%' : 'calc( ((70vw ) / 12) * 8 )'}} ><Box>
        <Grid container spacing={2} sx={{marginBottom: 1}}>
            <Grid item xs={12} md={9}>
                <TextField size="small" label="Search" value={search} onChange={(e) => setSearch(e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
                <LabeledBox label="Only Customer Waiting">
                    <Switch size="small" onChange={(e) => setStatus(e.target.checked ? 7 : null)} />
                </LabeledBox>
            </Grid>
        </Grid>
        <StyledTable
            enablePagination
            page={page}
            setPage={setPage}
            limit={limit}   
            setPageSize={setLimit}
            totalCount={data?.findAutotaskTicketsPaginated.totalCount || 0}
            columns={[
                {
                    headerName: 'Title',
                    field: 'title'
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    Element: ({ cell , row } : any ) => {
                        return <Chip label={row?.autotaskTicketStatus?.name||'Unknown'} sx={{backgroundColor: row.status === 7 ? 'warning.main' : StatusToColor(row?.autotaskTicketStatus?.name)}} />
                    }
                },
                {
                    headerName: 'Ticket Number',
                    field: 'ticketNumber',
                    Element: ({ cell , row }) => {
                        return <Button size="small" variant="contained" onClick={()=>{
                            window.open( `/ticketqueue/all/${row.id}` , `ticket-${row.id}` );
                        }}>{row.ticketNumber}</Button>
                    }
                }
            ]}
            rows={data?.findAutotaskTicketsPaginated.data || []}
        />
    </Box></StyledTab>;
}