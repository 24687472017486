import { FC } from "react"
import { useMergedTicketInfoQuery } from "./gql/MergedTicketInfo.generated";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Typography } from "@mui/material";
import { MERGE_ICON, MERGE_ICON_DEFAULT_COLOR } from "../../../constances/icons";
import { Icon } from "@iconify/react/dist/iconify.js";
import { MarkdownView } from "../../../MarkdownView";
import { LabeledBox } from "../../LabeldBox";
import { useUserInfoQuery } from "./gql/UserInfo.generated";

export const MergedTicket : FC<{mergedTicketId : number}> = ({mergedTicketId}) => {
    const { data } = useMergedTicketInfoQuery({ variables: { mergedTicketId } });
    const { data: userData } = useUserInfoQuery({ variables: { userId: data?.getAutotaskTicketsById?.mergedByUserId || '' } , skip: !data?.getAutotaskTicketsById?.mergedByUserId });
    return <Accordion>
        <AccordionSummary>
        <Box sx={{ display: 'flex', alignItems: 'center' , paddingRight: 1 , color : MERGE_ICON_DEFAULT_COLOR }}>
                    <Icon icon={MERGE_ICON} />
                </Box>
                    
                
                <Typography sx={{ paddingLeft: 1, whiteSpace: 'nowrap' }}>
                    {new Date(data?.getAutotaskTicketsById?.mergedDate || data?.getAutotaskTicketsById?.lastActivityDate).toLocaleString('en-US', { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(",", "")}
                </Typography>
                <Typography sx={{ paddingLeft: 1 , fontWeight: 'bold'}}>{data?.getAutotaskTicketsById?.ticketNumber} : {data?.getAutotaskTicketsById?.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Alert severity="info" sx={{marginBottom: 1}}>
                        { userData?.getCoreUsersById?.id && <>Ticket Merged By {userData?.getCoreUsersById?.firstName} {userData?.getCoreUsersById?.lastName} on {new Date(data?.getAutotaskTicketsById?.mergedDate || data?.getAutotaskTicketsById?.lastActivityDate).toLocaleString('en-US', { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(",", "")}</>}
                    </Alert>
                    <LabeledBox label="Ai Summary">
                        <MarkdownView  >
                            {data?.getAutotaskTicketsById?.aisummary  || ''}
                        </MarkdownView>
                    </LabeledBox>
                    <LabeledBox label="Description">
                        <MarkdownView  >
                            {data?.getAutotaskTicketsById?.description  || ''}
                        </MarkdownView>
                    </LabeledBox>
                </AccordionDetails>
    </Accordion>
}