import { FC, ReactElement, useEffect, useState } from "react";
import { TTodosTodo } from "../../../types/todos.dto";
import { Box, Button, CircularProgress, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { Dialog } from "@mui/material";
import { useCreateTicketFromTodoMutation } from "./gql/CreateTicketFromTodo.generated";
import { useUpdateTodosTodoMutation } from "./gql/UpdateTodo.generated";
import { useGetTicketDetailsQuery } from "./gql/GetTicketDetails.generated";
import { GqlCompany } from "../../../components/TicketPartialGraphql/Company/GqlCompany";
import { GqlContact } from "../../../components/TicketPartialGraphql/Contact/GqlContact";
import { GqlTitle } from "../../../components/TicketPartialGraphql/Title/Title";
import { GqlDescription } from "../../../components/TicketPartialGraphql/Description/Description";
import { GqlNextActionNote } from "../../../components/TicketPartialGraphql/NextActionNote/GqlNextActionNote";
import { GqlNextActionDate } from "../../../components/TicketPartialGraphql/NextActionDate/GqlNextActionDate";
import { GqlQueue } from "../../../components/TicketPartialGraphql/Queue/GqlQueue";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../providers/Api";
import { DialogActions } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

export const TodoToTicketDialog: FC<{todo: TTodosTodo, open: boolean, onClose: () => void}> = ({todo, open, onClose}): ReactElement => {
    const api = useApi();
    const navigate = useNavigate();
    
    // Fetch ticket details from the todo's associated ticket
    const { data: ticketData, loading: ticketLoading } = useGetTicketDetailsQuery({
        variables: { id: todo.autotaskTicketId },
        skip: !todo.autotaskTicketId
    });

    // State for the new ticket
    const [ticket, setTicket] = useState({
        coreCompanyId: 0,
        coreContactId: null as number | null,
        title: todo.title || "",
        status: 1,
        queueID: 0,
        priority: 2,
        contactlessStart: false,
        parentTicketId: null as number | null,
        description: todo.description || "",
        nextActionDate: todo.nextActionDate || null,
        nextActionTime: todo.nextActionTime || null,
        nextActionType: todo.nextActionType || null,
        nextActionNote: ""
    });
    
    // State to track if a ticket has been created
    const [createdTicketId, setCreatedTicketId] = useState<number | null>(null);

    // Update the ticket state when ticket data is loaded
    useEffect(() => {
        if (ticketData?.getAutotaskTicketsById) {
            const ticketInfo = ticketData.getAutotaskTicketsById;
            setTicket({
                coreCompanyId: ticketInfo.coreCompanyId || 0,
                coreContactId: ticketInfo.coreContactId !== undefined ? ticketInfo.coreContactId : null,
                title: todo.title || ticketInfo.title || "",
                status: ticketInfo.status || 1,
                queueID: ticketInfo.queueID || 0,
                priority: ticketInfo.priority || 2,
                contactlessStart: ticketInfo.contactlessStart || false,
                description: todo.description  || "",
                nextActionDate: todo.nextActionDate || ticketInfo.nextActionDate || null,
                nextActionTime: todo.nextActionTime || ticketInfo.nextActionTime || null,
                parentTicketId: ticketInfo.id || null,
                nextActionType: todo.nextActionType || 
                    (ticketInfo.nextActionType as "FLEXABLE" | "FIRM" | null) || null,
                nextActionNote:  ""
            });
        }
    }, [ticketData?.getAutotaskTicketsById?.id , todo]);

    // Mutation for creating a ticket
    const [createTicket, { loading: createLoading }] = useCreateTicketFromTodoMutation({
        
        onCompleted: (data) => {
            // Update the todo to mark it as complete and link it to the new ticket
            updateTodo({
                variables: {
                    id: todo.id,
                    complete: true,
                    spawnedTicketId: data.createAutotaskTickets.id
                }
            });
            
            // Clock in
            api.post('/core/timeclock/clockin', {
                ticketId: data.createAutotaskTickets.id
            });
            
            // Store the created ticket ID
            if (data.createAutotaskTickets.id !== undefined) {
                setCreatedTicketId(data.createAutotaskTickets.id);
            }
        }
    });

    // Mutation for updating the todo
    const [updateTodo, { loading: updateLoading }] = useUpdateTodosTodoMutation();

    // Handle create ticket button
    const handleCreateTicket = () => {
        // Extract only the fields needed for the mutation
        const ticketData = {
            coreCompanyId: ticket.coreCompanyId,
            coreContactId: ticket.coreContactId,
            title: ticket.title,
            status: ticket.status,
            queueID: ticket.queueID,
            priority: ticket.priority,
            contactlessStart: ticket.contactlessStart,
            description: ticket.description,
            nextActionDate: ticket.nextActionDate,
            nextActionTime: ticket.nextActionTime,
            nextActionType: ticket.nextActionType,
            parentTicketId: ticket.parentTicketId
        };
        
        createTicket({
            variables: ticketData
        });
    };
    
    // Handle navigation to the created ticket
    const handleGoToTicket = () => {
        if (createdTicketId) {
            navigate(`/ticketqueue/all/${createdTicketId}`);
            onClose();
        }
    };

    const loading = ticketLoading || createLoading || updateLoading;

    // Show loading dialog
    if (ticketLoading|| !ticket?.parentTicketId) {
        return (
            <Dialog 
                open={open} 
                onClose={onClose}
                maxWidth="md"
                fullWidth
            >
                <DialogContent>
                    <Box display="flex" justifyContent="center" alignItems="center" p={4}>
                        <CircularProgress />
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }
    
    // Show success dialog if ticket was created
    if (createdTicketId) {
        return (
            <Dialog 
                open={open} 
                onClose={onClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <CheckCircle color="success" sx={{ mr: 1 }} />
                        <Typography variant="h6">Ticket Created</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        The todo has been successfully converted to a ticket.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Close
                    </Button>
                    <Button 
                        onClick={handleGoToTicket} 
                        color="primary" 
                        variant="contained"
                    >
                        Go To Ticket
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    // Main dialog for creating ticket
    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <Typography variant="h6">Convert Todo to Ticket</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={6}>
                        <GqlContact 
                            ticket={ticket}
                            edit={true} 
                            onChange={setTicket}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <GqlCompany 
                            ticket={ticket}
                            edit={true} 
                            onChange={setTicket}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GqlTitle 
                            ticket={ticket}
                            edit={true} 
                            onChange={setTicket}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GqlDescription
                            ticket={ticket}
                            edit={true} 
                            onChange={setTicket}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GqlNextActionNote
                            ticket={ticket}
                            edit={true} 
                            onChange={setTicket}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GqlNextActionDate
                            ticket={ticket}
                            edit={true} 
                            onChange={setTicket}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {ticket.queueID}
                        <GqlQueue
                            ticket={ticket}
                            edit={true} 
                            onChange={setTicket}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button 
                    onClick={handleCreateTicket} 
                    color="primary" 
                    variant="contained"
                    disabled={loading}
                >
                    Create Ticket
                </Button>
            </DialogActions>
        </Dialog>
    );
}