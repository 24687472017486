import { Alert, Button, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, MenuItem, Switch, TextField, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";

import { Info } from "@mui/icons-material";
import { MarkdownCheatLink } from "../MarkdownCheatLink";
import { MarkdownView } from "../../MarkdownView";
import { TTodosTodo } from "../../types/todos.dto";
import { useApi } from "../../providers/Api/Api";
import { useTicket } from "../../providers/TicketProvider";
import { useTicketApi } from "../../providers/TicketApiProvider";
import { MarkdownEditor } from "../MarkdownEditor/MarkdownEditor";
import NewQuestion from "./NewQuestion";
import NewTask from "./NewTask";

export const todoTypes = [
    { 
        label: 'Task',
        value: 'task'
    },
    { 
        label: 'Question',
        value: 'question'
    }
]
export type TodoType = 'task' | 'question';



export const NewTodoDialog : FC<{open : boolean , onClose : () => any , todo?: Partial<TTodosTodo> }> = ({ open , onClose , todo }) => {  
    
    const {ticket} = useTicket();
    const api = useApi();
    const [ title , setTitle ] = useState('');
    const [ description , setDescription ] = useState('');
    const [ type , setType ] = useState( todo?.noteType || 'task');
    const [ groupId , setGroup ] = useState<number | null>(null);
    const [ userId , setUser ] = useState<string | null>(null);
    const [ dueDate , setDueDate ] = useState<string | null>(null);
    const [ ticketBlocking , setTicketBlocking ] = useState(false);

    useEffect( () => {
        const today = new Date().toISOString().split('T')[0];
        if( !dueDate && groupId === 1  ){
            setDueDate( today );
        }
    }, [ groupId , dueDate]);
    const submit = ()=>{
        if( (!groupId && !userId) || title === "" ) return;
        api.post('/todos/todo', { title , description , noteType : type , groupId , coreUserId : userId , autotaskTicketId : ticket?.id || null , dueDate , ticketBlocking }).then( ()=> {
            onClose();
        })
    }

    useEffect( () => {
        if( ticketBlocking && type === 'task'){
            setTicketBlocking( false );
        }
    } , [ ticketBlocking , type ])

    return <Dialog maxWidth="lg" open={open} onClose={()=>{
        onClose();
    }}>
        { type === "question" && <NewQuestion 
            submit={submit} 
            title={title} 
            setTitle={setTitle} 
            description={description} 
            setDescription={setDescription} 
            type={type} 
            setType={setType} 
            dueDate={dueDate} 
            setDueDate={setDueDate} 
            ticketBlocking={ticketBlocking} 
            setTicketBlocking={setTicketBlocking} 
            groupId={groupId} 
            setGroup={setGroup} 
            userId={userId} 
            setUser={setUser} 
        />}
        { type === "task" && <NewTask  
            submit={submit} 
            title={title} 
            setTitle={setTitle} 
            description={description} 
            setDescription={setDescription} 
            type={type} 
            setType={setType} 
            dueDate={dueDate} 
            setDueDate={setDueDate} 
            ticketBlocking={ticketBlocking} 
            setTicketBlocking={setTicketBlocking} 
            groupId={groupId} 
            setGroup={setGroup} 
            userId={userId} 
            setUser={setUser} 
        />}``
        
    </Dialog>
}