import { FunctionComponent } from "react";
import { Badge, Chip } from "@mui/material";

export const CpuScore : FunctionComponent<{score?: string}> = ( {score} ) => {
    if( !score ){
        return <></>
    }
    const scoreNumber = parseInt( score.replace( /,/g , '' ) );
    if( scoreNumber > 15000 ){
        return <Badge badgeContent={"A"} color="success"><Chip label={`${score}`}  /></Badge>
    }
    if( scoreNumber > 12000 ){
        return <Badge badgeContent={"B"} color="success"><Chip label={`${score}`}  /></Badge>
    }
    if( scoreNumber > 8000 ){
        return <Badge badgeContent={"C"} color="info"><Chip label={`${score}`}  /></Badge>
    }
    if( scoreNumber > 5000 ){
        return <Badge badgeContent={"D"} color="warning"><Chip label={`${score}`}  /></Badge>
    }
    return <Badge badgeContent={"F"} color="error" ><Chip label={`${score}`}  /></Badge>
}