import { FC } from "react";
import { useTechFinderUsersQuery } from "./gql/ActiveUsers.generated";
import { UserListItem } from "./components/UserListItem";
import { Button } from "@mui/material";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

export const TechFound : FC<{ techFound?: string , accepted?: boolean , resend?: () => void }> = ({ techFound , accepted , resend }) => {
    const { data: techFoundData } = useTechFinderUsersQuery({
        variables: {
            coreUserId: techFound
        },
        fetchPolicy: 'network-only',
        pollInterval: 10000
    });
    const techFoundUser = techFoundData?.findCoreUsersPaginated.data?.[0];
    return (
        <div>
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography variant="h6" sx={{ pb: 0 }}>
            Found a Tech
          </Typography>
          <Typography variant="caption" sx={{ pt: 0, pb: 1 }}>
            Tech to transfer to
          </Typography>
        </Box>
      </Box>
            {techFoundUser && <UserListItem 
            key={techFoundUser?.id} 
            user={{...techFoundUser , techFinderStatus : 'accepted'}} 
            noBorder={false}
            isActive={false}
            timeoutSeconds={undefined}
            onStatusChange={() => {}}
          />}
          {accepted && <Button onClick={resend}>Resend</Button>}
        </div>
    )
}