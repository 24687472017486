import { Alert, Box, Button } from "@mui/material";

import { useMemo } from "react";
import { useTicket } from "../../providers/TicketProvider";
import { useTicketApi } from "../../providers/TicketApiProvider";
import { useTimeclock } from "../../providers/Timeclock";
import { todos_view1 } from "../../../stories/testdata/TodoSidebar.data";

export const NewTicketAlert = () => {
    const {ticket} = useTicket();
    const { activePunches } = useTimeclock();
    const ticketIds = useMemo( () => activePunches.map( p => p.ticketId ) , [ JSON.stringify(activePunches) ]);
    const { nextTicket , getNextTicket } = useTicketApi();    
    const showTicket = useMemo( () => {
        if( !nextTicket ) return false;
        if( !ticket ) return false;
        if( ticket.id === nextTicket.id) return false;
        if( ticketIds.includes( nextTicket?.id || 0 ) ){
            return false;
        }
        if( nextTicket.statusId === 33 ) return true;
        if( nextTicket.nextActionType === "FIRM" ) return true;
        if( nextTicket.fromRole === "triage" ) return true;
        if( nextTicket.fromRole === "todo" ) {
            const todo = nextTicket.todos.find( t => Number(t.id) === Number(nextTicket.todoId) );
            if( todo?.nextActionType === "FIRM" ) return true;
        };
        return false;
    } , [ JSON.stringify(ticket) , JSON.stringify(nextTicket) , JSON.stringify(ticketIds) ]);
    if( ticket && nextTicket && showTicket ) {
        
        return <Alert severity="error"><Button size="small" variant="contained" color="error" onClick={()=>{
            getNextTicket( true );
        }}>Get Latest Ticket</Button> This is no longer the most important ticket! Please switch to <b>{nextTicket?.ticketNumber}  - {nextTicket?.title}</b> </Alert>
    }
    return <></>
}