import './ReportItemDialog.css';

import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material"
import { FC, useMemo } from "react"
import { ReportData } from "./ReportActivity.types"
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import luxon3Plugin from "@fullcalendar/luxon3";
import { DateTime } from "luxon";
import FullCalendar from "@fullcalendar/react";
import { getColorFromString } from "../../../../react-submodules/utils/colors";
import { L } from "@fullcalendar/resource/internal-common";

const formatPhoneNumber = ( phoneNumber: string | null | undefined ) => {
  if( phoneNumber === null || phoneNumber === undefined ){
    return '';
  }
  if( phoneNumber.length === 10 ){
    return phoneNumber.replace( /(\d{3})(\d{3})(\d{4})/ , '($1) $2-$3' );
  }
  if( phoneNumber.length === 11 ){
    return phoneNumber.replace( /(\d{1})(\d{3})(\d{3})(\d{4})/ , '($2) $3-$4' );
  }
  return phoneNumber;
}


const EventContent: FC<{ event: any }> = ({ event }) => {
    const instance : { call : ReportData['calls'][number] } = event.extendedProps;
    
  
    return (
      <div className="fc-event-main-frame">
        <div className="fc-event-title-container">
          <div className="fc-event-title fc-sticky">
            <span className='call-id-title'>{event.title && `[${event.title}]`}</span> { instance?.call?.direction &&  formatPhoneNumber( instance?.call?.direction === 'inbound' ? instance.call.fromNumber : instance.call.toNumber )} {instance?.call?.CoreCompany?.name}
          </div>
        </div>
      </div>
    );
  };

export const ReportItemDialogPhoneTab : FC<{ item : ReportData | null , resources : Array<{ id : string , title : string }> }> = ({ item , resources }) => {
    const callLegs = useMemo( () => {
        return item?.calls.map( c => c.PhoneCallLegs?.map(pl => { return { call: c ,  ...pl } } ) ).flat().map( l => {
            return {
                title: `${l?.phonesCallId || 'unset'}`,
                color: getColorFromString( l?.phonesCallId || 'unset' , '088ace71-b9f3-434f-9ee6-e9a44941ee6a' , 90 ),
                id: String(l?.id || ''),
                start: l?.startTime || '',
                end: l?.endTime || '',
                resourceId: l?.CoreUser?.id || 'unset',
                extendedProps: {
                    ...l,
                    item
                }
            }
        }) ||[];
    } , [ item ] );
    const date = DateTime.fromISO( callLegs[0]?.start || DateTime.now().toISO() );
    const usedResources = useMemo( () => {
        return resources.filter( r => callLegs?.some( l => l.resourceId === r.id ) || r.id === item?.resourceId ).map( u => {
            if( u.id === 'unset' ) {
                return { ...u , title : 'Ringing/Unknown' };
            };
            return u;
        });
    } , [ callLegs , resources ] );
    return <>
    <FullCalendar
    nowIndicator
    eventContent={EventContent}
    initialView={"resourceTimeGridDay10AM"}
    headerToolbar={{
      right:
        "resourceTimeGridDay10AM",
    }}
    resourceLabelClassNames={(arg) => {
        return arg.resource.id === item?.resourceId ? 'highlighted-resource' : '';
    }}
    views={{
      resourceTimeGridDay10AM: {
        type: 'resourceTimeGrid',
        slotMinTime: `${DateTime.fromISO( item?.start || DateTime.now().toISO() ).toFormat('HH:mm:ss')}`,
        slotMaxTime: `${DateTime.fromISO( item?.end || DateTime.now().toISO() ).toFormat('HH:mm:ss')}`,
        buttonText: 'Day (10AM-11AM)',
        slotDuration: '00:01:00',
      },
    }}
    editable={false}
    initialDate={date.toJSDate()}
    plugins={[resourceTimeGridPlugin, luxon3Plugin, interactionPlugin]}
    resources={usedResources}
    contentHeight="auto"
    stickyFooterScrollbar={true}
    stickyHeaderDates={true}
    resourceOrder="title"
    
    events={[...callLegs , {...item , display: 'background' , color: '#fff3cd' } ]}
    resourceAreaWidth="20%"
    schedulerLicenseKey="0535070809-fcs-1715174460"
  /></>;
}