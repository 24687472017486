import { useEffect, useState } from "react"

import { MenuItem, TextField } from "@mui/material";
import { useApi } from "../../react-submodules/providers/Api/Api";
import { CoreUsers } from "../../coretypes/users.dto";
import { useSearchParam } from "../../react-submodules/utils/reactrouter";

export const TicketDebug = () => {
    const api = useApi();
    const [ serverTime , setServerTime ] = useState<Record<string,any> | null >(null);
    const [ debugInfo , setDebugInfi ] = useState<Record<string,any> | null >(null);
    const [ role , setRole ] = useState<string | null >(null);
    const [ticketId , setTicketId ] = useSearchParam<number>('ticketId');
    const [ users , setUsers ] = useState<CoreUsers[]>([]);
    const [userId , setUserId ] = useSearchParam<string>('userId');

    const [ refreshTicket , setRefreshTicket ] = useState(1);
    useEffect( () => {
        const interval = setInterval( () => {
            setRefreshTicket( r => r + 1);
        } , 1000);
        return () => clearInterval(interval);
    } )
    useEffect( () => {
        if( !api.ready ){
            return ;
        }
        api.get('/core/users').then( ( { data }) => {
            setUsers( data );
        });
    } , [api.ready]);
    useEffect( () => {
        if( !api.ready || !ticketId || !userId){
            return ;
        }
        api.get(`/ticketqueue/debug/${userId}/${ticketId}/${role || 'all'}`).then( ( { data }) => {
            setDebugInfi( data.debugRank );
        });
    } , [userId , ticketId , refreshTicket ]);
    useEffect( () => {
        if( !api.ready ){
            return ;
        }
        api.get('/core/servertime').then( ( { data }) => {
            setServerTime( data );
        });
    }, [ api.ready ]);
    return <>
        <b>Server Info</b>
        <pre>
            {JSON.stringify(serverTime , null , 2)}
        </pre>
        <TextField label="Ticket ID" value={ticketId} onChange={(e)=>setTicketId(parseInt(e.target.value))} />
        <TextField sx={{ width: 300 }} select label="User ID" value={userId} onChange={(e)=>setUserId(e.target.value)}>
            {users.map( ( user ) => (
                <MenuItem key={user.id} value={user.id}>{user.firstName} {user.lastName}</MenuItem>
            ))}
        </TextField>
        <TextField label="Role" value={role} onChange={(e)=>setRole(e.target.value)} />
        <pre>
            {JSON.stringify(debugInfo , null , 2)}
        </pre>
    </>
}