import { PieChart } from "@mui/x-charts";
import { CoreUser } from "../../react-submodules/types/core";
import { TUserStatus } from "../../react-submodules/types/opscompliance";
import { FC, useMemo } from "react";
import { Box, Button, Theme, Typography, useTheme } from "@mui/material";
import { getCategoryColors } from "../../react-submodules/utils/charts";


const categories = ["Good", "Not Working", "Not Dispatchable", "Not working Dispatched", "Other"];


const getDataCategory = (data: TUserStatus) => {
    if( data.isWorkingDispatched || data.isOnCall ){
        return "Good";
    }
    if( data.isDispatchable && !data.hasDispatch ){
        return "Good";
    }
    if( !data.isWorking ){
        return "Not Working";
    }
    if(!data.isDispatchable){
        return "Not Dispatchable";
    }
    if( data.isDispatchable && data.isWorking){
        return "Not working Dispatched";
    }
    return "Other";
}




export const TechPieChart : FC<{
    user: CoreUser,
    data: TUserStatus[]
}> = ({user, data}) => {
    const theme = useTheme();
    const usersData : Array<TUserStatus & {minutes: number, category: string}> = useMemo(() => {
        return data.filter(d => d.coreUserId === user.id).filter( d => d.isTimwoodsClockedIn).map( d => {
            const end = d.end ? new Date(d.end) : new Date();
            const start = new Date(d.start);
            const minutes = Math.floor((end.getTime() - start.getTime()) / (1000 * 60));
            const category = getDataCategory(d);
            return {
                ...d,
                end: end.toISOString(),
                minutes: minutes,
                category: category,
                
            }
        })
    }, [data, user]);
    const results = useMemo( () => {
        const total = usersData.reduce((acc, d) => acc + d.minutes, 0);

        return categories.map(c => {
            const categoryData = usersData.filter(d => d.category === c);
            const cattotal = categoryData.reduce((acc, d) => acc + d.minutes, 0);
            return {
                id: c,
                value: Math.round((cattotal / total) * 100),
                label: c,
                color: getCategoryColors(c, theme)
            }
        })
    } , [usersData]);
    if( usersData.length === 0 ){
        return <></>
    }
    return <Box sx={{
        display: "flex",
        flexDirection: "column",
        position:'relative'
    }}>
    <Typography variant="h6">Status %</Typography>
    <Box  sx={{
        position:'relative',
        top: 0,
        left: 0,
        width: 550,
        height: 200
    }}
    ><Box sx={{}}><PieChart
    style={{
        position: "absolute",
        top: 0,
        left: '-100px',
        width: 550,
        height: 200
    }}
                series={[
                    {
                    data: results,
                    },
                ]}
                width={550}
                height={200}
                /></Box>
                </Box>
        </Box>
}

