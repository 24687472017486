import { Box, Card, CardContent, CardHeader, Grid, MenuItem, Switch, TextField } from "@mui/material"
import { Handle, Position } from "reactflow"
import { useNodeDataProperty, useRecipie } from "../../../../providers/Recipies.provider";
import { useMemo } from "react";


const events = [
    /*{
        name: "Ticket Created",
        value: "core.ticket.created"
    },
    {
        name: "Ticket Updated",
        value: "core.ticket.updated"
    },*/
    {
        name: "Rocket Cyber Incident Created",
        value: "rocketcyber.incident.created"
    },
    /*{
        name: "Rocket Cyber Incident Updated",
        value: "rocketcyber.incident.updated"
    }*/
];


export const EventListenerNode = ({ id , data } : any ) => {
    const [ event , setEvent ] = useNodeDataProperty<string>( id , 'event' , '' );
    const [ title , setTitle ] = useNodeDataProperty( id , 'title');
    const [ active , setActive ] = useNodeDataProperty( id , 'active' , true );

    const isCustom = useMemo( () => {
        return !events.some( ( e ) => e.value === event );
    } , [ event ] );
    const effectiveEvent = isCustom ? 'recipe.event.custom' : event;
    const customEventName = useMemo( () => {
        return event?.replace( 'recipe.event.' , '' );
    } , [ event ] );
    const setCustomEvent = ( e : any ) => {
        setEvent( `recipe.event.${e.target.value}` );
    }
    return <Box>
        <Card sx={{maxWidth: '300px'}}>
            <CardHeader className="drag-handle" title="Event Listener" />
            <CardContent>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField sx={{padding: '2px'}} size="small" label="Title" value={title} onChange={(e) => {
                    setTitle( e.target.value );
                }} fullWidth />
            </Grid>
            <Grid item xs={12}>
            <TextField select sx={{ marginTop: 2 , padding: '2px'}} size="small" label="Event" value={effectiveEvent} onChange={(e) => {
                setEvent( e.target.value );
            }} fullWidth >
                {events.map( ( event ) => {
                    return <MenuItem key={event.value} value={event.value}>{event.name}</MenuItem>
                })}
                {/*<MenuItem value="recipe.event.custom">Custom</MenuItem>*/}
            </TextField>
            { isCustom && <TextField sx={{ marginTop: 2 , padding: '2px'}} size="small" label="Event" value={customEventName} onChange={setCustomEvent} fullWidth />}
            </Grid>
            <Grid item xs={12}>
                <Switch color="secondary" checked={active} onChange={(e)=>setActive(e.target.checked)} />
            </Grid>
                </Grid>
            </CardContent>
        </Card>
        <Handle
          type="source"
          position={Position.Right}
          id={`handle-source`}
        />
    </Box>
}