import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Switch, TextField, setRef, Box } from "@mui/material";
import { FC, useEffect, useState } from "react";

import { StyledTable } from "../../react-submodules/components/Table"
import { useApi } from "../../react-submodules/providers/Api/Api";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { MarkdownView } from "../../react-submodules/MarkdownView";
import { useSearchCallsQuery } from "./SearchCalls.generated";
import { PaginatedGraphqlQueryTable } from "../../react-submodules/components/PaginatedGraphqlQueryTable/PaginatedGraphqlQueryTable";


const DateTimeFormat : (date: string) => string = (date) => {
    const lux = DateTime.fromISO(date).setZone("America/Chicago");
    return lux.toLocaleString(DateTime.DATETIME_SHORT);
}

const AudioPlayer : FC<{url: string}> = ({url}) => {
    return <audio controls src={url} />
}


export const Calls = () => {
    const nav = useNavigate();
    const api = useApi();
    const [ transcription , setTranscription ] = useState<string>(''    );
    const [ refreshTick , setRefresh ] = useState(1);
    const [ page , setPage ] = useState(0);
    const [ limit , setLimit ] = useState(10);
    const [ startDate , setStartDate ] = useState<string>(DateTime.now().minus({days: 1}).toFormat('yyyy-MM-dd'));
    const [ endDate , setEndDate ] = useState<string>(DateTime.now().toFormat('yyyy-MM-dd'));
    
    const { data , loading } = useSearchCallsQuery({
        variables: {
            startTime: startDate,
            endTime: endDate
        }
    });
    return (
        <div>
            <h1>Calls</h1>
            <Paper>
                <Grid sx={{p: 2}} container spacing={2}>
                    <Grid item sm={6}>
                        <TextField fullWidth label="Start Date" type="date" value={startDate} onChange={(e) => {
                            setStartDate(e.target.value);
                        }} />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField fullWidth label="End Date" type="date" value={endDate} onChange={(e) => {
                            setEndDate(e.target.value);
                        }} />
                    </Grid>
                </Grid>
            </Paper>
            <StyledTable rows={data?.findPhonesCallsPaginated.data || []} columns={[{
                field: 'id',
                headerName: 'ID',
                width: 100
            } , {
                field: 'startTime',
                headerName: 'Date',
                width: 100,
                Element: ({cell}) => <div>{DateTimeFormat(cell)}</div>
            } 
            , {
                field: 'endTime',
                headerName: 'Date',
                width: 100,
                Element: ({cell}) => <div>{DateTimeFormat(cell)}</div>
            } 
            , {
                field: 'fromNumber',
                headerName: 'Originated By',
                width: 100,
                Element: ({cell}) => <div>{cell}</div>
            }
            , {
                field: 'toNumber',
                headerName: 'Destination',
                width: 100,
                Element: ({cell}) => <div>{cell}</div>
            }
            , {
                field: 'PhoneCallLegs',
                headerName: 'Transcription',
                width: 100,
                Element: ({cell  }) => <>{ cell && <Button onClick={() => {
                            console.log(cell);
                            const transcription = cell.map( ( c : any  ) => c.transcription || '' ).join('\n');
                            setTranscription(transcription);
                        }}>View</Button>
                    }
                </>
            },
            {
                field: 'localPath',
                headerName: 'File',
                width: 100,
                Element: ({row}) => { 
                    return <>{row.PhoneCallLegs && row.PhoneCallLegs.filter( ( c: any ) => c.downloadUrl ).map( ( c : any ) => <Box  key={c.callProviderId}><AudioPlayer url={c.downloadUrl} /></Box> )}</>
                }
            } 
            
            ]} 
            
            page={page}
            limit={limit}
            setPage={setPage}
            setPageSize={setLimit}

            enablePagination
            disableFrontendSorting
            
            />
            <Dialog open={Boolean(transcription)} onClose={() => {
                setTranscription('');
            }}>
                <DialogTitle>Transcription</DialogTitle>
                <DialogContent>
                    <MarkdownView>{transcription}</MarkdownView>
                </DialogContent>
            </Dialog>
        </div>
    );
}