import { FC, useState } from "react";
import { CoreLocations } from "../../../../coretypes/locations";
import { CoreContact } from "../../../../coretypes/contacts/contacts.dto";
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, Button, Select, MenuItem } from "@mui/material";
import { useEffect } from "react";
import { useApi } from "../../../../react-submodules/providers/Api";
import { Location } from "../../../../react-submodules/components/Location/Location";

export const AddEditContactDialog: FC<{
    open : boolean;
    coreCompanyId : number;
    title : string;
    onClose? : () => void;
    onSave : (contact : Partial<CoreContact & {coreLocationId : number}>) => void;
    initalValues? : Partial<CoreContact & {coreLocationId : number}>;
    onSaveText : string;
}> = ({ open, coreCompanyId, title, onClose, onSave, initalValues, onSaveText }) => {
    const [ locations, setLocations ] = useState<CoreLocations[]>([]);
    const api = useApi();
    const [firstName, setFirstName] = useState(initalValues?.firstName ||   "");
    const [lastName, setLastName] = useState(initalValues?.lastName || "");
    const [email, setEmail] = useState(initalValues?.email || "");
    const [selectedLocationId, setSelectedLocationId] = useState<number | null>(initalValues?.coreLocationId || null);
    const [phone, setPhone] = useState(initalValues?.phone || "");
    const [mobilePhone, setMobilePhone] = useState(initalValues?.mobilePhone || "");
    const [alternatePhone, setAlternatePhone] = useState(initalValues?.alternatePhone || "");
    const [phoneExtension, setPhoneExtension] = useState(initalValues?.phoneExtension || "");
    useEffect( () => {
        if( !coreCompanyId ){
            setLocations([]);
            return;
        }
        api.get('/core/locations/search', {
                coreCompanyId: Number(coreCompanyId)
        }).then( (response) => {
            setLocations(response.data);
        });
    }, [coreCompanyId]);
    useEffect( () => {
        console.log( { initalValues });
        setFirstName(initalValues?.firstName || "");
        setLastName(initalValues?.lastName || "");
        setEmail(initalValues?.email || "");
        setPhone(initalValues?.phone || "");
        setMobilePhone(initalValues?.mobilePhone || "");
        setAlternatePhone(initalValues?.alternatePhone || "");
        setPhoneExtension(initalValues?.phoneExtension || "");
        setSelectedLocationId(initalValues?.coreLocationId || null);
    } ,[open] )
    return <Dialog open={open} onClose={() => {
        onClose?.();
    }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <Grid container spacing={2} sx={{marginTop: 1}} >
                <Grid item xs={12} >
                    <TextField fullWidth size="small" label="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </Grid>
                <Grid item xs={12} >
                    <TextField fullWidth size="small" label="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </Grid>
                <Grid item xs={12} >
                    <TextField fullWidth size="small" label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />      
                </Grid>
                <Grid item xs={12} >
                    <TextField select fullWidth size="small" label="Location" value={selectedLocationId} onChange={(e) => setSelectedLocationId( Number(e.target.value) )} >
                        {locations.map( (location) => <MenuItem key={location.id} value={location.id} ><Location location={location} /></MenuItem> )}
                    </TextField>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{marginTop: 1}} >
                <Grid item xs={12} >
                    <TextField fullWidth size="small" label="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{marginTop: 1}} >
                <Grid item xs={12} >
                    <TextField fullWidth size="small" label="Phone Extension" value={phoneExtension} onChange={(e) => setPhoneExtension(e.target.value)} />
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{marginTop: 1}} >
                <Grid item xs={12} >
                    <TextField fullWidth size="small" label="Mobile Phone" value={mobilePhone} onChange={(e) => setMobilePhone(e.target.value)} />
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{marginTop: 1}} >
                <Grid item xs={12} >
                    <TextField fullWidth size="small" label="Alternate Phone" value={alternatePhone} onChange={(e) => setAlternatePhone(e.target.value)} />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="success" onClick={() =>{
                onSave({
                    firstName : firstName,
                    lastName : lastName,
                    email : email,
                    phone : phone,
                    mobilePhone : mobilePhone,
                    alternatePhone : alternatePhone,
                    coreLocationId : selectedLocationId || undefined,
                    coreCompanyId : coreCompanyId,
                    phoneExtension : phoneExtension
                });
            }}>{onSaveText}</Button>
        </DialogActions>
    </Dialog>
}