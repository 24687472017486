import { Box, Typography } from "@mui/material";
import { FC, ReactElement, useMemo } from "react";

import { getColorFromString } from "../utils/colors";

export const keysFromName = ( name : string  ) => {
    // Checking for SN - Some Name, or SON - Some Other Name
    const keys = (name || '').split('-');
    if( (keys[0] || '').trim().length <= 5 && (keys[0] || '').trim().toUpperCase() === (keys[0] || '').trim() ){
        return (keys[0] || '').trim();
    }
    const words = (name || '').trim().split(' ');
    if( words.length === 1 ){
        const word = words[0];
        return word.substring( 0 , 3 );
    }
    const word = words.map( ( w ) => w.substring( 0 , 1 ) ).join('').substring( 0 , 3 );
    return word;

}

export const AnyBadgeBaseSizes = {
    small: 16,
    medium: 24,
    large: 32,
}
export type AnyBadgeSize = keyof typeof AnyBadgeBaseSizes;




export const AnyBadge : FC<{ 
        name : string , 
        colorkey?: string | number  , 
        color?: string ,
        namespace? : string, 
        size?: AnyBadgeSize,
        widthMultiplyer?: number,
        widthMultiplier?: number,
        customkey?: string | JSX.Element | ReactElement
    }> = ({ name , colorkey , namespace , size , widthMultiplyer , widthMultiplier , color : propColor , customkey : propKey  }) => {

        const key = propKey ? propKey :  keysFromName( name );

        widthMultiplyer = widthMultiplyer || typeof key === "string" &&(key.length <= 2 ? 1 : key.length / 2 ) || 1;
        size = size || 'medium';
        widthMultiplier = widthMultiplier || 1;
    colorkey = colorkey || name;
    const color = useMemo(()=>{
        if( propColor ) return propColor;
        return getColorFromString( `${name}${namespace||''}` , '088ace71-b9f3-434f-9ee6-e9a44941ee6a' , 90 );
    } , [colorkey , `${namespace}` , propColor])
    return <Box sx={{
        backgroundColor: color,
        borderRadius: `${AnyBadgeBaseSizes[size]}px`,
        width: `${AnyBadgeBaseSizes[size]* widthMultiplyer*widthMultiplier}px`,
        height: `${AnyBadgeBaseSizes[size]}px`,
        textAlign: 'center',
        paddingTop: `${AnyBadgeBaseSizes[size] / 8 }px`,
        color: 'text.primary'
    }}><Typography sx={{
        fontSize: `${AnyBadgeBaseSizes[size] / 2 }px`,
        fontWeight : 'bold',
    }}>{key}</Typography></Box>
}