import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateScheduleInstanceMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  coreUserId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  startDateTime?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  endDateTime?: Types.InputMaybe<Types.Scalars['Date']['input']>;
}>;


export type UpdateScheduleInstanceMutation = { __typename?: 'Mutation', updateScheduleInstance: { __typename?: 'ScheduleInstance', id?: number | null, coreUserId?: string | null, startDateTime?: any | null, endDateTime?: any | null } };


export const UpdateScheduleInstanceDocument = gql`
    mutation UpdateScheduleInstance($id: Int!, $coreUserId: String, $startDateTime: Date, $endDateTime: Date) {
  updateScheduleInstance(
    id: $id
    coreUserId: $coreUserId
    startDateTime: $startDateTime
    endDateTime: $endDateTime
    overidePlan: true
  ) {
    id
    coreUserId
    startDateTime
    endDateTime
  }
}
    `;
export type UpdateScheduleInstanceMutationFn = Apollo.MutationFunction<UpdateScheduleInstanceMutation, UpdateScheduleInstanceMutationVariables>;

/**
 * __useUpdateScheduleInstanceMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleInstanceMutation, { data, loading, error }] = useUpdateScheduleInstanceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      coreUserId: // value for 'coreUserId'
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *   },
 * });
 */
export function useUpdateScheduleInstanceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScheduleInstanceMutation, UpdateScheduleInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScheduleInstanceMutation, UpdateScheduleInstanceMutationVariables>(UpdateScheduleInstanceDocument, options);
      }
export type UpdateScheduleInstanceMutationHookResult = ReturnType<typeof useUpdateScheduleInstanceMutation>;
export type UpdateScheduleInstanceMutationResult = Apollo.MutationResult<UpdateScheduleInstanceMutation>;
export type UpdateScheduleInstanceMutationOptions = Apollo.BaseMutationOptions<UpdateScheduleInstanceMutation, UpdateScheduleInstanceMutationVariables>;