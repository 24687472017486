export type GetScheduleInstanceColorInstance = {
    available? : boolean| null;
    schedulePlanId? : number| null;
    azureCalendarId? : string| null;
    conflictDetected? : boolean| null;
    coreUserId? : string| null;
    AzureCalendar?: Record<string,any> | null;
    SchedulePlan?: Record<string,any> | null;
}
/*
    https://colorffy.com/color-scheme-generator?color=769D3E
    #3e9d95
    #663e9d
    #3e759d
*/
export const getScheduleInstanceColor = ( instance : GetScheduleInstanceColorInstance  & Record<string,any>) => {
    
    if( instance.available ){
        return '#B2B29E';
    }
    if( instance.conflictDetected || !instance.coreUserId ){
        return  '#9d3e46';
    }
    if( instance.AzureCalendar){
        return '#3e759d';
    }
    if( instance.SchedulePlan?.name ){
        return '#769D3E';
    }
    return '#3e9d95';
}