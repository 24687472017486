import { AnyBadge, keysFromName } from "../AnyBadge";
import { Autocomplete, Box, InputAdornment, ListItem, ListItemText, TextField } from "@mui/material";
import { AutocompleteContact, QueueTicket } from "../../types/ticketqueue";
import { FC, useEffect, useMemo, useState } from "react";

import { BadgeAndName } from "./BadgeAndName";
import { useTicket } from "../../providers/TicketProvider";
import { useTicketApi } from "../../providers/TicketApiProvider";

const matchesOption = ( option : AutocompleteContact , partial : string ) : boolean => {
    if( option.firstName?.toLowerCase().includes( partial ) ) return true;
    if( option.lastName?.toLowerCase().includes( partial ) ) return true;
    if( option.label?.toLowerCase().includes( partial ) ) return true;
    if( option.emailAddress?.toLowerCase().includes( partial ) ) return true;
    if( option.phone?.toLowerCase().includes( partial ) ) return true;
    if( option.mobilePhone?.toLowerCase().includes( partial ) ) return true;
    if( option.company.companyName?.toLowerCase().includes( partial ) ) return true;
    return false;
}

const rankPartialMatch = ( option : AutocompleteContact , partial : string  ) : number => {
    if( option.firstName?.toLowerCase().startsWith( partial ) ) return 5000;
    if( option.lastName?.toLowerCase().startsWith( partial ) ) return 5000;
    if( option.firstName?.toLowerCase().includes( partial ) ) return 1000;
    if( option.lastName?.toLowerCase().includes( partial ) ) return 1000;
    if( option.label?.toLowerCase().includes( partial ) ) return 1000;
    if( option.emailAddress?.toLowerCase().includes( partial ) ) return 10;
    if( option.phone?.toLowerCase().includes( partial ) ) return 100;
    if( option.mobilePhone?.toLowerCase().includes( partial ) ) return 100;
    if( option.company.companyName?.toLowerCase().startsWith( partial ) ) return 900;
    if( option.company.companyName?.toLowerCase().includes( partial ) ) return 500;
    return 0;
}

const filterAndSortContacts = ( options : AutocompleteContact[] , state : any , companyId? : number | null ) => {
    return options.filter( ( option ) => {
        const keys = state.inputValue.toLowerCase().split(" ");
        return keys.reduce( ( pv : boolean , cv : string ) => {
            return pv && matchesOption( option , cv )
        } , true );
    }).map( ( option ) => {
        return {
            ...option,
            rank: state.inputValue.toLowerCase().split(" ").reduce( ( pv : number , cv : string ) => {
                return pv + rankPartialMatch( option , cv );
            } , 0 ) + ( option.company.id === companyId ? 10000 : 0 )
        }
    }).sort( ( a , b ) => {
        return b.rank - a.rank;
    } );
}



export const Contact: FC<{ ticket?: Partial<QueueTicket>, edit?: boolean; contactID?: number , onChange?: ( company : AutocompleteContact | null ) => any}> = (
    { contactID , edit , onChange , ticket }
) => {
    const { contacts } = useTicketApi();
    const [ reload , setReload ] = useState(false);
    const selectedContact = useMemo(() => {
        return contacts.find( ( c ) => {
            return c.id === contactID;
        });
    }, [JSON.stringify(contacts) ,contactID ]);


    useEffect( () => {
        setReload( true);
    } , [JSON.stringify( contacts ) ])
    useEffect( () => {
        setTimeout( () => {
            setReload( false );
        } , 1)
    } , [reload])
    if( !edit){
        return <>{selectedContact && <BadgeAndName phone={selectedContact.phone} displayName={selectedContact.label || ''} colorkey={`${contactID}`||undefined} />}</>
    }

    if( reload ){
        return <></>
    }
    return <Autocomplete
    value={selectedContact}
    options={contacts.filter( ( c , v , a ) => {
        return a.findIndex( ( c2 ) => c2.id === c.id ) === v;
    } ).map( c => {
        const companykey = keysFromName( c.company.companyName || '' );
        return { ...c , label : `[${companykey}] ${c.label} (${c.emailAddress})` }
    }).filter( ( c , v , a ) => {
        return a.findIndex( ( c2 ) => c2.label === c.label ) === v;
    } )}
    size="small"
    fullWidth
    onChange={(event , company) => {
        onChange && onChange( company );
    }}
    groupBy={(option) => {
        return ticket?.companyID === option.company.id ? 'Current Company' : 'Other Companies';
    }}
    
    
    filterOptions={(options : AutocompleteContact[] , state : any ) => {
        return filterAndSortContacts( options , state , ticket?.companyID ).splice(0,25);
    
    }}
    renderInput={({ InputProps , ...params  }) => <TextField 
          
        InputProps={{...InputProps , startAdornment: <InputAdornment position="start">{ selectedContact && <AnyBadge name={selectedContact.label} colorkey={selectedContact.emailAddress||undefined}  />}</InputAdornment>}}  
        {...params} 
        label="Contact" 
    />}
  />
}