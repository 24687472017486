import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import type {} from '@redux-devtools/extension' // required for devtools typing

interface HeaderState {
  title: string
  setTitle: (title: string) => void
}

export const useHeaderStore : any = create<HeaderState>()(
  devtools(
    persist(
      (set : any) => ({
        title: '',
        setTitle: (title) => set({ title }),
      }),
      {
        name: 'header-storage',
      },
    ),
  ),
)