import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import  { FC, useEffect, useMemo, useState } from 'react';

import { StyledTable } from '../Table';
import { useApi } from '../../providers/Api/Api';
import { useTicket } from '../../providers/TicketProvider';
import { useTicketApi } from '../../providers/TicketApiProvider';
import { useRecipeApi } from '../../providers/Recipe';

interface StartRecipeDialogProps {
    open: boolean;
    onClose: () => void;
    contextType: 'ticket';
}

export const StartRecipeDialog: FC<StartRecipeDialogProps> = ({ open, onClose , contextType }) => {
    const { ticket } = useTicket();
    const [ processing, setProcessing ] = useState(false);
    const [ search , setSearch ] = useState('');
    const { recipeStarts } = useTicketApi();
    const { startRecipe : startRecipeCore } = useRecipeApi();

    const context = useMemo(() => {
        return {
            context: contextType,
            ticketId: ticket?.id
        }
    }, [ contextType , ticket?.id]);

    useEffect(() => {
        if( !open ){
            setSearch('');
        }
    }, [open]);
    const startRecipe = (recipeId : string ) => {    
        setProcessing(true);    
        startRecipeCore( recipeId , context ).then( () => { 
            setProcessing(false);
            onClose();
        });
    }
    return (
        <Dialog  open={open} onClose={onClose}>
            <DialogTitle>Start Recipe</DialogTitle>
            <DialogContent sx={{width: '600px'}}>
                <TextField size="small" sx={{marginBottom: 2}} fullWidth value={search} onChange={(c)=>{
                    setSearch(c.target.value);
                }}/>
                <StyledTable
                    hideHeader
                    onClick={(row)=>{
                        if( processing ) return;
                        startRecipe( row.id );
                    }}
                    columns={[
                        { headerName: 'Title', field: 'title' }
                    
                    ]}
                    rows={recipeStarts.filter( rs =>  rs.title?.toLowerCase().includes(search.toLowerCase()))}
                ></StyledTable>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};
