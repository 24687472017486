
import { Autocomplete, Button, TextField } from "@mui/material";
import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { GraphqlGenericColumn } from "../../../types/graphql.generics"
import { AnyBadge } from "../../AnyBadge";
import { useSearchCoreUsersQuery } from "../../GraphqlEditItemDialog/CoreUserSearch.generated";
import { BadgeAndName } from "../../TicketPartials/BadgeAndName";
interface CoreUserTemplateEditProps {
    value: string | null;
    onSave: (newValue: string | null) => void;
    label?: string;
    disabled?: boolean;
  }
  
  interface UserOption {
    id: string;
    label: string;
  }

export const CoreUserTemplateView = ( 
    { cell , row , refresh , column , gqlTableColumns } : { 
        cell : any , row : any , refresh?: () => any , column : { field: string } , gqlTableColumns : GraphqlGenericColumn[] } ) => {
            const gqlColumn = useMemo(()=>gqlTableColumns.find(c=>c.field === column.field), [gqlTableColumns, column.field]);
    return <BadgeAndName  displayName={`${row?.CoreUser?.firstName || ''} ${row?.CoreUser?.lastName || ''}` || ''} colorkey={`${row?.CoreUser?.email}` || undefined} />
}


export function CoreUserTemplateEdit({ value, onSave, label = 'User', disabled = false }: CoreUserTemplateEditProps) {
    const [searchTerm, setSearchTerm] = useState('');
    const { data, loading } = useSearchCoreUsersQuery({
      variables: { search: searchTerm },
    });
  
    // Convert users data to options format
    const options: UserOption[] = data?.findCoreUsersPaginated.data?.map(user => ({
      id: user.id || '',
      label: `${user.firstName || ''} ${user.lastName || ''} (${user.email || ''})`,
    })) || [];
  
    // Find the selected option based on value
    const selectedOption = options.find(option => option.id === value) || null;
  
    // Debounced search handler
    const debouncedSearch = useCallback(
      debounce((term: string) => {
        setSearchTerm(term);
      }, 300),
      []
    );
    if( !data ) return <></>;
  
    return (
      <Autocomplete
      fullWidth
        value={selectedOption}
        onChange={(_, newValue) => {
            onSave(newValue?.id || null);
        }}
        options={options}
        loading={loading}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            onChange={(e) => debouncedSearch(e.target.value)}
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.label}
        filterOptions={(x) => x} // Disable client-side filtering
        noOptionsText={searchTerm ? 'No users found' : 'Start typing to search'}
      />
    );
  }