import { Dialog, DialogTitle, TextField, Button, DialogContent, DialogActions, Grid } from "@mui/material"
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useState } from "react";

const CREATE_COMPANY = gql`
    mutation createCoreCompanies($name: String!) {
        createCoreCompanies(name: $name) {
            id
            name
        }
    }
`;

export const AddCompanyDialog = ({ open, onClose }: { open: boolean; onClose: ( id? : number ) => void; }) => {
    const [name, setName] = useState('');
    const [createCompany] = useMutation(CREATE_COMPANY);

    const handleSubmit = async () => {
        const result = await createCompany({ variables: { name } });
        onClose(result?.data?.createCoreCompanies?.id);
    };

    return (
        <Dialog maxWidth="lg" open={open} onClose={() => {
            setName('');
            onClose();
        }}>
            <DialogTitle>Add Company</DialogTitle>
            <DialogContent sx={{width: '500px' , padding: 2}}>
            <Grid container spacing={2} sx={{padding: 2}} >
                <TextField
                    sx={{marginTop: 1}}
                    label="Company Name" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)} 
                    required 
                    fullWidth
                />
            </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setName('');
                    onClose();
                }}>Cancel</Button>
                <Button color="success" variant="contained" onClick={handleSubmit}>Submit</Button>
            </DialogActions>
        </Dialog>
    );
}