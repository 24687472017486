import { Badge, IconButton, ListItemButton, ListItemText, Menu, MenuItem, Switch, useTheme } from "@mui/material"
import { TUserRoles, UserRolesList, useUserProfile } from "../../react-submodules/providers/UserProfileProvider";

import { Icon } from "@iconify/react"
import { useState } from "react";

const UserRoleItem = ( { role , label , minTierLevel} : { role: TUserRoles , label : string ,  minTierLevel: number  }) => {
    const {userProfile , updateUserProfile } = useUserProfile();
    const enabled = userProfile?.[role] || false;
    return <MenuItem
    disabled={minTierLevel !== 0 && ( (userProfile?.techTier || 10 ) < minTierLevel)}
    onClick={()=>{
        updateUserProfile({ [role]: !enabled });
    }}><Switch checked={enabled} color="success" /><ListItemText primary={label}></ListItemText></MenuItem>
}


export const UserRoles = () => {
    const {userProfile } = useUserProfile();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    
    const open = Boolean(anchorEl);
    const theme = useTheme();

    const count = UserRolesList.map( role => (userProfile?.[role] ? 1 : 0) as number ).reduce( (pv,cv) => pv + cv , 0 );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
    return <><IconButton onClick={handleClick}><Badge badgeContent={count} color="primary" ><Icon icon="oui:app-users-roles" color={theme.palette.primary.contrastText} /></Badge></IconButton>
    <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem><ListItemText primary="Active Roles" ></ListItemText></MenuItem>
        <UserRoleItem role="isRoleTriage" label="Triage" minTierLevel={0} />
        <UserRoleItem role="isRoleTier1" label="Tier 1" minTierLevel={1} />
        <UserRoleItem role="isRoleTier2FirstUp" label="Tier 2 First Up" minTierLevel={2} />
        <UserRoleItem role="isRoleTier2" label="Tier 2" minTierLevel={2} />
        <UserRoleItem role="isScheduler" label="Scheduler" minTierLevel={0} />
        <UserRoleItem role="isSherpaChampion" label="Sherpa Champion" minTierLevel={0} />
      </Menu>
    </>
}