import { Button, Grid } from "@mui/material"
import { FC, useEffect, useState } from "react"

import { CoreFormField } from "./fields/CoreFormField"
import { FormField } from "../../../coretypes/formfields"
import { FullForm } from "../../../coretypes/form"

export type CoreFormProps = {
    form : Pick<FullForm,"formFields"> & Partial<FullForm>
}

export const CoreForm : FC<{ 
    form : Pick<FullForm,"formFields"> & Partial<FullForm>, 
    values?: Record<string,any> , 
    onChange?: ( values : Record<string,any> ) => void 
    onSave?: ( values : Record<string,any> ) => void 
    onSubmit?: ( values : Record<string,any> ) => void 
    controlled? : boolean
    readonly?: boolean
    saving? : boolean       
}> = ({ readonly, form , values : initalValues , onChange : onChangeParent , onSave : onSaveParent , onSubmit : onSubmitParent , controlled = false , saving = false }) => {

    const [ values , setValues ] = useState<Record<string,any>>( initalValues || {});
    // If it is controlled use the main value's, otherwise use the casched values
    const valuesToUse : Record<string,any> =  controlled ? (initalValues || {} ) : values  ;

    const onChange = ( field : FormField , value : any ) => {
        if( readonly ) return;
        setValues( { ...valuesToUse , [ field.name ] : value } );
        onChangeParent && onChangeParent( { ...valuesToUse , [ field.name ] : value } );
    }
    const onSave = () => {
        if( readonly ) return;
        onSaveParent && onSaveParent( valuesToUse );
    }
    const onSubmit = () => {
        if( readonly ) return;
        onSubmitParent && onSubmitParent( valuesToUse );
    }
    
    

    return <Grid sx={{paddingTop: 1}} container spacing={2}>
        { form?.formFields?.map( field => <Grid  key={field.name} item xs={12}><CoreFormField readonly={readonly} field={field} value={valuesToUse[field.name]} onChange={( value ) => onChange( field , value )} /></Grid> )}    
        { !readonly && <Grid item xs={12} sx={{ display : "flex" , justifyContent : "end" }}>
            { onSaveParent && <Button disabled={saving} sx={{marginRight: onSubmitParent? 1 : 0}} color="secondary" variant="contained" onClick={onSave}>Save</Button>}
            { onSubmitParent && <Button disabled={saving} color="primary" variant="contained" onClick={onSubmit}>Submit</Button>}
            </Grid> }
        </Grid> 
}

