import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, styled } from '@mui/material';
import { useState , FC } from 'react';
import { useApi } from '../../react-submodules/providers/Api/Api';
const GridItem = styled(Grid)( {
    paddingBottom: 16
})
export const NewInventoryWizard : FC<{
    refresh?: () => any
}> = ( { refresh}) => {
    const [open , setOpen ] = useState( false );
    const [ name , setName] = useState( "");
    const [ description , setDescription] = useState("" );
    const [ upcharge , setUpcharge] = useState<number>();
    const [ internalCost , setInternalCost] = useState<number>();
    const api = useApi();
    
    const save = () => {
        api.post( `/inventorymanagement/inventory`, 
        { name , upcharge , internalCost , description}
        ).then( () => {
            refresh && refresh();
            setOpen( false );
        })
    };

    return <>
    <Button onClick={()=>{ setOpen( true )}} variant="contained" color="secondary" >Add</Button>
    <Dialog open={open}>
        <DialogTitle>Create Inventory</DialogTitle>
        <DialogContent sx={{minWidth: '500px'}}>
        <GridItem item sm={12}>
            <TextField  sx={{marginTop: 2}} fullWidth label="Name" value={name}  onChange={( v) => { setName( v.target.value)}} />
        </GridItem>
        <GridItem item sm={12}>
            <TextField multiline rows={5} sx={{marginTop: 2}} fullWidth label="Description" value={description}  onChange={( v) => { setDescription( v.target.value)}} />
        </GridItem>
        <GridItem item sm={12}>
            <TextField  sx={{marginTop: 2}} fullWidth label="Acquisition Cost" value={upcharge} type="number" onChange={( v) => { setUpcharge( parseFloat(v.target.value))}} />
        </GridItem>
        <GridItem item sm={12}>
            <TextField  sx={{marginTop: 2}} fullWidth label="Internal Cost" value={internalCost} type="number" onChange={( v) => { setInternalCost( parseFloat(v.target.value))}} />
        </GridItem>
        </DialogContent>
        <DialogActions>
            <Button color="secondary" variant='contained' onClick={()=>{
                save();
            }}>Save</Button>
        </DialogActions>

    </Dialog></>

}