import { gql } from "@apollo/client";
import { Container } from "@mui/material";
import { PaginatedGraphqlQueryTable } from "../../../react-submodules/components/PaginatedGraphqlQueryTable/PaginatedGraphqlQueryTable";



export const CoreGroupsConfig = () => {
    return  <Container>
        <PaginatedGraphqlQueryTable baseName="CoreGroup"  columns={[
            { field: 'id', type: 'Float', createRequired: false , primaryKey: true , autoIncrement : true}, 
            { field: 'name', type: 'String', createRequired: true }, 
            { field: 'description', type: 'String', createRequired: true}
            ]} />
    </Container>
}