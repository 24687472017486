import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCompanyCoreContactsQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
  coreCompanyId?: Types.InputMaybe<Types.Scalars['Float']['input']>;
}>;


export type GetCompanyCoreContactsQuery = { __typename?: 'Query', findCoreContactPaginated: { __typename?: 'PaginatedCoreContact', totalCount: number, page: number, limit: number, hash?: string | null, data?: Array<{ __typename?: 'CoreContact', id?: number | null, firstName?: string | null, lastName?: string | null, email?: string | null, phone?: string | null, alternatePhone?: string | null, mobilePhone?: string | null, coreCompanyId: number, active?: boolean | null }> | null } };


export const GetCompanyCoreContactsDocument = gql`
    query GetCompanyCoreContacts($search: String, $page: Int!, $limit: Int!, $coreCompanyId: Float) {
  findCoreContactPaginated(
    search: $search
    page: $page
    limit: $limit
    active: {eq: true}
    coreCompanyId: {eq: $coreCompanyId}
  ) {
    data {
      id
      firstName
      lastName
      email
      phone
      alternatePhone
      mobilePhone
      coreCompanyId
      active
    }
    totalCount
    page
    limit
    hash
  }
}
    `;

/**
 * __useGetCompanyCoreContactsQuery__
 *
 * To run a query within a React component, call `useGetCompanyCoreContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyCoreContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyCoreContactsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      coreCompanyId: // value for 'coreCompanyId'
 *   },
 * });
 */
export function useGetCompanyCoreContactsQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyCoreContactsQuery, GetCompanyCoreContactsQueryVariables> & ({ variables: GetCompanyCoreContactsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyCoreContactsQuery, GetCompanyCoreContactsQueryVariables>(GetCompanyCoreContactsDocument, options);
      }
export function useGetCompanyCoreContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyCoreContactsQuery, GetCompanyCoreContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyCoreContactsQuery, GetCompanyCoreContactsQueryVariables>(GetCompanyCoreContactsDocument, options);
        }
export function useGetCompanyCoreContactsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCompanyCoreContactsQuery, GetCompanyCoreContactsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanyCoreContactsQuery, GetCompanyCoreContactsQueryVariables>(GetCompanyCoreContactsDocument, options);
        }
export type GetCompanyCoreContactsQueryHookResult = ReturnType<typeof useGetCompanyCoreContactsQuery>;
export type GetCompanyCoreContactsLazyQueryHookResult = ReturnType<typeof useGetCompanyCoreContactsLazyQuery>;
export type GetCompanyCoreContactsSuspenseQueryHookResult = ReturnType<typeof useGetCompanyCoreContactsSuspenseQuery>;
export type GetCompanyCoreContactsQueryResult = Apollo.QueryResult<GetCompanyCoreContactsQuery, GetCompanyCoreContactsQueryVariables>;