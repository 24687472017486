import { Box, IconButton, Typography, useTheme } from "@mui/material"
import { FC, useState } from "react"

import { FormInstance } from "../../../coretypes/form.dto"
import { FormItem } from "./FormItem"
import { Icon } from "@iconify/react"
import { RecipeEpoc } from "./types"
import { TTodosTodo } from "../../types/todos.dto"
import { TodoItem } from "./TodoItem"

const isTodo = (item : TTodosTodo | FormInstance) : item is TTodosTodo => {
    // @ts-ignore
    return (item as TTodosTodo).formId === undefined;
}
const isForm = (item : TTodosTodo | FormInstance) : item is FormInstance => {
    // @ts-ignore
    return (item as FormInstance).formId !== undefined;
}


export const TodoEpocSection : FC<{
    epoc : RecipeEpoc
    todos : TTodosTodo[]
    forms : FormInstance[]
    refresh : () => void    
}> = ({epoc , todos , refresh , forms }) => {
    const theme = useTheme();
    
    const filteredTodos = todos.filter( t => t.recipe_epicId === epoc.id && t.noteType !== 'question' );
    const filteredForms = forms.filter( f => f.recipe_epicId === epoc.id );
    
    const [ expanded , setExpanded ] = useState([...filteredTodos , ...filteredForms].filter( t => !t.complete ).length > 0)
    const formsAndTodos = [ ...filteredTodos , ...filteredForms ].sort( (a , b) => {
        const { createdAt : aCreatedAt } = a as TTodosTodo | FormInstance;
        const { createdAt : bCreatedAt } = b as TTodosTodo | FormInstance;
        if( aCreatedAt === bCreatedAt ) return 0;
        if( aCreatedAt === null ) return 1;
        if( bCreatedAt === null ) return -1;
        return new Date(aCreatedAt || '').getTime() - new Date(bCreatedAt || '').getTime();
    } );
    return <><Box sx={
        {
            display: 'flex' , 
            background: theme.palette.common.black , 
            color: theme.palette.common.white ,
            margin: 0.5,
            
            borderRadius: 2
        }}>
            
            <IconButton size="small" sx={{
                color: theme.palette.common.white ,
                background:  theme.palette.common.black ,

            }} onClick={()=>{setExpanded(!expanded)}}><Icon color="white"  icon={expanded ? 'icon-park-outline:down-c' : 'icon-park-outline:right-c'} /></IconButton>
            <Typography onClick={()=>{
            }} sx={{ cursor: 'pointer', flexGrow: 1 ,  marginLeft: 1 , fontWeight:'bold'}} variant="body1" >{epoc.title}</Typography>&nbsp;&nbsp;

            
        

        
    </Box>
    { expanded && (<Box sx={{marginLeft: 2}}>
    {formsAndTodos.reverse().map( todo => {
        if( isTodo(todo) ){
            return <TodoItem todo={todo} key={todo.id} onChange={()=>{refresh()}} />
        }
        if( isForm(todo) ){
            return <FormItem formInstance={todo} key={todo.id} />
        }
    })}
    </Box> ) }
    </>
}

