import { Box, Button, IconButton, Tooltip, Typography, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { StatusToColor, StatusToIcon } from "./status.util";

import { Icon, InlineIcon } from "@iconify/react";
import { useMemo, useState } from "react";
import { useTicket } from "../../providers/TicketProvider";
import { useTicketApi } from "../../providers/TicketApiProvider";
import { MENU_ICON, MERGE_TICKETS_ICON } from "../../constances/icons";
import { MergeTicketWizard } from "../MergeTicketWizard/MergeTicketWizard";

export const TicketHeader = ({ title , status , onClick } : { title: string , status: string , onClick?: () => any }) => {
        const { nextTicket  } = useTicketApi();
        const { ticket } = useTicket();
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const [mergeTicketWizardOpen, setMergeTicketWizardOpen] = useState(false);

        const role = useMemo( () => {
                if( ticket?.fromRole ){
                    return ticket?.fromRole;
                }
                if( ticket?.id === nextTicket?.id && nextTicket?.fromRole ){
                    return nextTicket?.fromRole;
                }
                return null;
            } , [nextTicket?.id , ticket?.id , ticket?.fromRole , nextTicket?.fromRole]);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMergeTickets = () => {
        // Implement merge tickets logic here
        console.log("Merge Tickets clicked");
        handleMenuClose();
        setMergeTicketWizardOpen(true);
    };
    const onMerge = (toTicketId: number) => {
        
        // setMergeTicketWizardOpen(false);
        window.open( `/ticketqueue/all/${toTicketId}` );
    };

    return <Box onClick={() => { onClick && onClick() }} sx={{ background: StatusToColor( status) , padding: 1 , display: 'flex'}}>
            <Box sx={{marginRight: 1, marginLeft: 1}}><InlineIcon icon={StatusToIcon( status )} /></Box>
            {role && <Box sx={{marginRight: 1, marginLeft: 1}}><Typography variant="h6" sx={{ fontSize: '14px'}}>({role})</Typography></Box>}
            <Box flexGrow={1} sx={{
                fontSize: '16px' , 
                fontWeight: 'bold',
                display: 'block',
                overflow: 'hidden',
                }}><Tooltip title={title}><Typography noWrap variant="body1" textOverflow="ellipsis">{title}</Typography></Tooltip></Box>
            <Box>
                <IconButton
                    size="small"
                    sx={{ marginRight: 1 , color: 'black' }}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleMenuClick(e);
                    }}
                >
                    <Icon icon={MENU_ICON} />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleMergeTickets}>
                    <ListItemIcon><Icon icon={MERGE_TICKETS_ICON} /></ListItemIcon>
                    <ListItemText>Merge Ticket</ListItemText>
                    </MenuItem>
                    {/* Add more menu items here if needed */}
                </Menu>
            </Box>
            {ticket?.id && <MergeTicketWizard open={mergeTicketWizardOpen} onClose={() => setMergeTicketWizardOpen(false)} fromTicketId={ticket?.id} onMerge={onMerge} />}
        </Box>
}