import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { FC, useMemo, useState } from "react"
import { CoreNotificationDto } from "../../../coretypes/core.dto";
import { DailyKpi } from "./Notifications/DailyKpi";
import { useCoreNotifications } from "../../providers/CoreNotificationProvider";
import { useApi } from "../../providers/Api/Api";
import { MarkdownEditor } from "../MarkdownEditor/MarkdownEditor";



const NotificationComponent : FC<{
    notification: CoreNotificationDto
    acknowlegementRequired: boolean,
    setAcknowledgementRequired: (value: boolean) => void
}>= ({notification , acknowlegementRequired , setAcknowledgementRequired}) => {
    switch( notification?.component) {
        case "daily_kpi":
            return <DailyKpi {...(notification.componentParams || {}  ) as any} acknowlegementRequired={acknowlegementRequired} setAcknowledgementRequired={setAcknowledgementRequired} />
        default:
            return <></>
    }
    return <></>
}


export const CoreNotificationWatcher = () => {
    const api = useApi();
    const { notifications , refresh } = useCoreNotifications();
    const [ acknowledgementMessage , setAcknowledgementMessage ] = useState<string>("");
    const [ acknowlegementRequired , setAcknowledgementRequired ] = useState<boolean>(false);
    const [ showAcknowledgementPage , setShowAcknowledgementPage ] = useState<boolean>(false);
    
    const currentNotification = useMemo(() => {
        return notifications[0] || null
    }, [notifications]);
    const postAcknowledgement = async () => {
        await api.post(`/core/notifications/acknowledge/${currentNotification?.id}` , {
            acknowledgementMessage
        }).then( () => {
            refresh();
        })
    }
    if( !currentNotification) return <></>

    return <Dialog open={true}>
        <DialogContent>
            {showAcknowledgementPage ? <>
                <Typography variant="h6">Please write on what you are planning to do different to be able to hit your goal, or if you think the goal's are unrealistic please let us know why</Typography>
                <MarkdownEditor markdown={acknowledgementMessage} onChange={setAcknowledgementMessage} />
            </> : <>
                <NotificationComponent acknowlegementRequired={acknowlegementRequired} setAcknowledgementRequired={(b) => setAcknowledgementRequired(b)} notification={currentNotification} />
            </>}
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                if( acknowlegementRequired && !acknowledgementMessage ){
                    setShowAcknowledgementPage(true);
                }else{
                    postAcknowledgement();
                }
            }}>Acknowledge</Button>
        </DialogActions>
    </Dialog>
}

