import { FC, useEffect, useState } from "react";
import {  CInventoryItemLocation, InventoryItem } from "./types";
import { Button, Dialog, DialogActions, DialogTitle, Grid, IconButton, TextField, Typography, styled } from "@mui/material";
import { Icon } from "@iconify/react";
import { useApi } from "../../react-submodules/providers/Api/Api";

const GridItem = styled(Grid)( {
    paddingBottom: 16
})
export const InfoDialog : FC<{
    refresh: () => any;
    inventory: InventoryItem

}> = ({ refresh , inventory }) => {
    const [ open , setOpen ] = useState( false);
    const [ name , setName] = useState( inventory?.name );
    const [ description , setDescription] = useState( inventory?.description );
    const [ upcharge , setUpcharge] = useState( inventory?.upcharge );
    const [ internalCost , setInternalCost] = useState( inventory?.internalCost);
    const api = useApi();
    useEffect( () => { 
        setUpcharge( inventory?.upcharge);
        setInternalCost( inventory?.internalCost);
    } , [ inventory?.internalCost , inventory?.upcharge]);
    
    const save = () => {
        api.patch( `/inventorymanagement/inventory/${inventory.id}`, 
        { name , upcharge , internalCost , description}
        ).then( () => {
            refresh();
            setOpen( false );
        })
    };

    return <>
        <IconButton onClick={(()=>{ setOpen( true );})}><Icon color="white" icon="bxs:edit"  /></IconButton>
        <Dialog maxWidth="md" open={open} onClose={()=>{
            setOpen( false );
        }}>
            <DialogTitle>Edit {inventory.name}</DialogTitle>
        <Grid sx={{
            margin: 2,
            width: '500px'
        }}>
        <GridItem item sm={12}>
            <TextField  sx={{marginTop: 2}} fullWidth label="Name" value={name}  onChange={( v) => { setName( v.target.value)}} />
        </GridItem>
        <GridItem item sm={12}>
            <TextField multiline rows={5} sx={{marginTop: 2}} fullWidth label="Description" value={description}  onChange={( v) => { setDescription( v.target.value)}} />
        </GridItem>
        <GridItem item sm={12}>
            <TextField  sx={{marginTop: 2}} fullWidth label="Acquisition Cost" value={upcharge} type="number" onChange={( v) => { setUpcharge( parseFloat(v.target.value))}} />
        </GridItem>
        <GridItem item sm={12}>
            <TextField  sx={{marginTop: 2}} fullWidth label="Internal Cost" value={internalCost} type="number" onChange={( v) => { setInternalCost( parseFloat(v.target.value))}} />
        </GridItem>
        <GridItem item sm={12}>
            Total Price: <Typography variant="h6">${( internalCost || 0 ) + (upcharge || 0 )}</Typography>
        </GridItem>
        
        </Grid>
        <DialogActions>
            <Button onClick={() => {
                save();
            }} variant="contained" color="secondary">Save</Button>
        </DialogActions>
        </Dialog>
    </>
}