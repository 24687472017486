import { Box, Button, ButtonGroup, Container, Grid, MenuItem, Paper, Switch, TextField, Tooltip, Typography } from "@mui/material"
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { SortColumn, StyledTable, TableColumnDef } from "../../react-submodules/components/Table";
import { useApi } from "../../react-submodules/providers/Api";
import { useSearchParam } from "../../react-submodules/utils/reactrouter";
import { DateTime } from "luxon";
import { LabeledBox } from "../../react-submodules/components/LabeldBox";
import { useMounted } from "../../react-submodules/hooks/useMounted";
import { CoreCompaniesSnapshot } from "../../coretypes/reports/billing";
import { DurationView } from "../../react-submodules/components/DurationView";
import { HOURLY_BILLING_ICON, MANAGED_BILLING_ICON } from "../../react-submodules/constances/icons";
const columns : TableColumnDef[] = [
    {
        field: 'ticketNumber',
        flex: 1,
        sortable: true,
        headerName: 'Ticket Number',
        Element: ( { cell , row  } : any ) => {
            
            return <Button size="small" variant="contained" onClick={()=>{
                window.open( `/ticketqueue/all/${row.ticketId}` , `ticket-${row.id}` );
            }}>{row.ticketNumber}</Button>
        }
        
    },
    {
        field: 'companyName',
        flex: 1,
        sortable: true,
        headerName: 'Company'
    },
    {
        field: 'title',
        flex: 1,
        sortable: true,
        headerName: 'Title'
    },
    {
        field: 'minutesWorked',
        flex: 1,
        sortable: true,
        headerName: 'Time Worked',
        Element: ( { cell , row  } : any ) => {
            return <DurationView duration={cell} durationUnit="m" />
        }
    },
    {
        field: 'billable',
        flex: 1,
        sortable: true,
        headerName: 'Billable',
        sortRemap(cell, row ) {
            const isBillable = cell?.toLowerCase() === 'hourly';
            const isManaged = cell?.toLowerCase() === 'managed';
            const isUnknown = !isBillable && !isManaged;
            
            return isBillable ? 1 : isManaged ? 2 : isUnknown ? 3 : 3;
        },
        Element: ( { cell , row  , setTicketBillable } ) => {
            
            const isBillable = cell?.toLowerCase() === 'hourly';
            const isManaged = cell?.toLowerCase() === 'managed';
            const isUnknown = !isBillable && !isManaged;
            return <><ButtonGroup size="small" variant="contained" >
                <Button color={isBillable ? 'success' : 'inherit'} onClick={()=>{ setTicketBillable( row.ticketId , true ) }}><Icon icon={HOURLY_BILLING_ICON} /></Button>                        
                <Button color={isUnknown ?  'error' : 'inherit'} onClick={()=>{ setTicketBillable( row.ticketId , null ) }} ><Icon icon="el:question-sign"></Icon></Button>
                <Button color={isManaged ? 'info' : 'inherit'} onClick={()=>{setTicketBillable( row.ticketId , false ) }}><Icon icon={MANAGED_BILLING_ICON} /></Button>
            </ButtonGroup></>
        }
    }
]


const prependZero = ( value: number ) => {
    return value < 10 ? `0${value}` : `${value}`;
}

const getBillableValue = ( value: boolean | null ) => {
    if( value === true ) return "Hourly";
    if( value === false ) return "Managed";
    return "TBD";
}

export const BillingTicketReport = () => {
    const nav = useNavigate();
    const mounted = useMounted()
    const [ search, setSearch ] = useSearchParam( 'search' );
    const [ companies  , setCompanies ] = useState<CoreCompaniesSnapshot[]>( [] );
    const [ tickets , setTickets ] = useState<any[]>( [] );
    const [ sort , setSort ] = useState<SortColumn[]>( [] );
    const [ companyId , setCompanyId ] = useSearchParam( 'companyId' );
    
    // const [ date , setDate ] = useSearchParam<string>( 'date' , { typeProp: 'string' , defaultValue: DateTime.now().toISODate() } );
    const [ month , setMonth ] = useSearchParam<number>( 'month' , { typeProp: 'number' , defaultValue: Number( DateTime.now().toFormat('MM') ) } );
    const [ year , setYear ] = useSearchParam<number>( 'year' , { typeProp: 'number' , defaultValue: Number( DateTime.now().toFormat('yyyy') ) } );
    const [ billable , setBillable ] = useSearchParam<string>( 'billable' , { typeProp: 'string' } );
    const [ refresh , setRefresh ] = useState( false );
    const api = useApi();

    const getTickets = useCallback( () => {
        let query : Record<string, any> = {  month , year , companyId , search    };
            if( search ){
                query['name:like'] = search;
            }
            if( billable ){
                query['billable'] = billable;
            }
            api.get( '/reports/billing/tickets' , query ).then( ( results ) => {
                if( mounted() ){
                    setTickets( results.data );
                }
              });
              
    } , [ month , year , companyId , search ,  billable  ]);

    useEffect(() => {
        if( !month || !year ){
            setCompanies( [] );
            return;
        }
        const getData = setTimeout(() => {
            let query : Record<string, any> = {  month , year , companyId , search    };
            if( search ){
                query['name:like'] = search;
            }
          api.get( '/reports/billing/companies/list' , query ).then( ( results ) => {
            if( mounted() ){
                setCompanies( results.data );
            }
          });
          getTickets();
        }, refresh ? 0 : 500)
        
        return () => clearTimeout(getData)
    }, [search , month , year ,     search , companyId , refresh , billable ])

    
    const date = useMemo( () => {
        return `${year}-${prependZero( Number( month ) )}-01`
    } , [ year , month ] );
    const setDate = ( date: string ) => {
        const [ year , month ] = date.split( '-' );
        setYear( parseInt( year ) );
        setMonth( parseInt( month ) );
    }
    const setTicketBillable = ( ticketId: number , billable: boolean | null ) => {
        api.patch( `/autotask/updateTicket/${ticketId}` , { billable : getBillableValue( billable)} ).then( ( results ) => {
            getTickets();
        });
    }
    

    return <><Box sx={{marginTop: 2 }}>
        <Paper elevation={2} sx={{padding: 1}}>
            <Grid container spacing={2}>
                <Grid item sm={12} md={6} lg={4}>
                    <TextField fullWidth label="Search" value={search} onChange={( e)=>{ setSearch( e.target.value)}} />
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                    <TextField fullWidth label="Month" type="date" value={date} onChange={( e)=>{ setDate( e.target.value)}} />     
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                    <TextField select fullWidth label="Company" value={companyId} onChange={( e)=>{ setCompanyId( e.target.value)}} >
                        <MenuItem value={""}>All Companies</MenuItem>
                        { companies.sort( ( a , b ) => a.name.localeCompare( b.name ) ).map( ( c ) => (
                            <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                        ))}
                    </TextField>     
                </Grid>
                <Grid item sm={12} md={3} lg={2}>
                    <LabeledBox label="Billable" >
                        <ButtonGroup size="small" variant="contained" >
                            <Button color={billable ===  "Hourly" ? 'success' : 'inherit'} onClick={()=>{ setBillable( "Hourly" ) }}><Icon icon={HOURLY_BILLING_ICON} /></Button>                        
                            <Button color={ billable === "TBD" ?  'error' : 'inherit'} onClick={()=>{ setBillable( "TBD" ) }} ><Icon icon="el:question-sign"></Icon></Button>
                            <Button color={billable === "Managed" ? 'info' : 'inherit'} onClick={()=>{setBillable( "Managed" ) }}><Icon icon={MANAGED_BILLING_ICON} /></Button>
                        </ButtonGroup>
                        <Button onClick={()=>{ setBillable( "" ) }}>Reset</Button>
                </LabeledBox>
                </Grid>
            </Grid>
        </Paper>
        </Box>
        { tickets.length > 0 && (
            <Box sx={{marginTop: 2 }}>
                    <Paper>
                        <StyledTable
                            enablePagination
                            elementProps={{ setTicketBillable }}
                            indexField="ticketId"
                            size="small"
                            rows={tickets}
                            columns={columns as any }
                        />
                    </Paper>
            </Box>
        )
        }
    </>
}