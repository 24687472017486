import { FC, useState } from "react";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { useApi } from "../../../react-submodules/providers/Api";
import { ContractDTO } from "../../../react-submodules/types/contracts.dto";
import { Contract } from "../../Contracts/types";

type CombinedContract = ContractDTO | {
    id: string;
    contract_id: number;
    contract_name: string;
    contract_unitsMeasured: string;
    unitPrice: number | string;
    additionalPrice: number | string;
    approvedUnits: number;
    usedUnits: number;
    autoAproveUnits: boolean;
    contract_unitPrice?: number;
};

export const ContractsDownload: FC = () => {
    const { id } = useParams();
    const api = useApi();
    const [isLoading, setIsLoading] = useState(false);

    const handleDownload = async () => {
        setIsLoading(true);
        try {
            // Fetch contracts attached to the company
            const attachedResponse = await api.get(`/contracts/instances/search`, {
                coreCompanyId: id
            });
            const attachedContracts: ContractDTO[] = attachedResponse.data;

            // Fetch all contracts
            const allContractsResponse = await api.get('/contracts/search');
            const allContracts: Contract[] = allContractsResponse.data;

            // Filter out contracts not attached to the company
            const unattachedContracts = allContracts.filter(contract => 
                !attachedContracts.some(attached => attached.contract_id === contract.id)
            );

            // Combine attached and unattached contracts
            const combinedContracts = [
                ...attachedContracts,
                ...unattachedContracts.map(contract => ({
                    id: '',
                    contract_id: contract.id,
                    contract_name: contract.name,
                    contract_unitsMeasured: contract.unitsMeasured,
                    unitPrice: '',
                    additionalPrice: '',
                    approvedUnits: 0,
                    usedUnits: 0,
                    autoAproveUnits: false,
                    contract_unitPrice: contract.unitPrice
                }))
            ];
            
            // Convert contracts to CSV
            const csvContent = convertToCSV(combinedContracts as CombinedContract[]);
            
            // Create and download the CSV file
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement("a");
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", "company_contracts.csv");
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            console.error("Error downloading contracts:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const convertToCSV = (contracts: CombinedContract[]) => {
        const header = ["ID", "Contract ID", "Contract Name", "Units Measured", "Base Unit Price", "Unit Price Overide", "Additional Price", "Approved Units", "Used Units", "Auto Approve Units", "DELETE"];
        const rows = contracts.map(contract => [
            contract.id,
            contract.contract_id,
            contract.contract_name,
            contract.contract_unitsMeasured,
            contract.contract_unitPrice || contract.unitPrice,
            contract.unitPrice,
            contract.additionalPrice,
            contract.approvedUnits,
            contract.usedUnits || 0,
            contract.autoAproveUnits ? "Yes" : "No",
            contract.id ? "No" : "Yes" // Add DELETE field: "No" for main contracts, "Yes" for additional contracts
        ]);
        return [header, ...rows].map(row => row.join(",")).join("\n");
    };

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={handleDownload}
            disabled={isLoading}
        >
            {isLoading ? "Downloading..." : "Download Contracts CSV"}
        </Button>
    );
};