import { FC, useEffect, useMemo, useState } from "react";
import { FieldPicklist, useTicketApi } from "../../react-submodules/providers/TicketApiProvider";
import { MenuItem, TextField, Typography } from "@mui/material";

import { InlineIcon } from "@iconify/react";
import { QueuesToColor, QueuesToIcon } from "../../react-submodules/components/TicketPartials/queues.utils";

export const QueuePicker : FC<{ iconOnly?: boolean , edit?: boolean; queue?: string , onChange?: ( field : FieldPicklist ) => any}> = ({ queue , edit , onChange , iconOnly }) => {
    const { queues : queuesRaw } = useTicketApi();
    const queues = useMemo(() => {
        return [ ...queuesRaw , { value: 'ALL_QUEUES', label: 'All Queues' } ] as FieldPicklist[];
    }, [queuesRaw.length])

    const [ selectedQueue , setSelectedQueue ] = useState< FieldPicklist | null >( queues.find( ( s ) => {
        return s.value === queue || s.label === queue;
    } ) || null );
    useEffect( () => {
        const newQueue = queues.find( ( s ) => {
            return s.value === queue || s.label === queue;
        });
        if( newQueue ){
            setSelectedQueue( newQueue );
        }
    } , [ queue , JSON.stringify( queues )]);


    useEffect( () => {
        if( queue === null || queue === undefined || queue === 'null' ){
            onChange && onChange( { value: 'ALL_QUEUES', label: 'All Queues' } as any );
        }
    } , [ queue ]);

    if( queues.length === 0 ) return <></>;

    if( !edit ){
        return <Typography><InlineIcon color={QueuesToColor(selectedQueue?.label || "")} icon={QueuesToIcon( selectedQueue?.label || "" )} />&nbsp;{!iconOnly && selectedQueue?.label}</Typography>
    }

    return <TextField
    size="small"
    fullWidth
    select
    label="Queue"
    value={selectedQueue?.value || 'ALL_QUEUES'}
    onChange={(val)=>{
      const newStatus = queues.find( ( s ) => {
        return s.value === val.target.value || s.label === val.target.value;
    } );
      onChange && onChange( newStatus || { value : 'ALL_QUEUES' } as any );
    }}
  >
    {queues.map((option) => (
      <MenuItem key={option.value} value={option.value} sx={{display: 'flex' , flexDirection: 'row'}}>
        <Typography><InlineIcon color={QueuesToColor(option.label)} icon={QueuesToIcon( option.label )} />&nbsp;{option.label}</Typography> 
      </MenuItem>
    ))}
  </TextField>
}