import { Alert, Box, Button } from "@mui/material";

import { useMemo } from "react";
import { useTicket } from "../../providers/TicketProvider";
import { useTicketApi } from "../../providers/TicketApiProvider";
import { useTimeclock } from "../../providers/Timeclock";

export const NewTicketAlert = () => {
    const {ticket} = useTicket();
    const { activePunches } = useTimeclock();
    const ticketIds = useMemo( () => activePunches.map( p => p.ticketId ) , [ JSON.stringify(activePunches) ]);
    const { nextTicket , getNextTicket } = useTicketApi();
    if( ticket && nextTicket && ticket.id !== nextTicket.id && !ticketIds.includes( nextTicket.id ) ) {;
        return <Alert severity="error"><Button size="small" variant="contained" color="error" onClick={()=>{
            getNextTicket( true );
        }}>Get Latest Ticket</Button> This is no longer the most important ticket! Please switch to <b>{nextTicket?.ticketNumber}  - {nextTicket?.title}</b> </Alert>
    }
    return <></>
}