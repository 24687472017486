import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  DateTimeIso: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type AiChat = {
  __typename?: 'AIChat';
  aiChatMessages?: Maybe<Array<AiChatMessages>>;
  coreUserId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ticket?: Maybe<AutotaskTickets>;
  ticketId?: Maybe<Scalars['Float']['output']>;
  user?: Maybe<CoreUsers>;
};

export type AiChatMessages = {
  __typename?: 'AIChatMessages';
  chat?: Maybe<AiChat>;
  chatId: Scalars['Float']['output'];
  coreUserId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  message: Scalars['String']['output'];
  rawMessage: Scalars['String']['output'];
  role: Scalars['String']['output'];
  runWithContexts?: Maybe<Scalars['String']['output']>;
  user?: Maybe<CoreUsers>;
};

export type AutotaskCompanies = {
  __typename?: 'AutotaskCompanies';
  additionalAddressInformation: Scalars['String']['output'];
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  alternatePhone1: Scalars['String']['output'];
  alternatePhone2: Scalars['String']['output'];
  apiVendorID?: Maybe<Scalars['Int']['output']>;
  assetValue?: Maybe<Scalars['Int']['output']>;
  billToAdditionalAddressInformation: Scalars['String']['output'];
  billToAddressToUse: Scalars['Int']['output'];
  billToAttention: Scalars['String']['output'];
  billToCity: Scalars['String']['output'];
  billToCompanyLocationID?: Maybe<Scalars['Int']['output']>;
  billToCountryID?: Maybe<Scalars['Int']['output']>;
  billToState: Scalars['String']['output'];
  billToZipCode: Scalars['String']['output'];
  billingAddress1: Scalars['String']['output'];
  billingAddress2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  classification?: Maybe<Scalars['Int']['output']>;
  companyCategoryID: Scalars['Int']['output'];
  companyName: Scalars['String']['output'];
  companyNumber: Scalars['String']['output'];
  companyType: Scalars['Int']['output'];
  competitorID?: Maybe<Scalars['Int']['output']>;
  coreCompanyId?: Maybe<Scalars['Int']['output']>;
  countryID?: Maybe<Scalars['Int']['output']>;
  createDate: Scalars['DateTime']['output'];
  createdByResourceID: Scalars['Int']['output'];
  currencyID: Scalars['Int']['output'];
  fax: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  impersonatorCreatorResourceID?: Maybe<Scalars['Int']['output']>;
  invoiceEmailMessageID: Scalars['Int']['output'];
  invoiceMethod?: Maybe<Scalars['Int']['output']>;
  invoiceNonContractItemsToParentCompany?: Maybe<Scalars['Boolean']['output']>;
  invoiceTemplateID: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  isClientPortalActive: Scalars['Boolean']['output'];
  isEnabledForComanaged: Scalars['Boolean']['output'];
  isTaskFireActive: Scalars['Boolean']['output'];
  isTaxExempt: Scalars['Boolean']['output'];
  lastActivityDate: Scalars['DateTime']['output'];
  lastTrackedModifiedDateTime: Scalars['DateTime']['output'];
  marketSegmentID?: Maybe<Scalars['Int']['output']>;
  ownerResourceID: Scalars['Int']['output'];
  parentCompanyID?: Maybe<Scalars['Int']['output']>;
  phone: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  purchaseOrderTemplateID?: Maybe<Scalars['Int']['output']>;
  quoteEmailMessageID: Scalars['Int']['output'];
  quoteTemplateID: Scalars['Int']['output'];
  sicCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  stockMarket: Scalars['String']['output'];
  stockSymbol: Scalars['String']['output'];
  surveyCompanyRating?: Maybe<Scalars['Int']['output']>;
  taxID: Scalars['String']['output'];
  taxRegionID?: Maybe<Scalars['Int']['output']>;
  territoryID?: Maybe<Scalars['Int']['output']>;
  tickets: Array<AutotaskTickets>;
  userDefinedFields: Scalars['JSON']['output'];
  webAddress?: Maybe<Scalars['String']['output']>;
};

export type AutotaskContacts = {
  __typename?: 'AutotaskContacts';
  additionalAddressInformation: Scalars['String']['output'];
  addressLine: Scalars['String']['output'];
  addressLine1: Scalars['String']['output'];
  alternatePhone?: Maybe<Scalars['String']['output']>;
  apiVendorID?: Maybe<Scalars['Int']['output']>;
  bulkEmailOptOutTime?: Maybe<Scalars['DateTime']['output']>;
  city: Scalars['String']['output'];
  companyID: Scalars['Int']['output'];
  companyLocationID?: Maybe<Scalars['Int']['output']>;
  coreCompanyId?: Maybe<Scalars['Int']['output']>;
  coreContactId?: Maybe<Scalars['Int']['output']>;
  countryID?: Maybe<Scalars['Int']['output']>;
  createDate: Scalars['DateTime']['output'];
  emailAddress: Scalars['String']['output'];
  emailAddress2?: Maybe<Scalars['String']['output']>;
  emailAddress3?: Maybe<Scalars['String']['output']>;
  extension: Scalars['String']['output'];
  externalID: Scalars['String']['output'];
  facebookUrl: Scalars['String']['output'];
  faxNumber: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  impersonatorCreatorResourceID?: Maybe<Scalars['Int']['output']>;
  isActive: Scalars['Int']['output'];
  isOptedOutFromBulkEmail: Scalars['Boolean']['output'];
  lastActivityDate: Scalars['DateTime']['output'];
  lastModifiedDate: Scalars['DateTime']['output'];
  lastName: Scalars['String']['output'];
  linkedInUrl: Scalars['String']['output'];
  middleInitial?: Maybe<Scalars['String']['output']>;
  mobilePhone?: Maybe<Scalars['String']['output']>;
  namePrefix?: Maybe<Scalars['Int']['output']>;
  nameSuffix?: Maybe<Scalars['Int']['output']>;
  note: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  primaryContact: Scalars['Boolean']['output'];
  receivesEmailNotifications: Scalars['Boolean']['output'];
  roomNumber: Scalars['String']['output'];
  solicitationOptOut: Scalars['Boolean']['output'];
  solicitationOptOutTime?: Maybe<Scalars['DateTime']['output']>;
  state: Scalars['String']['output'];
  surveyOptOut: Scalars['Boolean']['output'];
  tickets: Array<AutotaskTickets>;
  title: Scalars['String']['output'];
  twitterUrl: Scalars['String']['output'];
  userDefinedFields: Scalars['JSON']['output'];
  zipCode: Scalars['String']['output'];
};

export type AutotaskTicketQueues = {
  __typename?: 'AutotaskTicketQueues';
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  isSystem: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  parentValue: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
  tickets: Array<AutotaskTickets>;
};

export type AutotaskTicketStatuses = {
  __typename?: 'AutotaskTicketStatuses';
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  isSystem: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  parentValue: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
  tickets: Array<AutotaskTickets>;
};

export type AutotaskTickets = {
  __typename?: 'AutotaskTickets';
  PhoneCalls?: Maybe<Array<PhonesCalls>>;
  ScheduleInstances?: Maybe<Array<ScheduleInstance>>;
  SchedulePlans?: Maybe<Array<SchedulePlan>>;
  TicketTimelines?: Maybe<Array<TicketTimeline>>;
  aisummary?: Maybe<Scalars['String']['output']>;
  apiVendorID?: Maybe<Scalars['Float']['output']>;
  archived: Scalars['Boolean']['output'];
  assignedResourceID?: Maybe<Scalars['Float']['output']>;
  assignedResourceRoleID?: Maybe<Scalars['Float']['output']>;
  autotaskCompany?: Maybe<AutotaskCompanies>;
  autotaskTicketQueue?: Maybe<AutotaskTicketQueues>;
  autotaskTicketStatus?: Maybe<AutotaskTicketStatuses>;
  billingCodeID?: Maybe<Scalars['Float']['output']>;
  changeApprovalBoard?: Maybe<Scalars['Float']['output']>;
  changeApprovalStatus?: Maybe<Scalars['Float']['output']>;
  changeApprovalType?: Maybe<Scalars['Float']['output']>;
  changeInfoField1?: Maybe<Scalars['String']['output']>;
  changeInfoField2: Scalars['String']['output'];
  changeInfoField3: Scalars['String']['output'];
  changeInfoField4: Scalars['String']['output'];
  changeInfoField5: Scalars['String']['output'];
  chats: Array<AiChat>;
  companyID?: Maybe<Scalars['Float']['output']>;
  companyLocationID?: Maybe<Scalars['Float']['output']>;
  completedByResourceID?: Maybe<Scalars['Float']['output']>;
  completedDate?: Maybe<Scalars['DateTimeIso']['output']>;
  configurationItemID?: Maybe<Scalars['Float']['output']>;
  contact?: Maybe<AutotaskContacts>;
  contactID?: Maybe<Scalars['Float']['output']>;
  contactlessStart?: Maybe<Scalars['Boolean']['output']>;
  contractID?: Maybe<Scalars['Float']['output']>;
  contractServiceBundleID?: Maybe<Scalars['Float']['output']>;
  contractServiceID?: Maybe<Scalars['Float']['output']>;
  createDate: Scalars['DateTimeIso']['output'];
  createdByContactID?: Maybe<Scalars['Float']['output']>;
  createdByCoreUserId?: Maybe<Scalars['String']['output']>;
  createdDateTime: Scalars['DateTime']['output'];
  creatorResourceID: Scalars['Float']['output'];
  creatorType: Scalars['Float']['output'];
  currentServiceThermometerRating?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dueDateTime: Scalars['DateTimeIso']['output'];
  enlightendTicketClass?: Maybe<Scalars['String']['output']>;
  estimatedHours?: Maybe<Scalars['Float']['output']>;
  externalID?: Maybe<Scalars['String']['output']>;
  firstResponseAssignedResourceID?: Maybe<Scalars['Float']['output']>;
  firstResponseDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  firstResponseDueDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  firstResponseInitiatingResourceID?: Maybe<Scalars['Float']['output']>;
  fromAutotask: Scalars['Boolean']['output'];
  hoursToBeScheduled?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  impersonatorCreatorResourceID?: Maybe<Scalars['Float']['output']>;
  isAssignedToComanaged: Scalars['Boolean']['output'];
  isVisibleToComanaged: Scalars['Boolean']['output'];
  issueType?: Maybe<Scalars['Float']['output']>;
  lastActivityDate: Scalars['DateTimeIso']['output'];
  lastActivityPersonType: Scalars['Float']['output'];
  lastActivityResourceID: Scalars['Float']['output'];
  lastCustomerNotificationDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  lastCustomerVisibleActivityDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  lastImportanceSync?: Maybe<Scalars['DateTimeIso']['output']>;
  lastTrackedModificationDateTime: Scalars['DateTimeIso']['output'];
  maxImportanceRank?: Maybe<Scalars['Float']['output']>;
  mergeToTicketId?: Maybe<Scalars['Float']['output']>;
  monitorID?: Maybe<Scalars['Float']['output']>;
  monitorTypeID?: Maybe<Scalars['Float']['output']>;
  nextActionDate?: Maybe<Scalars['DateTimeIso']['output']>;
  nextActionNote?: Maybe<Scalars['String']['output']>;
  nextActionTime?: Maybe<Scalars['Float']['output']>;
  nextActionType?: Maybe<Scalars['String']['output']>;
  opportunityID?: Maybe<Scalars['Float']['output']>;
  organizationalLevelAssociationID?: Maybe<Scalars['Float']['output']>;
  previousServiceThermometerRating?: Maybe<Scalars['Float']['output']>;
  priority?: Maybe<Scalars['Float']['output']>;
  problemTicketId?: Maybe<Scalars['Float']['output']>;
  projectID?: Maybe<Scalars['Float']['output']>;
  purchaseOrderNumber?: Maybe<Scalars['String']['output']>;
  queueID?: Maybe<Scalars['Float']['output']>;
  recipeInstanceId?: Maybe<Scalars['Float']['output']>;
  resolution?: Maybe<Scalars['String']['output']>;
  resolutionPlanDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  resolutionPlanDueDateTime?: Maybe<Scalars['Float']['output']>;
  resolvedDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  resolvedDueDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  resyncImportanceRank: Scalars['Boolean']['output'];
  rmaStatus?: Maybe<Scalars['Float']['output']>;
  rmaType?: Maybe<Scalars['Float']['output']>;
  rmmAlertID?: Maybe<Scalars['String']['output']>;
  serviceLevelAgreementHasBeenMet?: Maybe<Scalars['Float']['output']>;
  serviceLevelAgreementID?: Maybe<Scalars['Float']['output']>;
  serviceLevelAgreementPausedNextEventHours?: Maybe<Scalars['Float']['output']>;
  serviceThermometerTemperature?: Maybe<Scalars['Float']['output']>;
  source?: Maybe<Scalars['Float']['output']>;
  status: Scalars['Float']['output'];
  subIssueType?: Maybe<Scalars['Float']['output']>;
  tech?: Maybe<CoreUsers>;
  ticketCategory: Scalars['Float']['output'];
  ticketNumber: Scalars['String']['output'];
  ticketType?: Maybe<Scalars['Float']['output']>;
  timeclocks?: Maybe<Array<CoreTimeclock>>;
  title: Scalars['String']['output'];
  updatedByCoreUserId?: Maybe<Scalars['String']['output']>;
  updatedDateTime: Scalars['DateTime']['output'];
  userDefinedFields?: Maybe<Scalars['JSON']['output']>;
};

export type AzureCalendar = {
  __typename?: 'AzureCalendar';
  AzureCalendarParticipants?: Maybe<Array<AzureCalendarParticipant>>;
  ScheduleInstances?: Maybe<Array<ScheduleInstance>>;
  bodyPreview: Scalars['String']['output'];
  createdDateTime: Scalars['DateTimeIso']['output'];
  end: Scalars['DateTimeIso']['output'];
  id?: Maybe<Scalars['String']['output']>;
  isAllDay: Scalars['Boolean']['output'];
  isCancelled: Scalars['Boolean']['output'];
  lastModifiedDateTime: Scalars['DateTimeIso']['output'];
  mailbox: Scalars['String']['output'];
  onlineMeetingUrl?: Maybe<Scalars['String']['output']>;
  start: Scalars['DateTimeIso']['output'];
  subject?: Maybe<Scalars['String']['output']>;
  syncId?: Maybe<Scalars['String']['output']>;
  webLink?: Maybe<Scalars['String']['output']>;
};

export type AzureCalendarParticipant = {
  __typename?: 'AzureCalendarParticipant';
  AzureCalendar?: Maybe<AzureCalendar>;
  calendarId?: Maybe<Scalars['String']['output']>;
};

export type AzureDirectoryRoleMembers = {
  __typename?: 'AzureDirectoryRoleMembers';
  AzureDirectoryRole?: Maybe<AzureDirectoryRoles>;
  AzureUser?: Maybe<AzureUsers>;
  azureDirectoryRoleId?: Maybe<Scalars['String']['output']>;
  azureUserId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

export type AzureDirectoryRoles = {
  __typename?: 'AzureDirectoryRoles';
  AzureDirectoryRoleMembers?: Maybe<Array<AzureDirectoryRoleMembers>>;
  AzureTenant?: Maybe<AzureTenants>;
  azureTenantId?: Maybe<Scalars['String']['output']>;
  createdByCoreUserId?: Maybe<Scalars['String']['output']>;
  createdDateTime: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastSync?: Maybe<Scalars['DateTimeIso']['output']>;
  roleTemplateId?: Maybe<Scalars['String']['output']>;
  updatedByCoreUserId?: Maybe<Scalars['String']['output']>;
  updatedDateTime: Scalars['DateTime']['output'];
};

export type AzureEmail = {
  __typename?: 'AzureEmail';
  AzureEmailAttachments?: Maybe<Array<AzureEmailAttachment>>;
  AzureEmailParticipants?: Maybe<Array<AzureEmailParticipatnt>>;
  TicketTimelines?: Maybe<Array<TicketTimeline>>;
  attachmentsSynced: Scalars['Boolean']['output'];
  autotaskTicketId?: Maybe<Scalars['Float']['output']>;
  bodyHtml?: Maybe<Scalars['String']['output']>;
  bodyMarkdown?: Maybe<Scalars['String']['output']>;
  bodyPreview?: Maybe<Scalars['String']['output']>;
  conversationId?: Maybe<Scalars['String']['output']>;
  conversationIndex?: Maybe<Scalars['String']['output']>;
  coreCompanyId?: Maybe<Scalars['Float']['output']>;
  createdDateTime: Scalars['DateTimeIso']['output'];
  id?: Maybe<Scalars['String']['output']>;
  lastModifiedDateTime: Scalars['DateTimeIso']['output'];
  mailbox?: Maybe<Scalars['String']['output']>;
  markdownComplete: Scalars['Boolean']['output'];
  mergeFromTicketId?: Maybe<Scalars['Float']['output']>;
  parentFolderId?: Maybe<Scalars['String']['output']>;
  receivedDateTime: Scalars['DateTimeIso']['output'];
  subject: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
  webhookRan: Scalars['Boolean']['output'];
};

export type AzureEmailAttachment = {
  __typename?: 'AzureEmailAttachment';
  AzureEmail?: Maybe<AzureEmail>;
  contentId?: Maybe<Scalars['String']['output']>;
  contentLocation?: Maybe<Scalars['String']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  emailId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isInline?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AzureEmailParticipatnt = {
  __typename?: 'AzureEmailParticipatnt';
  AzureEmail?: Maybe<AzureEmail>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  emailId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  userRole?: Maybe<Scalars['String']['output']>;
};

export type AzureGroupLabel = {
  __typename?: 'AzureGroupLabel';
  labelId: Scalars['String']['output'];
  labelName: Scalars['String']['output'];
};

export type AzureGroupLicense = {
  __typename?: 'AzureGroupLicense';
  licenseId: Scalars['String']['output'];
};

export type AzureGroupProvisioningError = {
  __typename?: 'AzureGroupProvisioningError';
  error: Scalars['String']['output'];
};

export type AzureGroups = {
  __typename?: 'AzureGroups';
  AzureGroupMembers?: Maybe<Array<AzureGroupsMembers>>;
  AzureTenant?: Maybe<AzureTenants>;
  allowExternalSenders?: Maybe<Scalars['Boolean']['output']>;
  assignedLabels?: Maybe<Array<AzureGroupLabel>>;
  assignedLicenses?: Maybe<Array<AzureGroupLicense>>;
  autoSubscribeNewMembers?: Maybe<Scalars['Boolean']['output']>;
  azureTenantId?: Maybe<Scalars['String']['output']>;
  classification?: Maybe<Scalars['String']['output']>;
  createdByCoreUserId?: Maybe<Scalars['String']['output']>;
  createdDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  deletedDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  expirationDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  groupTypes?: Maybe<Array<Scalars['String']['output']>>;
  hasMembersWithLicenseErrors?: Maybe<Scalars['Boolean']['output']>;
  hideFromAddressLists?: Maybe<Scalars['Boolean']['output']>;
  hideFromOutlookClients?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isAssignableToRole?: Maybe<Scalars['Boolean']['output']>;
  isSubscribedByMail?: Maybe<Scalars['Boolean']['output']>;
  lastSync?: Maybe<Scalars['DateTimeIso']['output']>;
  licenseProcessingState?: Maybe<Scalars['String']['output']>;
  mail?: Maybe<Scalars['String']['output']>;
  mailEnabled?: Maybe<Scalars['Boolean']['output']>;
  mailNickname?: Maybe<Scalars['String']['output']>;
  membershipRule?: Maybe<Scalars['String']['output']>;
  membershipRuleProcessingState?: Maybe<Scalars['String']['output']>;
  onPremisesDomainName?: Maybe<Scalars['String']['output']>;
  onPremisesLastSyncDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  onPremisesNetBiosName?: Maybe<Scalars['String']['output']>;
  onPremisesProvisioningErrors?: Maybe<Array<AzureGroupProvisioningError>>;
  onPremisesSamAccountName?: Maybe<Scalars['String']['output']>;
  onPremisesSecurityIdentifier?: Maybe<Scalars['String']['output']>;
  onPremisesSyncEnabled?: Maybe<Scalars['Boolean']['output']>;
  preferredDataLocation?: Maybe<Scalars['String']['output']>;
  preferredLanguage?: Maybe<Scalars['String']['output']>;
  proxyAddresses?: Maybe<Array<Scalars['String']['output']>>;
  renewedDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  securityEnabled?: Maybe<Scalars['Boolean']['output']>;
  securityIdentifier?: Maybe<Scalars['String']['output']>;
  serviceProvisioningErrors?: Maybe<Array<AzureGroupProvisioningError>>;
  theme?: Maybe<Scalars['String']['output']>;
  uniqueName?: Maybe<Scalars['String']['output']>;
  unseenCount?: Maybe<Scalars['Float']['output']>;
  updatedByCoreUserId?: Maybe<Scalars['String']['output']>;
  updatedDateTime: Scalars['DateTime']['output'];
  visibility?: Maybe<Scalars['String']['output']>;
};

export type AzureGroupsMembers = {
  __typename?: 'AzureGroupsMembers';
  AzureGroup?: Maybe<AzureGroups>;
  AzureUser?: Maybe<AzureUsers>;
  azureGroupId?: Maybe<Scalars['String']['output']>;
  azureUserId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

export type AzureLicense = {
  __typename?: 'AzureLicense';
  AzureUser?: Maybe<AzureUsers>;
  azureUserId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  skuId: Scalars['String']['output'];
  skuPartNumber: Scalars['String']['output'];
};

export type AzureTenants = {
  __typename?: 'AzureTenants';
  AzureDirectoryRoles?: Maybe<Array<AzureDirectoryRoles>>;
  AzureGroups?: Maybe<Array<AzureGroups>>;
  AzureUsers?: Maybe<Array<AzureUsers>>;
  CoreCompany?: Maybe<CoreCompanies>;
  coreCompanyId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  licensed?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  verified: Scalars['Boolean']['output'];
};

export type AzureUsers = {
  __typename?: 'AzureUsers';
  AzureDirectoryRoleMembers?: Maybe<Array<AzureDirectoryRoleMembers>>;
  AzureDirectoryRoles?: Maybe<Array<AzureDirectoryRoles>>;
  AzureGroupMembers?: Maybe<Array<AzureGroupsMembers>>;
  AzureGroups?: Maybe<Array<AzureGroups>>;
  AzureLicenses?: Maybe<Array<AzureLicense>>;
  AzureTenant?: Maybe<AzureTenants>;
  Contact?: Maybe<CoreContact>;
  accountEnabled?: Maybe<Scalars['Boolean']['output']>;
  authenticationMethods?: Maybe<Scalars['JSON']['output']>;
  businessPhones: Scalars['JSON']['output'];
  coreContactId?: Maybe<Scalars['Float']['output']>;
  displayName: Scalars['String']['output'];
  givenName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isMfaRegistered?: Maybe<Scalars['Boolean']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastMfaCheckDate?: Maybe<Scalars['DateTimeIso']['output']>;
  lastSynced?: Maybe<Scalars['DateTimeIso']['output']>;
  mail?: Maybe<Scalars['String']['output']>;
  mobilePhone?: Maybe<Scalars['String']['output']>;
  officeLocation?: Maybe<Scalars['String']['output']>;
  preferredLanguage?: Maybe<Scalars['String']['output']>;
  surname?: Maybe<Scalars['String']['output']>;
  tenantId: Scalars['String']['output'];
  userPrincipalName: Scalars['String']['output'];
};

export type BooleanProp = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  not?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BvoipCalls = {
  __typename?: 'BvoipCalls';
  ActualEndTime?: Maybe<Scalars['DateTimeIso']['output']>;
  ActualStartTime?: Maybe<Scalars['DateTimeIso']['output']>;
  CCS_ID?: Maybe<Scalars['String']['output']>;
  CRMCompany?: Maybe<Scalars['String']['output']>;
  CRMContactCompany?: Maybe<Scalars['String']['output']>;
  CRMContactCompanyID?: Maybe<Scalars['String']['output']>;
  CRMContactID?: Maybe<Scalars['String']['output']>;
  CRMContactLink?: Maybe<Scalars['String']['output']>;
  CRMDetailsLink?: Maybe<Scalars['String']['output']>;
  CRMPlatform?: Maybe<Scalars['String']['output']>;
  CRMTicketID?: Maybe<Scalars['String']['output']>;
  CRMTicketLink?: Maybe<Scalars['String']['output']>;
  CallID?: Maybe<Scalars['Float']['output']>;
  DNSummary?: Maybe<Scalars['String']['output']>;
  Destination?: Maybe<Scalars['String']['output']>;
  DestinationName?: Maybe<Scalars['String']['output']>;
  DownloadRecording?: Maybe<Scalars['String']['output']>;
  Entry_Date?: Maybe<Scalars['DateTimeIso']['output']>;
  ExtensionName?: Maybe<Scalars['String']['output']>;
  ExtensionNumber?: Maybe<Scalars['String']['output']>;
  HourNumber?: Maybe<Scalars['Float']['output']>;
  IVR?: Maybe<Scalars['String']['output']>;
  IVRName?: Maybe<Scalars['String']['output']>;
  Inbound?: Maybe<Scalars['String']['output']>;
  IntervalNumber?: Maybe<Scalars['Float']['output']>;
  MinuteNumber?: Maybe<Scalars['Float']['output']>;
  NC_ID?: Maybe<Scalars['String']['output']>;
  OriginatedBy?: Maybe<Scalars['String']['output']>;
  OriginatedByName?: Maybe<Scalars['String']['output']>;
  PhoneCall?: Maybe<PhonesCalls>;
  Queue?: Maybe<Scalars['String']['output']>;
  QueueName?: Maybe<Scalars['String']['output']>;
  QueueWaitSeconds?: Maybe<Scalars['Float']['output']>;
  RecordingPlayer?: Maybe<Scalars['String']['output']>;
  RecordingURL?: Maybe<Scalars['String']['output']>;
  ReferenceDate?: Maybe<Scalars['DateTimeIso']['output']>;
  RingGroup?: Maybe<Scalars['String']['output']>;
  RingGroupName?: Maybe<Scalars['String']['output']>;
  SecondsTillFirstTalk?: Maybe<Scalars['Float']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  TalkTime?: Maybe<Scalars['String']['output']>;
  TalkTimeSeconds?: Maybe<Scalars['Float']['output']>;
  Transcription?: Maybe<Scalars['String']['output']>;
  Transferred?: Maybe<Scalars['String']['output']>;
  call_history_id?: Maybe<Scalars['String']['output']>;
  errorDownloadingCount?: Maybe<Scalars['Float']['output']>;
  localPath?: Maybe<Scalars['String']['output']>;
  phonesCallId?: Maybe<Scalars['Float']['output']>;
  recordingSaved?: Maybe<Scalars['Boolean']['output']>;
  recordingSummarized?: Maybe<Scalars['Boolean']['output']>;
  recordingSummarizing?: Maybe<Scalars['Boolean']['output']>;
  recordingTranscribed?: Maybe<Scalars['Boolean']['output']>;
  recordingTranscribing?: Maybe<Scalars['Boolean']['output']>;
  transcribingBackendId?: Maybe<Scalars['String']['output']>;
  uploadAttempts?: Maybe<Scalars['Float']['output']>;
  uploadingBackendId?: Maybe<Scalars['String']['output']>;
};

export type ContractsAssignments = {
  __typename?: 'ContractsAssignments';
  coreContactId?: Maybe<Scalars['Float']['output']>;
  coreLocationId?: Maybe<Scalars['Float']['output']>;
  device?: Maybe<DattormmDevices>;
  deviceId?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  instance?: Maybe<ContractsInstances>;
  instanceId: Scalars['Float']['output'];
};

export type ContractsContracts = {
  __typename?: 'ContractsContracts';
  additionalPrice: Scalars['Float']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  iconColor?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  instances?: Maybe<Array<ContractsInstances>>;
  internalDescription: Scalars['String']['output'];
  internalUnitCost?: Maybe<Scalars['Float']['output']>;
  laborIncluded: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  publicDescription: Scalars['String']['output'];
  service?: Maybe<Scalars['String']['output']>;
  unitPrice: Scalars['Float']['output'];
  unitsMeasured: Scalars['String']['output'];
};

export type ContractsInstances = {
  __typename?: 'ContractsInstances';
  CoreCompanies?: Maybe<Array<CoreCompanies>>;
  additionalPrice?: Maybe<Scalars['Float']['output']>;
  approvedUnits: Scalars['Int']['output'];
  assignments?: Maybe<Array<ContractsAssignments>>;
  autoAproveUnits: Scalars['Boolean']['output'];
  contract?: Maybe<ContractsContracts>;
  contractId: Scalars['Int']['output'];
  coreCompany?: Maybe<CoreCompanies>;
  coreCompanyId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  unitPrice?: Maybe<Scalars['Float']['output']>;
  usedUnits?: Maybe<Scalars['Int']['output']>;
};

export type CoreCompanies = {
  __typename?: 'CoreCompanies';
  AzureTenants?: Maybe<Array<AzureTenants>>;
  ContractsInstances?: Maybe<ContractsInstances>;
  CoreLocations?: Maybe<Array<CoreLocations>>;
  DattormmSites?: Maybe<DattormmSites>;
  PhoneCalls?: Maybe<Array<PhonesCalls>>;
  active: Scalars['Boolean']['output'];
  billingConfigured: Scalars['Boolean']['output'];
  contractsInstances?: Maybe<Scalars['Float']['output']>;
  dattormmSites?: Maybe<Scalars['Float']['output']>;
  defaultHourlyRate: Scalars['Float']['output'];
  defaultTicketLevel: Scalars['String']['output'];
  id?: Maybe<Scalars['Float']['output']>;
  instances?: Maybe<Array<ContractsInstances>>;
  isOwnCompany: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sites?: Maybe<Array<DattormmSites>>;
};

export type CoreContact = {
  __typename?: 'CoreContact';
  AzureUsers?: Maybe<Array<AzureUsers>>;
  PhoneCallLegs?: Maybe<Array<PhonesCallLegs>>;
  PhoneCalls?: Maybe<Array<PhonesCalls>>;
  alternatePhone?: Maybe<Scalars['String']['output']>;
  coreCompanyId: Scalars['Float']['output'];
  coreLocationId?: Maybe<Scalars['Float']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mobilePhone?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneExtension?: Maybe<Scalars['String']['output']>;
};

export type CoreGroup = {
  __typename?: 'CoreGroup';
  ScheduleInstances?: Maybe<Array<ScheduleInstance>>;
  SchedulePlans?: Maybe<Array<SchedulePlan>>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  members?: Maybe<Array<CoreGroupsMembers>>;
  name: Scalars['String']['output'];
};

export type CoreGroupsMembers = {
  __typename?: 'CoreGroupsMembers';
  coreUserId?: Maybe<Scalars['String']['output']>;
  group: CoreGroup;
  groupId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user: CoreUsers;
};

export type CoreLocations = {
  __typename?: 'CoreLocations';
  CoreCompany?: Maybe<CoreCompanies>;
  active: Scalars['Boolean']['output'];
  address1: Scalars['String']['output'];
  address2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  coreCompanyId?: Maybe<Scalars['Float']['output']>;
  extension?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  isPrimary: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  state: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type CoreTag = {
  __typename?: 'CoreTag';
  autotaskTicketId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Date']['output'];
  createdByService?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  tagLink?: Maybe<CoreTagLink>;
  updatedAt: Scalars['Date']['output'];
};

export type CoreTagLink = {
  __typename?: 'CoreTagLink';
  createdAt: Scalars['Date']['output'];
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
  tags: Array<CoreTag>;
  updatedAt: Scalars['Date']['output'];
};

export type CoreTimeclock = {
  __typename?: 'CoreTimeclock';
  TicketTimelines?: Maybe<Array<TicketTimeline>>;
  autotaskUserId?: Maybe<Scalars['Float']['output']>;
  coreUserId?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['DateTimeIso']['output']>;
  fromRole?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  isDispatchedTicket?: Maybe<Scalars['Boolean']['output']>;
  isSynced: Scalars['Boolean']['output'];
  lastActive?: Maybe<Scalars['DateTimeIso']['output']>;
  mergeFromTicketId?: Maybe<Scalars['Float']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  originalEnd?: Maybe<Scalars['DateTimeIso']['output']>;
  originalStart?: Maybe<Scalars['DateTimeIso']['output']>;
  start?: Maybe<Scalars['DateTimeIso']['output']>;
  ticket?: Maybe<AutotaskTickets>;
  ticketCompleted?: Maybe<Scalars['Boolean']['output']>;
  ticketId?: Maybe<Scalars['Float']['output']>;
  ticketUpdateData?: Maybe<Scalars['JSON']['output']>;
  todoId?: Maybe<Scalars['Float']['output']>;
  user?: Maybe<CoreUsers>;
};

export type CoreUsers = {
  __typename?: 'CoreUsers';
  PhoneCallLegs?: Maybe<Array<PhonesCallLegs>>;
  PhoneCalls?: Maybe<Array<PhonesCalls>>;
  ScheduleInstances?: Maybe<Array<ScheduleInstance>>;
  SchedulePlans?: Maybe<Array<SchedulePlan>>;
  autotaskId?: Maybe<Scalars['Float']['output']>;
  autotaskLocationId?: Maybe<Scalars['Float']['output']>;
  bvoipExtention?: Maybe<Scalars['Float']['output']>;
  canContactCustomers?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Array<CoreGroupsMembers>>;
  id?: Maybe<Scalars['String']['output']>;
  isDispatchable?: Maybe<Scalars['Boolean']['output']>;
  isRoleTier1?: Maybe<Scalars['Boolean']['output']>;
  isRoleTier2?: Maybe<Scalars['Boolean']['output']>;
  isRoleTier2FirstUp?: Maybe<Scalars['Boolean']['output']>;
  isRoleTriage?: Maybe<Scalars['Boolean']['output']>;
  isScheduler?: Maybe<Scalars['Boolean']['output']>;
  isSherpaChampion?: Maybe<Scalars['Boolean']['output']>;
  isTech?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  techTier?: Maybe<Scalars['Float']['output']>;
  theme: Scalars['String']['output'];
  timwoodsId?: Maybe<Scalars['String']['output']>;
  useNewRank?: Maybe<Scalars['Boolean']['output']>;
  workDayEnd?: Maybe<Scalars['String']['output']>;
  workDayStart?: Maybe<Scalars['String']['output']>;
};

export type DattormmDeviceMemory = {
  __typename?: 'DattormmDeviceMemory';
  DattormmDevice?: Maybe<DattormmDevices>;
  bank?: Maybe<Scalars['String']['output']>;
  dattormmDeviceId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  index?: Maybe<Scalars['Float']['output']>;
  module?: Maybe<Scalars['String']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  speed?: Maybe<Scalars['Float']['output']>;
};

export type DattormmDevices = {
  __typename?: 'DattormmDevices';
  DattormmDeviceMemory?: Maybe<Array<DattormmDeviceMemory>>;
  a64Bit?: Maybe<Scalars['Boolean']['output']>;
  antivirusProduct?: Maybe<Scalars['String']['output']>;
  antivirusStatus?: Maybe<Scalars['String']['output']>;
  archived: Scalars['Boolean']['output'];
  assignments?: Maybe<Array<ContractsAssignments>>;
  cDriveFreeSpace?: Maybe<Scalars['Float']['output']>;
  cDriveTotalSpace?: Maybe<Scalars['Float']['output']>;
  cagVersion?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  creationDate: Scalars['DateTimeIso']['output'];
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  deviceClass: Scalars['String']['output'];
  displayVersion?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  extIpAddress?: Maybe<Scalars['String']['output']>;
  hostname: Scalars['String']['output'];
  id?: Maybe<Scalars['Float']['output']>;
  intIpAddress: Scalars['String']['output'];
  lastAuditDate: Scalars['DateTimeIso']['output'];
  lastLoggedInUser?: Maybe<Scalars['String']['output']>;
  lastReboot: Scalars['DateTimeIso']['output'];
  lastSeen: Scalars['DateTimeIso']['output'];
  online: Scalars['Boolean']['output'];
  operatingSystem?: Maybe<Scalars['String']['output']>;
  patchStatus?: Maybe<Scalars['String']['output']>;
  patchesApprovedPending: Scalars['Float']['output'];
  patchesInstalled?: Maybe<Scalars['Float']['output']>;
  patchesNotApproved: Scalars['Float']['output'];
  patchingPolicies?: Maybe<Array<DattormmPatchingPolicies>>;
  portalUrl: Scalars['String']['output'];
  rebootRequired?: Maybe<Scalars['Boolean']['output']>;
  site?: Maybe<DattormmSites>;
  siteId: Scalars['Float']['output'];
  siteName: Scalars['String']['output'];
  siteUid: Scalars['String']['output'];
  snmpEnabled: Scalars['Boolean']['output'];
  softwareStatus: Scalars['String']['output'];
  suspended: Scalars['Boolean']['output'];
  syncId?: Maybe<Scalars['String']['output']>;
  totalPhysicalMemory?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  udf1?: Maybe<Scalars['String']['output']>;
  udf2?: Maybe<Scalars['String']['output']>;
  udf3?: Maybe<Scalars['String']['output']>;
  udf4?: Maybe<Scalars['String']['output']>;
  udf5?: Maybe<Scalars['String']['output']>;
  udf6?: Maybe<Scalars['String']['output']>;
  udf7?: Maybe<Scalars['String']['output']>;
  udf8?: Maybe<Scalars['String']['output']>;
  udf9?: Maybe<Scalars['String']['output']>;
  udf10?: Maybe<Scalars['String']['output']>;
  udf11?: Maybe<Scalars['String']['output']>;
  udf12?: Maybe<Scalars['String']['output']>;
  udf13?: Maybe<Scalars['String']['output']>;
  udf14?: Maybe<Scalars['String']['output']>;
  udf15?: Maybe<Scalars['String']['output']>;
  udf16?: Maybe<Scalars['String']['output']>;
  udf17?: Maybe<Scalars['String']['output']>;
  udf18?: Maybe<Scalars['String']['output']>;
  udf19?: Maybe<Scalars['String']['output']>;
  udf20?: Maybe<Scalars['String']['output']>;
  udf21?: Maybe<Scalars['String']['output']>;
  udf22?: Maybe<Scalars['String']['output']>;
  udf23?: Maybe<Scalars['String']['output']>;
  udf24?: Maybe<Scalars['String']['output']>;
  udf25?: Maybe<Scalars['String']['output']>;
  udf26?: Maybe<Scalars['String']['output']>;
  udf27?: Maybe<Scalars['String']['output']>;
  udf28?: Maybe<Scalars['String']['output']>;
  udf29?: Maybe<Scalars['String']['output']>;
  udf30?: Maybe<Scalars['String']['output']>;
  uid: Scalars['String']['output'];
  warrantyDate?: Maybe<Scalars['String']['output']>;
};

export type DattormmPatchingPolicies = {
  __typename?: 'DattormmPatchingPolicies';
  device: DattormmDevices;
  deviceId: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  lastRun?: Maybe<Scalars['Date']['output']>;
  lastSync: Scalars['Date']['output'];
  name: Scalars['String']['output'];
  schedule?: Maybe<Scalars['String']['output']>;
};

export type DattormmSites = {
  __typename?: 'DattormmSites';
  CoreCompanies?: Maybe<Array<CoreCompanies>>;
  accountUid: Scalars['String']['output'];
  autotaskCompanyId?: Maybe<Scalars['String']['output']>;
  autotaskCompanyName?: Maybe<Scalars['String']['output']>;
  coreCompany?: Maybe<CoreCompanies>;
  coreCompanyId?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  devices?: Maybe<Array<DattormmDevices>>;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  numberOfDevices: Scalars['Float']['output'];
  numberOfOfflineDevices: Scalars['Float']['output'];
  numberOfOnlineDevices: Scalars['Float']['output'];
  onDemand: Scalars['Boolean']['output'];
  portalUrl: Scalars['String']['output'];
  proxySettings?: Maybe<Scalars['Float']['output']>;
  splashtopAutoInstall: Scalars['Boolean']['output'];
  uid: Scalars['String']['output'];
};

export type FeedbackInstance = {
  __typename?: 'FeedbackInstance';
  autotaskTicketId?: Maybe<Scalars['Int']['output']>;
  coreCompanyId?: Maybe<Scalars['Int']['output']>;
  coreContactId?: Maybe<Scalars['Int']['output']>;
  createdByCoreUserId?: Maybe<Scalars['String']['output']>;
  createdDateTime: Scalars['DateTime']['output'];
  customerEmail?: Maybe<Scalars['String']['output']>;
  customerFeedback?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  score?: Maybe<FeedbackScore>;
  scoreId?: Maybe<Scalars['Int']['output']>;
  updatedByCoreUserId?: Maybe<Scalars['String']['output']>;
  updatedDateTime: Scalars['DateTime']['output'];
};

export type FeedbackScore = {
  __typename?: 'FeedbackScore';
  createdByCoreUserId?: Maybe<Scalars['String']['output']>;
  createdDateTime: Scalars['DateTime']['output'];
  customerResp: Scalars['String']['output'];
  customerRespTitle: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  instances?: Maybe<Array<FeedbackInstance>>;
  name: Scalars['String']['output'];
  updatedByCoreUserId?: Maybe<Scalars['String']['output']>;
  updatedDateTime: Scalars['DateTime']['output'];
};

export type FormsForm = {
  __typename?: 'FormsForm';
  FormFields?: Maybe<Array<FormsFormField>>;
  FormInstances?: Maybe<Array<FormsInstance>>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type FormsFormField = {
  __typename?: 'FormsFormField';
  Form?: Maybe<FormsForm>;
  config?: Maybe<Scalars['JSON']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type FormsInstance = {
  __typename?: 'FormsInstance';
  Form?: Maybe<FormsForm>;
  TicketTimelines?: Maybe<Array<TicketTimeline>>;
  autotaskTicketId?: Maybe<Scalars['Float']['output']>;
  complete: Scalars['Boolean']['output'];
  context?: Maybe<Scalars['JSON']['output']>;
  created_at?: Maybe<Scalars['DateTimeIso']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  recipeInstanceId?: Maybe<Scalars['Float']['output']>;
  todoId?: Maybe<Scalars['Float']['output']>;
  updated_at?: Maybe<Scalars['DateTimeIso']['output']>;
  values?: Maybe<Scalars['JSON']['output']>;
};

export type GetAutotaskCompaniesArgs = {
  coreCompanyId?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
};

export type GetCoreCompaniesSubfieldArgs = {
  contractsInstances?: InputMaybe<NumberProp>;
  dattormmSites?: InputMaybe<NumberProp>;
  defaultHourlyRate?: InputMaybe<NumberProp>;
  defaultTicketLevel?: InputMaybe<StringProp>;
  id?: InputMaybe<NumberProp>;
  name?: InputMaybe<StringProp>;
};

export type GetTicketsArgs = {
  assignedResourceID?: InputMaybe<NumberProp>;
  autotaskCompany?: InputMaybe<GetAutotaskCompaniesArgs>;
  company?: InputMaybe<GetAutotaskCompaniesArgs>;
  companyID?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextActionDate?: InputMaybe<TimeQueryProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<NumberProp>;
  queueID?: InputMaybe<NumberProp>;
  status?: InputMaybe<NumberProp>;
  ticketNumber?: InputMaybe<StringProp>;
  title?: InputMaybe<StringProp>;
};

export type GetUsersArgs = {
  autotaskId?: InputMaybe<NumberProp>;
  id?: InputMaybe<StringProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  autoScheduleDate: Scalars['JSON']['output'];
  createAIChat: AiChat;
  createAIChatMessages: AiChatMessages;
  createAutotaskTickets: AutotaskTickets;
  createAzureLicense: AzureLicense;
  createAzureTenants: AzureTenants;
  createAzureUsers: AzureUsers;
  createContractsAssignments: ContractsAssignments;
  createContractsContracts: ContractsContracts;
  createContractsInstances: ContractsInstances;
  createCoreCompanies: CoreCompanies;
  createCoreContact: CoreContact;
  createCoreGroup: CoreGroup;
  createCoreGroupsMembers: CoreGroupsMembers;
  createCoreTag: CoreTag;
  createCoreTagLink: CoreTagLink;
  createCoreUsers: CoreUsers;
  createDattormmDevices: DattormmDevices;
  createDattormmPatchingPolicies: DattormmPatchingPolicies;
  createDattormmSites: DattormmSites;
  createFeedbackInstance: FeedbackInstance;
  createFeedbackScore: FeedbackScore;
  createPhonesCallLegs: PhonesCallLegs;
  createPhonesCalls: PhonesCalls;
  createRecipeNode: RecipeNode;
  createRocketcyberAccounts: RocketcyberAccounts;
  createRocketcyberAgents: RocketcyberAgents;
  createRocketcyberIncidents: RocketcyberIncidents;
  createScheduleInstance: ScheduleInstance;
  createSchedulePlan: SchedulePlan;
  createTicketTimeline: TicketTimeline;
  deleteAIChat: Scalars['Boolean']['output'];
  deleteAIChatMessages: Scalars['Boolean']['output'];
  deleteAutotaskTickets: Scalars['Boolean']['output'];
  deleteAzureLicense: Scalars['Boolean']['output'];
  deleteAzureTenants: Scalars['Boolean']['output'];
  deleteAzureUsers: Scalars['Boolean']['output'];
  deleteContractsAssignments: Scalars['Boolean']['output'];
  deleteContractsContracts: Scalars['Boolean']['output'];
  deleteContractsInstances: Scalars['Boolean']['output'];
  deleteCoreCompanies: Scalars['Boolean']['output'];
  deleteCoreContact: Scalars['Boolean']['output'];
  deleteCoreGroup: Scalars['Boolean']['output'];
  deleteCoreGroupsMembers: Scalars['Boolean']['output'];
  deleteCoreTag: Scalars['Boolean']['output'];
  deleteCoreTagLink: Scalars['Boolean']['output'];
  deleteCoreUsers: Scalars['Boolean']['output'];
  deleteDattormmDevices: Scalars['Boolean']['output'];
  deleteDattormmPatchingPolicies: Scalars['Boolean']['output'];
  deleteDattormmSites: Scalars['Boolean']['output'];
  deleteFeedbackInstance: Scalars['Boolean']['output'];
  deleteFeedbackScore: Scalars['Boolean']['output'];
  deletePhonesCallLegs: Scalars['Boolean']['output'];
  deletePhonesCalls: Scalars['Boolean']['output'];
  deleteRecipeNode: Scalars['Boolean']['output'];
  deleteRocketcyberAccounts: Scalars['Boolean']['output'];
  deleteRocketcyberAgents: Scalars['Boolean']['output'];
  deleteRocketcyberIncidents: Scalars['Boolean']['output'];
  deleteScheduleInstance: Scalars['Boolean']['output'];
  deleteSchedulePlan: Scalars['Boolean']['output'];
  deleteTicketTimeline: Scalars['Boolean']['output'];
  mergeCoreCompanies: Scalars['Boolean']['output'];
  optimizeSchedule: Scalars['JSON']['output'];
  rescheduleScheduleDate: Scalars['JSON']['output'];
  syncDattormmDeviceInfo: DattormmDevices;
  syncUsersCalendarsToSchedule: Scalars['JSON']['output'];
  updateAIChat: AiChat;
  updateAIChatMessages: AiChatMessages;
  updateAutotaskTickets: AutotaskTickets;
  updateAzureLicense: AzureLicense;
  updateAzureTenants: AzureTenants;
  updateAzureUsers: AzureUsers;
  updateContractsAssignments: ContractsAssignments;
  updateContractsContracts: ContractsContracts;
  updateContractsInstances: ContractsInstances;
  updateCoreCompanies: CoreCompanies;
  updateCoreContact: CoreContact;
  updateCoreGroup: CoreGroup;
  updateCoreGroupsMembers: CoreGroupsMembers;
  updateCoreTag: CoreTag;
  updateCoreTagLink: CoreTagLink;
  updateCoreUsers: CoreUsers;
  updateDattormmDevices: DattormmDevices;
  updateDattormmPatchingPolicies: DattormmPatchingPolicies;
  updateDattormmSites: DattormmSites;
  updateFeedbackInstance: FeedbackInstance;
  updateFeedbackScore: FeedbackScore;
  updatePhonesCallLegs: PhonesCallLegs;
  updatePhonesCalls: PhonesCalls;
  updateRecipeNode: RecipeNode;
  updateRocketcyberAccounts: RocketcyberAccounts;
  updateRocketcyberAgents: RocketcyberAgents;
  updateRocketcyberIncidents: RocketcyberIncidents;
  updateScheduleInstance: ScheduleInstance;
  updateSchedulePlan: SchedulePlan;
  updateTicketTimeline: TicketTimeline;
  updateTimeclock: CoreTimeclock;
  verifyAzureTenant: AzureTenants;
};


export type MutationAutoScheduleDateArgs = {
  date: Scalars['String']['input'];
};


export type MutationCreateAiChatArgs = {
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ticketId?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationCreateAiChatMessagesArgs = {
  chatId: Scalars['Float']['input'];
  coreUserId?: InputMaybe<Scalars['Float']['input']>;
  message: Scalars['String']['input'];
  role: Scalars['String']['input'];
};


export type MutationCreateAutotaskTicketsArgs = {
  companyID: Scalars['Float']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Scalars['Float']['input']>;
  title: Scalars['String']['input'];
};


export type MutationCreateAzureLicenseArgs = {
  azureUserId?: InputMaybe<Scalars['String']['input']>;
  skuId?: InputMaybe<Scalars['String']['input']>;
  skuPartNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateAzureTenantsArgs = {
  coreCompanyId: Scalars['Float']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateAzureUsersArgs = {
  accountEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  coreContactId?: InputMaybe<Scalars['Int']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  isMfaRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastMfaCheckDate?: InputMaybe<Scalars['Date']['input']>;
  lastSynced?: InputMaybe<Scalars['Date']['input']>;
  mail?: InputMaybe<Scalars['String']['input']>;
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  officeLocation?: InputMaybe<Scalars['String']['input']>;
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  surname?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  userPrincipalName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateContractsAssignmentsArgs = {
  coreContactId?: InputMaybe<Scalars['Float']['input']>;
  coreLocationId?: InputMaybe<Scalars['Float']['input']>;
  deviceId?: InputMaybe<Scalars['Float']['input']>;
  instanceId?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationCreateContractsContractsArgs = {
  additionalPrice?: InputMaybe<Scalars['Float']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  iconColor?: InputMaybe<Scalars['String']['input']>;
  internalDescription?: InputMaybe<Scalars['String']['input']>;
  internalUnitCost?: InputMaybe<Scalars['Float']['input']>;
  laborIncluded?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  publicDescription?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  unitsMeasured?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateContractsInstancesArgs = {
  additionalPrice?: InputMaybe<Scalars['Float']['input']>;
  approvedUnits?: InputMaybe<Scalars['Float']['input']>;
  autoAproveUnits?: InputMaybe<Scalars['Boolean']['input']>;
  contractId?: InputMaybe<Scalars['Float']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Float']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  usedUnits?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationCreateCoreCompaniesArgs = {
  active?: Scalars['Boolean']['input'];
  billingConfigured?: Scalars['Boolean']['input'];
  defaultHourlyRate?: Scalars['Float']['input'];
  defaultTicketLevel?: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateCoreContactArgs = {
  alternatePhone?: InputMaybe<Scalars['String']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Int']['input']>;
  coreLocationId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneExtension?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateCoreGroupArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};


export type MutationCreateCoreGroupsMembersArgs = {
  coreUserId: Scalars['String']['input'];
  groupId: Scalars['Float']['input'];
};


export type MutationCreateCoreTagArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreateCoreTagLinkArgs = {
  link: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateCoreUsersArgs = {
  autotaskId?: InputMaybe<Scalars['Int']['input']>;
  autotaskLocationId?: InputMaybe<Scalars['Int']['input']>;
  bvoipExtention?: InputMaybe<Scalars['Int']['input']>;
  canContactCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isDispatchable?: InputMaybe<Scalars['Boolean']['input']>;
  isRoleTier1?: InputMaybe<Scalars['Boolean']['input']>;
  isRoleTier2?: InputMaybe<Scalars['Boolean']['input']>;
  isRoleTier2FirstUp?: InputMaybe<Scalars['Boolean']['input']>;
  isRoleTriage?: InputMaybe<Scalars['Boolean']['input']>;
  isScheduler?: InputMaybe<Scalars['Boolean']['input']>;
  isSherpaChampion?: InputMaybe<Scalars['Boolean']['input']>;
  isTech?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  techTier?: InputMaybe<Scalars['Int']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  timwoodsId?: InputMaybe<Scalars['String']['input']>;
  useNewRank?: InputMaybe<Scalars['Boolean']['input']>;
  workDayEnd?: InputMaybe<Scalars['String']['input']>;
  workDayStart?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateDattormmDevicesArgs = {
  a64Bit?: InputMaybe<Scalars['Boolean']['input']>;
  antivirusProduct?: InputMaybe<Scalars['String']['input']>;
  antivirusStatus?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  cagVersion?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  creationDate?: InputMaybe<Scalars['Date']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  deviceClass?: InputMaybe<Scalars['String']['input']>;
  displayVersion?: InputMaybe<Scalars['String']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  extIpAddress?: InputMaybe<Scalars['String']['input']>;
  hostname?: InputMaybe<Scalars['String']['input']>;
  intIpAddress?: InputMaybe<Scalars['String']['input']>;
  lastAuditDate?: InputMaybe<Scalars['Date']['input']>;
  lastLoggedInUser?: InputMaybe<Scalars['String']['input']>;
  lastReboot?: InputMaybe<Scalars['Date']['input']>;
  lastSeen?: InputMaybe<Scalars['Date']['input']>;
  online?: InputMaybe<Scalars['Boolean']['input']>;
  operatingSystem?: InputMaybe<Scalars['String']['input']>;
  patchStatus?: InputMaybe<Scalars['String']['input']>;
  patchesApprovedPending?: InputMaybe<Scalars['Float']['input']>;
  patchesInstalled?: InputMaybe<Scalars['Float']['input']>;
  patchesNotApproved?: InputMaybe<Scalars['Float']['input']>;
  portalUrl?: InputMaybe<Scalars['String']['input']>;
  rebootRequired?: InputMaybe<Scalars['Boolean']['input']>;
  siteId?: InputMaybe<Scalars['Float']['input']>;
  siteName?: InputMaybe<Scalars['String']['input']>;
  siteUid?: InputMaybe<Scalars['String']['input']>;
  snmpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  softwareStatus?: InputMaybe<Scalars['String']['input']>;
  suspended?: InputMaybe<Scalars['Boolean']['input']>;
  syncId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  udf1?: InputMaybe<Scalars['String']['input']>;
  udf2?: InputMaybe<Scalars['String']['input']>;
  udf3?: InputMaybe<Scalars['String']['input']>;
  udf4?: InputMaybe<Scalars['String']['input']>;
  udf5?: InputMaybe<Scalars['String']['input']>;
  udf6?: InputMaybe<Scalars['String']['input']>;
  udf7?: InputMaybe<Scalars['String']['input']>;
  udf8?: InputMaybe<Scalars['String']['input']>;
  udf9?: InputMaybe<Scalars['String']['input']>;
  udf10?: InputMaybe<Scalars['String']['input']>;
  udf11?: InputMaybe<Scalars['String']['input']>;
  udf12?: InputMaybe<Scalars['String']['input']>;
  udf13?: InputMaybe<Scalars['String']['input']>;
  udf14?: InputMaybe<Scalars['String']['input']>;
  udf15?: InputMaybe<Scalars['String']['input']>;
  udf16?: InputMaybe<Scalars['String']['input']>;
  udf17?: InputMaybe<Scalars['String']['input']>;
  udf18?: InputMaybe<Scalars['String']['input']>;
  udf19?: InputMaybe<Scalars['String']['input']>;
  udf20?: InputMaybe<Scalars['String']['input']>;
  udf21?: InputMaybe<Scalars['String']['input']>;
  udf22?: InputMaybe<Scalars['String']['input']>;
  udf23?: InputMaybe<Scalars['String']['input']>;
  udf24?: InputMaybe<Scalars['String']['input']>;
  udf25?: InputMaybe<Scalars['String']['input']>;
  udf26?: InputMaybe<Scalars['String']['input']>;
  udf27?: InputMaybe<Scalars['String']['input']>;
  udf28?: InputMaybe<Scalars['String']['input']>;
  udf29?: InputMaybe<Scalars['String']['input']>;
  udf30?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  warrantyDate?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateDattormmPatchingPoliciesArgs = {
  deviceId?: InputMaybe<Scalars['Float']['input']>;
  lastRun?: InputMaybe<Scalars['Date']['input']>;
  lastSync?: InputMaybe<Scalars['Date']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  schedule?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateDattormmSitesArgs = {
  accountUid?: InputMaybe<Scalars['String']['input']>;
  autotaskCompanyId?: InputMaybe<Scalars['String']['input']>;
  autotaskCompanyName?: InputMaybe<Scalars['String']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  numberOfDevices?: InputMaybe<Scalars['Float']['input']>;
  numberOfOfflineDevices?: InputMaybe<Scalars['Float']['input']>;
  numberOfOnlineDevices?: InputMaybe<Scalars['Float']['input']>;
  onDemand?: InputMaybe<Scalars['Boolean']['input']>;
  portalUrl?: InputMaybe<Scalars['String']['input']>;
  proxySettings?: InputMaybe<Scalars['Float']['input']>;
  splashtopAutoInstall?: InputMaybe<Scalars['Boolean']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateFeedbackInstanceArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateFeedbackScoreArgs = {
  customerResp: Scalars['String']['input'];
  customerRespTitle: Scalars['String']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreatePhonesCallLegsArgs = {
  callProvider?: InputMaybe<Scalars['String']['input']>;
  callProviderId?: InputMaybe<Scalars['Int']['input']>;
  coreContactId?: InputMaybe<Scalars['Int']['input']>;
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['Date']['input']>;
  extention?: InputMaybe<Scalars['String']['input']>;
  phonesCallId?: InputMaybe<Scalars['Int']['input']>;
  recordingLocalPath?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['Date']['input']>;
  transcription?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreatePhonesCallsArgs = {
  atTicketId?: InputMaybe<Scalars['Int']['input']>;
  callProvider?: InputMaybe<Scalars['String']['input']>;
  callProviderId?: InputMaybe<Scalars['Int']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Int']['input']>;
  coreContactId?: InputMaybe<Scalars['Int']['input']>;
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['Date']['input']>;
  extention?: InputMaybe<Scalars['String']['input']>;
  fromNumber?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['Date']['input']>;
  toNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateRecipeNodeArgs = {
  data: Scalars['String']['input'];
  id: Scalars['String']['input'];
  positionX: Scalars['Float']['input'];
  positionY: Scalars['Float']['input'];
  recipeId: Scalars['Float']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};


export type MutationCreateRocketcyberAccountsArgs = {
  coreCompanyId?: InputMaybe<Scalars['Float']['input']>;
  emails: Scalars['String']['input'];
  hierarchy: Scalars['String']['input'];
  id: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  path: Scalars['String']['input'];
  status: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type MutationCreateRocketcyberAgentsArgs = {
  accountPath: Scalars['String']['input'];
  agentVersion: Scalars['String']['input'];
  architecture: Scalars['String']['input'];
  build: Scalars['String']['input'];
  connectivity: Scalars['String']['input'];
  customerId: Scalars['Float']['input'];
  edition: Scalars['String']['input'];
  family: Scalars['String']['input'];
  hostname: Scalars['String']['input'];
  ipv4Address: Scalars['String']['input'];
  macAddress: Scalars['String']['input'];
  platform: Scalars['String']['input'];
  release: Scalars['String']['input'];
  version: Scalars['String']['input'];
};


export type MutationCreateRocketcyberIncidentsArgs = {
  accountId: Scalars['Float']['input'];
  autotaskTicketId?: InputMaybe<Scalars['Float']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  description: Scalars['String']['input'];
  eventCount: Scalars['Float']['input'];
  id: Scalars['Float']['input'];
  remediation: Scalars['String']['input'];
  resolvedAt?: InputMaybe<Scalars['Date']['input']>;
  status: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type MutationCreateScheduleInstanceArgs = {
  atTicketId?: InputMaybe<Scalars['Int']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  azureCalendarId?: InputMaybe<Scalars['String']['input']>;
  conflictDetected?: InputMaybe<Scalars['Boolean']['input']>;
  coreGroupId?: InputMaybe<Scalars['Int']['input']>;
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  endDateTime?: InputMaybe<Scalars['Date']['input']>;
  overidePlan?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleInstanceDay?: InputMaybe<Scalars['String']['input']>;
  schedulePlanId?: InputMaybe<Scalars['Int']['input']>;
  schedulerLog?: InputMaybe<Scalars['String']['input']>;
  startDateTime?: InputMaybe<Scalars['Date']['input']>;
};


export type MutationCreateSchedulePlanArgs = {
  atTicketId?: InputMaybe<Scalars['Int']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  blockSizeInMinutes?: InputMaybe<Scalars['Int']['input']>;
  coreGroupId?: InputMaybe<Scalars['Int']['input']>;
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredUserCount?: InputMaybe<Scalars['Int']['input']>;
  end_hour?: InputMaybe<Scalars['Int']['input']>;
  end_minute?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  optimalBlockSizeInMinutes?: InputMaybe<Scalars['Int']['input']>;
  splitableIntoBlocks?: InputMaybe<Scalars['Boolean']['input']>;
  startTimeDay_daysOfMonth?: InputMaybe<Array<Scalars['Int']['input']>>;
  startTimeDay_daysOfWeek?: InputMaybe<Array<Scalars['String']['input']>>;
  startTimeDay_monthsOfTheYear?: InputMaybe<Array<Scalars['String']['input']>>;
  start_hour?: InputMaybe<Scalars['Int']['input']>;
  start_minute?: InputMaybe<Scalars['Int']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateTicketTimelineArgs = {
  atTicketId?: InputMaybe<Scalars['Int']['input']>;
  azureEmailId?: InputMaybe<Scalars['String']['input']>;
  coreTimeclockId?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  formInstanceId?: InputMaybe<Scalars['Int']['input']>;
  phonesCallId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteAiChatArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteAiChatMessagesArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteAutotaskTicketsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteAzureLicenseArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteAzureTenantsArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteAzureUsersArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteContractsAssignmentsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteContractsContractsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteContractsInstancesArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCoreCompaniesArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCoreContactArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCoreGroupArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCoreGroupsMembersArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCoreTagArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCoreTagLinkArgs = {
  name: Scalars['String']['input'];
};


export type MutationDeleteCoreUsersArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteDattormmDevicesArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteDattormmPatchingPoliciesArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteDattormmSitesArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteFeedbackInstanceArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteFeedbackScoreArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeletePhonesCallLegsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeletePhonesCallsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteRecipeNodeArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteRocketcyberAccountsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteRocketcyberAgentsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteRocketcyberIncidentsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteScheduleInstanceArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteSchedulePlanArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteTicketTimelineArgs = {
  id: Scalars['Int']['input'];
};


export type MutationMergeCoreCompaniesArgs = {
  fromCompanyId: Scalars['Float']['input'];
  toCompanyId: Scalars['Float']['input'];
};


export type MutationOptimizeScheduleArgs = {
  date: Scalars['String']['input'];
};


export type MutationRescheduleScheduleDateArgs = {
  date: Scalars['String']['input'];
};


export type MutationSyncDattormmDeviceInfoArgs = {
  deviceId: Scalars['Float']['input'];
};


export type MutationSyncUsersCalendarsToScheduleArgs = {
  date: Scalars['String']['input'];
};


export type MutationUpdateAiChatArgs = {
  id: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  ticketId?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationUpdateAiChatMessagesArgs = {
  coreUserId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Float']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateAutotaskTicketsArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  priority?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateAzureLicenseArgs = {
  azureUserId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  skuId?: InputMaybe<Scalars['String']['input']>;
  skuPartNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateAzureTenantsArgs = {
  coreCompanyId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateAzureUsersArgs = {
  accountEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  coreContactId?: InputMaybe<Scalars['Int']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isMfaRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastMfaCheckDate?: InputMaybe<Scalars['Date']['input']>;
  lastSynced?: InputMaybe<Scalars['Date']['input']>;
  mail?: InputMaybe<Scalars['String']['input']>;
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  officeLocation?: InputMaybe<Scalars['String']['input']>;
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  surname?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  userPrincipalName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateContractsAssignmentsArgs = {
  coreContactId?: InputMaybe<Scalars['Float']['input']>;
  coreLocationId?: InputMaybe<Scalars['Float']['input']>;
  deviceId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Float']['input'];
  instanceId?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationUpdateContractsContractsArgs = {
  additionalPrice?: InputMaybe<Scalars['Float']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  iconColor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  internalDescription?: InputMaybe<Scalars['String']['input']>;
  internalUnitCost?: InputMaybe<Scalars['Float']['input']>;
  laborIncluded?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  publicDescription?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  unitsMeasured?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateContractsInstancesArgs = {
  additionalPrice?: InputMaybe<Scalars['Float']['input']>;
  approvedUnits?: InputMaybe<Scalars['Float']['input']>;
  autoAproveUnits?: InputMaybe<Scalars['Boolean']['input']>;
  contractId?: InputMaybe<Scalars['Float']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Float']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  usedUnits?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationUpdateCoreCompaniesArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  billingConfigured?: InputMaybe<Scalars['Boolean']['input']>;
  defaultHourlyRate?: InputMaybe<Scalars['Float']['input']>;
  defaultTicketLevel?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCoreContactArgs = {
  alternatePhone?: InputMaybe<Scalars['String']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Int']['input']>;
  coreLocationId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneExtension?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCoreGroupArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCoreGroupsMembersArgs = {
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Float']['input'];
};


export type MutationUpdateCoreTagArgs = {
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCoreTagLinkArgs = {
  link?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};


export type MutationUpdateCoreUsersArgs = {
  autotaskId?: InputMaybe<Scalars['Int']['input']>;
  autotaskLocationId?: InputMaybe<Scalars['Int']['input']>;
  bvoipExtention?: InputMaybe<Scalars['Int']['input']>;
  canContactCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isDispatchable?: InputMaybe<Scalars['Boolean']['input']>;
  isRoleTier1?: InputMaybe<Scalars['Boolean']['input']>;
  isRoleTier2?: InputMaybe<Scalars['Boolean']['input']>;
  isRoleTier2FirstUp?: InputMaybe<Scalars['Boolean']['input']>;
  isRoleTriage?: InputMaybe<Scalars['Boolean']['input']>;
  isScheduler?: InputMaybe<Scalars['Boolean']['input']>;
  isSherpaChampion?: InputMaybe<Scalars['Boolean']['input']>;
  isTech?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  techTier?: InputMaybe<Scalars['Int']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  timwoodsId?: InputMaybe<Scalars['String']['input']>;
  useNewRank?: InputMaybe<Scalars['Boolean']['input']>;
  workDayEnd?: InputMaybe<Scalars['String']['input']>;
  workDayStart?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateDattormmDevicesArgs = {
  a64Bit?: InputMaybe<Scalars['Boolean']['input']>;
  antivirusProduct?: InputMaybe<Scalars['String']['input']>;
  antivirusStatus?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  cagVersion?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  creationDate?: InputMaybe<Scalars['Date']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  deviceClass?: InputMaybe<Scalars['String']['input']>;
  displayVersion?: InputMaybe<Scalars['String']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  extIpAddress?: InputMaybe<Scalars['String']['input']>;
  hostname?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  intIpAddress?: InputMaybe<Scalars['String']['input']>;
  lastAuditDate?: InputMaybe<Scalars['Date']['input']>;
  lastLoggedInUser?: InputMaybe<Scalars['String']['input']>;
  lastReboot?: InputMaybe<Scalars['Date']['input']>;
  lastSeen?: InputMaybe<Scalars['Date']['input']>;
  online?: InputMaybe<Scalars['Boolean']['input']>;
  operatingSystem?: InputMaybe<Scalars['String']['input']>;
  patchStatus?: InputMaybe<Scalars['String']['input']>;
  patchesApprovedPending?: InputMaybe<Scalars['Float']['input']>;
  patchesInstalled?: InputMaybe<Scalars['Float']['input']>;
  patchesNotApproved?: InputMaybe<Scalars['Float']['input']>;
  portalUrl?: InputMaybe<Scalars['String']['input']>;
  rebootRequired?: InputMaybe<Scalars['Boolean']['input']>;
  siteId?: InputMaybe<Scalars['Float']['input']>;
  siteName?: InputMaybe<Scalars['String']['input']>;
  siteUid?: InputMaybe<Scalars['String']['input']>;
  snmpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  softwareStatus?: InputMaybe<Scalars['String']['input']>;
  suspended?: InputMaybe<Scalars['Boolean']['input']>;
  syncId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  udf1?: InputMaybe<Scalars['String']['input']>;
  udf2?: InputMaybe<Scalars['String']['input']>;
  udf3?: InputMaybe<Scalars['String']['input']>;
  udf4?: InputMaybe<Scalars['String']['input']>;
  udf5?: InputMaybe<Scalars['String']['input']>;
  udf6?: InputMaybe<Scalars['String']['input']>;
  udf7?: InputMaybe<Scalars['String']['input']>;
  udf8?: InputMaybe<Scalars['String']['input']>;
  udf9?: InputMaybe<Scalars['String']['input']>;
  udf10?: InputMaybe<Scalars['String']['input']>;
  udf11?: InputMaybe<Scalars['String']['input']>;
  udf12?: InputMaybe<Scalars['String']['input']>;
  udf13?: InputMaybe<Scalars['String']['input']>;
  udf14?: InputMaybe<Scalars['String']['input']>;
  udf15?: InputMaybe<Scalars['String']['input']>;
  udf16?: InputMaybe<Scalars['String']['input']>;
  udf17?: InputMaybe<Scalars['String']['input']>;
  udf18?: InputMaybe<Scalars['String']['input']>;
  udf19?: InputMaybe<Scalars['String']['input']>;
  udf20?: InputMaybe<Scalars['String']['input']>;
  udf21?: InputMaybe<Scalars['String']['input']>;
  udf22?: InputMaybe<Scalars['String']['input']>;
  udf23?: InputMaybe<Scalars['String']['input']>;
  udf24?: InputMaybe<Scalars['String']['input']>;
  udf25?: InputMaybe<Scalars['String']['input']>;
  udf26?: InputMaybe<Scalars['String']['input']>;
  udf27?: InputMaybe<Scalars['String']['input']>;
  udf28?: InputMaybe<Scalars['String']['input']>;
  udf29?: InputMaybe<Scalars['String']['input']>;
  udf30?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  warrantyDate?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateDattormmPatchingPoliciesArgs = {
  deviceId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Float']['input'];
  lastRun?: InputMaybe<Scalars['Date']['input']>;
  lastSync?: InputMaybe<Scalars['Date']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  schedule?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateDattormmSitesArgs = {
  autotaskCompanyId?: InputMaybe<Scalars['String']['input']>;
  autotaskCompanyName?: InputMaybe<Scalars['String']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  numberOfDevices?: InputMaybe<Scalars['Float']['input']>;
  numberOfOfflineDevices?: InputMaybe<Scalars['Float']['input']>;
  numberOfOnlineDevices?: InputMaybe<Scalars['Float']['input']>;
  onDemand?: InputMaybe<Scalars['Boolean']['input']>;
  portalUrl?: InputMaybe<Scalars['String']['input']>;
  proxySettings?: InputMaybe<Scalars['Float']['input']>;
  splashtopAutoInstall?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateFeedbackInstanceArgs = {
  id: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateFeedbackScoreArgs = {
  customerResp?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdatePhonesCallLegsArgs = {
  callProvider?: InputMaybe<Scalars['String']['input']>;
  callProviderId?: InputMaybe<Scalars['Int']['input']>;
  coreContactId?: InputMaybe<Scalars['Int']['input']>;
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['Date']['input']>;
  extention?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  phonesCallId?: InputMaybe<Scalars['Int']['input']>;
  recordingLocalPath?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['Date']['input']>;
  transcription?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdatePhonesCallsArgs = {
  atTicketId?: InputMaybe<Scalars['Int']['input']>;
  callProvider?: InputMaybe<Scalars['String']['input']>;
  callProviderId?: InputMaybe<Scalars['Int']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Int']['input']>;
  coreContactId?: InputMaybe<Scalars['Int']['input']>;
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['Date']['input']>;
  extention?: InputMaybe<Scalars['String']['input']>;
  fromNumber?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  startTime?: InputMaybe<Scalars['Date']['input']>;
  toNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateRecipeNodeArgs = {
  data?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  positionX?: InputMaybe<Scalars['Float']['input']>;
  positionY?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateRocketcyberAccountsArgs = {
  coreCompanyId?: InputMaybe<Scalars['Float']['input']>;
  emails?: InputMaybe<Scalars['String']['input']>;
  hierarchy?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateRocketcyberAgentsArgs = {
  accountPath?: InputMaybe<Scalars['String']['input']>;
  agentVersion?: InputMaybe<Scalars['String']['input']>;
  architecture?: InputMaybe<Scalars['String']['input']>;
  build?: InputMaybe<Scalars['String']['input']>;
  connectivity?: InputMaybe<Scalars['String']['input']>;
  edition?: InputMaybe<Scalars['String']['input']>;
  family?: InputMaybe<Scalars['String']['input']>;
  hostname?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  ipv4Address?: InputMaybe<Scalars['String']['input']>;
  macAddress?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  release?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateRocketcyberIncidentsArgs = {
  autotaskTicketId?: InputMaybe<Scalars['Float']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  eventCount?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Float']['input'];
  remediation?: InputMaybe<Scalars['String']['input']>;
  resolvedAt?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateScheduleInstanceArgs = {
  atTicketId?: InputMaybe<Scalars['Int']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  azureCalendarId?: InputMaybe<Scalars['String']['input']>;
  conflictDetected?: InputMaybe<Scalars['Boolean']['input']>;
  coreGroupId?: InputMaybe<Scalars['Int']['input']>;
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  endDateTime?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['Int']['input'];
  overidePlan?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleInstanceDay?: InputMaybe<Scalars['String']['input']>;
  schedulePlanId?: InputMaybe<Scalars['Int']['input']>;
  schedulerLog?: InputMaybe<Scalars['String']['input']>;
  startDateTime?: InputMaybe<Scalars['Date']['input']>;
};


export type MutationUpdateSchedulePlanArgs = {
  atTicketId?: InputMaybe<Scalars['Int']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  blockSizeInMinutes?: InputMaybe<Scalars['Int']['input']>;
  coreGroupId?: InputMaybe<Scalars['Int']['input']>;
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredUserCount?: InputMaybe<Scalars['Int']['input']>;
  end_hour?: InputMaybe<Scalars['Int']['input']>;
  end_minute?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  optimalBlockSizeInMinutes?: InputMaybe<Scalars['Int']['input']>;
  splitableIntoBlocks?: InputMaybe<Scalars['Boolean']['input']>;
  startTimeDay_daysOfMonth?: InputMaybe<Array<Scalars['Int']['input']>>;
  startTimeDay_daysOfWeek?: InputMaybe<Array<Scalars['String']['input']>>;
  startTimeDay_monthsOfTheYear?: InputMaybe<Array<Scalars['String']['input']>>;
  start_hour?: InputMaybe<Scalars['Int']['input']>;
  start_minute?: InputMaybe<Scalars['Int']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateTicketTimelineArgs = {
  atTicketId?: InputMaybe<Scalars['Int']['input']>;
  azureEmailId?: InputMaybe<Scalars['String']['input']>;
  coreTimeclockId?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  formInstanceId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  phonesCallId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateTimeclockArgs = {
  end?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['Float']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['Date']['input']>;
};


export type MutationVerifyAzureTenantArgs = {
  id: Scalars['String']['input'];
};

export type NumberProp = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<Scalars['Float']['input']>;
};

export type OpsComplianceUserStatus = {
  __typename?: 'OpsComplianceUserStatus';
  coreUserId: Scalars['String']['output'];
  end?: Maybe<Scalars['DateTime']['output']>;
  hasDispatch?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  isDispatchable?: Maybe<Scalars['Boolean']['output']>;
  isOnCall?: Maybe<Scalars['Boolean']['output']>;
  isTimwoodsClockedIn?: Maybe<Scalars['Boolean']['output']>;
  isWorking?: Maybe<Scalars['Boolean']['output']>;
  isWorkingClose?: Maybe<Scalars['Boolean']['output']>;
  isWorkingDispatched?: Maybe<Scalars['Boolean']['output']>;
  lastChecked: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
  user?: Maybe<CoreUsers>;
};

export type PaginatedAiChat = {
  __typename?: 'PaginatedAIChat';
  data?: Maybe<Array<AiChat>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedAiChatMessages = {
  __typename?: 'PaginatedAIChatMessages';
  data?: Maybe<Array<AiChatMessages>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedAutotaskTickets = {
  __typename?: 'PaginatedAutotaskTickets';
  data?: Maybe<Array<AutotaskTickets>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedAzureLicense = {
  __typename?: 'PaginatedAzureLicense';
  data?: Maybe<Array<AzureLicense>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedAzureTenants = {
  __typename?: 'PaginatedAzureTenants';
  data?: Maybe<Array<AzureTenants>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedAzureUsers = {
  __typename?: 'PaginatedAzureUsers';
  data?: Maybe<Array<AzureUsers>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedContractsAssignments = {
  __typename?: 'PaginatedContractsAssignments';
  data?: Maybe<Array<ContractsAssignments>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedContractsContracts = {
  __typename?: 'PaginatedContractsContracts';
  data?: Maybe<Array<ContractsContracts>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedContractsInstances = {
  __typename?: 'PaginatedContractsInstances';
  data?: Maybe<Array<ContractsInstances>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedCoreCompanies = {
  __typename?: 'PaginatedCoreCompanies';
  data?: Maybe<Array<CoreCompanies>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedCoreContact = {
  __typename?: 'PaginatedCoreContact';
  data?: Maybe<Array<CoreContact>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedCoreGroup = {
  __typename?: 'PaginatedCoreGroup';
  data?: Maybe<Array<CoreGroup>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedCoreGroupsMembers = {
  __typename?: 'PaginatedCoreGroupsMembers';
  data?: Maybe<Array<CoreGroupsMembers>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedCoreTag = {
  __typename?: 'PaginatedCoreTag';
  data?: Maybe<Array<CoreTag>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedCoreTagLink = {
  __typename?: 'PaginatedCoreTagLink';
  data?: Maybe<Array<CoreTagLink>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedCoreUsers = {
  __typename?: 'PaginatedCoreUsers';
  data?: Maybe<Array<CoreUsers>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedDattormmDevices = {
  __typename?: 'PaginatedDattormmDevices';
  data?: Maybe<Array<DattormmDevices>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedDattormmPatchingPolicies = {
  __typename?: 'PaginatedDattormmPatchingPolicies';
  data?: Maybe<Array<DattormmPatchingPolicies>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedDattormmSites = {
  __typename?: 'PaginatedDattormmSites';
  data?: Maybe<Array<DattormmSites>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedFeedbackInstance = {
  __typename?: 'PaginatedFeedbackInstance';
  data?: Maybe<Array<FeedbackInstance>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedFeedbackScore = {
  __typename?: 'PaginatedFeedbackScore';
  data?: Maybe<Array<FeedbackScore>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedPhonesCallLegs = {
  __typename?: 'PaginatedPhonesCallLegs';
  data?: Maybe<Array<PhonesCallLegs>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedPhonesCalls = {
  __typename?: 'PaginatedPhonesCalls';
  data?: Maybe<Array<PhonesCalls>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedRecipeNode = {
  __typename?: 'PaginatedRecipeNode';
  data?: Maybe<Array<RecipeNode>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedRocketcyberAccounts = {
  __typename?: 'PaginatedRocketcyberAccounts';
  data?: Maybe<Array<RocketcyberAccounts>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedRocketcyberAgents = {
  __typename?: 'PaginatedRocketcyberAgents';
  data?: Maybe<Array<RocketcyberAgents>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedRocketcyberIncidents = {
  __typename?: 'PaginatedRocketcyberIncidents';
  data?: Maybe<Array<RocketcyberIncidents>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedScheduleInstance = {
  __typename?: 'PaginatedScheduleInstance';
  data?: Maybe<Array<ScheduleInstance>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSchedulePlan = {
  __typename?: 'PaginatedSchedulePlan';
  data?: Maybe<Array<SchedulePlan>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedTicketTimeline = {
  __typename?: 'PaginatedTicketTimeline';
  data?: Maybe<Array<TicketTimeline>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedTimeclocks = {
  __typename?: 'PaginatedTimeclocks';
  data?: Maybe<Array<CoreTimeclock>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedUserStatus = {
  __typename?: 'PaginatedUserStatus';
  data?: Maybe<Array<OpsComplianceUserStatus>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PhonesCallLegs = {
  __typename?: 'PhonesCallLegs';
  Contact?: Maybe<CoreContact>;
  CoreUser?: Maybe<CoreUsers>;
  PhoneCall?: Maybe<PhonesCalls>;
  callProvider?: Maybe<Scalars['String']['output']>;
  callProviderId?: Maybe<Scalars['Float']['output']>;
  coreContactId?: Maybe<Scalars['Float']['output']>;
  coreUserId?: Maybe<Scalars['String']['output']>;
  createdByCoreUserId?: Maybe<Scalars['String']['output']>;
  createdDateTime: Scalars['DateTime']['output'];
  downloadUrl?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['DateTimeIso']['output']>;
  extention?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  phonesCallId?: Maybe<Scalars['Float']['output']>;
  recordingLocalPath?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['DateTimeIso']['output']>;
  transcription?: Maybe<Scalars['String']['output']>;
  updatedByCoreUserId?: Maybe<Scalars['String']['output']>;
  updatedDateTime: Scalars['DateTime']['output'];
};

export type PhonesCalls = {
  __typename?: 'PhonesCalls';
  AutotaskTickets?: Maybe<AutotaskTickets>;
  BvoipCalls?: Maybe<Array<BvoipCalls>>;
  Contact?: Maybe<CoreContact>;
  CoreCompany?: Maybe<CoreCompanies>;
  CoreUser?: Maybe<CoreUsers>;
  PhoneCallLegs?: Maybe<Array<PhonesCallLegs>>;
  TicketTimelines?: Maybe<Array<TicketTimeline>>;
  atTicketId?: Maybe<Scalars['Float']['output']>;
  callProvider?: Maybe<Scalars['String']['output']>;
  callProviderId?: Maybe<Scalars['Float']['output']>;
  coreCompanyId?: Maybe<Scalars['Float']['output']>;
  coreContactId?: Maybe<Scalars['Float']['output']>;
  coreUserId?: Maybe<Scalars['String']['output']>;
  createdByCoreUserId?: Maybe<Scalars['String']['output']>;
  createdDateTime: Scalars['DateTime']['output'];
  direction?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['DateTimeIso']['output']>;
  extention?: Maybe<Scalars['String']['output']>;
  fromNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  startTime?: Maybe<Scalars['DateTimeIso']['output']>;
  toNumber?: Maybe<Scalars['String']['output']>;
  updatedByCoreUserId?: Maybe<Scalars['String']['output']>;
  updatedDateTime: Scalars['DateTime']['output'];
};

export type Query = {
  __typename?: 'Query';
  AITranscribeAudio: Scalars['String']['output'];
  findAIChatMessagesPaginated: PaginatedAiChatMessages;
  findAIChatPaginated: PaginatedAiChat;
  findAutotaskTicketsPaginated: PaginatedAutotaskTickets;
  findAzureLicensePaginated: PaginatedAzureLicense;
  findAzureTenantsPaginated: PaginatedAzureTenants;
  findAzureUsersPaginated: PaginatedAzureUsers;
  findContractsAssignmentsPaginated: PaginatedContractsAssignments;
  findContractsContractsPaginated: PaginatedContractsContracts;
  findContractsInstancesPaginated: PaginatedContractsInstances;
  findCoreCompaniesPaginated: PaginatedCoreCompanies;
  findCoreContactPaginated: PaginatedCoreContact;
  findCoreGroupPaginated: PaginatedCoreGroup;
  findCoreGroupsMembersPaginated: PaginatedCoreGroupsMembers;
  findCoreTagLinkPaginated: PaginatedCoreTagLink;
  findCoreTagPaginated: PaginatedCoreTag;
  findCoreUsersPaginated: PaginatedCoreUsers;
  findDattormmDevicesPaginated: PaginatedDattormmDevices;
  findDattormmPatchingPoliciesPaginated: PaginatedDattormmPatchingPolicies;
  findDattormmSitesPaginated: PaginatedDattormmSites;
  findFeedbackInstancePaginated: PaginatedFeedbackInstance;
  findFeedbackScorePaginated: PaginatedFeedbackScore;
  findPhonesCallLegsPaginated: PaginatedPhonesCallLegs;
  findPhonesCallsPaginated: PaginatedPhonesCalls;
  findRecipeNodePaginated: PaginatedRecipeNode;
  findRocketcyberAccountsPaginated: PaginatedRocketcyberAccounts;
  findRocketcyberAgentsPaginated: PaginatedRocketcyberAgents;
  findRocketcyberIncidentsPaginated: PaginatedRocketcyberIncidents;
  findScheduleInstancePaginated: PaginatedScheduleInstance;
  findSchedulePlanPaginated: PaginatedSchedulePlan;
  findTicketTimelinePaginated: PaginatedTicketTimeline;
  getAIChatById: AiChat;
  getAIChatMessagesById: AiChatMessages;
  getAutotaskTicketsById: AutotaskTickets;
  getAzureLicenseById: AzureLicense;
  getAzureTenantsById: AzureTenants;
  getAzureUsersById: AzureUsers;
  getContractsAssignmentsById: ContractsAssignments;
  getContractsContractsById: ContractsContracts;
  getContractsInstancesById: ContractsInstances;
  getCoreCompaniesById: CoreCompanies;
  getCoreContactById: CoreContact;
  getCoreGroupById: CoreGroup;
  getCoreGroupsMembersById: CoreGroupsMembers;
  getCoreTagById: CoreTag;
  getCoreTagLinkById: CoreTagLink;
  getCoreUsersById: CoreUsers;
  getDattormmDevicesById: DattormmDevices;
  getDattormmPatchingPoliciesById: DattormmPatchingPolicies;
  getDattormmSitesById: DattormmSites;
  getFeedbackInstanceById: FeedbackInstance;
  getFeedbackScoreById: FeedbackScore;
  getPhonesCallLegsById: PhonesCallLegs;
  getPhonesCallsById: PhonesCalls;
  getRecipeNodeById: RecipeNode;
  getRocketcyberAccountsById: RocketcyberAccounts;
  getRocketcyberAgentsById: RocketcyberAgents;
  getRocketcyberIncidentsById: RocketcyberIncidents;
  getScheduleInstanceById: ScheduleInstance;
  getSchedulePlanById: SchedulePlan;
  getTicketTimelineById: TicketTimeline;
  signSecureGetUrl: Scalars['String']['output'];
  signSecurePutUrl: Scalars['String']['output'];
  timeclock: CoreTimeclock;
  timeclocks: PaginatedTimeclocks;
  userstatuses: PaginatedUserStatus;
};


export type QueryAiTranscribeAudioArgs = {
  input: TranscribeArgs;
  provider?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFindAiChatMessagesPaginatedArgs = {
  chatId?: InputMaybe<NumberProp>;
  coreUserId?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
  rawMessage?: InputMaybe<StringProp>;
  role?: InputMaybe<StringProp>;
  runWithContexts?: InputMaybe<StringProp>;
};


export type QueryFindAiChatPaginatedArgs = {
  coreUserId?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
  ticket?: InputMaybe<GetTicketsArgs>;
  ticketId?: InputMaybe<NumberProp>;
  user?: InputMaybe<GetUsersArgs>;
};


export type QueryFindAutotaskTicketsPaginatedArgs = {
  assignedResourceID?: InputMaybe<NumberProp>;
  autotaskCompany?: InputMaybe<GetAutotaskCompaniesArgs>;
  company?: InputMaybe<GetAutotaskCompaniesArgs>;
  companyID?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextActionDate?: InputMaybe<TimeQueryProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<NumberProp>;
  queueID?: InputMaybe<NumberProp>;
  status?: InputMaybe<NumberProp>;
  ticketNumber?: InputMaybe<StringProp>;
  title?: InputMaybe<StringProp>;
};


export type QueryFindAzureLicensePaginatedArgs = {
  azureUserId?: InputMaybe<StringProp>;
  id?: InputMaybe<StringProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortAzureLicensesArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  skuId?: InputMaybe<StringProp>;
  skuPartNumber?: InputMaybe<StringProp>;
};


export type QueryFindAzureTenantsPaginatedArgs = {
  coreCompanyId?: InputMaybe<NumberProp>;
  id?: InputMaybe<StringProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindAzureUsersPaginatedArgs = {
  coreContactId?: InputMaybe<NumberProp>;
  displayName?: InputMaybe<StringProp>;
  givenName?: InputMaybe<StringProp>;
  id?: InputMaybe<StringProp>;
  jobTitle?: InputMaybe<StringProp>;
  lastMfaCheckDate?: InputMaybe<TimeQueryProp>;
  lastSynced?: InputMaybe<TimeQueryProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mail?: InputMaybe<StringProp>;
  mobilePhone?: InputMaybe<StringProp>;
  officeLocation?: InputMaybe<StringProp>;
  order?: InputMaybe<SortAzureUsersArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  preferredLanguage?: InputMaybe<StringProp>;
  surname?: InputMaybe<StringProp>;
  tenantId?: InputMaybe<StringProp>;
  userPrincipalName?: InputMaybe<StringProp>;
};


export type QueryFindContractsAssignmentsPaginatedArgs = {
  coreContactId?: InputMaybe<NumberProp>;
  coreLocationId?: InputMaybe<NumberProp>;
  deviceId?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  instanceId?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindContractsContractsPaginatedArgs = {
  id?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindContractsInstancesPaginatedArgs = {
  contractId?: InputMaybe<NumberProp>;
  coreCompanyId?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindCoreCompaniesPaginatedArgs = {
  active?: InputMaybe<BooleanProp>;
  billingConfigured?: InputMaybe<BooleanProp>;
  defaultHourlyRate?: InputMaybe<NumberProp>;
  defaultTicketLevel?: InputMaybe<StringProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindCoreContactPaginatedArgs = {
  alternatePhone?: InputMaybe<StringProp>;
  coreCompanyId?: InputMaybe<NumberProp>;
  coreLocationId?: InputMaybe<NumberProp>;
  email?: InputMaybe<StringProp>;
  firstName?: InputMaybe<StringProp>;
  id?: InputMaybe<NumberProp>;
  lastName?: InputMaybe<StringProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mobilePhone?: InputMaybe<StringProp>;
  order?: InputMaybe<SortContactsArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<StringProp>;
  phoneExtension?: InputMaybe<StringProp>;
};


export type QueryFindCoreGroupPaginatedArgs = {
  description?: InputMaybe<StringProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindCoreGroupsMembersPaginatedArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindCoreTagLinkPaginatedArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  link?: InputMaybe<StringProp>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindCoreTagPaginatedArgs = {
  autotaskTicketId?: InputMaybe<NumberProp>;
  createdByService?: InputMaybe<StringProp>;
  id?: InputMaybe<StringProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindCoreUsersPaginatedArgs = {
  autotaskId?: InputMaybe<NumberProp>;
  autotaskLocationId?: InputMaybe<NumberProp>;
  bvoipExtention?: InputMaybe<NumberProp>;
  email?: InputMaybe<StringProp>;
  firstName?: InputMaybe<StringProp>;
  id?: InputMaybe<StringProp>;
  lastName?: InputMaybe<StringProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortCoreUsersArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  techTier?: InputMaybe<NumberProp>;
  theme?: InputMaybe<StringProp>;
  timwoodsId?: InputMaybe<StringProp>;
  workDayEnd?: InputMaybe<StringProp>;
  workDayStart?: InputMaybe<StringProp>;
};


export type QueryFindDattormmDevicesPaginatedArgs = {
  a64Bit?: InputMaybe<BooleanProp>;
  antivirusProduct?: InputMaybe<StringProp>;
  antivirusStatus?: InputMaybe<StringProp>;
  archived?: InputMaybe<BooleanProp>;
  cagVersion?: InputMaybe<StringProp>;
  category?: InputMaybe<StringProp>;
  creationDate?: InputMaybe<TimeQueryProp>;
  deleted?: InputMaybe<BooleanProp>;
  description?: InputMaybe<StringProp>;
  deviceClass?: InputMaybe<StringProp>;
  displayVersion?: InputMaybe<StringProp>;
  domain?: InputMaybe<StringProp>;
  extIpAddress?: InputMaybe<StringProp>;
  hostname?: InputMaybe<StringProp>;
  id?: InputMaybe<NumberProp>;
  intIpAddress?: InputMaybe<StringProp>;
  lastAuditDate?: InputMaybe<TimeQueryProp>;
  lastLoggedInUser?: InputMaybe<StringProp>;
  lastReboot?: InputMaybe<TimeQueryProp>;
  lastSeen?: InputMaybe<TimeQueryProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  online?: InputMaybe<BooleanProp>;
  operatingSystem?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
  patchStatus?: InputMaybe<StringProp>;
  patchesApprovedPending?: InputMaybe<NumberProp>;
  patchesInstalled?: InputMaybe<NumberProp>;
  patchesNotApproved?: InputMaybe<NumberProp>;
  portalUrl?: InputMaybe<StringProp>;
  rebootRequired?: InputMaybe<BooleanProp>;
  siteId?: InputMaybe<NumberProp>;
  siteName?: InputMaybe<StringProp>;
  siteUid?: InputMaybe<StringProp>;
  snmpEnabled?: InputMaybe<BooleanProp>;
  softwareStatus?: InputMaybe<StringProp>;
  suspended?: InputMaybe<BooleanProp>;
  syncId?: InputMaybe<StringProp>;
  type?: InputMaybe<StringProp>;
  udf1?: InputMaybe<StringProp>;
  udf2?: InputMaybe<StringProp>;
  udf3?: InputMaybe<StringProp>;
  udf4?: InputMaybe<StringProp>;
  udf5?: InputMaybe<StringProp>;
  udf6?: InputMaybe<StringProp>;
  udf7?: InputMaybe<StringProp>;
  udf8?: InputMaybe<StringProp>;
  udf9?: InputMaybe<StringProp>;
  udf10?: InputMaybe<StringProp>;
  udf11?: InputMaybe<StringProp>;
  udf12?: InputMaybe<StringProp>;
  udf13?: InputMaybe<StringProp>;
  udf14?: InputMaybe<StringProp>;
  udf15?: InputMaybe<StringProp>;
  udf16?: InputMaybe<StringProp>;
  udf17?: InputMaybe<StringProp>;
  udf18?: InputMaybe<StringProp>;
  udf19?: InputMaybe<StringProp>;
  udf20?: InputMaybe<StringProp>;
  udf21?: InputMaybe<StringProp>;
  udf22?: InputMaybe<StringProp>;
  udf23?: InputMaybe<StringProp>;
  udf24?: InputMaybe<StringProp>;
  udf25?: InputMaybe<StringProp>;
  udf26?: InputMaybe<StringProp>;
  udf27?: InputMaybe<StringProp>;
  udf28?: InputMaybe<StringProp>;
  udf29?: InputMaybe<StringProp>;
  udf30?: InputMaybe<StringProp>;
  uid?: InputMaybe<StringProp>;
  warrantyDate?: InputMaybe<StringProp>;
};


export type QueryFindDattormmPatchingPoliciesPaginatedArgs = {
  deviceId?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  lastRun?: InputMaybe<Scalars['Date']['input']>;
  lastSync?: InputMaybe<Scalars['Date']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
  schedule?: InputMaybe<StringProp>;
};


export type QueryFindDattormmSitesPaginatedArgs = {
  coreCompanyId?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindFeedbackInstancePaginatedArgs = {
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindFeedbackScorePaginatedArgs = {
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindPhonesCallLegsPaginatedArgs = {
  callProvider?: InputMaybe<StringProp>;
  callProviderId?: InputMaybe<NumberProp>;
  coreContactId?: InputMaybe<NumberProp>;
  coreUserId?: InputMaybe<StringProp>;
  endTime?: InputMaybe<TimeQueryProp>;
  extention?: InputMaybe<StringProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortPhoneCallLegsArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  phonesCallId?: InputMaybe<NumberProp>;
  recordingLocalPath?: InputMaybe<StringProp>;
  startTime?: InputMaybe<TimeQueryProp>;
  transcription?: InputMaybe<StringProp>;
};


export type QueryFindPhonesCallsPaginatedArgs = {
  CoreCompany?: InputMaybe<GetCoreCompaniesSubfieldArgs>;
  atTicketId?: InputMaybe<NumberProp>;
  callProvider?: InputMaybe<StringProp>;
  callProviderId?: InputMaybe<NumberProp>;
  coreCompanyId?: InputMaybe<NumberProp>;
  coreContactId?: InputMaybe<NumberProp>;
  coreUserId?: InputMaybe<StringProp>;
  direction?: InputMaybe<StringProp>;
  endTime?: InputMaybe<TimeQueryProp>;
  extention?: InputMaybe<StringProp>;
  fromNumber?: InputMaybe<StringProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortPhoneCallsArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<TimeQueryProp>;
  toNumber?: InputMaybe<StringProp>;
};


export type QueryFindRecipeNodePaginatedArgs = {
  id?: InputMaybe<StringProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  recipeId?: InputMaybe<NumberProp>;
  title?: InputMaybe<StringProp>;
  type?: InputMaybe<StringProp>;
};


export type QueryFindRocketcyberAccountsPaginatedArgs = {
  hierarchy?: InputMaybe<StringProp>;
  id?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<StringProp>;
  type?: InputMaybe<StringProp>;
};


export type QueryFindRocketcyberAgentsPaginatedArgs = {
  customerId?: InputMaybe<NumberProp>;
  family?: InputMaybe<StringProp>;
  hostname?: InputMaybe<StringProp>;
  id?: InputMaybe<StringProp>;
  ipv4Address?: InputMaybe<StringProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  macAddress?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
  platform?: InputMaybe<StringProp>;
};


export type QueryFindRocketcyberIncidentsPaginatedArgs = {
  accountId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<StringProp>;
  title?: InputMaybe<StringProp>;
};


export type QueryFindScheduleInstancePaginatedArgs = {
  atTicketId?: InputMaybe<NumberProp>;
  azureCalendarId?: InputMaybe<StringProp>;
  coreGroupId?: InputMaybe<NumberProp>;
  coreUserId?: InputMaybe<StringProp>;
  endDateTime?: InputMaybe<TimeQueryProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortScheduleInstancesArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  scheduleInstanceDay?: InputMaybe<StringProp>;
  schedulePlanId?: InputMaybe<NumberProp>;
  schedulerLog?: InputMaybe<StringProp>;
  startDateTime?: InputMaybe<TimeQueryProp>;
};


export type QueryFindSchedulePlanPaginatedArgs = {
  atTicketId?: InputMaybe<NumberProp>;
  blockSizeInMinutes?: InputMaybe<NumberProp>;
  coreGroupId?: InputMaybe<NumberProp>;
  coreUserId?: InputMaybe<StringProp>;
  description?: InputMaybe<StringProp>;
  desiredUserCount?: InputMaybe<NumberProp>;
  end_hour?: InputMaybe<NumberProp>;
  end_minute?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  optimalBlockSizeInMinutes?: InputMaybe<NumberProp>;
  order?: InputMaybe<SortSchedulePlansArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  startTimeDay_daysOfMonth?: InputMaybe<Array<Scalars['Int']['input']>>;
  startTimeDay_daysOfWeek?: InputMaybe<Array<Scalars['String']['input']>>;
  startTimeDay_monthsOfTheYear?: InputMaybe<Array<Scalars['String']['input']>>;
  start_hour?: InputMaybe<NumberProp>;
  start_minute?: InputMaybe<NumberProp>;
  timezone?: InputMaybe<StringProp>;
};


export type QueryFindTicketTimelinePaginatedArgs = {
  atTicketId?: InputMaybe<NumberProp>;
  azureEmailId?: InputMaybe<StringProp>;
  coreTimeclockId?: InputMaybe<NumberProp>;
  date?: InputMaybe<TimeQueryProp>;
  formInstanceId?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortTicketTimelinesArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  phonesCallId?: InputMaybe<NumberProp>;
  search?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<StringProp>;
};


export type QueryGetAiChatByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetAiChatMessagesByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetAutotaskTicketsByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetAzureLicenseByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetAzureTenantsByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAzureUsersByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetContractsAssignmentsByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetContractsContractsByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetContractsInstancesByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCoreCompaniesByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCoreContactByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCoreGroupByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCoreGroupsMembersByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCoreTagByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCoreTagLinkByIdArgs = {
  name: Scalars['String']['input'];
};


export type QueryGetCoreUsersByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetDattormmDevicesByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetDattormmPatchingPoliciesByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetDattormmSitesByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetFeedbackInstanceByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetFeedbackScoreByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetPhonesCallLegsByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetPhonesCallsByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetRecipeNodeByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetRocketcyberAccountsByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetRocketcyberAgentsByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetRocketcyberIncidentsByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetScheduleInstanceByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetSchedulePlanByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetTicketTimelineByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QuerySignSecureGetUrlArgs = {
  key: Scalars['String']['input'];
};


export type QuerySignSecurePutUrlArgs = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
};


export type QueryTimeclockArgs = {
  id: Scalars['Float']['input'];
};


export type QueryTimeclocksArgs = {
  fromRole?: InputMaybe<StringProp>;
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<TimeQueryProp>;
  ticket?: InputMaybe<GetTicketsArgs>;
  ticketId?: InputMaybe<Scalars['Int']['input']>;
  todoId?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<GetUsersArgs>;
};


export type QueryUserstatusesArgs = {
  hasDispatch?: InputMaybe<BooleanProp>;
  isDispatchable?: InputMaybe<BooleanProp>;
  isOnCall?: InputMaybe<BooleanProp>;
  isTimwoodsClockedIn?: InputMaybe<BooleanProp>;
  isWorking?: InputMaybe<BooleanProp>;
  isWorkingClose?: InputMaybe<BooleanProp>;
  isWorkingDispatched?: InputMaybe<BooleanProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<TimeQueryProp>;
  user?: InputMaybe<GetUsersArgs>;
};

export type RecipeNode = {
  __typename?: 'RecipeNode';
  data: Scalars['JSON']['output'];
  id: Scalars['String']['output'];
  lastEditedUser?: Maybe<Scalars['String']['output']>;
  positionX: Scalars['Float']['output'];
  positionY: Scalars['Float']['output'];
  recipeId: Scalars['Float']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type RocketcyberAccounts = {
  __typename?: 'RocketcyberAccounts';
  coreCompanyId?: Maybe<Scalars['Float']['output']>;
  emails?: Maybe<Scalars['String']['output']>;
  hierarchy: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type RocketcyberAgents = {
  __typename?: 'RocketcyberAgents';
  accountPath: Scalars['String']['output'];
  agentVersion: Scalars['String']['output'];
  architecture: Scalars['String']['output'];
  build: Scalars['String']['output'];
  connectivity: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  customerId: Scalars['Float']['output'];
  dattormmDeviceId?: Maybe<Scalars['Float']['output']>;
  edition: Scalars['String']['output'];
  family: Scalars['String']['output'];
  hostname: Scalars['String']['output'];
  id: Scalars['String']['output'];
  ipv4Address: Scalars['String']['output'];
  macAddress: Scalars['String']['output'];
  platform: Scalars['String']['output'];
  release: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type RocketcyberIncidents = {
  __typename?: 'RocketcyberIncidents';
  accountId: Scalars['Float']['output'];
  autotaskTicketId?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['Date']['output'];
  description: Scalars['String']['output'];
  eventCount: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  publishedAt?: Maybe<Scalars['Date']['output']>;
  remediation: Scalars['String']['output'];
  resolvedAt?: Maybe<Scalars['Date']['output']>;
  status: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ScheduleInstance = {
  __typename?: 'ScheduleInstance';
  AutotaskTickets?: Maybe<AutotaskTickets>;
  AzureCalendar?: Maybe<AzureCalendar>;
  CoreGroup?: Maybe<CoreGroup>;
  CoreUser?: Maybe<CoreUsers>;
  SchedulePlan?: Maybe<SchedulePlan>;
  atTicketId?: Maybe<Scalars['Float']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
  azureCalendarId?: Maybe<Scalars['String']['output']>;
  conflictDetected?: Maybe<Scalars['Boolean']['output']>;
  coreGroupId?: Maybe<Scalars['Float']['output']>;
  coreUserId?: Maybe<Scalars['String']['output']>;
  endDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  overidePlan?: Maybe<Scalars['Boolean']['output']>;
  scheduleInstanceDay?: Maybe<Scalars['String']['output']>;
  schedulePlanId?: Maybe<Scalars['Float']['output']>;
  schedulerLog?: Maybe<Scalars['String']['output']>;
  startDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
};

export type SchedulePlan = {
  __typename?: 'SchedulePlan';
  AutotaskTickets?: Maybe<AutotaskTickets>;
  CoreGroup?: Maybe<CoreGroup>;
  CoreUser?: Maybe<CoreUsers>;
  ScheduleInstances?: Maybe<Array<ScheduleInstance>>;
  atTicketId?: Maybe<Scalars['Float']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
  blockSizeInMinutes?: Maybe<Scalars['Float']['output']>;
  coreGroupId?: Maybe<Scalars['Float']['output']>;
  coreUserId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  desiredUserCount?: Maybe<Scalars['Float']['output']>;
  end_hour?: Maybe<Scalars['Float']['output']>;
  end_minute?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  optimalBlockSizeInMinutes?: Maybe<Scalars['Float']['output']>;
  splitableIntoBlocks?: Maybe<Scalars['Boolean']['output']>;
  startTimeDay_daysOfMonth?: Maybe<Array<Scalars['Int']['output']>>;
  startTimeDay_daysOfWeek?: Maybe<Array<Scalars['String']['output']>>;
  startTimeDay_monthsOfTheYear?: Maybe<Array<Scalars['String']['output']>>;
  start_hour?: Maybe<Scalars['Float']['output']>;
  start_minute?: Maybe<Scalars['Float']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type SortAzureLicensesArgs = {
  azureUserId?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  skuId?: InputMaybe<SortOrders>;
  skuPartNumber?: InputMaybe<SortOrders>;
};

export type SortAzureUsersArgs = {
  accountEnabled?: InputMaybe<SortOrders>;
  authenticationMethods?: InputMaybe<SortOrders>;
  businessPhones?: InputMaybe<SortOrders>;
  coreContactId?: InputMaybe<SortOrders>;
  displayName?: InputMaybe<SortOrders>;
  givenName?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  isMfaRegistered?: InputMaybe<SortOrders>;
  jobTitle?: InputMaybe<SortOrders>;
  lastMfaCheckDate?: InputMaybe<SortOrders>;
  lastSynced?: InputMaybe<SortOrders>;
  mail?: InputMaybe<SortOrders>;
  mobilePhone?: InputMaybe<SortOrders>;
  officeLocation?: InputMaybe<SortOrders>;
  preferredLanguage?: InputMaybe<SortOrders>;
  surname?: InputMaybe<SortOrders>;
  tenantId?: InputMaybe<SortOrders>;
  userPrincipalName?: InputMaybe<SortOrders>;
};

export type SortContactsArgs = {
  alternatePhone?: InputMaybe<SortOrders>;
  coreCompanyId?: InputMaybe<SortOrders>;
  coreLocationId?: InputMaybe<SortOrders>;
  email?: InputMaybe<SortOrders>;
  firstName?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  lastName?: InputMaybe<SortOrders>;
  mobilePhone?: InputMaybe<SortOrders>;
  phone?: InputMaybe<SortOrders>;
  phoneExtension?: InputMaybe<SortOrders>;
};

export type SortCoreUsersArgs = {
  autotaskId?: InputMaybe<SortOrders>;
  autotaskLocationId?: InputMaybe<SortOrders>;
  bvoipExtention?: InputMaybe<SortOrders>;
  canContactCustomers?: InputMaybe<SortOrders>;
  email?: InputMaybe<SortOrders>;
  firstName?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  isDispatchable?: InputMaybe<SortOrders>;
  isRoleTier1?: InputMaybe<SortOrders>;
  isRoleTier2?: InputMaybe<SortOrders>;
  isRoleTier2FirstUp?: InputMaybe<SortOrders>;
  isRoleTriage?: InputMaybe<SortOrders>;
  isScheduler?: InputMaybe<SortOrders>;
  isSherpaChampion?: InputMaybe<SortOrders>;
  isTech?: InputMaybe<SortOrders>;
  lastName?: InputMaybe<SortOrders>;
  techTier?: InputMaybe<SortOrders>;
  theme?: InputMaybe<SortOrders>;
  timwoodsId?: InputMaybe<SortOrders>;
  useNewRank?: InputMaybe<SortOrders>;
  workDayEnd?: InputMaybe<SortOrders>;
  workDayStart?: InputMaybe<SortOrders>;
};

export enum SortOrders {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortPhoneCallLegsArgs = {
  callProvider?: InputMaybe<SortOrders>;
  callProviderId?: InputMaybe<SortOrders>;
  coreContactId?: InputMaybe<SortOrders>;
  coreUserId?: InputMaybe<SortOrders>;
  endTime?: InputMaybe<SortOrders>;
  extention?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  phonesCallId?: InputMaybe<SortOrders>;
  recordingLocalPath?: InputMaybe<SortOrders>;
  startTime?: InputMaybe<SortOrders>;
  transcription?: InputMaybe<SortOrders>;
};

export type SortPhoneCallsArgs = {
  atTicketId?: InputMaybe<SortOrders>;
  callProvider?: InputMaybe<SortOrders>;
  callProviderId?: InputMaybe<SortOrders>;
  coreCompanyId?: InputMaybe<SortOrders>;
  coreContactId?: InputMaybe<SortOrders>;
  coreUserId?: InputMaybe<SortOrders>;
  direction?: InputMaybe<SortOrders>;
  endTime?: InputMaybe<SortOrders>;
  extention?: InputMaybe<SortOrders>;
  fromNumber?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  startTime?: InputMaybe<SortOrders>;
  toNumber?: InputMaybe<SortOrders>;
};

export type SortScheduleInstancesArgs = {
  atTicketId?: InputMaybe<SortOrders>;
  available?: InputMaybe<SortOrders>;
  azureCalendarId?: InputMaybe<SortOrders>;
  conflictDetected?: InputMaybe<SortOrders>;
  coreGroupId?: InputMaybe<SortOrders>;
  coreUserId?: InputMaybe<SortOrders>;
  endDateTime?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  overidePlan?: InputMaybe<SortOrders>;
  scheduleInstanceDay?: InputMaybe<SortOrders>;
  schedulePlanId?: InputMaybe<SortOrders>;
  schedulerLog?: InputMaybe<SortOrders>;
  startDateTime?: InputMaybe<SortOrders>;
};

export type SortSchedulePlansArgs = {
  atTicketId?: InputMaybe<SortOrders>;
  available?: InputMaybe<SortOrders>;
  blockSizeInMinutes?: InputMaybe<SortOrders>;
  coreGroupId?: InputMaybe<SortOrders>;
  coreUserId?: InputMaybe<SortOrders>;
  description?: InputMaybe<SortOrders>;
  desiredUserCount?: InputMaybe<SortOrders>;
  end_hour?: InputMaybe<SortOrders>;
  end_minute?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  name?: InputMaybe<SortOrders>;
  optimalBlockSizeInMinutes?: InputMaybe<SortOrders>;
  splitableIntoBlocks?: InputMaybe<SortOrders>;
  startTimeDay_daysOfMonth?: InputMaybe<SortOrders>;
  startTimeDay_daysOfWeek?: InputMaybe<SortOrders>;
  startTimeDay_monthsOfTheYear?: InputMaybe<SortOrders>;
  start_hour?: InputMaybe<SortOrders>;
  start_minute?: InputMaybe<SortOrders>;
  timezone?: InputMaybe<SortOrders>;
};

export type SortTicketTimelinesArgs = {
  atTicketId?: InputMaybe<SortOrders>;
  azureEmailId?: InputMaybe<SortOrders>;
  coreTimeclockId?: InputMaybe<SortOrders>;
  date?: InputMaybe<SortOrders>;
  formInstanceId?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  phonesCallId?: InputMaybe<SortOrders>;
  type?: InputMaybe<SortOrders>;
};

export type StringProp = {
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<Scalars['String']['input']>;
  notlike?: InputMaybe<Scalars['String']['input']>;
};

export type TicketTimeline = {
  __typename?: 'TicketTimeline';
  AutotaskTickets?: Maybe<AutotaskTickets>;
  AzureEmail?: Maybe<AzureEmail>;
  CoreTimeclock?: Maybe<CoreTimeclock>;
  FormInstance?: Maybe<FormsInstance>;
  PhoneCall?: Maybe<PhonesCalls>;
  atTicketId?: Maybe<Scalars['Float']['output']>;
  azureEmailId?: Maybe<Scalars['String']['output']>;
  coreTimeclockId?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['DateTimeIso']['output']>;
  formInstanceId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  phonesCallId?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type TimeQueryProp = {
  eq?: InputMaybe<Scalars['Date']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<Scalars['Date']['input']>>;
  like?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  not?: InputMaybe<Scalars['Date']['input']>;
  notlike?: InputMaybe<Scalars['String']['input']>;
};

export type TranscribeArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  prompt?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type GetAllGroupsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllGroupsQuery = { __typename?: 'Query', findCoreGroupPaginated: { __typename?: 'PaginatedCoreGroup', data?: Array<{ __typename?: 'CoreGroup', id?: number | null, name: string }> | null } };


export const GetAllGroupsDocument = gql`
    query GetAllGroups {
  findCoreGroupPaginated {
    data {
      id
      name
    }
  }
}
    `;

/**
 * __useGetAllGroupsQuery__
 *
 * To run a query within a React component, call `useGetAllGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllGroupsQuery, GetAllGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllGroupsQuery, GetAllGroupsQueryVariables>(GetAllGroupsDocument, options);
      }
export function useGetAllGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllGroupsQuery, GetAllGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllGroupsQuery, GetAllGroupsQueryVariables>(GetAllGroupsDocument, options);
        }
export function useGetAllGroupsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllGroupsQuery, GetAllGroupsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllGroupsQuery, GetAllGroupsQueryVariables>(GetAllGroupsDocument, options);
        }
export type GetAllGroupsQueryHookResult = ReturnType<typeof useGetAllGroupsQuery>;
export type GetAllGroupsLazyQueryHookResult = ReturnType<typeof useGetAllGroupsLazyQuery>;
export type GetAllGroupsSuspenseQueryHookResult = ReturnType<typeof useGetAllGroupsSuspenseQuery>;
export type GetAllGroupsQueryResult = Apollo.QueryResult<GetAllGroupsQuery, GetAllGroupsQueryVariables>;