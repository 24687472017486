import { useEffect, useMemo, useState } from "react";

import { useApi } from "../providers/Api/Api";

export type UseDataObject<T extends Record<string,any> = Record<string,any> > = {
    data: T,
    loading: boolean,
    refresh: () => any
}

export type UseDataQuery<T extends Record<string,any> = Record<string,any> > = {
    data: T[],
    loading: boolean,
    refresh: () => any
}

export const useDataQuery = <T extends Record<string,any> >( endpoint: string , params?: Record<string, string | number | null>) => {
    const [ data, setData ] = useState<T[]>([]);
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ refreshTick , setRefreshTick ] = useState<number>(0);
    const api = useApi();
    useEffect( () => {
        setLoading( true );
        api.get( endpoint , params ).then( ( { data } ) => {
            setData( data );
            if( loading ){
                setLoading( false );
            }
        } );
    } , [ api.ready , api , refreshTick ]);
    return {
        data,
        loading,
        refresh: () => {
            setRefreshTick( refreshTick + 1 );
        }
    } as UseDataQuery<T>
}
export const useDataObject = <T extends Record<string,any> >( endpoint: string , params?: Record<string, string | number | null>) => {
    const [ data, setData ] = useState<T>({} as T);
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ refreshTick , setRefreshTick ] = useState<number>(1);
    const api = useApi();
    useEffect( () => {
        if( !api.ready || !api.online ){
            return;
        }
        setLoading( true );
        api.get( endpoint , params ).then( ( { data } ) => {
            setData( data );
            if( loading ){
                setLoading( false );
            }
        } );
    } , [ api.ready , api , refreshTick , api.online ]);

    const refresh = () => {
        setRefreshTick( rt => rt * -1 );
    }

    return {
        data,
        loading,
        refresh
    } as UseDataObject<T>
}