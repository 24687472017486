import { Box, CircularProgress, List, ListItem, Step, StepLabel, Stepper } from "@mui/material";
import { FC } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { SchedulingSubmittingSteps } from "../types";

const keyToLabel = ( str : string ) => {
  return str.replace('_' , ' ').toUpperCase();
}


const LabelIcon : FC<{
    status: SchedulingSubmittingSteps, step : number,
    statusOrder : SchedulingSubmittingSteps[]
}> = ({status, step, statusOrder}) => {
    if(statusOrder.indexOf(status) < step) return <CheckCircleIcon color="success" />
    if(statusOrder.indexOf(status) === step) return <CircularProgress color="info" size={24} />
    return <CircleIcon color="disabled" />
}

const Submitting : FC<{
    submittingStatus: SchedulingSubmittingSteps,
    submittingSteps: SchedulingSubmittingSteps[]
}>= ({ submittingStatus , submittingSteps }) => {
    if( !submittingStatus) return <></>;
    return       <Box sx={{ width: '100%' }}><Stepper orientation="vertical"  activeStep={submittingSteps.indexOf(submittingStatus)}>
        {submittingSteps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          
          if (index < submittingSteps.indexOf(submittingStatus)) {
            stepProps.completed = true;
        }
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}
              StepIconComponent={() => <LabelIcon  status={submittingSteps[index]} step={submittingSteps.indexOf(submittingStatus)} statusOrder={submittingSteps} />}
              >{keyToLabel(label as any )}</StepLabel>
            </Step>
          );
        })}
      </Stepper></Box>
}

export default Submitting;

