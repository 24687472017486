import { useEffect, useMemo, useState } from "react"
import { useApi } from "../../react-submodules/providers/Api/Api";
import { Button, Dialog, DialogContent, DialogTitle, Palette, PaletteColor, Paper, TextField, Theme, Typography, createTheme, useTheme } from "@mui/material";
import { ResourceCalendar } from "../../react-submodules/components/calendar/Calendar";
import FullCalendar from "@fullcalendar/react";

import interactionPlugin from "@fullcalendar/interaction";
import luxon3Plugin from "@fullcalendar/luxon3";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { CoreUser } from "../../react-submodules/types/core";
import { MarkdownView } from "../../react-submodules/MarkdownView";

export interface TimeclockEvent {
    at_ticketNumber: string;
    at_title: string;
    user_email: string;
    id: number;
    start: string;
    end: string;
    lastActive: string;
    ticketId: number;
    coreUserId: string;
    autotaskUserId: number;
    questionAsked?: boolean | null;
    message: string | null;
    isDispatchedTicket: boolean | null;
    ticketCompleted: boolean | null;
    todoCreated: boolean | null;
    todoCompleted: boolean | null;
    ticketUpdateData: Record<string,any> | null;
    fromRole: string;
}
const sortOrder = ['triage' , 'tier-1' , 'tier-2-1stup' , 'tier2' , 'todo' , 'my_ticket' , '' ];

const techTierToName = ( tier: number | null ) => {
    if( tier === null ){
        return "No Level";
    }
    if( tier === 0){
        return "Triage / Dispatch";
    }
    return `Tier ${tier}`
}

const altColors = createTheme(
    {
        palette: {
            primary: {
                main: "#ccc"
            }, 
            secondary: {
                main: "#FFE101"
            }
        }
    }
)

const todoColors = createTheme(
    {
        palette: {
            primary: {
                main: "#C6F6F8"
            }, 
            secondary: {
                main: "#00AEE3"
            }
        }
    }
)

const getPallet = ( e: TimeclockEvent , theme: Theme ) : PaletteColor => {
    if( e.ticketCompleted ){
        return altColors.palette.primary;
    }
    if( e.questionAsked){
        return altColors.palette.secondary;
    }
    
    if( e.todoCompleted){
        return todoColors.palette.primary;
    }
    if( e.todoCreated){
        return todoColors.palette.secondary;
    }
    if( e.end ){
        return theme.palette.primary;
    }
    return theme.palette.secondary;
}

export const TimeclockByRole = () => {
    const [startTime, setStartTime] = useState( "" );
    const [ endTime , setEndTime ] = useState( "" );
    const [ selectedEvent , setSelectedEvent ] = useState<any>( null );
    const [ timeclockData , setTimeclockData ] = useState<TimeclockEvent[]>( [] );
    const api = useApi();
    const [ dataTicket , setDataTicket ] = useState( 1 );
    const [ users , setUsers] = useState<CoreUser[]>( [] );
    const theme = useTheme()
    useEffect( () => {
        api.get<CoreUser[]>('/core/users' , {'email:like' : 'coulee.tech'}).then( (res) => {
            console.log( "Users" ,  res );
            setUsers( res.data.filter( ( u ) => u.techTier !== null ) );
        });
        const intv = setInterval( () => {
            setDataTicket( dataTicket * -1 );
        }, 1000 );
        return () => {
            clearInterval( intv );
        }
    }, [])
    useEffect(()=>{
        api.get('/core/timeclock/report').then( (res) => {
            setTimeclockData( res.data );
        });
    },[dataTicket]);
    const roles = useMemo( () => {
        const tcdata : Record<string , string[]> = {};
        for( const td of timeclockData ){            
            if( !tcdata[ td.coreUserId ] ){
                tcdata[ td.coreUserId ] = [];
            }
            if( !tcdata[ td.coreUserId ].includes( td.fromRole ) ){
                tcdata[ td.coreUserId ].push( td.fromRole  );
            }
        }
        return tcdata;
    }, [JSON.stringify(timeclockData )]);
    const resorces = useMemo( () => {
        
        if( !roles || !users ){
            return [];
        }
        const r : Array<{id:string, title:string, role:string}> = [];
        for( const user of users ){
            for( const role of ( roles[ user.id ] || [] ) ){
                r.push( {
                    id: `${user.id}-${role}`,
                    title: user.email.split("@")[0],
                    role: role
                });
            }
        }
        return r.sort( ( a, b ) => sortOrder.indexOf( a.role ) - sortOrder.indexOf( b.role ) );
    }, [JSON.stringify( roles) , JSON.stringify( users)]);

    const mappedEvents = useMemo(() => {
        if( !timeclockData && !Array.isArray( timeclockData ) ){
            return [];
        }
        const now = new Date( new Date().getTime() + 1000 * 60 * 60 * 2 );
        /* if (!startTime) {
          return [];
        } */
        
        return timeclockData?.map((e) => {
            const endTime = e.end ? new Date( e.end ) : now;
            let pallet = getPallet( e , theme );
            const time = ( endTime.getTime() - new Date( e.start ).getTime() ) / 1000 / 60 / 60;
            if( time > 12 ){
                pallet = theme.palette.error;
            }
            return {
                title: e.at_title,
                start: e.start,
                end: e.end || now,                
                backgroundColor: pallet.main,
                borderColor: pallet.light,
                textColor: pallet.contrastText,
                resourceId: `${e.coreUserId}-${e.fromRole}`,
                fromRole: e.fromRole,
                event : e,
                id: e.id,
              } as any;
        }).sort( ( a, b ) => sortOrder.indexOf( a.fromRole ) - sortOrder.indexOf(b.fromRole ) ); 
      }, [JSON.stringify( timeclockData )]);

    return <>
    <Dialog open={!!selectedEvent} onClose={()=>setSelectedEvent( null )}>
        <DialogTitle>{selectedEvent?.extendedProps?.event?.at_ticketNumber} : {selectedEvent?.extendedProps?.event?.at_title}</DialogTitle>
        <DialogContent>
            <Typography>Start: {new Date(selectedEvent?.extendedProps?.event?.start).toLocaleString()}</Typography>
            <Typography>End: {selectedEvent?.extendedProps?.event?.end ? new Date(selectedEvent?.extendedProps?.event?.end).toLocaleString() : "Not Closed"}</Typography>
            <Button variant="contained" onClick={()=>window.open(`/ticketqueue/all/${selectedEvent?.extendedProps?.event?.ticketId}` , '_blank')}>{selectedEvent?.extendedProps?.event?.at_ticketNumber}</Button>

            <MarkdownView >{selectedEvent?.extendedProps?.event?.message || ''}</MarkdownView>
        </DialogContent>
    </Dialog>
        <Typography variant="h6">Timeclock Overview</Typography>
        <FullCalendar
        initialView={"resourceTimeline"}
        headerToolbar={{
          right:
            "prev,next today resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth",
        }}
        editable={true}
        plugins={[resourceTimelinePlugin, luxon3Plugin, interactionPlugin]}
        resources={resorces}
        contentHeight="auto"
        stickyFooterScrollbar={true}
        stickyHeaderDates={true}
        resourceOrder="title"
        eventClick={(...p) => {
            setSelectedEvent( p[0].event );
        }}
        events={mappedEvents}
        resourceAreaWidth="20%"
        resourceGroupField="role"
        schedulerLicenseKey="0535070809-fcs-1715174460"
        
        views={{
          resourceTimelineTwoDay: {
            type: "resourceTimeline",
            dayCount: 2,
          },
          resourceTimelineThreeDay: {
            type: "resourceTimeline",
            dayCount: 3,
          },
          resourceTimelineFourDay: {
            type: "resourceTimeline",
            dayCount: 4,
          },
        }}
      />
    </>
}

