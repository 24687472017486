
import { ErrorBoundary } from "../react-submodules/components/ErrorBoundry";


export const Test = () => {
    return <>
        <ErrorBoundary >
            <iframe src="https://reports.r.enlightenedmsp.com/JER/FindInstalledSoftware.html" width="100%" height="1000px" />
        </ErrorBoundary>
    </>
}