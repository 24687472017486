import { FC } from 'react';
import { Box, ListItem, ListItemText, Typography, IconButton, Tooltip, CircularProgress, useTheme } from '@mui/material';
import { Icon } from "@iconify/react/dist/iconify.js";
import { AnyBadge } from '../../AnyBadge';
import { ExtendedUserItem } from '../types';
import { BadgeWithBorder } from './BadgeWithBorder';
import { UserIcons } from './UserIcons';
import { isUserFirstUp, getColor } from '../utils';

interface UserListItemProps {
  user: ExtendedUserItem;
  noBorder?: boolean;
  isActive?: boolean;
  timeoutSeconds?: number;
  onStatusChange?: (userId: string, status: ExtendedUserItem['techFinderStatus']) => void;
}

export const UserListItem: FC<UserListItemProps> = ({ 
  user, 
  noBorder, 
  isActive, 
  timeoutSeconds, 
  onStatusChange 
}) => {
  const theme = useTheme();
  
  // Status indicator colors and icons
  const getStatusInfo = (status: ExtendedUserItem['techFinderStatus']) => {
    switch(status) {
      case 'accepted': 
        return { 
          color: theme.palette.success.main, 
          icon: 'akar-icons:check-box-fill',
          tooltip: 'Accepted'
        };
      case 'rejected': 
        return { 
          color: theme.palette.error.main, 
          icon: 'fluent:people-error-20-filled',
          tooltip: 'Rejected'
        };
      case 'pending': 
        return { 
          color: theme.palette.warning.main, 
          icon: 'wpf:ask-question',
          tooltip: 'Pending'
        };
      case 'skip': 
        return { 
          color: theme.palette.grey[500], 
          icon: 'octicon:skip-fill-24',
          tooltip: 'Skipped'
        };
      default: 
        return { 
          color: theme.palette.info.main, 
          icon: 'medical-icon:i-waiting-area',
          tooltip: 'Queued'
        };
    }
  };

  const statusInfo = getStatusInfo(user.techFinderStatus);
  
  // Handle status click to cycle through statuses
  const handleStatusClick = () => {
    if (!onStatusChange || !user.id) return;
    
    const nextStatus: Record<ExtendedUserItem['techFinderStatus'], ExtendedUserItem['techFinderStatus']> = {
      'queued': 'pending',
      'pending': 'accepted',
      'accepted': 'rejected',
      'rejected': 'skip',
      'skip': 'queued'
    };
    
    onStatusChange(user.id, nextStatus[user.techFinderStatus]);
  };
  
  return (
    <ListItem 
      sx={{ 
        padding: 0, 
        paddingLeft: 1, 
        borderTop: noBorder ? 'none' : '1px solid #eee',
        position: 'relative',
        backgroundColor: isActive ? theme.palette.action.hover : 'transparent',
      }} 
      key={user.id}
    >
      <Tooltip title={statusInfo.tooltip}>
        <IconButton 
          size="small" 
          sx={{ 
            color: statusInfo.color,
            marginRight: 1
          }}
          onClick={handleStatusClick}
        >
          <Icon icon={statusInfo.icon} />
        </IconButton>
      </Tooltip>
      <BadgeWithBorder color={getColor(user)} user={user}>
        <AnyBadge size="large" name={user.firstName + ' ' + user.lastName} colorkey={user.email || ''} />
      </BadgeWithBorder>
      <ListItemText 
        sx={{ paddingLeft: 2 }} 
        primary={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography>{user.firstName + ' ' + user.lastName}</Typography>
            {isActive && (
              <Tooltip title="Currently contacting this technician">
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                  <CircularProgress size={16} color="warning" />
                  {timeoutSeconds !== undefined && (
                    <Typography variant="caption" sx={{ ml: 0.5, color: 'warning.main' }}>
                      {timeoutSeconds}s
                    </Typography>
                  )}
                </Box>
              </Tooltip>
            )}
          </Box>
        }
        secondary={
          <Box>
            <Typography variant="caption" color="text.secondary">
              {user.techTier ? `Tier: ${user.techTier}` : ''}
              {user.isOnCall ? ' • On Call' : ''}
              {isUserFirstUp(user) ? ' • First Up' : ''}
              {user.activeTimeclocks && user.activeTimeclocks.length > 0 
                ? ` • Clocked In (${user.activeTimeclocks.length} ticket${user.activeTimeclocks.length > 1 ? 's' : ''})` 
                : ' • Not Clocked In'}
            </Typography>
            <UserIcons user={user} />
          </Box>
        } 
      />
    </ListItem>
  );
}; 