import { FC, useState } from "react";
import { Button } from "@mui/material";
import { useApi } from "../../../react-submodules/providers/Api";
import { parse } from 'csv-parse/browser/esm/sync';
import { useParams } from "react-router-dom";

interface ContractCSV {
    ID: string;
    "Contract ID": string;
    "Contract Name": string;
    "Units Measured": string;
    "Base Unit Price": string;
    "Unit Price Overide": string;
    "Additional Price": string;
    "Approved Units": string;
    "Used Units": string;
    "Auto Approve Units": string;
    DELETE: string;
}
interface ContractUploadProps {
    onUploadComplete: () => void;
}

export const ContractsUpload: FC<ContractUploadProps> = ({ onUploadComplete }) => {
    const api = useApi();
    const { id : coreCompanyId  } = useParams();
    const [isUploading, setIsUploading] = useState(false);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setIsUploading(true);
            const reader = new FileReader();
            reader.onload = async (e) => {
                const csvData = e.target?.result as string;
                const contracts = parse(csvData, {
                    columns: true,
                    skip_empty_lines: true
                }) as ContractCSV[];
                await processContracts(contracts);
                setIsUploading(false);
                onUploadComplete();
            };
            reader.readAsText(file);
        }
    };

    const processContracts = async (contracts: ContractCSV[]) => {
        for (const contract of contracts) {
            const shouldDelete = ['yes', '1', 'true'].includes(contract.DELETE.toLowerCase());

            if (shouldDelete) {
                if (contract.ID) {
                    await api.delete(`/contracts/instances/${contract.ID}`);
                }
            } else {
                const contractData = {
                    contractId: parseInt(contract["Contract ID"]),
                    coreCompanyId: parseInt(coreCompanyId || ''), // Assuming this is the correct field for coreCompanyId
                    name: contract["Contract Name"],
                    unitsMeasured: contract["Units Measured"],
                    approvedUnits: parseInt(contract["Approved Units"] || "0"),
                    unitPrice: contract["Unit Price Overide"] ? parseFloat(contract["Unit Price Overide"]) : null,
                    additionalPrice: contract["Additional Price"] ? parseFloat(contract["Additional Price"]) : null,
                    isActive: true, // You may want to add this field to your CSV
                    autoAproveUnits: contract["Auto Approve Units"].toLowerCase() === "yes",
                };

                if (contract.ID) {
                    await api.patch(`/contracts/instances/${contract.ID}`, contractData);
                } else {
                    await api.post(`/contracts/instances/create`, contractData);
                }
            }
        }
    };

    return (
        <Button
            variant="contained"
            color="primary"
            component="label"
            disabled={isUploading}
        >
            {isUploading ? "Uploading..." : "Upload Contracts CSV"}
            <input
                type="file"
                hidden
                accept=".csv"
                onChange={handleFileUpload}
            />
        </Button>
    );
};