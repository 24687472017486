import { Box, Button, Grid, InputAdornment, TextField } from "@mui/material";
import { useSearchParam } from "../../react-submodules/utils/reactrouter";
import { useApi } from "../../react-submodules/providers/Api";
import { useState } from "react";

export const EmailToDomain = () => {
    const api = useApi();
    const [ email , setEmail ] = useSearchParam( 'email' );
    const [ emailId , setEmailId ] = useSearchParam( 'emailId' );
    const [ coreCompanyId , setCoreCompanyId ] = useState<string>();
    const checkEmail = () => {
        api.post( '/core/debug/emailToDomain', { email } ).then( ( { data } ) => {
            setCoreCompanyId( data.coreCompanyId );
        } );
    }
    const checkEmailId = ( skipCached : boolean = false ) => {
        if( skipCached ){
            api.post( '/azure/emails/companyFromEmail', { emailId , skipCoreCompanyId : true , skipAutotaskTicketId : true } ).then( ( { data } ) => {
                setCoreCompanyId( data?.coreCompanyId );
            } );
        } else {
            api.post( '/azure/emails/companyFromEmail', { emailId } ).then( ( { data } ) => {
                setCoreCompanyId( data?.coreCompanyId );
            } );
        }
    }
    
    return <Box>
        <Grid container spacing={ 2 }>
            <Grid item sm={ 12 }  >
                <TextField 
                    fullWidth
                    label="Email"
            value={ email }
                    onChange={ ( e ) => setEmail( e.target.value ) }
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <Button variant="contained" onClick={ () => checkEmail() }>
                                Submit
                            </Button>
                        </InputAdornment>
                    }}
                />  
            </Grid>
            <Grid item sm={ 12 } >
                <TextField 
                    fullWidth
                    label="Email ID"
            value={ emailId }
                    onChange={ ( e ) => setEmailId( e.target.value ) }
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                                <Button variant="contained" onClick={ () => checkEmailId() }>
                                    Submit
                                </Button>
                                <Button variant="contained" onClick={ () => checkEmailId( true ) }>
                                    Submit No Cache
                                </Button>
                        </InputAdornment>
                    }}
                />  
            </Grid>
            <Grid item sm={ 12 }>
                <pre>{ coreCompanyId }</pre>
            </Grid>
        </Grid>
    </Box>
}