import { Edge, Node } from "reactflow"
import { z } from "zod"
import { AzureEmailSchema } from "../../../../react-submodules/types/azure"
import { GetContext } from "../../types"
import { baseCoreRecipeContextSchema } from "../utils"
import { QueueTicketSchema } from "../../../../react-submodules/types/ticketqueue"

export const getTicketUpdatedContext : GetContext = ( nodeId: string , nodes: Node[] , edges: Edge[] ) => {
    return baseCoreRecipeContextSchema.extend({
        context: z.literal('ticket.updated'),
        ticketId: z.string(),
        updatedColumns: z.array(z.string()),
        ticket: QueueTicketSchema
    });
}