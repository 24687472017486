import { FC, PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from "react";

import { useApi } from "../Api/Api";
import { useMounted } from "../../hooks/useMounted";
import { useUser } from "../User";
import { CoreTimeclock, TimeclockContext } from "./Timeclock.common";


export const TimeclockProvider : FC<PropsWithChildren> = ( { children }) => {

    const mounted = useMounted();
    const api = useApi();
    const user = useUser();
    
    const [ allPunches, setAllPunches ] = useState<CoreTimeclock[]>([]);  
    const activePunches = useMemo( () => {
        return allPunches.filter( ( punch ) => {            
            return punch.coreUserId === user?.tokenParsed?.sub;
        });
    }, [JSON.stringify(allPunches) , user?.tokenParsed?.sub]);
    const otherUsersPunches = useMemo( () => {
        return allPunches.filter( ( punch ) => {            
            return punch.coreUserId !== user?.tokenParsed?.sub;
        });
    }, [JSON.stringify(allPunches) , user?.tokenParsed?.sub]);
    const [ ready , setReady ] = useState( false ); 
    const [ refreshing , setRefreshing ] = useState( false );

    const refresh = () => {
        if( !mounted ) return;
        if( refreshing ) return;
        setRefreshing( true );
        api.get<CoreTimeclock[]>( '/core/timeclock/active' ).then( ( {data} ) => {
            if( !mounted ) return;
            setAllPunches( data );

            if( !ready){
                setReady( true );
            }
            setRefreshing( false );
            
        });
    }
    
    
    const [ tick , setTick ] = useState( 1 );
    useEffect( () => {
        refresh();
    } , [tick]);

    
    useEffect( () => {
        if( !api.ready ) {
            return;
        }
        refresh();
        const intv = setInterval( () => {
            setTick( pv => pv * -1);
        }, 1000 * 15 );
        return () => {
            clearInterval( intv );
        }
    }, [ api.ready , api.token ] );

    return <TimeclockContext.Provider value={{
            activePunches,
            ready,
            refreshing,
            allPunches,
            refresh,
            otherUsersPunches
        }}>{children}</TimeclockContext.Provider>
}
