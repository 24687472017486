import { isJsonNodeDefinition, JsonNodeDefinition } from "./types";



export const clipboardToNode = ( clipboardText: string ) : JsonNodeDefinition | null => {
    try { 
        const json = JSON.parse( clipboardText );
        if( isJsonNodeDefinition( json ) ){
            return json;
        }
    } catch( e ){
        console.error( e );
    }
    return null;
}