import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindAutotaskConfigurationItemsQueryVariables = Types.Exact<{
  page: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
  companyID?: Types.InputMaybe<Types.NumberProp>;
}>;


export type FindAutotaskConfigurationItemsQuery = { __typename?: 'Query', findAutotaskConfigurationItemsPaginated: { __typename?: 'PaginatedAutotaskConfigurationItems', totalCount: number, page: number, limit: number, data?: Array<{ __typename?: 'AutotaskConfigurationItems', id: number, referenceTitle?: string | null, referenceNumber?: string | null, companyID?: number | null, isActive?: boolean | null, configurationItemType?: number | null, serialNumber?: string | null, location?: string | null, lastModifiedTime?: any | null, DattormmDevice?: { __typename?: 'DattormmDevices', id?: number | null, hostname: string, lastLoggedInUser?: string | null, operatingSystem?: string | null, lastSeen: any, online: boolean, category?: string | null } | null }> | null } };


export const FindAutotaskConfigurationItemsDocument = gql`
    query FindAutotaskConfigurationItems($page: Int!, $limit: Int!, $companyID: NumberProp) {
  findAutotaskConfigurationItemsPaginated(
    page: $page
    limit: $limit
    companyID: $companyID
  ) {
    data {
      id
      referenceTitle
      referenceNumber
      companyID
      isActive
      configurationItemType
      serialNumber
      location
      lastModifiedTime
      DattormmDevice {
        id
        hostname
        lastLoggedInUser
        operatingSystem
        lastSeen
        online
        category
      }
    }
    totalCount
    page
    limit
  }
}
    `;

/**
 * __useFindAutotaskConfigurationItemsQuery__
 *
 * To run a query within a React component, call `useFindAutotaskConfigurationItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAutotaskConfigurationItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAutotaskConfigurationItemsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useFindAutotaskConfigurationItemsQuery(baseOptions: Apollo.QueryHookOptions<FindAutotaskConfigurationItemsQuery, FindAutotaskConfigurationItemsQueryVariables> & ({ variables: FindAutotaskConfigurationItemsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAutotaskConfigurationItemsQuery, FindAutotaskConfigurationItemsQueryVariables>(FindAutotaskConfigurationItemsDocument, options);
      }
export function useFindAutotaskConfigurationItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAutotaskConfigurationItemsQuery, FindAutotaskConfigurationItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAutotaskConfigurationItemsQuery, FindAutotaskConfigurationItemsQueryVariables>(FindAutotaskConfigurationItemsDocument, options);
        }
export function useFindAutotaskConfigurationItemsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindAutotaskConfigurationItemsQuery, FindAutotaskConfigurationItemsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindAutotaskConfigurationItemsQuery, FindAutotaskConfigurationItemsQueryVariables>(FindAutotaskConfigurationItemsDocument, options);
        }
export type FindAutotaskConfigurationItemsQueryHookResult = ReturnType<typeof useFindAutotaskConfigurationItemsQuery>;
export type FindAutotaskConfigurationItemsLazyQueryHookResult = ReturnType<typeof useFindAutotaskConfigurationItemsLazyQuery>;
export type FindAutotaskConfigurationItemsSuspenseQueryHookResult = ReturnType<typeof useFindAutotaskConfigurationItemsSuspenseQuery>;
export type FindAutotaskConfigurationItemsQueryResult = Apollo.QueryResult<FindAutotaskConfigurationItemsQuery, FindAutotaskConfigurationItemsQueryVariables>;