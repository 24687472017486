import { PropsWithChildren, createContext, useContext, useMemo } from "react";
import { CalendarEvent, LocationWithDistance, SchedulerWizardProps, SchedulingSubmittingSteps, SchedulingWizardState } from "./types";
import { FC, useEffect, useState } from "react"
import { QueueTicket } from "../../types/ticketqueue"
import { useApi } from "../../providers/Api"
import { getBaseTimes, roundDriveTime } from "./utils"
import { DateTime } from "luxon";
import { CoreUser } from "../../types/core";
import { useTicketApi } from "../../providers/TicketApiProvider";
import { DistanceMatrixResponse } from "../../types/google";

interface SchedulingWizard {
    state: SchedulingWizardState;
    setItem: (item: keyof SchedulingWizardState , value: any) => void;
    setState: (state: SchedulingWizardState) => void;
    submit: () => void;
    submittingStatus: SchedulingSubmittingSteps;
    submittingSteps: SchedulingSubmittingSteps[];
    ticket: QueueTicket | null;
    onClose?: () => void;
    locationsWithDistance: LocationWithDistance[];
    events: CalendarEvent[];
    users: CoreUser[];
    changedEvents: Array<Partial<CalendarEvent> & Pick<CalendarEvent , 'id'>>;
    eventsToRemove: CalendarEvent[];
}

const SchedulingWizardContext = createContext<SchedulingWizard>({
    state: {
        page: 1,
        title: "",
        description: "",
        ticketId: null,
        locationId: null,
        estimatedTimeRequired: 1,
        readyForNextPage: false,
        type: 'onsite',
    },
    setItem: () => {},
    setState: () => {},
    submit: () => {},
    submittingStatus: null,
    submittingSteps: [],
    ticket: null,
    onClose: () => {},
    locationsWithDistance: [],
    events: [],
    users: [],
    changedEvents: [],
    eventsToRemove: []
 })

 export const useSchedulingWizard = () => useContext( SchedulingWizardContext )
 export const SchedulingWizardProvider :React.FC<PropsWithChildren & SchedulerWizardProps > = ( {children ,  initalState , onClose }) => {   
    const api = useApi();
    const { locations , resources } = useTicketApi();
    const [ ticket , setTicket ] = useState<QueueTicket | null>( null );
    const [ submittingStatus , setSubmittingStatus ] = useState<string| null>( null );
    const [ submittingSteps , setSubmittingSteps ] = useState<string[]>( [] );
    const [ state , setState ] = useState<SchedulingWizardState>({
        page: 1,
        title: "",
        description: "",
        ticketId: null,
        locationId: null,
        estimatedTimeRequired: 1,
        readyForNextPage: false,
        type: 'onsite',
        ...initalState
    });

    const [ locationsWithDistance , setLocationsWithDistance] = useState<LocationWithDistance[]>([]);
    const [ events , setEvents] = useState<CalendarEvent[]>([]);
    const [ users , setUsers] = useState<CoreUser[]>( [] );
    const eventLocationIds = useMemo( () => {
        return events.filter( e => Boolean(e.stop_autotaskLocationId)).map( e => Number(e.stop_autotaskLocationId)).filter( ( n , index , arr) => arr.indexOf(n) === index);
    } , [ JSON.stringify(events) ]);

    const eventLocations = useMemo( () => {
        return locations.filter( (location) => eventLocationIds.includes(location.companyID) )
    } , [JSON.stringify( locations) , ticket?.companyID ]);

    const couleeTechLocations = useMemo( () => {
        return locations.filter( (location) => location.companyID === 0 )
    } , [JSON.stringify( locations) , ticket?.companyID ]);
    useEffect( () => {
        if( api.ready ){
            api.get<CalendarEvent[]>(`/azure/calendar/events` , {
                'start:gte':  DateTime.now().minus({days: 1}).toISO(),
                "isCancelled": "false"
            }).then( ({ data}) => {
                setEvents(data);
            });
            api.get<CoreUser[]>('/core/users' , {'email:like' : 'coulee.tech'}).then( (res) => {            
                setUsers( res.data.filter( ( u ) => u.techTier !== null && !['_tholum@coulee.tech', 'rholum@coulee.tech'].includes(u.email) ) );
            });
        }
    } , [api.ready])
    useEffect( () => {
        if( state.address1 && state.zip ){
            // Creating a local instance of it, just in case it changes between the time of the request and the response
            const cthqs = [ ...couleeTechLocations , ...eventLocations];
            api.get<DistanceMatrixResponse>( `/google/distancematrix` , {
                origins: cthqs.map( (location) => `${location.address1} ${location.city} ${location.state} ${location.postalCode}`).join('|'),
                destinations: `${state.address1} ${state.city} ${state.state} ${state.zip}`,
            }).then( (res) => {
                const items = res?.data?.rows.map( ( r , i ) => {
                    const location = cthqs[i];
                    if( !location ){
                        return null;
                    }
                    return {
                        ...location,
                        distance : r.elements[0].distance,
                        duration : r.elements[0].duration
                    }

                })
                setLocationsWithDistance(items.filter( i => i !== null ) as any);
            });
        }
    } , [state.address1 , state.address2 , state.city , state.state , state.zip , JSON.stringify( couleeTechLocations ) , JSON.stringify( eventLocations)]);

    useEffect( () => {
        if( !state.ticketId ) {
            setTicket( null );
            return;
        };
        api.get<QueueTicket>(`/ticketqueue/ticket/${state.ticketId}`).then( ( { data }) => {
            setTicket( data );
        });
    }, [ state.ticketId ]);




    // Add a eventsToRemote , as an id array and a relativeToStopId , which is the id of the stop to add to.

    const eventsToRemove = useMemo( () => {
        if( !Boolean( state.realitiveToStopId)){
            console.log("No Events to remove")
            return [];
        }
        if( state.whenToAdd === 'start'){
            return events.filter( (e) =>  Number(e.drive_toStopId) === Number(state.realitiveToStopId) );
        }
        if( state.whenToAdd === 'end'){
            return events.filter( (e) =>  Number(e.drive_fromStopId) === Number(state.realitiveToStopId) );
        }
        return [];
    } , [ state.realitiveToStopId , state.whenToAdd , JSON.stringify( events) ]);


    const changedEvents: Array<Partial<CalendarEvent> & Pick<CalendarEvent , 'id'>> = useMemo(() => {
        
        if( Boolean( state.addToStopId)){         
            const stopEvent = events.find( (e) => Number(e.stop_id) === Number(state.addToStopId) );
            const startDriveEvent = events.find( (e) => Number(e.drive_toStopId) === Number(state.addToStopId) );
            const endDriveEvent = events.find( (e) => Number(e.drive_fromStopId) === Number(state.addToStopId) );
            const ce : Array<Partial<CalendarEvent> & Pick<CalendarEvent , 'id'>> = [];
            if( state.whenToAdd === 'start'){
                if( startDriveEvent){
                    ce.push({ 
                        id: startDriveEvent?.id , 
                        start : DateTime.fromISO(startDriveEvent?.start || '').minus({ hours : state.estimatedTimeRequired || 0}).toISO() || undefined,
                        end: DateTime.fromISO(startDriveEvent?.end || '').minus({ hours : state.estimatedTimeRequired || 0}).toISO() || undefined
                    });
                }
                if( stopEvent){
                    ce.push({
                        id: stopEvent?.id,
                        start : DateTime.fromISO(stopEvent?.start || '').minus({ hours : state.estimatedTimeRequired || 0}).toISO() || undefined,
                    });
                }
            }
            if( state.whenToAdd === 'end'){
                if( endDriveEvent){
                    ce.push({
                        id: endDriveEvent?.id,
                        end : DateTime.fromISO(endDriveEvent?.end || '').plus({ hours : state.estimatedTimeRequired || 0}).toISO() || undefined,
                        start : DateTime.fromISO(endDriveEvent?.start || '').plus({ hours : state.estimatedTimeRequired || 0}).toISO() || undefined
                    });
                }
                if( stopEvent){
                    ce.push({
                        id: stopEvent?.id,
                        end : DateTime.fromISO(stopEvent?.end || '').plus({ hours : state.estimatedTimeRequired || 0}).toISO() || undefined
                    });
                }
            }
            return ce;
        }
        return [];
    } , [
        state.scheduledTechId , state.scheduledDate , users , locationsWithDistance , state.estimatedTimeRequired , state.addToStopId , JSON.stringify( events), state.whenToAdd
    ]);

    const setItem = (item: keyof SchedulingWizardState , value: any) => {
        setState(prevState => ({ ...prevState , [item] : value }));
    }

    const submitNewEventsCore = async (
        { 
            leaveOfficeTime , 
            ariveOnsiteTime , 
            leaveOnstieTime , 
            ariveOfficeTime ,
            tripId
        } : { 
            tripId: string,
            leaveOfficeTime: DateTime , 
            ariveOnsiteTime: DateTime , 
            leaveOnstieTime: DateTime , 
            ariveOfficeTime: DateTime 
        }
    ) => {
        const { data : stop } = await api.post(`/trips/${tripId}/stops/create` , {
            tripId,
            stopOrder: 0,
            ticketId: state.ticketId,
            start: ariveOnsiteTime.toISO(),
            end: leaveOnstieTime.toISO(),
            "address1": state.address1,
            "address2": state.address2,
            "city": state.city,
            "postalCode": state.zip,
            "state": state.state,
            "coreCompanyId": ticket?.companies_coreCompanyId,
            "autotaskLocationId": state.locationId,
            "estimatedTimeOnsiteInHours": state.estimatedTimeRequired  
            }              
        );
        setSubmittingStatus( 'creating_drive' );
        await api.post(`/trips/${tripId}/drives/create` , {
            tripId,
            start: leaveOfficeTime.toISO(),
            end: ariveOnsiteTime.toISO(),
            "fromStopId": null,
            "toStopId": stop.id,
            "driveTimeToInMinutes": roundDriveTime(state.homeLocation?.duration.value || 0) / 60
        });
        await api.post(`/trips/${tripId}/drives/create` , {
            tripId,
            start: leaveOnstieTime.toISO(),
            end: ariveOfficeTime.toISO(),
            "fromStopId": stop.id,
            "toStopId": null,
            "driveTimeToInMinutes": roundDriveTime(state.homeLocation?.duration.value || 0) / 60
        });
        setSubmittingStatus( 'creating_todo' );
        await api.post(`/todos/todo` , {
            title: state.title,
            noteType : 'task',
            description: state.description,
            autotaskTicketId: state.ticketId,
            "coreUserId": state.scheduledTechId,
            "estimatedMinutes": state.estimatedTimeRequired ? state.estimatedTimeRequired * 60 : 0,
            "tripStopId": stop.id,
            dueDate: DateTime.fromISO(state.scheduledDate || '').toISO()
        })
        setSubmittingStatus( null );
        onClose?.();
    }


    const  submitNewTrip = async () => {
        setSubmittingSteps( ['creating_trip' , 'creating_stop' , 'creating_drive' , 'creating_todo'] );
        if( state.eventsToMake , state.homeLocation){
            setSubmittingStatus( 'creating_trip' );
            const { leaveOfficeTime , ariveOnsiteTime , leaveOnstieTime , ariveOfficeTime } = getBaseTimes( state.homeLocation , state.homeLocation , state.scheduledDate || '' , state.estimatedTimeRequired);
            
            const {data:trip} = await api.post( `/trips/create` ,  {
                homeLocationId: state.homeLocation?.id,
                leaveTime: state.scheduledDate,
                coreUserId: state.scheduledTechId,
            } );
            setSubmittingStatus( 'creating_stop' );
            const tripId = trip.id;
            await submitNewEventsCore({ leaveOfficeTime , ariveOnsiteTime , leaveOnstieTime , ariveOfficeTime , tripId });
            
        }
    } 

    const submitAddToStop = async () => {
        if( state.addToStopId){
            setSubmittingSteps( ['updating_stop' , 'creating_todo' ] );
            setSubmittingStatus( 'updating_stop' );
            await api.post(`/trips/stop/${state.addToStopId}/extend` , {
                whenToAdd: state.whenToAdd,
                addTimeInMinutes: state.estimatedTimeRequired ? state.estimatedTimeRequired * 60 : 0
            });
            setSubmittingStatus( 'creating_todo' );
            await api.post(`/todos/todo` , {
                title: state.title,
                description: state.description,
                noteType : 'task',
                autotaskTicketId: state.ticketId,
                "coreUserId": state.scheduledTechId,
                "estimatedMinutes": state.estimatedTimeRequired ? state.estimatedTimeRequired * 60 : 0,
                "tripStopId": state.addToStopId,
            });
            setSubmittingStatus( null );
            onClose?.();
        }
    }
    const submitRelativeToStop = async () => {
        if( state.realitiveToStopId){
            const selectedTech = users.find( (u) => u.id === state.scheduledTechId );
            const techHomeLocation = locationsWithDistance.find( (l) => l.id === selectedTech?.autotaskLocationId );
            const relativeLocation = locationsWithDistance.find( (l) => l.id === state.realitiveToStopId);
            const relativeStop = events.find( (e) => Number(e.stop_id) === Number( state.realitiveToStopId ));

            const tripId = String(relativeStop?.stop_tripId);
            if( !techHomeLocation || !relativeLocation || !tripId ){
                return;
            }
            setSubmittingSteps( ['removing_drivetime'  , 'creating_stop' , 'creating_drive' , 'creating_todo'] );
            setSubmittingStatus( 'removing_drivetime' );
            
            for await ( const e of eventsToRemove){
                if( Boolean( e.drive_id)){
                    await api.delete(`/trips/drive/${e.drive_id}`);
                }
            }
            
            
        
            switch( state.whenToAdd){
                case 'start' :                     
                    { 
                        const { driveTimeBefore, driveTimeAfter, leaveOfficeTime , ariveOnsiteTime , leaveOnstieTime , ariveOfficeTime } = getBaseTimes( techHomeLocation , relativeLocation , relativeStop?.start || '' , state.estimatedTimeRequired, 'driveend');
                        await submitNewEventsCore({ leaveOfficeTime , ariveOnsiteTime , leaveOnstieTime , ariveOfficeTime , tripId });
                    }
                    break;
                case 'end' : 
                    {
                        const { driveTimeBefore, driveTimeAfter, leaveOfficeTime , ariveOnsiteTime , leaveOnstieTime , ariveOfficeTime } = getBaseTimes( relativeLocation , techHomeLocation , relativeStop?.end || '' , state.estimatedTimeRequired, 'drivestart');
                        await submitNewEventsCore({ leaveOfficeTime , ariveOnsiteTime , leaveOnstieTime , ariveOfficeTime , tripId });
                    }
                    break;
            }
        }
    }
    

    const submit = () => {
        if( state.realitiveToStopId){
            submitRelativeToStop();
            return;
        }
        
        if( state.addToStopId){
            submitAddToStop();
            return;
        }
        if( !state.addToStopId){
            submitNewTrip();
            return;
        }
    }





    return <SchedulingWizardContext.Provider
        value={{
            state,
            setItem,
            submit,
            submittingStatus,
            setState,
            ticket,
            onClose,
            locationsWithDistance,
            events,
            users,
            changedEvents,
            submittingSteps,
            eventsToRemove
        }}
    >{children}</SchedulingWizardContext.Provider>

 }

 export const withSchedulingWizard = (WrappedComponent: React.ComponentType) => {
    const wrapped : FC<PropsWithChildren & SchedulerWizardProps> =  (props) => (
      <SchedulingWizardProvider {...props}>
        <WrappedComponent />
      </SchedulingWizardProvider>
    );
    return wrapped;
  }; 

