import { FC, useEffect, useMemo } from "react"
import { PageProps } from "../types";
import { Grid, ListItemText, MenuItem, Switch, TextField } from "@mui/material";
import { useTicketApi } from "../../../providers/TicketApiProvider";
import { MarkdownEditor } from "../../MarkdownEditor/MarkdownEditor";

 const Page1 : FC<PageProps> = ({ state , setItem , ticket }) => {
    const { locations } = useTicketApi();

    const companyLocations = useMemo( () => {
        return locations.filter( (location) => location.companyID === ticket?.companyID )
    } , [JSON.stringify( locations) , ticket?.companyID ]);

    useEffect( () => {
        if( companyLocations.length === 1 && state.locationId === null ) {
            setItem('locationId' , companyLocations[0].id)
        }
    } , [state.locationId , JSON.stringify( companyLocations)]);

    const location = useMemo( () => {
        return companyLocations.find( (location) => location.id === state.locationId ) || null
    } , [state.locationId , JSON.stringify( locations)]);
    useEffect( () => {
        if( location ){
            setItem('address1' , location.address1);
            setItem('address2' , location.address2);
            setItem('city' , location.city);
            setItem('state' , location.state);
            setItem('zip' , location.postalCode || '');
        }
    } , [location]);



    useEffect( () => {
        const timeReady = Boolean( state.estimatedTimeRequired);
        const locationReady = Boolean ( state.locationId || ( state.address1 && state.address2 && state.city && state.state && state.zip) )
        
        if(  timeReady && locationReady ) {
            setItem( 'readyForNextPage' , true )
        }
    } , [ state.estimatedTimeRequired , state.locationId , state.address1 , state.address2 , state.city , state.state , state.zip ])


    const value = state.locationId || 'UNSET';
    return <>
    <Grid container sx={{padding: 2}} spacing={2}>
        <Grid item xs={6}>
            <Grid container  spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        InputLabelProps={{shrink: true}}
                        fullWidth label="Estimated Time Required ( in hours, without drive time )" 
                        type="number"
                        value={state.estimatedTimeRequired || 0} 
                        onChange={(e) => setItem("estimatedTimeRequired" , e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        InputLabelProps={{shrink: true}}
                        fullWidth 
                        label="Task Title ( don't include ticket id )" 
                        value={state.title} 
                        onChange={(e) => setItem("title" , e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MarkdownEditor label="Description" markdown={state.description} onChange={(markdown) => setItem("description" , markdown)}/>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={6}>
            <Grid container  spacing={2}>
                <Grid item xs={12}>
                <TextField
                    select
                    InputLabelProps={{shrink: true}}
                    fullWidth label="Location" 
                    value={value} 
                    onChange={(e) => setItem("locationId" , e.target.value === 'UNSET' ? null : e.target.value )}
                >
                    <MenuItem value={'UNSET'}>Select One</MenuItem>
                    <MenuItem value={'CUSTOM'}>Custom</MenuItem>
                    {companyLocations.map( (location) => <MenuItem key={location.id} value={location.id}><ListItemText secondary={`${location.address1} ${location.city}, ${location.state} ${location.postalCode}`}>{location.name}</ListItemText></MenuItem> )}
                </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        InputLabelProps={{shrink: true}}
                        disabled={state.locationId !== 'CUSTOM'}
                        fullWidth label="Address 1" 
                        value={state.address1|| ''} 
                        onChange={(e) => setItem("address1" , e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        InputLabelProps={{shrink: true}}
                        disabled={state.locationId !== 'CUSTOM'}
                        fullWidth label="Address 2" 
                        value={state.address2|| ''} 
                        onChange={(e) => setItem("address2" , e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        InputLabelProps={{shrink: true}}
                        disabled={state.locationId !== 'CUSTOM'}
                        fullWidth label="city" 
                        value={state.city|| ''} 
                        onChange={(e) => setItem("city" , e.target.value)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        InputLabelProps={{shrink: true}}
                        disabled={state.locationId !== 'CUSTOM'}
                        fullWidth label="ST" 
                        value={state.state|| ''} 
                        onChange={(e) => setItem("state" , e.target.value)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        InputLabelProps={{shrink: true}}
                        disabled={state.locationId !== 'CUSTOM'}
                        fullWidth label="zip" 
                        value={state.zip|| ''} 
                        onChange={(e) => setItem("zip" , e.target.value)}
                    />
                </Grid>
            </Grid>

        </Grid>
        
    </Grid></>
 }

 export default Page1;

