import { useEffect, useMemo, useState } from "react";
import { ContractDTO } from "../../../../react-submodules/types/contracts.dto";
import { useParams } from "react-router-dom";
import { useApi } from "../../../../react-submodules/providers/Api";
import { StyledTable } from "../../../../react-submodules/components/Table/Table";
import { UnitPrice } from "../../CompanyContracts/UnitPrice";
import { AdditionalPrice } from "../../CompanyContracts/AdditionalPrice";
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import { Icon } from "@iconify/react";


type MappedContractDTO = ContractDTO & {
    subtotal: number,
    usedTotal: number
}


export const BillingV2 = () => {
    const { id  } = useParams()
    const api = useApi();
    const [contracts, setContracts] = useState<Array<MappedContractDTO>>([]);
    const [ refreshTicket , setRefreshTicket ] = useState(1);
    const refresh = () => {
        setRefreshTicket(prev => prev * -1);
    }

    useEffect(()=>{
        api.get<ContractDTO[]>(`/contracts/instances/search` , {
            coreCompanyId : id
        }).then((res)=>{
            setContracts(res.data.map( (row) => ({
                ...row,
                subtotal: ( row.unitPrice === null ? row.contract_unitPrice : row.unitPrice ) * (row.approvedUnits || 1),
                usedTotal: ( row.unitPrice === null ? row.contract_unitPrice : row.unitPrice ) * ( row.usedUnits || 1 )
            })));
        });
    },[id, refreshTicket]);

    const total = useMemo(() => {
        return contracts.reduce((acc, row) => acc + row.subtotal, 0);
    }, [JSON.stringify( contracts)]);

    const usedTotal = useMemo(() => {
        return contracts.reduce((acc, row) => acc + row.usedTotal, 0);
    }, [JSON.stringify( contracts)]);

    return <>
    <StyledTable<MappedContractDTO>
                    rows={contracts}
                    columns={[{
                        field: "id",
                        headerName: "ID",
                    } , {
                        field: "contract_name",
                        headerName: "Contract Name",
                    } , {
                        field: "contract_unitsMeasured",
                        headerName: "Units Measured",
                    } , {
                        field: "contract_unitPrice",
                        headerName: "Unit Price",
                        Element: ({ cell, row }) => {
                            return<UnitPrice row={row}></UnitPrice>
                        }
                    } , {
                        field: "contract_additionalPrice",
                        headerName: "Additional Price",
                        Element: ({ cell, row }) => {
                            return<AdditionalPrice row={row}></AdditionalPrice>
                        }
                    } , {
                        field: "approvedUnits",
                        headerName: "Billed Units",
                    }, {
                        field: "usedUnits",
                        headerName: "Used Units",
                        Element: ({ cell }) => (
                            <Typography>{cell|| 0}</Typography>
                        ),
                    }, {
                        field: "subtotal",
                        headerName: "Subtotal",
                        Element: ({ cell , row }) => (
                            <Typography>${( row.subtotal ).toFixed(2)}</Typography>
                        ),
                    } , {
                        field: "usedTotal",
                        headerName: "Used Total",
                        Element: ({ cell , row }) => (
                            <Typography>${( row.usedTotal ).toFixed(2)}</Typography>
                        ),
                    }
                
                ]}
                />
                <Grid container>
                    <Grid item xs={6}>&nbsp;</Grid>
                    <Grid item xs={6}>
                        <Paper>
                            <Box p={2}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography>Total</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>${total.toFixed(2)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>Used Total</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>${usedTotal.toFixed(2)}</Typography>
                        </Grid>
                    </Grid>
                    </Box>
                </Paper>
                </Grid>
                </Grid>
    </>;
}