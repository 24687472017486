import { FC , useState, useEffect, useMemo } from "react";
import { useApi } from "../../react-submodules/providers/Api/Api";
import { CoreCompanyDTO } from "../../react-submodules/types/core.dto";
import { Button, FormControlLabel, Switch , styled,  Box, TextField , MenuItem , FormControl , InputLabel, Input, InputAdornment} from "@mui/material";

const SettingBox = styled(Box)( {
    margin: `16px`
})


export const SettingsWidget : FC<{ companyId : string }> = ({companyId}) => {
    const [ company , setCompany ] = useState<null | CoreCompanyDTO>(null);
    const [companyName, setCompanyName] = useState<string>("");
    const [ defaultTicketLevel , setDefaultTicketLevel ] = useState<'full_managed' | 'security_only' | 'unmanaged'>("full_managed");
    const [ defaultHourlyRate , setDefaultHourlyRate ] = useState<number>(0);
    const [ billingConfigured , setBillingConfigured ] = useState<boolean>(false);
    const [ active , setActive ] = useState<boolean>(false);
    useEffect( () => {
        if( company !== null ){
            setCompanyName( company.name );
            setDefaultTicketLevel( company.CoreCompanies_defaultTicketLevel );
            setDefaultHourlyRate( company.CoreCompanies_defaultHourlyRate );
            setBillingConfigured( Boolean(company.CoreCompanies_billingConfigured ) );
            setActive( Boolean(company.CoreCompanies_active ) );
            console.log( company );
        }
    }, [ company ] );
    const api = useApi();
    const clean = useMemo(()=>{
        return companyName === company?.name && defaultTicketLevel === company?.CoreCompanies_defaultTicketLevel && defaultHourlyRate === company?.CoreCompanies_defaultHourlyRate && billingConfigured === Boolean(company?.CoreCompanies_billingConfigured);
    } , [ companyName , defaultTicketLevel , defaultHourlyRate , billingConfigured , company]);
    useEffect( () => {
        if( api.ready ){
            api.get<CoreCompanyDTO>( `/core/company/${companyId}` ).then( ( results ) => {
                setCompany( results.data );
            } )
        }
    } , [ companyId , api.ready ]);
    const save = ()=>{
        api.patch( `/core/company/${companyId}` , {
            defaultTicketLevel: defaultTicketLevel,
            defaultHourlyRate: defaultHourlyRate,
            billingConfigured: billingConfigured,
            active: active
        } ).then( ( results ) => {
            setCompany( results.data );
        } )
    }
    return <><Box sx={{padding: 2}}>
            <SettingBox>
            
            <TextField disabled fullWidth label="Company Name" value={companyName} onChange={
                (e) => {
                    setCompanyName( e.target.value );
                }
            } />
            </SettingBox>
            <SettingBox>
            <TextField select label="Default Ticket Level" fullWidth value={defaultTicketLevel} onChange={( e ) => {
                setDefaultTicketLevel( e.target.value as any );
            }}>
                <MenuItem value="full_managed">Full Managed</MenuItem>
                <MenuItem value="security_only">Security Only</MenuItem>
                <MenuItem value="unmanaged">Unmanaged</MenuItem>
            </TextField>
            </SettingBox>
            <SettingBox>
            <TextField sx={{ textAlign: 'left'}} InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            endAdornment: <InputAdornment position="end">/hr</InputAdornment>,
          }} fullWidth label="Hourly Rate" value={defaultHourlyRate} onChange={(e) => {
                setDefaultHourlyRate( parseInt( e.target.value ) );
            }} type="number" />
            </SettingBox>
            <SettingBox sx={{marginBottom: 0}}>
            <FormControlLabel control={<Switch color="secondary" checked={billingConfigured} onChange={(v)=>{
                    setBillingConfigured( v.target.checked );
                }} ></Switch>
            } label="Billing Configured" />
            </SettingBox>
            <SettingBox sx={{marginBottom: 0}}>
            <FormControlLabel control={<Switch color="secondary" checked={active} onChange={(v)=>{
                    setActive( v.target.checked );
                }} ></Switch>
            } label="Active" />
            </SettingBox>
    </Box>
    <Box sx={{ display: 'flex'}}>
            <Box flexGrow={1}>&nbsp;</Box>
            <Box sx={{marginRight: 4}} ><Button color={clean ? 'primary' : 'secondary'} onClick={()=>{save();}} variant="contained">Save</Button></Box>
    </Box>
    </>
}