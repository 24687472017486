import { FC } from "react";
import { FormControlLabel, Switch, Typography } from "@mui/material";
import { InlineIcon } from "@iconify/react";

type WithContactlessStart = {
  contactlessStart?: boolean | null;
}

// Helper functions for contactlessStart icons and colors
export const contactlessStartToIcon = (contactlessStart?: boolean | null) => {
  return Boolean(contactlessStart) 
    ? 'material-symbols:call-end' 
    : 'material-symbols:call';
};

export const contactlessStartToColor = (contactlessStart?: boolean | null) => {
  return Boolean(contactlessStart) 
    ? '#4caf50'  // Green for contactless
    : '#2196f3'; // Blue for contact required
};

export const GqlContactlessStart = <T extends WithContactlessStart>({ 
  ticket,
  edit,
  onChange,
  iconOnly
}: {
  ticket: T;
  edit?: boolean;
  onChange?: (current: T | ((prev: T) => T)) => void;
  iconOnly?: boolean;
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(current => ({
        ...current,
        contactlessStart: event.target.checked
      }));
    }
  };

  if (!edit) {
    return (
      <Typography>
        <InlineIcon 
          color={contactlessStartToColor(ticket.contactlessStart)} 
          icon={contactlessStartToIcon(ticket.contactlessStart)} 
        />
        &nbsp;{!iconOnly && (ticket.contactlessStart ? 'Contactless Start' : 'Contact Required')}
      </Typography>
    );
  }

  return (
    <FormControlLabel
      control={
        <Switch 
          checked={Boolean(ticket.contactlessStart)} 
          onChange={handleChange}
        />
      }
      label="Contactless Start (Next action doesn't require a call)"
    />
  );
}; 