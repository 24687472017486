import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClockInMutationVariables = Types.Exact<{
  ticketId: Types.Scalars['Float']['input'];
}>;


export type ClockInMutation = { __typename?: 'Mutation', timeclockClockin: { __typename?: 'CoreTimeclock', id?: number | null, start?: any | null, end?: any | null, ticketId?: number | null, coreUserId?: string | null } };

export type ClockOutMutationVariables = Types.Exact<{
  id: Types.Scalars['Float']['input'];
  message?: Types.InputMaybe<Types.Scalars['String']['input']>;
  end: Types.Scalars['Date']['input'];
}>;


export type ClockOutMutation = { __typename?: 'Mutation', updateTimeclock: { __typename?: 'CoreTimeclock', id?: number | null, start?: any | null, end?: any | null, ticketId?: number | null, coreUserId?: string | null } };


export const ClockInDocument = gql`
    mutation ClockIn($ticketId: Float!) {
  timeclockClockin(ticketId: $ticketId) {
    id
    start
    end
    ticketId
    coreUserId
  }
}
    `;
export type ClockInMutationFn = Apollo.MutationFunction<ClockInMutation, ClockInMutationVariables>;

/**
 * __useClockInMutation__
 *
 * To run a mutation, you first call `useClockInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClockInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clockInMutation, { data, loading, error }] = useClockInMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useClockInMutation(baseOptions?: Apollo.MutationHookOptions<ClockInMutation, ClockInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClockInMutation, ClockInMutationVariables>(ClockInDocument, options);
      }
export type ClockInMutationHookResult = ReturnType<typeof useClockInMutation>;
export type ClockInMutationResult = Apollo.MutationResult<ClockInMutation>;
export type ClockInMutationOptions = Apollo.BaseMutationOptions<ClockInMutation, ClockInMutationVariables>;
export const ClockOutDocument = gql`
    mutation ClockOut($id: Float!, $message: String, $end: Date!) {
  updateTimeclock(id: $id, message: $message, end: $end) {
    id
    start
    end
    ticketId
    coreUserId
  }
}
    `;
export type ClockOutMutationFn = Apollo.MutationFunction<ClockOutMutation, ClockOutMutationVariables>;

/**
 * __useClockOutMutation__
 *
 * To run a mutation, you first call `useClockOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClockOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clockOutMutation, { data, loading, error }] = useClockOutMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useClockOutMutation(baseOptions?: Apollo.MutationHookOptions<ClockOutMutation, ClockOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClockOutMutation, ClockOutMutationVariables>(ClockOutDocument, options);
      }
export type ClockOutMutationHookResult = ReturnType<typeof useClockOutMutation>;
export type ClockOutMutationResult = Apollo.MutationResult<ClockOutMutation>;
export type ClockOutMutationOptions = Apollo.BaseMutationOptions<ClockOutMutation, ClockOutMutationVariables>;