import { Box, Button, MenuItem, TextField, TextFieldProps, Typography } from "@mui/material"
import { ExtraFieldSetting, FieldSettingProps } from "./types"

const defaultTextareaProps : Partial<TextFieldProps> = {
    size : "small" ,
    fullWidth : true,
    sx: { marginTop : 1 }
}

const presets = ['today' , 'tomorrow'];


export type DateManipulate = {
    days?: number
}


export const DateFieldSetting : React.FC<FieldSettingProps> =  ({ extra,  id , value , type , interpreter , onChange , onDelete }) => {
    const presetValue = presets.includes(value) ? value : 'none';
    const days : number = extra?.manipulate?.days || 0;

    const setDays = (days : number) => {
        onChange( c => {
            const cExtra = c.extra || {};

            const manipulate = cExtra.manipulate || {};
            const updateValue = { ...c ,   extra : {  ...cExtra , manipulate : {...manipulate , days} } };
            return updateValue;
        } );
    }

    return <Box sx={{border: '1px solid' , borderColor: '#777' , borderRadius: 2 , p: 1 , m: 1, marginBottom: 2 , position: 'relative'}}>
        
        <TextField select label="Preset Value" {...defaultTextareaProps} value={presetValue} onChange={(e)=>{
            if( presets.includes( e.target.value ) ){
                onChange({value:e.target.value as any })
            } else {
                onChange({value: ""});
            }
            
        } } >
            <MenuItem value="none">None</MenuItem>
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="tomorrow">Tomorrow</MenuItem>
        </TextField>
        <TextField disabled={presetValue !== 'none'} label="value (yyyy-mm-dd)" {...defaultTextareaProps} value={value} onChange={(e)=>{onChange({value:e.target.value as any })}} />
        <TextField label="interpreter" {...defaultTextareaProps} select value={interpreter} onChange={(e)=>{onChange({interpreter:e.target.value as any })}}>
            <MenuItem value="DIRECT">DIRECT</MenuItem>
            <MenuItem value="HANDLEBARS">HANDLEBARS</MenuItem>
        </TextField>
        <TextField {...defaultTextareaProps} type="number" label="Additional Days" value={days} onChange={(e)=>{setDays(parseInt(e.target.value))}} />
        <Box sx={{position: 'absolute' , top: -16 , left: 10 , background: 'white' , p: 0.5 , borderRadius: 1}}><Typography>{id}</Typography></Box>
        <Button onClick={onDelete} color="error">Delete</Button>
    </Box>
  }
  

