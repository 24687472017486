import { Box, Button, MenuItem, TextField, TextFieldProps, Typography } from "@mui/material"
import { ExtraFieldSetting, FieldSettingProps } from "./types"

const defaultTextareaProps : Partial<TextFieldProps> = {
    size : "small" ,
    fullWidth : true,
    sx: { marginTop : 1 }
}

const presets = ['today' , 'tomorrow'];


export type DateManipulate = {
    days?: number
}


export const TextFieldSetting : React.FC<FieldSettingProps> =  ({ extra,  id , value , type , interpreter , onChange , multiline , onDelete }) => {    

    return <Box sx={{border: '1px solid' , borderColor: '#777' , borderRadius: 2 , p: 1 , position: 'relative' , m : 1 , marginBottom: 2 }}>
        {multiline}
        
        <TextField rows={multiline ? 5 : undefined} multiline={multiline} label="value" {...defaultTextareaProps} value={value} onChange={(e)=>{onChange({value:e.target.value as any })}} />
        <TextField label="interpreter" {...defaultTextareaProps} select value={interpreter} onChange={(e)=>{onChange({interpreter:e.target.value as any })}}>
            <MenuItem value="DIRECT">DIRECT</MenuItem>
            <MenuItem value="HANDLEBARS">HANDLEBARS</MenuItem>
        </TextField>
        
        <Box sx={{position: 'absolute' , top: -16 , left: 10 , background: 'white' , p: 0.5 , borderRadius: 1}}><Typography>{id}</Typography></Box>
        <Button onClick={onDelete} color="error">Delete</Button>
    </Box>
  }
  

