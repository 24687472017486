

import recipeNodes from './index';
import { GetContext } from "../types";
import { z } from "zod";
import { Edge, Node } from 'reactflow';
import { RocketcyberIncidentsSchema } from '../../../react-submodules/types/rocketcyber';

export const baseCoreRecipeContextSchema = z.object({
    context: z.string(),
    ticketId: z.string().optional().describe('If there is a ticket tied to the context'),
    coreCompanyId: z.number().optional().describe('If there is a company tied to the context'),
    autotaskCompanyId: z.number().optional().describe('If there is a company tied to the context'),
    autotaskContactId: z.number().optional().describe('If there is a contact tied to the context'),
    coreContactId: z.number().optional().describe('If there is a contact tied to the context'),
    rocketCyberIncidentId: z.number().optional().describe('If there is a rocket cyber incident tied to the context'),
    rocketCyberIncident: RocketcyberIncidentsSchema.optional().describe('If there is a rocket cyber incident tied to the context')
  }).catchall(z.any());


export const getContextRetreaver = ( nodeType: string ) : GetContext  => {
    const nodeDef = recipeNodes.find( n => n.id === nodeType );

    return nodeDef?.getContext || ( () => {
        console.log( "Default Context ");
        return z.any()
    } );
}
export const getParentContext = ( nodeId: string , nodes: Node[] , edges: Edge[] ) => {
    
    const edge = edges.find( e => e.target === nodeId );
    const parent = nodes.find( n => n.id === edge?.source );
    const parentContextRetreaver = getContextRetreaver( parent?.type || "" );
    return parentContextRetreaver( parent?.id || "" , nodes , edges );
}