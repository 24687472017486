import { useParams } from "react-router-dom";
import { FunctionComponent, useEffect, useState } from "react";
import { Paper, Chip, Box, Typography, IconButton, Tooltip, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Grid } from "@mui/material";
import { Icon } from "@iconify/react";
import { StyledTable } from "../../../../react-submodules/components/Table/Table";
import { useApi } from "../../../../react-submodules/providers/Api";
import { CoreContact, CreateNewContactDTO } from "../../../../coretypes/contacts/contacts.dto";
import { ContractsInstances } from "../../../../coretypes/contracts/contracts.dto";
import { ADD_CONTACT_ICON, EDIT_ICON } from "../../../../react-submodules/constances/icons";
import { CoreLocations } from "../../../../coretypes/locations";
import { NewLocationButton } from "./NewLocationDialog";
import { UpdateLocationDialog } from "./UpdateLocationDialog";

export const Locations: FunctionComponent = () => {
    const { id } = useParams<{id: string}>();
    const [ editLocationId, setEditLocationId ] = useState<number | null>(null);
    const [ locations, setLocations ] = useState<CoreLocations[]>([]);
    const [refreshKey, setRefreshKey] = useState(1);
    const api = useApi();
    


    const refresh = () => {
        setRefreshKey(-1 * refreshKey);
    };


    useEffect(() => {
        api.get<CoreLocations[]>( `/core/locations/search` , { coreCompanyId : id } ).then( ( results ) => {
            setLocations(results.data);
        });
    }, [api.ready, id, refreshKey]);


    
    

    return (
        <><Paper>
            { editLocationId !== null && <UpdateLocationDialog id={editLocationId} open={editLocationId !== null} onClose={() => setEditLocationId(null)} refresh={refresh} /> }    
            <StyledTable
                title="Locations"
                rows={locations}
                indexField="id"
                columns={[
                    {
                        field: 'name',
                        flex: 1,
                        headerName: 'Name',
                        sortable: true
                    },
                    {
                        field: 'address1',
                        flex: 1,
                        headerName: 'Address',
                        sortable: true
                    },
                    {
                        field: 'city',
                        flex: 1,
                        headerName: 'City',
                        sortable: true,
                    },
                    {
                        field: 'state',
                        flex: 1,
                        headerName: 'State',
                        sortable: true,
                    },
                    {
                        field: 'zip',
                        flex: 1,
                        headerName: 'Zip',
                        sortable: false,
                    },
                    {
                        field: 'phone',
                        flex: 1,
                        headerName: 'Phone',
                        sortable: false,
                    },
                    {
                        field: 'actions',
                        flex: 1,
                        headerName: 'Actions',
                        sortable: false,
                        Element: (params) => <><IconButton onClick={() => setEditLocationId(params.row.id)}><Icon icon={EDIT_ICON} /></IconButton></>
                    }
                ]}
                actions={
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <NewLocationButton refresh={refresh} />
                    </Box>
                }
            />
        </Paper>
        
        </>
    );
};