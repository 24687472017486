import { BeEdge, BeNode } from "../react-submodules/types/recipes";
import { Connection, Edge, EdgeChange, Node, NodeChange, addEdge, applyEdgeChanges, applyNodeChanges } from "reactflow";
import { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { beEdgeToReactFlowEdge, beNodetoReactFlowNode, reactFlowNodeToBeEdge, reactFlowNodeToBeNode } from "./recipes.utils";

import { useApi } from "../react-submodules/providers/Api/Api";
import { useParams } from "react-router-dom";
import { v4 } from 'uuid';

interface Recipies {
    nodes : Node[],
    edges: Edge[],
    onNodesChange: (changes: NodeChange[]) => any,
    onEdgesChange: (changes: EdgeChange[]) => any,
    onConnect: (params: Edge | Connection ) => any
    addNode: (node: Node) => any
    deleteNode: (id: string) => any
    deleteEdge: (id: string) => any
    updateNodeData: (id: string , data: any) => any
}
const RecipieContext = createContext<Recipies>({
    nodes : [],
    edges: [],
    onNodesChange: () => { return []},
    onEdgesChange: () => { return []},
    onConnect: () => {},
    addNode: () => {},
    deleteNode: () => {},
    deleteEdge: () => {},
    updateNodeData: () => {}

});
type FunctionManipulator = (data: Record<string, any>) => Record<string, any> ;
export const useRecipie = () => useContext(RecipieContext);


  const getValue = (  value: any , defaultValue? : any ) => {
    
    if( defaultValue === undefined ){
        return value;
    }
    return value === undefined ? defaultValue : value;
  }
  
  type UNDProp<T> = [ T , ( value: T ) => void  ];

  


const NodeSync : FC<{node : Node , loaded : boolean , recipeId : string }> = ( { node , loaded , recipeId }) => {
    const api = useApi();
    useEffect( () => {
        if( !loaded ) return;
        const timeout = setTimeout( () => {
            api.patch(`/recipe/${recipeId}/node/${node.id}/data`,  node.data );            
        } , 1000);
        return () => clearTimeout(timeout);
    } , [ node.data ]);
    useEffect( () => {
        if( !loaded ) return;
        const timeout = setTimeout( () => {
            api.patch(`/recipe/${recipeId}/node/${node.id}`, { positionX: node.position.x , positionY: node.position.y });            
        } , 1000);
        return () => clearTimeout(timeout);
    } , [ node.position.x , node.position.y]);
    return <></>
}

export const RecipieProvider :React.FC<PropsWithChildren> = ( {children}) => {
    const api = useApi();
    const { id } = useParams<{id : string }>();
    const recipeId = parseInt(id || '');
    const [ nodes , setNodes ] = useState<Node[]>([]);
    const [ edges , setEdges ] = useState<Edge[]>([]);
    const [ loaded , setLoaded ] = useState(false);
    
    useEffect( () => {
        api.get<
            {
                nodes: BeNode[],
                edges: BeEdge[]
            }
        >( `/recipe/${id}/full`).then( ({ data }) => {
            setNodes(data.nodes.map( beNodetoReactFlowNode ));
            setEdges(data.edges.map( beEdgeToReactFlowEdge));
            setTimeout( () => {setLoaded(true) } , 1000 ) ;
        });
    } , [ api.ready ])


    const onNodesChange = useCallback(
        (changes : NodeChange[] ) => {
            setNodes((nds) => applyNodeChanges(changes, nds)) 
        },
        [],
      );
      const onEdgesChange = useCallback(
        (changes : any ) => setEdges((eds : any ) => applyEdgeChanges(changes, eds)),
        [],
      );
      const onConnect = useCallback(
          (params : any ) => setEdges((eds) => { 
            const edgeId = v4();
            const edge = {...params , type: 'smoothstep' , id : edgeId };
            api.post(`/recipe/${id}/edge`, reactFlowNodeToBeEdge( edge , recipeId ));
            return addEdge(edge , eds) 
        }),
          [],
        );
    const addNode = useCallback(
        (node : Node) => { 
            api.post(`/recipe/${id}/node`, reactFlowNodeToBeNode( node , recipeId )).then( () => {} );
            setNodes( nds => [...nds , beNodetoReactFlowNode(reactFlowNodeToBeNode( node , recipeId ))]) 
        },
        [ api.token ]
    )

    const updateNodeData = useCallback(
        (id: string, data: Record<string, any> | FunctionManipulator ) => {
            setNodes((nds) => {
                const updatedNodes = nds.map((node) => {
                    if (node.id === id) {
                        if( typeof data === 'function' ){
                            return {
                                ...node,
                                data: data(node.data),
                            };
                        }
                        return {
                            ...node,
                            data: {
                                ...node.data,
                                ...data,
                            },
                        };
                    }
                    return node;
                });
                return updatedNodes;
            });
        },
        []
    );

    const deleteNode = useCallback(
        (id: string) => {
            api.delete(`/recipe/${recipeId}/node/${id}`).then(() => {
                setNodes((nds) => nds.filter((node) => node.id !== id));
                setEdges((eds) => eds.filter((edge) => edge.source !== id && edge.target !== id));
            });
        },
        [api.token]
    );

    const deleteEdge = useCallback(
        (id: string) => {
            api.delete(`/recipe/${recipeId}/edge/${id}`).then(() => {
                setEdges((eds) => eds.filter((edge) => edge.id !== id));
            });
        },
        [api.token]
    );

    return (
        <>
        {nodes.map( (node) => <NodeSync recipeId={id||''} loaded={loaded} key={node.id} node={node} />)}
        {loaded && <RecipieContext.Provider value={{ deleteEdge , deleteNode , nodes  , edges ,  onNodesChange , onEdgesChange, onConnect , addNode , updateNodeData }}>
            
            {children}</RecipieContext.Provider> }
        </>
    )
}

export const useNodeDataProperty = <T = any>( id: string , property: string , defaultValue? : T  ) : UNDProp<T> => {
    const { nodes , updateNodeData  } = useRecipie();
    const node = nodes.find( nd => nd.id === id );
    
    const updateProperty = useCallback( ( value: any ) => {
        updateNodeData( id , { [property]: value });
    } , [updateNodeData , id , property] );

    return  [ getValue( node?.data[property] , defaultValue )  , updateProperty ];
  
  }