import { Autocomplete, TextField } from "@mui/material";
import { useSearchCompaniesLazyQuery } from "../gql/SearchCompanies.generated";
import { useEffect, useState } from "react";
import { debounce } from "lodash";
import { CoreCompanies } from "../../../generated/graphql";

type Company = Pick<CoreCompanies, 'id' | 'name'>;

interface CompanySearchProps {
  value: number | null;
  onChange: (companyId: number | null) => void;
}

export const CompanySearch = ({ value, onChange }: CompanySearchProps) => {
  const [search, { data }] = useSearchCompaniesLazyQuery();
  const [inputValue, setInputValue] = useState("");
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

  const companies = data?.findCoreCompaniesPaginated?.data || [];

  // Debounced search function
  const debouncedSearch = debounce((term: string) => {
    if (term) {
      search({ variables: { search: term } });
    }
  }, 300);

  // Handle input change
  const handleInputChange = (_: any, newInputValue: string) => {
    setInputValue(newInputValue);
    debouncedSearch(newInputValue);
  };

  // Handle selection change
  const handleChange = (_: any, newValue: Company | null) => {
    setSelectedCompany(newValue);
    onChange(newValue?.id ?? null);
  };

  // If value changes externally, clear selection
  useEffect(() => {
    if (!value) {
      setSelectedCompany(null);
      setInputValue("");
    }
  }, [value]);

  return (
    <Autocomplete
      value={selectedCompany}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={companies}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Company"
          fullWidth
        />
      )}
    />
  );
};