import { gql, useQuery } from "@apollo/client";
import { Box, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material"
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { withRecipeApiProvider as rapi, useRecipeApi  } from "../../react-submodules/providers/Recipe";
import { FormActionWatcher } from "../../react-submodules/components/FormActionWatcher";

const FORM_NODES = gql`
query FindRecipeNodePaginated {
    findRecipeNodePaginated(type: { eq: "start" }){
        data {
            id
            recipeId
            data 
        }
    }
}
`


export const Forms = rapi(() => {
    const { id } = useParams();
    const { data , loading } = useQuery(FORM_NODES);
    const { startRecipe } = useRecipeApi();
    return <Box>
        <FormActionWatcher />
        <Typography>Recipe Starts</Typography>
        <List>
            {data?.findRecipeNodePaginated?.data.map( (node : any) => { 
                if( !node.data.independentStart){
                    return <Fragment key={node.id}></Fragment>;
                }
                return <ListItem key={node.id}>
                    <ListItemButton onClick={() => {
                        startRecipe( node.id , {} );
                    }}>
                        <ListItemText primary={node.data.title} />
                    </ListItemButton>
                </ListItem>
            })}
        </List>
    </Box>
} )