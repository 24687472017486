import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindAutotaskTicketsPaginatedQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  coreCompanyId?: Types.InputMaybe<Types.Scalars['Float']['input']>;
}>;


export type FindAutotaskTicketsPaginatedQuery = { __typename?: 'Query', findAutotaskTicketsPaginated: { __typename?: 'PaginatedAutotaskTickets', data?: Array<{ __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, title: string, parentTicketId?: number | null, coreCompanyId?: number | null }> | null } };


export const FindAutotaskTicketsPaginatedDocument = gql`
    query FindAutotaskTicketsPaginated($search: String, $coreCompanyId: Float) {
  findAutotaskTicketsPaginated(
    search: $search
    status: {not: 5}
    parentTicketId: {null: true}
    coreCompanyId: {eq: $coreCompanyId}
  ) {
    data {
      id
      ticketNumber
      title
      parentTicketId
      coreCompanyId
    }
  }
}
    `;

/**
 * __useFindAutotaskTicketsPaginatedQuery__
 *
 * To run a query within a React component, call `useFindAutotaskTicketsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAutotaskTicketsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAutotaskTicketsPaginatedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      coreCompanyId: // value for 'coreCompanyId'
 *   },
 * });
 */
export function useFindAutotaskTicketsPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<FindAutotaskTicketsPaginatedQuery, FindAutotaskTicketsPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAutotaskTicketsPaginatedQuery, FindAutotaskTicketsPaginatedQueryVariables>(FindAutotaskTicketsPaginatedDocument, options);
      }
export function useFindAutotaskTicketsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAutotaskTicketsPaginatedQuery, FindAutotaskTicketsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAutotaskTicketsPaginatedQuery, FindAutotaskTicketsPaginatedQueryVariables>(FindAutotaskTicketsPaginatedDocument, options);
        }
export function useFindAutotaskTicketsPaginatedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindAutotaskTicketsPaginatedQuery, FindAutotaskTicketsPaginatedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindAutotaskTicketsPaginatedQuery, FindAutotaskTicketsPaginatedQueryVariables>(FindAutotaskTicketsPaginatedDocument, options);
        }
export type FindAutotaskTicketsPaginatedQueryHookResult = ReturnType<typeof useFindAutotaskTicketsPaginatedQuery>;
export type FindAutotaskTicketsPaginatedLazyQueryHookResult = ReturnType<typeof useFindAutotaskTicketsPaginatedLazyQuery>;
export type FindAutotaskTicketsPaginatedSuspenseQueryHookResult = ReturnType<typeof useFindAutotaskTicketsPaginatedSuspenseQuery>;
export type FindAutotaskTicketsPaginatedQueryResult = Apollo.QueryResult<FindAutotaskTicketsPaginatedQuery, FindAutotaskTicketsPaginatedQueryVariables>;