import { Badge, Button, IconButton, ListItemText, Menu, MenuItem, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";

import { AnswerTodoDialog } from "../../react-submodules/components/AnswerTodoDialog";
import { Icon } from "@iconify/react";
import { TTodosTodo } from "../../react-submodules/types/todos.dto";
import { useApi } from "../../react-submodules/providers/Api/Api";
import { useTicketApi } from "../../react-submodules/providers/TicketApiProvider";

const QuestionIcon : FC<{count : number }> = ( { count } ) => {
    const theme = useTheme();
    if( count === 0 ) return <Icon icon="iconoir:chat-bubble-question-solid" color={theme.palette.primary.contrastText} />
    return <Badge badgeContent={count } color="info" ><Icon icon="iconoir:chat-bubble-question-solid" color={theme.palette.primary.contrastText} /></Badge>
}


export const MyQuestions = () => {
    const api = useApi();
    const theme = useTheme();
    
    const [ updateTicketTick , setUpdateTicketTick ] = useState( 1 );
    const [ myQuestions , setMyQuestions ] = useState<TTodosTodo[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [ selectedTodo , setSelectedTodo ] = useState<TTodosTodo | null >( null );

    useEffect( () => {
        if( api.ready === false ) return;
        setUpdateTicketTick( pv => pv * -1);
        const interval = setInterval( ()=>{
            setUpdateTicketTick( pv => pv * -1);
        } , 30 * 1000 )
        return () => {
            clearInterval( interval );
        }
    } , [ api.ready ]);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
    useEffect( () => {
        api.get<TTodosTodo[]>('/todos/questions/mine').then( ( { data }) => {
            setMyQuestions( data );
        } );
    } , [ updateTicketTick ]);

    const open = Boolean(anchorEl);


    return <><IconButton onClick={handleClick}><QuestionIcon count={myQuestions.length} /></IconButton>
    <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem><ListItemText primary="Questions" ></ListItemText></MenuItem>
        {myQuestions.map( ( question , idx ) => <MenuItem onClick={()=>{
            setSelectedTodo( question );
        }}  key={question.id}><ListItemText primary={question.title} ></ListItemText></MenuItem>)}
      </Menu>
      { selectedTodo && <AnswerTodoDialog open={ selectedTodo !== null } onClose={()=>{
        setUpdateTicketTick( pv => pv * -1);
        setSelectedTodo( null );
      }} todo={selectedTodo} /> }
    </>
}