import { FunctionComponent, useEffect, useState } from "react"
import { useApi } from "../../react-submodules/providers/Api/Api";
import { Alert, Badge, Box, Chip, Divider, Typography, useTheme } from "@mui/material";
import { Label } from "@mui/icons-material";


type DattoRmmSummaryType = {
    devices: Array<{
        category: string;
        count: number;
    }>
}

const SectionDevicer : FunctionComponent<{ title : string}> = ({ title }) => {
    const theme = useTheme();
    return <Box sx={{
        background: theme.palette.secondary.main, color: theme.palette.secondary.contrastText, padding: 1, borderRadius: 1

    }}><Typography variant="body1">{title}</Typography></Box>
}

export const DattoRmmWidget : FunctionComponent<{ dattoSiteId: number | null }> = ({ dattoSiteId }) => {
    const api = useApi();
    
    const [ summary , setSummary ] = useState<DattoRmmSummaryType | null>( null );
    useEffect( () => {
        if( api.ready && typeof dattoSiteId === 'number'){
            api.get( `/dattormm/summary` , {
                siteId: dattoSiteId
            } ).then( ( results ) => {
                setSummary( results.data );
            });
        }
    } , [dattoSiteId , api.ready]);
    if( dattoSiteId === null){
        return <Alert severity="warning">DattoRmm is not configured</Alert>
    }
    return <>
        
        <Box >
        <SectionDevicer title="Devices" />
        <Divider />
        <Box sx={{margin: 2}}>
        {summary?.devices.map( ( device ) => {
          return <Badge max={9999} key={device.category|| 'Default'} sx={{margin: 1}} badgeContent={device.count} color="primary"><Chip color="info" label={device.category} /></Badge>
        }) }
        </Box>
        </Box>
    </>
}