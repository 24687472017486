import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAutotaskTicketsMutationVariables = Types.Exact<{
  coreCompanyId: Types.Scalars['Int']['input'];
  coreContactId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  title: Types.Scalars['String']['input'];
  status: Types.Scalars['Int']['input'];
  queueID: Types.Scalars['Int']['input'];
  priority: Types.Scalars['Int']['input'];
  contactlessStart: Types.Scalars['Boolean']['input'];
}>;


export type CreateAutotaskTicketsMutation = { __typename?: 'Mutation', createAutotaskTickets: { __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, coreCompanyId?: number | null, coreContactId?: number | null, contactID?: number | null, companyID?: number | null } };


export const CreateAutotaskTicketsDocument = gql`
    mutation CreateAutotaskTickets($coreCompanyId: Int!, $coreContactId: Int, $title: String!, $status: Int!, $queueID: Int!, $priority: Int!, $contactlessStart: Boolean!) {
  createAutotaskTickets(
    coreCompanyId: $coreCompanyId
    coreContactId: $coreContactId
    title: $title
    status: $status
    queueID: $queueID
    priority: $priority
    contactlessStart: $contactlessStart
  ) {
    id
    ticketNumber
    coreCompanyId
    coreContactId
    contactID
    companyID
  }
}
    `;
export type CreateAutotaskTicketsMutationFn = Apollo.MutationFunction<CreateAutotaskTicketsMutation, CreateAutotaskTicketsMutationVariables>;

/**
 * __useCreateAutotaskTicketsMutation__
 *
 * To run a mutation, you first call `useCreateAutotaskTicketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAutotaskTicketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAutotaskTicketsMutation, { data, loading, error }] = useCreateAutotaskTicketsMutation({
 *   variables: {
 *      coreCompanyId: // value for 'coreCompanyId'
 *      coreContactId: // value for 'coreContactId'
 *      title: // value for 'title'
 *      status: // value for 'status'
 *      queueID: // value for 'queueID'
 *      priority: // value for 'priority'
 *      contactlessStart: // value for 'contactlessStart'
 *   },
 * });
 */
export function useCreateAutotaskTicketsMutation(baseOptions?: Apollo.MutationHookOptions<CreateAutotaskTicketsMutation, CreateAutotaskTicketsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAutotaskTicketsMutation, CreateAutotaskTicketsMutationVariables>(CreateAutotaskTicketsDocument, options);
      }
export type CreateAutotaskTicketsMutationHookResult = ReturnType<typeof useCreateAutotaskTicketsMutation>;
export type CreateAutotaskTicketsMutationResult = Apollo.MutationResult<CreateAutotaskTicketsMutation>;
export type CreateAutotaskTicketsMutationOptions = Apollo.BaseMutationOptions<CreateAutotaskTicketsMutation, CreateAutotaskTicketsMutationVariables>;