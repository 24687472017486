import { useNavigate, useParams } from "react-router-dom";

export const useQueueParams = () => {
    const { queueId , ticketId : origTicket } = useParams<{ queueId: string , ticketId? : string }>();
    const nav=useNavigate();
    
    const changeTicket = ( ticketId : string , prepend="" ) => {
            nav(`${prepend}${ticketId}`, { replace: true });
        
    }
    return { queueId , ticketId : origTicket , changeTicket };
}