import { Container, FormControlLabel, Grid, Paper, Switch, TextField } from "@mui/material"
import { memo, useEffect, useMemo, useState } from "react";
import { QueueTicket } from "../../react-submodules/types/ticketqueue";
import { useApi } from "../../react-submodules/providers/Api/Api";
import { StyledTable, TableColumnDef } from "../../react-submodules/components/Table";
import { useNavigate } from "react-router-dom";
import { Company } from "../../react-submodules/components/TicketPartials/Company";
import { TicketApiProvider } from "../../react-submodules/providers/TicketApiProvider";
import { Queue } from "../../react-submodules/components/TicketPartials/Queue";
import { QueuePicker } from "./QueuePicker";
import { useSearchParam } from "../../react-submodules/utils/reactrouter";
import { Resource } from "../../react-submodules/components/TicketPartials/Resource";


const MemoizedStyleTable = memo( StyledTable );
const columns : TableColumnDef[] = [
    {
        field: 'id',
        headerName: 'Ticket ID',
        flex: 1,
    },
    {
        field: 'title',
        headerName: 'Title',
        flex: 1,
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
    },
    {
        field: 'contacts_emailAddress',
        headerName: 'Contact Email',
        flex: 1,
    },
    {
        field: 'contacts_firstName',
        headerName: 'Contact Name',
        flex: 1,
        // @ts-ignore
        Element: ( {row} : any ) => {
            console.log( { row });
            if( !row.contacts_firstName && !row.contacts_lastName ){
                return <></>;
            }                
            return `${row.contacts_firstName} ${row.contacts_lastName}`;
        } 
    },
    {
        field: 'companies_companyName',
        headerName: 'Company Name',
        flex: 1,
    },
    {
        field: 'ticketNumber',
        headerName: 'Ticket Number',
        flex: 1,
    },
    {
        field: 'queue',
        headerName: 'Queue',
        flex: 1,
    },
    {
        field: 'assigned_tech_email',
        headerName: 'Tech',
        flex: 1,
        // @ts-ignore
        Element: ( {cell} : any ) => {
            return <>{cell?.split('@')[0]}</>
        }
    }
]


export const TicketSearch = () => {
    const nav = useNavigate();
    const [ search , setSearch ] = useSearchParam( 'search' , { typeProp: 'string' , defaultValue: '' } );
    const [ tickets , setTickets ] = useState<QueueTicket[]>( [] );
    const [ companyId , setCompanyId ] = useState<number | null>( null );
    const [ queueId , setQueueId ] = useSearchParam<string | null>( 'queue' , { typeProp: 'string' , defaultValue: null } );
    const [ allTickets , setAllTickets ] = useSearchParam<boolean>( 'allTickets' , { typeProp: 'boolean' , defaultValue: false } );
    const [ resourceID , setResourceID ] = useSearchParam<number | null>( 'resource' , { typeProp: 'number' , defaultValue: null } );
    const api = useApi();
    useEffect( () => {
        const getData = setTimeout(() => {
        const queueID = (queueId === 'ALL_QUEUES' || !queueId ) ? undefined : queueId;
        api.get<QueueTicket[]>(`/ticketqueue/tickets`, { assignedResourceID : resourceID || undefined , queueID , companyID: companyId, search, allTickets: allTickets, page: 0, limit: 100  }).then(({ data }) => setTickets(data))
        }, 200)
        return () => clearTimeout(getData)
    }, [companyId, search, allTickets , queueId , resourceID ]);

    /*const filteredTickets = useMemo(() => {
        return tickets.filter((ticket: QueueTicket) => {
          return ticket.title.toLowerCase().includes(search.toLowerCase()) || ticket.ticketNumber?.toLowerCase().includes(search.toLowerCase()) || ticket.companies_companyName?.toLowerCase().includes(search.toLowerCase());
        });
      }, [search, JSON.stringify(tickets)]); */


    // const visiableTicket = useMemo(() => {
    //     return tickets.filter((ticket: QueueTicket) => {
    //       return ticket.title.toLowerCase().includes(search.toLowerCase());
    //     });
    //   }, [search, tickets]);

    useEffect(() => {
        const getData = setTimeout(() => {
            api.get( '/ticketqueue/tickets').then( ( results ) => {
              setTickets( results.data );
            });
          }, 200)
          return () => clearTimeout(getData)
        }, []);
    

    return <><TicketApiProvider getNextTicket={() => {}}>
        <Container sx={{marginTop: 2 }}>
            <h1>Ticket Search</h1>
            <Paper elevation={2} sx={{padding: 1}}>
            <Grid container spacing={2}>
                <Grid item xs={6} sx={{mt: 2}}>
                    <Company companyID={typeof companyId === 'number' ? companyId : undefined} edit={true} onChange={(company) => setCompanyId(company?.id ?? null)} />
                </Grid>
                <Grid item xs={6} sx={{mt: 2}}>
                    <QueuePicker queue={typeof queueId === 'string' ? queueId : undefined} edit={true} onChange={(queue : any ) => setQueueId(queue?.value ?? null)} />
                </Grid>
                <Grid item xs={6} sx={{mt: 2}}>
                    <FormControlLabel label="All Tickets" control={<Switch checked={allTickets ?? false} onChange={(e) => setAllTickets(e.target.checked)} />} />
                </Grid>
                <Grid item xs={6} sx={{mt: 2}}>
                    <Resource resourceID={resourceID || undefined} edit={true} onChange={(resource) => setResourceID(resource?.id ?? null)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth label="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
                </Grid>
            </Grid>
            </Paper>
        </Container>
        { tickets.length > 0 && (
            <Container sx={{marginTop: 2 }}>
                <Paper>
                    <MemoizedStyleTable
                        onClick={( row)=>{
                            window.open(`/ticketqueue/tickets/${row.id}`, `ticket_${row.id}`);
                        }}
                        rows={tickets}
                        columns={columns}
                    />
                </Paper>
            </Container>
        )
        }
        </TicketApiProvider>
    </> 
}

