import { 
  Alert, 
  Button, 
  Checkbox,
  Dialog, 
  DialogContent, 
  DialogTitle, 
  FormControlLabel,
  Grid, 
  IconButton, 
  ListSubheader, 
  MenuItem, 
  TextField, 
  Typography 
} from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { TTodosTodo } from "../../types/todos.dto";
import { useApi } from "../../providers/Api/Api";
import { useTicket } from "../../providers/TicketProvider";
import { useTicketApi } from "../../providers/TicketApiProvider";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { MarkdownEditor } from "../MarkdownEditor/MarkdownEditor";
import { useCreateTodoGroupMutation } from "./gql/CreateTodoGroup.generated";

export const BulkTodoDialog: FC<{ 
  open: boolean, 
  onClose: () => any, 
  todo?: Partial<TTodosTodo>,
  todoGroupId?: number
}> = ({ open, onClose, todo, todoGroupId: providedTodoGroupId }) => {
  const { ticket } = useTicket();
  const api = useApi();
  const { groups, users } = useTicketApi();

  // Shared properties for all todos
  const [groupId, setGroup] = useState<number | null>(null);
  const [userId, setUser] = useState<string | null>(null);
  const [dueDate, setDueDate] = useState("");
  
  // Array of titles for bulk creation
  const [titles, setTitles] = useState<string[]>(['']);

  // Todo group state - only used if providedTodoGroupId is not provided
  const [createAsGroup, setCreateAsGroup] = useState(false);
  const [groupTitle, setGroupTitle] = useState("");
  const [groupDescription, setGroupDescription] = useState("");

  // GraphQL mutation
  const [createTodoGroup] = useCreateTodoGroupMutation();

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    if (dueDate === '' && groupId === 1) {
      setDueDate(today);
    }
  }, [groupId, dueDate]);

  const assignableGroups = useMemo(() => {
    return groups.map(g => ({
      value: `group:${g.id}`,
      text: g.name
    }));
  }, [JSON.stringify(groups)]);

  const assignableUsers = useMemo(() => {
    return users.map(u => ({
      value: `user:${u.id}`,
      text: `${u.firstName} ${u.lastName}`
    }));
  }, [JSON.stringify(users)]);

  const assignedTo = useMemo(() => {
    if (userId) {
      return `user:${userId}`;
    }
    if (groupId) {
      return `group:${groupId}`;
    }
    return '';
  }, [groupId, userId]);

  const handleAssignedToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    
    if (!value) {
      setUser(null);
      setGroup(null);
      return;
    }
    
    if (value.startsWith("user:")) {
      setUser(value.split(":")[1]);
      setGroup(null);
      return;
    }
    
    if (value.startsWith("group:")) {
      setGroup(parseInt(value.split(":")[1]) || null);
      setUser(null);
    }
  };

  // Update a title at specific index
  const updateTitle = (index: number, newTitle: string) => {
    const newTitles = [...titles];
    newTitles[index] = newTitle;
    setTitles(newTitles);
  };

  // Remove a title at specific index
  const removeTitle = (index: number) => {
    if (titles.length > 1) {
      const newTitles = [...titles];
      newTitles.splice(index, 1);
      setTitles(newTitles);
    }
  };

  // Add a new empty title
  const addTitle = () => {
    setTitles([...titles, '']);
  };

  // Ensure there's always at least one empty title at the end
  useEffect(() => {
    const lastTitleEmpty = titles.length > 0 && titles[titles.length - 1] === '';
    if (!lastTitleEmpty) {
      addTitle();
    }
  }, [titles]);

  const createTodos = async () => {
    if (!groupId && !userId) return;

    // Filter out empty titles
    const validTitles = titles.filter(title => title.trim() !== '');
    
    if (validTitles.length === 0) return;

    let todoGroupId: number | null = providedTodoGroupId || null;

    // Create todo group if checkbox is checked and no todoGroupId was provided
    if (!providedTodoGroupId && createAsGroup && groupTitle.trim() !== '') {
      try {
        const ticketId = ticket?.id ? (typeof ticket.id === 'string' ? parseInt(ticket.id) : ticket.id) : null;
        const response = await createTodoGroup({
          variables: {
            title: groupTitle,
            description: groupDescription,
            atTicketId: ticketId
          }
        });
        
        if (response.data?.createTodosGroup.id) {
          todoGroupId = response.data.createTodosGroup.id;
        }
      } catch (error) {
        console.error('Error creating todo group:', error);
      }
    }

    // Create a todo for each valid title
    const promises = validTitles.map(title => {
      return api.post('/todos/todo', {
        title,
        description: '', // No shared description in bulk mode
        noteType: 'task',
        groupId,
        coreUserId: userId,
        autotaskTicketId: ticket?.id || null,
        dueDate: dueDate || null, // Ensure null is sent to API if dueDate is empty string
        ticketBlocking: false,
        todoGroupId: todoGroupId // Add the todoGroupId if a group was created or provided
      });
    });

    await Promise.all(promises);
    onClose();
  };

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>Bulk Create Todos</DialogTitle>
      <DialogContent sx={{
        width: '800px'
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Create multiple todos with the same assigned to and next action date
              {providedTodoGroupId && " (will be added to existing todo group)"}
            </Typography>
          </Grid>

          {/* Shared properties section */}
          <Grid item sm={6}>
            <TextField 
              error={assignedTo === ''} 
              required 
              sx={{ marginTop: 1, marginBottom: 1 }} 
              fullWidth 
              size="small" 
              label="Assigned To" 
              value={assignedTo} 
              select
              onChange={handleAssignedToChange}
            >
              <ListSubheader>Groups</ListSubheader>
              {assignableGroups.map(t => (
                <MenuItem 
                  sx={{ marginLeft: 2 }} 
                  key={t.value} 
                  value={t.value}
                >
                  {t.text}
                </MenuItem>
              ))}
              <ListSubheader>Users</ListSubheader>
              {assignableUsers.map(t => (
                <MenuItem 
                  sx={{ marginLeft: 2 }} 
                  key={t.value} 
                  value={t.value}
                >
                  {t.text}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item sm={6}>
            <TextField 
              fullWidth 
              sx={{ marginTop: 1 }} 
              InputLabelProps={{ shrink: true }} 
              size="small" 
              label="Next Action Date" 
              type="date" 
              value={dueDate} 
              onChange={e => setDueDate(e.target.value)} 
            />
          </Grid>

          {/* Todo Group section - only show if providedTodoGroupId is not provided */}
          {!providedTodoGroupId && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={createAsGroup}
                    onChange={(e) => setCreateAsGroup(e.target.checked)}
                  />
                }
                label="Create as Todo Group"
              />
            </Grid>
          )}

          {!providedTodoGroupId && createAsGroup && (
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  label="Group Title"
                  value={groupTitle}
                  onChange={(e) => setGroupTitle(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" sx={{ mb: 1 }}>
                  Group Description
                </Typography>
                <MarkdownEditor
                  markdown={groupDescription}
                  onChange={(value) => setGroupDescription(value)}
                  label="Group Description"
                  size="small"
                />
              </Grid>
            </>
          )}

          {/* Todo titles section */}
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Todo Titles
            </Typography>
          </Grid>

          {titles.map((title, index) => (
            <Grid item xs={12} key={index}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    size="small"
                    label={`Title ${index + 1}`}
                    value={title}
                    onChange={(e) => updateTitle(index, e.target.value)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton 
                    color="error" 
                    onClick={() => removeTitle(index)} 
                    disabled={titles.length <= 1}
                  >
                    <DeleteIcon />
                  </IconButton>
                  {index === titles.length - 1 && (
                    <IconButton color="primary" onClick={addTitle}>
                      <AddIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}

          {dueDate && 
            <Grid item xs={12}>
              <Alert color="info" sx={{ mt: 2 }}>
                These todos will not use the Ticket's Next Action Date
              </Alert>
            </Grid>
          }
          
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Button 
              onClick={createTodos} 
              color="success" 
              variant="contained" 
              size="small" 
              disabled={
                assignedTo === '' || 
                !titles.some(title => title.trim() !== '') ||
                (!providedTodoGroupId && createAsGroup && groupTitle.trim() === '')
              }
            >
              Create Todos
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default BulkTodoDialog; 