import { MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";

import { CoreUser } from "../react-submodules/types/core";
import { QueueTicket } from "../react-submodules/types/ticketqueue";
import { StyledTable } from "../react-submodules/components/Table"
import { useApi } from "../react-submodules/providers/Api/Api";
import { useMounted } from "../react-submodules/hooks/useMounted";
import { useQueueParams } from "../react-submodules/hooks/useQueueParams";

export const QueueOrder = () => {
    
    const loaded = useMounted();
    const { queueId } = useQueueParams();
    const [ tickets , setTickets ] = useState< QueueTicket[] >( [] );
    const api = useApi();

    const [ userId , setUserId ] = useState<  string | null >( null );
    const [ users , setUsers] = useState< CoreUser[] >( [] );
    const [ tick , setTick ] = useState( 1 );

    useEffect( () => {
        api.get<CoreUser[]>( '/core/users' ).then( ( { data }) => {
            loaded() && setUsers( data.filter( user => /coulee.tech/.test( user.email ) ) );
        });
    }, [api.ready]);

    
    const refreshQueueData = () => {
        if( userId === null ) return;
        api.get<QueueTicket[]>(`/ticketqueue/queue/${queueId}/user/${userId}` ).then( ( { data }) => {
            loaded() && setTickets( data );
        });
    }

    useEffect( () => {
        refreshQueueData();
    } , [ tick])
    useEffect( () => {
        setTick( pv => pv * -1 );
        const iv = setInterval( () => {
            setTick( pv => pv * -1 );
        }, 15000);
        return () => {
            clearInterval( iv );
        }
    }, [ queueId , userId , api.ready ]);

    
    return <>

    <TextField value={userId} onChange={( e ) => {
        setUserId( e.target.value );
    }} select >
        {users.map( user => <MenuItem value={user.id}>{user.email}</MenuItem>)}
    </TextField>
    
    
    <StyledTable columns={[
        {
            headerName: "Ticket",
            field: "ticketNumber"
        },
        {
            headerName: "Title",
            field: "title"
        } , 
        {
            headerName: "NAD",
            field: "nextActionDate"
        } , 
        {
            headerName: "Status",
            field: "status"
        } , 
        {
            headerName: "Priority",
            field: "priority"
        },
        {
            headerName: "Status ID",
            field: "statusId"
        } , 
        {
            headerName: "Queue",
            field: "queue"
        } , 
        {
            headerName: "Rank",
            field: "importanceRank"
        }
    ]} rows={tickets} /></>
}