import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchCoreGroupsQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SearchCoreGroupsQuery = { __typename?: 'Query', findCoreGroupPaginated: { __typename?: 'PaginatedCoreGroup', data?: Array<{ __typename?: 'CoreGroup', id?: number | null, name: string, description?: string | null }> | null } };


export const SearchCoreGroupsDocument = gql`
    query SearchCoreGroups($search: String) {
  findCoreGroupPaginated(name: {like: $search}) {
    data {
      id
      name
      description
    }
  }
}
    `;

/**
 * __useSearchCoreGroupsQuery__
 *
 * To run a query within a React component, call `useSearchCoreGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCoreGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCoreGroupsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchCoreGroupsQuery(baseOptions?: Apollo.QueryHookOptions<SearchCoreGroupsQuery, SearchCoreGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCoreGroupsQuery, SearchCoreGroupsQueryVariables>(SearchCoreGroupsDocument, options);
      }
export function useSearchCoreGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCoreGroupsQuery, SearchCoreGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCoreGroupsQuery, SearchCoreGroupsQueryVariables>(SearchCoreGroupsDocument, options);
        }
export function useSearchCoreGroupsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchCoreGroupsQuery, SearchCoreGroupsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchCoreGroupsQuery, SearchCoreGroupsQueryVariables>(SearchCoreGroupsDocument, options);
        }
export type SearchCoreGroupsQueryHookResult = ReturnType<typeof useSearchCoreGroupsQuery>;
export type SearchCoreGroupsLazyQueryHookResult = ReturnType<typeof useSearchCoreGroupsLazyQuery>;
export type SearchCoreGroupsSuspenseQueryHookResult = ReturnType<typeof useSearchCoreGroupsSuspenseQuery>;
export type SearchCoreGroupsQueryResult = Apollo.QueryResult<SearchCoreGroupsQuery, SearchCoreGroupsQueryVariables>;