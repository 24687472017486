import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

interface LabeledBoxProps {
  label: string;
  children: ReactNode;
}

export const LabeledBox: FC<PropsWithChildren<{ label : string , sx? : React.CSSProperties , fontSx? : React.CSSProperties }>> = ({ label, children , sx , fontSx }) => {
    return <Box sx={{ border : "1px solid rgba(0, 0, 0, 0.2 )" , borderRadius : "5px" , padding: 1 , position:'relative' , ...sx }}>
    <Box sx={{ position:'absolute' , top : '-13px' , left : '12px' }} ><Typography sx={{
      backgroundColor : "white" ,
      padding : "0 4px",
      color: "rgba(0, 0, 0, 0.5 )" ,
      ...fontSx
    }} fontSize="12px" variant="caption">{label}</Typography></Box>
    {children}
  
</Box>
};