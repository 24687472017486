import { Box, Card, CardContent, CardHeader, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";

import { FC } from "react";
import { Icon } from "@iconify/react";
import { UserReportData } from "./pages/OldManagerView";

const CheckBox = ( { value , label} : { value : boolean | number | null , label : string }) => { 
    const icon = value ? 'material-symbols:check-box' : 'bxs:error';
    const color = value ? 'green' : 'red';

    return <Grid item sm={6}><Icon icon={icon} color={color} /> {label}</Grid>

}

export const UserCard : FC<{ item : UserReportData}> = ( { item}) => {
    const { user , dispatch , dispatchticket , timeclockticket , queue } = item;
    return <Card sx={{margin: 1}}>
        <CardHeader title={user.email} />
        <CardContent>
            <Grid container>
                <CheckBox value={user.isDispatchable} label="Dispatchable" />
                <CheckBox value={timeclockticket?.id} label="Clocked In" />
                <CheckBox value={timeclockticket?.id === dispatch.ticketId} label="Working Dispatched" />
                <Grid item sm={12}>
                    <List>
                        <ListItem>
                            <ListItemText primary={`${timeclockticket?.ticketNumber || 'Not clocked in'} (Timeclock)`} secondary={timeclockticket?.title} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={`${dispatchticket?.ticketNumber} (Dispatched)`} secondary={dispatchticket?.title} />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item sm={12}>
                    <Typography variant="h6">Next 3 tickets</Typography>
                    <List>
                        {(queue||[] ).slice(0,3).map( ticket => {
                            return <ListItem key={ticket.id}>
                            <ListItemText primary={`${ticket?.ticketNumber}`} secondary={ticket?.title} />
                        </ListItem>
                        } )}
                    </List>
                </Grid>
            </Grid>
        </CardContent>  
    </Card>
}