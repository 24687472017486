import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "../../../../react-submodules/providers/Api";
import { ADD_CONTACT_ICON } from "../../../../react-submodules/constances/icons";
import { Icon } from "@iconify/react";
import { CoreLocations } from "../../../../coretypes/locations";
import { AddEditContactDialog } from "./AddEditContactDialog";

export const NewContactButton : FC<{refresh: () => void}> = ({refresh}) => {
    const { id } = useParams<{id: string}>();
    const api = useApi();
    const [newContactDialogOpen, setNewContactDialogOpen] = useState(false);

    const createNewContact = ( params : any ) => {
        api.post('/core/contacts/create', params ).then( () => {
            setNewContactDialogOpen(false);
            
            refresh();
        } );
    };

    return <>
     <Button
        variant="contained"
        color="success"
        size="small"
        onClick={()=> {
            setNewContactDialogOpen(true);
        }}
        startIcon={<Icon icon={ADD_CONTACT_ICON} />}
        sx={{ whiteSpace: 'nowrap' }} >
        Create New Contact
    </Button>
    <AddEditContactDialog 
        open={newContactDialogOpen}
        onClose={() => setNewContactDialogOpen(false)}
        onSave={createNewContact}
        title="Create New Contact"
        onSaveText="Create"
        coreCompanyId={Number(id)}
        />
    </>
}