import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";

import { AnyBadge } from "../AnyBadge";
import { AutocompleteCompany } from "../../types/ticketqueue";
import { BadgeAndName } from "./BadgeAndName";
import { useTicketApi } from "../../providers/TicketApiProvider";

export const Company: FC<{ edit?: boolean; companyID?: number , onChange?: ( company : AutocompleteCompany | null ) => any}> = (
    { companyID , edit , onChange }
) => {
    const { companies } = useTicketApi();
    const [ reload , setReload ] = useState(false);
    const selectedCompany = useMemo(() => {
        return companies.find( ( c ) => {
            return c.id === companyID;
        });
    }, [JSON.stringify(companies) ,companyID ]);


    useEffect( () => {
        setReload( true);
    } , [JSON.stringify( companies ) ])
    useEffect( () => {
        setTimeout( () => {
            setReload( false );
        } , 1)
    } , [reload])
    if( !edit){
        return <>{selectedCompany && <BadgeAndName phone={selectedCompany.phone} displayName={selectedCompany.companyName || ''} colorkey={`${companyID}`||undefined} />}</>
    }

    if( reload ){
        return <></>
    }
    return <><Autocomplete
    value={selectedCompany||null}
    options={companies}
    size="small"
    fullWidth
    onChange={(event , company) => {
        onChange && onChange( company );
    }}
    
    filterOptions={(options : AutocompleteCompany[] , state : any ) => {
        const filtered =  options.filter( ( option ) => {
            const keys = state.inputValue.toLowerCase().split(" ");
            return keys.reduce( ( pv : boolean , cv : string ) => {
                return pv && option.companyName.toLowerCase().includes( cv ) 
            } , true );
        }).filter( (c , i  , a  ) => {
            const sameName = a.filter( ( o ) => o.companyName === c.companyName );
            if( sameName.length === 1 ){
                return true;
            }
            const namedWithCoreId = sameName.filter( ( o ) => o.coreCompanyId  );
            if( namedWithCoreId.length === 1 ){
                return Boolean( c.coreCompanyId );
            }
            const firstItem = a.findIndex( o => o.companyName === c.companyName);
            if( firstItem === i ){
                return true;
            }
            return false;
        })
        return filtered
    
    }}
    renderInput={({ InputProps , ...params  }) => <TextField           
        InputProps={{...InputProps , startAdornment: <InputAdornment position="start">{ selectedCompany && <AnyBadge name={selectedCompany.companyName} colorkey={selectedCompany.id||undefined}  />}</InputAdornment>}}  
        {...params} 
        label="Company" 
    />}
  /></>
}