import { io, Socket } from 'socket.io-client';
import { createContext, useContext, useEffect, useState, useMemo } from 'react';
import { useUser } from '../react-submodules/providers/User';
import { getApiEndpoint } from '../react-submodules/providers/Api/api.utils';

const SocketContext = createContext<Socket | null>(null);

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
    const user = useUser();
    const [socket, setSocket] = useState<Socket | null>(null);
    
    useEffect(() => {
        console.log( "Socket UseEffect")
        const URL = getApiEndpoint("websockets");
        if (!user.token) {
            return;
        }
        const socket = io(URL, {
            auth: {
                token: user.token // Use the actual token from user object
            }
        });
        setSocket(socket || null);
        
        // Cleanup function to disconnect socket when component unmounts
        return () => {
            if (socket) {
                socket.disconnect();
            }
        };
    }, [user.token]);
    
    // Memoize the context value so it doesn't change reference on every render
    const memoizedValue = useMemo(() => socket, [socket]);
    
    return <SocketContext.Provider value={memoizedValue}>{children}</SocketContext.Provider>
};