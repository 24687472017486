import 'reactflow/dist/style.css';

import { Menu, MenuItem } from '@mui/material';
import ReactFlow, { Background, Controls, Edge, MiniMap, Node, useViewport } from 'reactflow';
import { useEffect, useRef, useState } from 'react';

import { useRecipie } from '../../providers/Recipies.provider';
import { v4 } from 'uuid';

import recipeNodes from './Nodes';
import { JsonNodeDefinition, NodeDefinition, NodeElement } from './types';
import { useMounted } from '../../react-submodules/hooks/useMounted';
import { clipboardToNode } from './recipe.utils';


const nodeTypes = recipeNodes.reduce((acc, node) => {
    return { ...acc , [node.id]: node.element };
}, {} as Record<string, NodeElement>);


export const Recipies = () => {
    const { x, y, zoom } = useViewport();
    const mounted = useMounted()
    const { nodes, edges , onNodesChange , onEdgesChange , onConnect ,addNode , deleteEdge , deleteNode } = useRecipie();
    const reactFlowRef = useRef(null);
    const [ customNode , setCustomNode ] = useState<JsonNodeDefinition | null>( null );

    const [paneContextMenu, setPaneContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
        raw?: any;
      } | null>(null);
    
    const handlePaneContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        navigator.clipboard.readText().then(text => {
            if( mounted() ){
              setCustomNode( clipboardToNode( text ) )
            }
            
        });
        setPaneContextMenu(
          paneContextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
                raw: event
              }
            :  null,
        );
      };

    const [edgeContextMenu, setEdgeContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
        raw?: any;
        edge: Edge
    } | null>(null);

    const handleEdgeContextMenu = (event: React.MouseEvent , edge : Edge ) => {
        event.preventDefault();
        setEdgeContextMenu(
            edgeContextMenu === null
                ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY - 6,
                        raw: event,
                        edge
                    }
                : null
        );
    };

    const handleCloseEdge = () => {
        setEdgeContextMenu(null);
    };

    const [nodeContextMenu, setNodeContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
        raw?: any;
        node: Node;
    } | null>(null);

    const handleNodeContextMenu = (event: React.MouseEvent, node: Node) => {
        event.preventDefault();
        setNodeContextMenu(
            nodeContextMenu === null
                ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY - 6,
                        raw: event,
                        node
                    }
                : null
        );
    };

    const handleNodeClose = () => {
        setNodeContextMenu(null);
    };

      const project = (position: { x: number; y: number }) => {
        try {
            // @ts-ignore
            const bounds = reactFlowRef.current.getBoundingClientRect();
            return  {
                // @ts-ignore
                x: ( ( position.x - bounds.left  ) / zoom ) - ( x / zoom ) ,
                // @ts-ignore
                y: ( ( position.y - bounds.top ) / zoom ) - ( y / zoom )
            }
        } catch {
            return {
                x: 0 , y: 0
            }
        }
        
      }
      const handlePaneClose = () => {
        setPaneContextMenu(null);
      }

      const addNewNode = ( node: Pick<NodeDefinition , 'id' | 'title' | 'defaultData' > & Partial<NodeDefinition> ) => {
        addNode( { id: v4() , data: { title : node.title, ...(node.defaultData || {}) } , position: { x: 0 , y: 0 } , type: node.id } );
        handlePaneClose();
      }


      const deleteEdgeOption = () => {
        if( !edgeContextMenu ) return;
        
        deleteEdge( edgeContextMenu.edge.id );
        handleCloseEdge();
      }
      const deleteNodeOption = () => {
        if( !nodeContextMenu ) return;
        
        deleteNode( nodeContextMenu.node.id );
        handleNodeClose();
      }


    return <div style={{ height: '100%' }}>
    <ReactFlow
      ref={reactFlowRef}
      nodes={nodes}      
      onNodesChange={onNodesChange}
      edges={edges}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      onEdgeContextMenu={handleEdgeContextMenu}
      onNodeContextMenu={handleNodeContextMenu}
      onPaneContextMenu={handlePaneContextMenu}
      fitView
      nodeTypes={nodeTypes}
    >
        
      <Background />
      <Controls />
      <MiniMap nodeStrokeWidth={3} />

      
      <Menu
        open={nodeContextMenu !== null}
        onClose={handleNodeClose}
        anchorReference="anchorPosition"
        anchorPosition={
            nodeContextMenu !== null
            ? { top: nodeContextMenu.mouseY, left: nodeContextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={deleteNodeOption}>Delete</MenuItem>
      </Menu>


      <Menu
        open={edgeContextMenu !== null}
        onClose={handlePaneClose}
        anchorReference="anchorPosition"
        anchorPosition={
            edgeContextMenu !== null
            ? { top: edgeContextMenu.mouseY, left: edgeContextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={deleteEdgeOption}>Delete</MenuItem>
      </Menu>

      <Menu
        open={paneContextMenu !== null}
        onClose={handlePaneClose}
        anchorReference="anchorPosition"
        anchorPosition={
          paneContextMenu !== null
            ? { top: paneContextMenu.mouseY, left: paneContextMenu.mouseX }
            : undefined
        }
      >
        { recipeNodes.map( node => <MenuItem key={ node.id } onClick={() => addNewNode( node )}>Add { node.title }</MenuItem> ) }
        { customNode && <MenuItem onClick={() => addNewNode( customNode )}>Add From Clipboard ({ customNode.title })</MenuItem> }
      </Menu>
    </ReactFlow>
  </div>
}