import { createContext } from "react";

export type CommonOuput<T = any> = {
    data: T,
    error?: string,
    page?: number,
    pageSize?: number,    
}



export type ValidDataTypes =  string | number | null | boolean | undefined | Record<string,any>;

interface Api {
    get: <T extends any = any>( path : string , params?: Record<any, ValidDataTypes > ) => Promise<CommonOuput<T>>,
    put: <T extends any = any>( path : string , params?: Record<any, ValidDataTypes > ) => Promise<CommonOuput<T>>,
    post: <T extends any = any>( path : string , params?: Record<any, ValidDataTypes > ) => Promise<CommonOuput<T>>,
    patch: <T extends any = any>( path : string , params?: Record<any, ValidDataTypes > ) => Promise<CommonOuput<T>>,
    delete: <T extends any = any>( path : string , params?: Record<any, ValidDataTypes > ) => Promise<CommonOuput<T>>,
    ready: boolean,
    token: string;
    fileUploaderUrl : string;
    online: boolean;
}

export const DEFAULT_API_PROPS : Api = {
    get: async () => ({data: [] as unknown as any }),
    put : async () => ({data: [] as unknown as any}),
    post : async () => ({data: [] as unknown as any}),
    patch : async () => ({data: [] as unknown as any}),
    delete : async () => ({data: [] as unknown as any}),
    online: false,
    ready: false,
    token: '',
    fileUploaderUrl: '',
}
export const ApiContext = createContext<Api>(DEFAULT_API_PROPS);