import { FC } from 'react';
import { Box, IconButton, Tooltip, useTheme } from '@mui/material';
import { InlineIcon } from "@iconify/react/dist/iconify.js";
import { CALENDAR_ICON } from "../../../constances/icons";
import { priorityToColor, priorityToIcon } from "../../TicketPartials/Priority";
import { AZURE_COLOR, SCHEDULE_COLOR, AZURE_COLOR_HOVER, SCHEDULE_COLOR_HOVER } from "../../../utils/schedule";
import { ExtendedUserItem, TimeclockItem, ScheduleInstance } from '../types';

export const UserIcons: FC<{ user: ExtendedUserItem }> = ({ user }) => {
  const theme = useTheme();
  
  return (
    <Box>
      {user.activeTimeclocks?.filter(tc => tc.ticket?.id).map((tc: TimeclockItem) => {
        return (
          <IconButton
            onClick={() => { window.open(`/ticketqueue/all/${tc.ticket?.id}`, `ticket_${tc.ticket?.id}`) }}
            size="small"
            sx={{
              color: priorityToColor(tc?.ticket?.priority || 5),
              backgroundColor: theme.palette.grey[200],
              width: '24px',
              height: '24px',
              marginRight: 0.25,
              '&:hover': {
                backgroundColor: theme.palette.grey[300],
              }
            }}
            key={tc.id}
          >
            <InlineIcon icon={priorityToIcon(tc?.ticket?.priority || 5)} />
          </IconButton>
        );
      })}
      {user?.activeScheduleInstances?.filter(si => si.available !== true).map((si: ScheduleInstance) => {
        return (
          <Tooltip title={`${si.title || si.AzureCalendar?.subject || si.SchedulePlan?.name}`} key={si.id}>
            <IconButton
              onClick={() => { window.open(`/schedule/daily`, 'schedule_daily') }}
              size="small"
              sx={{
                color: 'white',
                backgroundColor: si?.AzureCalendar?.subject ? AZURE_COLOR : SCHEDULE_COLOR,
                width: '24px',
                height: '24px',
                marginRight: 0.25,
                '&:hover': {
                  backgroundColor: si?.AzureCalendar?.subject ? AZURE_COLOR_HOVER : SCHEDULE_COLOR_HOVER,
                }
              }}
            >
              <InlineIcon icon={CALENDAR_ICON} />
            </IconButton>
          </Tooltip>
        );
      })}
    </Box>
  );
}; 