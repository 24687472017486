export const getApiEndpoint = ( service = "be") => {
    try {
        const endpoint = sessionStorage.getItem(`api_endpoint_${service}`);
        if( endpoint ){
            return endpoint;
        }
    } catch{}
    /*if( process && process?.env?.REACT_APP_API_ENDPOINT ){
         return process.env.REACT_APP_API_ENDPOINT;
    } */
    const { host } = window.location;
    if( /^localhost/.test( host ) ){
        if( host === "localhost:7839" ){
            return `https://${service}.staging.emsp.couleetech.network`;
        }
        if( host === "localhost:3501"|| host === "localhost:3502"){
            return `https://${service}.dev.emsp.couleetech.network`;
        }
        return "http://localhost:5480";
    }
    if( /^m\./.test( host )){
        return `https://${service}.${host.replace(/^m\./,'')}`;
    }
    if( host === "portal.enlightenedmsp.com"){
        return `https://${service}.enlightenedmsp.com`;
    }
    return `https://${service}.${host}`;
}