import { Icon } from "@iconify/react"
import { useNavigate, useParams } from "react-router-dom"
import { FunctionComponent, useEffect, useState } from "react"
import { useApi } from "../react-submodules/providers/Api/Api"
import { Button, Container, Paper } from "@mui/material"
import { StyledTable, TableColumnDef } from "../react-submodules/components/Table/Table"

const columns : TableColumnDef[] = [
    {
        field: 'name',
        flex: 1,
        headerName: 'Name'
    },
    {
        field: 'id',
        flex: 1,
        headerName: 'Custom Component',
        Element: ( { cell , row } ) => {
            return <Button onClick={()=>{} } variant="contained" color="primary">Custom</Button>
        }
    }]

export const RodsReport : FunctionComponent =  () => {
    const { id } = useParams<{id: string}>();
    const [ report  , setReport ] = useState( [] );
    const api = useApi();
    useEffect(() => {
        const getData = setTimeout(() => {
          api.get( `/reports/rods/${id}`).then( ( results ) => {
            setReport( results.data );
          });
        }, 500)
    
        return () => clearTimeout(getData)
      }, [])
    return <>
    { report.length > 0 && (
  <Container sx={{marginTop: 2 }}><Paper><StyledTable
  onClick={( row)=>{
    console.log( row );
  }}
  rows={report}
  columns={columns}
/>
</Paper>
</Container>
)
}
    </>
}