import { FC, PropsWithChildren, useMemo, useState } from "react";
import { useUser } from "../User";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { getApiEndpoint } from "../Api/api.utils";
import { setContext } from '@apollo/client/link/context';
export const Apollo : FC<PropsWithChildren> = ({children}) => {
    
    const API_ENDPOINT = getApiEndpoint();
    const user = useUser();
    /*const client = new ApolloClient({
        uri: `${API_ENDPOINT}/graphql`,
        cache: new InMemoryCache(),
    });*/
    // const [client, setClient] = useState<ApolloClient<any>>();

      
    const client = useMemo( () => {
        if( !user?.token ){
            return null;
        }
        const authLink = setContext((_, { headers }) => {
            const token = user?.token;
            return {
              headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '', // Add the authorization header
              },
            };
          });
          const httpLink = createHttpLink({
            uri: `${API_ENDPOINT}/graphql`, // Your GraphQL endpoint
            credentials: 'same-origin', // Set credentials option
          });
        return new ApolloClient({
            cache: new InMemoryCache(),
            link: authLink.concat(httpLink),
        });
    }, [API_ENDPOINT , user?.token]);

    if( !client ){
        return <></>;
    }
    return <ApolloProvider client={client}>
        {children}
    </ApolloProvider>
}