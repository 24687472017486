import { DateTime } from "luxon";
import { useSearchParam } from "../../react-submodules/utils/reactrouter";
import { useApiSearchResults } from "../../hooks/useApiSearchResults";
import { useEffect, useMemo, useState } from "react";
import { SortColumn, StyledTable } from "../../react-submodules/components/Table";
import { gql, useQuery } from "@apollo/client";
import { QueryProps } from "../../coretypes/queries";
import { Button, Tooltip, Paper, Grid, TextField, Alert } from "@mui/material";
import { DurationView } from "../../react-submodules/components/DurationView";
import { ApiProvider } from "../../react-submodules/providers/Api";
import { TicketApiProvider } from "../../react-submodules/providers/TicketApiProvider";
import { Company } from "../../react-submodules/components/TicketPartials/Company";
import { Resource } from "../../react-submodules/components/TicketPartials/Resource";
import { EditTimepunch } from "./EditTimepunch";
const columns : any[] = [
    {
        field: "id",
        headerName: "ID",
    },
    {
        field: "user",
        headerName: "User",
        Element: ( { row }: { row: any } ) => {
            return <span>{ row?.user?.firstName } { row?.user?.lastName }</span>
        }
    }, {
        field: "ticket",
        headerName: "Ticket",
        Element: ( { row }: { row: any } ) => {
            return <Tooltip title={row?.ticket?.title}><span><Button variant="contained" color="primary" onClick={() => {
                window.open( `/ticketqueue/all/${row?.ticket?.id}` , '_blank' );
            }}>{ row?.ticket?.ticketNumber }</Button> - { row?.ticket?.title?.substring( 0 , 30 ) }{row?.ticket?.title?.length > 30 ? '...' : ''}</span></Tooltip>
        }
    },
    {
        field: "companyName",
        headerName: "Company",
        Element: ( { cell , row }: { cell: string , row: any } ) => {
            return <span>{!row?.ticket?.autotaskCompany?.coreCompanyId && <Alert severity="warning">Not tied to a core company</Alert>}{cell}</span>
        }
    },
    {
        field: "start",
        headerName: "Start",
        Element: ( { cell }: { cell: string } ) => {
            return <span>{ DateTime.fromISO( cell ).toLocaleString( DateTime.DATETIME_MED ) }</span>
        }
    } , {
        field: "end",
        headerName: "End",
        Element: ( { cell }: { cell: string } ) => {
            return <span>{ DateTime.fromISO( cell ).toLocaleString( DateTime.DATETIME_MED ) }</span>
        }
    } , {
        field: "duration",
        headerName: "Duration",
        Element: ( { cell }: { cell: number } ) => {
            return <span><DurationView duration={cell} durationUnit="m" /></span>
        }
    } , {
        field: "actions",
        headerName: "Actions",
        Element: ( { row , setEditTimepunchId  }: { row: any , setEditTimepunchId : ( id: number ) => void } ) => {
            return <Button variant="contained" color="primary" onClick={() => {
                setEditTimepunchId( row.id );
            }}>Edit</Button>
        }
    }
]

const GET_TIMEPUNCHES = gql`
    query TimePunches($start: TimeQueryProp, $page: Int, $limit: Int , $ticket : GetTicketsArgs , $user : GetUsersArgs ) {
        timeclocks(start: $start, page: $page, limit: $limit , ticket : $ticket , user : $user) {
            data {
                id
            start
            end
            ticketId
            todoId
            coreUserId
            autotaskUserId
            message
            user {
                id
                firstName
                lastName
                email
            }
            ticket {
                id
                title
                ticketNumber
                autotaskCompany {
                companyName
                coreCompanyId
                }
            }
            }
            totalCount
            page
            limit
            
        }
    }
`;


export const TimePunches = () => {
    const [ startdate , setStartdate ] = useSearchParam<string>( 'startdate' , { typeProp: 'string' , defaultValue: DateTime.now().toFormat('yyyy-MM-dd') } );
    const [ enddate , setEnddate ] = useSearchParam<string>( 'enddate' , { typeProp: 'string' , defaultValue: DateTime.now().toFormat('yyyy-MM-dd') } );
    const [ page , setPage ] = useSearchParam<number>( 'page' , { typeProp: 'number' , defaultValue: 0 } );
    const [ limit , setLimit ] = useSearchParam<number>( 'limit' , { typeProp: 'number' , defaultValue: 25 } );
    const [ companyId , setCompanyId ] = useSearchParam<number | null>( 'companyId' , { typeProp: 'number' } );
    const [ resourceId , setResourceId ] = useSearchParam<number | null>( 'resourceId' , { typeProp: 'number' } );
    const [ editTimepunchId , setEditTimepunchId ] = useState<number | null>( null );
    // const [ startQuery , setStartQuery ] = useState<QueryProps>({});
    const startQuery : QueryProps = useMemo(() => {
        const query : QueryProps = {};
        if( startdate ){
            query.gte = DateTime.fromISO( startdate ).startOf('day').toISO() ;
        }
        if( enddate ){
            query.lte = DateTime.fromISO( enddate ).endOf('day').toISO() ;
        }
        return query;
    } , [ startdate , enddate  ] );
    const ticketQuery : any = useMemo(() => {
        if( companyId ){
            return { 
                autotaskCompany: {
                    id: { eq : companyId }
                }
            };
        }
        return undefined;
    } , [ companyId ] );
    const userQuery : any = useMemo(() => {
        if( resourceId ){
            return { 
                autotaskId: { eq : resourceId }
            };
        }
        return undefined;
    } , [ resourceId ] );


    const { loading, error, data , refetch } = useQuery(GET_TIMEPUNCHES, { variables: { start: startQuery , ticket : ticketQuery , user : userQuery , page: page , limit: limit } });
    const timeclocks = data?.timeclocks?.data || [];
    const totalCount = data?.timeclocks?.totalCount || 0;

    useEffect( () => {
        refetch();
    },[page , limit] );

    const rows = useMemo( () => {
        return timeclocks.map( ( timeclock : any ) => {
            const start = DateTime.fromISO( timeclock.start );
            const end = DateTime.fromISO( timeclock.end );
            const duration = end.diff( start , 'minutes' ).toObject().minutes;
            return {
                ...timeclock,
                companyName: timeclock?.ticket?.autotaskCompany?.companyName,
                ticketNumber: timeclock?.ticket?.ticketNumber,
                user: timeclock.user,
                ticket: timeclock.ticket,
                duration: duration
            }
        } )
    } , [ JSON.stringify( timeclocks ) ] );

    return <>
    <TicketApiProvider queueId="all" getNextTicket={() => {}}>
        <EditTimepunch id={editTimepunchId} onClose={() => {
            setEditTimepunchId( null );
        }} />
    <Paper elevation={2} sx={{padding: 1}}>
            <Grid container spacing={2}>
                
                <Grid item sm={12} md={3} lg={2}>
                    <TextField fullWidth label="Start Date" type="date" value={startdate} onChange={( e)=>{ setStartdate( e.target.value)}} />     
                </Grid>
                <Grid item sm={12} md={3} lg={2}>
                <TextField fullWidth label="End Date" type="date" value={enddate} onChange={( e)=>{ setEnddate( e.target.value)}} />     
                </Grid>
                <Grid item sm={12} md={3} lg={2}>
                    <Company companyID={companyId || undefined} edit={true} onChange={(company) => setCompanyId(company?.id ?? null)} />
                </Grid>
                <Grid item sm={12} md={3} lg={2}>
                    <Resource resourceID={resourceId || undefined} edit={true} onChange={(resource) => setResourceId(resource?.id ?? null)} />
                </Grid>
            </Grid>
        </Paper>

    { totalCount && ![ totalCount , page , limit ].includes( undefined) &&  Array.isArray( timeclocks ) && timeclocks.length > 0 && <StyledTable 
        totalCount={totalCount} 
        setPage={setPage} 
        setPageSize={setLimit} 
        page={page || 0 } 
        limit={limit || 25 } 
        enablePagination 
        rows={rows} 
        columns={columns} 
        elementProps={{ setEditTimepunchId }}
        
        /> }
        {/*   */}
        </TicketApiProvider></>

}