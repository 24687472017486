import { DateTime } from "luxon";
import { LocationWithDistance } from "./types";

export const roundDriveTime = ( timeInSeconds : number ) => {
    const minutes = timeInSeconds / 60;
    const roundToQuarterHour = Math.ceil( minutes / 15 ) * 15;
    return (roundToQuarterHour || 15 ) * 60;
    // return Math.round( num * 100 ) / 100;
}

const getleaveOfficeTime  = ( driveTime : number, scheduledDate : string , direction: 'standard' | 'drivestart' | 'driveend' = 'standard' , totalTimeInSeconds: number ) => {
    switch( direction){
        default:
        case 'standard' :
            return DateTime.fromISO( scheduledDate || '').minus({seconds: driveTime});
        case 'drivestart' :
            return DateTime.fromISO( scheduledDate || '');
        case 'driveend' :
            return DateTime.fromISO( scheduledDate || '').minus({seconds: totalTimeInSeconds});
    }
}


export const getBaseTimes = ( 
    beforelocation: LocationWithDistance , 
    afterlocation: LocationWithDistance , 
    scheduledDate : string,
    estimatedTimeRequired : number | null,
    direction: 'standard' | 'drivestart' | 'driveend' = 'standard'
 ) => {
        const driveTimeBefore = roundDriveTime(beforelocation.duration.value);
        const driveTimeAfter = roundDriveTime(afterlocation.duration.value);
        const totalTimeInSeconds = ((estimatedTimeRequired || 0) * 60 * 60) + driveTimeBefore + driveTimeAfter;

        
        
        DateTime.fromISO( scheduledDate || '');
        const leaveOfficeTime = getleaveOfficeTime(driveTimeBefore, scheduledDate, direction, totalTimeInSeconds);

        const ariveOnsiteTime = leaveOfficeTime.plus({seconds: driveTimeBefore});
        const leaveOnstieTime = ariveOnsiteTime.plus({hours: estimatedTimeRequired || 1 });
        const ariveOfficeTime = leaveOnstieTime.plus({seconds: driveTimeAfter});
        return {
            driveTimeBefore,
            driveTimeAfter,
            leaveOfficeTime,
            ariveOnsiteTime,
            leaveOnstieTime,
            ariveOfficeTime
        }
}

export const modifiedEvents = ( events : any[] ) => {
    return events.map( (e) => {
        return {
            ...e,
            start : DateTime.fromISO(e.start || '').minus({ hours : e.estimatedTimeRequired || 0}).toISO(),
            end : DateTime.fromISO(e.end || '').plus({ hours : e.estimatedTimeRequired || 0}).toISO()
        }
    })
}
