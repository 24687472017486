import { AnyBadge, AnyBadgeSize } from "../AnyBadge";
import { Box, Typography } from "@mui/material";

import { FC } from "react";
import { Icon } from "@iconify/react";
import { useTicket } from "../../providers/TicketProvider";

type BadgeAndNameProps = {
    displayName: string,
    colorkey?: string,
    color?: string
    phoneExtension?: string | null
    email?: string | null
    phone?: string| null
    cell?: string| null
    size?: AnyBadgeSize
}



export const BadgeAndName : FC<BadgeAndNameProps> = ({ displayName , colorkey , color , email , phone , cell , size , phoneExtension }) => {
    const { ticket } = useTicket();
    const subject = ticket && `${ticket.ticketNumber} - ${ticket.title}`;
    return <Box sx={{display: 'flex' , 'flexDirection' : 'row'}}  >
    <Box><AnyBadge size={size} name={displayName} colorkey={colorkey||undefined} color={color} /></Box>
    <Box sx={{paddingTop: '2px' , paddingLeft : '4px'}}><Typography variant="body2">{displayName}</Typography></Box>
    { email && <Box sx={{marginLeft: 1}}><a target={`mail_${ticket?.id}`} href={`https://outlook.office.com/mail/helpdesk@coulee.tech/deeplink/compose?bcc=helpdesk.COULEE.TECH@email.autotask.net&to=${email},helpdesk@coulee.tech&${subject && `subject=${subject}`}`} >
        <AnyBadge size="small" name={email} customkey={<Icon color="white" fontSize="12"  icon="ic:baseline-email"/>} color="#00A4EF" />
        </a></Box>}
    { phone && <Box sx={{marginLeft: 1}}><a target={`mail_${ticket?.id}`} href={`tel:${phone}`} >
        <AnyBadge size="small" name={phone} customkey={<><Icon color="white" fontSize="12"  icon="ph:phone-fill"/></>} color="#339966" />
        
    </a>
    { phoneExtension && <Box sx={{marginLeft: 1}}><Typography variant="caption" fontSize="10px">Ext:{phoneExtension}</Typography></Box>}
    </Box>}
    
    { cell && <Box sx={{marginLeft: 1}}><a target={`mail_${ticket?.id}`} href={`tel:${cell}`} >
        <AnyBadge size="small" name={cell} customkey={<Icon color="white" fontSize="12"  icon="heroicons-solid:device-mobile"/>} color="#339966" />
    </a></Box>}
   <Box sx={{flexGrow: 1 , paddingTop: '2px' , paddingLeft : '4px'}}><Typography variant="body2">&nbsp;</Typography></Box>
   
</Box>;
}