
import { Box, Button, ButtonGroup, Container, Grid, Paper, Switch, TextField, Tooltip, Typography } from "@mui/material"
import { Chart } from "react-google-charts";
import { useSearchParam } from "../../../react-submodules/utils/reactrouter";
import { gql, useQuery } from "@apollo/client";
import { DateTime } from "luxon";
import { useMemo } from "react";

const GET_USER_STATUS_QUERY = gql`
query GetUserStatusesAfterDate($start: DateTime , $end: DateTime ) {
  userstatuses(
      start: { gte: $start , lte: $end  } , isOnCall: { eq : true } , limit : 100000
  ) {
    data {
        id
      start
      end
      isOnCall
      user {
        firstName
        lastName
        id
      }    
  }
}
}`



export const OnCall = () => {
    const [ date , setDate ] = useSearchParam<string>( 'date' , { typeProp: 'string' , defaultValue: DateTime.now().toISODate() } );
    const start = useMemo ( () => DateTime.fromISO( date || new Date().toISOString() ).startOf( 'day' ).toISO() , [ date ] );
    const end = useMemo ( () => DateTime.fromISO( date || new Date().toISOString() ).endOf( 'day' ).toISO() , [ date ] );
    const { data , loading } = useQuery( GET_USER_STATUS_QUERY , { variables: { start , end } } );
    const users = useMemo( () => {
        return data?.userstatuses?.data.map( (d : any ) => d.user ).filter( ( u : any , i : any , a : any ) => a.findIndex( ( t : any ) => t.id === u.id ) === i )
    } , [ data ] );
    const chartdata = useMemo( () => {
        return ( data?.userstatuses?.data || [] ).map( ( d : any ) => [ `${d.user.firstName} ${d.user.lastName}` ,  new Date( d.start ) , new Date( d.end ) ] )
    } , [ JSON.stringify( data?.userstatuses)]);
    return <>
    <Paper elevation={2} sx={{padding: 1}}>
            <Grid container spacing={2}>
                <Grid item sm={12} md={6} lg={4}>
                    <TextField fullWidth label="Day" type="date" value={date} onChange={( e)=>{ setDate( e.target.value)}} />     
                </Grid>
            </Grid>
        </Paper>
        <Paper>
        <Chart
        chartType="Timeline"
        data={[[ { type : 'string' , id : 'User' }, { type: 'date' , id : 'Start' }, { type: 'date' , id : 'End' }], ...chartdata]}
        width="100%"
        height="400px"
        options={{
          timeline: {
            colorByRowLabel: true,
          },
        }}
      />
    </Paper>
    </>
}