import { Button, Stack } from "@mui/material";
import { FC } from "react";
import { LabeledBox } from "../LabeldBox";
import { useGetChildTicketsQuery } from "./gql/GetChildTickets.generated";

interface Ticket {
    id?: number | null;
    ticketNumber: string;
    title: string;
}

export const ChildTicketButtons: FC<{ ticketId: number | null }> = ({ ticketId }) => {
    const { data } = useGetChildTicketsQuery({
        variables: { id: Math.floor(ticketId || 0) },
        skip: ticketId === null,
        fetchPolicy: 'cache-and-network',
    });

    const childTickets = data?.getAutotaskTicketsById?.ChildTickets || [];

    if (childTickets.length === 0) {
        return null;
    }

    return (
        <LabeledBox sx={{ margin: 2 }} label="Child Tickets">
            <Stack spacing={1}>
                {childTickets.map((ticket: Ticket) => ticket.id && (
                    <Button
                        key={ticket.id}
                        color="info"
                        fullWidth
                        variant="contained"
                        size="small"
                        onClick={() => {
                            window.open(`/ticketqueue/tickets/${ticket.id}`, "_blank");
                        }}
                    >
                        {ticket.ticketNumber} - {ticket.title}
                    </Button>
                ))}
            </Stack>
        </LabeledBox>
    );
}; 