import { FC, useState } from "react";
import { 
    Box, 
    Button, 
    TextField, 
    Typography, 
    TablePagination, 
    Paper,
    InputAdornment
} from "@mui/material";
import { useFindCoreFilePaginatedQuery } from "./gql/GetFiles.generated";
import { FileUploader } from "../FileUploader/FileUploader";
import { FileView } from "./FileView";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

interface FilesViewProps {
    coredeviceSoftwareId?: number;
    coreCompanyId?: number;
    isClient?: boolean;
}

export const FilesView: FC<FilesViewProps> = ({ coredeviceSoftwareId, coreCompanyId, isClient = false }) => {
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(25);
    const [search, setSearch] = useState<string>("");
    const [uploadDialogOpen, setUploadDialogOpen] = useState<boolean>(false);

    const { data, loading : loadingTrue, refetch } = useFindCoreFilePaginatedQuery({
        variables: {
            coredeviceSoftwareId: coredeviceSoftwareId ? coredeviceSoftwareId : undefined,
            coreCompanyId: coreCompanyId ? coreCompanyId : undefined,
            page,
            limit
        },
        fetchPolicy: "cache-and-network"
    });
    const loading = loadingTrue && !Array.isArray(data?.findCoreFilePaginated?.data);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handlePageChange = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLimit(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRefresh = () => {
        refetch();
    };

    const files = data?.findCoreFilePaginated.data || [];
    const totalCount = data?.findCoreFilePaginated.totalCount || 0;

    const filteredFiles = search.trim() === "" 
        ? files 
        : files.filter(file => 
            file.name?.toLowerCase().includes(search.toLowerCase()) || 
            file.contentType.toLowerCase().includes(search.toLowerCase())
        );

    return (
        <Paper sx={{ p: 2, height: "100%" }}>
            <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                    <Typography variant="h5">Files</Typography>
                    <Button 
                        variant="contained" 
                        startIcon={<AddIcon />}
                        onClick={() => setUploadDialogOpen(true)}
                    >
                        Upload File
                    </Button>
                </Box>

                <TextField
                    placeholder="Search files..."
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={search}
                    onChange={handleSearchChange}
                    sx={{ mb: 2 }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />

                <Box sx={{ flexGrow: 1, overflowY: "auto", mb: 2 }}>
                    {loading && <Typography>Loading files...</Typography>}
                    
                    {!loading && filteredFiles.length === 0 && (
                        <Typography align="center" sx={{ py: 4 }}>
                            No files found.
                        </Typography>
                    )}

                    {!loading && filteredFiles.length > 0 && (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                            {filteredFiles.map((file) => (
                                <FileView 
                                    key={file.id} 
                                    file={file} 
                                    onFileUpdated={handleRefresh}
                                    isClient={isClient}
                                />
                            ))}
                        </Box>
                    )}
                </Box>

                <TablePagination
                    component="div"
                    count={totalCount}
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleLimitChange}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                />

                {coredeviceSoftwareId && (
                    <FileUploader
                        open={uploadDialogOpen}
                        onClose={() => {
                            setUploadDialogOpen(false);
                            handleRefresh();
                        }}
                        coredeviceSoftwareId={coredeviceSoftwareId}
                    />
                )}
                
                {coreCompanyId && !coredeviceSoftwareId && (
                    <FileUploader
                        open={uploadDialogOpen}
                        onClose={() => {
                            setUploadDialogOpen(false);
                            handleRefresh();
                        }}
                        coreCompanyId={coreCompanyId}
                    />
                )}
            </Box>
        </Paper>
    );
};