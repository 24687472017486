import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteScheduleInstanceMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteScheduleInstanceMutation = { __typename?: 'Mutation', deleteScheduleInstance: boolean };


export const DeleteScheduleInstanceDocument = gql`
    mutation DeleteScheduleInstance($id: Int!) {
  deleteScheduleInstance(id: $id)
}
    `;
export type DeleteScheduleInstanceMutationFn = Apollo.MutationFunction<DeleteScheduleInstanceMutation, DeleteScheduleInstanceMutationVariables>;

/**
 * __useDeleteScheduleInstanceMutation__
 *
 * To run a mutation, you first call `useDeleteScheduleInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduleInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduleInstanceMutation, { data, loading, error }] = useDeleteScheduleInstanceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScheduleInstanceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteScheduleInstanceMutation, DeleteScheduleInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteScheduleInstanceMutation, DeleteScheduleInstanceMutationVariables>(DeleteScheduleInstanceDocument, options);
      }
export type DeleteScheduleInstanceMutationHookResult = ReturnType<typeof useDeleteScheduleInstanceMutation>;
export type DeleteScheduleInstanceMutationResult = Apollo.MutationResult<DeleteScheduleInstanceMutation>;
export type DeleteScheduleInstanceMutationOptions = Apollo.BaseMutationOptions<DeleteScheduleInstanceMutation, DeleteScheduleInstanceMutationVariables>;