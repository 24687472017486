import { CoreUser } from "../../../types/core";
import { LocationWithDistance, SchedulingWizardState } from "../types";
import { getBaseTimes } from "../utils";

export const eventsToMakeNew = ( location : LocationWithDistance , state : SchedulingWizardState , selectedTech : CoreUser ) => {
    const { driveTimeBefore, driveTimeAfter, leaveOfficeTime , ariveOnsiteTime , leaveOnstieTime , ariveOfficeTime } = getBaseTimes( location , location , state.scheduledDate || '' , state.estimatedTimeRequired);
    return [{
        start : leaveOfficeTime.toISO(),
        end : ariveOnsiteTime.toISO(),
        resourceId : selectedTech.email,
        coreUserId : selectedTech.id,
        display: 'background',
        title : `Drive To Location`,
        
        color: 'black',
        type: 'drive',
        lengthInMinutes: Math.round( driveTimeBefore / 60 )
    },{
        start : ariveOnsiteTime.toISO(),
        end : leaveOnstieTime.toISO(),
        resourceId : selectedTech.email,
        coreUserId : selectedTech.id,
        title : `Arrive Onsite`,
        color: '#00ff00',
        textColor : '#333',
        type: 'onsite',
        lengthInMinutes: ( state.estimatedTimeRequired || 1) * 60
    } , {
        start : leaveOnstieTime.toISO(),
        display: 'background',
        end : ariveOfficeTime.toISO(),
        resourceId : selectedTech.email,
        coreUserId : selectedTech.id,
        title : `Drive Back`,
        color: 'black',
        type: 'drive',
        lengthInMinutes: Math.round( driveTimeAfter / 60 )
    }]
}

export const eventsToMakeRelative = (
    beforelocation : LocationWithDistance , 
    afterLocation: LocationWithDistance , 
    state : SchedulingWizardState , 
    selectedTech : CoreUser , 
    relativeTime : string , 
    direction: 'standard' | 'drivestart' | 'driveend' = 'standard'  ) => {

    const { driveTimeBefore, driveTimeAfter, leaveOfficeTime , ariveOnsiteTime , leaveOnstieTime , ariveOfficeTime } = getBaseTimes( beforelocation , afterLocation , relativeTime , state.estimatedTimeRequired, direction);
    return [{
        start : leaveOfficeTime.toISO(),
        end : ariveOnsiteTime.toISO(),
        resourceId : selectedTech.email,
        coreUserId : selectedTech.id,
        display: 'background',
        title : `Drive To Location`,
        
        color: 'black',
        type: 'drive',
        lengthInMinutes: Math.round( driveTimeBefore / 60 )
    },{
        start : ariveOnsiteTime.toISO(),
        end : leaveOnstieTime.toISO(),
        resourceId : selectedTech.email,
        coreUserId : selectedTech.id,
        title : `Arrive Onsite`,
        color: '#00ff00',
        textColor : '#333',
        type: 'onsite',
        lengthInMinutes: ( state.estimatedTimeRequired || 1) * 60
    } , {
        start : leaveOnstieTime.toISO(),
        display: 'background',
        end : ariveOfficeTime.toISO(),
        resourceId : selectedTech.email,
        coreUserId : selectedTech.id,
        title : `Drive Back`,
        color: 'black',
        type: 'drive',
        lengthInMinutes: Math.round( driveTimeAfter / 60 )
    }]
}
