import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTicketDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetTicketDetailsQuery = { __typename?: 'Query', getAutotaskTicketsById: { __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, title: string, description?: string | null, status: number, queueID?: number | null, priority?: number | null, coreCompanyId?: number | null, companyID?: number | null, coreContactId?: number | null, contactID?: number | null, contactlessStart?: boolean | null, nextActionDate?: string | null, nextActionTime?: number | null, nextActionType?: string | null, nextActionNote?: string | null, billable?: string | null } };


export const GetTicketDetailsDocument = gql`
    query GetTicketDetails($id: Int!) {
  getAutotaskTicketsById(id: $id) {
    id
    ticketNumber
    title
    description
    status
    queueID
    priority
    coreCompanyId
    companyID
    coreContactId
    contactID
    contactlessStart
    nextActionDate
    nextActionTime
    nextActionType
    nextActionNote
    billable
  }
}
    `;

/**
 * __useGetTicketDetailsQuery__
 *
 * To run a query within a React component, call `useGetTicketDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTicketDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetTicketDetailsQuery, GetTicketDetailsQueryVariables> & ({ variables: GetTicketDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTicketDetailsQuery, GetTicketDetailsQueryVariables>(GetTicketDetailsDocument, options);
      }
export function useGetTicketDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTicketDetailsQuery, GetTicketDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTicketDetailsQuery, GetTicketDetailsQueryVariables>(GetTicketDetailsDocument, options);
        }
export function useGetTicketDetailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTicketDetailsQuery, GetTicketDetailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTicketDetailsQuery, GetTicketDetailsQueryVariables>(GetTicketDetailsDocument, options);
        }
export type GetTicketDetailsQueryHookResult = ReturnType<typeof useGetTicketDetailsQuery>;
export type GetTicketDetailsLazyQueryHookResult = ReturnType<typeof useGetTicketDetailsLazyQuery>;
export type GetTicketDetailsSuspenseQueryHookResult = ReturnType<typeof useGetTicketDetailsSuspenseQuery>;
export type GetTicketDetailsQueryResult = Apollo.QueryResult<GetTicketDetailsQuery, GetTicketDetailsQueryVariables>;