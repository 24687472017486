import { Button, Container, Paper, TextField } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../react-submodules/providers/Api/Api";
import { StyledTable, TableColumnDef } from "../../react-submodules/components/Table";
import { NewInventoryWizard } from "./NewInventoryWizard";

const columns : TableColumnDef[] = [
    {
        field: 'name',
        flex: 1,
        sortable: true,
        headerName: 'Name'        
    },
    {
        field: 'quantity',
        flex: 1,
        sortable: true,
        headerName: 'Quantity'        
    },
    {
        field: 'internalCost',
        flex: 1,
        sortable: true,
        headerName: 'internalCost',
        Element: ( { cell , row } ) => {
            return <>${cell}</>
        }      
    }
]
export const InventoryList = () => {
    const nav = useNavigate();
    const [ search, setSearch ] = useState( '' );
    const [ refreshTick , setRefreshTick] = useState( 1);
    const [ companies  , setCompanies ] = useState( [] );
    const api = useApi();
    const refresh = () => {
      setRefreshTick( refreshTick * -1 )
    }

    useEffect(() => {
        const getData = setTimeout(() => {
          api.get( '/inventorymanagement/inventory' , { 'name:like' : search } ).then( ( results : { data : any } ) => {
            setCompanies( results.data );
          });
        }, 500)
    
        return () => clearTimeout(getData)
      }, [search , refreshTick ])
    return <><Container sx={{marginTop: 2 }}>
        <Paper elevation={2} sx={{padding: 1}}>
            <TextField fullWidth label="Search" value={search} onChange={( e)=>{ setSearch( e.target.value)}} />
        </Paper>
    </Container>
    { companies.length > 0 && (
  <Container sx={{marginTop: 2 }}><Paper><StyledTable
  title="Inventory"
  actions={<NewInventoryWizard refresh={refresh} / >}
  onClick={( row)=>{
    nav(`/inventory/${row.id}`);
  }}
  rows={companies}
  columns={columns}
/>
</Paper>
</Container>
)
}
    </>
}