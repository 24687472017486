import { Container } from "@mui/material"
import { PaginatedGraphqlQueryTable } from "../../../react-submodules/components/PaginatedGraphqlQueryTable/PaginatedGraphqlQueryTable";
import { FC } from "react";

const ScoreView : FC<{ cell : any , row : any , refresh?: () => any } & any > = ({ row}) => {
    return <div>{row?.score?.description}</div>
}

const TimeView : FC<{ cell : any , row : any , refresh?: () => any } & any > = ({ row}) => {
    return <div>{new Date(row?.createdDateTime).toLocaleString()}</div>
}

export const FeedbackInstances = () => {
    
        return  <Container>
        <PaginatedGraphqlQueryTable baseName="FeedbackInstance"  columns={[
            { field: 'id', type: 'Float', createRequired: false , primaryKey: true}, 
            { field: 'createdDateTime', type: 'Date', createRequired: false , CustomView: TimeView},
            { field: 'autotaskTicketId', type: 'Float', createRequired: true},
            { field: 'scoreId', type: 'Float', createRequired: true},
            { field: 'customerEmail', type: 'String', createRequired: true},
            { field: 'user', type: 'CUSTOM', visible: true , select: true, selectString: `score { description }` , CustomView: ScoreView },
            ]} />
    </Container>
    
}