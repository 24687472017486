import { Dialog, DialogContent } from "@mui/material";

import { Forms } from "./forms/Forms";
import { useMemo } from "react";
import { useRecipeApi } from "../providers/Recipe"

export const FormActionWatcher = () => {
    const { actions } = useRecipeApi();

    const action = useMemo(() => {
        return actions.find(a => a.action === 'form')
    }, [JSON.stringify( actions)]);
    if( !action || !action.formInstanceId ){
        return <></>
    }
    return <Dialog open={true}>
        <DialogContent>
        <Forms formInstanceId={action.formInstanceId} />
        </DialogContent>
    </Dialog>
}

