import * as React from 'react';

import { CSSObject, Theme, styled, useTheme } from '@mui/material/styles';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import { ActiveQueueToggle } from './customButtons/ActiveQueueToggle';
import { AppRoutes } from '../routes';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CreateTicket } from './tools/CreateTicket';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import { Icon } from '@iconify/react';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import MuiDrawer from '@mui/material/Drawer';
import { MyQuestions } from './customButtons/MyQuestions';
import { TicketSearch } from './customButtons/TicketSearch';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { UserRoles } from './customButtons/UserRoles';
import { useLayout } from '../react-submodules/providers/LayoutProvider';
import { KpiWatch } from '../react-submodules/components/KpiWatch/KpiWatch';
import { ChatBubble } from '../react-submodules/components/ChatBubble/ChatBubble';
import { useHeaderStore } from '../react-submodules/providers/Header';


const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  '@media print': {
    display: 'none',
  },
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));



interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  '@media print': {
    display: 'none',
  },
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
    '@media print': {
    display: 'none',
  },
  }),
);

const APP_NAME = 'Enlightened MSP';

export const MainLayout = () => {
  const { title } = useHeaderStore();
  const theme = useTheme();
  const nav = useNavigate();
  const [ dynamicOpen , setDynamicOpen ] = React.useState(false);
  const [ staticOpen , setOpen ] = React.useState(false);

  const [ debouncedOpen , setDebouncedOpen ] = React.useState(false);
  React.useEffect(()=>{
    const timer = setTimeout(()=>{
      setDebouncedOpen(dynamicOpen || staticOpen);
    }, 50);
    return ()=>clearTimeout(timer);
  },[dynamicOpen]);

  // Update document title on title change
  React.useEffect(() => {
    document.title = title || 'Enlightened MSP';
  }, [title]);

  // const [openOld, setOpen] = React.useState(false);
  const open = debouncedOpen || staticOpen ;
  const { buttons } = useLayout();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }} className={`sidepanel-${open?'open':'closed'}`}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {APP_NAME}
          </Typography>
          {buttons.map((button) => {
            return <Box key={button.key} sx={{marginLeft: 2 }}>
              { button?.onClick && <>
                <Button variant='contained' color="secondary" onClick={()=>{
                button?.onClick && button.onClick();
                }}>{button.label}</Button>
              </>}
              {  button.button_type === 'active_queue_toggle' && <ActiveQueueToggle /> }
              {  button.button_type === 'user_roles' && <UserRoles /> }
              { button.button_type === 'my_questions' && <MyQuestions />}
              { button.button_type === 'ticket_search' && <TicketSearch />}
            </Box>
          })}
          
          <KpiWatch />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List onMouseEnter={()=>{
          setDynamicOpen(true);
        }} onMouseLeave={()=>{
          setDynamicOpen(false);
        }}>
          {AppRoutes.filter( r => r.icon && r.title ).map((route, index) => (
            
            <ListItem onClick={()=>{
              nav(route.path||'');
            }}  key={index} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                    <Icon icon={route.icon|| 'fluent:glance-default-12-regular'} />
                </ListItemIcon>
                <ListItemText color='primary' primary={route.title} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3  , backgroundColor: theme.palette.background.default, minHeight: '100vh', '@media print': {
    backgroundColor: 'white', // or any other color you prefer for printing
  } }}>
        <DrawerHeader />
       <Outlet />
       <ChatBubble />
      </Box>
    </Box>
  );
}