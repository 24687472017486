import { Box, Button, FormControlLabel, Grid, MenuItem, Switch, TextField } from "@mui/material";
import { numberToTime, timeToNumber } from "../../utils/time.utils";

import { Billable } from "../TicketPartials/Billable";
import { Company } from "../TicketPartials/Company";
import { Contact } from "../TicketPartials/Contact";
import { Device } from "../TicketPartials/Device";
import { FC } from "react";
import { Priority } from "../TicketPartials/Priority";
import { Queue } from "../TicketPartials/Queue";
import { QueueTicket } from "../../types/ticketqueue";
import { Resource } from "../TicketPartials/Resource";
import { Status } from "../TicketPartials/Status";
import { useTicket } from "../../providers/TicketProvider";
import { MarkdownEditor } from "../MarkdownEditor/MarkdownEditor";

export const allUpdateFields : Array<keyof QueueTicket> = ['title','status' , 'description' , 'companyID' , 'contactID' , 'assignedResourceID' , 'nextActionNote' , 'nextActionDate' , 'queue' , 'priority' , 'billable' , 'contactlessStart' , 'configurationItemID']

export const TicketUpdateField  : FC<{
    field : string;
    value : any;
    onChange : ( value : any ) => any;
    currentTicket? : Partial<QueueTicket>;
    updateField( field : string , value : any ): any;
}>= ({ field , value , onChange , currentTicket , updateField }) => {
    
    switch( field ){
        case 'contactlessStart':
            return <FormControlLabel
            control={<Switch checked={Boolean(value)} onChange={( val ) => {
                onChange( val.target.checked );
            }}  />}
            label="Contactless Start ( Next action doesn't require a call )"
          />
        case 'assignedResourceID':
            return <Resource resourceID={value} edit onChange={( pl ) => {
                onChange( pl?.id || null );
            }} />
        case 'title':
            return <TextField size="small" label="Title" fullWidth value={value} onChange={( val ) => {
                onChange( val.target.value );
            }} />
        case 'status':
            return <Status status={`${value}`} edit onChange={( pl ) => {
                onChange( pl.value );
            }} />
        case 'nextActionNote':
            return <MarkdownEditor disableImageResize size="small" label="Next Action Note"  markdown={value} onChange={( val ) => {
                onChange( val );
            }} />
        case 'description':
            return <MarkdownEditor disableImageResize size="small" label="Description"  markdown={value} onChange={( val ) => {
                onChange( val );
            }} />
        case 'configurationItemID': 
        return <Device ticket={currentTicket}  configurationItemID={value} edit onChange={( val ) => {
            onChange( val.target.value );
        } } />
        case 'nextActionDate':
            return <>
            <Grid container>
                <Grid item xs={12} sm={4}>
                <TextField error={!value}   InputLabelProps={{ shrink: true }} size="small" label="Next Action Date" fullWidth type="date" value={value?.split("T")[0] || ""} onChange={( val ) => {
                onChange( val.target.value );
            }} />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField  InputLabelProps={{ shrink: true }} size="small" label="Next Action Time" fullWidth type="time" value={ numberToTime( currentTicket?.nextActionTime || NaN ) } 
                onChange={( val ) => {
                    console.log({val});
                    updateField( 'nextActionTime' , timeToNumber( val.target.value ) );
                }} />
            </Grid>
            <Grid item sm={4} >
            <TextField
    fullWidth
    size="small"
    select
    label="Type"
    value={currentTicket?.nextActionType || ""}
    onChange={(val)=>{
        updateField( 'nextActionType' , val.target.value );
    }}
  >
    
    {['FLEXABLE' , 'FIRM'].map((option) => (
      <MenuItem key={option} value={option} sx={{display: 'flex' , flexDirection: 'row'}}>{option}</MenuItem>
    ))}
  </TextField>
            </Grid>
            </Grid>
            <Button size="small" onClick={()=>{
                onChange( new Date( new Date().getTime() + ( 1000 * 60 * 60 * 24)).toISOString() );
            }}>Tomorrow</Button>
            </>
        case 'queue':
            return <Queue queue={`${value}`} edit onChange={( pl ) => {
                onChange( pl.value );
                //onChange( pl.value );
            }} />
        case 'priority':
            return <Priority priority={value} edit onChange={( pl ) => {
                onChange( pl.value );
            }} />
        case 'companyID':
            return <Company companyID={value} edit onChange={( pl ) => {
                onChange( typeof pl?.value === 'number' ? pl?.value : null );
            } } />
        case 'contactID':
            return <Contact ticket={currentTicket} contactID={value} edit onChange={( pl ) => {
                onChange( pl?.value || null );
                if( currentTicket?.companyID !== pl?.company.id && pl?.company.id ){
                    updateField( 'companyID' , pl?.company.id );
                }
            } } />
        case 'billable':
            return <Billable billable={value} edit onChange={( value ) => {
                onChange(value );
            } } />

    }
    return <></>
}