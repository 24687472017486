import { z } from 'zod';

export type AzureTeamsChat = {
    id: string;
    topic: string;
    createdDateTime: string;
    lastUpdatedDateTime: string;
    chatType: string;
    webUrl: string;
    tenantId: string;
    onlineMeetingInfo?: any;
}
export type AzureCalendarParticipant = {
    id: number;
    calendarId?: string;
    emailAddress?: string;
    responseStatus?: string;
    type?: string;
  };
export type AzureCalendar = {
    id: string;
    subject: string | null;
    bodyPreview: string;
    mailbox: string;
    createdDateTime: Date;
    lastModifiedDateTime: Date;
    isCancelled: boolean;
    isAllDay: boolean;
    start: Date;
    end: Date;
    webLink?: string;
    onlineMeetingUrl?: string;
    participants?: AzureCalendarParticipant[];
  }
export type AzureEmailParticipant = {
  id: string;
  emailId: string;
  userRole: string;
  emailAddress: string;
  name: string;
}

export const AzureEmailParticipantSchema = z.object({
  id: z.string(),
  emailId: z.string(),
  userRole: z.string(),
  emailAddress: z.string(),
  name: z.string(),
});

export type AzureEmailAttachment = {
  id: string;
  name: string;
  contentType: string;
  contentId: string;
  emailId: string;
  contentLocation: string;
  isInline: boolean;
};

export const AzureEmailAttachmentSchema = z.object({
  id: z.string(),
  name: z.string(),
  contentType: z.string(),
  contentId: z.string(),
  emailId: z.string(),
  contentLocation: z.string(),
  isInline: z.boolean(),
});

export const isAzureEmail = ( obj : any ) : obj is AzureEmail => {
    return obj.id  && obj.mailbox && obj.subject;
}


export type AzureEmail = {
    id: string;
    parentFolderId: string;
    conversationId: string;
    conversationIndex: string;
    mailbox: string;
    subject: string;
    bodyPreview: string;
    bodyHtml: string;
    tenantId: string;
    bodyMarkdown: string;
    markdownComplete: boolean;
    receivedDateTime: string;
    createdDateTime: string;
    lastModifiedDateTime: string;
    attachmentsSynced: boolean;
    autotaskTicketId: number;
    attachments: AzureEmailAttachment[];
    participants: AzureEmailParticipant[];
  };

const isoDateString = z.string().datetime( { offset: true } );

export const AzureEmailSchema = z.object({
  id: z.string(),
  parentFolderId: z.string(),
  conversationId: z.string(),
  conversationIndex: z.string(),
  mailbox: z.string(),
  subject: z.string(),
  bodyPreview: z.string(),
  bodyHtml: z.string(),
  tenantId: z.string(),
  bodyMarkdown: z.string(),
  markdownComplete: z.boolean(),
  receivedDateTime: isoDateString,
  createdDateTime: isoDateString,
  lastModifiedDateTime: isoDateString,
  attachmentsSynced: z.boolean(),
  autotaskTicketId: z.number(),
  attachments: z.array(AzureEmailAttachmentSchema),
  participants: z.array(AzureEmailParticipantSchema),
  coreCompanyId: z.number(),
});