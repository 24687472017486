import { Edge, Node } from "reactflow"
import { z } from "zod"
import { GetContext } from "../types";
import { baseCoreRecipeContextSchema } from "../Nodes/utils";
import { QueueTicketSchema } from "../../../react-submodules/types/ticketqueue";

export const getFormNodeContext : GetContext = ( nodeId: string , nodes: Node[] , edges: Edge[] ) => {
    return baseCoreRecipeContextSchema.extend({
        context: z.literal('form')
    });
}