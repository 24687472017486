import { FC, useEffect, useMemo, useState } from "react";
import { Button, Chip, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useMyTimepunchesQuery } from "./gql/MyTimepunches.generated";
import { useOtherUsersTimepunchesQuery } from "./gql/OtherUsersTimepunches.generated";
import { useClockInMutation, useClockOutMutation } from "./gql/TimeclockMutations.generated";
import { MarkdownEditor } from "../../MarkdownEditor/MarkdownEditor";
import { useUser } from "../../../providers/User";
import { Box } from "@mui/system";
import { Icon, InlineIcon } from "@iconify/react";
import { AnyBadge } from "../../AnyBadge";

const OtherUserChip = ({ punch }: { punch: any }) => {
    const [now, setNow] = useState(new Date().getTime());
    
    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date().getTime());
        }, 15000);
        return () => {
            clearInterval(interval);
        }
    }, []);
    
    const punchTime = useMemo(() => {
        if (!punch) return null;
        const start = punch?.start ? new Date(punch.start).getTime() : 0;

        const secondsFull = (now - start) / 1000;
        const seconds = secondsFull < 0 ? 0 : secondsFull;
        const minutes = Math.floor(seconds / 60) % 60;
        const hours = Math.floor(seconds / 60 / 60);
        if (hours) {
            return `${hours}h ${minutes}m`
        }
        return `${minutes}m`;
    }, [punch?.start, now]);
    
    return <Chip 
        size="small" 
        avatar={<AnyBadge 
            size="medium" 
            colorkey={punch?.user?.id || ""} 
            name={`${punch.user?.firstName} ${punch.user?.lastName}`} 
        />} 
        label={<><InlineIcon icon="mdi:clock" />&nbsp;{punchTime}</>}
    />
}

export const OtherUsersTimeclock: FC<{ ticketId: number }> = ({ ticketId }) => {
    const user = useUser();
    
    const { data } = useOtherUsersTimepunchesQuery({
        variables: { 
            ticketId,
            userId: user.tokenParsed?.sub || ''
        }
    });
    
    const punches = useMemo(() => {
        return data?.timeclocks?.data || [];
    }, [data]);
    
    return <>{punches.map(punch => <OtherUserChip key={punch.id} punch={punch} />)}</>
}

export const MyTicketTimeclock: FC<{ ticketId: number }> = ({ ticketId }) => {
    const user = useUser();
    const [clockOutDialog, setClockOutDialog] = useState(false);
    const [message, setMessage] = useState('');
    const [now, setNow] = useState(new Date().getTime());

    const { data, loading, error, refetch } = useMyTimepunchesQuery({
        variables: { 
            ticketId,
            userId: user.tokenParsed?.sub || ''
        },
        fetchPolicy: 'cache-and-network'
    });

    const [clockIn] = useClockInMutation();
    const [clockOut] = useClockOutMutation();

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date().getTime());
        }, 15000);
        return () => clearInterval(interval);
    }, []);

    const activePunch = useMemo(() => {
        if (!data?.timeclocks?.data?.length) return null;
        return data.timeclocks.data[0];
    }, [data]);

    const calculateTime = (start: string) => {
        const startTime = new Date(start).getTime();
        const secondsFull = (now - startTime) / 1000;
        const seconds = secondsFull < 0 ? 0 : secondsFull;
        const minutes = Math.floor(seconds / 60) % 60;
        const hours = Math.floor(seconds / 60 / 60);
        if (hours) {
            return `${hours}h ${minutes}m`;
        }
        return `${minutes}m`;
    };

    const punchTime = useMemo(() => {
        if (!activePunch?.start) return null;
        return calculateTime(activePunch.start);
    }, [activePunch?.start, now]);

    const handleClockIn = async () => {
        try {
            await clockIn({
                variables: { ticketId }
            });
            refetch();
        } catch (err) {
            console.error('Failed to clock in:', err);
        }
    };

    const handleClockOut = async () => {
        if (!activePunch?.id) return;
        try {
            await clockOut({
                variables: {
                    id: activePunch.id,
                    message,
                    end: new Date()
                }
            });
            refetch();
            setClockOutDialog(false);
        } catch (err) {
            console.error('Failed to clock out:', err);
        }
    };

    if (activePunch) {
        return (
            <>
                <Chip size="small" color="warning" label={punchTime} onClick={() => setClockOutDialog(true)} />
                <Dialog onClose={() => setClockOutDialog(false)} maxWidth="lg" open={clockOutDialog}>
                    <DialogContent sx={{ width: '600px' }} >
                        <MarkdownEditor label="Time Entry Message" markdown={message} onChange={setMessage} />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="success" onClick={handleClockOut}>Clock Out</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return <Button size="small" variant="contained" color="success" onClick={handleClockIn}>Clock In</Button>;
};

export const TicketTimeclock : FC<{ticketId: number}> = ({ticketId}) => {
    return <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <MyTicketTimeclock ticketId={ticketId} />
        <OtherUsersTimeclock ticketId={ticketId} />
    </Box>
}