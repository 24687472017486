import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Modal, Tooltip } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { Icon, InlineIcon } from "@iconify/react";

import { MyTicketSearch } from "../MyTicketSearch";
import { OtherUsersTimeclock } from "../OtherUsersTimeclock";
import { QueueTicket } from "../../types/ticketqueue";
import { StartRecipeDialog } from "../StartRecipeDialog/StartRecipeDialog";
import { TicketUpdateForm } from "../TicketUpdateForm/TicketUpdateForm";
import { Timeclock } from "../Timeclock/Timeclock";
import { allUpdateFields } from "../TicketUpdateForm/Fields";
import { useApi } from "../../providers/Api/Api";
import { useMounted } from "../../hooks/useMounted";
import { useTicket } from "../../providers/TicketProvider";
import { useTicketApi } from "../../providers/TicketApiProvider";

export const TicketQuickActions : FC<{ refreshTicket: () => any}> = ({ refreshTicket }) => {
    const [ syncing , setSyncing ] = useState( false );
    const [ recipeDialogOpen , setRecipeDialogOpen ] = useState( false );
    const mounted = useMounted();
    const [ openPia , setOpenPia] = useState( false );
    const { ticket , ticketPunch } = useTicket();
    const [ refreshPia , setRefreshPia ] = useState( false );
    useEffect(()=>{
        if( refreshPia ){
            setRefreshPia( false );
        }
    } , [ refreshPia ])
    const api = useApi();
    const { queueId ,recipeStarts } = useTicketApi();
    const [ formProps , setFormProps ] = useState< { fields: Array<keyof QueueTicket> , updates: Record<string,any>} | null >(null);
    
    const sync = async () => {
        setSyncing( true );
        await api.get(`/autotask/sync/ticket/${ticket?.id}`);
        if( mounted () ){
            setSyncing( false );
        }
        window.location.reload();
        
    }
    
    const actions = useMemo( () => {
        const actions : Array<{
            name: string;
            fields: Array<keyof QueueTicket>;
            updates: Record<string,any>;
        
        }> = [];
        
        if(  ticket?.statusId === 19){
            actions.push( {
                name: 'Reviewed Note',
                fields: ['status' , 'nextActionNote' , 'nextActionDate'],
                updates: { status: 32}
            })
        }
        if( ticket?.statusId === 1 ){
            const updates : Record<string,any> = { status: 30, nextActionDate : new Date().toISOString()  };
            // If in Triage, send to Tier 1
            if( ticket?.queueID === 29682833 ){
                updates.queue = 29683494;
            }
            actions.push( {
                name: 'Dispatch',
                fields: [ 'companyID' , 'contactID','title' , 'priority','status' , 'nextActionNote' , 'nextActionDate' , 'billable' , 'queue' , 'contactlessStart' , 'configurationItemID' ],
                updates
            })
        }

        actions.push( {
            name: 'Update',
            fields: allUpdateFields,
            updates: {}
        
        })
        return actions
    } , [ JSON.stringify( ticket ) , queueId ])

    return <>
    <Timeclock /> <OtherUsersTimeclock />
    
    {actions.map( ( action , i ) => {
            return <Button size="small" color="secondary" sx={{margin: 1}} variant="contained" key={i} onClick={() => {
                setFormProps( {  fields: action.fields , updates: action.updates} );
            }}>{action.name}</Button>
        }) }
        { formProps && <Dialog open={!!formProps} onClose={()=>{ setFormProps( null)}}>
            <DialogContent sx={{minWidth: '500px'}}>
                <Box>
                <TicketUpdateForm onClose={()=>{ setFormProps( null)}} fields={formProps.fields} updates={formProps.updates} />
                </Box>
            </DialogContent>
        </Dialog>}
        <StartRecipeDialog contextType="ticket" open={recipeDialogOpen} onClose={()=>{
            refreshTicket();
            setRecipeDialogOpen( false );
        }}  />

    </>
}