import { StartNode } from "./StartNode/StartNode";
import { NodeDefinition } from "../types";
import { EmailCategorization } from "./EmailCategorization";
import { EmailReceivedNode } from "./EmailReceived/EmailReceived";
import { getEmailReceivedContext } from "./EmailReceived/emailrecieved.functions";
import { EventEmitterNode } from "./EventEmitterNode";
import { EventListenerNode } from "./EventListenerNode";
import { getFilterContext } from "./FilterNode/filter.functions";
import { FilterNode } from "./FilterNode/FilterNode";

import { FormNode } from '../FormNode/FormNode';
import { ScheduleNode } from "./ScheduleNode";
import { SendEmailNode } from "./SendEmailNode/SendEmailNode";
import { TicketCreationNode } from "./TicketCreationNode";
import { TicketEditNode } from "./TicketEditNode/TicketEditNode";
import { TicketUpdatedNode } from "./TicketUpdated/TicketUpdated";
import { getTicketUpdatedContext } from "./TicketUpdated/ticketupdated.functions";
import { TodoNode } from "./TodoNode";
import { getParentContext } from "./utils";
import { getStartNodeContext } from "./StartNode/startnode.functions";
import { SingleTriggerNode } from "./SingleTriggerNode/SingleTriggerNode";
import { getEventListenerContext } from "./EventListenerNode/eventlistener.functions";
import { CreateCompanyNode } from "./CreateCompanyNode/CreateCompanyNode";
import { getFormNodeContext } from "../FormNode/FormNode.functions";

const recipeNodes: NodeDefinition[] = [
    {
        id: 'todo',
        title: 'Todo',
        element: TodoNode
    },
    {
        id: 'start',
        title: 'Start',
        element: StartNode,
        getContext: getStartNodeContext
    },
    {
        id: 'form',
        title: 'Form',
        element: FormNode,
        getContext: getFormNodeContext
    },
    {
        id: 'schedule',
        title: 'Schedule',
        element: ScheduleNode
    },
    {
        id: 'ticketCreation',
        title: 'Ticket Creation',
        element: TicketCreationNode,
        getContext: getParentContext
    },
    {
        id: 'ticketEdit',
        title: 'Ticket Edit',
        element: TicketEditNode
    },
    {
        id: 'eventListener',
        title: 'Event Listener',
        element: EventListenerNode,
        getContext: getEventListenerContext
    },
    {
        id: 'eventEmitter',
        title: 'Event Emitter',
        element: EventEmitterNode
    },
    {
        id: 'emailReceived',
        title: 'Email Received',
        element: EmailReceivedNode,
        getContext: getEmailReceivedContext
    },
    {
        id: 'filter',
        title: 'Filter',
        element: FilterNode,
        getContext: getFilterContext
    },
    {
        id: 'emailCategorization',
        title: 'Email Categorization',
        element: EmailCategorization,
        getContext: getParentContext
    },
    {
        id: 'ticketUpdated',
        title: 'Ticket Updated',
        element: TicketUpdatedNode,
        getContext: getTicketUpdatedContext
    },
    {
        id: 'sendEmail',
        title: 'Send Email',
        element: SendEmailNode,
        getContext: getParentContext
    },
    {
        id: 'singleTrigger',
        title: 'Single Trigger',
        element: SingleTriggerNode,
        getContext: getParentContext
    },
    {
        id: 'createCompany',
        title: 'Create Company',
        element: CreateCompanyNode,
        getContext: getParentContext
    }
];

export default recipeNodes;