import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTodoGroupMutationVariables = Types.Exact<{
  title?: Types.InputMaybe<Types.Scalars['String']['input']>;
  description?: Types.InputMaybe<Types.Scalars['String']['input']>;
  atTicketId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type CreateTodoGroupMutation = { __typename?: 'Mutation', createTodosGroup: { __typename?: 'TodosGroup', createdDateTime: any, createdByCoreUserId?: string | null, updatedDateTime: any, updatedByCoreUserId?: string | null, id?: number | null, title?: string | null, description?: string | null, atTicketId?: number | null } };


export const CreateTodoGroupDocument = gql`
    mutation CreateTodoGroup($title: String, $description: String, $atTicketId: Int) {
  createTodosGroup(
    title: $title
    description: $description
    atTicketId: $atTicketId
  ) {
    createdDateTime
    createdByCoreUserId
    updatedDateTime
    updatedByCoreUserId
    id
    title
    description
    atTicketId
  }
}
    `;
export type CreateTodoGroupMutationFn = Apollo.MutationFunction<CreateTodoGroupMutation, CreateTodoGroupMutationVariables>;

/**
 * __useCreateTodoGroupMutation__
 *
 * To run a mutation, you first call `useCreateTodoGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTodoGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTodoGroupMutation, { data, loading, error }] = useCreateTodoGroupMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      atTicketId: // value for 'atTicketId'
 *   },
 * });
 */
export function useCreateTodoGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateTodoGroupMutation, CreateTodoGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTodoGroupMutation, CreateTodoGroupMutationVariables>(CreateTodoGroupDocument, options);
      }
export type CreateTodoGroupMutationHookResult = ReturnType<typeof useCreateTodoGroupMutation>;
export type CreateTodoGroupMutationResult = Apollo.MutationResult<CreateTodoGroupMutation>;
export type CreateTodoGroupMutationOptions = Apollo.BaseMutationOptions<CreateTodoGroupMutation, CreateTodoGroupMutationVariables>;