import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActiveSessionsIdOnlyQueryVariables = Types.Exact<{
  assignedToUserId: Types.Scalars['String']['input'];
}>;


export type ActiveSessionsIdOnlyQuery = { __typename?: 'Query', findCoreMessageChannelPaginated: { __typename?: 'PaginatedCoreMessageChannel', totalCount: number, page: number, limit: number, data?: Array<{ __typename?: 'CoreMessageChannel', id?: number | null }> | null } };


export const ActiveSessionsIdOnlyDocument = gql`
    query ActiveSessionsIdOnly($assignedToUserId: String!) {
  findCoreMessageChannelPaginated(
    limit: 200
    expired: {eq: false}
    assignedToUserId: {eq: $assignedToUserId}
    acknowledged: {eq: false}
  ) {
    totalCount
    page
    limit
    data {
      id
    }
  }
}
    `;

/**
 * __useActiveSessionsIdOnlyQuery__
 *
 * To run a query within a React component, call `useActiveSessionsIdOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveSessionsIdOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveSessionsIdOnlyQuery({
 *   variables: {
 *      assignedToUserId: // value for 'assignedToUserId'
 *   },
 * });
 */
export function useActiveSessionsIdOnlyQuery(baseOptions: Apollo.QueryHookOptions<ActiveSessionsIdOnlyQuery, ActiveSessionsIdOnlyQueryVariables> & ({ variables: ActiveSessionsIdOnlyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveSessionsIdOnlyQuery, ActiveSessionsIdOnlyQueryVariables>(ActiveSessionsIdOnlyDocument, options);
      }
export function useActiveSessionsIdOnlyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveSessionsIdOnlyQuery, ActiveSessionsIdOnlyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveSessionsIdOnlyQuery, ActiveSessionsIdOnlyQueryVariables>(ActiveSessionsIdOnlyDocument, options);
        }
export function useActiveSessionsIdOnlySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ActiveSessionsIdOnlyQuery, ActiveSessionsIdOnlyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ActiveSessionsIdOnlyQuery, ActiveSessionsIdOnlyQueryVariables>(ActiveSessionsIdOnlyDocument, options);
        }
export type ActiveSessionsIdOnlyQueryHookResult = ReturnType<typeof useActiveSessionsIdOnlyQuery>;
export type ActiveSessionsIdOnlyLazyQueryHookResult = ReturnType<typeof useActiveSessionsIdOnlyLazyQuery>;
export type ActiveSessionsIdOnlySuspenseQueryHookResult = ReturnType<typeof useActiveSessionsIdOnlySuspenseQuery>;
export type ActiveSessionsIdOnlyQueryResult = Apollo.QueryResult<ActiveSessionsIdOnlyQuery, ActiveSessionsIdOnlyQueryVariables>;