import { Box, Typography, useTheme } from "@mui/material";
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { TDailyKpiData, TDailyKpiUserStatusCategory } from "../CoreNotificationWatcher/Notifications/DailyKpi";
import { useApi } from "../../providers/Api/Api";
import { useEffect, useMemo, useState } from "react";
import { TUserStatus } from "../../types/opscompliance";
import { DateTime} from 'luxon';
import { getCategoryColors } from "../../utils/charts";
type CurStatusType  =TUserStatus & {
    status : TDailyKpiUserStatusCategory
}

const today = DateTime.now().setZone('America/Chicago').toFormat("yyyy-MM-dd");


export const KpiWatch = () => {
    const target = 50;
    const theme = useTheme();
    const [refresh , setRefresh] = useState( 1 );
    const [ currentStatus , setCurrentStatus ] = useState< CurStatusType | null>( null );
    const [date , setDate] = useState<string>( today );
    const api = useApi();
    useEffect( () => {
        const interval = setInterval( () => {
            setRefresh( r => r * -1);
            setDate( DateTime.now().setZone('America/Chicago').toFormat("yyyy-MM-dd") );
        } , 1000 * 15 );
        return () => clearInterval( interval );
    } , []);

    const [data , setData] = useState<TDailyKpiData | null>( null );
    useEffect( () => {
        api.get<TDailyKpiData>("/reports/tech/dailykpi" , {
            date
        }).then( (res) => {
            setData( res.data );
        });
        
        api.get<CurStatusType>("/opscompliance/tech/status" , {
            date
        }).then( (res) => {
            setCurrentStatus( res.data );
        }); 
    }, [ refresh , date]);

    const value = useMemo( () => {
        return data?.ticketStatusPercentages?.Good || 0;
    } , [data]);


    const statusColor = getCategoryColors( currentStatus?.status || "Other" , theme );

  return <><Box sx={{width: 40, height: 40 , borderRadius: '50%', backgroundColor: 'white', marginLeft: 2 }}><Gauge height={40} width={40} value={value} startAngle={-110}
  innerRadius={16  }
  outerRadius={10}
endAngle={110} sx={(theme) => ({
    top: '-3px',

[`& .${gaugeClasses.valueArc}`]: {
fill: value > target ? theme.palette.success.main : theme.palette.warning.main,
},
[`& .${gaugeClasses.referenceArc}`]: {
fill: theme.palette.text.disabled,
},
[`& .${gaugeClasses.valueText}`]: {
fontSize: 12,
transform: 'translate(0px, 0px)',
},
})} ></Gauge></Box>

    <Box sx={{backgroundColor: statusColor , marginLeft: 2 ,  height: 40 , borderRadius: 2 , position: 'relative' }}>
        <Box sx={{top: '-5px' , left: 2 , position: 'absolute', background: theme.palette.primary.dark , color: theme.palette.primary.contrastText , fontSize: '10px' , padding: '0px 5px' , borderRadius: '5px'}}>Current Status</Box>
        <Box sx={{ marginLeft: 2 , marginRight: 2 , top: '6px' , position: 'relative'}}><Typography variant="h6">{currentStatus?.status}</Typography></Box>
    </Box>
</>
};

