import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tab, Tabs, TextField, Typography } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";

import { MarkdownCheatLink } from "./MarkdownCheatLink";
import { MarkdownView } from "../MarkdownView";
import { QueueTicket } from "../types/ticketqueue";
import { TTodosTodo } from "../types/todos.dto";
import { useApi } from "../providers/Api/Api";
import { useTicketApi } from "../providers/TicketApiProvider";

export const AnswerTodoDialog : FC<{
    open: boolean,
    onClose: () => any,
    todo: TTodosTodo
}> = ({ open , onClose , todo }) => {
    const { resources } = useTicketApi();
    const api = useApi();
    const [ answer , setAnswer] = useState('');
    const [ tab , setTab] = useState<'question' | 'ticket'>('question');
    const [ ticket , setTicket ] = useState<QueueTicket | null>(null);
    useEffect( () => {
        if( todo.autotaskTicketId ){
            api.get<QueueTicket>(`/ticketqueue/ticket/${todo.autotaskTicketId}`).then( ({ data }) => {
                setTicket( data );
            });
        }
    
    } , [ todo.autotaskTicketId]);
    const submitAnswer = ()=>{
        
        api.patch(`/todos/todo` , { id : todo.id, complete: true , response: answer  }).then( ()=>{
            onClose();
        })
    }

    const answerLength = useMemo( () => {
        const lines = answer.split('\n');
        if( lines.length < 4 ){
            return 5
        }
        return lines.length + 1;
    } , [answer]);

    return <Dialog maxWidth="lg" open={open} onClose={()=>{
        onClose();
    }}>
        <DialogTitle>Answer {todo.title}</DialogTitle>
        <DialogContent sx={{width: '800px'}}>
            
            <Tabs value={tab} onChange={(e , newValue )=>{ setTab( newValue as any )}}>
                <Tab value='question' label="Question" />
                <Tab value='ticket' label="Ticket" />
            </Tabs>
            { tab === 'question' && <>
            
            
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1" sx={{fontWeight: 'bold'}}>Asked by : {todo.creatorEmail}</Typography>
                </Grid>    
                <Grid item xs={12}>
                <MarkdownView>{todo.description || ''}</MarkdownView>
                </Grid>
                <Grid item xs={6}>
                    <TextField value={answer} onChange={(e)=>setAnswer(e.target.value)} multiline fullWidth rows={answerLength}/>
                </Grid>
            <Grid item xs={6}>
                <Typography variant="body2" sx={{fontWeight: 'bold'}}>Preview <MarkdownCheatLink /></Typography>
                <MarkdownView>{answer || ''}</MarkdownView>
            </Grid>
            </Grid>
            </>}
            { tab === 'ticket' && ticket && <>
            <Box><Typography variant="h6"><Button
                onClick={()=>{
                    window.open(`/ticketqueue/all/${ticket.id}` , `ticket_${ticket.id}`)
                }}
            size="small" variant="contained">{ticket.ticketNumber}</Button> {ticket.title}</Typography></Box>
            <MarkdownView>{ticket.description}</MarkdownView></>}
        </DialogContent>
        <DialogActions>
            <Button onClick={()=>{ submitAnswer() }} variant="contained" color="success">Answer</Button>
        </DialogActions>
    </Dialog>
}