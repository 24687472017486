export const QueuesToIcon = (queue: string) => {
    switch( queue.toLocaleLowerCase().replace(/ /g,'_') ) { 
        case "sherpa_champion":
            return "fa-solid:mountain";
        default:
        case "client_portal":
        case "zzz_post_sale":
        case "bad_-_autocreated_tickets":
        case "zzz_recurring_tickets":
        case "zzz_administration":
        case "zzz_merged_tickets":
        case "zzz_pre-sale":
        case "zzz_development":
        case "zzz_lhi_properties":
        case "zzz_dev":
            return "ph:question-fill";
        case "monitoring_alert":
        case "triage":
            return "game-icons:medical-pack";

        case "helpdesk_queue":
        case "tier_2_-_1st_up":
        case "tier_1":    
            return "fluent:person-support-28-filled";
        case "tier_2":
        case "tier_2_-_short_project":
        case "tier_2_-_long_project":
            return "game-icons:gear-hammer";
        case "onboarding":
            return "mdi:partnership";
        case "stuck_tickets":
            return "game-icons:wrecking-ball";
        case "critical":
            return "material-symbols:error";
        case "review":
            return "pajamas:review-checkmark";
        case "internal_development":
        case "backup_alerts":
            return "mingcute:alert-fill";        
        case "process_and_procedure_requests":
        case "automation_requests":
            return "vaadin:automation";
        case "scheduler":
            return "uil:schedule";
        
    }
}
export const QueuesToColor = (queue: string) => {
    switch( queue.toLocaleLowerCase().replace(/ /g,'_') ) { 
        case "sherpa_champion":
            return "#333399";
        case "client_portal":
        case "zzz_post_sale":
        case "bad_-_autocreated_tickets":
        case "zzz_recurring_tickets":
        case "zzz_administration":
        case "zzz_merged_tickets":
        case "zzz_pre-sale":
        case "zzz_development":
        case "zzz_lhi_properties":
        case "zzz_dev":
            return "#333399";
        case "monitoring_alert":
            return "#cc9900";
        case "triage":
            return "#ff6600";

        case "helpdesk_queue":
            return "#336600";
        case "tier_2_-_1st_up":
            return "#003399";
        case "tier_1":    
            return "#0066ff";
        case "tier_2":
        case "tier_2_-_short_project":
        case "tier_2_-_long_project":
            return "#003366";
        case "onboarding":
            return "#00cc66";
        case "stuck_tickets":
            return "#ff00ff";
        case "critical":
            return "#ff0000";
        case "review":
            return "#7575a3";
        case "internal_development":
            return "#3973ac";
        case "backup_alerts":
            return "#ff6600";        
        case "process_and_procedure_requests":
        case "automation_requests":
            return "#006666";
        case "scheduler":
            return "#99cc00";
        
    }
}