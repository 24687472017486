import { AutocompleteCompany, AutocompleteContact, AutotaskResource, QueueTicket } from "../../types/ticketqueue";
import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { BeNode } from "../../types/recipes";
import { CoreGroup, CoreUser } from "../../types/core";
import { TTodosTodo } from "../../types/todos.dto";
import { toast } from 'react-toastify';
import { useApi } from "../Api/Api";
import { useMounted } from "../../hooks/useMounted";
import { AutotaskCompanyLocations } from "../../types/companylocations";

export type FieldPicklist = {
    "label": string;
    "value": string
    "isActive": boolean
    "isSystem": boolean,
    "sortOrder": number,
    "parentValue": string,
    "isDefaultValue": boolean,
}

export interface ITicketApiProvider {
    queueId?: string;
    statuses: FieldPicklist[];
    queues: FieldPicklist[];    
    priorities: FieldPicklist[];
    companies: AutocompleteCompany[];
    locations: AutotaskCompanyLocations[];
    contacts: AutocompleteContact[];
    resources: AutotaskResource[];
    recipeStarts: BeNode[];
    getNextTicket: (useCache? : boolean) => any ;
    users: CoreUser[];
    nextTicket: QueueTicket | null;
    firstLoad: boolean;
    groups: CoreGroup[];
    myQuestions: TTodosTodo[];
    dispatchPaused : boolean  | null; 
    setDispatchPaused: (b : boolean) => any ;
}
export const defaultTicketApiProvider : ITicketApiProvider = {
    statuses : [],
    users: [],
    queues: [],
    priorities: [],
    companies: [],
    contacts: [],
    locations: [],
    groups: [],
    recipeStarts: [],
    myQuestions: [],
    resources: [],
    getNextTicket: (useCache? : boolean) => {},
    nextTicket: null,
    firstLoad: true,
    dispatchPaused : true , 
    setDispatchPaused: () => { console.log("NO CONTEXT !!!")},
};
export const TicketContext = createContext<ITicketApiProvider>( defaultTicketApiProvider );
export const useTicketApi = () => useContext(TicketContext);

