import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TicketTodoGroupsQueryVariables = Types.Exact<{
  ticketId: Types.Scalars['Float']['input'];
}>;


export type TicketTodoGroupsQuery = { __typename?: 'Query', findTodosGroupPaginated: { __typename?: 'PaginatedTodosGroup', data?: Array<{ __typename?: 'TodosGroup', id?: number | null, title?: string | null, description?: string | null }> | null } };


export const TicketTodoGroupsDocument = gql`
    query TicketTodoGroups($ticketId: Float!) {
  findTodosGroupPaginated(atTicketId: {eq: $ticketId}) {
    data {
      id
      title
      description
    }
  }
}
    `;

/**
 * __useTicketTodoGroupsQuery__
 *
 * To run a query within a React component, call `useTicketTodoGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketTodoGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketTodoGroupsQuery({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useTicketTodoGroupsQuery(baseOptions: Apollo.QueryHookOptions<TicketTodoGroupsQuery, TicketTodoGroupsQueryVariables> & ({ variables: TicketTodoGroupsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TicketTodoGroupsQuery, TicketTodoGroupsQueryVariables>(TicketTodoGroupsDocument, options);
      }
export function useTicketTodoGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketTodoGroupsQuery, TicketTodoGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TicketTodoGroupsQuery, TicketTodoGroupsQueryVariables>(TicketTodoGroupsDocument, options);
        }
export function useTicketTodoGroupsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TicketTodoGroupsQuery, TicketTodoGroupsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TicketTodoGroupsQuery, TicketTodoGroupsQueryVariables>(TicketTodoGroupsDocument, options);
        }
export type TicketTodoGroupsQueryHookResult = ReturnType<typeof useTicketTodoGroupsQuery>;
export type TicketTodoGroupsLazyQueryHookResult = ReturnType<typeof useTicketTodoGroupsLazyQuery>;
export type TicketTodoGroupsSuspenseQueryHookResult = ReturnType<typeof useTicketTodoGroupsSuspenseQuery>;
export type TicketTodoGroupsQueryResult = Apollo.QueryResult<TicketTodoGroupsQuery, TicketTodoGroupsQueryVariables>;