import { Alert, Button, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, ListSubheader, MenuItem, Switch, TextField, Typography } from "@mui/material"
import { FC, useEffect, useMemo, useState } from "react"

import { MarkdownCheatLink } from "../MarkdownCheatLink";
import { MarkdownView } from "../../MarkdownView";
import { TTodosTodo } from "../../types/todos.dto"
import { useApi } from "../../providers/Api/Api";
import { useTicketApi } from "../../providers/TicketApiProvider";

export const EditTodoDialog: FC<{open: boolean , onClose: () => any , todo : TTodosTodo}> = ({ open, onClose, todo }) => {
    const { groups , users } = useTicketApi();
    const api = useApi();
    const [ title , setTitle ] = useState(todo.title);
    const completeOptions = useMemo( () => {
        return todo?.recipe_node_data?.completeOptions || [];
    }, [JSON.stringify(todo?.recipe_node_data?.completeOptions)]);
    
    const [ complete ,setComplete ] = useState(todo.complete);
    const [ description , setDescription ] = useState(todo.description);
    const [ response , setResponse ] = useState(todo.response);
    const [ dueDate , setDueDate ] = useState<string | null>(todo.dueDate );
    const [ ticketBlocking , setTicketBlocking ] = useState(todo.ticketBlocking);

    
    

    const todoGroup = useMemo( () => {
        const assignee = todo.assignees.filter( a => a.coreGroupId !== null).pop();
        
        if( !assignee ) return null;
        return assignee.coreGroupId;
    } , [ JSON.stringify( todo.assignees)]);
    const todoUser = useMemo( () => {
        const assignee = todo.assignees.filter( a => a.coreUserId !== null).pop();
        if( !assignee ) return null;
        return assignee.coreUserId;
    } , [ JSON.stringify( todo.assignees)]);
    const [ groupId , setGroup ] = useState<number | null>(todoGroup);
    const [ userId , setUser ] = useState<string | null>(todoUser);
    
    const assignableGroups = useMemo( () => {
        return groups.map( g => {
            return {                
                value : `group:${g.id}`,
                text : g.name

            }
        });
    } , [JSON.stringify( groups ) ]);

    const assignableUsers = useMemo( () => {
        return users.map( u => ({
            value : `user:${u.id}`,
            text : `${u.firstName} ${u.lastName}`
        }));
    } , [JSON.stringify( users )]);
    const assignedTo = useMemo( () => {
        if( userId){
            return `user:${userId}`;
        }
        if( groupId){
            return `group:${groupId}`;
        }
        return null;
    } , [groupId , userId ]);

    const setAssignedTo = (assignedTo : string | null) => {
        if( assignedTo && assignedTo.startsWith("user:")){
            setUser( assignedTo.split(":")[1] );
            setGroup( null);
            return;
        }
        if( assignedTo && assignedTo.startsWith("group:")){
            setGroup( parseInt( assignedTo.split(":")[1] ) || null );
            setUser( null);
            return;
        }
        setUser( null);
        setGroup( null);
    }

    useEffect( () => {
        setTitle(todo.title);
        setGroup(todoGroup);
        setComplete(todo.complete);
        // Convert dueDate from ISO to YYYY-MM-DD format before setting it
        const formattedDueDate = todo.dueDate ? todo.dueDate.split('T')[0] : null;
        setDueDate(formattedDueDate);
        setTicketBlocking(todo.ticketBlocking);
    } , [open]);

    const responseLength = useMemo( () => {
        const lines = (response || '' ).split('\n');
        if( lines.length < 4 ){
            return 5
        }
        return lines.length + 1;
    } , [response]);
    
    const descriptionLength = useMemo( () => {
        const lines = (description || '' ).split('\n');
        if( lines.length < 4 ){
            return 5
        }
        return lines.length + 1;
    } , [description]);

    const submit = () =>{
        const update : Record<string, any > = { 
            id : todo.id,
        }
        if( dueDate !== todo.dueDate ){
            update.dueDate = dueDate;
        }
        if( title !== todo.title ){
            update.title = title;
        }
        if( description !== todo.description ){
            update.description = description;
        }
        if( response !== todo.response ){
            update.response = response;
        }
        if( complete !== todo.complete ){
            update.complete = complete;
        }
        if( groupId !== todoGroup ){
            update.groupId = groupId;
        }
        if( userId !== todoUser ){
            update.coreUserId = userId;
        }
        if( ticketBlocking !== todo.ticketBlocking ){
            update.ticketBlocking = ticketBlocking;
        }
        api.patch(`/todos/todo` , update ).then( ()=>{
            onClose && onClose();
        });
    }
    useEffect( () => {
        const today = new Date().toISOString().split('T')[0];
        if( !dueDate && groupId === 1  ){
            setDueDate( today );
        }
    }, [ groupId , dueDate]);

    return <Dialog maxWidth="lg" open={open} onClose={()=>{
        onClose();
    }}>
        <DialogTitle>Edit {title}</DialogTitle>
        <DialogContent sx={{width: '800px'}}>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <TextField error={title === ""} sx={{marginTop: 1}} required fullWidth size="small" label="Title" value={title} 
                        onChange={(e)=>setTitle(e.target.value)} />
                </Grid>
                <Grid item sm={6}>
                <TextField InputLabelProps={{ shrink: true }}error={!assignedTo} required sx={{marginTop: 1 , marginBottom: 1}} fullWidth size="small"  label="Assigned To" value={assignedTo} select
                        onChange={(e)=>setAssignedTo(  `${e.target.value}` || null )} >
                        <ListSubheader>Groups</ListSubheader>
                        {assignableGroups.map( t => <MenuItem sx={{marginLeft: 2}} key={t.value} value={t.value}>{t.text}</MenuItem>)}
                        <ListSubheader>Users</ListSubheader>
                        {assignableUsers.map( t => <MenuItem sx={{marginLeft: 2}} key={t.value} value={t.value}>{t.text}</MenuItem>)}
                    </TextField>
                </Grid>
                <Grid item sm={6}>
                    {completeOptions.length > 0 && !Boolean(complete) && <Alert severity="warning">This task is from a recipe and must be completed from the options on the main card.</Alert>}
                    <FormControlLabel control={<Switch disabled={completeOptions.length > 0 && !Boolean(complete)} checked={Boolean(complete)} onChange={(e)=>setComplete(e.target.checked)} />} label="Complete" />
                </Grid>
                <Grid item sm={6}>
                <TextField fullWidth sx={{marginTop: 1}} InputLabelProps={{ shrink: true }} size="small" label="Next Action Date" type="date" value={dueDate} onChange={e => setDueDate( e.target.value || null )}   />
            </Grid>
            <Grid item sm={6}>
            <FormControlLabel
          control={
            <Switch disabled={todo?.noteType === "task"} color="success" checked={Boolean(ticketBlocking)} onChange={(e)=>{
                setTicketBlocking( e.target.checked );
            }} />
          }
          label="Ticket Blocking"
        /> </Grid>
                <Grid item sm={6}>
                    <TextField sx={{marginTop: 1}} label="Description" fullWidth size="small"  value={description}
                        onChange={(e)=>setDescription(e.target.value)} multiline rows={descriptionLength} />
                </Grid>
                <Grid item sm={6}>
                    <Typography variant="body2" sx={{fontWeight: 'bold'}}>Preview <MarkdownCheatLink /></Typography>
                    <MarkdownView>{description}</MarkdownView>
                </Grid>
                <Grid item sm={6}>
                    <TextField sx={{marginTop: 1}} label="Response" fullWidth size="small"  value={response}
                        onChange={(e)=>setResponse(e.target.value)} multiline rows={responseLength} />
                </Grid>
                <Grid item sm={6}>
                    <Typography variant="body2" sx={{fontWeight: 'bold'}}>Preview <MarkdownCheatLink /></Typography>
                    <MarkdownView>{response}</MarkdownView>
                </Grid>
            </Grid>
            <Button onClick={()=>{submit();}} color="success"  variant="contained" size="small" disabled={(!groupId && !userId) || title === ""}>Update Todo</Button>
            
        </DialogContent>
    </Dialog>
   }