import { FC, Fragment } from "react";

import { JsonSchema7ObjectType, JsonSchema7Type } from "zod-to-json-schema";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { isJsonSchemaObjectType } from "./FilterNode/filter.functions";


const getContextByPath = (path: string, context: JsonSchema7Type): JsonSchema7Type => {
    if( path === ""){
        return context;
    }
    const pathParts = path.split('.');
    const relativePath = pathParts.shift();
    
    if( relativePath && isJsonSchemaObjectType(context) && context.properties.hasOwnProperty(relativePath) ){
        console.log(pathParts);
        const newContext = context.properties[relativePath];
        if( isJsonSchemaObjectType( newContext)){
            return getContextByPath( pathParts.join('.'), newContext );
        }
        return context.properties[relativePath];
    }
    return context;
}



const propertiesByKey = (context: JsonSchema7Type): string[] => {
    if( isJsonSchemaObjectType( context )){
        return Object.keys(context.properties);
    }
    return [];
}


export const ContextKeyPicker : FC<{
    field : string;
    onChange : ( field : string ) => void;
    context: JsonSchema7Type
}> = ( { field , onChange , context  } ) => {

    const keyParts = field.split('.');
    

    const contextByPath = getContextByPath(field , context);
    const keys = propertiesByKey( contextByPath );
    return <>
            <Box display="flex" flexDirection="row" mb={1}>
                {keyParts.map((key, index) => (
                    <Fragment key={index}>
                        
                        <Button size="small" onClick={()=>{
                            onChange( keyParts.slice(0, index).join('.') );
                        }}>{index > 0 && "."}{key}</Button>
                    </Fragment>
                ))}
                { isJsonSchemaObjectType( contextByPath ) && <TextField 
                size="small"
                label="Key"
                select
                fullWidth   
                value={keyParts.slice(0, keyParts.length - 1).join('.')}
                onChange={(e)=>{
                    onChange( keyParts.concat(e.target.value).filter( k => k !== '' ).join('.') );
                }}
            >
                    {keys.map( key => <MenuItem key={key} value={key}>{key}</MenuItem>)}
                </TextField>
            }
            </Box>
        </>
}