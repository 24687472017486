import { FC, useState } from "react"
import { ContractsInstances } from "../../../../coretypes/contracts/contracts.dto"
import { DeviceQueryItem } from "../../../../react-submodules/types/dattormm.dto"
import { IconButton, useTheme } from "@mui/material"
import { Icon } from "@iconify/react"
import { useApi } from "../../../../react-submodules/providers/Api"
import { DEFAULT_CONTRACT_ICON, DEFAULT_CONTRACT_ICON_COLOR } from "../../../../react-submodules/constances/icons"
import { useMounted } from "../../../../react-submodules/hooks/useMounted"
import { isServer } from "./utils"

export const ContractCell : FC<{ contract : ContractsInstances[] , device : DeviceQueryItem , refresh : () => void }> = ({
    contract , device , refresh
}) => {
    const loaded = useMounted();
    const theme = useTheme();
    
    const api = useApi();
    const um : string = isServer( device ) ? 'SERVERS' : 'WORKSTATIONS';
    const usableContracts = contract.filter( c => c.contract_unitsMeasured == um );
    const [ updating , setUpdating ] = useState<boolean | number >( false );
    const toggleContract = ( id : number ) => {
        setUpdating( id );
        api.post( `/contracts/instances/${id}/${device.contracts[id] ? 'unassign' : 'assign'}` , { deviceId : device.device_id } ).then( async () => {
            await refresh();
            setTimeout( () => {
                loaded() && setUpdating( false );
            }, 10000 );
        } );
    }
    

    return <>
        { usableContracts.map( c => {
            let color = device.contracts[c.id] ? (c.contract_iconColor || DEFAULT_CONTRACT_ICON_COLOR) : undefined;
            if( updating == c.id ) {
                color = theme.palette.warning.main;
            }

            if( !color ){
                color = theme.palette.grey[300];
            }
            return <IconButton onClick={()=>{toggleContract( c.id );}} size="small" sx={{
                color: color
            }}><Icon icon={c.contract_icon || DEFAULT_CONTRACT_ICON} /></IconButton>
        })}
    </>
}