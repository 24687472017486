import { Box, Button, Grid, IconButton, Fab as MuiFab, Paper, styled, TextField, Typography, useTheme } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { io, Socket } from 'socket.io-client';
import { useUser } from '../../providers/User';
import { ChatInput, ChatMessage } from '../../../coretypes/ai.types';
import { Icon } from '@iconify/react';
import { MarkdownView } from '../../MarkdownView';
import { TAiChat } from '../../../coretypes/ai/chat';
import { gql, useQuery } from '@apollo/client';
import { IPaginatedType } from '../../../coretypes/graphql/QueryResponse';
import { NestedPick } from '../../../coretypes/graphql/DeepPartial';
import { ChatBox } from './ChatBox';
import { ChatList } from './ChatList';
import { useParams } from 'react-router-dom';
// "undefined" means the URL will be computed from the `window.location` object


  const Fab = styled(MuiFab)({
    position: 'fixed',
    bottom: 20,
    left: 100,
    '.sidepanel-open &' : {
        left: 300
    }
  });
  const ChatPaper = styled(Paper)({
    position: 'fixed',
    bottom: 100,
    left: 100,
    width: 800,
    height: '70vh',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '.sidepanel-open &' : {
        left: 300
    },
    '&.full-screen' : {
      zIndex: 999000,
        width: '100vw',
        height: '100vh',
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        
    }
  });



export const ChatBubble = () => {
    const [ open , setOpen ] = useState(false);
    const [ chatId , setChatId ] = useState<number | null>(null);
    const [ fullScreen , setFullScreen ] = useState(false);
    const theme = useTheme();

    return <>
    { open && <ChatPaper className={fullScreen ? 'full-screen' : ''}>
        <Box color="primary.contrastText" sx={{ 
          backgroundColor: 'primary.main',
          p: 1 , display: 'flex' , justifyContent: 'space-between' }}>
            <Typography variant="h6">AI Chat</Typography> 
              <Box>{ chatId && <Button color="secondary" variant="contained" size="small" onClick={()=>setChatId(null)}>Back</Button> } 
              <IconButton sx={{ color: theme.palette.primary.contrastText }} onClick={()=>setFullScreen(!fullScreen)}>
                  <Icon icon={fullScreen ?  "dashicons:fullscreen-exit-alt" : "dashicons:fullscreen-alt"} />
              </IconButton>
            </Box>
        </Box>
        { !chatId && <ChatList onSelect={setChatId} /> }
        { chatId && <ChatBox chatId={chatId} /> }
      
    </ChatPaper> }
    <Fab color="primary" aria-label="Chat" onClick={()=>setOpen(!open)}>
  <Icon icon="entypo:chat" />
</Fab></>
    return <div>ChatBubble</div>
}