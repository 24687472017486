import { FC } from "react"
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { Alert, Box, Button, Card, CardActions, CardContent, CardHeader } from "@mui/material";
import Markdown from "react-markdown";
import { MarkdownView } from "../../../../MarkdownView";

export const GoodTicketGuage : FC<{value: number , target : number , label : string , helper? : string , toggleDetails? : () => void , showDetails? : boolean}> = ({value, target, label, helper, toggleDetails, showDetails}) => {

    return <Card>
        <CardHeader title={label} />
        <CardContent>
        <Box display="flex" flexDirection="column" alignItems="center"><Gauge
    value={value}
    startAngle={-110}
    endAngle={110}
    height={200}
    width={200}
    sx={(theme) => ({
      
      [`& .${gaugeClasses.valueArc}`]: {
        fill: value > target ? theme.palette.success.main : theme.palette.warning.main,
      },
      [`& .${gaugeClasses.referenceArc}`]: {
        fill: theme.palette.text.disabled,
      },
      [`& .${gaugeClasses.valueText}`]: {
        fontSize: 40,
        transform: 'translate(0px, 0px)',
      },
    })}
    
    text={
       () => `${value}%`
    }
  />
  </Box>
  { value >= target ? <Alert severity="success">You have met your target</Alert> : <Alert severity="warning">Your goal is {target}% but you have {value}%</Alert>}
  {value < target && helper && <MarkdownView>{helper}</MarkdownView>}
  </CardContent>
  <CardActions>
    {toggleDetails && <Button variant="contained" onClick={toggleDetails}>{showDetails ? "Hide Details" : "Show Details"}</Button>}
  </CardActions>
  </Card>
}

