import { Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Switch, TextField, InputAdornment, IconButton, MenuItem } from "@mui/material"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Handle, Position, useEdges, useNodes } from "reactflow"
import { useEffect, useMemo, useState } from "react";

import { useApi } from "../../../../react-submodules/providers/Api/Api";
import { useNodeDataProperty, useRecipie } from "../../../../providers/Recipies.provider";
import { MarkdownEditor } from "../../../../react-submodules/components/MarkdownEditor/MarkdownEditor";
import { allUpdateFields, TicketUpdateField } from "../../../../react-submodules/components/TicketUpdateForm/Fields";
import { ContextKeyPicker } from "../ContextKeyPicker";
import zodToJsonSchema, { JsonSchema7Type } from "zod-to-json-schema";
import { useNodeContext } from "../../useNodeContext";
import { z } from "zod";
import { Icon } from "@iconify/react";
import { QueueTicket } from "../../../../react-submodules/types/ticketqueue";

const contextJson = ( context : z.ZodType ) => {
    return zodToJsonSchema( context , { $refStrategy : 'none'} );
   }
   

const FieldRow = ({ id, label, field, defaultValue }: { id: string, label: string, field: string, defaultValue?: any }) => {
    const nodes = useNodes();
    const edges = useEdges();
    const { getContext } = useNodeContext( id );

    const [ context, setContext ] = useState<JsonSchema7Type>( contextJson( getContext() ) );
    useEffect( () => {
      const newContext = getContext();
      setContext( contextJson( newContext ) );
    }, [
      JSON.stringify( { nodes , edges})
    ]);

    const [value, setValue] = useNodeDataProperty(id, field, defaultValue);
    const [ showHelp , setShowHelp ] = useState(false);
    const [ pickerKey , setPickerKey ] = useState('');
    return <>
        <Grid item sm={12}>
            <TextField 
                size="small" 
                label={label} 
                value={value} 
                onChange={(e) => {
                    setValue(e.target.value);
                }} 
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="help"
                                onClick={() => setShowHelp(true)}
                                edge="end"
                            >
                                <HelpOutlineIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </Grid>
        <Dialog open={showHelp} onClose={() => {
            setShowHelp(false);
        }}>
            <DialogTitle>Variable Picker</DialogTitle>
            <DialogContent>
                    <ContextKeyPicker  field={pickerKey} onChange={(key) => {
                        setPickerKey(key);
                    }} context={context} />
            </DialogContent>
            <DialogActions>
                {pickerKey && <Button onClick={() => {
                    setShowHelp(false);
                    setValue(`${value||''}{{${pickerKey}}}`);
                    setPickerKey('');
                }}>Inject</Button>}
            </DialogActions>
        </Dialog>
    </>
}
export const CreateCompanyNode = ({ id , data } : {id : string , data : any  } ) => {
    const {  updateNodeData  } = useRecipie();
    const [ fieldToAdd , setFieldToAdd ] = useState<keyof QueueTicket>();
    const handleCopy = () => {
        navigator.clipboard.writeText( JSON.stringify( { defaultData : data , id : 'ticketCreation' , title : data.title } , null , 2 ) );
      }

    return <Box>
        <Card sx={{maxWidth: '600px'}}>
            <CardHeader className="drag-handle" title="Company Creation" action={
          <IconButton aria-label="copy" onClick={handleCopy}>
            <Icon icon="mdi:content-copy" />
          </IconButton>
        } />
            <CardContent>
                <Grid container spacing={2}>                   
                    <FieldRow id={id} label="Name" field="title" />
                </Grid>                
            </CardContent>
        </Card>
        <Handle
          type="target"
          position={Position.Left}
          id={`handle-target`}
        />
        <Handle
          type="source"
          position={Position.Right}
          id={`handle-source`}
        />
    </Box>
}