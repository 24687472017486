// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%271em%27 height=%271em%27 viewBox=%270 0 24 24%27%3E%3Cpath fill=%27%23000%27 d=%27M12.68 6h-1.36L7 16h2l.73-2h4.54l.73 2h2zm-2.38 6.5L12 8l1.7 4.5zm7.1 7.9L19 22h-5v-5l2 2c2.39-1.39 4-4.05 4-7c0-4.41-3.59-8-8-8s-8 3.59-8 8c0 2.95 1.61 5.53 4 6.92v2.24C4.47 19.61 2 16.1 2 12C2 6.5 6.5 2 12 2s10 4.5 10 10c0 3.53-1.83 6.62-4.6 8.4%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iconify-icon{
    display: inline-block;
    width: 1em;
    height: 1em;
    background-color: currentColor;
    mask-image: var(--svg);
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .auto-schedule-icon {
    --svg: url(${___CSS_LOADER_URL_REPLACEMENT_0___})
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Schedule/DailySchedule/DailySchedule.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,UAAU;IACV,WAAW;IACX,8BAA8B;IAE9B,sBAAsB;IAEtB,sBAAsB;IAEtB,oBAAoB;IACpB,kBAAkB;IAClB,QAAQ;IACR,MAAM;EACR;;EAEA;IACE;EACF","sourcesContent":[".iconify-icon{\n    display: inline-block;\n    width: 1em;\n    height: 1em;\n    background-color: currentColor;\n    -webkit-mask-image: var(--svg);\n    mask-image: var(--svg);\n    -webkit-mask-repeat: no-repeat;\n    mask-repeat: no-repeat;\n    -webkit-mask-size: 100% 100%;\n    mask-size: 100% 100%;\n    position: absolute;\n    right: 0;\n    top: 0;\n  }\n  \n  .auto-schedule-icon {\n    --svg: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M12.68 6h-1.36L7 16h2l.73-2h4.54l.73 2h2zm-2.38 6.5L12 8l1.7 4.5zm7.1 7.9L19 22h-5v-5l2 2c2.39-1.39 4-4.05 4-7c0-4.41-3.59-8-8-8s-8 3.59-8 8c0 2.95 1.61 5.53 4 6.92v2.24C4.47 19.61 2 16.1 2 12C2 6.5 6.5 2 12 2s10 4.5 10 10c0 3.53-1.83 6.62-4.6 8.4'/%3E%3C/svg%3E\")\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
