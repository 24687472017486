import { AutocompleteCompany, AutocompleteContact, AutotaskResource, QueueTicket } from "../../types/ticketqueue";
import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { BeNode } from "../../types/recipes";
import { CoreGroup, CoreUser } from "../../types/core";
import { TTodosTodo } from "../../types/todos.dto";
import { toast } from 'react-toastify';
import { useApi } from "../Api/Api";
import { useMounted } from "../../hooks/useMounted";
import { FieldPicklist, TicketContext } from "./TicketApiProvider.common";
import { AutotaskCompanyLocations } from "../../types/companylocations";


export const TicketApiProvider :React.FC<PropsWithChildren & { queueId? : string , getNextTicket: () => any}> = ( { queueId , children , getNextTicket }) => {
    const api = useApi();
    const loaded = useMounted();
    const [ statuses , setStatuses ] = useState< FieldPicklist[] >([]);
    const [ queues , setQueues ] = useState< FieldPicklist[] >([]);
    const [ priorities , setPriorities ] = useState< FieldPicklist[] >([]);
    const [ companies , setCompanies ] = useState< AutocompleteCompany[] >([]);
    const [ contacts , setContacts ] = useState< AutocompleteContact[] >([]);
    const [ resources , setResources ] = useState< AutotaskResource[] >([] );
    const [ users , setUsers]  = useState<CoreUser[]>([]);
    const [ firstLoad , setFirstLoad ] = useState( true );
    const [ myQuestions , setMyQuestions ] = useState<TTodosTodo[]>([]);
    const [ recipeStarts , setRecipeStarts ] = useState<BeNode[]>( []);
    const [ locations , setLocations ] = useState<AutotaskCompanyLocations[]>( [] );
    

    const [ groups , setGroups ] = useState<any[]>([]);
    const [ refreshRecipes , setRefreshRecipes ] = useState(1);
    const [ dispatchPaused , setDispatchPaused ] = useState<boolean | null >( null );
    

    const [nextTicket , setNextTicket ] = useState<QueueTicket | null >( null );

    const [ updateTicketTick , setUpdateTicketTick ] = useState( 1 );
    const tapGetNextTicket = ( useCache? : boolean ) => {
        
        if( useCache && nextTicket ){
            setNextTicket( nextTicket );
        } else {
            setNextTicket( null );
        }
        
        getNextTicket();
    
    }

    const updateTicket =  () => {
        api.get<QueueTicket>(`/ticketqueue/queue/${queueId}/nextTicket` , { dispatch : !dispatchPaused }).then( ( { data , error }) => {
            if( loaded() && error !== 'No data' ){
                
                setNextTicket( data );
                if( firstLoad ){
                    setFirstLoad( false );
                }
                if( dispatchPaused ){
                    toast.warning('You appear to be paused.  Please unpause when you are back' , { autoClose: 35000 });
                }
            }
        });
    }
    
    
    useEffect( () => {
        updateTicket();
        api.get<TTodosTodo[]>('/todos/questions/mine').then( ( { data }) => {
            setMyQuestions( data );
        } );
    } , [ updateTicketTick ]);

    useEffect( () => {
        if( api.ready === false ) return;
        setUpdateTicketTick( pv => pv * -1);
        const interval = setInterval( ()=>{
            setUpdateTicketTick( pv => pv * -1);
        } , 30 * 1000 )
        return () => {
            clearInterval( interval );
        }
    } , [ ]);

    useEffect( () => {
        if( api.ready === false ) return;
        // @ts-ignore 
        window.recipeDevMode = ( b : boolean ) => {
            sessionStorage.setItem('recipeDevMode' , b ? 'true' : 'false');
            setRefreshRecipes( pv => pv * -1);
            console.log( b ? "Your browser is now in recipe dev mode" : "Your browser is no longer in recipe dev mode");
            return b;
        } 
        const ls = sessionStorage.getItem('recipeDevMode');
        let append = '';
        if( ls === 'true'){
            append = '/all';
        }
        api.get<BeNode[]>(`/recipe/starts${append}`).then( ( { data }) => {
            setRecipeStarts( data );
        } );
    }, [ api.ready, api.token , refreshRecipes , setRefreshRecipes ]);

    useEffect( () => {
        if( api.ready === false ) return;

        api.get<FieldPicklist[]>('/ticketqueue/fields/statuses').then( ( { data }) => {
            setStatuses( data.filter( d => d.isActive ) );
        });
        api.get<FieldPicklist[]>('/ticketqueue/fields/queues').then( ( { data }) => {
            setQueues( data.filter( d => d.isActive ) );
        });
        api.get<FieldPicklist[]>('/ticketqueue/fields/priorities').then( ( { data }) => {
            setPriorities( data.filter( d => d.isActive ) );
        });
        api.get<AutocompleteCompany[]>('/ticketqueue/fields/companies').then( ( { data }) => {
            setCompanies( data );
        });
        api.get<AutocompleteContact[]>('/ticketqueue/fields/contacts').then( ( { data }) => {
            setContacts( data );
        });
        api.get<AutotaskResource[]>('/autotask/resources').then( ( { data }) => {
            setResources( data );
        });
        api.get<CoreGroup[]>('/core/groups').then( ( { data }) => {
            setGroups( data );
        } );
        api.get<AutotaskCompanyLocations[]>('/autotask/locations').then( ( { data }) => {
            setLocations( data );
        });
        api.get<CoreUser[]>( '/core/users' ).then( ( { data }) => {
            setUsers( data.filter( user => /coulee.tech/.test( user.email ) ) );
        });
    } , [ api.ready])


    return (
        <TicketContext.Provider value={{
            statuses,
            queues,
            priorities,
            queueId, 
            users,
            getNextTicket : tapGetNextTicket,
            companies,
            locations,
            groups,
            contacts,
            nextTicket,
            firstLoad,
            resources,
            dispatchPaused , setDispatchPaused , 
            myQuestions,
            recipeStarts
        }}>{children}</TicketContext.Provider>
    )
}