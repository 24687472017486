import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateTodosTodoMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  complete: Types.Scalars['Boolean']['input'];
  spawnedTicketId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type UpdateTodosTodoMutation = { __typename?: 'Mutation', updateTodosTodo: { __typename?: 'TodosTodo', id?: number | null, complete?: boolean | null, spawnedTicketId?: number | null } };


export const UpdateTodosTodoDocument = gql`
    mutation UpdateTodosTodo($id: Int!, $complete: Boolean!, $spawnedTicketId: Int) {
  updateTodosTodo(id: $id, complete: $complete, spawnedTicketId: $spawnedTicketId) {
    id
    complete
    spawnedTicketId
  }
}
    `;
export type UpdateTodosTodoMutationFn = Apollo.MutationFunction<UpdateTodosTodoMutation, UpdateTodosTodoMutationVariables>;

/**
 * __useUpdateTodosTodoMutation__
 *
 * To run a mutation, you first call `useUpdateTodosTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTodosTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTodosTodoMutation, { data, loading, error }] = useUpdateTodosTodoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      complete: // value for 'complete'
 *      spawnedTicketId: // value for 'spawnedTicketId'
 *   },
 * });
 */
export function useUpdateTodosTodoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTodosTodoMutation, UpdateTodosTodoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTodosTodoMutation, UpdateTodosTodoMutationVariables>(UpdateTodosTodoDocument, options);
      }
export type UpdateTodosTodoMutationHookResult = ReturnType<typeof useUpdateTodosTodoMutation>;
export type UpdateTodosTodoMutationResult = Apollo.MutationResult<UpdateTodosTodoMutation>;
export type UpdateTodosTodoMutationOptions = Apollo.BaseMutationOptions<UpdateTodosTodoMutation, UpdateTodosTodoMutationVariables>;