import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from "react";

import { useKeycloak } from "./Keycloak";

interface User {
    id?: number;
    sub?: string;
    name?: string;
    email?: string;
    token?: string;
    isLogged?: boolean;
    login: () => void;
    logout: () => void;
    ready?: boolean;
    tokenParsed?: Record<string,any> | null;
}
const UserContext = createContext<User>({
    login: () => {},
    logout: () => {},
});
export const useUser = () => useContext(UserContext);

export const UserProvider :React.FC<PropsWithChildren> = ( {children}) => {
    const { login , logout , token , ready, tokenParsed } = useKeycloak();
    const [ email , setEmail ] = useState( '' );
    const [ name , setName ] = useState( '' );
    useEffect( () => {
        // @ts-ignore
        window.apiProviderToken = token;
    }, [token]);
    const userToken = useMemo( () => {
        return token;
    } , [token]);
    useEffect( () => { 
        if( email !== tokenParsed?.email ){
            setEmail( tokenParsed?.email || '' );
        }
        if( name !== tokenParsed?.name ){
            setName( tokenParsed?.name || '' );
        }

    } , [JSON.stringify( tokenParsed)] );
    

    const id = tokenParsed?.sub;


    return (
        <UserContext.Provider value={{login, logout , name , email , token : userToken , ready , tokenParsed , id }}>{children}</UserContext.Provider>
    )
}