import { PropsWithChildren, createContext, useContext, useEffect,  useState } from "react";
import KeycloakFactory from "keycloak-js";
import LogRocket from "logrocket";
import { KEYCLOAK_REALM, KEYCLOAK_URL, KeycloakContext } from "./Keycloak.common";



const keycloak = new KeycloakFactory({
    url: KEYCLOAK_URL,
    realm: KEYCLOAK_REALM,
    clientId: 'core-frontend'
});
let keycloakInitialized = false;
/* keycloak.init({
    onLoad: 'login-required',
}); */

export const KeycloakMobileProvider :React.FC<PropsWithChildren> = ( {children}) => {
   

    const [ ready , setReady ] = useState( false );
    const [ offline , setOffline ] = useState( false );
    const [ initialized , setInitialized ] = useState( false );
    const [ token , setToken ] = useState( '' );
    const [ tokenParsed , setTokenParsed ] = useState<Record<string,any>>( {} );
    const [ firstLoop  , setFirstLoop ] = useState( true );

    const checkOnline = async () => {
        console.log( "Checking online");
        try {
            await fetch( `${KEYCLOAK_URL}realms/${KEYCLOAK_REALM}` ).then( ( resp ) => resp.json() );
            if( offline ){
                setOffline( false );
            }
            return true;            
        } catch ( e ){
        }
        if( !offline ){
            setOffline( true );
        }
        
        return false;
    }
    useEffect( () => {
        if( !offline){
            return;
        }
        checkOnline();
        const interval = setInterval( () => {
            checkOnline();
        } , 1000 * 15 );
        return () => {
            clearInterval( interval );
        }
    } , [ offline , token , initialized ])

    useEffect( () => {
        if( initialized ) return;
        if( keycloakInitialized ){
            setInitialized( true );
            return;
        }
        checkOnline().then( online => {
            if( online ){
                if( keycloakInitialized ){
                    return;
                }
                keycloakInitialized = true;
                setInitialized( true );
                keycloak.init({
                    onLoad: 'check-sso',
                    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
                })
            }
        });
       

    }  , [])
    useEffect( () => {
        
        if( !ready && initialized && !offline ){
            setTimeout( () => {
                console.log( " Login ");
                if( !ready && !keycloak.authenticated ){                    
                    keycloak.login();
                }
                if( keycloak.token){
                    setToken( keycloak.token || '' );
                    setTokenParsed( keycloak.tokenParsed || {} );
                }
            } , 1500 );
        } 
        
    } , [ready , initialized , offline ])

    useEffect( () => {
        if( offline ){
            return;
        }
        let loaded = true;
        const checks = () => { 
            if( !loaded ) return;
            
            if( keycloak.authenticated !== ready ){
                setReady( keycloak.authenticated || false );
            }
            if( keycloak.token !== token ){
                setToken( keycloak.token || '' );
            }
            if( keycloak.tokenParsed &&  JSON.stringify( keycloak.tokenParsed ) !== JSON.stringify( tokenParsed ) ){
                console.log( keycloak.tokenParsed );
                setTokenParsed( keycloak.tokenParsed || {} );
            }
            const now =  Math.round(new Date().getTime() / 1000 );
            if( !firstLoop && (keycloak.tokenParsed?.exp || 0 ) - ( now + 60  ) <= 0 ){
                checkOnline().then( (online) => {
                    if( online ){
                        keycloak.updateToken && keycloak.updateToken( 60 * 60 );
                    }
                });
                
            }
            if( firstLoop){
                setFirstLoop( false );
            }
            
        };
        const iv = setInterval( checks , 1000 * 60  ); 
        checks();

        keycloak.onTokenExpired = () => {
            keycloak.updateToken && keycloak.updateToken( 60 * 15 );
        } 
        return () =>{
            loaded = false;
            clearInterval( iv );
        }
    } , [token , offline ])

    const login = () => {
        keycloak?.login();
    }
    const logout = () => {
        keycloak?.logout();
    }
    useEffect( () => {
        if ( !tokenParsed?.email ){
            return;
        }

        LogRocket.identify('enlightened/enlightenedmsp', {
            name: `${tokenParsed?.given_name} ${tokenParsed?.family_name}`,
            email: tokenParsed?.email,
            AUTOTASK_ID : tokenParsed?.AUTOTASK_ID,
            CORE_COMPANY_ID: tokenParsed?.CORE_COMPANY_ID,
          }); 
    } , [JSON.stringify( tokenParsed )])
    
    if( !token || !initialized || offline ){
        return <KeycloakContext.Provider value={{  offline : true , login: () => {} , logout: () => {} , ready : false , token : '' , tokenParsed : {} }}>{children}</KeycloakContext.Provider>
    }
    return (
        <KeycloakContext.Provider value={{  offline : false , login , logout , ready , token , tokenParsed  }}>{children}</KeycloakContext.Provider>
    )
}