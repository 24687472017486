import { Button, Container, Paper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StyledTable, TableColumnDef } from "../../react-submodules/components/Table/Table";
import { useGetSoftwareQuery } from "./gql/GetSoftware.generated";

const columns: TableColumnDef[] = [
    {
        field: 'id',
        flex: 1,
        headerName: 'ID',
        sortable: true
    },
    {
        field: 'name',
        flex: 1,
        sortable: true,
        headerName: 'Name'
    },
    {
        field: 'description',
        flex: 2,
        headerName: 'Description'
    },
    {
        field: 'vendor',
        flex: 1,
        headerName: 'Vendor'
    },
    {
        field: 'Tags',
        flex: 1,
        headerName: 'Tags',
        Element: ({ cell }) => {
            if (!cell || !cell.length) return null;
            return (
                <div>
                    {cell.map((tag: any, index: number) => (
                        <span key={index} style={{ marginRight: '4px' }}>
                            {tag.name}{index < cell.length - 1 ? ', ' : ''}
                        </span>
                    ))}
                </div>
            );
        }
    }
];

export const SoftwareSearch = () => {
    const nav = useNavigate();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(25);
    const [search, setSearch] = useState('');
    
    const { data,  refetch } = useGetSoftwareQuery({
        variables: {
            page,
            limit,
            search: search || undefined
        },
        fetchPolicy: "cache-and-network"
    });

    const software = data?.findCoreDeviceSoftwarePaginated?.data || [];
    const totalCount = data?.findCoreDeviceSoftwarePaginated?.totalCount || 0;

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
        setPage(0); // Reset to first page when search changes
    };

    useEffect(() => {
        const getData = setTimeout(() => {
            refetch({
                page,
                limit,
                search: search || undefined
            });
        }, 500);

        return () => clearTimeout(getData);
    }, [search, page, limit]);

    return (
        <>
            <Container sx={{ marginTop: 2 }}>
                <Paper elevation={2} sx={{ padding: 1 }}>
                    <TextField
                        fullWidth
                        label="Search"
                        value={search}
                        onChange={handleSearchChange}
                    />
                </Paper>
            </Container>

            <Container sx={{ marginTop: 2 }}>
                <Paper>
                    <StyledTable
                        rows={software}
                        columns={columns}
                        onClick={(row) => {
                            nav(`/software/${row.id}`);
                        }}
                        actions={<Button variant="contained" color="secondary">Add</Button>}
                        enablePagination={true}
                        totalCount={totalCount}
                        page={page}
                        limit={limit}
                        setPage={setPage}
                        setPageSize={setLimit}
                    />
                </Paper>
            </Container>
        </>
    );
}