
import { Box, Button, ButtonGroup } from "@mui/material";
import { FC, useMemo } from "react"
import { LabeledBox } from "../../../react-submodules/components/LabeldBox";

const daysOfTheWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
export const DaysOfTheWeekEdit : FC<{ row : Record<string, any> , value : any , onChangeField : (field: string, value: any) => void }> = ({ row , value , onChangeField }) => {
    const selectedDays = useMemo(() => {
        if( !value || !Array.isArray(value) ) {
            return daysOfTheWeek;
        }
        return value;
    }, [value]);
    const toggleDay = (day: string) => {
        const newDays = [...selectedDays];
        if( newDays.includes(day) ) {
            newDays.splice(newDays.indexOf(day), 1);
        } else {
            newDays.push(day);
        }
        onChangeField('startTimeDay_daysOfWeek', newDays);
    }
    return <><LabeledBox label="Days of the Week">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.25 }}>
        <ButtonGroup>{daysOfTheWeek.map(day => <Button key={day} variant="contained" color={selectedDays.includes(day) ? 'success' : 'inherit'} onClick={() => toggleDay(day)} >{day}</Button>)}</ButtonGroup>
        </Box>
        </LabeledBox></>
}