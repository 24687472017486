import { Autocomplete, Box, Button, DialogActions, FormHelperText, Grid, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material';
import { AutotaskCompany, AutotaskContact } from '../../types/autotask.dto';
import { FC, useEffect, useMemo, useState } from 'react';

import { Accessories } from './NewComputerWizard';
import { DeviceQueryItem } from '../../types/dattormm.dto';
import { MinimumInventory } from '../../types/inventory.dto';
import { StyledTable } from '../Table';
import { useApi } from '../../providers/Api/Api';
import {usePDF} from 'react-to-pdf';
import { useUser } from '../../providers/User';

const getDefaultDeliveryDate = () => {
    return new Date(  new Date().getTime() + (1000 * 60 * 60 * 24 * 7) ).toISOString().split("T")[0];
}

const ContactField : FC<{
    email: string;
    setEmail: ( s : string ) => any
    contacts: AutotaskContact[];
    contact?: AutotaskContact | null;
    label?: string;
}> = ( { email , setEmail , contacts , contact, label } ) => {
    label = label || 'Email';
    const options = contacts.map( c => {
        return {
            name: `${c.firstName} ${c.lastName} ( ${c.emailAddress})`,
            value: c.emailAddress
        }
    })
    return <>
        
        <Autocomplete
            freeSolo
            fullWidth
            options={options}
            value={email}
            size='small'
            getOptionLabel={( v ) => {
                return typeof v === "string" ? v : v.name || v.value || '';
            }}
            onChange={( e , i) =>{
                setEmail( typeof i ==="string" ? i : i?.value || '' )
            }}
            renderInput={(params) => (
                <TextField

                  {...params}
                  label={label}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
        />
        <FormHelperText >{contact?.firstName} {contact?.lastName}</FormHelperText>
        </>
}


export const Review : FC<{
    onSelect?: ( id : number ) => any,
    computerId : number,
    inventory: MinimumInventory[],
    accessories: Record< Accessories , number >,
    updateAccessory: ( key : Accessories , value : number ) => any,
    enableDownload?: boolean;
    companyID: number;
    device?: DeviceQueryItem;
    onClose: (ticketNumber?: string ) => any;

}> = ({ inventory, accessories , computerId , enableDownload , device , companyID , onClose }) => {
    const user = useUser();
    const api = useApi();

    const [ company , setCompany ] = useState<AutotaskCompany |  null >( null);
    const [ contacts , setContacts ] = useState<AutotaskContact[] |  null >( null);    
    const [ submitting , setSubmitting ] = useState<boolean>( false );
    // const [ endUserName , setEndUserName ] = useState<string>('');
    const [ endUserEmail , setEndUserEmail ] = useState<string>('');
    // const [ pocName , setPocName ] = useState<string>('');
    const [ pocEmail , setPocEmail ] = useState<string>('');
    useEffect( () => {
        let loaded = true;
        if( api.ready){
            api.get(`/autotask/company/${companyID}`).then( ( { data })=>{
                if( loaded){
                    setCompany(data);
                }
            });
            api.get<AutotaskContact[]>(`/autotask/contacts/${companyID}`).then( ( { data })=>{
                if( loaded){
                    setContacts(data);
                    const poc = data.find( c => c.emailAddress === user.email );
                    if( poc ){
                        setPocEmail( poc.emailAddress || '');
                    }
                    const userameparts = device?.device_lastLoggedInUser?.split("\\") ;
                    const eun = userameparts && userameparts[ userameparts?.length -1 ];
                    const enduser = data.find( c => { 
                        const username = c?.emailAddress?.split("@")[0]
                        return eun === username;
                    } );
                    if( enduser){
                        setEndUserEmail( enduser.emailAddress || '');
                    }
                }
            });
        }
        return () => {
            loaded = false;
        }
    }, [api.ready , companyID]);

    const endUser = useMemo( () => {
        if( contacts && endUserEmail){
            return contacts.find( c => c?.emailAddress === endUserEmail ) || null;
        }
        return null;
    } , [JSON.stringify( contacts) , endUserEmail ] );
    const pocUser = useMemo( () => {
        if( contacts && pocEmail){
            return contacts.find( c => c?.emailAddress === pocEmail ) || null;
        }
        return null;
    } , [JSON.stringify( contacts) , pocEmail ] );

    const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});

    const [ deliverDate , setDeliverDate ] = useState<string|null>(getDefaultDeliveryDate());


    const computer = useMemo( () => {
        return inventory.find( i => i.id === computerId);
    } , [JSON.stringify( inventory)]);
    const monitor = useMemo( () => {
        return inventory.find( i => i.id === 8);
    } , [JSON.stringify( inventory)]);
    const dock = useMemo( () => {
         return inventory.find( i => i.id === 9);

    } , [JSON.stringify( inventory)]);
    const cable = useMemo( () => {
        return inventory.find( i => i.id === 3);
    } , [JSON.stringify( inventory)]);
    const rows = useMemo( () => {
        const r : Array<MinimumInventory & {quantity : number , subtotal: number  }> = [];
        if( computer){
            r.push( { ...computer , quantity: 1 , subtotal : computer.price * 1  });
        }
        if( monitor && accessories.monitor ){
            r.push( { ...monitor , quantity: accessories.monitor , subtotal : accessories.monitor * monitor.price  });
        }
        if( dock && accessories.dock ){
            r.push( { ...dock , quantity: accessories.dock , subtotal : dock.price * accessories.monitor });
        }
        if( cable && accessories.cable ){
            r.push( { ...cable , quantity: accessories.cable  , subtotal : cable.price * accessories.cable });
        }
        return r;
        
    } , [computer , monitor , dock , cable , JSON.stringify( accessories )]);

    const subtotal = useMemo( () => {
        return rows.reduce( ( pv , cv ) => {
            return cv.subtotal + pv;
        } , 0)
    } , [JSON.stringify( rows )])


    const description = useMemo( () => {
        
        return `Requester: ${user.name} ( ${user.email} )
End User: ${endUser?.firstName} ${endUser?.lastName}  ( ${endUserEmail} )
POC: ${pocUser?.firstName} ${pocUser?.lastName}  ( ${pocEmail} )

${device && `
Old Device Hostname: ${device.device_hostname}
`}

Desired Delivery Date : ${new Date(deliverDate || '').toLocaleDateString()}

Approved Inventory
${rows.map( r => {
    return `${r.quantity} ${r.name}  @ $${r.price.toFixed(2)} each`
}).join(`\n`)}

Subtotal $${subtotal.toFixed(2)}
`

    } , [ subtotal , JSON.stringify( rows ) , JSON.stringify( user) , JSON.stringify( pocUser) , JSON.stringify( endUser) , JSON.stringify( device) , endUserEmail , pocEmail]);
    const title = device?.device_hostname ? `${company?.companyNumber} - Replace ${device?.device_hostname}` : `${company?.companyNumber} - New Computer`
    const approve = () => {
        setSubmitting( true );
        api.post(`/autotask/createTicket/${companyID}` , {
            title,
            description,
            enlightendTicketClass: device ? "HARDWARE_REPLACEMENT" : "NEW_HARDWARE",
            nextActionDate: deliverDate && new Date( new Date( deliverDate).getTime() - (1000 * 60 * 60 * 24 * 7) ).toISOString(),
            rmmDeviceID: device?.device_id,
            contactEmail: pocEmail
        }).then( ( { data }) => {            
            onClose(data?.ticketNumber);
            setSubmitting( false );
        })
    }
    return <Box>
        <Box ref={targetRef} sx={{marginBottom: 2}}>
        <Box sx={{
            padding: 2
        }}>
        <Grid container>
            <Grid sm={4}>
            <Typography variant='h5'>{company?.companyName}</Typography>
                
            </Grid>
            <Grid sm={8}>
            <Box sx={{ padding: 2 }}><ContactField 
                label="End User"
                email={endUserEmail}
                contact={endUser}
                setEmail={setEndUserEmail}
                contacts={contacts||[]}
                />
                </Box>
                <Box sx={{ padding: 2 }}>
            <ContactField
                label="Point of Contact"
                email={pocEmail}
                contact={pocUser}
                setEmail={setPocEmail}
                contacts={contacts||[]}
                />
                </Box>
            </Grid>
        </Grid>
        <StyledTable
            title='Estimate'
            columns={[{
                field: 'name'
            },
            {
                field: 'quantity'
            },
            {
                field: 'price',
                headerName: 'unit price',
                Element: ({cell , row} ) => {
                    
                    return<>${( typeof cell === "number" ? cell : parseFloat( cell as any ) ).toFixed(2)}</>
                }
            },
            {
                field: 'subtotal',
                headerName: 'subtotal',
                Element: ({cell , row} ) => {
                    
                    return<>${ (cell).toFixed(2)}</>
                }
            },
        ]}
            rows={rows}
        ></StyledTable>
    </Box>
    <Box sx={{display: 'flex'}}>
        <Box sx={{marginLeft: 2}}>
            
        <TextField type="date" 
            label="Desired Delivery Date"
            value={deliverDate} onChange={(e)=>{
                setDeliverDate( e.target.value);
            }} />
        </Box>
        <Box  sx={{ flexGrow: 1 }}>&nbsp;</Box>
        <Box sx={{marginRight: 2}}>
        <Typography variant='h6'>Subtotal : ${subtotal.toFixed( 2)}</Typography>
        <Typography variant='caption'>Pricing subject to change</Typography>
        
        </Box>
    </Box>
    
    </Box>
    <DialogActions>
        { enableDownload && <Button onClick={()=>{
        toPDF();
    }}>Download</Button> }
    <Button disabled={submitting}  variant='contained' color="secondary" onClick={()=>{
        approve();
    }}>{ submitting ?  "Submitting" : "Approve" }</Button>
    </DialogActions>
    </Box>
}