import React, { FC } from 'react';
import { Typography, Box, Chip } from '@mui/material';
import { AzureEmail } from '../../types/azure';
import { HtmlView } from '../HtmlView';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { AnyBadge } from '../AnyBadge';
import { AttachmentChip } from '../AttachmentChip';

interface EmailViewProps {
  email: AzureEmail;
}

const EmailBadge : FC<{ emailAddress : string }> = ( { emailAddress } ) => {
  return <AnyBadge widthMultiplyer={(emailAddress?.length || 1) * .3} customkey={emailAddress || "Unknown"} name={emailAddress || "Unknown"} colorkey={emailAddress || "Unknown"} />
}

export const EmailView: React.FC<EmailViewProps> = ({ email }) => {
  const from = email.participants.find(p => p.userRole === 'from');
  const to = email.participants.filter(p => p.userRole === 'to');
  const cc = email.participants.filter(p => p.userRole === 'cc');

  const handleAttachmentClick = (contentLocation: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = contentLocation;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const attachments = email.attachments.filter(a => !a.isInline);

  return (
    <Box>
      <Typography variant="h6">{email.subject}</Typography>
      <Typography variant="subtitle2">
        {new Date(email.createdDateTime).toLocaleString()}
      </Typography>

      <Box my={2}>
        <Typography><strong>From:</strong><EmailBadge emailAddress={from?.emailAddress || "Unknown"} /></Typography>
        <Typography><strong>To:</strong> {to.map(p => <EmailBadge key={p.emailAddress} emailAddress={p.emailAddress} />)}</Typography>
        {cc.length > 0 && (
          <Typography><strong>CC:</strong> {cc.map(p => <EmailBadge key={p.emailAddress} emailAddress={p.emailAddress} />)}</Typography>
        )}
      </Box>

      {attachments.length > 0 && (
        <Box mb={2}>
          <Typography><strong>Attachments:</strong></Typography>
          {attachments.map((attachment, index) => (
            <AttachmentChip
              key={index}
              attachment={attachment}
            />
          ))}
        </Box>
      )}

      <Box mt={2}>
        <HtmlView html={email.bodyHtml} />
      </Box>
    </Box>
  );
};