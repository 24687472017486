import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeviceCoreCompanyByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetDeviceCoreCompanyByIdQuery = { __typename?: 'Query', getCoreCompaniesById: { __typename?: 'CoreCompanies', name: string, autotaskCompanyId?: number | null } };


export const GetDeviceCoreCompanyByIdDocument = gql`
    query GetDeviceCoreCompanyById($id: Int!) {
  getCoreCompaniesById(id: $id) {
    name
    autotaskCompanyId
  }
}
    `;

/**
 * __useGetDeviceCoreCompanyByIdQuery__
 *
 * To run a query within a React component, call `useGetDeviceCoreCompanyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceCoreCompanyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceCoreCompanyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDeviceCoreCompanyByIdQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceCoreCompanyByIdQuery, GetDeviceCoreCompanyByIdQueryVariables> & ({ variables: GetDeviceCoreCompanyByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviceCoreCompanyByIdQuery, GetDeviceCoreCompanyByIdQueryVariables>(GetDeviceCoreCompanyByIdDocument, options);
      }
export function useGetDeviceCoreCompanyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceCoreCompanyByIdQuery, GetDeviceCoreCompanyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviceCoreCompanyByIdQuery, GetDeviceCoreCompanyByIdQueryVariables>(GetDeviceCoreCompanyByIdDocument, options);
        }
export function useGetDeviceCoreCompanyByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDeviceCoreCompanyByIdQuery, GetDeviceCoreCompanyByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDeviceCoreCompanyByIdQuery, GetDeviceCoreCompanyByIdQueryVariables>(GetDeviceCoreCompanyByIdDocument, options);
        }
export type GetDeviceCoreCompanyByIdQueryHookResult = ReturnType<typeof useGetDeviceCoreCompanyByIdQuery>;
export type GetDeviceCoreCompanyByIdLazyQueryHookResult = ReturnType<typeof useGetDeviceCoreCompanyByIdLazyQuery>;
export type GetDeviceCoreCompanyByIdSuspenseQueryHookResult = ReturnType<typeof useGetDeviceCoreCompanyByIdSuspenseQuery>;
export type GetDeviceCoreCompanyByIdQueryResult = Apollo.QueryResult<GetDeviceCoreCompanyByIdQuery, GetDeviceCoreCompanyByIdQueryVariables>;