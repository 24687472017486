// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.printheader {
    string-set: title content();
    display: none; /* Hide this element in the normal view */
}

@page testing {
    margin: 2cm;
    @top-left {
        content: "Page " counter(page);
    }
}

/* Additional styles for print */
@media print {
    .print-container {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Billing/printview.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,aAAa,EAAE,yCAAyC;AAC5D;;AAEA;IACI,WAAW;IACX;QACI,8BAA8B;IAClC;AACJ;;AAEA,gCAAgC;AAChC;IACI;QACI,WAAW;QACX,SAAS;QACT,UAAU;IACd;AACJ","sourcesContent":[".printheader {\n    string-set: title content();\n    display: none; /* Hide this element in the normal view */\n}\n\n@page testing {\n    margin: 2cm;\n    @top-left {\n        content: \"Page \" counter(page);\n    }\n}\n\n/* Additional styles for print */\n@media print {\n    .print-container {\n        width: 100%;\n        margin: 0;\n        padding: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
