import { Box, MenuItem, TextField, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { FieldPicklist, useTicketApi } from "../../providers/TicketApiProvider";

import { BadgeAndName } from "./BadgeAndName";
import { InlineIcon } from "@iconify/react";
import { TBillable } from "../../types/ticketqueue";

const p = [
    {"label": "High", "value": "1", "isActive": true, "isSystem": false, "sortOrder": 3, "parentValue": "", "isDefaultValue": false}, 
    {"label": "Medium", "value": "2", "isActive": true, "isSystem": false, "sortOrder": 5, "parentValue": "", "isDefaultValue": false},
     {"label": "Low", "value": "3", "isActive": true, "isSystem": false, "sortOrder": 6, "parentValue": "", "isDefaultValue": false},
      {"label": "Critical", "value": "4", "isActive": true, "isSystem": false, "sortOrder": 1, "parentValue": "", "isDefaultValue": false}, 
      {"label": "Unspecified", "value": "5", "isActive": true, "isSystem": false, "sortOrder": 2, "parentValue": "", "isDefaultValue": false}, 
      {"label": "Med High", "value": "6", "isActive": false, "isSystem": false, "sortOrder": 4, "parentValue": "", "isDefaultValue": false}];
      const billableToIcon = ( billable?: TBillable ) => {
        switch( billable ){
            // Critical
            case 'Hourly':
                return 'zondicons:hour-glass';
            // Unspecified
            case 'Managed':
                return 'clarity:checkbox-list-line';
            default:
                return 'fluent-mdl2:unknown-solid';
        }
  }
      const billableToColor = ( billable? : TBillable ) => {
            switch( billable ){
                // Critical
                case "Managed":
                    return '0099ff';
                // Unspecified
                case "Hourly":
                    return ' #cccc00';
                default:
                    return '#ff0000';
            }
      }

export const Billable : FC<{
    iconOnly?: boolean;
    edit?: boolean;
    billable?: TBillable
    onChange?: (priority: TBillable) => any;
}> = ({ billable , edit , onChange , iconOnly}) => {
    
    if( !edit ){
        return <Typography><InlineIcon color={billableToColor( billable)} icon={billableToIcon(  billable )} />&nbsp;{!iconOnly && billable}</Typography>
    }
    return <TextField
    size="small"
    fullWidth
    select
    label="Billable"
    value={billable}
    
    error={!['Hourly' , 'Managed'].includes( billable || '' )}

    onChange={(val)=>{
      onChange && onChange( val.target.value as any );
    }}
  >
    {( ['Managed' , 'Hourly' , 'TBD' , null ] as TBillable[] ).map((option) => (
      <MenuItem key={option|| '_DEFAULT'} value={option||''} sx={{display: 'flex' , flexDirection: 'row'}}>
        <Typography><InlineIcon color={billableToColor(option )} icon={billableToIcon(option )} />&nbsp;{option}</Typography> 
      </MenuItem>
    ))}
  </TextField>
}