import { FC , useEffect , useState , useMemo , useCallback } from "react";
import { useApi } from "../../providers/Api/Api";
import { Table, TableBody, TableCell, TableHead, TableRow , Badge , ListItem , ListItemText, Typography, Box} from "@mui/material";
import { CoreServices } from "../../types/services.dto";
const NA = '--';

const displayPrice = (service?: CoreServices | null ) => {
    if( !service ){
        return NA;
    }
    if( typeof service.totalCost === "number" ){
        if( service.totalCost === 0 ){
            return NA;
        }
        return `$${service.totalCost.toFixed(2)}`
    }
    
    const price = service.units * service.unitPrice;
    if( price.toFixed(2) === '0.00' ){
        return NA;
    }
    return `$${price.toFixed(2)}`;
}


const ServiceItem : FC<{
    service : string ,
    services : null |  Record<string,CoreServices>
}> = ( { services , service }) => {
    const getService = ( key : string ) => {
        if( services === null ){
            return null;
        }
        return services[key];
    }
    const displayService = useMemo( () => {
        const s = getService( service );
        if( !s ){
            return '--';
        }
        return displayPrice( s ) ;

    }, [ JSON.stringify( services ) ,service ] );
    const displayServiceSecondary = useMemo( () => {
        const s = getService( service );
        if( !s ){
            return '';
        }
        if( s.units === 0 ){
            return '';
        }
        return `${s.units} @ $${s.unitPrice.toFixed(2)}`;

    }, [ JSON.stringify( services ) , service ] );
    

    return <ListItem><ListItemText 
        primary={displayService}
        secondary={displayServiceSecondary}
     /></ListItem>
}



export const ContractOverview : FC<{
    companyId: string,
}> = ({companyId}) => {
    const api = useApi();
    const [ services , setServices ] = useState<null |  Record<string,CoreServices> >( null );

    useEffect( () => {
        if( api.ready ){
            api.get<{ data : CoreServices}>( `/core/services/${companyId}` ).then( ( results ) => {
                setServices( results.data);
            } )
        }
    } , [ companyId , api.ready ]);
    
    const total = useMemo( () => {
        if( services === null ){
            return 0;
        }
        return Object.values( services ).reduce( ( prev , service ) => {
            if( typeof service.totalCost === "number" ){
                return prev + service.totalCost;
            }
            return prev + ( service.units * service.unitPrice );
        } , 0 );
    } , [ services ] );

    
    return <>
    <Table>
        <TableHead>
            <TableRow>
                <TableCell></TableCell>
                <TableCell>Company / Sites</TableCell>
                <TableCell>Servers</TableCell>
                <TableCell>Desktops</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
        <TableRow>
                <TableCell>Managed IT Support</TableCell>
                <TableCell><ServiceItem service="network_management" services={services} /></TableCell>
                <TableCell><ServiceItem service="server_management" services={services} /></TableCell>
                <TableCell><ServiceItem service="desktop_management" services={services} /></TableCell>
            </TableRow>
        <TableRow>
                <TableCell>Cyber Security</TableCell>
                <TableCell><ServiceItem service="darkweb_monitoring" services={services} /></TableCell>
                <TableCell><ServiceItem service="server_security" services={services} /></TableCell>
                <TableCell><ServiceItem service="desktop_security" services={services} /></TableCell>
            </TableRow>
        <TableRow>
                <TableCell>Business Continuity</TableCell>
                <TableCell></TableCell>
                <TableCell><ServiceItem service="datto_backup" services={services} /></TableCell>
                <TableCell><ServiceItem service="saas_backup" services={services} /></TableCell>
            </TableRow>
        </TableBody>
    </Table>
    
    <Box sx={{marginTop: 2 , display: 'flex'}}>
            <Box flexGrow={1}></Box>
            <Box ><Typography variant="h6" noWrap>Total: ${total.toFixed(2)}</Typography></Box>
    </Box>
    </>
}