import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OtherUsersTimepunchesQueryVariables = Types.Exact<{
  ticketId: Types.Scalars['Float']['input'];
  userId: Types.Scalars['String']['input'];
}>;


export type OtherUsersTimepunchesQuery = { __typename?: 'Query', timeclocks: { __typename?: 'PaginatedTimeclocks', data?: Array<{ __typename?: 'CoreTimeclock', id?: number | null, start?: any | null, end?: any | null, ticketId?: number | null, coreUserId?: string | null, user?: { __typename?: 'CoreUsers', id?: string | null, firstName?: string | null, lastName?: string | null } | null }> | null } };


export const OtherUsersTimepunchesDocument = gql`
    query OtherUsersTimepunches($ticketId: Float!, $userId: String!) {
  timeclocks(
    ticketId: {eq: $ticketId}
    end: {null: true}
    user: {id: {not: $userId}}
  ) {
    data {
      id
      start
      end
      ticketId
      coreUserId
      user {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useOtherUsersTimepunchesQuery__
 *
 * To run a query within a React component, call `useOtherUsersTimepunchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOtherUsersTimepunchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOtherUsersTimepunchesQuery({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOtherUsersTimepunchesQuery(baseOptions: Apollo.QueryHookOptions<OtherUsersTimepunchesQuery, OtherUsersTimepunchesQueryVariables> & ({ variables: OtherUsersTimepunchesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OtherUsersTimepunchesQuery, OtherUsersTimepunchesQueryVariables>(OtherUsersTimepunchesDocument, options);
      }
export function useOtherUsersTimepunchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OtherUsersTimepunchesQuery, OtherUsersTimepunchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OtherUsersTimepunchesQuery, OtherUsersTimepunchesQueryVariables>(OtherUsersTimepunchesDocument, options);
        }
export function useOtherUsersTimepunchesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OtherUsersTimepunchesQuery, OtherUsersTimepunchesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OtherUsersTimepunchesQuery, OtherUsersTimepunchesQueryVariables>(OtherUsersTimepunchesDocument, options);
        }
export type OtherUsersTimepunchesQueryHookResult = ReturnType<typeof useOtherUsersTimepunchesQuery>;
export type OtherUsersTimepunchesLazyQueryHookResult = ReturnType<typeof useOtherUsersTimepunchesLazyQuery>;
export type OtherUsersTimepunchesSuspenseQueryHookResult = ReturnType<typeof useOtherUsersTimepunchesSuspenseQuery>;
export type OtherUsersTimepunchesQueryResult = Apollo.QueryResult<OtherUsersTimepunchesQuery, OtherUsersTimepunchesQueryVariables>;