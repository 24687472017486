
//import { Editable, useEditor } from "@wysimark/react"
import { useState } from "react";

import {MDXEditor ,  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  imagePlugin,
  toolbarPlugin,
  linkDialogPlugin,
  tablePlugin,
  codeBlockPlugin,
  codeMirrorPlugin,
  ConditionalContents,
  ChangeCodeMirrorLanguage,
  linkPlugin,
  RealmPlugin,
  UndoRedo, BoldItalicUnderlineToggles , InsertTable , InsertCodeBlock , CreateLink , InsertImage , InsertThematicBreak
} from '@mdxeditor/editor';

import '@mdxeditor/editor/style.css';

// import { Client, createClient } from "./client"
import { useApi } from "../../providers/Api/Api";
import axios from "axios";
import { Box, InputLabel, Typography } from "@mui/material";


export const MarkdownEditor : React.FC<{ 
  markdown : string
  readOnly?: boolean
  disableImageResize?: boolean
  label?: string
  size?: 'small' | 'medium'
  onChange?: (markdown : string) => void
}> = ({ markdown , readOnly , disableImageResize , size , onChange , label }) => {  
  markdown = markdown || "";
  const {  post  } = useApi();

  const imageUploadHandler = async (image: File) => {
    
    const { data: { url , filename , link } } = await post( '/files/signedput', { filename : image.name , type : image.type } );
    const config = {
      headers: {
        'Content-Type': image.type,
      },
      onUploadProgress: function (progressEvent : any ) {
        Math.round((progressEvent.loaded * 100) / progressEvent.total);
      },
    };

    await axios.put(url, new File([image], filename), config);
    return link;
  }
  const plugins : RealmPlugin[] = [  headingsPlugin(),
    listsPlugin(),
    quotePlugin(),
    tablePlugin(),
    codeBlockPlugin({defaultCodeBlockLanguage: 'ps1'}),
    codeMirrorPlugin({ codeBlockLanguages: { ps1: 'Powershell' , bat : "Batch" , sh : 'Bash' , py : "Python" , js : "Javascript" } }),
    thematicBreakPlugin(),
    markdownShortcutPlugin(),
    imagePlugin({
      imageUploadHandler, 
      disableImageResize: !!disableImageResize ,
    }),
    linkPlugin(),
    linkDialogPlugin(),
  ];
  if( !readOnly){
    plugins.push( toolbarPlugin({
      toolbarContents: () => (
        <ConditionalContents options={
          [
            { when: (editor) => editor?.editorType === 'codeblock', contents: () => <ChangeCodeMirrorLanguage /> },
             { fallback: () => (<><UndoRedo /><BoldItalicUnderlineToggles />
            <InsertTable />
          <InsertCodeBlock />
          <CreateLink />
          <InsertImage />
          <InsertThematicBreak />
          </>) }
          ]
        } />)
    }) )
  }
  if( readOnly ){
    return <MDXEditor suppressHtmlProcessing  onError={(...props) => console.log( props )} markdown={markdown} onChange={onChange} 
        readOnly={readOnly}
        plugins={plugins} />
  }

  // const [markdown, setMarkdown] = useState(DEFAULT_MARKDOWN);
  return <Box sx={{ border : "1px solid rgba(0, 0, 0, 0.2 )" , borderRadius : "5px" , padding: 1 , position:'relative' }}>
    <Box sx={{ position:'absolute' , top : '-13px' , left : '12px' }} ><Typography sx={{
      backgroundColor : "white" ,
      padding : "0 4px",
      color: "rgba(0, 0, 0, 0.5 )"
    }} fontSize="12px" variant="caption">{label}</Typography></Box>
    <MDXEditor suppressHtmlProcessing onError={(...props) => console.log( props )}  markdown={markdown} onChange={onChange} 
  readOnly={readOnly}
  plugins={plugins} />
  
</Box>
  /*
  return <><Editable editor={editor} value={markdown} onChange={setMarkdown} />
  <pre>{markdown}</pre>
  <button onClick={showMarkdown}>Show Markdown</button>
  </> */
};

