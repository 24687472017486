import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from "@mui/material";
import { DialogTitle } from "@mui/material";
import { DatePicker, LocalizationProvider, TimePicker  } from "@mui/x-date-pickers";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from "luxon";
import { FC, useEffect, useState } from "react";
import { MarkdownEditor } from "../../../../react-submodules/components/MarkdownEditor/MarkdownEditor";
import { UserPicker } from "../components/UserPicker";
import { useCreateScheduleInstanceMutation } from "./gql/CreateScheduleInstance.generated";

export type QuickEditSchedulePrimaryProps = {
    date?: string;
    coreUserId?: string;
}




export const QuickScheduleEdit : FC<{
    open?: boolean;
    onClose?: () => void;
    afterSubmit?: () => void;
} & QuickEditSchedulePrimaryProps > = ({ open , date , coreUserId : coreUserIdProp , onClose , afterSubmit }) => {
    
    const [ startDate , setStartDate ] = useState<DateTime | null >( date ? DateTime.fromFormat(date , 'yyyy-MM-dd') : DateTime.now() );
    useEffect(() => {
        setStartDate( date ? DateTime.fromFormat(date , 'yyyy-MM-dd') : DateTime.now() );
    }, [date]);
    const [ startDateTime , setStartDateTime ] = useState<DateTime | null >( date ? DateTime.fromFormat(date , 'yyyy-MM-dd') : null);
    const [ description , setDescription ] = useState<string | null >(null);
    const [ endDateTime , setEndDateTime ] = useState<DateTime | null >( date ? DateTime.fromFormat(date , 'yyyy-MM-dd') : null);
    useEffect(() => {
        if ( startDate ) {
            setStartDateTime(startDate.set({ hour : 7 , minute : 0 , second : 0 , millisecond : 0 }));
            setEndDateTime(startDate.set({ hour : 17 , minute : 0 , second : 0 , millisecond : 0 }));
        }
    }, [startDate]);
    const [ coreUserId , setCoreUserId ] = useState<string | null >(coreUserIdProp || null);
    const [ title , setTitle ] = useState<string | null >(null);
    const [ createScheduleInstance ] = useCreateScheduleInstanceMutation();
    useEffect(() => {
        if ( open ) {
            const start = DateTime.now().startOf('hour');
            const end = start.plus({ hours : 1 });
            setStartDateTime(start);
            setEndDateTime(end);
        }
    }, [date , open ]);
    const scheduleInstance = () => {
        if (!startDateTime || !endDateTime || !coreUserId || !startDate) return;
        
        // Combine date from startDate with time from startDateTime
        const finalStartDateTime = startDate.set({
            hour: startDateTime.hour,
            minute: startDateTime.minute
        });

        // Combine date from startDate with time from endDateTime
        let finalEndDateTime = startDate.set({
            hour: endDateTime.hour,
            minute: endDateTime.minute
        });

        // If end time is before start time, add one day to end date
        if (finalEndDateTime < finalStartDateTime) {
            finalEndDateTime = finalEndDateTime.plus({ days: 1 });
        }
        
        createScheduleInstance({ variables: {
            available: false,
            startDateTime: finalStartDateTime.toJSDate(),
            endDateTime: finalEndDateTime.toJSDate(),
            coreUserId: coreUserId,
            title : title?.trim() || null,
            description : description?.trim() || null,
        }}).then(() => {
            onClose?.();
            afterSubmit?.();
        });
    }



    return <Dialog open={open ?? false} onClose={onClose}>
        <DialogTitle>Quick Schedule Edit</DialogTitle>
        <DialogContent>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <TextField label="Title" sx={{ width : '100%' , mt : 2 }} value={title} onChange={e => setTitle(e.target.value)} />
                    </Grid>
                    <Grid item sm={12}>
                        <MarkdownEditor label="Description" markdown={description ?? ''} onChange={e => setDescription(e)} />
                    </Grid>
                    <Grid item sm={12}>
                        <DatePicker label="Date" sx={{ width : '100%' , mt : 2 }} value={startDate} onChange={setStartDate} />
                    </Grid>
                    <Grid item sm={12}>
                        <TimePicker label="Start Time" sx={{ width : '100%' }} value={startDateTime} onChange={setStartDateTime} />
                    </Grid>
                    <Grid item sm={12}>
                        <TimePicker label="End Time" sx={{ width : '100%' }} value={endDateTime} onChange={setEndDateTime} />
                    </Grid>
                    <Grid item sm={12}>
                        <UserPicker value={coreUserId} onChange={setCoreUserId} />
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </DialogContent>
        <DialogActions>
            <Button onClick={scheduleInstance}>Create</Button>
        </DialogActions>
    </Dialog>;
}