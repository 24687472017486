import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";

import { Form } from "../../../coretypes/form";
import { FormField } from "../../../coretypes/formfields";
import { FormFieldEdit } from "./component/FormFieldEdit";
import { FormFieldType } from "./component/FormFieldType";
import { useApi } from "../../providers/Api/Api";
import { useParams } from "react-router-dom";

type FormFieldState = Partial<FormField> & Pick<FormField , 'name' | 'label' | 'type'>;
const DEFAULT_FORM_FIELD : FormFieldState = { name: '', label: '', type: 'TEXT' }; 

export const FormBuilder = () => {
    const { id } = useParams();
    const api = useApi();
    const [form, setForm] = useState<Form>();
    const [formFields , setFormFields] = useState<FormField[]>([]);

    const [ formDirty , setFormDirty ] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [newField, setNewField] = useState<FormFieldState>(DEFAULT_FORM_FIELD);

    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    

    const handleAddNewField = () => {
        // console.log(newField); // For demonstration
        // setFormFields([...formFields, newField]);
        
        handleCloseDialog();
        
        api.post(`/forms/field` , {...newField , formId : id  }).then( ( {data} ) => {
            console.log( { data });
            setFormFields( ff => [...ff , data] );
        });
        
        setNewField(DEFAULT_FORM_FIELD); // Reset the form
    };
    
    useEffect( () => {
        api.get(`/forms/${id}/full`).then( ({data}) => {
            const { formFields , ...newForm } = data;
            setForm(newForm);
            setFormFields(formFields);
        });
    } , [ id ]);

    

    const updateFormParam = (key: string , value: any) => {
        setFormDirty(true);
        if ( form ) {
            if( key === 'identifier'){
                value = value.toLowerCase().replace(/ /g, '_');
            }
            setForm( { ...form , [key]: value } );
        }
    }
    const updateNewField = (key: string , value: any) => {

        if( key === 'name'){
            value = value.toLowerCase().replace(/ /g, '_');
        }
        setNewField( { ...newField , [key]: value } );
    }
    useEffect( () => { 
        const timeout = setTimeout( () => { 
            setFormDirty(false);
            if( !form ) return;
            api.patch( `/forms/${id}` , form );
        } , 2000 );
        return () => clearTimeout( timeout );
    } , [ formDirty , JSON.stringify( form )]);

    const onFieldChange = ( field : Partial<FormField> & Pick<FormField , 'id'> ) => {
        setFormFields( ff => ff.map( f => f.id === field.id ? { ...f , ... field } : f ) );
    }
    const onFieldDelete = ( field : FormField ) => {
        setFormFields( ff => ff.filter( f => f.id !== field.id ) );
    }


    return <>
        
        <Grid container >
        <Grid item xs={12} >
        <TextField InputLabelProps={{ shrink: true }} sx={{margin: 2 }} fullWidth size="small" variant='outlined' label='Form Name' value={form?.name} onChange={(e) => updateFormParam('name' , e.target.value)} />
        </Grid>
        <Grid item xs={12} >
        <TextField InputLabelProps={{ shrink: true }} sx={{margin: 2 }} fullWidth size="small" variant='outlined' label='Form Description' value={form?.description} onChange={(e) => updateFormParam('description' , e.target.value)} />   
        </Grid>
        <Grid item xs={12} >
        <TextField InputLabelProps={{ shrink: true }} sx={{margin: 2 }} fullWidth size="small" variant='outlined' label='Form Context Identifer' value={form?.identifier} onChange={(e) => updateFormParam('identifier' , e.target.value)} />   
        </Grid>
        </Grid>
        <Button size="small" variant="contained" onClick={handleOpenDialog}>Add Field</Button>

        <Grid>
            { formFields.map( ff => <FormFieldEdit onFieldDelete={onFieldDelete} onFieldChange={onFieldChange} controlled key={ff.id} field={ff} /> ) }
        </Grid>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>Add New Field</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Field Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={newField.name}
                    onChange={(e) => {
                        updateNewField('name' , e.target.value);
                    }}
                />
                <TextField
                    margin="dense"
                    id="description"
                    label="Field Label"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={newField.label}
                    onChange={
                        (e) => {
                            updateNewField('label' , e.target.value);
                        }
                    }
                />
                <FormFieldType margin="dense" fullWidth type={newField.type} onChange={
                            (e) => {
                                updateNewField('type' , e.target.value);
                            }
                        }  />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Cancel</Button>
                <Button onClick={handleAddNewField}>Add</Button>
            </DialogActions>
        </Dialog>
    </>;
};

