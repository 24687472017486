import { Box, Card,  TextField, CardContent, Typography, Tab, TabProps, Badge } from "@mui/material";
import { FC } from "react";
import { useTicketEmailsForAttachmentsQuery } from "./gql/TicketEmailsForAttachments.generated";

export const AttachmentsTab : FC<TabProps & {
    ticketId : number;

}> = ({ ticketId , ...props }) => {
    const { data } = useTicketEmailsForAttachmentsQuery({
        variables: {
            autotaskTicketId: ticketId,

        }
    });
    const allAttachments = ( data?.findAzureEmailPaginated?.data || [] ).flatMap( e => e.AzureEmailAttachments || [] ).filter( a => a.isInline === false );
    console.log( allAttachments );
    if( allAttachments.length === 0 ){
        return null;
    }
    return <Tab  {...props} label={`Attachments (${allAttachments.length})`} value="Attachments" />;
}
