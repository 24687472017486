import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TicketFieldsFragment = { __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, status: number, title: string };

export type GetParentAndChildInfoQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetParentAndChildInfoQuery = { __typename?: 'Query', getAutotaskTicketsById: { __typename?: 'AutotaskTickets', coreCompanyId?: number | null, ParentTicket?: { __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, status: number, title: string, ParentTicket?: { __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, status: number, title: string } | null, ChildTickets?: Array<{ __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, status: number, title: string }> | null } | null, ChildTickets?: Array<{ __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, status: number, title: string, ChildTickets?: Array<{ __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, status: number, title: string, ChildTickets?: Array<{ __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, status: number, title: string }> | null }> | null }> | null } };

export const TicketFieldsFragmentDoc = gql`
    fragment TicketFields on AutotaskTickets {
  id
  ticketNumber
  status
  title
}
    `;
export const GetParentAndChildInfoDocument = gql`
    query GetParentAndChildInfo($id: Int!) {
  getAutotaskTicketsById(id: $id) {
    coreCompanyId
    ParentTicket {
      ...TicketFields
      ParentTicket {
        ...TicketFields
      }
      ChildTickets {
        ...TicketFields
      }
    }
    ChildTickets {
      ...TicketFields
      ChildTickets {
        ...TicketFields
        ChildTickets {
          ...TicketFields
        }
      }
    }
  }
}
    ${TicketFieldsFragmentDoc}`;

/**
 * __useGetParentAndChildInfoQuery__
 *
 * To run a query within a React component, call `useGetParentAndChildInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentAndChildInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentAndChildInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetParentAndChildInfoQuery(baseOptions: Apollo.QueryHookOptions<GetParentAndChildInfoQuery, GetParentAndChildInfoQueryVariables> & ({ variables: GetParentAndChildInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParentAndChildInfoQuery, GetParentAndChildInfoQueryVariables>(GetParentAndChildInfoDocument, options);
      }
export function useGetParentAndChildInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParentAndChildInfoQuery, GetParentAndChildInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParentAndChildInfoQuery, GetParentAndChildInfoQueryVariables>(GetParentAndChildInfoDocument, options);
        }
export function useGetParentAndChildInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetParentAndChildInfoQuery, GetParentAndChildInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetParentAndChildInfoQuery, GetParentAndChildInfoQueryVariables>(GetParentAndChildInfoDocument, options);
        }
export type GetParentAndChildInfoQueryHookResult = ReturnType<typeof useGetParentAndChildInfoQuery>;
export type GetParentAndChildInfoLazyQueryHookResult = ReturnType<typeof useGetParentAndChildInfoLazyQuery>;
export type GetParentAndChildInfoSuspenseQueryHookResult = ReturnType<typeof useGetParentAndChildInfoSuspenseQuery>;
export type GetParentAndChildInfoQueryResult = Apollo.QueryResult<GetParentAndChildInfoQuery, GetParentAndChildInfoQueryVariables>;