import { FormControlLabel, Switch, TextField } from "@mui/material";
import { MarkdownView } from "../../../MarkdownView";
import { MarkdownEditor } from "../../MarkdownEditor/MarkdownEditor";
import { useEffect, useState, useRef } from "react";
import { useMounted } from "../../../hooks/useMounted";

type WithDescription = {
    description?: string | null | undefined;
}
export const GqlDescription = <T extends WithDescription>({ 
    ticket,
    edit,
    onChange
  }: {
    ticket: T;
    edit?: boolean;
    onChange?: (current: T | ((prev: T) => T)) => void;
  }) => {
    const [reset, setReset] = useState(false);
    const mounted = useMounted();
    const prevTicketIdRef = useRef<string | number | null>(null);
    
    // Reset only when the ticket ID changes (new ticket loaded)
    useEffect(() => {
      // @ts-ignore - We're just using this to detect ticket changes
      const currentTicketId = ticket?.id || null;
      
      if (prevTicketIdRef.current !== currentTicketId) {
        setReset(true);
        prevTicketIdRef.current = currentTicketId;
      }
    }, [ticket]);
    
    useEffect(() => {
      if (reset) {
        setTimeout(() => {
          mounted() && setReset(false);
        }, 10);
      }
    }, [reset, mounted]);
    
    if (!edit) {
      return <MarkdownView>{ticket.description}</MarkdownView>;
    }
    
    if (reset) { 
      return <></>;
    }
    
    return (
      <MarkdownEditor 
        label="Description" 
        markdown={ticket.description || ''} 
        onChange={(e) => {
          onChange?.({ ...ticket, description: e });
        }} 
      />
    );
  }