import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "../../../../react-submodules/providers/Api";
import { ADD_CONTACT_ICON, ADD_LOCATION_ICON, EDIT_ICON } from "../../../../react-submodules/constances/icons";
import { Icon } from "@iconify/react";

export const UpdateLocationDialog : FC<{refresh: () => void , id: number , open: boolean , onClose: () => void}> = ({refresh, id , open , onClose}) => {
    
    const api = useApi();

    const [name, setName] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [phone, setPhone] = useState("");
    useEffect( () => {  
        if( !open ){
            return;
        }
        api.get(`/core/locations/get/${id}`).then( ({ data }) => {
            setName(data.name);
            setAddress1(data.address1);
            setAddress2(data.address2);
            setCity(data.city);
            setState(data.state);   
            setZip(data.zip);
            setPhone(data.phone);
        });
    } , [id , open ]);
    useEffect(() => {
        if (open) {
            return;
        }
        setName("");
        setAddress1("");
        setAddress2("");
        setCity("");
        setState("");
        setZip("");
        setPhone(""); 
    }, [open]);

    const save = () => {
        api.post(`/core/locations/update/${id}`, {
            name,
            address1,
            address2,
            city,
            state,
            zip,
            phone
        }).then(() => {
            onClose();
            refresh();
        });
    };

    return <>
        <Dialog open={open} onClose={() => {
            onClose();
        }}>
            <DialogTitle>Edit Location</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ marginTop: 1 }}>
                    <Grid item xs={12}>
                        <TextField fullWidth size="small" label="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth size="small" label="Address 1" value={address1} onChange={(e) => setAddress1(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth size="small" label="Address 2" value={address2} onChange={(e) => setAddress2(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth size="small" label="City" value={city} onChange={(e) => setCity(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth size="small" label="State" value={state} onChange={(e) => setState(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth size="small" label="Zip" value={zip} onChange={(e) => setZip(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth size="small" label="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="success" onClick={save}>Save</Button>
            </DialogActions>
        </Dialog>
    </>
}