import { useEffect, useMemo, useState } from "react";
import { useApi } from "../../react-submodules/providers/Api/Api";
import { LineChart } from "@mui/x-charts";
import { Paper } from "@mui/material";

export const TotalTicketGraph = () => {
    const api = useApi();
    const [data, setData] = useState<Array<{ date : string , count : number }>>([]);

    useEffect(() => {
        api.get('/reports/open/tickets')
            .then((response) => {
                setData(response.data.sort( ( a : any , b : any ) => {
                    return a.date.localeCompare(b.date);
                }));
            })
    }, []);

    const { labels , series } = useMemo(() => {
        return {
            labels : data.map((d) => d.date),
            series : data.map((d) => d.count)
        }
    }, [data]);


    return (
        <Paper sx={{marginTop: 2}}>
            Total Ticket Graph
            <LineChart
      width={1000}
      height={600}
      series={[
        { data: series, label: 'Open Tickets' },
      ]}
      xAxis={[{ scaleType: 'point', data: labels }]}
    />
        </Paper>
    )
}

