import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCoreContactByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetCoreContactByIdQuery = { __typename?: 'Query', getCoreContactById: { __typename?: 'CoreContact', id?: number | null, firstName?: string | null, lastName?: string | null, email?: string | null, phone?: string | null, alternatePhone?: string | null, mobilePhone?: string | null, coreCompanyId: number, active?: boolean | null } };


export const GetCoreContactByIdDocument = gql`
    query GetCoreContactById($id: Int!) {
  getCoreContactById(id: $id) {
    id
    firstName
    lastName
    email
    phone
    alternatePhone
    mobilePhone
    coreCompanyId
    active
  }
}
    `;

/**
 * __useGetCoreContactByIdQuery__
 *
 * To run a query within a React component, call `useGetCoreContactByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoreContactByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoreContactByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCoreContactByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCoreContactByIdQuery, GetCoreContactByIdQueryVariables> & ({ variables: GetCoreContactByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCoreContactByIdQuery, GetCoreContactByIdQueryVariables>(GetCoreContactByIdDocument, options);
      }
export function useGetCoreContactByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoreContactByIdQuery, GetCoreContactByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCoreContactByIdQuery, GetCoreContactByIdQueryVariables>(GetCoreContactByIdDocument, options);
        }
export function useGetCoreContactByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCoreContactByIdQuery, GetCoreContactByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCoreContactByIdQuery, GetCoreContactByIdQueryVariables>(GetCoreContactByIdDocument, options);
        }
export type GetCoreContactByIdQueryHookResult = ReturnType<typeof useGetCoreContactByIdQuery>;
export type GetCoreContactByIdLazyQueryHookResult = ReturnType<typeof useGetCoreContactByIdLazyQuery>;
export type GetCoreContactByIdSuspenseQueryHookResult = ReturnType<typeof useGetCoreContactByIdSuspenseQuery>;
export type GetCoreContactByIdQueryResult = Apollo.QueryResult<GetCoreContactByIdQuery, GetCoreContactByIdQueryVariables>;