import { TableColumnDef } from "../Table";
import { DataGrid, GridColDef , gridClasses} from "@mui/x-data-grid";
import { FunctionComponent, useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import { alpha, styled } from '@mui/material/styles';
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, Popover, SxProps, Table, TableBody, TableCell, TableHead, TableRow, TextField, Theme, Typography, useTheme } from "@mui/material";
import { InlineIcon } from "@iconify/react";
import { FilterSelectItem, useFilter, useFilterOption } from "../../../providers/FilterProvider";

const isStringNumArray = ( value : any ) : value is Array<string|number> => {
    if( !Array.isArray( value ) ){
        return false;
    }
    return value.every( v => typeof v === "string" || typeof v === "number" );
}

type TOptionCheckboxProp = { 
    values : Array<string|number> , 
    setValue: React.Dispatch<React.SetStateAction<(string | number)[]>> 
    option : FilterSelectItem;
}

const OptionCheckbox = ({ values , setValue , option  } : TOptionCheckboxProp) =>{

    const checked = useMemo( () => {
        return values.includes( option.value as any );
    } , [JSON.stringify( values ) , option.value ] );
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if( event.target.checked ){
            setValue( values => {
                return [...values , option.value ].filter( (v,i,a) => a.indexOf(v) === i );
            })
        } else {
            setValue( values => {
                return values.filter( v => v !== option.value );
            })
        }
        };

    return <Grid item sm={6}><FormControlLabel control={<Checkbox size="small" defaultChecked checked={checked} onChange={handleChange} />} label={option.label} /></Grid>
}


export const FilterMenuOptions = ( { column , onClose } : { column : TableColumnDef , onClose: ()=> void } ) => {
    const {filter , updateFilterValue , clearFilter } = useFilter( column.field );
    const filterValue = filter?.value;
    const [values, setValues] = useState<Array<string|number>>( isStringNumArray(filterValue) ? filterValue : [] );
    const theme = useTheme();
    const options = useFilterOption( column.field);
    const handleClose = ()=>{ 
    updateFilterValue( 'in' , values);
      onClose();
    }
    
    return <>
    <Box sx={{background: theme.palette.background.paper , borderRadius: 1, maxWidth: '600px' }}><Box sx={{padding: 1 }}>
        <Grid container>
        {options.map( option => <OptionCheckbox key={option.value  } values={values} setValue={setValues} option={option} /> ) }
        </Grid>
    </Box></Box>
    <Box sx={{padding: 1 , position: 'relative' , width: '100%' , height: '48px' }}>
      <Button size="small"  onClick={()=>{ clearFilter(); onClose(); }} color="warning" sx={{ position: 'absolute' , right: '75px'}} variant="contained">Clear</Button>
      <Button size="small" onClick={handleClose} color="secondary" sx={{ position: 'absolute' , right: '0'}} variant="contained">Save</Button>
    </Box>
    </>
   }