import { Button, Container, Dialog, DialogTitle, Grid, Paper, TextField } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../react-submodules/providers/Api/Api";
import { StyledTable, TableColumnDef } from "../../react-submodules/components/Table";
import { LocationForm } from "./LocationForm";
import { InventoryLocation } from "./types";

const columns : TableColumnDef[] = [
    {
        field: 'name',
        flex: 1,
        sortable: true,
        headerName: 'Name'        
    },
]
export const Locations = () => {
    const nav = useNavigate();
    const [ search, setSearch ] = useState( '' );
    const [ companies  , setCompanies ] = useState( [] );
    const [showAddLocation , setShowAddLocation] = useState( false );
    
    const api = useApi();
    const addInventory = ( i : Omit<InventoryLocation , 'id'>) => {
        api.post('/inventorymanagement/location' , i ).then( () => {
            setShowAddLocation( false );
            api.get( '/inventorymanagement/locations' , { 'name:like' : search } ).then( ( results : { data : any } ) => {
                setCompanies( results.data );
              });
        })
        
    }
    useEffect(() => {
        const getData = setTimeout(() => {
          api.get( '/inventorymanagement/locations' , { 'name:like' : search } ).then( ( results : { data : any } ) => {
            setCompanies( results.data );
          });
        }, 500)
    
        return () => clearTimeout(getData)
      }, [search])
    return <>
        <Dialog open={showAddLocation} onClose={()=>{
            setShowAddLocation( false );
        }} 
        >
            <DialogTitle  >Add Location</DialogTitle>
            {showAddLocation && <LocationForm onSubmit={addInventory} />}
        </Dialog>
    <Container sx={{marginTop: 2 }}>
        <Grid container >
            <Grid sm={2}><Button variant="outlined" onClick={()=>{
                setShowAddLocation( true );
            }}>Add Location</Button></Grid>
        </Grid>
        <Paper elevation={2} sx={{padding: 1}}>
            <TextField fullWidth label="Search" value={search} onChange={( e)=>{ setSearch( e.target.value)}} />
        </Paper>
    </Container>
    { companies.length > 0 && (
  <Container sx={{marginTop: 2 }}><Paper><StyledTable
  onClick={( row)=>{
    nav(`/location/${row.id}`);
  }}
  rows={companies}
  columns={columns}
/>
</Paper>
</Container>
)
}
    </>
}