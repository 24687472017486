import { Dialog, DialogTitle, DialogContent, TextField, Grid, MenuItem, InputAdornment, DialogActions, Button, useTheme } from "@mui/material"
import { FC, useEffect, useMemo, useState } from "react"
import { MarkdownEditor } from "../../react-submodules/components/MarkdownEditor/MarkdownEditor";
import { useApi } from "../../react-submodules/providers/Api";
import { Contract, measuringUnits } from "./types";
import { IconPicker } from "../../react-submodules/components/IconPicker";


export const EditContract : FC<{onClose: () => void , contract : Contract | null }> = ({ onClose, contract}) => {   

    const api = useApi();
    const theme = useTheme();

    const [name, setName] = useState('');
    const [ internalDescription, setInternalDescription ] = useState('');
    const [ publicDescription, setPublicDescription ] = useState('');
    const [ unitsMeasured, setUnitsMeasured ] = useState('');
    const [ unitPrice, setUnitPrice ] = useState('');
    const [ additionalPrice, setAdditionalPrice ] = useState('');
    const [ reloadMarkdown , setReloadMarkdown ] = useState(false);
    const [icon, setIcon] = useState('');
    const [iconColor, setIconColor] = useState('');

    useEffect( () => {
        if( reloadMarkdown ) {
            setReloadMarkdown( false );
        }
    } ,[reloadMarkdown])
    useEffect( () => {
        if ( contract ) {
            setName(contract.name);
            setInternalDescription(contract.internalDescription);
            setPublicDescription(contract.publicDescription);
            setUnitsMeasured(contract.unitsMeasured);
            setUnitPrice(contract.unitPrice.toString());
            setAdditionalPrice(contract.additionalPrice.toString());
            setIcon(contract.icon || '');
            setIconColor(contract.iconColor || theme.palette.success.main);
            setReloadMarkdown(true);
        }
    }, [ contract ])
    const open = useMemo( () => {
        return contract !== null;
    }, [ contract ])
    const editContract = () => {
        api.patch(`/contracts/${contract?.id}`, {
            name,
            internalDescription,
            publicDescription,
            unitsMeasured,
            unitPrice,
            additionalPrice,
            icon,
            iconColor
        }).then(() => {
            onClose();
        })
    }
    return <Dialog open={open} onClose={onClose} maxWidth="lg">
        <DialogTitle>Edit Contract</DialogTitle>
        <DialogContent sx={{width: '650px'}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{marginTop: 2 }}>
                    <TextField
                        type="color"
                        fullWidth
                        label="Icon"
                        value={iconColor}
                        onChange={(e) => setIconColor(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconPicker
                                        icon={icon}
                                        onChange={(newIcon) => setIcon(newIcon)}
                                        color={iconColor}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sx={{marginTop: 2 }}>
                    <TextField size="small" fullWidth label="Name" value={name} onChange={( e)=>{ setName( e.target.value)}} />
                </Grid>
                <Grid item xs={12}>
                    <TextField select size="small" fullWidth label="Units Measured" value={unitsMeasured} onChange={( e)=>{ setUnitsMeasured( e.target.value)}} >
                        {measuringUnits.map(( unit ) => <MenuItem key={unit} value={unit}>{unit}</MenuItem>)}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        size="small" type="number" fullWidth label="Each Unit Price" value={unitPrice} onChange={( e)=>{ setUnitPrice( e.target.value)}} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        size="small" type="number" fullWidth label="Additional Price" value={additionalPrice} onChange={( e)=>{ setAdditionalPrice( e.target.value)}} />
                </Grid>
                <Grid item xs={12}>
                   { !reloadMarkdown && <MarkdownEditor label="Internal Description" markdown={internalDescription} onChange={( value)=>{ setInternalDescription( value )}} /> }
                </Grid>
                <Grid item xs={12}>
                   { !reloadMarkdown && <MarkdownEditor label="Public Description" markdown={publicDescription} onChange={( value)=>{ setPublicDescription( value )}} /> }
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="primary" onClick={()=>{
                editContract();
            }}>Edit</Button>
        </DialogActions>
    </Dialog>
}