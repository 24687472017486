import { Icon, InlineIcon } from "@iconify/react";
import { Box, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { FC, useEffect, useMemo, useState } from "react";
import { useUser } from "../../providers/User";
import { getScheduleInstanceColor } from "../../utils/schedule";
import { GetUsersScheduleQuery, useGetUsersScheduleQuery } from "./gql/GetUsersSchedule.generated";

// type ScheduleItem = GetUsersScheduleQuery['findScheduleInstancePaginated']['data'];
type ScheduleItem = NonNullable<GetUsersScheduleQuery['findScheduleInstancePaginated']['data']>[number];
type ScheduleItemExtra = ScheduleItem & { merged?: ScheduleItem[]}

// fluent-mdl2:game
const DisplayScheduleItem : FC<{ item? : ScheduleItemExtra | null }> = ({ item }) => {
    const theme = useTheme();
    const color = useMemo(() => {
        if( item ){
            return getScheduleInstanceColor(item);
        }
        return '#aaaaaa';
    }, [JSON.stringify(item)]);
    if( !item ){
        return <Box sx={{backgroundColor: '#333' , marginLeft: 2 ,  height: 40 , borderRadius: 2 , position: 'relative' }}>        
        <Box sx={{ marginLeft: 2 , marginRight: 2 , top: '6px' , position: 'relative'}}><Typography variant="h6">
            <InlineIcon icon="fluent-mdl2:game" /> Not Scheduled
        </Typography></Box>
    </Box>;
    }
    return  <Box sx={{backgroundColor: color , marginLeft: 2 ,  height: 40 , borderRadius: 2 , position: 'relative' }}>
    <Box sx={{top: '-5px' , left: 2 , position: 'absolute', background: theme.palette.primary.dark , color: theme.palette.primary.contrastText , fontSize: '10px' , padding: '0px 5px' , borderRadius: '5px'}}>{DateTime.fromISO(item?.startDateTime).toFormat('h:mm a')} - {DateTime.fromISO(item?.endDateTime).toFormat('h:mm a ')}</Box>
    <Box sx={{ marginLeft: 2 , marginRight: 2 , top: '6px' , position: 'relative'}}><Typography variant="h6">
        {item?.title || item?.AzureCalendar?.subject || item?.SchedulePlan?.name}
    </Typography></Box>
</Box>
}

export const ScheduledInstance = () => {
    const { tokenParsed } = useUser();
    
    const [ now , setNow ] = useState<Date>(new Date());
    const [ scheduleInstanceDay , setScheduleInstanceDay ] = useState<string>(DateTime.now().toFormat('yyyy-MM-dd'));
    

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date());
            if( scheduleInstanceDay !== DateTime.now().toFormat('yyyy-MM-dd') ){
                setScheduleInstanceDay(DateTime.now().toFormat('yyyy-MM-dd'));
            }
        }, 1000 * 60 );
        return () => clearInterval(interval);
    }, []);
    const [ cleanData , setCleanData ] = useState<ScheduleItemExtra[]>([]);
    const { data } = useGetUsersScheduleQuery({
        variables: {
            coreUserId: tokenParsed?.sub || '' ,
            scheduleInstanceDay
        }
    });

    useEffect(() => {
        const mergedData : ScheduleItemExtra[] = [];
        let lastItem : ScheduleItemExtra | null = null;
        data?.findScheduleInstancePaginated.data?.forEach(item => {            
            if( !lastItem ){
                lastItem = { ...item, merged: []};
            }
            if( lastItem?.schedulePlanId === item.schedulePlanId && lastItem?.endDateTime === item.startDateTime && lastItem?.title === item.title ){
                lastItem.endDateTime = item.endDateTime;
                lastItem.merged?.push(item);
            } else {
                mergedData.push(lastItem);
                lastItem = { ...item, merged: []};
            }
        });
        if( lastItem ){
            mergedData.push(lastItem);
        }
        setCleanData(mergedData.sort((a,b) => {
            const startDateA = new Date(a.startDateTime);
            const startDateB = new Date(b.startDateTime);
            return startDateA.getTime() - startDateB.getTime() ;
        }));
    }, [JSON.stringify(data?.findScheduleInstancePaginated.data)]);

    const currentItem : ScheduleItemExtra | null = useMemo(() => {
        
        const item = cleanData.find(item => {
            const startDate = new Date(item.startDateTime);
            const endDate = new Date(item.endDateTime);
            return startDate <= now && endDate >= now && !item.available;
        });
        if( item ){
            return item;
        }
        const schedule  = cleanData.find(item => {
            const startDate = new Date(item.startDateTime);
            const endDate = new Date(item.endDateTime);
            return startDate <= now && endDate >= now && item.available;
        });        
        if( !schedule ){
            return null;
        }
        const nextItem = cleanData.filter(item => {
            const startDate = new Date(item.startDateTime);
            const scheduleEndDate = new Date(schedule?.endDateTime);
            return startDate > now && !item.available && startDate < scheduleEndDate;
        }).shift();
        const lastItem = cleanData.filter(item => {
            const endDate = new Date(item.endDateTime);
            const scheduleEndDate = new Date(schedule?.endDateTime);
            return endDate < scheduleEndDate && !item.available;
        }).shift();
        const startDateTime = lastItem?.endDateTime ? lastItem?.endDateTime : schedule?.startDateTime;
        const endDateTime = nextItem?.startDateTime ? nextItem?.startDateTime : schedule?.endDateTime;
        return { ...schedule, nextItem, lastItem, startDateTime, endDateTime};
    }, [JSON.stringify(cleanData) , now ]);

    const nextItem = useMemo(() => {
        const currentEndDateTime = new Date(currentItem?.endDateTime || now);
        const nextSchedule  = cleanData.filter(item => {
            const startDate = new Date(item.startDateTime);
            return startDate < currentEndDateTime && item.available && currentItem?.id !== item.id;
        }).pop();
        
        const nextItem = cleanData.filter(item => {
            const startDate = new Date(item.startDateTime);
            return startDate > now && !item.available;
        }).shift();
        
        if( !nextItem && !nextSchedule ){
            return null;
        }
        if( nextItem && !nextSchedule ){
            return nextItem;
        }
        const startDateTime = currentItem?.endDateTime ? currentItem?.endDateTime : nextSchedule?.startDateTime;
        const endDateTime = nextItem?.startDateTime ? nextItem?.startDateTime : nextSchedule?.endDateTime;
        return { ...nextSchedule, startDateTime, endDateTime};
    }, [JSON.stringify(currentItem)]);

    
    
    return <>
    <DisplayScheduleItem item={currentItem} />
    <DisplayScheduleItem item={nextItem} />
    </>;
}