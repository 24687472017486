import { Box, Button, Grid, MenuItem, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { numberToTime, timeToNumber } from "../../../utils/time.utils";

type WithNextActionDate = {
  nextActionDate?: string | null | undefined;
  nextActionTime?: number | null | undefined;
  nextActionType?: string | null | undefined;
}

export const GqlNextActionDate = <T extends WithNextActionDate>({
  ticket,
  edit,
  onChange
}: {
  ticket: T;
  edit?: boolean;
  onChange?: (current: T | ((prev: T) => T)) => void;
}) => {
  const handleDateChange = (value: string) => {
    if (onChange) {
      onChange(current => ({
        ...current,
        nextActionDate: value
      }));
    }
  };

  const handleTimeChange = (value: string) => {
    if (onChange) {
      onChange(current => ({
        ...current,
        nextActionTime: timeToNumber(value)
      }));
    }
  };

  const handleTypeChange = (value: string) => {
    if (onChange) {
      onChange(current => ({
        ...current,
        nextActionType: value
      }));
    }
  };

  const setTomorrowDate = () => {
    if (onChange) {
      onChange(current => ({
        ...current,
        nextActionDate: new Date(new Date().getTime() + (1000 * 60 * 60 * 24)).toISOString().split('T')[0]
      }));
    }
  };

  if (!edit) {
    return (
      <Box>
        {ticket.nextActionDate && (
          <>
            <div>Date: {ticket.nextActionDate?.split('T')[0]}</div>
            <div>Time: {numberToTime(ticket.nextActionTime || 0)}</div>
            <div>Type: {ticket.nextActionType}</div>
          </>
        )}
      </Box>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            error={!ticket.nextActionDate}
            InputLabelProps={{ shrink: true }}
            size="small"
            label="Next Action Date"
            fullWidth
            type="date"
            value={ticket.nextActionDate?.split('T')[0] || ''}
            onChange={(e) => handleDateChange(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            InputLabelProps={{ shrink: true }}
            size="small"
            label="Next Action Time"
            fullWidth
            type="time"
            value={numberToTime(ticket.nextActionTime || NaN)}
            onChange={(e) => handleTimeChange(e.target.value)}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            fullWidth
            size="small"
            select
            label="Type"
            value={ticket.nextActionType || ''}
            onChange={(e) => handleTypeChange(e.target.value)}
          >
            {['FLEXABLE', 'FIRM'].map((option) => (
              <MenuItem key={option} value={option} sx={{ display: 'flex', flexDirection: 'row' }}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Button size="small" onClick={setTomorrowDate}>
        Tomorrow
      </Button>
    </>
  );
}; 