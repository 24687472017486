import { Button, Card, CardContent, CardHeader, Grid } from "@mui/material";

import { FC } from "react";
import { StyledTable } from "../../../../react-submodules/components/Table";
import { UserReportDataMinimum } from "./TicketRouting";

export const UserCard : FC<{user : UserReportDataMinimum , setInvestigateUser : ( user :UserReportDataMinimum ) => any}> = ({
    setInvestigateUser , user 
}) => {
    const totalTickets = user.ticketIds.filter( (value, index, self) => self.indexOf(value) === index).length;
    const roles = user.queue.map( q => q.fromRole ).filter( (value, index, self) => self.indexOf(value) === index).map( r => {
        return { tickets : user.queue.filter( q => q.fromRole === r) , role : r} 
    });
    const statuses = user.queue.map( q => q.status ).filter( (value, index, self) => self.indexOf(value) === index);
    return <Card>
        <CardHeader title={user.user.email}></CardHeader>
        <CardContent>
            <Grid container>
                <Grid item sm={6}>Workable Tickets : {totalTickets}</Grid>
                {/*roles.map( role => {
                    const roleTickets = user.queue.filter( q => q.fromRole === role).length;
                    return <Grid item sm={6}>{role} : {roleTickets}</Grid>
                })*/}
                <Grid item sm={12}>
                <StyledTable rows={roles} columns={[
                    {
                        field: 'role'
                    },
                    ...statuses.sort( ).map( status => {
                        return {
                            headerName: status.replace( /Customer/i , '' ),
                            field: 'test',
                            Element: ({cell,row} : any ) => {
                                const c = row.tickets.filter( ( t : any ) => t.status === status).length;
                                return <>{c || ''}</>
                            }
                        }
                    })
                    
                ]}></StyledTable>
                </Grid>
            </Grid>

            <Button onClick={ () => setInvestigateUser( user )}>Investigate</Button>
        </CardContent>
    </Card>
}