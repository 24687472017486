import { CoreUserTemplateEdit, CoreUserTemplateView } from "../components/PaginatedGraphqlQueryTable/templates/CoreUserTemplate";
import { CoreGroupTemplateEdit } from "../components/PaginatedGraphqlQueryTable/templates/groups/CoreGroupTemplateEdit";
import { CoreGroupTemplateView } from "../components/PaginatedGraphqlQueryTable/templates/groups/CoreGroupTemplateView";
import { GraphqlGenericColumn, GraphqlGenericColumnOption, GridWidth } from "../types/graphql.generics";

export const getGraphqlEndpoints = (baseName: string) => {
    return {
        queryEndpoint: `find${baseName}Paginated`,
        createEndpoint: `create${baseName}`,
        editEndpoint: `update${baseName}`,
        getByIdEndpoint: `get${baseName}ById`,
        deleteEndpoint: `delete${baseName}`
    }
}
const defaultColumn : GraphqlGenericColumn = {
    field: 'id',
    type: 'String',
    editable: true,
    visible: true,
    searchable: true,
    formGridWidth: 12
}

const getTemplate = (template: string) : Partial<GraphqlGenericColumn> => {
    switch(template){
        case 'user': return { 
            CustomView: CoreUserTemplateView, 
            CustomEdit: CoreUserTemplateEdit,
            field: 'coreUserId', 
            type: 'String', 
            createRequired: false,
            label: 'User',
            formGridWidth: 3,
            selectString: `coreUserId
            CoreUser {
                id
                email
                firstName
                lastName
            }`
        };
        case 'group': return {
            CustomView: CoreGroupTemplateView,
            CustomEdit: CoreGroupTemplateEdit,
            field: 'coreGroupId', 
            type: 'Int', 
            createRequired: false,
            label: 'Group',
            formGridWidth: 3,
            selectString: `coreGroupId
            CoreGroup {
                id
                name
                description
            }`
        };
        default: return {};
    }
}

export const mapGraphqlGenericColumns = (columns: Array<GraphqlGenericColumn | string | GraphqlGenericColumnOption>) => {
    return columns.map(column => {
        if (typeof column === 'string') {
            return {
                field: column,
                type: 'String',
                editable: true,
                visible: true,
                label: column,
                searchable: true
            } as GraphqlGenericColumn;  
        }
        const label = column.label || column.field;
        return {
            label,
            ...defaultColumn,
            ...getTemplate(column.template||''),
            ...column
        };
    });
}
export const mapGraphqlGenericColumnsToInnerSelect= (columns: GraphqlGenericColumn[]) => {
    return columns.filter( c => !c.disableSelect ).filter(column => column.visible || column?.select).map(column => column.selectString ? column.selectString : `${column.field}`).join('\n');
}

export const getGridWidthProps = (column: GraphqlGenericColumn) : { xs?: GridWidth, sm?: GridWidth, md?: GridWidth, lg?: GridWidth } => {
    if( !column.formGridWidth ) {
        return { sm: 6 };
    }
    if( typeof column.formGridWidth === 'number' ) {
        return { sm: column.formGridWidth };
    }
    return column.formGridWidth;
}

export const columnToPropDef = (column: GraphqlGenericColumn) => {
    if( column.customType ) {
        return `$${column.field}: ${column.customType}${column.primaryKey ? '!' : ''}`;
    }
    return `$${column.field}: ${column.type}${column.primaryKey ? '!' : ''}`;
}