import { PhoneCallLeg, PhoneCalls, ReportData, ScheduleInstance } from "../ReportActivity.types";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useGetAllUsersQuery } from "../../../../Schedule/DailySchedule/gql/GetAllUsers.generated";
import { useGetAllPhoneCallsQuery } from "../../gql/GetAllPhoneCalls.generated";
import { useGetScheduleInstancesQuery } from "../../../../Schedule/DailySchedule/gql/GetScheduleInstances.generated";
import { useGetAllGroupsQuery } from "../../../../Schedule/DailySchedule/gql/GetAllGroups.generated";
import { buildReportData } from "./UseReportData.utils";




export const useReportData = ( date : DateTime ) => {

    const [ reportData , setReportData ] = useState<ReportData[]>([]);
    
    const { data : rawUsers } = useGetAllUsersQuery({
        fetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: false
    });
    const { data : rawPhoneCalls } = useGetAllPhoneCallsQuery({
        variables : {
            startTime : date.startOf('day').toJSDate(),
            endTime : date.endOf('day').toJSDate(),
        },
        fetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: false
    });
    const { data : scheduleInstances  } = useGetScheduleInstancesQuery({
        variables : {
            startDateTime : date.startOf('day').toJSDate(),
            endDateTime : date.endOf('day').toJSDate(),
        },
        fetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: false,
    });
    useEffect(() => {
        
        const users = rawUsers?.findCoreUsersPaginated?.data || [];
        const schedule : ScheduleInstance[] = scheduleInstances?.findScheduleInstancePaginated?.data || [];
        const calls = rawPhoneCalls?.findPhonesCallsPaginated?.data || [];
        const updatedReportData = buildReportData( date , calls , users ,  schedule );
        setReportData( updatedReportData );
    }, [ JSON.stringify( rawPhoneCalls ) , JSON.stringify( rawUsers ) ,  JSON.stringify( scheduleInstances ) ]);
    return [ reportData  ];
}