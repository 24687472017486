import { gql, useMutation, useQuery } from "@apollo/client";

import {useTheme , Accordion, AccordionDetails, AccordionSummary, Box, List, Grid, ListItemText, ListItem, Button, TextField, InputAdornment } from "@mui/material";
import { FC, useState } from "react"
import { LabeledBox } from "../LabeldBox";
import { MarkdownView } from "../../MarkdownView";
import { ChatBox } from "../ChatBubble/ChatBox";
import { useUser } from "../../providers/User";

export type ChatListProps = {
    ticketId? : number;
}

const CREATE_CHAT = gql`
mutation CreateChat($name: String, $ticketId: Float, $coreUserId: String) {
  createAIChat(name: $name, ticketId: $ticketId, coreUserId: $coreUserId) {
    id
  }
}
`;

const CHAT_SEARCH = gql`
query ChatSearch($ticketId: NumberProp) {
  findAIChatPaginated(ticketId: $ticketId , limit: 1000) {
    data {
      id
      name
      ticket {
        id
        title
      }
      user {
        id
        firstName
        lastName
      }
      aiChatMessages {
        id
        message
        role
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
}
`;
type ChatResponse = {
    findAIChatPaginated: {
        data: Array<{
            id: number;
            name: string;
            user: {
                id: string;
                firstName: string;
                lastName: string;
            };
            ticket?: {
                id: number;
                title?: string;
            };
            aiChatMessages: Array<{
                id: number;
                message: string;
                role: string;
                user: {
                    id: string;
                    firstName: string;
                    lastName: string;
                };
            }>;
        }>;
    } 
    }

export const ChatList : FC<ChatListProps> = ({ ticketId }) => {
    const theme = useTheme();
    const user = useUser();
    const [ chatId , setChatId ] = useState<number | null>(null);
    const [ name , setName ] = useState<string>('');
    const { data , loading } = useQuery<ChatResponse>( CHAT_SEARCH , {
        variables: {
            ticketId : ticketId ? { eq : ticketId } : undefined
        }
    } );
    const [createChat] = useMutation(CREATE_CHAT , {
        onCompleted: ( item ) => {
            // refetch();
            setChatId(item.createAIChat.id);
        }
    });
    const chats = data?.findAIChatPaginated.data || [];
    const createChatForTicket = () =>{
        createChat({
            variables: {
                name,
                ticketId: ticketId ?? '0',
                coreUserId: user?.sub ?? null
            }
        });
    }

    return <>
   
    { !chatId && <>
        <Box sx={{m : 2 , width : 'calc(100% - 32px)'}}><TextField
        label="Chat Name" 
        size="small"
        value={name} 
        fullWidth
        onChange={(e) => setName(e.target.value)}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    
                    { ticketId && <Button size="small" variant="outlined" onClick={createChatForTicket}>
                        Create Chat
                    </Button> }
                </InputAdornment>
            ),
        }}
    /></Box>
    <List>
        {chats.map(chat => <ListItem sx={{color : theme.palette.primary.contrastText , backgroundColor: theme.palette.primary.light}}  key={chat.id} onClick={()=>setChatId(chat.id)}><ListItemText primary={chat.name} /></ListItem>)}
    </List></> }
    { chatId && <Box sx={{
        height: '100%', // Ensure it takes full height of the parent
        width: '100%', // Ensure it takes full width of the parent
        display: 'flex', 
        flexDirection: 'column', 
        overflow: 'hidden', 
        boxSizing: 'border-box', // Include padding/border in height calculation
        position: 'relative'
    }}><ChatBox chatId={chatId} /></Box> }
    </>
}