import { FunctionComponent, PropsWithChildren, useMemo } from "react"
import { TThemes, useUserProfile } from "./UserProfileProvider";
import { Theme, createTheme } from "@mui/material";
import { useDataObject, useDataQuery } from "../hooks/useDataQuery";

import { ThemeProvider } from "@emotion/react"
import { useKeycloak } from "./Keycloak";

export const themes : Record<TThemes , Theme > = {
    blacktie:  createTheme({
        palette: {
            primary: {
              main: '#000000',
            },
            secondary: {
              main: '#f58500',
            },
          }
      }),
    forest: createTheme({
        palette: {
            primary: {
                main: '#3c730c',
            },
            secondary: {
                main: '#a66a40',
            },
        }
    }),
    couleetech : createTheme({
        palette: {
            secondary: {
                
                main: 'rgb(141, 198, 63)',
            },
            primary: {                
                main: 'rgb(88, 89, 91)',
            },
            background: {
                default: '#F3F6F9',
                paper: 'rgb(255, 255, 255)',
            },
        }
    }),
    royal : createTheme({
        palette: {
            primary: {
                main: '#370c73',
            },
            secondary: {
                main: '#3e96aa',
            },
        }
    }),
}
export const DEFAULT_THEME : TThemes = 'couleetech';


export const UserTheme : React.FC<PropsWithChildren> = ( { children} : PropsWithChildren ) => {
    const { userProfile} = useUserProfile();
    
    
    const theme : TThemes = useMemo( () : TThemes => {
        if( Object.keys( themes ).includes( userProfile?.theme || '' ) ){
            return userProfile?.theme || DEFAULT_THEME as TThemes;
        }
        return DEFAULT_THEME
    }, [ JSON.stringify( userProfile )  ]);
    
    /*if( userProfile === null ){
        return <>Loading</>
    } */
    return <ThemeProvider theme={themes[theme] || themes[DEFAULT_THEME]}>{children}</ThemeProvider>
}