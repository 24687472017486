import { Badge, IconButton, ListItemButton, ListItemText, Menu, MenuItem, Switch, useTheme } from "@mui/material"

import { Icon } from "@iconify/react"
import { ReportTicket } from "./TicketRouting/TicketRouting";
import { useState } from "react";

export const QueuedUsersButton = ({cell , row } : {cell : any , row : ReportTicket }) => {
    
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    
    const open = Boolean(anchorEl);
    const count = cell.length;
    let icon = 'clarity:users-solid';
    if( count === 0 ){
        icon = 'fa-solid:users-slash';
    }
    if( count === 1 ){
        icon = 'fa-solid:user';
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
    return <>
    <IconButton onClick={handleClick}><Badge badgeContent={count}> <Icon icon={icon} /></Badge></IconButton>
    <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem><ListItemText primary="Queued Users" ></ListItemText></MenuItem>
        {row.queuedUsers.map( email => <MenuItem key={email}><ListItemText primary={email} ></ListItemText></MenuItem>)}
      </Menu>
    </>
}