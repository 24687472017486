import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCoreDeviceSoftwareByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetCoreDeviceSoftwareByIdQuery = { __typename?: 'Query', getCoreDeviceSoftwareById: { __typename?: 'CoreDeviceSoftware', id?: number | null, name: string, description?: string | null, vendor?: string | null, ignore: boolean, installMethod?: string | null, installMethodId?: string | null, aliases?: Array<string> | null, Tags?: Array<{ __typename?: 'CoreTag', id: number, name: string, autotaskTicketId?: number | null, createdByService?: string | null, createdAt: any, updatedAt: any, coredeviceSoftwareId?: number | null }> | null, Files?: Array<{ __typename?: 'CoreFile', createdDateTime: any, createdByCoreUserId?: string | null, updatedDateTime: any, updatedByCoreUserId?: string | null, id?: number | null, name?: string | null, contentType: string, size: number, key: string, verified: boolean, childpart: boolean, metadata?: any | null, coredeviceSoftwareId?: number | null }> | null } };


export const GetCoreDeviceSoftwareByIdDocument = gql`
    query GetCoreDeviceSoftwareById($id: Int!) {
  getCoreDeviceSoftwareById(id: $id) {
    id
    name
    description
    vendor
    ignore
    installMethod
    installMethodId
    aliases
    Tags {
      id
      name
      autotaskTicketId
      createdByService
      createdAt
      updatedAt
      coredeviceSoftwareId
    }
    Files {
      createdDateTime
      createdByCoreUserId
      updatedDateTime
      updatedByCoreUserId
      id
      name
      contentType
      size
      key
      verified
      childpart
      metadata
      coredeviceSoftwareId
    }
  }
}
    `;

/**
 * __useGetCoreDeviceSoftwareByIdQuery__
 *
 * To run a query within a React component, call `useGetCoreDeviceSoftwareByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoreDeviceSoftwareByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoreDeviceSoftwareByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCoreDeviceSoftwareByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCoreDeviceSoftwareByIdQuery, GetCoreDeviceSoftwareByIdQueryVariables> & ({ variables: GetCoreDeviceSoftwareByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCoreDeviceSoftwareByIdQuery, GetCoreDeviceSoftwareByIdQueryVariables>(GetCoreDeviceSoftwareByIdDocument, options);
      }
export function useGetCoreDeviceSoftwareByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoreDeviceSoftwareByIdQuery, GetCoreDeviceSoftwareByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCoreDeviceSoftwareByIdQuery, GetCoreDeviceSoftwareByIdQueryVariables>(GetCoreDeviceSoftwareByIdDocument, options);
        }
export function useGetCoreDeviceSoftwareByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCoreDeviceSoftwareByIdQuery, GetCoreDeviceSoftwareByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCoreDeviceSoftwareByIdQuery, GetCoreDeviceSoftwareByIdQueryVariables>(GetCoreDeviceSoftwareByIdDocument, options);
        }
export type GetCoreDeviceSoftwareByIdQueryHookResult = ReturnType<typeof useGetCoreDeviceSoftwareByIdQuery>;
export type GetCoreDeviceSoftwareByIdLazyQueryHookResult = ReturnType<typeof useGetCoreDeviceSoftwareByIdLazyQuery>;
export type GetCoreDeviceSoftwareByIdSuspenseQueryHookResult = ReturnType<typeof useGetCoreDeviceSoftwareByIdSuspenseQuery>;
export type GetCoreDeviceSoftwareByIdQueryResult = Apollo.QueryResult<GetCoreDeviceSoftwareByIdQuery, GetCoreDeviceSoftwareByIdQueryVariables>;