import { FC, useEffect, useState } from "react";

import { CoreForm } from "../CoreForm/CoreForm";
import { FullForm } from "../../../coretypes/form";
import { useApi } from "../../providers/Api/Api";
import { useRecipeApi } from "../../providers/Recipe";

type FormInstanceFull =  { form: FullForm , id : string , formId : string, values : Record<string,any> , recipeInstanceId : string }

export const Forms : FC<{ formInstanceId : string }>  = ( { formInstanceId } ) => {
    const { submitRecipe , removeAction, actions } = useRecipeApi();
    const [ formInstance , setForm ] = useState<FormInstanceFull| null>( null);
    const [ saving , setSaving ] = useState( false );
    const api = useApi();
    useEffect( () => {
        api.get<FormInstanceFull>(`/forms/instance/${formInstanceId}/full`).then( ( { data}) => {
            setForm( data );
        })
    } , [formInstanceId]);
    const { form , values } = formInstance || {};

    const onSave = async ( values : Record<string,any> ) => {
        setSaving( true );
        await api.put(`/forms/instance/${formInstanceId}/values` , values );
        // Find and remove the current form action to close the dialog
        const currentAction = actions.find(a => a.action === 'form' && a.formInstanceId === formInstanceId);
        if (currentAction) {
            removeAction(currentAction);
        }
        setSaving( false );
    }

    const onSubmit = async ( values : Record<string,any> ) => {
        setSaving( true );
        if( !formInstance?.recipeInstanceId ){
            return;
        }
        await submitRecipe(formInstance?.recipeInstanceId , { values } , { formInstanceId : formInstanceId });
        setSaving( false );
        
    }
    

    
    return <>{form && <CoreForm form={form} values={values} onSave={onSave} onSubmit={onSubmit} saving={saving} />}</>
}

