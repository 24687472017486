import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FileUploadMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  contentType: Types.Scalars['String']['input'];
  size: Types.Scalars['Float']['input'];
  coredeviceSoftwareId?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  coreCompanyId?: Types.InputMaybe<Types.Scalars['Float']['input']>;
}>;


export type FileUploadMutation = { __typename?: 'Mutation', addFileToEntity: { __typename?: 'CoreFile', id?: number | null, name?: string | null, contentType: string, size: number, key: string, verified: boolean, metadata?: any | null, coredeviceSoftwareId?: number | null, coreCompanyId?: number | null, signedUrl?: { __typename?: 'SignedUrl', url: string, expires: any } | null } };


export const FileUploadDocument = gql`
    mutation FileUpload($name: String!, $contentType: String!, $size: Float!, $coredeviceSoftwareId: Float, $coreCompanyId: Float) {
  addFileToEntity(
    name: $name
    contentType: $contentType
    size: $size
    coredeviceSoftwareId: $coredeviceSoftwareId
    coreCompanyId: $coreCompanyId
  ) {
    id
    name
    contentType
    size
    key
    verified
    metadata
    coredeviceSoftwareId
    coreCompanyId
    signedUrl {
      url
      expires
    }
  }
}
    `;
export type FileUploadMutationFn = Apollo.MutationFunction<FileUploadMutation, FileUploadMutationVariables>;

/**
 * __useFileUploadMutation__
 *
 * To run a mutation, you first call `useFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileUploadMutation, { data, loading, error }] = useFileUploadMutation({
 *   variables: {
 *      name: // value for 'name'
 *      contentType: // value for 'contentType'
 *      size: // value for 'size'
 *      coredeviceSoftwareId: // value for 'coredeviceSoftwareId'
 *      coreCompanyId: // value for 'coreCompanyId'
 *   },
 * });
 */
export function useFileUploadMutation(baseOptions?: Apollo.MutationHookOptions<FileUploadMutation, FileUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileUploadMutation, FileUploadMutationVariables>(FileUploadDocument, options);
      }
export type FileUploadMutationHookResult = ReturnType<typeof useFileUploadMutation>;
export type FileUploadMutationResult = Apollo.MutationResult<FileUploadMutation>;
export type FileUploadMutationOptions = Apollo.BaseMutationOptions<FileUploadMutation, FileUploadMutationVariables>;