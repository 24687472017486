import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TicketQueuesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TicketQueuesQuery = { __typename?: 'Query', TicketQueues: Array<{ __typename?: 'PicklistEnteryGql', label: string, value: string, isActive: boolean, isSystem: boolean, sortOrder: number, parentValue: string, isDefaultValue: boolean }> };


export const TicketQueuesDocument = gql`
    query TicketQueues {
  TicketQueues {
    label
    value
    isActive
    isSystem
    sortOrder
    parentValue
    isDefaultValue
  }
}
    `;

/**
 * __useTicketQueuesQuery__
 *
 * To run a query within a React component, call `useTicketQueuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketQueuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketQueuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTicketQueuesQuery(baseOptions?: Apollo.QueryHookOptions<TicketQueuesQuery, TicketQueuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TicketQueuesQuery, TicketQueuesQueryVariables>(TicketQueuesDocument, options);
      }
export function useTicketQueuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketQueuesQuery, TicketQueuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TicketQueuesQuery, TicketQueuesQueryVariables>(TicketQueuesDocument, options);
        }
export function useTicketQueuesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TicketQueuesQuery, TicketQueuesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TicketQueuesQuery, TicketQueuesQueryVariables>(TicketQueuesDocument, options);
        }
export type TicketQueuesQueryHookResult = ReturnType<typeof useTicketQueuesQuery>;
export type TicketQueuesLazyQueryHookResult = ReturnType<typeof useTicketQueuesLazyQuery>;
export type TicketQueuesSuspenseQueryHookResult = ReturnType<typeof useTicketQueuesSuspenseQuery>;
export type TicketQueuesQueryResult = Apollo.QueryResult<TicketQueuesQuery, TicketQueuesQueryVariables>;