import { Box, Card, CardContent, CardHeader, Grid, IconButton, InputAdornment, Switch, TextField, TextFieldProps, Typography } from "@mui/material"
import { Handle, Position, useEdges, useNodes } from "reactflow"
import { useNodeDataProperty } from "../../../../providers/Recipies.provider";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { FC, useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { ContextKeyPicker } from "../ContextKeyPicker";
import { useNodeContext } from "../../useNodeContext";
import zodToJsonSchema, { JsonSchema7Type } from "zod-to-json-schema";
import { Icon } from "@iconify/react";



const ContextTextField : FC<{field: string , id: string } & TextFieldProps> = ({ field , id , ...props } ) => {
    const nodes = useNodes();
    const edges = useEdges();
    const [ value , setValue ] = useNodeDataProperty( id , field );
    const [ showHelp , setShowHelp ] = useState(false);
    const [ pickerKey , setPickerKey ] = useState('');
    const { getContext } = useNodeContext( id );

    const [ context, setContext ] = useState<JsonSchema7Type>( zodToJsonSchema( getContext() ) );
    useEffect( () => {
      const newContext = getContext();
      setContext( zodToJsonSchema( newContext ) );
    }, [
      JSON.stringify( { nodes , edges})
    ]);

    return <><TextField
        {...props}
        value={value}
        onChange={(e)=>setValue(e.target.value)}
        onScroll={(e)=>e.preventDefault()}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                        aria-label="help"
                        onClick={() => setShowHelp(true)}
                        edge="end"
                    >
                        <HelpOutlineIcon />
                    </IconButton>
                </InputAdornment>
            ),
        }}
    />
    <Dialog open={showHelp} onClose={() => {
            setShowHelp(false);
        }}>
            <DialogTitle>Variable Picker</DialogTitle>
            <DialogContent>
                    <ContextKeyPicker  field={pickerKey} onChange={(key) => {
                        setPickerKey(key);
                    }} context={context} />
            </DialogContent>
            <DialogActions>
                {pickerKey && <Button onClick={() => {
                    setShowHelp(false);
                    setValue(`${value||''}{{${pickerKey}}}`);
                    setPickerKey('');
                }}>Inject</Button>}
            </DialogActions>
        </Dialog>
    </>
}



export const SendEmailNode = ({ id , data } : {id : string , data : any  } ) => {

    const [ active , setActive ] = useNodeDataProperty( id , 'active' , true );
    const handleCopy = () => {
        navigator.clipboard.writeText( JSON.stringify( { defaultData : data , id : 'sendEmail' , title : data.title } , null , 2 ) );
      }
    
    return <Box>
        <Card sx={{maxWidth: '600px'}}>
            <CardHeader className="drag-handle" title="Send Email" action={
          <IconButton aria-label="copy" onClick={handleCopy}>
            <Icon icon="mdi:content-copy" />
          </IconButton>
        } />
            <CardContent>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                            <Switch color="secondary" checked={active} onChange={(e)=>setActive(e.target.checked)} />
                            <Typography component="label" htmlFor={`active-switch-${id}`}>Active</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <ContextTextField
                            field="subject"
                            id={id}
                            fullWidth
                            label="Subject"
                            
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ContextTextField
                            field="to"
                            id={id}
                            fullWidth
                            label="To"
                            
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ContextTextField
                            field="body"
                            id={id}
                            fullWidth
                            label="Body"
                            multiline
                            rows={16}
                        />
                    </Grid>

                    
                </Grid>
            </CardContent>
        </Card>
        <Handle
          type="target"
          position={Position.Left}
          id={`handle-target`}
        />
    </Box>
}