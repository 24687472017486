import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material"
import { createRef, FC, ForwardedRef, forwardRef, useMemo, useRef, useState } from "react"
import { PRINT_ICON } from "../../react-submodules/constances/icons";
import { InlineIcon } from "@iconify/react";
import { CompanyBillingReportItem } from "../../coretypes/reports/billing";
import { useReactToPrint } from "react-to-print";
import { useParams, useSearchParams } from "react-router-dom";
import { BillingPrintViewReport } from "./BillingPrint";

export const BillingDialogButton : FC<{
    reportItem: CompanyBillingReportItem
}> = ({ reportItem }) => {
    const [ open , setOpen ] = useState( false );
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    return <>
        <IconButton onClick={()=>{setOpen( true )}}><InlineIcon icon={PRINT_ICON} /></IconButton>
        <Dialog   maxWidth="lg" open={open} onClose={()=>{setOpen( false )}}>
            <DialogTitle>Billing Report for {reportItem.name} </DialogTitle>
                <DialogContent sx={{ width: '1200px', margin: 2 }}><BillingPrintViewReport reportItem={reportItem} /></DialogContent>
        </Dialog>
    </>
}

export const BillingPrintView  = () => { 
    const { id } = useParams();
    const [ searchParams ] = useSearchParams();
    const year = searchParams.get( 'year' );
    const month = searchParams.get( 'month' );
    return <>{id} , {year} , {month}</>
   }