import { PropsWithChildren, createContext, useContext, useMemo, useState } from "react";


export type FilterMethods = 'eq' | 'like' | 'in' | 'gt' | 'lt' | 'gte' | 'lte' | 'between';
export type FilterValues = string | number | boolean | Array<string| number>;
export interface FilterItems {
    field: string;
    method: FilterMethods;
    value?: string | number | boolean | Array<string| number> | null;
    min?: number | string | null;
    max?: number | string | null ;
}
export interface FilterSelectItem {
    label: string;
    value: string | number;
}

interface Filters  {
    filters: FilterItems[];
    fitlerParams: Record<string, string>;
    updateFilter : ( field : string , value : Omit<FilterItems , 'field'> ) => void;
    updateFilterValue : ( field : string , method: FilterMethods , value : FilterValues ) => void;
    clearFilter : ( field : string ) => void;
    clearAllFilters : () => void;
    selectOptions: Record<string, FilterSelectItem[]>;
    setSelectOption: ( field: string , options: FilterSelectItem[] ) => void;

}

const FilterContext = createContext<Filters>({
    filters: [],
    selectOptions: {},
    setSelectOption: () => {},
    fitlerParams: {},
    updateFilter: () => {},
    updateFilterValue: () => {},
    clearFilter: () => {},
    clearAllFilters: () => {},

});
export const useFilters = () => useContext( FilterContext);
export const useFilter = ( field: string ) => {
    const { filters, updateFilter, updateFilterValue, clearFilter } = useFilters();
    const filter = filters.find( f => f.field === field );
    return {
        filter,
        updateFilter: ( value : Omit<FilterItems , 'field'> ) => {
            updateFilter( field , value );
        },
        updateFilterValue: ( method: FilterMethods , value : FilterValues ) => {
            updateFilterValue( field , method , value );
        },
        clearFilter: () => {
            clearFilter( field );
        }
    }
}
export const useFilterOption = ( field: string ) => {
    
    const { selectOptions } = useFilters();
    return useMemo( () => {
        return selectOptions[field] || [];
    } , [JSON.stringify( selectOptions )]);
}
export const FilterProvider: React.FC<PropsWithChildren> = ({children}) => {

    const [filters , setFilters ] = useState<FilterItems[]>([]);
    const [selectOptions , setSelectOptions ] = useState<Record<string, FilterSelectItem[]>>({});

    const setSelectOption = ( field: string , options: FilterSelectItem[] ) => {
        setSelectOptions( lastOptions => {
            return { ...lastOptions , [field]: options };
        } );
    }

    const updateFilter = ( field : string , value : Omit<FilterItems , 'field'>  ) => {
        /* const newFilters = filters.filter( f => f.field !== field );
        newFilters.push( { field , method , value } ); */
        setFilters( lastFilter => {
            const newFilters = lastFilter.filter( f => f.field !== field );
            newFilters.push( { ...value ,  field } );
            return newFilters;
        } );
    }
    const updateFilterValue = ( field : string , method: FilterMethods , value : FilterValues ) => {
        /* const newFilters = filters.filter( f => f.field !== field );
        newFilters.push( { field , method , value } ); */
        setFilters( lastFilter => {
            const newFilters = lastFilter.filter( f => f.field !== field );
            newFilters.push( { field , method , value } );
            return newFilters;
        } );
    }
    const clearFilter = ( field : string ) => {
        setFilters( lastFilter => {
            const newFilters = lastFilter.filter( f => f.field !== field );
            return newFilters;
        } );
    }
    const clearAllFilters = () => {}
    const fitlerParams : Record<string, string> = useMemo( () => {
        return filters.reduce( ( acc , filter ) => {
            switch( filter.method ){
                case 'eq':
                    return { ...acc , [filter.field]: filter.value };
                case 'between':
                    return { ...acc , [`${filter.field}:gte`]: filter.min , [`${filter.field}:lte`]: filter.max };
                default:
                    return { ...acc , [`${filter.field}:${filter.method}`]: filter.value };
            }
            
        } , {} );
    } , [JSON.stringify( filters )])

    return <FilterContext.Provider value={{selectOptions , setSelectOption , fitlerParams , filters , updateFilter , updateFilterValue , clearFilter , clearAllFilters }}>{children}</FilterContext.Provider>
}

