import { useParams } from "react-router-dom"
import { useApi } from "../../providers/Api/Api";
import { ChangeEvent, FunctionComponent, useEffect, useRef, useState  } from "react";
import {  Badge, Box, Chip, Paper, Tooltip, Typography, useTheme , IconButton , Dialog , DialogTitle , DialogActions , Button , List , ListItem , ListItemButton , ListItemIcon , ListItemText, DialogContent, Checkbox, Menu, MenuItem, FormControl, InputLabel, Select, Popover, Switch } from "@mui/material";
import { Icon, InlineIcon } from "@iconify/react";
import { StyledTable } from "../../../react-submodules/components/Table/Table";
import { NewComputerType, NewComputerWizard } from "../../../react-submodules/components/NewComputerWizard/NewComputerWizard";
import { DeviceQueryItem } from "../../../react-submodules/types/dattormm.dto";
import { GroupGuard } from "../GroupGuard";
// these are the contracts@@!!!----------------------
type Contracts = 'full_managed' | 'security_only' | 'decommissioned' | 'unknown';
const ContractChip : FunctionComponent<{contract?: string | Contracts , onClick?: () => any }> = ( {contract , onClick } ) => {
    switch( contract ){
        case 'full_managed':
            return <Chip  onClick={()=> onClick && onClick()} sx={{
                cursor: 'pointer'
            }} label={<><InlineIcon icon="icon-park-solid:asterisk" /> Full Managed</>} color="success" />
        case 'security_only':
            return <Chip sx={{
                cursor: 'pointer'
            }} onClick={()=> onClick && onClick()} label={<><InlineIcon icon="bxs:shield" /> Security Only</>} color="info" />
        default:
            return <Chip sx={{
                cursor: 'pointer'
            }} onClick={()=> onClick && onClick()} label={<><InlineIcon icon="basil:cancel-solid" /> Unknown</>} color="warning"  />
        case 'decommissioned':
            return <Chip sx={{
                cursor: 'pointer'
            }} onClick={()=> onClick && onClick()} label={<><InlineIcon icon="bxs:shield" /> Decommissioned</>} color="error" />
    }
}

const ContractMenuItem : FunctionComponent<{
    icon: string;
    text: string;
    color: 'success' | 'info' | 'warning' | 'error';
    selected?: boolean;
    onClick?: () => any 
}> = ({ icon , text , color , selected , onClick }) => {
    // this is list of compenonts I would maybe have to place a checkbox inside this.
return <ListItem >
            <ListItemButton onClick={() => onClick && onClick()}>
              <ListItemIcon color={color}>
                <IconButton color={ selected ? color : 'default'}>
                <InlineIcon icon={icon} />
                </IconButton>
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
}


//CONTRACT = WILL NEED THIS WHEN I DO CHECKBOXES____-----------------------
const Contract : FunctionComponent<{contract?: string | Contracts , deviceId : number , refresh: () => any  }> = ( {contract , deviceId , refresh } ) => {
    const { id } = useParams<{id: string}>();
    const api = useApi();
    const [ open , setOpen ] = useState(false);
    const [ selected , setSelected ] = useState<string>( contract || '' );
    // console.log(selected);
    const changeSelected = ( selected : string ) =>{
        setSelected(selected);
        console.log(contract);
        api.post( `/dattormm/${id}/device/${deviceId}/contract` , {
            contract: selected
        }).then( () => {
            console.log(contract);
            refresh();
            setOpen( false );
        })
    }

    return <>
    <Dialog onClose={()=>{
        setOpen(false);
    }} open={open}>
            <List>
                <ContractMenuItem onClick={()=>{
                    changeSelected('full_managed')
                }} selected={ selected === 'full_managed'}  icon="icon-park-solid:asterisk" text="Full Managed" color='success' />
                <ContractMenuItem onClick={()=>{
                    changeSelected('security_only')
                }} selected={ selected === 'security_only'}   icon="bxs:shield" text="Security Only" color='info' />
                <ContractMenuItem onClick={()=>{
                    changeSelected('unknown')
                }}  selected={ !['full_managed' , 'security_only' , 'decommissioned'].includes(selected || '')}   icon="basil:cancel-solid" text="Unknown" color='warning' />
                <ContractMenuItem onClick={()=>{
                    changeSelected('decommissioned')
                }}  selected={ selected === 'decommissioned'}   icon="ph:trash-fill" text="Decommitioned" color='error' />
            </List>
    </Dialog>
    <ContractChip contract={contract} onClick={()=>{
        setOpen( true );
    }} /></>
}


const CpuScore : FunctionComponent<{score?: string}> = ( {score} ) => {
    if( !score ){
        return <></>
    }
    const scoreNumber = parseInt( score.replace( /,/g , '' ) );
    if( scoreNumber > 15000 ){
        return <Badge badgeContent={"A"} color="success"><Chip label={`${score}`}  /></Badge>
    }
    if( scoreNumber > 12000 ){
        return <Badge badgeContent={"B"} color="success"><Chip label={`${score}`}  /></Badge>
    }
    if( scoreNumber > 8000 ){
        return <Badge badgeContent={"C"} color="info"><Chip label={`${score}`}  /></Badge>
    }
    if( scoreNumber > 5000 ){
        return <Badge badgeContent={"D"} color="warning"><Chip label={`${score}`}  /></Badge>
    }
    return <Badge badgeContent={"F"} color="error" ><Chip label={`${score}`}  /></Badge>
}


// const CheckboxColumn: FunctionComponent<{
//     contract: string;
//     onContractChange: (deviceId: number, contract: string, deviceLastUser: string) => void;
//     refresh: () => void;
//     deviceId: number;
//     deviceLastUser: string;
//     }> = ({ contract, onContractChange, refresh, deviceId, deviceLastUser }) => {
   
//     // Check if the contract is full management
//     const { id } = useParams<{ id: string }>();
//     const isFullManagement = contract === 'full_managed';
  
//     const api = useApi();
//     const [open, setOpen] = useState(false);
//     const [selected, setSelected] = useState<string>(contract || '');
  
//     const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
//         const clicked = event.target.checked;
//         const newContract = clicked ? 'full_managed' : 'Unknown';
        
    
  
//         setSelected(newContract);
//         api.post(`/dattormm/${id}/device/${deviceId}/contract`, {
//             contract: newContract,
//         })
//         .then(() => {
//             onContractChange(deviceId, newContract, deviceLastUser);
//             refresh();
//             setOpen(false);
//         });
//     };
  
//     return <Checkbox checked={isFullManagement} onChange={handleCheckboxChange} />;
//   };
 
 //creating a bulk action but using the checkbox column and when you click one of them 
  // the example Tim showed me that he checkbox a few and had a drop down menu with the option
//   const HandleBulkContractChangeColumn : FunctionComponent<{
//     row: any;
//     toggleDeviceId : (deviceId: number) => void;
//     refresh: () => void;
//     }> = ({row, toggleDeviceId, refresh }) => {
//         console.log(toggleDeviceId);
//         // I'm not getting the toggleDeviceId and the refresh function.
//         // Next I'm going to try to undestand how to get the checkbox to stay checked when I click on the bulk update button.
    
//     const [selectedDeviceIds, setSelectedDeviceIds] = useState<number[]>([]);
    
//     // I'm need to set the id by the functionComponent because the id is initialized by the event for the checkbox(HTML Element)
//     // daily.kpi.tsx file.

//     // I need to check to see if the row it checked then do the changes.

//     const handleCheckboxChange = (row: ChangeEvent<HTMLInputElement>) => {
//         const isChecked = row.target.checked;
//         console.log(isChecked);
//         const getInfo = isChecked ? [...selectedDeviceIds, parseInt(row.target.id)] : selectedDeviceIds.filter((id) => id !== parseInt(row.target.id));
        
        
//         console.log(getInfo);
//         // toggleDeviceId(getInfo);


//         getInfo.forEach((deviceId) => {
//             if(isChecked){
//                 console.log(isChecked); // this is working with the checkbox.
//                 console.log(deviceId);
//                 // toggleDeviceId(deviceId);
//                 // toggleDeviceId(parseInt(deviceId));
//                 toggleDeviceId(deviceId);
//                 setSelectedDeviceIds([...selectedDeviceIds, deviceId]);
//                 console.log(deviceId);
//             }
//             else{
//                 setSelectedDeviceIds(selectedDeviceIds.filter((id) => id !== parseInt(row.target.id)));
//             }
//         });   
//     };
    
//     return( 
//         <Checkbox 
//             id={row.device_id.toString()}
//             onChange={(row) => handleCheckboxChange(row)}
//         />  
//     );
//   }

  


// It seems like these are in order of the screen(right to left) or (top down) for the code
// Checkbox Column
// Looping through the columns : DONE
// Add the functionality for the checkbox Also note that I could use the useState() hook to make this easier.
const today = new Date();

const categorySortOrder = ["Server" , "Desktop" , "Laptop"];
// this is Category 
const devicesProcess = ( devices: DeviceQueryItem[] ) => {
    return devices.filter( d => categorySortOrder.includes( d.category ) ).sort( ( a , b ) => {
        return categorySortOrder.indexOf( a.category ) - categorySortOrder.indexOf( b.category );
    } );
}

export const DeviceReport = () => {
    const { id } = useParams<{id: string}>();
    const [ open , setOpen ] = useState(false);
    const [ openContractChange , setOpenContractChange ] = useState(false);
    const [ refreshKey , setRefreshKey ] = useState( 1 );
    const refresh = () =>{
        setRefreshKey( -1 * refreshKey);    
    }
    const [ deviceIds , setDeviceIds] = useState<number[]>([]);
    const [toggleDeviceId, setToggleDeviceId] = useState<number[]>([]);


    const [ selectedDevice , setSelectedDevice ] = useState<DeviceQueryItem | null>(null );
    const [ newTicketId  , setNewTicketId] = useState<string | null>(null)

    useEffect( () => {
        if( selectedDevice !== null && !open ){
            setOpen( true );
        }
    } , [JSON.stringify( selectedDevice )]);
    useEffect( () => {
        if( !open ){
            setSelectedDevice( null );
        }
    } , [open]);
    const api = useApi();
    const [ devices , setDevices ] = useState<any[]>( [] );
    useEffect( () => {
        api.get<DeviceQueryItem[]>( `/reports/devices/${id}` ).then( ( results ) => {
            
            setDevices( devicesProcess(results.data) );
        } );
    } , [api.ready , refreshKey ]);
    
    const toggleContractChangeMenu = () => {
        setOpenContractChange(true);
    }
    const [ selected , setSelected ] = useState<string>( selectedDevice?.contract || '' );
    // const [ newContract, setNewContract ] = useState<string>('');
    // I'm getting the handleChangeContract plus, I trying to get the ids from the switch.
    // then when click on the bulk update button. then handleChangeContract.

    // console.log(selected);
    // I would have to get the deviceIds from the selectedDeviceIds array
    const handleChangeContract  = (newContract: string | Contracts) => {
        // loop every single one inside the outside so it's hitting the api call.
        setSelected(newContract);
        deviceIds.forEach((deviceId) => {
            setSelected(newContract);
            api.post(`/dattormm/${id}/device/${deviceId}/contract`, {
                contract : newContract
            }).then(() => {
                setDeviceIds([]);
                refresh();
                setOpenContractChange(false);
            })
            .catch((error) => {
                console.log(error);
            });
        });
    }
 
    
    return <Paper>
        <Button onClick={toggleContractChangeMenu}>Bulk Update</Button>
        <Dialog open={openContractChange} onClose={() => setOpenContractChange(false)}>
            <DialogTitle>Change Contract</DialogTitle>
                <List>
                    <ContractMenuItem
                        onClick={() => handleChangeContract('full_managed')}
                        selected={ selected === 'full_managed'}
                        icon="icon-park-solid:asterisk" 
                        text="Full Managed" 
                        color='success'/>
                    <ContractMenuItem 
                        onClick={()=> handleChangeContract( 'security_only')}
                        selected={ selected === 'security_only'}
                        icon="bxs:shield"
                        text="Security Only" 
                        color='info'/>
                    <ContractMenuItem 
                        onClick={()=> handleChangeContract( 'unknown')} 
                        selected={ selected === 'unknown'}
                        icon="basil:cancel-solid" 
                        text="Unknown" 
                        color='warning'/>
                    <ContractMenuItem 
                        onClick={()=> handleChangeContract( 'decommissioned')} 
                        selected={ selected === 'decommissioned'}
                        icon="ph:trash-fill" 
                        text="Decommitioned" 
                        color='error'/>
                    <ContractMenuItem  
                        onClick={() => setOpenContractChange(false)}
                        selected={ selected === 'cancel'}
                        icon="icon-park-solid:asterisk" 
                        text="Cancel" 
                        color='error'/>
                </List>
        </Dialog>
        
        { open && <NewComputerWizard  onClose={( ticketId? : string)=>{
            setNewTicketId( ticketId || null );
            setOpen( false );
            refresh();
        }} enableDownload open={open} companyID={parseInt(id||'')} device={selectedDevice || undefined} /> }
        <StyledTable
            refresh={()=>{
                
                refresh();
            }}
        elementProps={{
            openWizard: ( device: DeviceQueryItem ) => {
                setSelectedDevice(device)
            }
        }} rows={devices} columns={[
            {
                // Create a checkbox at the top of the table and maybe within the column. right next check name on top of the column
                field: 'handleBulkContractChange',
                flex: 1,
                headerName: 'Check',
                sortable: true,
                Element: ({row} : any) => {
                    return <> 
                    <Switch value={row.device_id} checked={deviceIds.includes(row.device_id)}
                    onChange={(checked)=>{
                        if(checked.target.checked){ // when I click on the switch it must place the deviceId inside of the array
                            // it's placing the deviceId inside of the array but when I click on it twice then it will
                            
                            // I can loop through all the ids and place them inside of the array
                            
                            
                            if(!deviceIds.includes(row.device_id)){
                                setDeviceIds([...deviceIds, row.device_id]);
                            }
                            
                            console.log(deviceIds);  // this is the array that I'm placing the id inside of.                       
                            console.log(row.device_id);
                            // setState({ checked : value.target.checked });
                            // setDeviceIds([...deviceIds, row.device_id]);
                        }
                        else{
                            setDeviceIds(deviceIds.filter((id) => id !== row.device_id));
                            console.log(deviceIds + " ELSE STATEMENT");
                            console.log(row.device_id + " ELSE STATEMENT");
                        }
                    }} />
                    </>
                }
            },
            {
                field: 'device_lastSeen',
                flex: 1,
                headerName: 'Last Seen',
                sortable: true,
                sortRemap: ( cell : any ) => {
                    return new Date( cell ).getTime();            
                },
                Element: ( { cell } : any ) => {
                    const date = new Date( cell );
                    const diff = today.getTime() - date.getTime();
                    const days = diff / (1000 * 60 * 60 * 24);
        
                    if( days < 1 ){
                        return <Chip label={date.toLocaleDateString()} color="success" />
                    }
                    if( days < 7 ){
                        return <Chip label={date.toLocaleDateString()} color="info" />
                    }
                    if( days < 30 ){
                        return <Chip label={date.toLocaleDateString()} color="warning" />
                    }
                    return <Chip label={date.toLocaleDateString()} color="error" />
        
                }
        
        
            },
            {
                field: 'device_hostname',
                flex: 1,
                headerName: 'Hostname',
                sortable: true
        
            },
            {
                field: 'category',
                flex: 1,
                headerName: 'Category',
                sortable: true
            },
            {
                field: 'device_lastLoggedInUser',
                flex: 1,
                headerName: 'Last User',
                sortable: true,
                sortRemap: ( cell : any ) => {
                    const parts = cell.split( "\\" );
                    if( parts.length === 2 ){
                        return parts[1];
                    }
                    return cell;
                },
                Element: ( { cell , row } : any ) => {
                    if( !cell ){
                        return <></>
                    }
                    let icon = 'icon-park:network-tree';
                    
                    const parts = cell.split( "\\" );
                    if( parts[0].toLowerCase() === "azuread" ){
                        icon = "logos:microsoft-azure";
                    }
                    if( (row?.device_hostname?.toLowerCase().startsWith( parts[0].toLowerCase()) && parts[0].length === 15 ) || parts[0].toLowerCase() === row?.device_hostname?.toLowerCase()  ){
                        icon = "icon-park-twotone:computer";
                    }
                    if( parts.length === 2 ){
                        return <Box sx={{
                        }}>
                            <Box sx={{margin: 'auto'}}>
                                <Box sx={{ marginLeft: 2 }}><Typography sx={{fontWeight: 'bold' , fontSize: 14}} >{icon && <InlineIcon icon={icon} />} {parts[1]}</Typography></Box>
                            </Box>
                        </Box>;
                    }

                    return <></>
                    
                },
                
            } , 
            {
                field: 'contract',
                flex: 1,
                headerName: 'Contract',
                sortRemap: ( cell : any ) => {
                    if( cell === 'full_managed'){
                        return 10
                    }
                    if( cell === 'security_only'){
                        return 5
                    }
                    return 0
                },
                sortable: true,
                Element: ( { cell , row , refresh } : any ) => {
                    return <Contract refresh={()=>{ refresh() }} contract={cell} deviceId={row.device_id} />
                }
            } , 
            {
                field: 'cpu_score',
                flex: 1,
                headerName: 'Cpu Score',
                sortRemap: ( cell : any ) => {
                    return parseInt( cell?.replace( /,/g , '' ) ||'0' );
                },
                sortable: true,
                Element: ( { cell } : any ) => {
                    return <CpuScore score={cell} />
                }
            } , 
            
            {
                field: 'device_id',
                flex: 1,
                headerName: 'Actions',
                sortable: false,
                Element: ({ cell, row , openWizard } : any ) => {
                    const [ open , setOpen ] = useState( false );
                    return <>
                        <Dialog open={open} onClose={()=>{
                            setOpen( false );
                        }}>
                            <DialogTitle>Replace Computer {row.device_hostname}</DialogTitle>
                            <DialogContent>
                                <Typography sx={{ fontWeight: 'bold'}}>Ticket ID : {row.hardware_replacement_ticket_id}</Typography>
                                <Typography sx={{whiteSpace: 'pre-line'}}>{row.hardware_replacement_description}</Typography>
                            </DialogContent>
                        </Dialog>
                        <GroupGuard group="tech"><Tooltip title="Web Remote"><IconButton sx={{marginRight: 1}} size="small" color="info"
                        onClick={()=>{
                            window.open(`https://concord.centrastage.net/csm/remote/rto/${cell}` , `remote_${cell}`)
                        }}
                    ><Icon icon="mdi:remote-desktop" /></IconButton></Tooltip>
                    <Tooltip title="Datto Device"><IconButton onClick={()=>{
                        window.open(`https://concord.rmm.datto.com/device/${cell}` , `device_${cell}`)
                    }} color="info" size="small"
                            ><Icon icon="icon-park-outline:code-computer" />
                    </IconButton></Tooltip>
                    </GroupGuard>
                        {!row.hardware_replacement_ticket_id &&<Tooltip title="Replace Computer"><IconButton onClick={()=>{
                            openWizard(row )
                        }}><InlineIcon icon="material-symbols:shopping-cart-outline" /></IconButton></Tooltip> }
                        {row.hardware_replacement_ticket_id && <Tooltip title="Replace Computer"><IconButton color="info" onClick={()=>{
                            setOpen(true)
                        }}><InlineIcon icon="material-symbols:shopping-cart" /></IconButton></Tooltip>}
                    </>
                }
            },
            
        ]} />

        <Dialog open={newTicketId !== null} onClose={()=>{
                    setNewTicketId( null );
                }}>
            <DialogTitle>Order Placed</DialogTitle>
            <Box sx={{margin: 2}}>Your order has been placed, the ticket id is {newTicketId}, If you have any questions or comments please email helpdesk@coulee.tech with that ticket id in the subject or call 608-783-8324
            
            </Box>
            <DialogActions>
                <Button onClick={()=>{
                    setNewTicketId( null );
                }}>Close</Button>
            </DialogActions>
        
        </Dialog>
        </Paper>
}