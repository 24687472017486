import { createContext, FC, PropsWithChildren, useContext, useState } from "react";
import { ActiveUsersQuery } from "../gql/ActiveUsers.generated";
import { Typography } from "@mui/material";
import { Popover } from "@mui/material";
import { TicketView } from "../../../react-submodules/components/TicketView/TicketView";


export type HoverPopoverContextType = {
   
    onHover : ( item:  {
        timeclockItem : TimeclockItem,
        anchorEl : any,
    } ) => any;
    onLeave : () => void;
}

export const HoverPopoverContext = createContext<HoverPopoverContextType>({
    onHover : () => {},
    onLeave : () => {}
});
export const useHoverPopover = () => useContext(HoverPopoverContext);

export type TimeclockItem = NonNullable<NonNullable<ActiveUsersQuery['findCoreUsersPaginated']['data']>[number]['activeTimeclocks']>[number];
export const TicketPopover : FC<PropsWithChildren> = ({ children }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [timeclockItem, setTimeclockItem] = useState<TimeclockItem | null>(null);

    const onHover = ( item : {
        timeclockItem : TimeclockItem,
        anchorEl : any,
    } ) => {
        console.log(item);
        setAnchorEl(item.anchorEl);
        setTimeclockItem(item.timeclockItem);
    }

    const onLeave = () => {
        console.log("leave");
        setAnchorEl(null);
        setTimeclockItem(null);
    }


    return       <><Popover
    sx={{ pointerEvents: 'none' }}
    open={Boolean(anchorEl)}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'center',
      horizontal: 'right',
    }}
    onClose={onLeave}
    disableRestoreFocus
  >
    <Typography sx={{ p: 1 }}>{ Boolean(timeclockItem?.ticket?.id) &&  <TicketView id={timeclockItem?.ticket?.id || 0} />}</Typography>
  </Popover>
  <HoverPopoverContext.Provider value={{
    onHover,
    onLeave
  }}>
  {children}
  </HoverPopoverContext.Provider>
  </>
}