import { Box, Button, IconButton, InputAdornment, Popover, SxProps, Table, TableBody, TableCell, TableHead, TableRow, TextField, Theme, Typography, useTheme } from "@mui/material";
import { DataGrid, GridColDef, gridClasses } from "@mui/x-data-grid";
import { FunctionComponent, useCallback, useEffect, useMemo } from "react";
import { alpha, styled } from '@mui/material/styles';

import { InlineIcon } from "@iconify/react";
import { TableColumnDef } from "../Table";
import { useFilter } from "../../../providers/FilterProvider";
import { useState } from "react";

export const FilterMenuString = ( { column , onClose } : { column : TableColumnDef , onClose: ()=> void } ) => {
    const {filter , updateFilterValue , clearFilter } = useFilter( column.field );
    const [value, setValue] = useState( filter?.value || '' );
    const theme = useTheme();
    const handleClose = ()=>{ 
      if( !value ){
        clearFilter();
      } else {
        updateFilterValue( 'like' , value);
      }    
      onClose();
    }
    
    return <>
    <Box sx={{background: theme.palette.background.paper , borderRadius: 1 }}><Box sx={{padding: 1 }}>
    <TextField label="search" size="small" sx={{ background: theme.palette.background.paper , borderRadius: 1}} value={value} onChange={( e ) => {
      setValue( e.target.value );
    } } />
    </Box></Box>
    <Box sx={{padding: 1 , position: 'relative' , width: '100%' , height: '48px' }}>
      <Button size="small"  onClick={()=>{ clearFilter(); onClose(); }} color="warning" sx={{ position: 'absolute' , right: '75px'}} variant="contained">Clear</Button>
      <Button size="small" onClick={handleClose} color="secondary" sx={{ position: 'absolute' , right: '0'}} variant="contained">Save</Button>
    </Box>
    </>
   }