import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { FormInstance } from "../../../coretypes/form.dto";
import { NewTodoDialog } from "../NewTodoDialog";
import { RecipeEpoc } from "./types";
import { TTodosTodo } from "../../types/todos.dto";
import { TodoEpocSection } from "./TodoEpocSection";
import { TodoItem } from "./TodoItem";
import { useRecipeApi } from "../../providers/Recipe";
import { useTicket } from "../../providers/TicketProvider";
import { InlineIcon } from "@iconify/react";
import { useTicketApi } from "../../providers/TicketApiProvider";
import { StartRecipeDialog } from "../StartRecipeDialog/StartRecipeDialog";
import { AddButton } from "../AddButton";
import { MenuSelect } from "../MenuSelect/MenuSelect";
import { ADD_ICON, ONSITE_ICON, QUESTION_ICON, RECIPE_ICON, TASK_ICON } from "../../constances/icons";
import { SchedulerWizard } from "../SchedulerWizard";
import { QueueTicket } from "../../types/ticketqueue";
import { TicketQuickActions } from "../TicketQuickActions/TicketQuickActions";
import { BulkTodoDialog } from "../BulkTodoDialog/BulkTodoDialog";
import { useTicketTodoGroupsQuery } from "./gql/TicketTodoGroups.generated";
import { TodoGroupDisplay } from "./TodoGroupDisplay";

type TodoSidebarProps = {
    refresh: () => void;
    todos: TTodosTodo[];
    forms: FormInstance[];
    ticket: QueueTicket
};


const todoSort = ( a : TTodosTodo , b : TTodosTodo ) => {
    if( a.complete && !b.complete ) return 1;
    if( !a.complete && b.complete ) return -1;
    return 0;

}

export const TodoSidebarCore : React.FC<TodoSidebarProps> = ({ todos , refresh , forms , ticket }) => {
    const [ createTodo , setCreateTodo ] = useState<boolean | Partial<TTodosTodo>>( false );
    const { queueId ,recipeStarts  } = useTicketApi();
    const [ recipeDialogOpen , setRecipeDialogOpen ] = useState( false );
 
    

    const [ openScheduleTech , setOpenScheduleTech ] = useState( false );
    const [ openBulkTodo , setOpenBulkTodo ] = useState( false );

    const epocs : RecipeEpoc[] = useMemo( () => {
        const epocIdsTasks = todos.map( t => t.recipe_epicId ).filter( (v,i,a)=>{
            return a.indexOf(v) === i;
        });
        const epocIdsForms = forms.map( f => f.recipe_epicId ).filter( (v,i,a)=>{
            return a.indexOf(v) === i;
        });
        const epocIds = [...epocIdsTasks, ...epocIdsForms].filter( (v,i,a)=>{
            return a.indexOf(v) === i;
        });
        const epocTask = epocIds.map( (e) => {
            const epocTodo = todos.filter( t => t.recipe_epicId === e ).sort( (a , b) => {
                if( a.createdAt === b.createdAt ){
                    return 0;
                }
                if( a.createdAt === null ){
                    return -1;
                }
                if( b.createdAt === null ){
                    return 1;
                }
                return new Date(a.completeAt || '').getTime() - new Date( b.createdAt || '').getTime()
            }).pop();
            if( epocTodo ){
                return epocTodo
            }
            const epocForm = forms.filter( f => f.recipe_epicId === e ).sort( (a , b) => {
                if( a.createdAt === b.createdAt ){
                    return 0;
                }
                if( a.createdAt === null ){
                    return -1;
                }
                if( b.createdAt === null ){
                    return 1;
                }
                return 0;
            }).pop();
            if( epocForm ){
                return epocForm
            }
            return undefined;
        }).filter( e => e !== undefined) as TTodosTodo[];

        return epocTask.map( e => {
            return {
                id: e.recipe_epicId,
                title: e.recipe_title
            }
        }).filter( e => e.id !== null );

    } , [JSON.stringify( todos)]);

    const todosWithoutItems = todos.filter( t => t.recipe_epicId === null && t.todoGroupId === null );

    return <>
    <Box sx={{
        display: 'flex',
        flexDirection: 'row'
    }}><MenuSelect sx={{
        marginTop: 1 , marginLeft: 2
    }} size="small" menuItems={[
        {
            label: "Recipes",
            icon: RECIPE_ICON,
            iconColor: 'info',
            onClick: () => {
                setRecipeDialogOpen( true );
            }
        },
        {
            label: "Todo",
            icon: TASK_ICON,
            iconColor: 'secondary',
            onClick: () => {
                setCreateTodo(true);
            }
        },
        {
            label: "Question",
            iconColor: '#FC2',
            icon: QUESTION_ICON,
            onClick: () => {
                setCreateTodo({ noteType: 'question' });
            }
        },
        {
            label: "Schedule Tech Onsite",
            iconColor: 'warning',
            icon: ONSITE_ICON,
            onClick: () => {
                setOpenScheduleTech( true );
            }
        },
        {
            label: "Bulk Todo",
            icon: TASK_ICON,
            iconColor: 'success',
            onClick: () => {
                setOpenBulkTodo( true );
            }
        }
    ]} icon={ADD_ICON} title="Add" color="success" />
<Box sx={{ marginLeft: 2}}>
<TicketQuickActions refreshTicket={() => {
                        refresh();
                    }} /></Box></Box>

    { createTodo && <NewTodoDialog todo={typeof createTodo === "object" ? createTodo : undefined} open={Boolean(createTodo)} onClose={()=>{
        setCreateTodo(false);
        refresh();    
    }}/> }
    { openBulkTodo && <BulkTodoDialog open={openBulkTodo} onClose={()=>{
        setOpenBulkTodo( false );
        refresh();    
    }} /> }
    { Boolean(epocs.length) && <Box sx={{
        margin: 1,
        borderBottom: '1px solid rgba(0,0,0,.4 )'
    }}><Typography variant="body2">Recipe Tasks</Typography>
    {epocs.map( e => <TodoEpocSection key={e.id} epoc={e} todos={todos} forms={forms} refresh={refresh} />)}
    </Box> }
    <TodoGroupDisplay ticketId={ticket.id} todos={todos} refresh={refresh} />
    <Box sx={{
        margin: 1,
        borderBottom: '1px solid rgba(0,0,0,.4 )'
    }}><Typography variant="body2">Tasks</Typography></Box>
    {todosWithoutItems.filter( todo => todo.noteType !== 'question' ).reverse().map( todo => {
        return <TodoItem todo={todo} key={todo.id} onChange={()=>{refresh()}} />
    })}
    <Box sx={{
        margin: 1,
        borderBottom: '1px solid rgba(0,0,0,.4 )'
    }}><Typography variant="body2">Questions</Typography></Box>
    {todosWithoutItems.filter( todo => todo.noteType === 'question' ).sort(todoSort ).map( todo => {
        return <TodoItem todo={todo} key={todo.id} onChange={()=>{refresh()}} />
    })}
    <Dialog onClose={()=> {
        setOpenScheduleTech( false );
        refresh();
    }} open={openScheduleTech} maxWidth="lg">
        <DialogContent sx={{width: '800px'}}>
            <SchedulerWizard onClose={()=>{
            refresh();
            setOpenScheduleTech( false );
        }}  initalState={{
                ticketId: ticket.id
            }} />
        </DialogContent>
    </Dialog>
    <StartRecipeDialog contextType="ticket" open={recipeDialogOpen} onClose={()=>{
            refresh();
            setRecipeDialogOpen( false );
        }}  />
    </>
}

export const TodoSidebar: React.FC = () => {
    const { todos , refresh , forms , ticket } = useTicket();
    

  if( !ticket ){
    return <></>
  }
   return <TodoSidebarCore forms={forms} todos={todos} refresh={refresh} ticket={ticket} />

};

