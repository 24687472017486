import { FC } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";
import { InlineIcon } from "@iconify/react";

// Define the TBillable type
export type TBillable = 'Managed' | 'Hourly' | 'TBD' | null | string;

type WithBillable = {
  billable?: TBillable;
}

// Helper functions for billable icons and colors
export const billableToIcon = (billable?: TBillable) => {
  switch (billable) {
    case 'Hourly':
      return 'zondicons:hour-glass';
    case 'Managed':
      return 'clarity:checkbox-list-line';
    default:
      return 'fluent-mdl2:unknown-solid';
  }
};

export const billableToColor = (billable?: TBillable) => {
  switch (billable) {
    case "Managed":
      return '#0099ff';
    case "Hourly":
      return '#cccc00';
    default:
      return '#ff0000';
  }
};

// Hardcoded billable options
const billableOptions: TBillable[] = ['Managed', 'Hourly', 'TBD', null];

export const GqlBillable = <T extends WithBillable>({ 
  ticket,
  edit,
  onChange,
  iconOnly
}: {
  ticket: T;
  edit?: boolean;
  onChange?: (current: T | ((prev: T) => T)) => void;
  iconOnly?: boolean;
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(current => ({
        ...current,
        billable: event.target.value as TBillable
      }));
    }
  };

  if (!edit) {
    return (
      <Typography>
        <InlineIcon 
          color={billableToColor(ticket.billable)} 
          icon={billableToIcon(ticket.billable)} 
        />
        &nbsp;{!iconOnly && ticket.billable}
      </Typography>
    );
  }

  return (
    <TextField
      select
      size="small"
      fullWidth
      label="Billable"
      value={ticket.billable || ''}
      error={!['Hourly', 'Managed'].includes(ticket.billable || '')}
      onChange={handleChange}
    >
      {billableOptions.map((option) => (
        <MenuItem 
          key={option || '_DEFAULT'} 
          value={option || ''} 
          sx={{display: 'flex', flexDirection: 'row'}}
        >
          <Typography>
            <InlineIcon 
              color={billableToColor(option)} 
              icon={billableToIcon(option)} 
            />
            &nbsp;{option}
          </Typography>
        </MenuItem>
      ))}
    </TextField>
  );
}; 