import { AutocompleteContact, QueueTicket } from "../../types/ticketqueue";
import { Box, FormControl, InputAdornment, InputLabel, ListSubheader, MenuItem, Select, TextField, Typography } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { Icon, InlineIcon } from "@iconify/react";

import { LastUser } from "../LastUser";
import { TDevice } from "../../types/dattormm.dto";
import { useApi } from "../../providers/Api/Api";
import { useTicket } from "../../providers/TicketProvider";
import { useTicketApi } from "../../providers/TicketApiProvider";

const likelynessRank = ( ticket : QueueTicket | null , device : TDevice , contact?: AutocompleteContact  ) => {
    const usernames = [];
    const emailEnding = ( contact?.emailAddress || ticket?.contacts_emailAddress || ""  )?.split("@")[0].toLowerCase();
    if( emailEnding ){
        usernames.push( emailEnding );    
    }    
    const firstName = contact?.firstName?.toLowerCase() || ticket?.contacts_firstName?.toLowerCase();
    const lastName = contact?.lastName?.toLowerCase() ||ticket?.contacts_lastName?.toLowerCase();

    if( firstName && lastName ){
        usernames.push( `${firstName}.${lastName}`.toLowerCase() );
        usernames.push( `${firstName}${lastName}`.toLowerCase() );
        usernames.push( `${firstName[0]}${lastName}`.toLowerCase() );
        usernames.push( `${firstName}${lastName[0]}`.toLowerCase() );
    }
    const lastUser = (device?.lastLoggedInUser?.split("\\")[1] || device?.lastLoggedInUser?.split("\\")[0] || "").toLowerCase();
    
    if( usernames.includes( lastUser ) ){
        return 'likely';    
    }
    if( !lastName || !firstName ){
        return 'unmatched';
    }

    if( lastUser.includes( lastName) || lastUser.includes( firstName) ){
        return 'possible';
    }
    return 'unmatched'
};




export const Device: FC<{ ticket?: Partial<QueueTicket>, edit?: boolean; configurationItemID?: number | null , onChange?: ( company : any | null ) => any}> = (
    { configurationItemID , edit , onChange , ticket : currentTicket  }
) => {
    const {ticket} = useTicket();
    const { contacts } = useTicketApi();

    const contactId = currentTicket?.contactID || ticket?.contactID;

    const contact = useMemo(() => {
        return contacts.find( ( c ) => {
            return c.id === contactId;
        });
    }, [ JSON.stringify( contacts) , contactId])

    const [devices , setDevices] = useState< Array<TDevice> | null >( null );
    const [searchTextDebounced, setSearchTextDebounced] = useState("");
    const [searchText, setSearchText] = useState("");
    const [ coreCompanyId , setCoreCompanyId ] = useState<number | null>( currentTicket?.companies_coreCompanyId || null );
    useEffect( () => {
        const timeout = setTimeout( () => {
            setSearchTextDebounced( searchText)
        } , 1000);
        return () => {
            clearTimeout( timeout );
        }
    } , [searchText]);

    const searchDevices = useMemo(()=>{
        const lowerSearchText = searchTextDebounced?.toLowerCase();
        return devices?.filter( ( device ) => {
            return device.hostname?.toLowerCase().includes( lowerSearchText ) || device.lastLoggedInUser?.toLowerCase().includes( lowerSearchText ) || device.id === configurationItemID;
        } ).filter( (item)=>{
            return !!item.configurationItemID;
        }).filter( (item , index , arr ) => {
            return arr.findIndex( i => i.id === item.id ) === index;
        }  ).map( d => {
            return { ...d , likelynessRank: likelynessRank( ticket , d , contact ) } 
        }) ||[];
    } , [JSON.stringify(devices) , searchTextDebounced , JSON.stringify( contact )] );

    const likelyDevices = useMemo(()=>{
        return searchDevices.filter( d => d.likelynessRank === 'likely');
    }, [JSON.stringify( searchDevices )]);
    const possibleDevices = useMemo(()=>{
        return searchDevices.filter( d => d.likelynessRank === 'possible');
    }, [JSON.stringify( searchDevices )]);

    const unmatchedDevices = useMemo(()=>{
        return searchDevices.filter( d => d.likelynessRank === 'unmatched').slice( 0 , 10 );
    }, [JSON.stringify( searchDevices )]); 

    const api = useApi();
    
    useEffect( () => {
        if( !coreCompanyId ){
            setDevices( null );
            return;
        }
        api.get(`/dattormm/devices/${coreCompanyId}`).then( ( res ) => {
            setDevices( res.data );
        });
        
    } , [ coreCompanyId  ]);

    useEffect( () => {
        if( currentTicket?.companyID ){
            api.get(`/autotask/company/${currentTicket.companyID}/field/coreCompanyId`).then( ( { data } ) => {
                setCoreCompanyId( data.coreCompanyId );
            });
            return;
        }
        if( currentTicket?.companies_coreCompanyId ){
            setCoreCompanyId( currentTicket.companies_coreCompanyId );
            return;
        }
        setCoreCompanyId( null );
    } , [
        currentTicket?.companies_coreCompanyId ,
        currentTicket?.companyID 
     ]);
    
    if( !edit){
        return <Box>{ticket?.device?.hostname} {ticket?.device && <LastUser device={ticket.device} />}</Box>
    }
    return <FormControl variant='outlined' style={{ width: '100%' }} >
    <InputLabel sx={{backgroundColor: 'white' , margin: '2px'}} >Device</InputLabel><Select onChange={( v ) => {
        onChange && onChange( v );
    }} value={configurationItemID || ''} onClose={() => setSearchText("")} fullWidth>
        <ListSubheader>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon icon="material-symbols:search" />
                  </InputAdornment>
                )
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                
                if( e.key === 'Enter'){
                    setSearchTextDebounced( searchText );
                }
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          <ListSubheader>Likely Match</ListSubheader>
          {likelyDevices.map((device, i) => (
            <MenuItem sx={{marginLeft: 2}} key={device.id} value={device.configurationItemID || ''}>
            {device.hostname} <LastUser device={device} />
          </MenuItem>
          ))}
          <ListSubheader>Possible Match</ListSubheader>
          {possibleDevices.map((device, i) => (
            <MenuItem sx={{marginLeft: 2}} key={device.id} value={device.configurationItemID || ''}>
            {device.hostname} <LastUser device={device} />
          </MenuItem>
          ))}
          <ListSubheader>Everything Else</ListSubheader>
          {unmatchedDevices.map((device, i) => (
            <MenuItem sx={{marginLeft: 2}} key={device.id} value={device.configurationItemID || ''}>
            {device.hostname} <LastUser device={device} />
          </MenuItem>
          ))}
    </Select>
    </FormControl>
    
}