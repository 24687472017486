import { FC } from "react"
import { ReportData, PhoneCalls, PhoneCallLeg } from "./ReportActivity.types"
import { 
    Accordion, 
    AccordionSummary, 
    AccordionDetails,
    Typography,
    Box,
    Paper,
    Grid
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { DateTime } from "luxon"

export const ReportItemDialogDebugCallsTab: FC<{ item: ReportData | null }> = ({ item }) => {
    if (!item) return null

    const answerableCalls = item.calls.filter(call => 
        item.userAnswerableInboundCallsIds.includes(call.id)
    )

    const getDuration = (start?: string | null, end?: string | null) => {
        if (!start || !end) return null
        const startDt = DateTime.fromISO(start)
        const endDt = DateTime.fromISO(end)
        const diff = endDt.diff(startDt, ['minutes', 'seconds'])
        return `${diff.minutes}m ${Math.floor(diff.seconds)}s`
    }

    const isResourceUser = (userId?: string | null) => {
        return userId === item.resourceId
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Typography variant="h6" gutterBottom>
                Answerable Calls ({answerableCalls.length})
            </Typography>
            {answerableCalls.map((call) => {
                const answeredByResourceUser = call.PhoneCallLegs?.some(leg => 
                    leg.CoreUser?.id === item.resourceId
                )

                return (
                    <Accordion 
                        key={call.id} 
                        defaultExpanded={false}
                        sx={{
                            '&.MuiAccordion-root': {
                                '&:before': {
                                    display: 'none',
                                },
                            },
                        }}
                    >
                        <AccordionSummary 
                            expandIcon={<ExpandMoreIcon />}
                            sx={{ 
                                backgroundColor: answeredByResourceUser ? 'success.light' : 'grey.100',
                                '&:hover': { backgroundColor: answeredByResourceUser ? 'success.200' : 'grey.200' },
                                borderRadius: '4px',
                                '&.Mui-expanded': {
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                }
                            }}
                        >
                            <Typography>
                                Call {call.id} - {DateTime.fromISO(call.startTime).toFormat('MM/dd/yyyy HH:mm:ss')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 2 }}>
                            {call.PhoneCallLegs?.map((leg: PhoneCallLeg) => (
                                <Paper 
                                    key={leg.id} 
                                    elevation={1} 
                                    sx={{ 
                                        p: 2, 
                                        mb: 1,
                                        borderLeft: isResourceUser(leg.CoreUser?.id) ? '4px solid' : 'none',
                                        borderLeftColor: 'success.main'
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                Leg {leg.id}
                                                {leg.CoreUser && (
                                                    <Box 
                                                        component="span" 
                                                        sx={{ 
                                                            color: isResourceUser(leg.CoreUser.id) ? 'success.main' : 'text.secondary'
                                                        }}
                                                    >
                                                        {' '}• Answered by {leg.CoreUser.firstName} {leg.CoreUser.lastName}
                                                    </Box>
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold' }}>
                                                Duration: {getDuration(leg.startTime, leg.endTime) || 'N/A'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Typography variant="body2" color="textSecondary">
                                                Start: {DateTime.fromISO(leg.startTime).toFormat('HH:mm:ss')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Typography variant="body2" color="textSecondary">
                                                End: {leg.endTime ? DateTime.fromISO(leg.endTime).toFormat('HH:mm:ss') : 'N/A'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </Box>
    )
}