
import { Box, Button, ButtonGroup } from "@mui/material";
import { FC, useMemo } from "react"
import { LabeledBox } from "../../../react-submodules/components/LabeldBox";

const monthsOfTheYear = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

const g1 = ['jan', 'feb', 'mar', 'apr'];
const g2 = ['may', 'jun', 'jul', 'aug'];
const g3 = ['sep', 'oct', 'nov', 'dec'];
export const MonthsOfTheYearEdit : FC<{ row : Record<string, any> , value : any , onChangeField : (field: string, value: any) => void }> = ({ row , value , onChangeField }) => {
    const selectedMonths = useMemo(() => {
        if( !value || !Array.isArray(value) ) {
            return monthsOfTheYear;
        }
        return value;
    }, [value]);
    const toggleMonth = (month: string) => {
        const newMonths = [...selectedMonths];
        if( newMonths.includes(month) ) {
            newMonths.splice(newMonths.indexOf(month), 1);
        } else {
            newMonths.push(month);
        }
        onChangeField('startTimeDay_monthsOfTheYear', newMonths);
    }
    return <LabeledBox label="Months">
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            gap: 0.25 
        }}>
        <ButtonGroup>{g1.map(month => <Button sx={{ width: '4rem' }} key={month} variant="contained" color={selectedMonths.includes(month) ? 'success' : 'inherit'} onClick={() => toggleMonth(month)} >{month}</Button>)}</ButtonGroup>
        <ButtonGroup>{g2.map(month => <Button sx={{ width: '4rem' }} key={month} variant="contained" color={selectedMonths.includes(month) ? 'success' : 'inherit'} onClick={() => toggleMonth(month)} >{month}</Button>)}</ButtonGroup>
        <ButtonGroup>{g3.map(month => <Button sx={{ width: '4rem' }} key={month} variant="contained" color={selectedMonths.includes(month) ? 'success' : 'inherit'} onClick={() => toggleMonth(month)} >{month}</Button>)}</ButtonGroup>
        </Box>
        </LabeledBox>
}