import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCoreCompaniesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCoreCompaniesQuery = { __typename?: 'Query', findCoreCompaniesPaginated: { __typename?: 'PaginatedCoreCompanies', data?: Array<{ __typename?: 'CoreCompanies', id?: number | null, name: string }> | null } };


export const GetCoreCompaniesDocument = gql`
    query GetCoreCompanies {
  findCoreCompaniesPaginated(page: 0, limit: 1000, active: {eq: true}) {
    data {
      id
      name
    }
  }
}
    `;

/**
 * __useGetCoreCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCoreCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoreCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoreCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCoreCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<GetCoreCompaniesQuery, GetCoreCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCoreCompaniesQuery, GetCoreCompaniesQueryVariables>(GetCoreCompaniesDocument, options);
      }
export function useGetCoreCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoreCompaniesQuery, GetCoreCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCoreCompaniesQuery, GetCoreCompaniesQueryVariables>(GetCoreCompaniesDocument, options);
        }
export function useGetCoreCompaniesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCoreCompaniesQuery, GetCoreCompaniesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCoreCompaniesQuery, GetCoreCompaniesQueryVariables>(GetCoreCompaniesDocument, options);
        }
export type GetCoreCompaniesQueryHookResult = ReturnType<typeof useGetCoreCompaniesQuery>;
export type GetCoreCompaniesLazyQueryHookResult = ReturnType<typeof useGetCoreCompaniesLazyQuery>;
export type GetCoreCompaniesSuspenseQueryHookResult = ReturnType<typeof useGetCoreCompaniesSuspenseQuery>;
export type GetCoreCompaniesQueryResult = Apollo.QueryResult<GetCoreCompaniesQuery, GetCoreCompaniesQueryVariables>;