import { Button, Container, Grid, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Box } from "@mui/material"
import { FC, useEffect, useMemo, useState } from "react"
import { Icon } from '@iconify/react';
import { EDIT_ICON, DELETE_ICON } from '../../../react-submodules/constances/icons';

import { useParams } from "react-router-dom"
import { useApi } from "../../../react-submodules/providers/Api"
import { StyledTable } from "../../../react-submodules/components/Table"
import { AddContractInstance } from "./AddContract"
import { ContractDTO } from "../../../react-submodules/types/contracts.dto"
import { UnitPrice } from "./UnitPrice"
import { AdditionalPrice } from "./AdditionalPrice"
import { EditContract } from "./EditContract"
import { ContractsDownload } from "./ContractsDownload"
import { ContractsUpload } from "./ContractsUpload"
import { isServer } from "../report/DeviceV2/utils";
import { useApiSearchResults } from "../../../hooks/useApiSearchResults";
import { useApiResults } from "../../../hooks/useApiResults";
import { BadgeAndName } from "../../../react-submodules/components/TicketPartials/BadgeAndName";
import { AnyBadge } from "../../../react-submodules/components/AnyBadge";
import { AddFromMatrix } from "./AddFromMatrix";

export const CompanyContracts : FC = () => {
    const { id  } = useParams()
    const api = useApi();
    const [contracts, setContracts] = useState<ContractDTO[]>([]);
    const [devices, setDevices] = useState<any[]>([]);
    const [ showAddContract, setShowAddContract ] = useState(false);
    const [ contractData, setContractData ] = useState<ContractDTO>();
    const [ refreshTicket , setRefreshTicket ] = useState(1);
    const [ deleteConfirmOpen, setDeleteConfirmOpen ] = useState(false);
    const [ contractToDelete, setContractToDelete ] = useState<ContractDTO | null>(null);
    
    const [company ] = useApiResults( `/core/company/${id}`);
    const [total , {refresh : refreshTotal }] = useApiResults<{ total : number }>( `/reports/billing/company/${id}/contract/total`);
    const refresh = () => {
        setRefreshTicket(prev => prev * -1);
    }
    useEffect(() => {
        refreshTotal();
    }, [JSON.stringify(contracts)]);

    const handleDeleteConfirm = () => {
        if (contractToDelete) {
            api.delete(`/contracts/instances/${contractToDelete.id}`).then(() => {
                setDeleteConfirmOpen(false);
                setContractToDelete(null);
                refresh();
                refreshTotal();
            });
        }
    };

    const servers = useMemo(() => {
        return devices.map(  s => isServer(s) )
    }, [JSON.stringify( devices)]);
    
    const workstations = useMemo(() => {
        return devices.map(  s => !isServer(s) )
    }, [JSON.stringify( devices)]);
    useEffect(()=>{
        api.get(`/contracts/instances/search`, {
            coreCompanyId: id
        }).then((res)=>{
            setContracts(res.data);
        });
    },[id, refreshTicket]);

    useEffect(() => {
        api.get(`/reports/devices/${id}`).then((results) => {
            setDevices(results.data);
        });
    }, [id, refreshTicket]);
    

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <AddContractInstance open={showAddContract} onClose={() => {setShowAddContract(false); refresh();}} />
            { contractData && <EditContract open={Boolean( contractData )} onClose={() => {setContractData(undefined); refresh();}} contractData={contractData} /> }
            
            <Container>
                <Paper sx={{padding: 2}}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h4">Contracts</Typography>
                            <Box sx={{ display: 'flex', gap: 1 }}  >
                            
                            <AnyBadge name={company?.name}  /> <Typography variant="h6">{company?.name}</Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box sx={{ '& > :not(style)': { m: 1 } }}>
                                <Button variant="contained" color="primary" onClick={() => setShowAddContract(true)}>Add Contract</Button>
                                <ContractsDownload />
                                <ContractsUpload onUploadComplete={refresh} />
                                <AddFromMatrix onClose={() => setRefreshTicket(prev => prev * -1)} />
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
                <StyledTable<ContractDTO>
                    rows={contracts}
                    columns={[{
                        field: "id",
                        headerName: "ID",
                    } , {
                        field: "contract_name",
                        headerName: "Contract Name",
                    } , {
                        field: "contract_unitsMeasured",
                        headerName: "Units Measured",
                    } , {
                        field: "contract_unitPrice",
                        headerName: "Unit Price",
                        Element: ({ cell, row }) => {
                            return<UnitPrice row={row}></UnitPrice>
                        }
                    } , {
                        field: "contract_additionalPrice",
                        headerName: "Additional Price",
                        Element: ({ cell, row }) => {
                            return<AdditionalPrice row={row}></AdditionalPrice>
                        }
                    } , {
                        field: "approvedUnits",
                        headerName: "Approved Units",
                    }, {
                        field: "usedUnits",
                        headerName: "Used Units",
                        Element: ({ cell }) => (
                            <Typography>{cell|| 0}</Typography>
                        ),
                    }, {
                        field: "unprotected",
                        headerName: "Unprotected",
                        Element: ({ row }) => {
                            switch( row.contract_unitsMeasured ){
                                case "WORKSTATIONS":
                                    return <Typography>{workstations.length - Number( row.usedUnits )}</Typography>
                                case "SERVERS":
                                    return <Typography>{servers.length - Number( row.usedUnits )}</Typography>
                            }
                            return <Typography></Typography>
                        },
                    }, {
                        field: "autoAproveUnits",
                        headerName: "Auto Approve Units",
                        Element: ({ cell }) => (
                            <Typography>{cell ? "Yes" : "No"}</Typography>
                        ),
                    },
                     {
                        field: "actions",
                        headerName: "Actions",
                        Element: ({ row }) => (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <IconButton
                                    color="primary"
                                    onClick={() => setContractData(row)}
                                    size="small"
                                >
                                    <Icon icon={EDIT_ICON} />
                                </IconButton>
                                <IconButton
                                    color="error"
                                    onClick={() => {
                                        setContractToDelete(row);
                                        setDeleteConfirmOpen(true);
                                    }}
                                    size="small"
                                >
                                    <Icon icon={DELETE_ICON} />
                                </IconButton>
                            </div>
                        ),
                    }]}
                />
                <Paper sx={{ padding: 2, marginBottom: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                    <Typography variant="h6">Total Cost: ${(total?.total || 0).toFixed(2)}</Typography>
                </Box>
                </Paper>
            </Container>
        </Grid>
        
        <Dialog
            open={deleteConfirmOpen}
            onClose={() => setDeleteConfirmOpen(false)}
        >
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this contract?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
                <Button onClick={handleDeleteConfirm} color="error" autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    </Grid>
}