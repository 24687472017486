import { FC } from "react"


const convertToMs = (duration: number , durationUnit: 'ms' |  's' | 'm' | 'h') => {
    switch (durationUnit) {
        case 'ms':
            return duration;
        case 's':
            return duration * 1000;
        case 'm':
            return duration * 60000;
        case 'h':
            return duration * 3600000;
    }
}


export const DurationView : FC<{
    duration: number
    durationUnit: 'ms' |  's' | 'm' | 'h'
    minUnit?: 'ms' |  's' | 'm' | 'h'
}> = (props ) => {
    const { duration , durationUnit , minUnit  } = { minUnit: props.durationUnit , ...props};
    const msDuration = convertToMs(duration , durationUnit);
    const hours = Math.floor(msDuration / 3600000);
    const minutes = Math.floor((msDuration % 3600000) / 60000);
    const seconds = Math.floor((msDuration % 60000) / 1000);
    const parts = [];
    if (hours) parts.push(hours + 'h');
    if (minutes && !['h'].includes( minUnit)) parts.push(minutes + 'm');
    if (seconds && !['h', 'm'].includes( minUnit)) parts.push(seconds + 's');
    return <>{parts.join(' ')}</>
}