import { FC, Fragment } from "react";
import { FilterSetting, Operators } from "../types";
import { JsonSchema7ObjectType, JsonSchema7Type } from "zod-to-json-schema";
import { Box, Button, Grid, IconButton, MenuItem, TextField } from "@mui/material";
import { isJsonSchemaArrayType, isJsonSchemaNumberType, isJsonSchemaObjectType, isJsonSchemaStringType } from "../filter.functions";
import { Icon } from "@iconify/react";
type FunctionManipulator<T = any > = (data: T) => T ;


const getContextByPath = (path: string, context: JsonSchema7Type): JsonSchema7Type => {
    if( path === ""){
        return context;
    }
    const pathParts = path.split('.');
    const relativePath = pathParts.shift();
    
    if( relativePath && isJsonSchemaObjectType(context) && context.properties.hasOwnProperty(relativePath) ){
        const newContext = context.properties[relativePath];
        if( isJsonSchemaObjectType( newContext)){
            return getContextByPath( pathParts.join('.'), newContext );
        }
        return context.properties[relativePath];
    }
    return context;
}



const propertiesByKey = (context: JsonSchema7Type): string[] => {
    if( isJsonSchemaObjectType( context )){
        return Object.keys(context.properties);
    }
    return [];
}

const implementedOperators = (): Operators[] => {
    return ['eq' , 'ne' , 'contains' , 'containsAny' , 'in' , 'notIn' , 'endsWith',  'startsWith' , 'exists'  ];
}

const opperatorTypesByContextCore = (context: JsonSchema7Type): Operators[] => {
    if( isJsonSchemaObjectType( context )){
        return [ 'exists'];
    }
    if( isJsonSchemaStringType( context )){
        if( context.format === 'date-time' ){
            return [ 'eq' , 'ne' , 'gt' , 'gte' , 'lt' , 'lte' , 'in' , 'notIn' , 'exists' ];
        }
        return [ 'contains' , 'containsAny' , 'startsWith' , 'endsWith' , 'eq' , 'ne' , 'in' , 'notIn' , 'exists' ];
    }
    if( isJsonSchemaNumberType( context )){
        return [ 'eq' , 'ne' , 'gt' , 'gte' , 'lt' , 'lte' , 'in' , 'notIn' , 'exists' ];
    }
    if( isJsonSchemaArrayType( context )){
        return [ 'contains' , 'containsAny' , 'exists' ];
    }
    return ['exists'];
}
const opperatorTypesByContext = (context: JsonSchema7Type): Operators[] => {
    return implementedOperators().filter( operator => opperatorTypesByContextCore(context).includes(operator) );
}

export const FilterItem : FC<{
    filterSetting : FilterSetting;
    onChange : (id : string , value : FunctionManipulator<Partial<FilterSetting>> | Partial<FilterSetting> ) => void;
    context: JsonSchema7Type
    removeFilter : ( id : string ) => void;
}> = ( { filterSetting , onChange , context , removeFilter } ) => {

    const keyParts = filterSetting.field.split('.');
    

    const contextByPath = getContextByPath(filterSetting.field , context);
    const keys = propertiesByKey( contextByPath );
    return <><Grid item sm={4}>
            <Box display="flex" flexDirection="row" mb={1}>
                {keyParts.map((key, index) => (
                    <Fragment key={index}>
                        
                        <Button size="small" onClick={()=>{
                            onChange( filterSetting.id , { field : keyParts.slice(0, index).join('.') } );
                        }}>{index > 0 && "."}{key}</Button>
                    </Fragment>
                ))}
                { isJsonSchemaObjectType( contextByPath ) && <TextField 
                size="small"
                label="Key"
                select
                fullWidth   
                value={filterSetting.field}
                onChange={(e)=>{
                    onChange( filterSetting.id , { field : keyParts.concat(e.target.value).filter( k => k !== '' ).join('.') } );
                }}
            >
                    {keys.map( key => <MenuItem key={key} value={key}>{key}</MenuItem>)}
                </TextField>
            }
            </Box>
            
        </Grid>
        <Grid item sm={3}>
            <TextField
            size="small"
            label="Operator"
            fullWidth
            select
            value={filterSetting.operator}
            onChange={(e)=>{
                onChange( filterSetting.id , { operator : e.target.value as Operators } );
            }}
            >
                {opperatorTypesByContext( contextByPath ).map( operator => <MenuItem key={operator} value={operator}>{operator}</MenuItem>)}
            </TextField>
        </Grid>
        <Grid item sm={4}>
            { filterSetting.operator !== 'exists' && <TextField
            size="small"
            label="Value"
            fullWidth
            multiline={['in' , 'notIn' , 'containsAny'].includes(filterSetting.operator)}
            value={filterSetting.value}
            maxRows={10}
            onChange={(e)=>{
                onChange( filterSetting.id , { value : e.target.value } );
            }}
            /> }
        </Grid>
        <Grid item sm={1}>
            <IconButton onClick={()=>{
                removeFilter( filterSetting.id );
            }}>
                <Icon icon="mdi:delete" />
            </IconButton>
        </Grid>
        </>
}