import { CircularProgress, List, ListItem, ListItemText, Tooltip } from "@mui/material";
import { FC, useEffect, useState } from "react";

import { QueueTicket } from "../../types/ticketqueue";
import { useApi } from "../../providers/Api/Api";

export const CompanyTicketList : FC<{
    companyID?: number | null;
    contactID?: number | null;
}> = ({contactID , companyID}) => {
    const api = useApi();
    const [ tickets , setTickets ] = useState< Array<QueueTicket> | null >( null );
    useEffect( () => {
        
        let loaded = true;
        setTickets( null );
        if( !companyID ) return;
        api.get<QueueTicket[]>(`/ticketqueue/tickets` , { companyID }).then( ( { data }) => {
            if( loaded ){
                setTickets( data );
            }
        });
        return () => {
            loaded = false;
        }
    } , [ companyID]);
    return  <List>
        { companyID !== undefined && !tickets && <ListItem>
            <CircularProgress />
            </ListItem>}
        { companyID === undefined && !tickets && <ListItem>
            <ListItem >
                <ListItemText primary="Select a company first" />
            </ListItem>
        </ListItem>}  
        { companyID !== undefined && tickets?.length === 0 && <ListItem>
            <ListItem >
                <ListItemText primary="No tickets found" />
            </ListItem>
        </ListItem>}  
        { tickets?.sort( (a , b) => {
            let ascore = 0;
            let bscore = 0;
            if( a.contactID === contactID ) { ascore += 100 };
            if( b.contactID === contactID ) { bscore += 100 };
            return bscore - ascore;
        })?.map( ticket => {
            return <ListItem key={ticket.id}>
                <ListItemText primary={ticket.title} />
            </ListItem>
        })}
    </List>
}