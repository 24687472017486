import { Button, Container, Grid, Paper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { StyledTable, TableColumnDef } from "../../react-submodules/components/Table";
import { useApi } from "../../react-submodules/providers/Api";
import { AddContract } from "./AddContract";
import { Contract } from "./types";
import { EditContract } from "./EditContract";
import { Icon } from "@iconify/react";
import { IconPicker } from "../../react-submodules/components/IconPicker";
import { DEFAULT_CONTRACT_ICON, DEFAULT_CONTRACT_ICON_COLOR } from "../../react-submodules/constances/icons";

const columns : TableColumnDef[] = [
    {
        field: 'id',
        flex: 1,
        headerName: 'ID'        
    },
    {
        field: 'name',
        flex: 1,
        headerName: 'Name'        
    },
    {
        field: 'unitsMeasured',
        flex: 1,
        headerName: 'Units Measured'        
    },
    {
        field: 'unitPrice',
        flex: 1,
        headerName: 'Unit Price'   ,
        Element: ({cell} ) => <>${parseFloat(cell).toFixed(2)}</>     
    },
    {
        field: 'additionalPrice',
        flex: 1,
        headerName: 'Additional Price'   ,
        Element: ({cell} ) => <>${parseFloat(cell).toFixed(2)}</>     
    },
    {
        field: 'instances',
        flex: 1,
        headerName: 'Instances'   ,
        Element: ({cell} ) => <>{cell}</>     
    },
    {
        field: 'usedUnits',
        flex: 1,
        headerName: 'Used Units'   ,
        Element: ({cell} ) => <>{cell}</>     
    },
    {
        field: 'icon',
        flex: 1,
        headerName: 'Icon'   ,
        Element: ({cell , row } ) => <Icon icon={cell || DEFAULT_CONTRACT_ICON } color={row.iconColor || DEFAULT_CONTRACT_ICON_COLOR} />
    }
]

export const Contracts = () => {

    const [ name , setName] = useState('');
    const [ contracts , setContracts] = useState([]);
    const api = useApi();
    const [ refreshTick , setRefreshTick] = useState(1);
    const [ showAddContract , setShowAddContract] = useState(false);
    const [ selectedContract , setSelectedContract] = useState<Contract | null>(null);
    useEffect(() => {
        const getData = setTimeout(() => {
          api.get( '/contracts/search' , { 'name:like' : name } ).then( ( results : { data : any } ) => {
            setContracts( results.data );
          });
        }, 500)
    
        return () => clearTimeout(getData)
      }, [name , refreshTick ]);
      const refresh = () => {
        setRefreshTick( r => r * -1 );
      }
    return <Container sx={{marginTop: 2 }}>
    <Grid container >
        <Grid sm={2}><Button variant="outlined" onClick={()=>{
            setShowAddContract( true );
        }}>Add Contract</Button></Grid>
    </Grid>
    <Paper elevation={2} sx={{padding: 1}}>
        <TextField fullWidth label="Search" value={name} onChange={( e)=>{ setName( e.target.value)}} />
    </Paper>
    <EditContract onClose={()=>{
        setSelectedContract( null );
        refresh();
    }} contract={selectedContract} />
    <AddContract open={showAddContract} onClose={()=>{
        setShowAddContract( false );
        refresh();
    }} />
    <Paper><StyledTable
onClick={( row)=>{
// nav(`/contract/${row.id}`);
setSelectedContract( row );
}}
rows={contracts}
columns={columns}
/>
</Paper>
</Container>

    }