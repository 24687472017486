import { Box , Tab } from "@mui/material";
import { useEffect, useState } from "react"
import { TicketDebug } from "./TicketDebug";
import { TabList , TabPanel , TabContext } from "@mui/lab";
import { EmailToDomain } from "./EmailToDomain";
import { useSearchParams } from "react-router-dom";
import { useSearchParam } from "../../react-submodules/utils/reactrouter";
import { ContractFix } from "./ContractFix";


export const Admin = () => {
    const [ tab , setTab ] = useSearchParam( 'tab' ,  {
        defaultValue : 'ticket_debug',
        resetOnChange : true
    } );
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
      };
    return   <Box sx={{ width: '100%', typography: 'body1' }}>
        <pre>{typeof tab} {tab || 'ticket_debug'}</pre>
    <TabContext value={tab || 'ticket_debug'}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="Ticket Debug" value="ticket_debug" />
          <Tab label="Email to Domain" value="email_to_domain" />
          <Tab label="Contract Fix" value="contract_fix" />
        </TabList>
      </Box>
      <TabPanel value="ticket_debug">
        <TicketDebug />
      </TabPanel>
      <TabPanel value="email_to_domain"><EmailToDomain /></TabPanel>
      <TabPanel value="contract_fix"><ContractFix /></TabPanel>
    </TabContext>
  </Box>
}