import { Dialog, DialogTitle, DialogContent, TextField, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { FC, useState } from "react";
import { useFindAutotaskTicketsPaginatedQuery } from "./gql/FindAutotaskTicketsPaginated.generated";
import type { FindAutotaskTicketsPaginatedQuery } from "./gql/FindAutotaskTicketsPaginated.generated";


interface TicketSearchProps {
    open: boolean;
    onClose: () => void;
    onSelect: (ticketId: number) => void;
    coreCompanyId?: number;
}

type AutotaskTicket = NonNullable<FindAutotaskTicketsPaginatedQuery['findAutotaskTicketsPaginated']['data']>[number];

export const TicketSearch: FC<TicketSearchProps> = ({ open, onClose, onSelect, coreCompanyId }) => {
    const [searchTerm, setSearchTerm] = useState("");
    
    const { data, loading } = useFindAutotaskTicketsPaginatedQuery({
        variables: {
            search: searchTerm,
            coreCompanyId: coreCompanyId
        }
    });

    const handleSelect = (ticketId: number | null) => {
        if (ticketId === null) return;
        onSelect(ticketId);
        onClose();
    };

    const tickets = data?.findAutotaskTicketsPaginated?.data ?? [];

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Search Tickets</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Search tickets..."
                    fullWidth
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                
                <List sx={{ mt: 2 }}>
                    {loading && <ListItem>
                        <ListItemText primary="Loading..." />
                    </ListItem>}
                    
                    {tickets.map((ticket: AutotaskTicket) => (
                        <ListItemButton 
                            key={ticket.id} 
                            onClick={() => ticket.id && handleSelect(ticket.id)}
                        >
                            <ListItemText 
                                primary={`${ticket.ticketNumber} - ${ticket.title}`}
                            />
                        </ListItemButton>
                    ))}

                    {!loading && searchTerm.length >= 3 && tickets.length === 0 && (
                        <ListItem>
                            <ListItemText primary="No tickets found" />
                        </ListItem>
                    )}
                </List>
            </DialogContent>
        </Dialog>
    );
}; 