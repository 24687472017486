import { gql, useMutation, useQuery } from "@apollo/client";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, Typography } from "@mui/material"
import { FC, useEffect, useMemo, useState } from "react";
const MERGE_COMPANIES = gql`
    mutation MergeCompanies($fromCompanyId: Float!, $toCompanyId: Float!) {
        mergeCoreCompanies(fromCompanyId: $fromCompanyId, toCompanyId: $toCompanyId)
    }
`;

const COMPANIES_QUERY = gql`
    query Companies {
        findCoreCompaniesPaginated( limit: 10000 ) {
            data {
                id
                name
            }
        }
    }
`;

export const MergeCompaniesDialog : FC<{
    mergeCompany: number | null;
    onClose: ()=>void;
}> = ({ mergeCompany , onClose }) => {
    const [ mergeCompanies ] = useMutation( MERGE_COMPANIES );
    const { data , loading } = useQuery<{findCoreCompaniesPaginated: {data: {id: number, name: string}[]} }>( COMPANIES_QUERY );
    const [ selectedCompany , setSelectedCompany ] = useState<number | null>( null );
    const fromCompany = useMemo(()=>{
        return data?.findCoreCompaniesPaginated?.data?.find( ( company: { id: number } ) => company.id === mergeCompany );
    }, [JSON.stringify(data), mergeCompany]);
    const toCompany = useMemo(()=>{
        return data?.findCoreCompaniesPaginated?.data?.find( ( company: { id: number } ) => company.id === selectedCompany );
    }, [JSON.stringify(data), selectedCompany]);

    useEffect(()=>{
        if( !selectedCompany && fromCompany && (data?.findCoreCompaniesPaginated?.data?.length || 0) > 1 ){
            const companies = (data?.findCoreCompaniesPaginated?.data ||[] ) as {id: number, name: string}[];
            const company = companies.find( ( company: { id: number , name: string } ) => company.id !== fromCompany.id && company.name.trim().toLowerCase() === fromCompany.name.trim().toLowerCase() );
            if( company ){
                setSelectedCompany( company.id );
            }
        }
    }, [fromCompany , selectedCompany ,JSON.stringify(data) ])
    
    return <Dialog maxWidth="lg" fullWidth open={Boolean(mergeCompany)} onClose={()=>{onClose()}}>
        <DialogTitle>Merge Companies</DialogTitle>
        <DialogContent>
            <Box sx={{marginBottom: 4}}><Typography variant="h6">Merge ({fromCompany?.id}) {fromCompany?.name} into</Typography></Box>
            <TextField
                fullWidth
                label="To Company"
                value={selectedCompany}
                select
                onChange={(e)=>{
                    setSelectedCompany( e.target.value as unknown as number );
                }}
            >
                {data?.findCoreCompaniesPaginated?.data?.map( ( company: { id: number, name: string } ) => (
                    <MenuItem key={company.id} value={company.id}>({company.id}) {company.name} </MenuItem>
                ))}
            </TextField>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="error" onClick={()=>{
                mergeCompanies({variables: {fromCompanyId: fromCompany?.id, toCompanyId: selectedCompany}});
                onClose();
            }}>Merge</Button>
        </DialogActions>
    </Dialog>
}