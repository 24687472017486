import { FC, useEffect, useState } from "react";
import { FieldPicklist, useTicketApi } from "../../providers/TicketApiProvider";
import { MenuItem, TextField, Typography } from "@mui/material";
import { QueuesToColor, QueuesToIcon } from "./queues.utils";

import { InlineIcon } from "@iconify/react";

export const Queue : FC<{ iconOnly?: boolean , edit?: boolean; queue?: string , onChange?: ( field : FieldPicklist ) => any}> = ({ queue , edit , onChange , iconOnly }) => {
    const { queues } = useTicketApi();
    const [ selectedQueue , setSelectedQueue ] = useState< FieldPicklist | null >( queues.find( ( s ) => {
        return s.value === queue || s.label === queue;
    } ) || null );
    useEffect( () => {
        const newQueue = queues.find( ( s ) => {
            return s.value === queue || s.label === queue;
        });
        if( newQueue ){
            setSelectedQueue( newQueue );
        }
    } , [ queue , JSON.stringify( queues )]);



    if( queues.length === 0 ) return <></>;

    if( !edit ){
        return <Typography><InlineIcon color={QueuesToColor(selectedQueue?.label || "")} icon={QueuesToIcon( selectedQueue?.label || "" )} />&nbsp;{!iconOnly && selectedQueue?.label}</Typography>
    }

    return <TextField
    size="small"
    fullWidth
    select
    label="Queue"
    value={selectedQueue?.value}
    onChange={(val)=>{
      const newStatus = queues.find( ( s ) => {
        return s.value === val.target.value || s.label === val.target.value;
    } );
      onChange && onChange( newStatus as any );
    }}
  >
    {queues.map((option) => (
      <MenuItem key={option.value} value={option.value} sx={{display: 'flex' , flexDirection: 'row'}}>
        <Typography><InlineIcon color={QueuesToColor(option.label)} icon={QueuesToIcon( option.label )} />&nbsp;{option.label}</Typography> 
      </MenuItem>
    ))}
  </TextField>
}