// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlighted-resource {
    background-color: #fff3cd !important;  /* or any color you prefer */
    font-weight: bold;
}

.highlighted-resource {
    background-color: #fff3cd !important;
    font-weight: bold;
}

.highlighted-resource-column {
    background-color: rgba(255, 243, 205, 0.3) !important;
}
.highlighted-resource-column {
    background-color: rgba(255, 243, 205, 0.3) !important;
}

.call-id-title {
    font-weight: 900;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Reports/UserActivity/ReportItemDialog.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC,GAAG,4BAA4B;IACnE,iBAAiB;AACrB;;AAEA;IACI,oCAAoC;IACpC,iBAAiB;AACrB;;AAEA;IACI,qDAAqD;AACzD;AACA;IACI,qDAAqD;AACzD;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".highlighted-resource {\n    background-color: #fff3cd !important;  /* or any color you prefer */\n    font-weight: bold;\n}\n\n.highlighted-resource {\n    background-color: #fff3cd !important;\n    font-weight: bold;\n}\n\n.highlighted-resource-column {\n    background-color: rgba(255, 243, 205, 0.3) !important;\n}\n.highlighted-resource-column {\n    background-color: rgba(255, 243, 205, 0.3) !important;\n}\n\n.call-id-title {\n    font-weight: 900;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
