import { DateTime } from "luxon";
import { useSearchParam } from "../../react-submodules/utils/reactrouter";
import { Paper, Grid, TextField } from "@mui/material";
import { StyledTable } from "../../react-submodules/components/Table";
import { useGetTicketSummaryQuery } from "./gql/GetTicketSummary.generated";
import { useMemo } from "react";
import { QueryProps } from "../../coretypes/queries";
import { MarkdownView } from "../../react-submodules/MarkdownView";
import { CompanySearch } from "./components/CompanySearch";
import { TicketsDownload } from "./components/TicketsDownload";

const columns = [
  {
    field: "id",
    headerName: "ID",
  },
  {
    field: "ticketNumber",
    headerName: "Ticket Number",
  },
  {
    field: "status",
    headerName: "Status",
    Element: ({ row }: { row: any }) => (
      <span>{row.autotaskTicketStatus?.name || 'Unknown'}</span>
    )
  },
  {
    field: "contactEmail",
    headerName: "Contact Email",
    Element: ({ row }: { row: any }) => (
      <span>{row.contact?.emailAddress}</span>
    )
  },
  {
    field: "contactName",
    headerName: "Contact Name",
    Element: ({ row }: { row: any }) => (
      <span>{row.contact ? `${row.contact.firstName} ${row.contact.lastName}` : ''}</span>
    )
  },
  {
    field: "aiSummary",
    headerName: "AI Summary",
    Element: ({ row }: { row: any }) => (
      <MarkdownView>{row.aisummary || ''}</MarkdownView>
    )
  }
];

export const TicketSummary = () => {
  const [startDate, setStartDate] = useSearchParam<string>('startDate', {
    typeProp: 'string',
    defaultValue: DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd')
  });
  const [companyId, setCompanyId] = useSearchParam<number | null>('companyId', {
    typeProp: 'number'
  });
  const [page, setPage] = useSearchParam<number>('page', {
    typeProp: 'number',
    defaultValue: 0
  });
  const [limit, setLimit] = useSearchParam<number>('limit', {
    typeProp: 'number',
    defaultValue: 25
  });

  const startDateQuery: QueryProps = useMemo(() => {
    if (startDate) {
      return {
        gte: DateTime.fromISO(startDate).startOf('day').toISO()
      };
    }
    return {};
  }, [startDate]);

  const { data } = useGetTicketSummaryQuery({
    variables: {
      coreCompanyId: companyId ? { eq: companyId } : undefined,
      startDate: startDateQuery,
      page,
      limit
    }
  });

  const rows = useMemo(() => {
    return data?.findAutotaskTicketsPaginated?.data || [];
  }, [data]);

  const totalCount = data?.findAutotaskTicketsPaginated?.totalCount || 0;

  return (
    <>
      <Paper elevation={2} sx={{ padding: 1, marginBottom: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item sm={12} md={6} lg={4}>
            <TextField
              fullWidth
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <CompanySearch
              value={companyId}
              onChange={setCompanyId}
            />
          </Grid>
          <Grid item sm={12} md={12} lg={4}>
            <TicketsDownload
              companyId={companyId}
              startDate={startDate}
            />
          </Grid>
        </Grid>
      </Paper>

      <StyledTable
        rows={rows}
        columns={columns}
        page={page ?? 0}
        limit={limit ?? 25}
        totalCount={totalCount}
        setPage={setPage}
        setPageSize={setLimit}
        enablePagination
      />
    </>
  );
};