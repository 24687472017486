import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchCallsQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  startTime?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  endTime?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  order?: Types.InputMaybe<Types.SortPhoneCallsArgs>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type SearchCallsQuery = { __typename?: 'Query', findPhonesCallsPaginated: { __typename?: 'PaginatedPhonesCalls', totalCount: number, page: number, data?: Array<{ __typename?: 'PhonesCalls', id?: number | null, startTime?: any | null, endTime?: any | null, direction?: string | null, fromNumber?: string | null, toNumber?: string | null, extention?: string | null, PhoneCallLegs?: Array<{ __typename?: 'PhonesCallLegs', createdDateTime: any, createdByCoreUserId?: string | null, updatedDateTime: any, updatedByCoreUserId?: string | null, id?: number | null, extention?: string | null, startTime?: any | null, endTime?: any | null, recordingLocalPath?: string | null, transcription?: string | null, callProvider?: string | null, callProviderId?: number | null, downloadUrl?: string | null, phonesCallId?: number | null, coreUserId?: string | null, coreContactId?: number | null }> | null, CoreCompany?: { __typename?: 'CoreCompanies', name: string, defaultTicketLevel: string, defaultHourlyRate: number, billingConfigured: boolean, active: boolean, id?: number | null, dattormmSites?: number | null, contractsInstances?: number | null } | null }> | null } };


export const SearchCallsDocument = gql`
    query SearchCalls($search: String, $startTime: Date, $endTime: Date, $order: SortPhoneCallsArgs, $page: Int, $limit: Int) {
  findPhonesCallsPaginated(
    search: $search
    page: $page
    limit: $limit
    startTime: {gte: $startTime}
    endTime: {lte: $endTime}
    order: $order
  ) {
    data {
      id
      PhoneCallLegs {
        createdDateTime
        createdByCoreUserId
        updatedDateTime
        updatedByCoreUserId
        id
        extention
        startTime
        endTime
        recordingLocalPath
        transcription
        callProvider
        callProviderId
        downloadUrl
        phonesCallId
        coreUserId
        coreContactId
      }
      startTime
      endTime
      direction
      fromNumber
      toNumber
      extention
      CoreCompany {
        name
        defaultTicketLevel
        defaultHourlyRate
        billingConfigured
        active
        id
        dattormmSites
        contractsInstances
      }
    }
    totalCount
    page
  }
}
    `;

/**
 * __useSearchCallsQuery__
 *
 * To run a query within a React component, call `useSearchCallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCallsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchCallsQuery(baseOptions?: Apollo.QueryHookOptions<SearchCallsQuery, SearchCallsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCallsQuery, SearchCallsQueryVariables>(SearchCallsDocument, options);
      }
export function useSearchCallsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCallsQuery, SearchCallsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCallsQuery, SearchCallsQueryVariables>(SearchCallsDocument, options);
        }
export function useSearchCallsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchCallsQuery, SearchCallsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchCallsQuery, SearchCallsQueryVariables>(SearchCallsDocument, options);
        }
export type SearchCallsQueryHookResult = ReturnType<typeof useSearchCallsQuery>;
export type SearchCallsLazyQueryHookResult = ReturnType<typeof useSearchCallsLazyQuery>;
export type SearchCallsSuspenseQueryHookResult = ReturnType<typeof useSearchCallsSuspenseQuery>;
export type SearchCallsQueryResult = Apollo.QueryResult<SearchCallsQuery, SearchCallsQueryVariables>;