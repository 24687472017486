import { Icon, listIcons } from "@iconify/react";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Pagination, TextField } from "@mui/material";
import { FC, useMemo, useState } from "react";
import * as iconJson from '../utils/icons.json'
const { icons } = iconJson as { icons : string[]};
export const IconPicker : FC<{
    icon?: string;
    onChange?: ( icon : string ) => void;
    color?: string; 
}> = ( { icon , onChange , color } ) => {
    const [ open , setOpen ] = useState( false );
    const [ search , setSearch ] = useState( '' );
    const [ page , setPage ] = useState( 1 ); // Start from 1 instead of 0
    const [ pageSize ] = useState( 100 ); // Remove setPageSize as it's not being used

    const [ manualIcon , setManualIcon ] = useState( '' );

    const filteredIcons : string[] = useMemo( () => {
        const filtered = icons.filter( icon => icon.includes( search ) || !search );
        const startIndex = ( page - 1 ) * pageSize; // Adjust for 1-based page index
        return filtered.slice( startIndex , startIndex + pageSize );
    }, [ search , page, pageSize ] );

    const totalPages = Math.ceil( icons.filter( icon => icon.includes( search ) || !search ).length / pageSize );

    return <><IconButton  sx={{color: color}} onClick={() => setOpen( true )}>
        <Icon  icon={icon || 'carbon:unknown-filled'} />
    </IconButton>
    <Dialog open={open} onClose={() => setOpen( false )}>
        <DialogTitle>Select Icon</DialogTitle>
        <DialogContent>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: 2 }}>
                <TextField
                    size="small"
                    fullWidth
                    label="Manual Icon"
                    value={manualIcon}
                    onChange={(e) => setManualIcon(e.target.value)}
                />
                <IconButton 
                    sx={{color: color}}
                    onClick={() => {
                        if (manualIcon && onChange) {
                            onChange(manualIcon);
                            setOpen(false);
                        }
                    }}
                    disabled={!manualIcon}
                >
                    <Icon icon="mdi:check" />
                </IconButton>
            </Box>

            <TextField
                sx={{marginTop: 2}}
                fullWidth
                label="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
            
            <Box>
                { filteredIcons.map( ( icon ) => (
                    <IconButton sx={{color: color}} key={icon} onClick={() => {
                        setOpen( false );
                        onChange && onChange( icon );
                    }}>
                        <Icon icon={icon} />
                    </IconButton>
                ) ) }
            </Box>
            <Pagination
                count={totalPages}
                page={page}
                onChange={(e, newPage) => setPage(newPage)}
            />
        </DialogContent>
    </Dialog>
    </>
}