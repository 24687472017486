import { Box, Card, CardContent, CardHeader, Grid, MenuItem, Skeleton, Switch, TextField, Typography } from "@mui/material"
import { Handle, Position } from "reactflow"
import { useEffect, useState } from "react";
import { useNodeDataProperty, useRecipie } from "../../../providers/Recipies.provider";

import { Form } from "../../../coretypes/form";
import { useApi } from "../../../react-submodules/providers/Api/Api";
import { LabeledBox } from "../../../react-submodules/components/LabeldBox";

export const FormNode = ({ id , data } : {id : string , data : any  } ) => {
    const api = useApi();
    const [ refreshForms , setRefreshForms ] = useState(1);
    const [ title , setTitle ] = useNodeDataProperty( id , 'title');
    const [ formId , setFormId ] = useNodeDataProperty( id , 'formId');
    const [ startPoint , setStartPoint ] = useNodeDataProperty<boolean>( id , 'startPoint' , false );
    const [ loaded , setLoaded] = useState( false);
    const [ forms , setForms ] = useState<Form[]>([]);
    useEffect( () => {
        api.get('/forms').then( ({ data}) => {
            setForms( data );
            setLoaded( true );
        })
    } , [api.ready , refreshForms])
    return <Box>
        <Card sx={{maxWidth: '300px'}}>
            <CardHeader className="drag-handle" title="Form" />
            <CardContent>{ loaded && (

                <><Grid container spacing={2}>
                    <Grid item xs={12}>
                <TextField sx={{padding: '2px'}} size="small" label="Title" value={title} onChange={(e) => {
                    
                    setTitle( e.target.value );
                }} fullWidth />
                </Grid>
                <Grid item xs={12}>
                <TextField select sx={{padding: '2px'}} size="small" label="Form" value={formId} onChange={(e) => {
                    setFormId( e.target.value );
                }} fullWidth >
                    {forms.map( (form) => (
                        <MenuItem key={form.id} value={form.id}>{form.id} : {form.name}</MenuItem>
                    ))}
                </TextField>
                </Grid>
                <Grid item xs={12}>
                    <LabeledBox label="Is Start Point">
                        <Switch checked={startPoint} onChange={(e) => {
                            setStartPoint( e.target.checked );
                        }} />
                    </LabeledBox>
                </Grid>

                
                </Grid>
                {formId && <a href={`/formbuilder/${formId}`} target={`form_${formId}`}><Typography variant="caption">Edit</Typography></a>}
                </>            
            )}
            { !loaded && (
                <><Skeleton variant="rounded" width={250} height={30} sx={{margin: 2}} />
                <Skeleton variant="rounded" width={250} height={30} sx={{margin: 2}} />
                </>
            )}
            </CardContent>
        </Card>
        <Handle
          type="target"
          position={Position.Left}
          id={`handle-target`}
        />
        <Handle
          type="source"
          position={Position.Right}
          id={`handle-source`}
        />
    </Box>
}
