import { FC } from "react"
import { FormField } from "../../../../coretypes/formfields"
import { TextAreaFormField } from "./TextAreaFormField"
import { TextFormField } from "./TextFormField"

export const FormFieldEdit : FC<{ field : FormField , onFieldChange? : ( field : Partial<FormField> & Pick<FormField , 'id'> ) => void
onFieldSave? : ( field : Partial<FormField> & Pick<FormField , 'id'> ) => void
onFieldDelete? : ( field : FormField ) => void
disableAutosave? : boolean
controlled? : boolean  }> = ({ field , ...props }) => {
    switch( field.type ){
        case "TEXT" : 
            return <TextFormField field={field} {...props} />
        case "TEXTAREA" : 
            return <TextAreaFormField field={field} {...props} />
        default:
            return <></>
    
    }
}

