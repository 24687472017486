import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCoreUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCoreUsersQuery = { __typename?: 'Query', findCoreUsersPaginated: { __typename?: 'PaginatedCoreUsers', data?: Array<{ __typename?: 'CoreUsers', id?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null, CoreCompany?: { __typename?: 'CoreCompanies', name: string, active: boolean, isOwnCompany: boolean, id?: number | null } | null }> | null } };


export const GetCoreUsersDocument = gql`
    query GetCoreUsers {
  findCoreUsersPaginated(active: {eq: true}, coreCompanyId: {eq: 4}) {
    data {
      id
      email
      firstName
      lastName
      CoreCompany {
        name
        active
        isOwnCompany
        id
      }
    }
  }
}
    `;

/**
 * __useGetCoreUsersQuery__
 *
 * To run a query within a React component, call `useGetCoreUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoreUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoreUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCoreUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetCoreUsersQuery, GetCoreUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCoreUsersQuery, GetCoreUsersQueryVariables>(GetCoreUsersDocument, options);
      }
export function useGetCoreUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoreUsersQuery, GetCoreUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCoreUsersQuery, GetCoreUsersQueryVariables>(GetCoreUsersDocument, options);
        }
export function useGetCoreUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCoreUsersQuery, GetCoreUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCoreUsersQuery, GetCoreUsersQueryVariables>(GetCoreUsersDocument, options);
        }
export type GetCoreUsersQueryHookResult = ReturnType<typeof useGetCoreUsersQuery>;
export type GetCoreUsersLazyQueryHookResult = ReturnType<typeof useGetCoreUsersLazyQuery>;
export type GetCoreUsersSuspenseQueryHookResult = ReturnType<typeof useGetCoreUsersSuspenseQuery>;
export type GetCoreUsersQueryResult = Apollo.QueryResult<GetCoreUsersQuery, GetCoreUsersQueryVariables>;