import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useMounted } from "../hooks/useMounted";

const getType = ( defaultValue?: any , type? : 'string' | 'number' | 'boolean' ) => {
    if( type ) return type;
    if( typeof defaultValue === 'number' ) return 'number';
    if( typeof defaultValue === 'boolean' ) return 'boolean';
    return 'string';
}
export const useSearchParam = <T = string>(param : string , options : {
    defaultValue? : T , 
    typeProp? : 'string' | 'number' | 'boolean',
    resetOnChange? : boolean
} = {}  ) : 
[ 
    T | null , 
    ( value : T ) => void 
] => {
    const mounted = useMounted();
    const { defaultValue , typeProp , resetOnChange } = options;
    const type = getType( defaultValue , typeProp );
    const [ searchParams , setSearchParams ] = useSearchParams();
    const [ valueCheck , setValueCheck ] = useState( 1 );
    let value = searchParams.get(param);
    // console.log( { value });
    const setParam = ( value : any ) => {
        if( !mounted() ) return;
        if( resetOnChange ) {
            setSearchParams( { [param]: value });
            return;
        }
        setSearchParams( sp => { 
            sp.set(param, value);
            return sp;
        });
    }
    useEffect(() => {
        if( [null, undefined].includes(value as any ) && defaultValue !== undefined && mounted() ) {
            setParam(defaultValue);
            setTimeout( () => {
                setValueCheck( v => v + 1 );
            } , 50 );
        }        
    }, [value,defaultValue , valueCheck ]);
    
    const usableValue = useMemo(() => {
        if( value === undefined && defaultValue === undefined ) return null;
        if( value === undefined || value === null ) return defaultValue as T;
        if( type === 'string' ) return String(value) as T;
        if( type === 'number' ) return Number(value) as T;
        if( type === 'boolean' ){
            switch( String(value).toLowerCase() ){
                case null:
                case 'null':
                case undefined:
                case 'undefined':
                    return null;
                case 'true':
                case 'yes':
                case '1':
                    return true as T;
                default:
                    return false as T;
            }
        };
        return null;
    },[value,defaultValue,type]);

    return [ usableValue , setParam  ];
}