import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TransferToTechMutationVariables = Types.Exact<{
  assignedToUserId: Types.Scalars['String']['input'];
  atTicketId: Types.Scalars['Int']['input'];
  message?: Types.InputMaybe<Types.Scalars['String']['input']>;
  expireTime?: Types.InputMaybe<Types.Scalars['Date']['input']>;
}>;


export type TransferToTechMutation = { __typename?: 'Mutation', createCoreMessageChannel: { __typename?: 'CoreMessageChannel', id?: number | null, type?: Types.CoreMessageChannelType | null, message?: string | null, assignedToUserId?: string | null, atTicketId?: number | null, createdDateTime: any, expireTime?: any | null, messageEpicId?: string | null } };


export const TransferToTechDocument = gql`
    mutation TransferToTech($assignedToUserId: String!, $atTicketId: Int!, $message: String, $expireTime: Date) {
  createCoreMessageChannel(
    type: TICKET_TRANSFER
    message: $message
    assignedToUserId: $assignedToUserId
    atTicketId: $atTicketId
    expireTime: $expireTime
  ) {
    id
    type
    message
    assignedToUserId
    atTicketId
    createdDateTime
    expireTime
    messageEpicId
  }
}
    `;
export type TransferToTechMutationFn = Apollo.MutationFunction<TransferToTechMutation, TransferToTechMutationVariables>;

/**
 * __useTransferToTechMutation__
 *
 * To run a mutation, you first call `useTransferToTechMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferToTechMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferToTechMutation, { data, loading, error }] = useTransferToTechMutation({
 *   variables: {
 *      assignedToUserId: // value for 'assignedToUserId'
 *      atTicketId: // value for 'atTicketId'
 *      message: // value for 'message'
 *      expireTime: // value for 'expireTime'
 *   },
 * });
 */
export function useTransferToTechMutation(baseOptions?: Apollo.MutationHookOptions<TransferToTechMutation, TransferToTechMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransferToTechMutation, TransferToTechMutationVariables>(TransferToTechDocument, options);
      }
export type TransferToTechMutationHookResult = ReturnType<typeof useTransferToTechMutation>;
export type TransferToTechMutationResult = Apollo.MutationResult<TransferToTechMutation>;
export type TransferToTechMutationOptions = Apollo.BaseMutationOptions<TransferToTechMutation, TransferToTechMutationVariables>;