import { gql, useQuery } from "@apollo/client"
import { InlineIcon } from "@iconify/react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Grid, Typography } from "@mui/material";
import { FC, PropsWithChildren, useMemo, useState } from "react";
import { StatusToColor, StatusToIcon } from "../../../react-submodules/components/TicketPartials/status.util";
import { MarkdownView } from "../../../react-submodules/MarkdownView";
import { LabeledBox } from "../../../react-submodules/components/LabeldBox";
import { DateTime } from "luxon";

const COMPANIES_QUERY = gql`query FindAutotaskTicketsPaginated($date: Date!) {
    findAutotaskTicketsPaginated(
        queueID: { in: [29683484, 1000] }
        nextActionDate: { gte: $date }
        limit: 10000
    ) {
        data {
            id
            companyID
            autotaskCompany {
                id
                companyName
                coreCompanyId
            }
        }
        limit
    }
}
`

const TICKETS_QUERY = gql`query FindAutotaskTicketsPaginated($companyIDs: [Float!]!) {
    findAutotaskTicketsPaginated(
        queueID: { in: [29683484, 1000] }
        companyID: { in: $companyIDs }
        limit: 10000
    ) {
        data {
            id
            companyID
            title
            status
            autotaskTicketStatus {
                name
            }
            priority
            nextActionDate
            nextActionNote
        }
        limit
    }
}
`
type CompaniesQueryDataItem = {
    id: string;
    companyID: string;
    autotaskCompany: {
        id: string;
        companyName: string;
        coreCompanyId: string;
    }
}

type TicketsQueryDataItem = {
    id: string;
    companyID: string;
    title: string;
    status: number;
    autotaskTicketStatus: {
        name: string;
    }
    priority: number;
    nextActionDate: string;
    nextActionNote: string;
}

const ticketTypes = ['Deploy Datto', 'Backup Review', 'M365 Onboarding', 'Darkweb Monitoring', 'Network Assessment' , 'Server Assessment' , 'Threatlocker Deployment' , 'Welcome Email'];

const ticketFilters : Record< (typeof ticketTypes)[number], (ticket: TicketsQueryDataItem) => boolean> = {
    'Deploy Datto': (ticket) => ticket.title.toLowerCase().includes('deploy datto rmm'),
    'Backup Review': (ticket) => ticket.title.toLowerCase().includes('data backup assessment'),
    'M365 Onboarding': (ticket) => ticket.title.toLowerCase().includes('m365 onboarding'),
    'Darkweb Monitoring': (ticket) => ticket.title.toLowerCase().includes('darkweb'),
    'Network Assessment': (ticket) => ticket.title.toLowerCase().includes('assess network plan'),
    'Server Assessment': (ticket) => ticket.title.toLowerCase().includes('assess server'),
    'Threatlocker Deployment': (ticket) => ticket.title.toLowerCase().includes('threatlocker') || ticket.title.toLowerCase().includes('threat locker'),
    'Welcome Email': (ticket) => ticket.title.toLowerCase().includes('welcome email'),
}

type ReportItem = {
    id: string;
    companyID: string;
    companyName: string;
    coreCompanyId: string;
    tickets : {
        [key in (typeof ticketTypes)[number]]?: TicketsQueryDataItem;
    }
}

const OnboardingItemIcon : FC<{ticket?: TicketsQueryDataItem }> = ({ticket} : {ticket?: TicketsQueryDataItem}) => {
    switch( ticket?.autotaskTicketStatus?.name.toLowerCase() ) {
        default:
            return <Box sx={{ marginLeft: 1}}><InlineIcon  color={StatusToColor(ticket?.autotaskTicketStatus?.name || '')} icon={StatusToIcon( ticket?.autotaskTicketStatus?.name || '' )} /></Box>
    }
}

export const SectionLabel: FC<PropsWithChildren<{ label : string , sx? : React.CSSProperties , fontSx? : React.CSSProperties }>> = ({ label, children , sx , fontSx }) => {
    return <Box sx={{ border : "1px solid rgba(0, 0, 0, 0.8 )" , borderRadius : "5px" , padding: 3 , position:'relative' , ...sx }}>
    <Box sx={{ position:'absolute' , top : '-16px' , left : '12px' }} ><Typography sx={{
      backgroundColor : "white" ,
      padding : "0 4px",
      
      fontSize: '1.2rem' , fontWeight: 'bold' , color: "rgba(0, 0, 0, 0.8 )" 
    }} fontSize="12px" variant="caption">{label}</Typography></Box>
    {children}
  
</Box>
};


const OnboardingItemDetails : FC<{ticket?: TicketsQueryDataItem , ticketType: (typeof ticketTypes)[number] }> = ({ticket , ticketType} : {ticket?: TicketsQueryDataItem , ticketType: (typeof ticketTypes)[number]}) => {
    if( !ticket){
        return <Box sx={{margin: 1 , marginBottom: 4 }}>
        <SectionLabel  label={ticketType} > <Grid container spacing={1}><Alert severity="error">No tickets found</Alert></Grid>

    </SectionLabel>
    </Box>
    }
   return <Box sx={{margin: 1 , marginBottom: 4 }}>
    <SectionLabel  label={ticketType} > <Grid container spacing={1}>
        <Grid item xs={12}>
            <LabeledBox  label="Title">{ticket?.title}</LabeledBox>
        </Grid>
        <Grid item xs={12}>
            <LabeledBox  label="Status"><InlineIcon  color={StatusToColor(ticket?.autotaskTicketStatus?.name || '')} icon={StatusToIcon( ticket?.autotaskTicketStatus?.name || '' )} /> {ticket?.autotaskTicketStatus?.name}</LabeledBox>
        </Grid>
        <Grid item xs={12}>
            <LabeledBox  label="Next Action Date">{ticket?.nextActionDate}</LabeledBox>
        </Grid>
        <Grid item xs={12}>
        <LabeledBox  label="Next Action Note"> <MarkdownView>{ticket?.nextActionNote}</MarkdownView></LabeledBox>
        </Grid>
    </Grid>
    </SectionLabel> </Box>
}

export const Onboarding = () => {
    const [date, setDate] = useState<string>(DateTime.now().plus({months: -1}).toFormat('yyyy-MM-dd'));
    const { data } = useQuery<{findAutotaskTicketsPaginated: {data: Array<CompaniesQueryDataItem>}}>(COMPANIES_QUERY, { variables: { date: "2024-09-01" } });
    const companyIDs = useMemo(() => (data?.findAutotaskTicketsPaginated?.data || []).map(t => t.companyID).filter(( c : any , i : any , self : any ) => self.indexOf(c) === i && Boolean(c)), [data?.findAutotaskTicketsPaginated?.data]);
    const companies : Array<{id: string, name: string, coreCompanyId: string}> = useMemo(() => {
        return companyIDs.map((companyID) => {
            const company = data?.findAutotaskTicketsPaginated?.data?.find((c: {companyID: string}) => c.companyID === companyID);
            if( !company ) return null;
            return {
                id: company?.autotaskCompany?.id,
                name: company?.autotaskCompany?.companyName,
                coreCompanyId: company?.autotaskCompany?.coreCompanyId
            }
        }).filter((c) => c !== null) as Array<{id: string, name: string, coreCompanyId: string}>;
    }, [data?.findAutotaskTicketsPaginated?.data , companyIDs ]);

    const { data : ticketsData } = useQuery<{findAutotaskTicketsPaginated: {data: Array<TicketsQueryDataItem>}}>(TICKETS_QUERY, { variables: { companyIDs: companyIDs } });


    const reportData : Array<ReportItem> = useMemo(() => {
        return companies.map((company) => {
            const companyID = company.id;
            const companyName = company.name;
            const coreCompanyId = company.coreCompanyId;
            const tickets = ticketsData?.findAutotaskTicketsPaginated?.data.filter((t ) => t.companyID === company.id );
            
            return {
                id: companyID,
                companyID: companyID,
                companyName: companyName,
                coreCompanyId: coreCompanyId,
                tickets: ticketTypes.map((type) => ({
                    [type]: tickets?.find((ticket) => ticketFilters[type](ticket)) 
                })).reduce((acc, curr) => ({ ...acc, ...curr }), {})
            }
        });
    }, [companies, ticketsData?.findAutotaskTicketsPaginated?.data]);
    return <div>Onboarding
        {reportData.map((company) => (
            <Accordion key={company.id} sx={{maxWidth: 'calc(100vw - 120px)'}}>
                <AccordionSummary>
                    <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, justifyContent: 'space-between' , width: '100%'}}>
                        <Box sx={{fontWeight: 'bold' }}>{company.companyName}</Box> 
                        <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, marginLeft: 'auto' }}>
                            {ticketTypes.map((type) => <OnboardingItemIcon key={type} ticket={company.tickets[type]} />)}
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                { ticketTypes.map((type) => <OnboardingItemDetails key={type} ticket={company.tickets[type]} ticketType={type} />)}
                </AccordionDetails>
            </Accordion>
        ))}
    </div>
}