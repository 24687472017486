import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindCoreFilePaginatedQueryVariables = Types.Exact<{
  coredeviceSoftwareId?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  coreCompanyId?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type FindCoreFilePaginatedQuery = { __typename?: 'Query', findCoreFilePaginated: { __typename?: 'PaginatedCoreFile', totalCount: number, page: number, limit: number, hash?: string | null, data?: Array<{ __typename?: 'CoreFile', createdDateTime: any, createdByCoreUserId?: string | null, updatedDateTime: any, updatedByCoreUserId?: string | null, id?: number | null, name?: string | null, contentType: string, size: number, key: string, verified: boolean, metadata?: any | null, coredeviceSoftwareId?: number | null, coreCompanyId?: number | null, clientDeletable?: boolean | null }> | null } };


export const FindCoreFilePaginatedDocument = gql`
    query FindCoreFilePaginated($coredeviceSoftwareId: Float, $coreCompanyId: Float, $page: Int, $limit: Int) {
  findCoreFilePaginated(
    verified: {eq: true}
    childpart: {eq: false}
    coredeviceSoftwareId: {eq: $coredeviceSoftwareId}
    coreCompanyId: {eq: $coreCompanyId}
    page: $page
    limit: $limit
  ) {
    data {
      createdDateTime
      createdByCoreUserId
      updatedDateTime
      updatedByCoreUserId
      id
      name
      contentType
      size
      key
      verified
      metadata
      coredeviceSoftwareId
      coreCompanyId
      clientDeletable
    }
    totalCount
    page
    limit
    hash
  }
}
    `;

/**
 * __useFindCoreFilePaginatedQuery__
 *
 * To run a query within a React component, call `useFindCoreFilePaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCoreFilePaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCoreFilePaginatedQuery({
 *   variables: {
 *      coredeviceSoftwareId: // value for 'coredeviceSoftwareId'
 *      coreCompanyId: // value for 'coreCompanyId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFindCoreFilePaginatedQuery(baseOptions?: Apollo.QueryHookOptions<FindCoreFilePaginatedQuery, FindCoreFilePaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindCoreFilePaginatedQuery, FindCoreFilePaginatedQueryVariables>(FindCoreFilePaginatedDocument, options);
      }
export function useFindCoreFilePaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindCoreFilePaginatedQuery, FindCoreFilePaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindCoreFilePaginatedQuery, FindCoreFilePaginatedQueryVariables>(FindCoreFilePaginatedDocument, options);
        }
export function useFindCoreFilePaginatedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindCoreFilePaginatedQuery, FindCoreFilePaginatedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindCoreFilePaginatedQuery, FindCoreFilePaginatedQueryVariables>(FindCoreFilePaginatedDocument, options);
        }
export type FindCoreFilePaginatedQueryHookResult = ReturnType<typeof useFindCoreFilePaginatedQuery>;
export type FindCoreFilePaginatedLazyQueryHookResult = ReturnType<typeof useFindCoreFilePaginatedLazyQuery>;
export type FindCoreFilePaginatedSuspenseQueryHookResult = ReturnType<typeof useFindCoreFilePaginatedSuspenseQuery>;
export type FindCoreFilePaginatedQueryResult = Apollo.QueryResult<FindCoreFilePaginatedQuery, FindCoreFilePaginatedQueryVariables>;