import { FC, ReactNode, useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import { Icon } from "@iconify/react/dist/iconify.js";
import { FIRST_UP_ICON, PHONE_ICON } from "../../../constances/icons";
import { ExtendedUserItem, ScheduleInstance } from '../types';

export const BadgeWithBorder: FC<{ 
  children: ReactNode, 
  color?: string, 
  user: ExtendedUserItem 
}> = ({ children, color, user }) => {
  const isFirstUp = useMemo(() => {
    return user?.activeScheduleInstances?.some((si: ScheduleInstance) => 
      String(si?.SchedulePlan?.name).toLocaleLowerCase()?.includes('first up')
    );
  }, [JSON.stringify(user?.activeScheduleInstances)]);
  
  const theme = useTheme();
  
  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{
        border: '1px solid #ccc',
        borderRadius: '50%',
        padding: '2px',
        boxSizing: 'border-box',
        backgroundColor: color,
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        '& > *': { // Target direct children
          width: '100%',
          border: '1px solid #fff',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      }}>
        {children}
      </Box>
      {isFirstUp && (
        <Box sx={{
          position: 'absolute',
          width: '16px',
          height: '16px',
          padding: '2px',
          bottom: -4,
          right: -4,
          backgroundColor: theme.palette.success.main,
          color: theme.palette.success.contrastText,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Icon fontSize="14px" icon={FIRST_UP_ICON} />
        </Box>
      )}
      {user?.isOnCall && (
        <Box sx={{
          position: 'absolute',
          width: '16px',
          height: '16px',
          padding: '2px',
          bottom: -4,
          left: -4,
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.warning.contrastText,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Icon fontSize="14px" icon={PHONE_ICON} />
        </Box>
      )}
    </Box>
  );
}; 