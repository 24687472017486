import { useEffect } from "react";
import { useSocket } from "../../providers/SocketIo";
import {  useActiveAssignedMessageChannelsQuery } from "./gql/ActiveAssignedMessageChannels.generated";
import { useUser } from "../../react-submodules/providers/User";
import { ActiveMessageChannel } from "./types";
import { PrepareForTransferMessage } from "./PrepareForTransferMessage";
import { CoreMessageChannelType } from "../../generated/graphql";
import { TransferTicket } from "./TransferTicket";
import useSound from "use-sound";
import { useActiveSessionsIdOnlyQuery } from "./gql/ActiveSessionsIdOnly.generated";


export const SingleActiveMessageChannel = ({ messageChannel , refresh  }: { messageChannel: ActiveMessageChannel , refresh : () => any}) => {
    switch( messageChannel.type ){        
        case CoreMessageChannelType.TicketTransfer:
            return <TransferTicket messageChannel={messageChannel} refresh={refresh} />;
        case CoreMessageChannelType.PrepareForTransfer:
            return <PrepareForTransferMessage messageChannel={messageChannel} refresh={refresh} />;
    }
    return <></>;
}


export const Messaging = () => {
    const user = useUser();
    
    const socket = useSocket();
    const { data, loading, error , refetch } = useActiveAssignedMessageChannelsQuery({
        variables: {
            assignedToUserId: user.tokenParsed?.sub || ""
        },
        skip: !user.tokenParsed?.sub,
        fetchPolicy: 'network-only',

    });

    const { data : activeSessions } = useActiveSessionsIdOnlyQuery({
        variables: {
            assignedToUserId: user.tokenParsed?.sub || ""
        },
        pollInterval: 2000,
        skip: !user.tokenParsed?.sub,
        fetchPolicy: 'network-only',

    });
    useEffect( () => {
        console.log( "ID Changed , Refetching");
        refetch();
    } , [
        (activeSessions?.findCoreMessageChannelPaginated?.data || []).map( d => d.id).sort().join(",")
    ])
    useEffect( () => {
        if(!user.tokenParsed?.sub){
            console.log(`We will be skiping because ${user.tokenParsed?.sub} is falsy `)
        }
    } , [user.tokenParsed?.sub])
    useEffect(() => {
        console.log( "Socket is reconnecting");
        if( !socket ){
            return;
        }  
      function onChatResponse(value: any) {
        refetch();
      }
      socket.on('core.message.channel.created', onChatResponse);
      socket.on('core.message.channel.updated', onChatResponse);
      return () => {
        socket.off('core.message.channel.created', onChatResponse);
        socket.off('core.message.channel.updated', onChatResponse);
      };
    }, [socket]);
    return <>{data?.findCoreMessageChannelPaginated.data?.map(messageChannel => <SingleActiveMessageChannel 
        refresh={refetch}
        key={messageChannel.id} 
        messageChannel={messageChannel} 
    />)}</>;
}