import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindTicketTimelinePaginatedQueryVariables = Types.Exact<{
  ticketId: Types.Scalars['Float']['input'];
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  type?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
}>;


export type FindTicketTimelinePaginatedQuery = { __typename?: 'Query', findTicketTimelinePaginated: { __typename?: 'PaginatedTicketTimeline', totalCount: number, page: number, limit: number, data?: Array<{ __typename?: 'TicketTimeline', id?: number | null, type?: string | null, date?: any | null, PhoneCall?: { __typename?: 'PhonesCalls', startTime?: any | null, id?: number | null, PhoneCallLegs?: Array<{ __typename?: 'PhonesCallLegs', id?: number | null, transcription?: string | null }> | null } | null, AzureEmail?: { __typename?: 'AzureEmail', id?: string | null, subject: string, bodyMarkdown?: string | null, bodyHtml?: string | null, createdDateTime: any, AzureEmailParticipants?: Array<{ __typename?: 'AzureEmailParticipatnt', id?: number | null, emailId?: string | null, userRole?: string | null, emailAddress?: string | null, name?: string | null }> | null, AzureEmailAttachments?: Array<{ __typename?: 'AzureEmailAttachment', id?: string | null, name?: string | null, contentType?: string | null, contentId?: string | null, emailId?: string | null, contentLocation?: string | null, isInline?: boolean | null }> | null } | null, FormInstance?: { __typename?: 'FormsInstance', id?: number | null, autotaskTicketId?: number | null, todoId?: number | null, complete: boolean, updated_at?: any | null, created_at?: any | null, recipeInstanceId?: number | null, context?: any | null, values?: any | null, Form?: { __typename?: 'FormsForm', description?: string | null, identifier: string, name: string, id?: number | null, FormFields?: Array<{ __typename?: 'FormsFormField', id?: number | null, formId?: number | null, type: string, name: string, label: string, config?: any | null }> | null } | null } | null, CoreTimeclock?: { __typename?: 'CoreTimeclock', message?: string | null, id?: number | null, start?: any | null, end?: any | null, user?: { __typename?: 'CoreUsers', firstName?: string | null, lastName?: string | null, email?: string | null } | null } | null }> | null } };


export const FindTicketTimelinePaginatedDocument = gql`
    query FindTicketTimelinePaginated($ticketId: Float!, $search: String, $page: Int, $type: [String!]) {
  findTicketTimelinePaginated(
    atTicketId: {eq: $ticketId}
    limit: 15
    page: $page
    search: $search
    order: {date: DESC}
    type: {in: $type}
  ) {
    totalCount
    page
    limit
    data {
      id
      type
      date
      PhoneCall {
        startTime
        id
        PhoneCallLegs {
          id
          transcription
        }
      }
      AzureEmail {
        id
        subject
        bodyMarkdown
        bodyHtml
        createdDateTime
        AzureEmailParticipants {
          id
          emailId
          userRole
          emailAddress
          name
        }
        AzureEmailAttachments {
          id
          name
          contentType
          contentId
          emailId
          contentLocation
          isInline
        }
      }
      FormInstance {
        id
        autotaskTicketId
        todoId
        complete
        updated_at
        created_at
        recipeInstanceId
        Form {
          FormFields {
            id
            formId
            type
            name
            label
            config
          }
          description
          identifier
          name
          id
        }
        context
        values
      }
      CoreTimeclock {
        message
        id
        user {
          firstName
          lastName
          email
        }
        start
        end
      }
    }
  }
}
    `;

/**
 * __useFindTicketTimelinePaginatedQuery__
 *
 * To run a query within a React component, call `useFindTicketTimelinePaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTicketTimelinePaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTicketTimelinePaginatedQuery({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useFindTicketTimelinePaginatedQuery(baseOptions: Apollo.QueryHookOptions<FindTicketTimelinePaginatedQuery, FindTicketTimelinePaginatedQueryVariables> & ({ variables: FindTicketTimelinePaginatedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindTicketTimelinePaginatedQuery, FindTicketTimelinePaginatedQueryVariables>(FindTicketTimelinePaginatedDocument, options);
      }
export function useFindTicketTimelinePaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindTicketTimelinePaginatedQuery, FindTicketTimelinePaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindTicketTimelinePaginatedQuery, FindTicketTimelinePaginatedQueryVariables>(FindTicketTimelinePaginatedDocument, options);
        }
export function useFindTicketTimelinePaginatedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindTicketTimelinePaginatedQuery, FindTicketTimelinePaginatedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindTicketTimelinePaginatedQuery, FindTicketTimelinePaginatedQueryVariables>(FindTicketTimelinePaginatedDocument, options);
        }
export type FindTicketTimelinePaginatedQueryHookResult = ReturnType<typeof useFindTicketTimelinePaginatedQuery>;
export type FindTicketTimelinePaginatedLazyQueryHookResult = ReturnType<typeof useFindTicketTimelinePaginatedLazyQuery>;
export type FindTicketTimelinePaginatedSuspenseQueryHookResult = ReturnType<typeof useFindTicketTimelinePaginatedSuspenseQuery>;
export type FindTicketTimelinePaginatedQueryResult = Apollo.QueryResult<FindTicketTimelinePaginatedQuery, FindTicketTimelinePaginatedQueryVariables>;