import { Box, Typography } from "@mui/material";
import { FC, useMemo } from "react"

import { numberToTime } from "../../utils/time.utils";

type TRelativeKeys =  'past' | 'today' | 'future';

const rt2color = ( rt : TRelativeKeys ) => {
    switch( rt ){
        case 'past':
            return 'error';
        default:
        case 'today':
            return '#993d00';
        case 'future':
            return 'success';
    }
}


export const NextActionDate : FC<{
    edit?: boolean;
    nextActionDate?: string | null;
    nextActionTime?: number | null;
    nextActionType?: string | null;
    onChange?: (date: Date ) => {};
}> = ({ edit, nextActionDate, onChange , nextActionTime , nextActionType }) => {
    const displayDate = useMemo(() => {
        if( !nextActionDate ) return "";
        // return nextActionDate.split("T")[0];
        return new Date( `${nextActionDate.split("T")[0]}T12:00:00Z` ).toLocaleDateString();
    } , [nextActionDate]);
    const relative : TRelativeKeys = useMemo( () => {
        if( !nextActionDate ) return 'past';
        const today = new Date().toISOString().split('T')[0];
        if( new Date( nextActionDate ).toISOString().split('T')[0] === today ){
            return 'today';
        }
        return new Date( nextActionDate ) < new Date(today) ? 'past' : 'future';
    }, [nextActionDate]);
    return <Box sx={{display: 'inline'}}>NAD: 
        <Typography variant="body2" sx={{fontWeight: 'bold' , display: 'inline'}}  color={rt2color(relative)} >{displayDate|| "Unset"}</Typography>
        { nextActionTime  && <Typography variant="body2" sx={{fontWeight: 'bold' , display: 'inline'}}  color={rt2color(relative)} >&nbsp;{numberToTime(nextActionTime , '12hr')|| "Unset"}</Typography> }
        { nextActionType && <Typography variant="body2" sx={{fontWeight: 'bold' , display: 'inline'}}  color={rt2color(relative)} >&nbsp;{nextActionType || "Unset"}</Typography> }
    </Box>
}