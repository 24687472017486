import { Icon } from "@iconify/react";
import { Button, Container, MenuItem, TextField, Typography, useTheme } from "@mui/material"
import { TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

import { FC } from "react";
import { PaginatedGraphqlQueryTable } from "../../react-submodules/components/PaginatedGraphqlQueryTable/PaginatedGraphqlQueryTable";
import { CHECK_ICON, UNCHECK_ICON } from "../../react-submodules/constances/icons";
import { DaysOfTheMonthEdit } from "./components/DaysOfTheMonthEdit";
import { DaysOfTheWeekEdit } from "./components/DaysOfTheWeekEdit";
import { MonthsOfTheYearEdit } from "./components/MonthsOfTheYear";

/*const ScoreView : FC<{ cell : any , row : any , refresh?: () => any } & any > = ({ row}) => {
    return <div>{row?.score?.description}</div>
}

const TimeView : FC<{ cell : any , row : any , refresh?: () => any } & any > = ({ row}) => {
    return <div>{new Date(row?.createdDateTime).toLocaleString()}</div>
}
 */

const prependZero = (value : number) => {
    return value < 10 ? `0${value}` : value;
}
const makeTimeView = (fieldPre : string) => {
    return ({ cell , row , refresh } : any ) => {
        const ampm = row?.[`${fieldPre}_hour`] >= 12 ? 'PM' : 'AM';
        const hour = row?.[`${fieldPre}_hour`] % 12 || 12;
        return <Button onClick={() => { console.log(row) }}>{hour}:{prependZero(row?.[`${fieldPre}_minute`])} {ampm}</Button>
    }
}
const makeTimeEdit = (fieldPre : string) => {
    return ({ cell , row , refresh , onChangeField } : any ) => {
        
        const rawValue = dayjs( `2022-04-17T${prependZero(row?.[`${fieldPre}_hour`])}:${prependZero(row?.[`${fieldPre}_minute`])}`)
        const onChange = (newValue : any) => {
            //const newTime = dayjs(newValue)
            
            // console.log(setFormDataField , newValue.hour() , newValue.minute() )
            onChangeField(`${fieldPre}_hour`, newValue.hour())
            onChangeField(`${fieldPre}_minute`, newValue.minute())
        }
        return <TimePicker
        label={`${fieldPre} Time`}
        value={rawValue}
        onChange={(newValue) => onChange(newValue)}
      />
    }
}
const StartTimeView = makeTimeView('start')
const EndTimeView = makeTimeView('end')
const StartTimeEdit = makeTimeEdit('start')
const EndTimeEdit = makeTimeEdit('end')

const TimeZoneEdit : FC<{ row : Record<string, any> , value : any , onChangeField : (field: string, value: any) => void }> = ({ row , value , onChangeField }) => {
    return <TextField fullWidth select label="Time Zone" value={value} onChange={(e) => onChangeField('timezone', e.target.value)} >
        <MenuItem value="America/New_York">America/New_York</MenuItem>
        <MenuItem value="America/Chicago">America/Chicago</MenuItem>
        <MenuItem value="America/Denver">America/Denver</MenuItem>
        <MenuItem value="America/Los_Angeles">America/Los_Angeles</MenuItem>
    </TextField>
}

const AvailableView : FC<{ cell : any , row : any , refresh?: () => any } & any > = ({ row}) => {
    const theme = useTheme();
    const icon = row?.available ? CHECK_ICON : UNCHECK_ICON;
    const color = row?.available ? theme.palette.success.main : theme.palette.grey[500];
    return <Typography sx={{ color }} ><Icon icon={icon}  /></Typography>
}
export const Plan = () => {
    
        return  <Container>
        <PaginatedGraphqlQueryTable baseName="SchedulePlan"  columns={[
            { field: 'id', type: 'Int', createRequired: false , primaryKey: true , autoIncrement: true , formGridWidth: 2 }, 
            { field: 'name', type: 'String', createRequired: true, formGridWidth: 10},
            { field: 'description',  visible: false, type: 'String', createRequired: true, formGridWidth: 12},
            /*{ field : 'desiredUserCount', type: 'Int', createRequired: true, formGridWidth: 3},*/
            { field: 'start_hour', label: 'Start Time', type: 'Int', createRequired: true, CustomView: StartTimeView, CustomEdit: StartTimeEdit, formGridWidth: 3},
            { field: 'start_minute', 
                type: 'Int', 
                createRequired: false , 
                visible: false , 
                select: true , 
                editable: false , 
                updatePropField: true,
                formGridWidth: 1 
            },
            { field: 'end_hour', label: 'End Time', type: 'Int', createRequired: true , CustomView: EndTimeView, CustomEdit: EndTimeEdit , formGridWidth: 3},
            { field: 'end_minute', 
                type: 'Int', 
                createRequired: false , 
                visible: false , 
                select: true , 
                editable: false,
                updatePropField: true,
                formGridWidth: 1 
            },
            { field: 'timezone', initialValue: 'America/Chicago', type: 'String', createRequired: true, CustomEdit: TimeZoneEdit, formGridWidth: 3},
            { CustomView: AvailableView, field: 'available' , initialValue: false, type: 'Boolean', createRequired: true, formGridWidth: 3},
            { template: 'user'},
            { template: 'group'},
            { tab: 'Schedule Days', field: 'startTimeDay_daysOfWeek' , 
                type: 'CUSTOM', 
                initialValue: ['mon', 'tue', 'wed', 'thu', 'fri'],
                customType: '[String!]',
                CustomEdit: DaysOfTheWeekEdit,
                formGridWidth: 12,
                visible: false,
                select: true,
                updatePropField: true,
            },
            { tab: 'Schedule Days', field: 'startTimeDay_daysOfMonth' , 
                type: 'CUSTOM', 
                initialValue: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
                customType: '[Int!]',
                CustomEdit: DaysOfTheMonthEdit,
                formGridWidth: 6,
                visible: false,
                select: true,
                updatePropField: true,
            },
            { tab: 'Schedule Days', field: 'startTimeDay_monthsOfTheYear' , 
                type: 'CUSTOM', 
                customType: '[String!]',
                initialValue: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
                CustomEdit: MonthsOfTheYearEdit,
                formGridWidth: 6,
                visible: false,
                select: true,
                updatePropField: true,
            },
            { select: true, updatePropField: true, tab: 'Schedule Blocks', field: 'splitableIntoBlocks', label: 'Splitable Into Blocks', initialValue: false, type: 'Boolean', formGridWidth: 3 , visible: false },
            { select: true, updatePropField: true, tab: 'Schedule Blocks', field: 'blockSizeInMinutes', label: 'Minimum Block Size (Minutes)', initialValue: 15, type: 'Int', formGridWidth: 3 , visible: false },
            { select: true, updatePropField: true, tab: 'Schedule Blocks', field: 'optimalBlockSizeInMinutes', label: 'Optimal Block Size (Minutes)', initialValue: 120, type: 'Int', formGridWidth: 3 , visible: false },
            ]} />
    </Container>
    
}