import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch, TextField, setRef } from "@mui/material";
import { FC, useEffect, useState } from "react";

import { StyledTable } from "../../react-submodules/components/Table"
import { useApi } from "../../react-submodules/providers/Api/Api";
import { useNavigate } from "react-router-dom";

type Recipe = {
    id: string;
    title: string;
    active: boolean;
}

export const ActiveToggle : FC<{ cell : boolean , row : Recipe , refresh?: () => any }> = ({ cell, row, refresh }) => {
    const [ updating , setUpdating ] = useState(false);
    const api = useApi();
    useEffect( () => {
        setUpdating(false);
    } , [cell]);
    return <Switch color="primary" disabled={updating} checked={cell} onChange={async (e) => { 
        setUpdating(true);
        await api.patch( `/recipe/${row.id}` , {
            active: !cell
        } );
        refresh && refresh();

    }} />;
}
export const RecipeSearch = () => {
    const nav = useNavigate();
    const api = useApi();
    const [ refreshTick , setRefresh ] = useState(1);
    const [ recipes , setRecipes ] = useState<Recipe[]>([]);
    const [ title , setTitle ] = useState('');
    const [ showAddRecipe , setShowAddRecipe ] = useState(false);
    const refresh = () => {
        setRefresh( r =>  r * -1 );
    }
    useEffect( () => {
        if( api.ready){
            api.get('/recipe/recipes').then( ( { data}) => {
                setRecipes(data);
            });
        }
    } , [api.ready , refreshTick ]);
    const addRecipe = () => {
        api.post('/recipe/create' , { title }).then( () => {
            setTitle('');
            setShowAddRecipe(false);
            refresh();
        });
        
    }
    return <>
    <Dialog open={showAddRecipe}>
        <DialogTitle>Add Recipe</DialogTitle>
        <DialogContent>
            <TextField label="Title" fullWidth value={title} onChange={(e)=> {setTitle( e.target.value )}} />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => { addRecipe()} }>Add Recipe</Button>
        </DialogActions>
    </Dialog>
    <Button onClick={()=>{
        setShowAddRecipe(true);
    }} sx={{margin: 1}} variant="contained" color="secondary">Add Recipe</Button>
        <StyledTable<Recipe> 
            refresh={refresh}
            columns={[
                {
                    field: 'title',
                    flex: 1,
                    sortable: true,
                    headerName: 'Name',
                    Element: ( { cell , row } ) => {
                        return <Button onClick={() => {
                            nav(`/recipe/${row.id}`);
                        }}>{cell}</Button>
                    }
                    
                },
                {
                    field: 'active',
                    flex: 0,
                    sortable: true,
                    headerName: 'active',
                    Element: ( { cell , row , refresh } ) => {
                        return <ActiveToggle cell={cell} row={row} refresh={refresh} />
                    }
                },
            ]}
            rows={recipes}
        />
    </>
}