import { FC, Fragment, useState } from "react"
import { CoreContact } from "../../../../coretypes/contacts/contacts.dto"
import { Dialog, DialogTitle, DialogContent, DialogContentText, Grid, Typography, ButtonGroup, Button, Box, Badge, LinearProgress, DialogActions } from "@mui/material"
import { ContractsInstances } from "../../../../coretypes/contracts/contracts.dto"
import { InlineIcon } from "@iconify/react"
import { useApi } from "../../../../react-submodules/providers/Api"

export const BulkActions: FC<{
    contacts: CoreContact[]
    onClose: () => any
    contractInstances: ContractsInstances[]
}> = ({ contacts, onClose, contractInstances }) => {
    const api = useApi();
    const [progress, setProgress] = useState(0);
    const [updating, setUpdating] = useState(false);
    const [contractChanges, setContractChanges] = useState<Array<{
        contractInstanceId: number;
        set: boolean;
    }>>([]);

    const setContract = (contractInstanceId: number, set: boolean | null) => {
        if (set === null) {
            setContractChanges(cc => cc.filter(c => c.contractInstanceId !== contractInstanceId));
        } else {
            setContractChanges(cc => [...cc.filter(c => c.contractInstanceId !== contractInstanceId), { contractInstanceId, set }]);
        }
    }

    const isContractState = (contractInstanceId: number, set: boolean | null) => {
        if (set === null) {
            return contractChanges.find(c => c.contractInstanceId === contractInstanceId) === undefined;
        }
        return contractChanges.find(c => c.contractInstanceId === contractInstanceId)?.set === set;
    }

    const applyActions = async () => {
        setUpdating(true);
        const actions: Array<{ url: string, contactId: number }> = [];
        for (const change of contractChanges) {
            for (const contact of contacts) {
                actions.push({ url: `/contracts/instances/${change.contractInstanceId}/${change.set ? 'assign' : 'unassign'}`, contactId: contact.id });
            }
        }
        if (actions.length === 0) {
            setUpdating(false);
            return;
        }
        setProgress(0);
        let i = 0;
        for await (const action of actions) {
            i++;
            await api.post(action.url, { coreContactId: action.contactId });
            setProgress(i / actions.length);
        }
        setUpdating(false);
        onClose();
    }

    return <Dialog open={contacts.length !== 0} onClose={onClose}>
        <DialogTitle>Bulk Actions</DialogTitle>
        <DialogContent>
            <DialogContentText>
                <Grid container spacing={2} sx={{marginTop: 2}}>
                    <Grid item sm={12}>
                        <Badge badgeContent={contacts.length} color="secondary" sx={{paddingRight: 1}}>
                            <Box sx={{ fontWeight: 'bold' }}>Contacts</Box>
                        </Badge>
                    </Grid>
                    {contractInstances.map(c => {
                        return <Fragment key={c.id}>
                            <Grid item sm={6}>
                                <Typography variant="body1">{c.contract_name}</Typography>
                            </Grid>
                            <Grid item sm={6}>
                                <ButtonGroup>
                                    <Button variant="contained" color={isContractState(c.id, false) ? 'error' : 'inherit'} onClick={() => setContract(c.id, false)}><InlineIcon icon="mdi:close-circle-outline"></InlineIcon></Button>
                                    <Button variant="contained" color={isContractState(c.id, null) ? 'info' : 'inherit'} onClick={() => setContract(c.id, null)}><InlineIcon icon="mdi:checkbox-blank-circle"></InlineIcon></Button>
                                    <Button variant="contained" color={isContractState(c.id, true) ? 'success' : 'inherit'} onClick={() => setContract(c.id, true)}><InlineIcon icon="mdi:check-circle-outline"></InlineIcon></Button>
                                </ButtonGroup>
                            </Grid>
                        </Fragment>
                    })}
                </Grid>
                {updating && <Box sx={{marginTop: 2}}>
                    <LinearProgress variant="determinate" value={progress * 100} />
                </Box>}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={applyActions}>Apply</Button>
        </DialogActions>
    </Dialog>
}
