import { gql } from "@apollo/client";
import { Container } from "@mui/material";
import { PaginatedGraphqlQueryTable } from "../../../react-submodules/components/PaginatedGraphqlQueryTable/PaginatedGraphqlQueryTable";



export const CoreTagsLinksConfig = () => {
    return  <Container>
        <PaginatedGraphqlQueryTable baseName="CoreTagLink"  columns={[
            { field: 'name', type: 'String', createRequired: true , primaryKey: true}, 
            { field: 'link', type: 'String', createRequired: true}
            ]} />
    </Container>
}