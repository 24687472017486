import { FC, useEffect, useMemo, useState } from "react";
import { useApi } from "../../react-submodules/providers/Api/Api";
import { LineChart } from "@mui/x-charts";
import { Paper } from "@mui/material";

export const TotalTicketGraph : FC<{ width?: number, height?: number }> = ({ width = 1000, height = 600 }) => {
    const api = useApi();
    const [data, setData] = useState<Array<{ date : string , count : number , createdTickets : number , closedTickets : number }>>([]);

    useEffect(() => {
        api.get('/reports/open/tickets')
            .then((response) => {
                setData(response.data.sort( ( a : any , b : any ) => {
                    return a.date.localeCompare(b.date);
                }));
            })
    }, []);

    const { labels , series , created , closed } = useMemo(() => {
        return {
            labels : data.map((d) => d.date),
            series : data.map((d) => d.count),
            created : data.map((d) => d.createdTickets),
            closed : data.map((d) => d.closedTickets),
        }
    }, [data]);


    return (
        <Paper sx={{marginTop: 2}}>
            Total Ticket Graph
            <LineChart
      width={width}
      height={height}
      series={[
        { data: series, label: 'Open Tickets' },
        { data: created, label: 'Created Tickets' },
        { data: closed, label: 'Closed Tickets' },
      ]}
      xAxis={[{ scaleType: 'point', data: labels }]}
    />
        </Paper>
    )
}

