import { Autocomplete, Box, InputAdornment, MenuItem, TextField } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";

import { AnyBadge } from "../AnyBadge";
import { AutotaskResource } from "../../types/ticketqueue";
import { BadgeAndName } from "./BadgeAndName";
import { useTicketApi } from "../../providers/TicketApiProvider";

export const Resource: FC<{ edit?: boolean; resourceID?: number , onChange?: ( resource : AutotaskResource | null ) => any}> = (
    { resourceID , edit , onChange }
) => {
    const { resources } = useTicketApi();
    const mappedResorces = useMemo( () => {
        return resources.filter( r => {
            return r.isActive && r.licenseType === 1;
        }).map( r => {
            return {
                ...r,
                label: `${r.firstName} ${r.lastName}`,
                value: r.id
            }
        } ).sort( ( a , b ) => {
            return a.label.localeCompare( b.label );
        });
    } , [JSON.stringify(resources)]);
    const [ reload , setReload ] = useState(false);
    const selectedResource = useMemo(() => {
        return resources.find( ( c ) => {
            return c.id === resourceID;
        });
    }, [JSON.stringify(resources) ,resourceID ]);
    useEffect( () => {
        setReload( true);
    } , [JSON.stringify( resources ) ])
    useEffect( () => {
        setTimeout( () => {
            setReload( false );
        } , 1)
    } , [reload])
    if( !edit){
        return <>{selectedResource && <BadgeAndName phone={selectedResource.officePhone} displayName={`${selectedResource.firstName} ${selectedResource.lastName}`} colorkey={`${selectedResource.email}`||undefined} />}</>
    }

    if( reload ){
        return <></>
    }
    return <TextField label="Tech" size="small" fullWidth select value={selectedResource?.id} onChange={(e) => {
        const resource = resources.find( r => r.id === parseInt(e.target.value));
        onChange && onChange( resource || null );
    }} >
        
        <MenuItem value={undefined}>Unassigned</MenuItem>
        {mappedResorces.map( ( resource ) => {
            return <MenuItem key={resource.id} value={resource.id}><BadgeAndName displayName={`${resource.firstName} ${resource.lastName}`} colorkey={`${resource.email}`||undefined} /></MenuItem>
        })}
    </TextField>
    // return <>{JSON.stringify(selectedResource)}</>
    /*return <Autocomplete
    value={selectedResource||null}
    options={mappedResorces}
    size="small"
    fullWidth
    onChange={(event , resource) => {
        onChange && onChange( resource );
    }}
    
    filterOptions={(options : AutotaskResource[] , state : any ) => {
        return options.filter( ( option ) => {
            const keys = state.inputValue.toLowerCase().split(" ");
            return keys.reduce( ( pv : boolean , cv : string ) => {
                const name = `${option.firstName} ${option.lastName}`.toLowerCase();
                return pv && name.toLowerCase().includes( cv ) 
            } , true );
        })
    
    }}
    renderInput={({ InputProps , ...params  }) => <TextField 
          
        InputProps={{...InputProps , startAdornment: <InputAdornment position="start">{ selectedResource && <AnyBadge name={`${selectedResource.firstName} ${selectedResource.lastName}`} colorkey={selectedResource.id||undefined}  />}</InputAdornment>}}  
        {...params} 
        label="Resource" 
    />}
  /> */
}