import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAutotaskTicketsMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  status?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  coreCompanyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  coreContactId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  title?: Types.InputMaybe<Types.Scalars['String']['input']>;
  description?: Types.InputMaybe<Types.Scalars['String']['input']>;
  priority?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  billable?: Types.InputMaybe<Types.Scalars['String']['input']>;
  assignedUserId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  nextActionTime?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  nextActionType?: Types.InputMaybe<Types.Scalars['String']['input']>;
  nextActionDate?: Types.InputMaybe<Types.Scalars['String']['input']>;
  nextActionNote?: Types.InputMaybe<Types.Scalars['String']['input']>;
  queueID?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  contactlessStart?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  configurationItemID?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type UpdateAutotaskTicketsMutation = { __typename?: 'Mutation', updateAutotaskTickets: { __typename?: 'AutotaskTickets', id?: number | null, status: number, coreCompanyId?: number | null, coreContactId?: number | null, title: string, description?: string | null, priority?: number | null, billable?: string | null, assignedUserId?: string | null, nextActionNote?: string | null, nextActionDate?: string | null, nextActionTime?: number | null, nextActionType?: string | null, queueID?: number | null, contactlessStart?: boolean | null, configurationItemID?: number | null } };


export const UpdateAutotaskTicketsDocument = gql`
    mutation UpdateAutotaskTickets($id: Int!, $status: Int, $coreCompanyId: Int, $coreContactId: Int, $title: String, $description: String, $priority: Int, $billable: String, $assignedUserId: String, $nextActionTime: Float, $nextActionType: String, $nextActionDate: String, $nextActionNote: String, $queueID: Int, $contactlessStart: Boolean, $configurationItemID: Int) {
  updateAutotaskTickets(
    id: $id
    status: $status
    coreCompanyId: $coreCompanyId
    coreContactId: $coreContactId
    title: $title
    description: $description
    priority: $priority
    billable: $billable
    assignedUserId: $assignedUserId
    nextActionTime: $nextActionTime
    nextActionType: $nextActionType
    nextActionDate: $nextActionDate
    nextActionNote: $nextActionNote
    queueID: $queueID
    contactlessStart: $contactlessStart
    configurationItemID: $configurationItemID
  ) {
    id
    status
    coreCompanyId
    coreContactId
    title
    description
    priority
    billable
    assignedUserId
    nextActionNote
    nextActionDate
    nextActionTime
    nextActionType
    queueID
    contactlessStart
    configurationItemID
  }
}
    `;
export type UpdateAutotaskTicketsMutationFn = Apollo.MutationFunction<UpdateAutotaskTicketsMutation, UpdateAutotaskTicketsMutationVariables>;

/**
 * __useUpdateAutotaskTicketsMutation__
 *
 * To run a mutation, you first call `useUpdateAutotaskTicketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutotaskTicketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutotaskTicketsMutation, { data, loading, error }] = useUpdateAutotaskTicketsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      coreCompanyId: // value for 'coreCompanyId'
 *      coreContactId: // value for 'coreContactId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      priority: // value for 'priority'
 *      billable: // value for 'billable'
 *      assignedUserId: // value for 'assignedUserId'
 *      nextActionTime: // value for 'nextActionTime'
 *      nextActionType: // value for 'nextActionType'
 *      nextActionDate: // value for 'nextActionDate'
 *      nextActionNote: // value for 'nextActionNote'
 *      queueID: // value for 'queueID'
 *      contactlessStart: // value for 'contactlessStart'
 *      configurationItemID: // value for 'configurationItemID'
 *   },
 * });
 */
export function useUpdateAutotaskTicketsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAutotaskTicketsMutation, UpdateAutotaskTicketsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAutotaskTicketsMutation, UpdateAutotaskTicketsMutationVariables>(UpdateAutotaskTicketsDocument, options);
      }
export type UpdateAutotaskTicketsMutationHookResult = ReturnType<typeof useUpdateAutotaskTicketsMutation>;
export type UpdateAutotaskTicketsMutationResult = Apollo.MutationResult<UpdateAutotaskTicketsMutation>;
export type UpdateAutotaskTicketsMutationOptions = Apollo.BaseMutationOptions<UpdateAutotaskTicketsMutation, UpdateAutotaskTicketsMutationVariables>;