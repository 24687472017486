import { Badge, Button, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material"
import { useMemo, useState } from "react";

import { QueueTicket } from "../../../../react-submodules/types/ticketqueue";
import { QueuedUsersButton } from "../QueuedUsersButton";
import { StyledTable } from "../../../../react-submodules/components/Table";
import { UserCard } from "./UserCard";
import { UserReportData } from "../OldManagerView";
import { useApi } from "../../../../react-submodules/providers/Api/Api";
import { useMounted } from "../../../../react-submodules/hooks/useMounted";

export type UserReportDataMinimum = Pick<UserReportData , 'user' > & {
    queue : Pick<QueueTicket , 'id' | 'fromRole' | 'ticketNumber' | 'title' |  'importanceRank' | 'contactlessStart' | 'status'>[]
    ticketIds : Array<number | null >;
    
}

export type ReportTicket = QueueTicket & {queuedUsers : string[]};



export const TicketRouting = () => {
    const api = useApi();
    const loaded = useMounted();
    const [tickets, setTickets] = useState<QueueTicket[] | null>(null);
    const [ userReportData , setUserReportData ] = useState< UserReportDataMinimum[] | null >( null );

    const [ investgateUser , setInvestigateUser ] = useState< UserReportDataMinimum | null >( null );


    const cleanedTickets = useMemo(() => {
        if (!tickets) return [];
        if (!userReportData) return [];

        return tickets.map(ticket => {
            const users = userReportData.filter( ur => {
                return ur.ticketIds.includes(ticket.id);
            });
            return {
                ...ticket,
                queuedUsers: users.map( u => u.user.email)
            } as ReportTicket
        });

    },[ JSON.stringify(tickets) , JSON.stringify(userReportData)]);

    const refreshData = () => {
        api.get<QueueTicket[]>(`/ticketqueue/tickets`).then(({ data }) => {
            setTickets(data);
        });
        api.get<UserReportData[]>(`/ticketqueue/report/useroverview` ).then( ( { data }) => {
            loaded() && setUserReportData( data.map( ur => {
                const { user , queue } = ur;
                return {
                    user,
                    queue : queue.map( q => {
                        const { id , fromRole , ticketNumber , title , importanceRank , contactlessStart , status } = q;
                        return {id , fromRole , ticketNumber , title , importanceRank ,contactlessStart , status  }
                    
                    }),
                    ticketIds: queue.map( q => q.id )
                }
            }));
        });
    }


    return (
        <>
            <Button onClick={()=>{
                refreshData();
            }}>Get Data</Button>

            <Dialog maxWidth="lg" open={investgateUser !== null} onClose={()=>{
                setInvestigateUser( null );
            }}>
                <DialogTitle>{investgateUser?.user.email}</DialogTitle>
                <DialogContent sx={{width: '900px'}}>
                    <StyledTable
                        rows={investgateUser?.queue || []}
                        columns={[
                            {
                                headerName: 'Ticket Number',
                                field: 'ticketNumber',
                                Element: ({cell,row}) => {
                                    return <Button size="small" variant="contained" onClick={()=>{window.open( `/ticketqueue/all/${row.id}` , `ticket-${row.id}`)}}>{cell} </Button>
                                }
                            },
                            {
                                headerName: 'From Role',
                                field: 'fromRole'
                            },{
                                headerName: 'Title',
                                field: 'title'
                            },
                            {
                                headerName: 'Contactless Start',
                                field: 'contactlessStart'
                            },
                            {
                                headerName: 'status',
                                field: 'status'
                            },
                            {
                                headerName: 'Importance Rank',
                                field: 'importanceRank'
                            }
                        ]}
                    />
                </DialogContent>
            </Dialog>
            <Grid container>
                {userReportData?.map( u => <Grid sx={{padding: 1}} key={u.user.id} item xs={12}>
                    <UserCard user={u} setInvestigateUser={setInvestigateUser}></UserCard>
                </Grid> ) }
                <Grid item xs={12}>
                <StyledTable<ReportTicket>
                    
                    rows={cleanedTickets || []}
                    columns={[
                        {
                            headerName: 'Ticket ID',
                            field: 'ticketNumber',
                            Element: ({cell,row}) => {
                                return <Button size="small" variant="contained" onClick={()=>{window.open( `/ticketqueue/all/${row.id}` , `ticket-${row.id}`)}}>{cell} </Button>
                            }

                        },
                        {
                            headerName: 'Next Action Date',
                            field: 'nextActionDate',
                            
                            sortable: true,
                            sortRemap: ( cell ) => {
                                if( !cell ){
                                    return 0;
                                }
                                const date = new Date(`${cell.split("T")[0]}T12:00:00Z`);
                                return date.getTime()
                            },
                            Element: ( { cell , row }) => {
                                if( !cell ) return <>No Date Set</>;
                                const d = new Date(`${cell.split("T")[0]}T12:00:00Z`).toLocaleString();
                                return <>{d}</>
                            }
                        },
                        {
                            headerName: 'Title',
                            field: 'title'
                        },
                        {
                            headerName: 'Queue',
                            field: 'queue'
                        },
                        {
                            headerName: 'Queued Users',
                            field: 'queuedUsers',
                            Element: ( { cell , row }) => {
                                
                                return <QueuedUsersButton cell={cell} row={row} />
                            }
                        },
                        {
                            headerName: 'Importance Rank',
                            field: 'importanceRank',
                            sortable: true,
                            sortRemap: ( cell ) => {
                                if( typeof cell === 'number' ){
                                    return cell;
                                }
                                if( typeof cell === 'string' ){
                                    return parseInt(cell) || 0;
                                }
                                return 0;
                            },
                            Element: ( { cell , row }) => {
                                const rank = typeof cell === 'number' ? cell : parseInt(cell) || 0;
                                return <>{rank.toLocaleString()}</>
                            }
                        },
                    ]}
                />
                </Grid>
            </Grid>
        </>
    )
}