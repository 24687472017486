import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessageChannelsQueryVariables = Types.Exact<{
  epicId: Types.Scalars['String']['input'];
}>;


export type MessageChannelsQuery = { __typename?: 'Query', findCoreMessageChannelPaginated: { __typename?: 'PaginatedCoreMessageChannel', data?: Array<{ __typename?: 'CoreMessageChannel', id?: number | null, acknowledged?: boolean | null, result?: Types.CoreMessageChannelResult | null, type?: Types.CoreMessageChannelType | null, assignedToUserId?: string | null, expireTime?: any | null }> | null } };


export const MessageChannelsDocument = gql`
    query MessageChannels($epicId: String!) {
  findCoreMessageChannelPaginated(limit: 100, messageEpicId: {eq: $epicId}) {
    data {
      id
      acknowledged
      result
      type
      assignedToUserId
      expireTime
    }
  }
}
    `;

/**
 * __useMessageChannelsQuery__
 *
 * To run a query within a React component, call `useMessageChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageChannelsQuery({
 *   variables: {
 *      epicId: // value for 'epicId'
 *   },
 * });
 */
export function useMessageChannelsQuery(baseOptions: Apollo.QueryHookOptions<MessageChannelsQuery, MessageChannelsQueryVariables> & ({ variables: MessageChannelsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageChannelsQuery, MessageChannelsQueryVariables>(MessageChannelsDocument, options);
      }
export function useMessageChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageChannelsQuery, MessageChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageChannelsQuery, MessageChannelsQueryVariables>(MessageChannelsDocument, options);
        }
export function useMessageChannelsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MessageChannelsQuery, MessageChannelsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MessageChannelsQuery, MessageChannelsQueryVariables>(MessageChannelsDocument, options);
        }
export type MessageChannelsQueryHookResult = ReturnType<typeof useMessageChannelsQuery>;
export type MessageChannelsLazyQueryHookResult = ReturnType<typeof useMessageChannelsLazyQuery>;
export type MessageChannelsSuspenseQueryHookResult = ReturnType<typeof useMessageChannelsSuspenseQuery>;
export type MessageChannelsQueryResult = Apollo.QueryResult<MessageChannelsQuery, MessageChannelsQueryVariables>;