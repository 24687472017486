import { Badge, Typography, useTheme } from "@mui/material";
import { CoreTimeclock, useTimeclock } from "../../../../react-submodules/providers/Timeclock";
import { FC, useEffect, useMemo, useState } from "react";
import { StyledTable, TableColumnDef } from "../../../../react-submodules/components/Table";

import { CoreUser } from "../../../../react-submodules/types/core";
import { Icon } from "@iconify/react";
import { useApi } from "../../../../react-submodules/providers/Api/Api";
import { useTicketApi } from "../../../../react-submodules/providers/TicketApiProvider";

const CellFlag : TableColumnDef['Element'] = ( { cell , row } ) => {
    const theme = useTheme();
    if( cell){
        return <Icon icon="icon-park-solid:check-one" color={theme.palette.success.main} />
    }
    return <></>
}

const sortCorTimeclock = ( a : CoreTimeclock, b : CoreTimeclock ) => {
    if( a === b ) return 0;
    if( a === null ) return 1;
    if( b === null ) return -1;
    return new Date( b.end || '' ).getTime() - new Date( a.end || '' ).getTime() ;
}

const LastPunch : TableColumnDef['Element'] = ( { cell } ) => {
    const theme = useTheme();
    const active = cell.filter( ( p : CoreTimeclock ) => !p.end );
    const lastPunch = cell.sort( sortCorTimeclock )[0];
    if( active.length === 1 ){
        return <Icon icon="icon-park-solid:check-one" color={theme.palette.success.main} />
    }
    if( active.length ){
        return <Badge badgeContent={active.length}><Icon icon="icon-park-solid:check-one" color={theme.palette.success.main} /></Badge>
    }
    if( !lastPunch ){
        return <Typography></Typography>
    }
    const lastDate = new Date( lastPunch.end );

    return <Typography>{lastDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })}</Typography>
}


export const MainOverview : FC = () => {
    const { resources } = useTicketApi();
    // const { allPunches} = useTimeclock();
    const api = useApi();
    const [coreUsers , setCoreUsers] = useState<CoreUser[] | null>(null);
    const [reloadTick , setReloadTick] = useState(1);
    const [ todaysPunches , setTodaysPunches ] = useState<CoreTimeclock[]>([]);
    useEffect( () => {
        let loaded = true;
        const intv = setInterval( () => {
            loaded && setReloadTick( pv => pv * -1 );
        } , 15 * 1000 );
        return ()=>{
            loaded = false;
            clearInterval(intv);
        }
    }, [  ]);


    useEffect( () => {      
        api.get<CoreUser[]>('/core/users' , { 'email:like' : 'coulee.tech'}).then( ( { data }) => {
            setCoreUsers( data );
        });
        api.get<CoreTimeclock[]>('/core/timeclock/todays').then( ( { data }) => {
            setTodaysPunches( data.sort( sortCorTimeclock) );
        });

    }, [ reloadTick ]);

    

    const users = useMemo( () => {
        const now = new Date();
        return resources.filter( r => r.isActive && r.licenseType === 1 && r.userType !== 10 ).map( r => {
            const user = coreUsers?.find( u => u.email === r.email );
            return {
                ...user,
                ...r,
                now,
                punches: todaysPunches.filter( p => p.coreUserId === user?.id )
            }
        
        })
    } , [ JSON.stringify( resources) ,  JSON.stringify(coreUsers) ]);
    return <>
        <StyledTable
            rows={users }
            onClick={(row) => {
                console.log(row);            
            }}
            columns={[
                { field: 'firstName', headerName: 'First Name' },
                { field: 'lastName', headerName: 'Last Name' },
                { field: 'email', headerName: 'Email'},
                
                { field: 'isDispatchable', headerName: 'Dispatchable' , Element: CellFlag },
                { field: 'isRoleTriage', headerName: 'Triage' , Element: CellFlag },
                { field: 'isRoleTier1', headerName: 'T1' , Element: CellFlag },
                {field: 'isRoleTier2FirstUp' , headerName: 'T2 First Up' , Element: CellFlag },
                { field: 'isRoleTier2', headerName: 'T2' , Element: CellFlag },
                { field: 'punches', headerName: 'Clocked In', Element: LastPunch },

            ]}
        />
    </>
}
