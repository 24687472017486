import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSoftwareQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetSoftwareQuery = { __typename?: 'Query', findCoreDeviceSoftwarePaginated: { __typename?: 'PaginatedCoreDeviceSoftware', page: number, limit: number, hash?: string | null, totalCount: number, data?: Array<{ __typename?: 'CoreDeviceSoftware', id?: number | null, name: string, description?: string | null, vendor?: string | null, Tags?: Array<{ __typename?: 'CoreTag', name: string }> | null }> | null } };


export const GetSoftwareDocument = gql`
    query GetSoftware($page: Int, $limit: Int, $search: String) {
  findCoreDeviceSoftwarePaginated(
    page: $page
    limit: $limit
    name: {like: $search}
  ) {
    data {
      id
      name
      description
      Tags {
        name
      }
      vendor
    }
    page
    limit
    hash
    totalCount
  }
}
    `;

/**
 * __useGetSoftwareQuery__
 *
 * To run a query within a React component, call `useGetSoftwareQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSoftwareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSoftwareQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetSoftwareQuery(baseOptions?: Apollo.QueryHookOptions<GetSoftwareQuery, GetSoftwareQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSoftwareQuery, GetSoftwareQueryVariables>(GetSoftwareDocument, options);
      }
export function useGetSoftwareLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSoftwareQuery, GetSoftwareQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSoftwareQuery, GetSoftwareQueryVariables>(GetSoftwareDocument, options);
        }
export function useGetSoftwareSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSoftwareQuery, GetSoftwareQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSoftwareQuery, GetSoftwareQueryVariables>(GetSoftwareDocument, options);
        }
export type GetSoftwareQueryHookResult = ReturnType<typeof useGetSoftwareQuery>;
export type GetSoftwareLazyQueryHookResult = ReturnType<typeof useGetSoftwareLazyQuery>;
export type GetSoftwareSuspenseQueryHookResult = ReturnType<typeof useGetSoftwareSuspenseQuery>;
export type GetSoftwareQueryResult = Apollo.QueryResult<GetSoftwareQuery, GetSoftwareQueryVariables>;