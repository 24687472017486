import { Theme } from "@mui/material";

export const getCategoryColors = ( category : string , theme : Theme ) => {
    if( category === "Good"){
        return theme.palette.success.main;
    }
    if( category === "Not Working"){
        return theme.palette.error.main;
    }
    if( category === "Not Dispatchable"){
        return theme.palette.warning.main;
    }
    if( category === "Not working Dispatched"){
        return theme.palette.warning.light;
    }
    if( category === "Other"){
        return theme.palette.info.main;
    }
}