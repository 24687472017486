import { TableColumnDef } from "../Table";
import { DataGrid, GridColDef , gridClasses} from "@mui/x-data-grid";
import { FunctionComponent, useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import { alpha, styled } from '@mui/material/styles';
import { Box, Button, IconButton, InputAdornment, Popover, SxProps, Table, TableBody, TableCell, TableHead, TableRow, TextField, Theme, Typography, useTheme } from "@mui/material";
import { InlineIcon } from "@iconify/react";
import { useFilter } from "../../../providers/FilterProvider";

export    const FilterMenuNumber = ( { column , onClose } : { column : TableColumnDef , onClose: ()=> void } ) => {
    const {filter , updateFilter , clearFilter } = useFilter( column.field );
    const [min, setMin] = useState<number| null >( typeof filter?.min  === "number" ? filter?.min : null );
    const [max, setMax] = useState<number| null >( typeof filter?.max  === "number" ? filter?.max : null );
    const theme = useTheme();
    const handleClose = ()=>{ 
      
      if( max === null && min === null ){
        clearFilter();
        onClose();
        return;
      } 
      if( typeof min === "number" && typeof max === "number"){
        updateFilter( {
          method: 'between' ,min, max 
        } );
        onClose();
          return;
      }
      if( typeof min === "number" ){
        updateFilter( {
          method: 'gte' , value: min , min , max 
        } );
        onClose();
          return;
      }
      if( typeof max === "number" ){
        updateFilter( {
          method: 'lte'  , value: max , max , min
        } );
        onClose();
          return;
      }
      onClose();
    }
    
    return <>
    <Box sx={{background: theme.palette.background.paper , borderRadius: 1 }}><Box sx={{padding: 1 }}><TextField label="min" size="small" type="number" sx={{  borderRadius: 1}} value={min} onChange={( e ) => {
      const f = parseFloat( e.target.value );
      setMin( isNaN( f ) ?  null : f  );
    } } /></Box>
    <Box sx={{padding: 1 }}>
    <TextField label="Max" size="small" type="number" sx={{ background: theme.palette.background.paper , borderRadius: 1}} value={max} onChange={( e ) => {
      const f = parseFloat( e.target.value );
      setMax( isNaN( f ) ?  null : f  );
    } } />
    </Box>
    </Box>
    <Box sx={{padding: 1 , position: 'relative' , width: '100%' , height: '48px' }}><Button onClick={handleClose} color="secondary" sx={{ position: 'absolute' , right: '0'}} variant="contained">Save</Button></Box>
    </>
   }