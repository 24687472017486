import { BarChart } from '@mui/x-charts/BarChart';
import { useEffect, useMemo, useState } from "react"
import { useApi } from "../../react-submodules/providers/Api/Api";
import { Button, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle, Grid, Paper, TextField, Theme, Typography, useTheme } from "@mui/material";
import { ResourceCalendar } from "../../react-submodules/components/calendar/Calendar";
import FullCalendar from "@fullcalendar/react";

import interactionPlugin from "@fullcalendar/interaction";
import luxon3Plugin from "@fullcalendar/luxon3";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { CoreUser } from "../../react-submodules/types/core";
import { MarkdownView } from "../../react-submodules/MarkdownView";
import { useInterval } from "../../react-submodules/hooks/useInterval";
import { TUserStatus } from '../../react-submodules/types/opscompliance';
import { PieChart } from '@mui/x-charts';
import { TechPieChart } from './TechPieChart';
import { TotalTicketGraph } from './TotalTicketGraph';





export const TechOverview = () => {
    const api = useApi();
    const [ data , setData ] = useState<TUserStatus[]>( [] );
    const [ users , setUsers] = useState<CoreUser[]>( [] );

    const [ refreshTick , setRefreshTick ] = useState( 1 );
    useEffect( () => {
        const intv = setInterval( () => {
            setRefreshTick( rt => rt * -1 );
        } , 60 * 1000 );
        return () => {
            clearInterval( intv );
        }
    } , [])
    useEffect( () => {
        api.get<TUserStatus[]>('/opscompliance/userStatusCalendar').then( ({data}) => {
            setData( data );
        });
        api.get<CoreUser[]>('/core/users' , {'email:like' : 'coulee.tech'}).then( (res) => {
            
            setUsers( res.data.filter( ( u ) => u.techTier !== null ) );
        });
    } , [refreshTick]);
    const filteredUsers = useMemo(()=>{
        return users.filter( ( u ) => {
            if( ["tholum@coulee.tech" , "rholum@coulee.tech"].includes( u.email) ) {
                return false;
            }
            const hasPunches = data.find( d => d.coreUserId === u.id && d.isTimwoodsClockedIn );
            return !!hasPunches;
         } )
    }, [JSON.stringify( users) , JSON.stringify( data ) ])
    return <>
    <Grid container spacing={2}>
        <Grid md={12}>
            <Typography variant="h2">Todays Tech Overview</Typography>
        </Grid>
    {filteredUsers.map( u => {
        return <Grid key={u.id} item md={12} lg={6} xl={4}><Card>
            <CardHeader title={`${u.firstName} ${u.lastName}`}></CardHeader>
            <CardContent>
                <TechPieChart user={u} data={data} />
            </CardContent>
        </Card></Grid>
    })}
    </Grid>

    <TotalTicketGraph />
        
    </>
}

