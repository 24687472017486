import { CoreTimeclock, useTimeclock } from "../providers/Timeclock";
import { Icon, InlineIcon } from "@iconify/react";
import { useEffect, useMemo, useState } from "react";

import { AnyBadge } from "./AnyBadge";
import { AutotaskResource } from "../types/ticketqueue";
import { BadgeAndName } from "./TicketPartials/BadgeAndName";
import { Chip } from "@mui/material";
import { useTicket } from "../providers/TicketProvider";
import { useTicketApi } from "../providers/TicketApiProvider";

const OtherUserChip = ( { punch } : { punch: CoreTimeclock & { resource? : AutotaskResource } } ) => {
    const [ now , setNow ] = useState( new Date().getTime() );
    useEffect( () => {
        const interval = setInterval( () => {
            setNow( new Date().getTime() );
        }, 15000);
        return () => {
            clearInterval( interval );
        }
    }, []);
    const punchTime = useMemo( () => {
        if( !punch ) return null;
        const start = punch?.start ? new Date( punch.start ).getTime() : 0;

        const secondsFull = (now - start) / 1000;
        const seconds = secondsFull < 0 ? 0 : secondsFull;
        const secondsDisplay = seconds % 60;
        const minutes = Math.floor( seconds / 60 ) % 60;
        const hours = Math.floor( seconds / 60 / 60 );
        if( hours ) {
            return `${hours}h ${minutes}m`
        }
        return `${minutes}m`;
      } , [ punch?.start , now ] );
    return <Chip size="small" avatar={<AnyBadge size="medium" colorkey={punch?.resource?.email||""} name={`${punch.resource?.firstName} ${punch.resource?.lastName}`} />} label={<><InlineIcon icon="mdi:clock" />&nbsp;{punchTime}</>}/>
}



export const OtherUsersTimeclock = () => {
    const { otherUsersPunches } = useTimeclock();
    const { resources } = useTicketApi();
    const { ticket } = useTicket();
    const punches = useMemo( () => {
        return otherUsersPunches.filter( ( punch ) => {
            return punch.ticketId === ticket?.id;
        } ).map( punch => {
            return {
                ...punch,
                resource: resources.find( r => r.id === punch.autotaskUserId )
            }
        }) as Array< CoreTimeclock & { resource? : AutotaskResource }>;
    } , [JSON.stringify( otherUsersPunches) , ticket?.id , JSON.stringify( resources )])
    return <>{punches.map( punch => <OtherUserChip key={punch.id} punch={punch} />)}</>
}