import { Button, Dialog, DialogContent, DialogTitle, Grid,  IconButton,  InputAdornment,  TextField, Typography } from "@mui/material"
import { FC, useState } from "react"
import { gql, useMutation, useQuery } from "@apollo/client"
import { Icon } from "@iconify/react"
import { ADD_ICON, COPY_ICON, LINK_ICON, SAVE_ICON } from "../../react-submodules/constances/icons"
import { StyledTable, TableColumnDef } from "../../react-submodules/components/Table"

const CREATE_AZURE_TENANT_MUTATION = gql`
mutation CreateAzureTenant( $tenantId: String! , $name: String! , $coreCompanyId: Float! ) {
    createAzureTenants( id: $tenantId , name: $name , coreCompanyId: $coreCompanyId ) {
        id
    }
}
`

const AZURE_TENANTS_QUERY = gql`
query GetAzureTenants( $coreCompanyId: Float! ) {
    findAzureTenantsPaginated( coreCompanyId: { eq : $coreCompanyId } ) {
        data {
            id
            name
            coreCompanyId
            verified
        }
        totalCount
        page
        limit
    }
}
`
const VERIFY_TENANT_MUTATION = gql`
mutation VerifyTenant( $tenantId: String! ) {
    verifyAzureTenant( id: $tenantId ) {
        id
        verified
        name
    }
}
`

const columns: TableColumnDef[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Name' },
    { field: 'verified', headerName: 'Verified', Element: ( { cell , row : tenant, verifyTenant } ) => {
        if( !cell ) {
            return  <Button onClick={()=>{
                            verifyTenant( { variables: { tenantId: tenant.id } } );
                        }}>Verify</Button>
        }
        return <></>
    } },
    { field: 'Authorize', headerName: 'Actions', Element: ( { row : tenant } ) => {
        return <>
        <IconButton onClick={()=>{
            window.open(`https://login.microsoftonline.com/${tenant.id}/adminconsent?client_id=ffe79c39-e31f-4307-96f6-f3762f6e91df&redirect_uri=${window.location.href}`, '_blank');
        }}><Icon icon={LINK_ICON} /></IconButton>
        <IconButton onClick={()=>{
            navigator.clipboard.writeText( `https://login.microsoftonline.com/${tenant.id}/adminconsent?client_id=ffe79c39-e31f-4307-96f6-f3762f6e91df&redirect_uri=${window.location.href}` );
        }}><Icon icon={COPY_ICON} /></IconButton>
        </>
    } }
    
]

export const LinkAzureDialog : FC<{coreCompanyId?: number | null , onClose: ()=>void}> = ({coreCompanyId, onClose}) => {
    const [ tenantId , setTenantId ] = useState( '' );
    const { data , refetch } = useQuery<{findAzureTenantsPaginated: {data: {id: string, name: string, coreCompanyId: number, verified: boolean}[] } }>( AZURE_TENANTS_QUERY , { variables: { coreCompanyId } , skip: !coreCompanyId } );
    const [ createAzureTenant ] = useMutation( CREATE_AZURE_TENANT_MUTATION , { refetchQueries: [AZURE_TENANTS_QUERY] } );
    const [ verifyTenant ] = useMutation( VERIFY_TENANT_MUTATION , { refetchQueries: [AZURE_TENANTS_QUERY] } );
    const onSave = async () => {
        await createAzureTenant( { variables: { tenantId, coreCompanyId , name : 'New Tenant' } } );
        setTenantId( '' );
    }
    return <Dialog fullWidth maxWidth="lg" open={!!coreCompanyId} onClose={()=>{onClose()}}>
        <DialogTitle>Link Azure</DialogTitle>
        <DialogContent>
            <StyledTable elementProps={{verifyTenant}} columns={columns} rows={data?.findAzureTenantsPaginated?.data||[]} />
        </DialogContent>
    </Dialog>
}