import { Icon } from "@iconify/react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { FC } from "react";
import { LabeledBox } from "../../../../react-submodules/components/LabeldBox";
import { LINK_ICON } from "../../../../react-submodules/constances/icons";
import { MarkdownView } from "../../../../react-submodules/MarkdownView";
import { useUser } from "../../../../react-submodules/providers/User";
import { useDeleteScheduleInstanceMutation } from "./gql/DeleteScheduleInstance.generated";
import { useGetScheduleInstanceQuery } from "./gql/GetScheduleInstance.generated";

export const ItemView : FC<{ instanceId : number | null , onClose : () => void }> = ({ instanceId , onClose }) => {
    const { data } = useGetScheduleInstanceQuery({ variables : { id : instanceId ?? 0 } , skip : instanceId === null });
    const [ deleteInstance ] = useDeleteScheduleInstanceMutation();
    const user = useUser();
    const instance = data?.getScheduleInstanceById;
    const locked = instance?.lockedDateTime || instance?.lockedUser;
    return <Dialog open={instanceId !== null} onClose={onClose}>
        <DialogTitle>Item View {instanceId}</DialogTitle>
        <DialogContent>
            <Typography variant="h6">{ instance?.title || instance?.AzureCalendar?.subject || instance?.SchedulePlan?.name }</Typography>
            {instance?.AzureCalendar && <>
                { user.email === instance?.AzureCalendar?.mailbox &&  instance?.AzureCalendar?.webLink && <Button sx={{ mb : 2 }} variant="contained" color="info" size="small" startIcon={<Icon icon={LINK_ICON} />} href={instance.AzureCalendar.webLink} >Open in Calendar</Button>} 
                <LabeledBox  label="Calendar Details" >
                    <MarkdownView size="small" >
                        { instance?.AzureCalendar?.bodyPreview || '' }
                    </MarkdownView>

                </LabeledBox >
            </>}
            {instance?.description && <LabeledBox label="Description" >
                <MarkdownView size="small" >
                    { instance?.description || '' }
                </MarkdownView>
            </LabeledBox>}
            {instance?.SchedulePlan?.description && <LabeledBox label="Plan Details" >
                <MarkdownView size="small" >
                    { instance?.SchedulePlan?.description || '' }
                </MarkdownView>
            </LabeledBox>}
        </DialogContent>
        <DialogActions>
            { instanceId && !locked && <Button variant="contained" color="error" onClick={() => deleteInstance({ variables : { id : instanceId ?? 0 } }).finally(() => onClose())} >Delete</Button> }
            <Button variant="contained" color="info" onClick={onClose} >Close</Button>
        </DialogActions>
    </Dialog>;
}
