import { FC, useMemo, ReactNode, useState, useEffect, useCallback, useRef } from 'react';
import { Box, List, ListItem, ListItemText, Typography, Divider, useTheme, Avatar, IconButton, Tooltip, Button, CircularProgress, TextField, Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions, Paper } from '@mui/material';
import { AnyBadge } from '../AnyBadge';
import { TechFinderUsersQuery, useTechFinderUsersQuery } from './gql/ActiveUsers.generated';
import { InlineIcon, Icon } from "@iconify/react/dist/iconify.js";
import { CALENDAR_ICON, FIRST_UP_ICON, PHONE_ICON, TICKET_ICON } from "../../constances/icons";
import { priorityToColor, priorityToIcon } from "../TicketPartials/Priority";
import { CONFLICT_COLOR, AZURE_COLOR, SCHEDULE_COLOR, UNSET_COLOR, AZURE_COLOR_HOVER, SCHEDULE_COLOR_HOVER } from "../../utils/schedule";
import { useUser } from "../../providers/User";
import { useCreateCoreMessageChannelMutation } from './gql/CreateMessageChannel.generated';
import { useTechFinderUpdateCoreMessageChannelMutation } from './gql/UpdateMessageChannel.generated';
import { CoreMessageChannelResult, CoreMessageChannelType } from '../../../generated/graphql';
import { useSocket } from '../../../providers/SocketIo';
import { ExtendedUserItem, UserItem, ActiveTransferRequest } from './types';
import { UserListItem } from './components/UserListItem';
import { isUserFirstUp, getHighestPriority } from './utils';
import { useMounted } from '../../hooks/useMounted';

import { v4 as uuidv4 } from 'uuid';
import { useMessageChannelsQuery } from './gql/MessageChannels.generated';


const TIMEOUT = 30000;


export const TechFinder: FC<{ ticketId: number , messageEpicId: string , onTechFound: (userId: string) => void }> = ({ ticketId , messageEpicId , onTechFound }) => {
    const { data: messageChannelsData , refetch: refetchMessageChannels } = useMessageChannelsQuery({
        variables: {
            epicId: messageEpicId || ''
        },
        skip: !messageEpicId
    });
  const { data: techFinderData, loading, refetch } = useTechFinderUsersQuery();
  const [ findTech , setFindTech ] = useState(false);
  const mounted = useMounted()
  const [refreshing, setRefreshing] = useState(false);
  const [users, setUsers] = useState<ExtendedUserItem[]>([]);
  const [transferInProgress, setTransferInProgress] = useState(false);
  const [activeTransferRequest, setActiveTransferRequest] = useState<ActiveTransferRequest | null>(null);
  const [transferMessage, setTransferMessage] = useState("Please take this ticket!");
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [timeoutSeconds, setTimeoutSeconds] = useState<number>(10);
  const [createMessageChannel] = useCreateCoreMessageChannelMutation();
  const [updateMessageChannel] = useTechFinderUpdateCoreMessageChannelMutation();
  const socket = useSocket();
  const [now, setNow] = useState(new Date());
  const theme = useTheme();
  const currentUser = useUser();
  const currentUserId = currentUser.tokenParsed?.sub;

  useEffect(() => {
    if( findTech ){
      const interval = setInterval(() => {
        setNow(new Date());
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [findTech]);

  // Function to transform raw users to extended users with status
  const transformUsers = (rawUsers: UserItem[]): ExtendedUserItem[] => {
    return rawUsers.map(user => ({
      ...user,
      techFinderStatus: 'queued'
    }));
  };
  
  // Initial data load
  useEffect(() => {
    if (techFinderData?.findCoreUsersPaginated?.data) {
      // Filter out the current user
      const filteredUsers = techFinderData.findCoreUsersPaginated.data.filter(
        user => user.id !== currentUserId
      ) as UserItem[];
      
      // Only set users if we don't already have them (to preserve status)
      if (users.length === 0) {
        setUsers(transformUsers(filteredUsers));
      } else {
        // Update existing users but preserve their status
        setUsers(prevUsers => {
          const userMap = new Map(prevUsers.map(u => [u.id, u]));
          
          return filteredUsers.map(newUser => {
            const existingUser = userMap.get(newUser.id);
            return {
              ...newUser,
              techFinderStatus: existingUser?.techFinderStatus || 'queued'
            };
          });
        });
      }
    }
  }, [techFinderData, currentUserId]);
  
  // Handle refresh button click
  const handleRefresh = async () => {
    setRefreshing(true);
    try {
      await refetch();
    } finally {
      setRefreshing(false);
    }
  };
  
  // Handle status change
  const handleStatusChange = useCallback((userId: string, status: ExtendedUserItem['techFinderStatus']) => {
    setUsers(prevUsers => 
      prevUsers.map(user => 
        user.id === userId 
          ? { ...user, techFinderStatus: status } 
          : user
      )
    );
    refetchMessageChannels();
  }, []);

  useEffect(() => {
    if( messageChannelsData?.findCoreMessageChannelPaginated?.data ){
      for(const messageChannel of messageChannelsData.findCoreMessageChannelPaginated.data){
        const user = users.find(u => u.id === messageChannel.assignedToUserId);
        if( user ){
          switch( messageChannel.result ){
            case CoreMessageChannelResult.Accepted:
              user.techFinderStatus !== 'accepted' && handleStatusChange(`${user.id}`, 'accepted');
              break;
            
            case CoreMessageChannelResult.Rejected:
            case CoreMessageChannelResult.Ignored:
            case CoreMessageChannelResult.Timeout:
              user.techFinderStatus !== 'rejected' && handleStatusChange(`${user.id}`, 'rejected');
              break;
            default:
              user.techFinderStatus !== 'pending' && handleStatusChange(`${user.id}`, 'pending');
              break;            
          }
        }
      }
    }
  }, [messageChannelsData?.findCoreMessageChannelPaginated?.data?.map( c => `${c.id}-${c.result}`).join(',') , users.map( u => `${u.id}-${u.techFinderStatus}`).join(',') ]);
  // Sort users by various criteria
  const sortedUsers = useMemo(() => {
    return [...users].sort((a, b) => {
      // 1. Clocked in users go up, not clocked in go down
      const aIsActive = (a.activeTimeclocks?.length || 0) > 0;
      const bIsActive = (b.activeTimeclocks?.length || 0) > 0;
      
      if (aIsActive && !bIsActive) return -1;
      if (!aIsActive && bIsActive) return 1;

      if( a.canContactCustomers && !b.canContactCustomers ) return -1;
      if( !a.canContactCustomers && b.canContactCustomers ) return 1;
      
      // 2. isOnCall: false goes up, true goes down
      if (!a.isOnCall && b.isOnCall) return -1;
      if (a.isOnCall && !b.isOnCall) return 1;
      
      // 3. First Up goes up
      const aIsFirstUp = isUserFirstUp(a);
      const bIsFirstUp = isUserFirstUp(b);
      
      if (aIsFirstUp && !bIsFirstUp) return -1;
      if (!aIsFirstUp && bIsFirstUp) return 1;
      
      // 4. Lower techTier goes up
      const aTechTier = a.techTier || 999;
      const bTechTier = b.techTier || 999;
      
      if (aTechTier < bTechTier) return -1;
      if (aTechTier > bTechTier) return 1;
      
      // 5. Sort by highest priority ticket (lower number = higher priority)
      const aHighestPriority = getHighestPriority(a);
      const bHighestPriority = getHighestPriority(b);
      
      // Higher priority (lower number) goes down the list
      if (aHighestPriority > bHighestPriority) return -1;
      if (aHighestPriority < bHighestPriority) return 1;
      
      // Fallback to alphabetical by name
      return ((a.firstName || '') + (a.lastName || '')).localeCompare((b.firstName || '') + (b.lastName || ''));
    });
  }, [users]);
  
  const targetUser = useMemo(() => {
    const acceptedUser = sortedUsers.find(user => user.techFinderStatus === 'accepted');
    if (acceptedUser) {
      return acceptedUser;
    }
    const nextUser = sortedUsers.find(user => ['queued', 'pending'].includes(user.techFinderStatus));
    if (nextUser) {
      return nextUser;
    }
    return null;
  } , [JSON.stringify(sortedUsers)]);

  useEffect(() => {
    const requestsSent = messageChannelsData?.findCoreMessageChannelPaginated?.data?.map( c => `${c.assignedToUserId}`);
    if (findTech && targetUser?.techFinderStatus !== 'accepted' && targetUser && !requestsSent?.includes(targetUser.id || '') ) {
      createMessageChannel({
        variables: {
          epicId: messageEpicId,
          expireTime: new Date(Date.now() + TIMEOUT),
          assignedToUserId: targetUser.id ||'',
          atTicketId: ticketId,
          type: CoreMessageChannelType.PrepareForTransfer,
          message: transferMessage
        }
      }).then(res => {
        refetchMessageChannels();
      })
    }
  }, [findTech , targetUser?.id , targetUser?.techFinderStatus , messageChannelsData?.findCoreMessageChannelPaginated?.data?.map( c => `${c.assignedToUserId}`).join(',') ]);


  useEffect(
    () => {
      if( targetUser?.techFinderStatus === 'accepted' ){
        onTechFound(targetUser.id || '');
      }
    },
    [targetUser?.techFinderStatus , onTechFound , targetUser?.id]
  )

  useEffect(() => {
    if( targetUser?.techFinderStatus === 'accepted' ){
      setFindTech(false);
    }
  }, [ targetUser?.techFinderStatus ]);
  
  useEffect(() => {
    for( const message of messageChannelsData?.findCoreMessageChannelPaginated?.data || [] ){
      if( message.expireTime && new Date(message.expireTime) < now ){
        updateMessageChannel({
          variables: { id: message.id || 0, result: CoreMessageChannelResult.Timeout }
        })
      }
    }
  }, [ messageChannelsData?.findCoreMessageChannelPaginated?.data?.map( c => `${c.assignedToUserId}-${c.result}-${c.expireTime}`).join(',') , now ]);
  
  


  const startTransferProcess = () => {
    setFindTech(true);
  }

  // Listen for socket events related to message channels
  useEffect(() => {
    if (!socket ) return;

    const handleMessageUpdate = (res: any) => {
      const data = res.entity;
      refetchMessageChannels();
      // messageChannelsData?.findCoreMessageChannelPaginated?.data?.map( c => `${c.assignedToUserId}`)
    };

    socket.on('core.message.channel.updated', handleMessageUpdate);
    
    return () => {
      socket.off('core.message.channel.updated', handleMessageUpdate);
    };
  }, [socket, handleStatusChange]);

  // Handle error dismissal
  const handleErrorClose = () => {
    setError(null);
  };
  
  // Handle success dismissal
  const handleSuccessClose = () => {
    setSuccess(null);
  };

  if (loading && users.length === 0) {
    return <Typography>Loading technicians...</Typography>;
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography variant="h6" sx={{ pb: 0 }}>
            Tech Finder
          </Typography>
          <Typography variant="caption" sx={{ pt: 0, pb: 1 }}>
            Technicians sorted by availability
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            variant="outlined"
            startIcon={refreshing ? <CircularProgress size={20} /> : <Icon icon="mdi:refresh" />}
            onClick={handleRefresh}
            disabled={refreshing}
          >
            {refreshing ? 'Refreshing...' : 'Refresh'}
          </Button>
        </Box>
      </Box>
      
      {ticketId && (
        <Box sx={{ px: 2, pb: 2 }}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 1, 
            p: 2, 
            bgcolor: 'background.default', 
            borderRadius: 1,
            border: '1px solid',
            borderColor: 'divider'
          }}>
            <Typography variant="subtitle2">
              Transfer Ticket #{ticketId}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Available Technicians: {sortedUsers.filter(user => user.techFinderStatus === 'queued').length}
            </Typography>
            <TextField
              fullWidth
              label="Message to Technician"
              variant="outlined"
              multiline
              rows={3}
              value={transferMessage}
              onChange={(e) => setTransferMessage(e.target.value)}
              disabled={transferInProgress}
              placeholder="Enter message to send to technician"
              size="small"
              sx={{ mt: 1 }}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              startIcon={transferInProgress ? <CircularProgress size={20} color="inherit" /> : <Icon icon={TICKET_ICON} />}
              onClick={startTransferProcess}
              disabled={transferInProgress || !ticketId || !transferMessage.trim()}
              sx={{ mt: 1 }}
            >
              {transferInProgress ? 'Finding Tech...' : 'Find Tech'}
            </Button>
          </Box>
        </Box>
      )}
      
      <Divider />
      <List>
        {sortedUsers.map((user) => (
          <UserListItem 
            key={user.id} 
            user={user} 
            noBorder={false}
            isActive={activeTransferRequest?.userId === user.id}
            timeoutSeconds={activeTransferRequest?.userId === user.id ? timeoutSeconds : undefined}
            onStatusChange={handleStatusChange}
          />
        ))}
        {sortedUsers.length === 0 && (
          <ListItem>
            <ListItemText primary="No technicians found" />
          </ListItem>
        )}
      </List>
      
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleErrorClose}>
        <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      
      <Snackbar open={!!success} autoHideDuration={6000} onClose={handleSuccessClose}>
        <Alert onClose={handleSuccessClose} severity="success" sx={{ width: '100%' }}>
          {success}
        </Alert>
      </Snackbar>
    </Box>
  );
};
