import { useParams } from "react-router-dom"
import { ChangeEvent, FunctionComponent, useEffect, useRef, useState  } from "react";
import {  Badge, Box, Chip, Paper, Tooltip, Typography, useTheme , IconButton , Dialog , DialogTitle , DialogActions , Button , List , ListItem , ListItemButton , ListItemIcon , ListItemText, DialogContent, Checkbox, Menu, MenuItem, FormControl, InputLabel, Select, Popover, Switch } from "@mui/material";
import { Icon, InlineIcon } from "@iconify/react";
import { StyledTable } from "../../../../react-submodules/components/Table/Table";
import { NewComputerType, NewComputerWizard } from "../../../../react-submodules/components/NewComputerWizard/NewComputerWizard";
import { DeviceQueryItem } from "../../../../react-submodules/types/dattormm.dto";
import { useApi } from "../../../../react-submodules/providers/Api";
import { GroupGuard } from "../../../../react-submodules/components/GroupGuard";
import { CpuScore } from "./CpuScore";
import { ContractsInstances } from "../../../../coretypes/contracts/contracts.dto";
import { ContractCell } from "./ContractCell";
import { BulkActions } from "./BulkActions";


const today = new Date();

const categorySortOrder = ["Server" , "Desktop" , "Laptop"];
// this is Category 
const devicesProcess = ( devices: DeviceQueryItem[] ) => {
    return devices.filter( d => categorySortOrder.includes( d.category ) ).sort( ( a , b ) => {
        return categorySortOrder.indexOf( a.category ) - categorySortOrder.indexOf( b.category );
    } );
}

export const DeviceV2 = () => {
    const { id } = useParams<{id: string}>();
    const [ open , setOpen ] = useState(false);
    
    const [ refreshKey , setRefreshKey ] = useState( 1 );
    const refresh = () =>{
        setRefreshKey( -1 * refreshKey);    
    }

    const [ selectedDevice , setSelectedDevice ] = useState<DeviceQueryItem | null>(null );
    const [ newTicketId  , setNewTicketId] = useState<string | null>(null);

    const [ bulkActionRows , setBulkActionRows ] = useState<DeviceQueryItem[]>([]);

    useEffect( () => {
        if( selectedDevice !== null && !open ){
            setOpen( true );
        }
    } , [JSON.stringify( selectedDevice )]);
    useEffect( () => {
        if( !open ){
            setSelectedDevice( null );
        }
    } , [open]);
    const api = useApi();
    const [ devices , setDevices ] = useState<any[]>( [] );
    const [ contractInstances , setContractInstances ] = useState<ContractsInstances[]>( [] );
    useEffect( () => {
        api.get<DeviceQueryItem[]>( `/reports/devices/${id}` ).then( ( results ) => {
            
            setDevices( devicesProcess(results.data) );
        } );
    } , [api.ready , refreshKey ]);

    useEffect( () => {
        api.get<ContractsInstances[]>( `/contracts/instances/search` , { coreCompanyId : id } ).then( ( results ) => {
            setContractInstances( results.data.filter( c => [ 'SERVERS' , 'WORKSTATIONS' ].includes(c.contract_unitsMeasured) ) );
        } );
    } , [api.ready , id ]);
     
    
    return <Paper>        
        <BulkActions rows={bulkActionRows} onClose={()=>{
            console.log("Onclose");
            setBulkActionRows([]);
        }} contract={contractInstances} />
        { open && <NewComputerWizard  onClose={( ticketId? : string)=>{
            setNewTicketId( ticketId || null );
            setOpen( false );
            refresh();
        }} enableDownload open={open} companyID={parseInt(id||'')} device={selectedDevice || undefined} /> }
        <StyledTable
        title="Devices"
        enableBulkActions
        indexField="device_id"
        onBulkAction={(selectedItems)=>{
            setBulkActionRows( selectedItems );
        }}
        elementProps={{
            openWizard: ( device: DeviceQueryItem ) => {
                setSelectedDevice(device)
            }
        }} rows={devices} columns={[
            {
                field: 'device_lastSeen',
                flex: 1,
                headerName: 'Last Seen',
                sortable: true,
                sortRemap: ( cell : any ) => {
                    return new Date( cell ).getTime();            
                },
                Element: ( { cell } : any ) => {
                    const date = new Date( cell );
                    const diff = today.getTime() - date.getTime();
                    const days = diff / (1000 * 60 * 60 * 24);
        
                    if( days < 1 ){
                        return <Chip label={date.toLocaleDateString()} color="success" />
                    }
                    if( days < 7 ){
                        return <Chip label={date.toLocaleDateString()} color="info" />
                    }
                    if( days < 30 ){
                        return <Chip label={date.toLocaleDateString()} color="warning" />
                    }
                    return <Chip label={date.toLocaleDateString()} color="error" />
        
                }
        
        
            },
            {
                field: 'device_hostname',
                flex: 1,
                headerName: 'Hostname',
                sortable: true
        
            },
            {
                field: 'category',
                flex: 1,
                headerName: 'Category',
                sortable: true
            },
            {
                field: 'device_lastLoggedInUser',
                flex: 1,
                headerName: 'Last User',
                sortable: true,
                sortRemap: ( cell : any ) => {
                    const parts = cell.split( "\\" );
                    if( parts.length === 2 ){
                        return parts[1];
                    }
                    return cell;
                },
                Element: ( { cell , row } : any ) => {
                    if( !cell ){
                        return <></>
                    }
                    let icon = 'icon-park:network-tree';
                    
                    const parts = cell.split( "\\" );
                    if( parts[0].toLowerCase() === "azuread" ){
                        icon = "logos:microsoft-azure";
                    }
                    if( (row?.device_hostname?.toLowerCase().startsWith( parts[0].toLowerCase()) && parts[0].length === 15 ) || parts[0].toLowerCase() === row?.device_hostname?.toLowerCase()  ){
                        icon = "icon-park-twotone:computer";
                    }
                    if( parts.length === 2 ){
                        return <Box sx={{
                        }}>
                            <Box sx={{margin: 'auto'}}>
                                <Box sx={{ marginLeft: 2 }}><Typography sx={{fontWeight: 'bold' , fontSize: 14}} >{icon && <InlineIcon icon={icon} />} {parts[1]}</Typography></Box>
                            </Box>
                        </Box>;
                    }

                    return <></>
                    
                },
                
            } , 
            {
                field: 'contracts',
                flex: 1,
                headerName: 'Contract',                
                sortable: false,
                Element: ( { cell , row  } : any ) => {
                    return <ContractCell refresh={refresh} contract={contractInstances} device={row} />
                }
            } , 
            {
                field: 'cpu_score',
                flex: 1,
                headerName: 'Cpu Score',
                sortRemap: ( cell : any ) => {
                    return parseInt( cell?.replace( /,/g , '' ) ||'0' );
                },
                sortable: true,
                Element: ( { cell } : any ) => {
                    return <CpuScore score={cell} />
                }
            } , 
            
            {
                field: 'device_id',
                flex: 1,
                headerName: 'Actions',
                sortable: false,
                Element: ({ cell, row , openWizard } : any ) => {
                    const [ open , setOpen ] = useState( false );
                    return <>
                        <Dialog open={open} onClose={()=>{
                            setOpen( false );
                        }}>
                            <DialogTitle>Replace Computer {row.device_hostname}</DialogTitle>
                            <DialogContent>
                                <Typography sx={{ fontWeight: 'bold'}}>Ticket ID : {row.hardware_replacement_ticket_id}</Typography>
                                <Typography sx={{whiteSpace: 'pre-line'}}>{row.hardware_replacement_description}</Typography>
                            </DialogContent>
                        </Dialog>
                        <GroupGuard group="tech"><Tooltip title="Web Remote"><IconButton sx={{marginRight: 1}} size="small" color="info"
                        onClick={()=>{
                            window.open(`https://concord.centrastage.net/csm/remote/rto/${cell}` , `remote_${cell}`)
                        }}
                    ><Icon icon="mdi:remote-desktop" /></IconButton></Tooltip>
                    <Tooltip title="Datto Device"><IconButton onClick={()=>{
                        window.open(`https://concord.rmm.datto.com/device/${cell}` , `device_${cell}`)
                    }} color="info" size="small"
                            ><Icon icon="icon-park-outline:code-computer" />
                    </IconButton></Tooltip>
                    </GroupGuard>
                        {!row.hardware_replacement_ticket_id &&<Tooltip title="Replace Computer"><IconButton onClick={()=>{
                            openWizard(row )
                        }}><InlineIcon icon="material-symbols:shopping-cart-outline" /></IconButton></Tooltip> }
                        {row.hardware_replacement_ticket_id && <Tooltip title="Replace Computer"><IconButton color="info" onClick={()=>{
                            setOpen(true)
                        }}><InlineIcon icon="material-symbols:shopping-cart" /></IconButton></Tooltip>}
                    </>
                }
            },
            
        ]} />

        <Dialog open={newTicketId !== null} onClose={()=>{
                    setNewTicketId( null );
                }}>
            <DialogTitle>Order Placed</DialogTitle>
            <Box sx={{margin: 2}}>Your order has been placed, the ticket id is {newTicketId}, If you have any questions or comments please email helpdesk@coulee.tech with that ticket id in the subject or call 608-783-8324
            
            </Box>
            <DialogActions>
                <Button onClick={()=>{
                    setNewTicketId( null );
                }}>Close</Button>
            </DialogActions>
        
        </Dialog>
        </Paper>
}