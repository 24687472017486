import { Button } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { LabeledBox } from "../LabeldBox";
import { TicketSearch } from "../TicketSearch";
import { useGetParentAndChildInfoQuery } from "./gql/GetParentAndChildInfo.generated";
import { useSetParentOnTicketMutation } from "./gql/SetParentOnTicket.generated";
export const ParentTicketButton : FC<{ ticketId : number | null }> = ({ ticketId }) => {

    const { data , refetch } = useGetParentAndChildInfoQuery({ variables: { id: ticketId || NaN  } , skip: ticketId === null , fetchPolicy: 'cache-and-network' });
    const [ setParentOnTicket ] = useSetParentOnTicketMutation();
    const [ ticketSearchOpen, setTicketSearchOpen ] = useState(false);
    const ableToHaveParent = useMemo(() => {
        if( !data?.getAutotaskTicketsById ) return false;
        if( data?.getAutotaskTicketsById?.ParentTicket !== null  ){
            return true;
        }
        if( data?.getAutotaskTicketsById?.ChildTickets?.length === 0 ){
            return true;
        }
        return false;
    }, [data?.getAutotaskTicketsById.ParentTicket , data?.getAutotaskTicketsById.ChildTickets ]);

    const parentTicket = data?.getAutotaskTicketsById?.ParentTicket;

    const handleSelect = (parentTicketId: number | null | undefined) => {
        setTicketSearchOpen(false);
        if( parentTicketId === null || parentTicketId === undefined  || ticketId === null ) return;
        setParentOnTicket({ variables: { childId: ticketId, parentId: parentTicketId  } }).then(() => {
            refetch();
        });
    };
    if( !ableToHaveParent ){
        return null;
    }

    return <>
    <TicketSearch open={ticketSearchOpen} onClose={() => setTicketSearchOpen(false)} onSelect={handleSelect} coreCompanyId={data?.getAutotaskTicketsById?.coreCompanyId ?? undefined} />
    <LabeledBox sx={{margin: 2}} label="Parent Ticket" >
    { !parentTicket && <Button fullWidth color="inherit" variant="contained" size="small" onClick={() => {
        setTicketSearchOpen(true);
    }}>Link Parent Ticket</Button> }


    { parentTicket && <Button color="info" fullWidth variant="contained" size="small" onClick={() => {
        window.open(`/ticketqueue/tickets/${parentTicket?.id}`, "_blank");
    }}>{parentTicket?.ticketNumber} - {parentTicket?.title}</Button> }
    </LabeledBox>
    </>
}