import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllPhoneCallsQueryVariables = Types.Exact<{
  startTime: Types.Scalars['Date']['input'];
  endTime: Types.Scalars['Date']['input'];
}>;


export type GetAllPhoneCallsQuery = { __typename?: 'Query', findPhonesCallsPaginated: { __typename?: 'PaginatedPhonesCalls', data?: Array<{ __typename?: 'PhonesCalls', id?: number | null, createdDateTime: any, startTime?: any | null, endTime?: any | null, direction?: string | null, fromNumber?: string | null, toNumber?: string | null, extention?: string | null, CoreCompany?: { __typename?: 'CoreCompanies', id?: number | null, name: string } | null, CoreUser?: { __typename?: 'CoreUsers', id?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null } | null, AutotaskTickets?: { __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, title: string } | null, PhoneCallLegs?: Array<{ __typename?: 'PhonesCallLegs', id?: number | null, startTime?: any | null, phonesCallId?: number | null, endTime?: any | null, extention?: string | null, CoreUser?: { __typename?: 'CoreUsers', id?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null } | null }> | null }> | null } };


export const GetAllPhoneCallsDocument = gql`
    query GetAllPhoneCalls($startTime: Date!, $endTime: Date!) {
  findPhonesCallsPaginated(
    limit: 100000
    startTime: {gte: $startTime, lte: $endTime}
  ) {
    data {
      id
      createdDateTime
      startTime
      endTime
      direction
      fromNumber
      toNumber
      extention
      CoreCompany {
        id
        name
      }
      CoreUser {
        id
        email
        firstName
        lastName
      }
      AutotaskTickets {
        id
        ticketNumber
        title
      }
      PhoneCallLegs {
        id
        startTime
        phonesCallId
        endTime
        extention
        CoreUser {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllPhoneCallsQuery__
 *
 * To run a query within a React component, call `useGetAllPhoneCallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPhoneCallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPhoneCallsQuery({
 *   variables: {
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useGetAllPhoneCallsQuery(baseOptions: Apollo.QueryHookOptions<GetAllPhoneCallsQuery, GetAllPhoneCallsQueryVariables> & ({ variables: GetAllPhoneCallsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPhoneCallsQuery, GetAllPhoneCallsQueryVariables>(GetAllPhoneCallsDocument, options);
      }
export function useGetAllPhoneCallsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPhoneCallsQuery, GetAllPhoneCallsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPhoneCallsQuery, GetAllPhoneCallsQueryVariables>(GetAllPhoneCallsDocument, options);
        }
export function useGetAllPhoneCallsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllPhoneCallsQuery, GetAllPhoneCallsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllPhoneCallsQuery, GetAllPhoneCallsQueryVariables>(GetAllPhoneCallsDocument, options);
        }
export type GetAllPhoneCallsQueryHookResult = ReturnType<typeof useGetAllPhoneCallsQuery>;
export type GetAllPhoneCallsLazyQueryHookResult = ReturnType<typeof useGetAllPhoneCallsLazyQuery>;
export type GetAllPhoneCallsSuspenseQueryHookResult = ReturnType<typeof useGetAllPhoneCallsSuspenseQuery>;
export type GetAllPhoneCallsQueryResult = Apollo.QueryResult<GetAllPhoneCallsQuery, GetAllPhoneCallsQueryVariables>;