import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";

import { AnyBadge } from "./AnyBadge";
import { QueueTicket } from "../types/ticketqueue";
import { StyledTable } from "./Table";
import { useApi } from "../providers/Api/Api";
import { useQueueParams } from "../hooks/useQueueParams";

export const MyTicketSearch : FC<{
    open: boolean;
    onClose: () => any;
}> = ({ open , onClose}) => {
    const api = useApi();
    const { changeTicket } = useQueueParams();
    const [ tickets , setTickets ] = useState<QueueTicket[]>([]);
    
    useEffect( () => {
        if( !open ) {
            setTickets([]);
            return;
        };
        api.get<QueueTicket[]>(`/ticketqueue/queue/all`).then( ( { data }) => {
            setTickets( data );
        } );
    }, [ open  ]);

    const cleanedTickets = useMemo(  () => tickets.filter( ( v , i , a ) => {
        const id = a.findIndex( t => t.id === v.id);
        return id === i;
    }) , [ JSON.stringify( tickets )]);

    return <Dialog maxWidth="lg" open={open} onClose={()=>{
        onClose();
    }}>
        <DialogTitle>Search for a Ticket</DialogTitle>
        <DialogContent sx={{ width : '900px'}}>
        <StyledTable<QueueTicket>
                    defaultSort={[{
                        field: 'importanceRank',
                        direction: 'desc' 
                      }]}
                    rows={cleanedTickets || []}
                    columns={[
                        {
                            headerName: 'Ticket ID',
                            field: 'ticketNumber',
                            Element: ({cell,row}) => {
                                return <Button size="small" variant="contained" onClick={()=>{
                                    changeTicket(`${row.id}` , '/ticketqueue/all/');
                                    onClose();
                                }}>{cell} </Button>
                            }

                        },
                        {
                            headerName: 'Next Action Date',
                            field: 'nextActionDate',
                            
                            sortable: true,
                            sortRemap: ( cell ) => {
                                if( !cell ){
                                    return 0;
                                }
                                const date = new Date(`${cell.split("T")[0]}T12:00:00Z`);
                                return date.getTime()
                            },
                            Element: ( { cell , row }) => {
                                if( !cell ) return <>No Date Set</>;
                                const d = new Date(`${cell.split("T")[0]}T12:00:00Z`).toLocaleString();
                                return <>{d}</>
                            }
                        },
                        {
                            headerName: 'Title',
                            field: 'title'
                        },
                        {
                            headerName: 'Queue',
                            field: 'queue'
                        },
                        {
                            headerName: 'Importance Rank',
                            field: 'importanceRank',
                            sortable: true,
                            sortRemap: ( cell ) => {
                                if( typeof cell === 'number' ){
                                    return cell;
                                }
                                if( typeof cell === 'string' ){
                                    return parseInt(cell) || 0;
                                }
                                return 0;
                            },
                            Element: ( { cell , row }) => {
                                const rank = typeof cell === 'number' ? cell : parseInt(cell) || 0;
                                return <>{rank.toLocaleString()}</>
                            }
                        },
                        {
                            headerName: 'Dispatched',
                            field: 'dispatched_user_email',
                            Element: ( { cell , row }) => {
                                if( !cell ){
                                    return <></>
                                }
                                // @ts-ignore
                                return <AnyBadge name={row.displayName || cell } colorkey={cell}  />
                            }
                        }
                    ]}
                />
        </DialogContent>
    </Dialog>
}