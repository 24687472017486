import { CoreTimeclock, useTimeclock } from "../Timeclock";
import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from "react";

import { FormInstance } from "../../../coretypes/form.dto";
import { QueueTicket } from "../../types/ticketqueue";
import { TTodosTodo } from "../../types/todos.dto";
import { useApi } from "../Api/Api";
import { useRecipeApi } from "../Recipe";
import { AzureTeamsChat } from "../../types/azure";
import { TicketContext } from "./TicketProvider.common";



export const TicketProvider :React.FC<PropsWithChildren & { ticket: QueueTicket , refresh?: number }> = ( { ticket , children , refresh : parentRefresh}) => {
    const { activePunches } = useTimeclock();
    const { actions } = useRecipeApi();
    const api = useApi();
    const [ refreshTick , setRefreshTick ] = useState(1);
    const [todos, setTodos] = useState<TTodosTodo[]>([]);
    const [forms, setForms] = useState<FormInstance[]>([]);
    const [chats , setChats ] = useState<AzureTeamsChat[]>([]);
    const refresh = () => {
        setRefreshTick( refreshTick * -1 );
    }
    useEffect( () => {
        refresh();
    }  , [ JSON.stringify( actions)])

    useEffect( () => {
        let loaded = true;
        api.get<AzureTeamsChat[]>(`/autotask/ticket/${ticket.id}/chats`).then( ( { data } ) => {
            if( loaded ){
                setChats( data );
            }
        });
        api.get<TTodosTodo[]>(`/todos/ticket/${ticket.id}`).then( ( { data } ) => {
            if( loaded ){
                setTodos( data );
            }
        });
        api.get<FormInstance[]>(`/forms/ticket/${ticket.id}`).then( ( { data } ) => {
            if( loaded ){
                setForms( data );
            }
        });
        return () => {
            loaded = false;
        }
    } , [api.ready , refreshTick , parentRefresh , ticket.id ]);

    useEffect( () => {
        refresh();
    } , [parentRefresh])
    const ticketPunch = useMemo(() => {
        if( !ticket ) return null;
        return activePunches.find( pv => pv.ticketId === ticket.id ) || null;
    }, [JSON.stringify(activePunches) , `${ticket?.id}`])

    return (
        <TicketContext.Provider value={{
            ticket , ticketPunch , todos , refresh , forms ,chats
        }}>{children}</TicketContext.Provider>
    )
}