import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useUser } from "../User";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, WatchQueryFetchPolicy } from '@apollo/client';
import { getApiEndpoint } from "../Api/api.utils";
import { setContext } from '@apollo/client/link/context';
import { persistCache } from 'apollo3-cache-persist';

export const Apollo : FC<PropsWithChildren> = ({children}) => {
    const [client, setClient] = useState<ApolloClient<any> | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const API_ENDPOINT = getApiEndpoint();
    const user = useUser();
    
    // Initialize cache and apollo client
    useEffect(() => {
        const setupCache = async () => {
            if (!user?.token) return;
            
            setIsLoading(true);
            const cache = new InMemoryCache();
            
            try {
                // Persist cache to localStorage
                await persistCache({
                    cache,
                    storage: window.localStorage,
                    maxSize: 1048576, // 1MB
                    debug: process.env.NODE_ENV === 'development',
                });
                
                const authLink = setContext((_, { headers }) => {
                    return {
                      headers: {
                        ...headers,
                        authorization: user.token ? `Bearer ${user.token}` : '', // Add the authorization header
                      },
                    };
                });
                
                const httpLink = createHttpLink({
                    uri: `${API_ENDPOINT}/graphql`, // Your GraphQL endpoint
                    credentials: 'same-origin', // Set credentials option
                });
                
                const newClient = new ApolloClient({
                    cache,
                    link: authLink.concat(httpLink),
                    defaultOptions: {
                        watchQuery: {
                            fetchPolicy: 'network-only',
                            notifyOnNetworkStatusChange: true,
                        },
                        query: {
                            fetchPolicy: 'network-only',
                            notifyOnNetworkStatusChange: true,
                        },
                    },
                });
                
                setClient(newClient);
            } catch (error) {
                console.error('Error setting up Apollo cache:', error);
            } finally {
                setIsLoading(false);
            }
        };
        
        setupCache();
    }, [API_ENDPOINT, user?.token]);

    if ( !client) {
        return <></>;
    }
    
    return <ApolloProvider client={client}>
        {children}
    </ApolloProvider>
}