import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetScheduleInstancesQueryVariables = Types.Exact<{
  users?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  startDateTime: Types.Scalars['Date']['input'];
  endDateTime: Types.Scalars['Date']['input'];
}>;


export type GetScheduleInstancesQuery = { __typename?: 'Query', findScheduleInstancePaginated: { __typename?: 'PaginatedScheduleInstance', data?: Array<{ __typename?: 'ScheduleInstance', id?: number | null, startDateTime?: any | null, endDateTime?: any | null, available?: boolean | null, coreUserId?: string | null, coreGroupId?: number | null, title?: string | null, schedulePlanId?: number | null, overidePlan?: boolean | null, azureCalendarId?: string | null, scheduleInstanceDay?: string | null, lockedDateTime?: boolean | null, lockedUser?: boolean | null, conflictDetected?: boolean | null, schedulerLog?: string | null, CoreUser?: { __typename?: 'CoreUsers', email?: string | null } | null, SchedulePlan?: { __typename?: 'SchedulePlan', name?: string | null } | null, AzureCalendar?: { __typename?: 'AzureCalendar', subject?: string | null, mailbox: string, start: any, end: any, webLink?: string | null } | null }> | null } };


export const GetScheduleInstancesDocument = gql`
    query GetScheduleInstances($users: [String!], $startDateTime: Date!, $endDateTime: Date!) {
  findScheduleInstancePaginated(
    coreUserId: {in: $users}
    startDateTime: {lte: $endDateTime}
    endDateTime: {gte: $startDateTime}
    limit: 10000
  ) {
    data {
      id
      startDateTime
      endDateTime
      available
      coreUserId
      coreGroupId
      title
      schedulePlanId
      overidePlan
      azureCalendarId
      scheduleInstanceDay
      lockedDateTime
      lockedUser
      CoreUser {
        email
      }
      conflictDetected
      overidePlan
      schedulerLog
      SchedulePlan {
        name
      }
      AzureCalendar {
        subject
        mailbox
        start
        end
        webLink
      }
    }
  }
}
    `;

/**
 * __useGetScheduleInstancesQuery__
 *
 * To run a query within a React component, call `useGetScheduleInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduleInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduleInstancesQuery({
 *   variables: {
 *      users: // value for 'users'
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *   },
 * });
 */
export function useGetScheduleInstancesQuery(baseOptions: Apollo.QueryHookOptions<GetScheduleInstancesQuery, GetScheduleInstancesQueryVariables> & ({ variables: GetScheduleInstancesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduleInstancesQuery, GetScheduleInstancesQueryVariables>(GetScheduleInstancesDocument, options);
      }
export function useGetScheduleInstancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduleInstancesQuery, GetScheduleInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduleInstancesQuery, GetScheduleInstancesQueryVariables>(GetScheduleInstancesDocument, options);
        }
export function useGetScheduleInstancesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetScheduleInstancesQuery, GetScheduleInstancesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetScheduleInstancesQuery, GetScheduleInstancesQueryVariables>(GetScheduleInstancesDocument, options);
        }
export type GetScheduleInstancesQueryHookResult = ReturnType<typeof useGetScheduleInstancesQuery>;
export type GetScheduleInstancesLazyQueryHookResult = ReturnType<typeof useGetScheduleInstancesLazyQuery>;
export type GetScheduleInstancesSuspenseQueryHookResult = ReturnType<typeof useGetScheduleInstancesSuspenseQuery>;
export type GetScheduleInstancesQueryResult = Apollo.QueryResult<GetScheduleInstancesQuery, GetScheduleInstancesQueryVariables>;