import { Box, Button, Card, CardActions, CardContent, CardHeader, ListItem, ListItemText } from "@mui/material";

import { AutotaskTodo } from "../../types/autotask.dto";
import { BadgeAndName } from "../TicketPartials/BadgeAndName";
import { FC } from "react";
import { useUser } from "../../providers/User";

export const AutotaskTodoItem: FC<{ todo: AutotaskTodo, onChange?: ( todo: AutotaskTodo ) => any }> = ({ todo, onChange }) => {
    const user = useUser();
    return <Card sx={{marginBottom: 2}} >
        <CardHeader sx={{
            backgroundColor: todo.resources_email === user.email ? 'info.main' : 'background.default',
            color: todo.resources_email === user.email ? 'info.contrastText' : 'text.primary',
            padding: 1
        }} title={<BadgeAndName colorkey={`${todo.resources_email}`} displayName={`${todo.resources_firstName} ${todo.resources_lastName}`} />} />

        <CardContent>
            <Box sx={{whiteSpace: 'pre-line'}}>{todo.activityDescription}</Box>
        </CardContent>
    </Card>
}