import { PropsWithChildren, createContext, useContext } from "react";

interface TableApi {
    refresh:  () => Promise<any>;
}

const TableApiContext = createContext<TableApi>({
    refresh : async () => null
 })

 export const useTableApi = () => useContext( TableApiContext )
 export const TableApiProvider :React.FC<PropsWithChildren & TableApi> = ( {children , ...props }) => {    
    return <TableApiContext.Provider
        value={props}
    >{children}</TableApiContext.Provider>

 }