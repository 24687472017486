
import { AppBar, Avatar, Box, Button, Card, CardContent, CardHeader, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Toolbar , Typography, styled, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CInventoryItemLocation, InventoryAdjustment, InventoryItem } from "./types";
import { useApi } from "../../react-submodules/providers/Api/Api";
import { InventoryAdjustmentForm } from "./InventoryAdjustmentForm";

import { StyledTable } from "../../react-submodules/components/Table";
import { green, red } from "@mui/material/colors";
import { Icon, InlineIcon } from "@iconify/react";
import { InfoDialog } from "./InfoDialog";
const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
}));



const InventoryLocation : FC<{ item : CInventoryItemLocation , refresh: ()=> any  } > = ( {item , refresh} ) =>{
    const [ editMinStock , setEditMinStock ] = useState( false );
    const [ minStock , setMinStock ] = useState( item?.minStock );
    const api = useApi();
    const save = () => {
        api.patch( `/inventorymanagement/inventoryItemLocation/${item.id}`, 
        { minStock}
        ).then( () => {
            refresh();
            setEditMinStock( false );
        })
    };

    return <>
        <Dialog open={editMinStock} onClose={() => {
            setEditMinStock( false );
        }}>
            <DialogTitle>Edit Min Stock</DialogTitle>
            <DialogContent>
                <Box sx={{
                    margin:2
                }}>

                    <TextField value={minStock} type="number" onChange={(e) =>{
                        setMinStock( parseInt( e.target.value ))
                    }} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={()=>{
                    save();
                }}>Save</Button>
            </DialogActions>
        </Dialog>
    
    <Box key={item.locationId}>
    <SectionDevicer title={item.location_name||''} />
    
    <InfoLine label="Quantity" value={`${item?.quantity}`} />
    <InfoLine label="Allocated" value={`${item?.allocated}`} />
    <InfoLine label="Ordered" value={`${item?.ordered}`} />
    <InfoLine label="Min Stock" value={item?.minStock} actions={<>
        <IconButton size="small" onClick={()=>{
            setEditMinStock( true );
        }}><Icon icon="bxs:edit" /></IconButton>
    </>} />
</Box></>
}

const adjustmentColumns = [
    {
     field: 'location_name',
     headerName: 'Location'
    },
    {
        field: 'quantity',
        headerName: 'Qty',
        Element : ({ cell, row} : { row : InventoryAdjustment , cell : string }) => {
            const level = row.status === 'applied' ? 700 : 300;
            const { quantity ,direction} = row;
            return <Chip  size="small" avatar={<Avatar sx={{ color: 'white', bgcolor : direction === 'inbound' ? green[level] : red[level]}}  >
                <Typography color="white">{ direction === 'inbound' ? '+' : '-'}</Typography></Avatar>} label={quantity} />
        }

       },
       {
        field: 'poNumber',
        headerName: 'PO / Ticket'
       },
       {
        field: 'status',
        headerName: 'Status',
        Element : ( { cell , row , refresh , api } : any ) => {            
            if( cell === 'applied'){
                return<Typography color="green"> <InlineIcon icon='mingcute:check-fill' /></Typography>
            }
            return <Button onClick={async () => {
                await api.post(`/inventorymanagement/adjustment/${row.id}/apply`);
                await refresh();
            }} color="success" variant="contained">Apply</Button>
        }
       },
];


const InfoLineValue : FC<{ value? : string | null | number }> = ({ value }) => {
    if( typeof value === "string"){        
        return <Typography sx={{whiteSpace: 'pre'}}>{value}</Typography>
    }
    return <>{value}</>
}

const InfoLine = ( { label , value , actions : Actions } : { label: string, value?: string | null | number , actions?:  JSX.Element } ) => {
    return <Grid container>
    <Grid item xs={4} sx={{fontWeight : 'bold' }}>{label}</Grid>
    <Grid item xs={8}><InfoLineValue value={value} />{Actions}</Grid>

</Grid>
}

const SectionDevicer : FC<{ title : string}> = ({ title }) => {
    const theme = useTheme();
    return <Box sx={{
        background: theme.palette.secondary.main, color: theme.palette.secondary.contrastText, padding: 1, borderRadius: 1

    }}><Typography variant="body1">{title}</Typography></Box>
}

export const InventoryDetails = () => {
    const { id } = useParams<{id: string}>();
    const [ inventory , setInventory] = useState<null| InventoryItem >( null );    
    const [ inventoryLocations , setInventoryLocations ] = useState<CInventoryItemLocation[] | null>( null )
    const [ inventoryAdjustments , setInventoryAdjustments ] = useState<InventoryAdjustment[] | null>( null )
    const [ openAdjustments , setOpenAdjustments ] = useState<InventoryAdjustment[] | null>( null )
    const [ showAddAdjustment ,setShowAddAdjustment ] = useState( false);
    const [ refeshNum , setRefreshNum ] = useState( 1 );
    const refresh = () =>{
        setRefreshNum( -1 * refeshNum);
    }
    const api = useApi();
    const addInventoryAdjustment = ( {...props } : Omit<InventoryAdjustment , 'id' | 'createdAt'> ) => {
        api.post( `/inventorymanagement/adjustment` , props ).then( ( results  ) => {
            setShowAddAdjustment( false );
        } );
    }
    useEffect( () => {
        if( api.ready ){
            api.get( `/inventorymanagement/inventory/${id}` ).then( ( results  ) => {
                setInventory( results.data );
            } );
            api.get( `/inventorymanagement/inventory/${id}/locations` ).then( ( results  ) => {
                setInventoryLocations( results.data );
            } );
            api.get( `/inventorymanagement/inventory/${id}/adjustments` ).then( ( results  ) => {
                setInventoryAdjustments( results.data );
            } );
            api.get( `/inventorymanagement/inventory/${id}/adjustments` , { status : 'pending'} ).then( ( results  ) => {
                setOpenAdjustments( results.data );
            } );
        }
    } , [ id , api.ready , refeshNum ])
    return <Container>
    <AppBar position="static" color="primary" sx={{marginBottom: 2}}>
        <Toolbar variant="dense" >
            <Button sx={{marginRight: 2}} color="secondary" variant="contained" onClick={()=>{
                setShowAddAdjustment( true );
            }}>Add Adjustment</Button>
            
        </Toolbar>
    </AppBar>
    <Dialog open={showAddAdjustment} onClose={()=>{
            setShowAddAdjustment( false );
        }} 
        >
            <DialogTitle  >Add Inventory Adjustment</DialogTitle>
            {showAddAdjustment && <InventoryAdjustmentForm inventoryId={parseInt(id ||'0')} onSubmit={addInventoryAdjustment} />}
        </Dialog>
    <Grid container spacing={2}>
    <Grid item xs={6}><Card>
        <StyledCardHeader title="Info" action={<>{ inventory && <InfoDialog refresh={()=> refresh()} inventory={inventory}/> }</>} />
        <CardContent>
        <InfoLine label="Name" value={inventory?.name} />
        
        <InfoLine label="Customer Price" value={`$${( inventory?.internalCost || 0 ) + (inventory?.upcharge || 0)}`} />
        <InfoLine label="Quantity" value={`${inventory?.quantity}`} />
        <InfoLine label="Description" value={inventory?.description} />
        </CardContent>
    </Card></Grid>
    <Grid item xs={6}><Card>
        <StyledCardHeader title="Per Site" />
        <CardContent>
            {inventoryLocations?.map( item => <InventoryLocation refresh={()=>{refresh()}} key={item.locationId} item={item} />)}
        </CardContent>
    </Card></Grid>
    <Grid item xs={6}><Card>
        <StyledCardHeader title="Open Adjustments" />
        <CardContent>
            { openAdjustments && <StyledTable elementProps={{ api }} refresh={()=>{refresh()}} columns={adjustmentColumns} rows={openAdjustments}  /> }
            
        </CardContent>
    </Card></Grid>
    <Grid item xs={6}><Card>
        <StyledCardHeader title="All Adjustments" />
        <CardContent>
            { inventoryAdjustments && <StyledTable elementProps={{ api }} refresh={()=>{refresh()}} columns={adjustmentColumns} rows={inventoryAdjustments}  /> }
            
        </CardContent>
    </Card></Grid>
    </Grid>
    
    </Container>
}