import { FC } from "react";
import { useGetTicketViewQuery } from "./gql/GetTicketView.generated";
import { Box, CardContent, CardHeader, Card, Typography, useTheme, Grid } from "@mui/material";
import { MarkdownView } from "../../MarkdownView";
import { TicketHeader } from "../TicketPartials/TicketHeader";
import { AnyBadge } from "../AnyBadge";
import { Billable } from "../TicketPartials/Billable";
import { Priority, priorityToColor, priorityToIcon } from "../TicketPartials/Priority";
import { Queue } from "../TicketPartials/Queue";
import { Status } from "../TicketPartials/Status";
import { BadgeAndName } from "../TicketPartials/BadgeAndName";
import { NextActionDate } from "../TicketPartials/NextActionDate";
import { InlineIcon } from "@iconify/react/dist/iconify.js";
import { QueuesToColor, QueuesToIcon } from "../TicketPartials/queues.utils";
import { StatusToColor, StatusToIcon } from "../TicketPartials/status.util";
import { LabeledBox } from "../LabeldBox";

export const TicketView : FC<{ id : number }> = ({ id }) => {
    const { data } = useGetTicketViewQuery({ variables: { id } });
    const ticket = data?.getAutotaskTicketsById;
    return <>
    <Box sx={{
        width: '50vw',
        minHeight: '50vh',
    }}>
    <Card>
    
    <CardContent>
        <TicketHeader title={`${ticket?.ticketNumber} - ${ticket?.title}`} status={ticket?.autotaskTicketStatus?.name || ""}  />
        <Grid container spacing={2} sx={{ paddingBottom: 2, margin: 0, width: 'calc(100% - 20px)' }}>
                            <Grid item xs={12} sm={6} md={4} lg={3} ><Typography><InlineIcon color={StatusToColor(ticket?.autotaskTicketStatus?.name || "")} icon={StatusToIcon( ticket?.autotaskTicketStatus?.name || "" )} />&nbsp;{ticket?.autotaskTicketStatus?.name }</Typography></Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} ><Typography><InlineIcon color={QueuesToColor(ticket?.autotaskTicketQueue?.name || "")} icon={QueuesToIcon( ticket?.autotaskTicketQueue?.name  || "" )} />&nbsp;{ticket?.autotaskTicketQueue?.name }</Typography></Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} >
                                <BadgeAndName  displayName={ticket?.autotaskCompany?.companyName || ''} colorkey={`${ticket?.companyID}` || undefined} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} >
                                <BadgeAndName 
                                    cell={ticket?.contact?.mobilePhone}
                                    email={ticket?.contact?.emailAddress} displayName={`${ticket?.contact?.Contact?.firstName} ${ticket?.contact?.Contact?.lastName}`} colorkey={ticket?.contact?.emailAddress || undefined} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} ><NextActionDate nextActionDate={ticket?.nextActionDate || ""} nextActionTime={ticket?.nextActionTime || 0} nextActionType={ticket?.nextActionType || ""} /></Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} ><Typography><InlineIcon color={priorityToColor( ticket?.priority || 0 )} icon={priorityToIcon(  ticket?.priority || 0 )} /></Typography></Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} ></Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} ></Grid>
                        </Grid>
                        <LabeledBox label="Summary AI"  sx={{ marginTop: 2 }} >
                            <MarkdownView>{data?.getAutotaskTicketsById?.aisummary || ""}</MarkdownView>
                        </LabeledBox>
                        <LabeledBox label="Description"  sx={{ marginTop: 2 }} >
                            <MarkdownView>{data?.getAutotaskTicketsById?.description || ""}</MarkdownView>
                        </LabeledBox>
    </CardContent>
    </Card>
    </Box>
    </>
}