import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Paper, 
  Container, 
  Grid, 
  Button, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Chip,
  Divider,
  Stack,
  CircularProgress,
  Switch,
  FormControlLabel
} from '@mui/material';
import { useGetCoreDeviceSoftwareByIdQuery } from './gql/GetCoreDeviceSoftwareById.generated';
import { useUpdateCoreDeviceSoftwareMutation } from './gql/UpdateCoreDeviceSoftware.generated';
import { MarkdownView } from '../../react-submodules/MarkdownView';
import { MarkdownEditor } from '../../react-submodules/components/MarkdownEditor/MarkdownEditor';
import { FilesView } from '../../react-submodules/components/FilesView/FilesView';
import { Icon } from '@iconify/react';

// Define types for software properties
interface SoftwareTag {
  id?: number;
  name: string;
  autotaskTicketId?: number | null;
  createdByService?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  coredeviceSoftwareId?: number | null;
}

interface SoftwareFile {
  createdDateTime: any;
  createdByCoreUserId?: string | null;
  updatedDateTime: any;
  updatedByCoreUserId?: string | null;
  id?: number | null;
  name?: string | null;
  contentType: string;
  size: number;
  key: string;
  verified?: boolean | null;
  childpart?: boolean | null;
  metadata?: any;
  coredeviceSoftwareId?: number | null;
}

export const SoftwareDetail = () => {
  const params = useParams<{id: string}>();
  const id = parseInt(params.id || '0', 10);
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  
  // Form state for editing
  const [formState, setFormState] = useState({
    description: '',
    ignore: false
  });
  
  const { data, loading : loadingData, error, refetch } = useGetCoreDeviceSoftwareByIdQuery({
    variables: { id },
    fetchPolicy: 'cache-and-network'
  });
  const loading = loadingData && !Boolean(data?.getCoreDeviceSoftwareById.id);
  const [updateSoftware] = useUpdateCoreDeviceSoftwareMutation();
  
  const software = data?.getCoreDeviceSoftwareById;
  
  // Initialize form state when data is loaded
  useEffect(() => {
    if (software) {
      setFormState({
        description: software.description || '',
        ignore: software.ignore || false
      });
    }
  }, [software?.id]);
  
  const handleEditClick = () => {
    // Update form state with current data
    if (software) {
      setFormState({
        description: software.description || '',
        ignore: software.ignore || false
      });
    }
    setEditMode(true);
  };
  
  const handleSave = async () => {
    if (!software) return;
    
    setSaving(true);
    try {
      await updateSoftware({
        variables: {
          id: id,
          description: formState.description,
          ignore: formState.ignore
        }
      });
      
      setEditMode(false);
      refetch();
    } catch (error) {
      console.error('Error updating software:', error);
    } finally {
      setSaving(false);
    }
  };
  
  const handleDescriptionChange = (markdown: string) => {
    setFormState({ ...formState, description: markdown });
  };
  
  const handleIgnoreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, ignore: event.target.checked });
  };
  
  if (loading && !data) {
    return (
      <Container sx={{ mt: 4, mb: 4 }}>
        <Paper sx={{ p: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 5, flexDirection: 'column' }}>
            <CircularProgress sx={{ mb: 2 }} />
            <Typography>Loading software details...</Typography>
          </Box>
        </Paper>
      </Container>
    );
  }
  
  if (error) {
    return (
      <Container sx={{ mt: 4, mb: 4 }}>
        <Paper sx={{ p: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
            <Typography color="error">Error loading software: {error.message}</Typography>
          </Box>
        </Paper>
      </Container>
    );
  }
  
  if (!software) {
    return (
      <Container sx={{ mt: 4, mb: 4 }}>
        <Paper sx={{ p: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
            <Typography>Software not found</Typography>
          </Box>
        </Paper>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 3, position: 'relative' }}>
        {loading && (
          <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center', p: 1, bgcolor: 'rgba(255,255,255,0.8)', zIndex: 1 }}>
            <CircularProgress size={20} sx={{ mr: 1 }} />
            <Typography variant="caption">Refreshing...</Typography>
          </Box>
        )}
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h4">
            {software.name}
            {software.ignore && (
              <Chip 
                label="Ignored" 
                color="warning" 
                size="small" 
                sx={{ ml: 2, verticalAlign: 'middle' }} 
              />
            )}
          </Typography>
          <Button 
            variant="contained" 
            startIcon={<Icon icon="mdi:pencil" />}
            onClick={handleEditClick}
          >
            Edit
          </Button>
        </Box>
        
        <Divider sx={{ mb: 3 }} />
        
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>Vendor</Typography>
            <Typography variant="body1" paragraph>{software.vendor || 'Not specified'}</Typography>
            
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>Install Method</Typography>
            <Typography variant="body1" paragraph>{software.installMethod || 'Not specified'}</Typography>
            
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>Status</Typography>
            <Box sx={{ mb: 2 }}>
              {software.ignore ? (
                <Chip 
                  label="Ignored" 
                  color="warning" 
                  variant="outlined"
                  icon={<Icon icon="mdi:alert-circle-outline" />}
                />
              ) : (
                <Chip 
                  label="Active" 
                  color="success" 
                  variant="outlined"
                  icon={<Icon icon="mdi:check-circle-outline" />}
                />
              )}
            </Box>
            
            {software.aliases && software.aliases.length > 0 && (
              <>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>Aliases</Typography>
                <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 1, mb: 2 }}>
                  {software.aliases.map((alias: string, index: number) => (
                    <Chip key={index} label={alias} variant="outlined" />
                  ))}
                </Stack>
              </>
            )}
            
            {software.Tags && software.Tags.length > 0 && (
              <>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>Tags</Typography>
                <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 1, mb: 2 }}>
                  {software.Tags.map((tag: SoftwareTag) => (
                    <Chip key={tag.id} label={tag.name} color="primary" variant="outlined" />
                  ))}
                </Stack>
              </>
            )}
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>Description</Typography>
            {software.description ? (
              <Box sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: 1, p: 2 }}>
                <MarkdownView size="medium">{software.description}</MarkdownView>
              </Box>
            ) : (
              <Typography variant="body1" paragraph>No description available</Typography>
            )}
          </Grid>
        </Grid>
        
        <FilesView coredeviceSoftwareId={id} />
      </Paper>
      
      {/* Edit Dialog */}
      <Dialog 
        open={editMode} 
        onClose={() => !saving && setEditMode(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Software</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formState.ignore}
                    onChange={handleIgnoreChange}
                    color="warning"
                  />
                }
                label="Ignore this software"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>Description</Typography>
              <MarkdownEditor 
                markdown={formState.description} 
                onChange={handleDescriptionChange} 
                label="Description"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setEditMode(false)} 
            disabled={saving}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSave} 
            variant="contained" 
            color="primary"
            disabled={saving}
            startIcon={saving ? <CircularProgress size={20} /> : undefined}
          >
            {saving ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}; 