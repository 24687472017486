import { io, Socket } from 'socket.io-client';
import { createContext, useContext, useEffect, useState } from 'react';
import { useUser } from '../react-submodules/providers/User';
import { getApiEndpoint } from '../react-submodules/providers/Api/api.utils';

const SocketContext = createContext<Socket | null>(null);

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
    const user = useUser();
    const [ socket , setSocket ] = useState<Socket | null>( null );
    useEffect(()=>{
        const URL = getApiEndpoint( "websockets" );
        if( !user.token ){
            return;
        }
        const socket = io(URL, {
            auth: {
                token: user.token // Use the actual token from user object
            }
        });
        setSocket(socket || null );
    }, [user.token]);
    return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>

}