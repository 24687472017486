import { AutotaskTicketNotes, AutotaskTimeEntries, isTicketTime } from "../../types/autotask.dto"
import { FC, useMemo, useState } from "react"

import { List, TextField, ToggleButtonGroup, ToggleButton, styled } from "@mui/material"
import { NoteItem } from "./NoteItem"
import { TTimelineItem } from "./types"
import { TimelineItem } from "./TimelineItem"
import { useTicket } from "../../providers/TicketProvider"
import { AzureEmail } from "../../types/azure"
import { EMAIL_ICON, EMAIL_ICON_DEFAULT_COLOR, FORM_ICON, FORM_ICON_DEFAULT_COLOR, NOTE_ICON, NOTE_ICON_DEFAULT_COLOR, TIME_ICON, TIME_ICON_DEFAULT_COLOR } from "../../constances/icons"
import { Icon } from "@iconify/react"
import { CoreTimeclock } from "../../providers/Timeclock"

type UsableItems = AutotaskTicketNotes | AutotaskTimeEntries | AzureEmail | CoreTimeclock;

const isTimeclock = ( note : UsableItems ) : note is CoreTimeclock => {
    return ( note as CoreTimeclock ).start !== undefined && ( note as CoreTimeclock ).lastActive !== undefined && ( note as CoreTimeclock ).isDispatchedTicket !== undefined;
}

const isAzureEmail = ( note : UsableItems ) : note is AzureEmail => {
    return ( note as AzureEmail ).bodyHtml !== undefined;
}

const isAutotaskTicketNote = ( note :UsableItems     ) : note is AutotaskTicketNotes | AutotaskTimeEntries => {
    return ( note as AutotaskTicketNotes  ).description !== undefined;
}
const isAutotaskTimeEntry = ( note : UsableItems ) : note is AutotaskTimeEntries => {
    return ( note as AutotaskTimeEntries ).dateWorked !== undefined;
}

const isOgNote = ( note : UsableItems ) : note is AutotaskTicketNotes | AutotaskTimeEntries => {
    return isAutotaskTicketNote( note ) || isAutotaskTimeEntry( note );
}

// Add this styled component
const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    '&.Mui-selected': {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.info.dark,
        },
    },
}));

export const TicketNoteGrid : FC<{
    notes: Array<AutotaskTicketNotes | AutotaskTimeEntries | AzureEmail | CoreTimeclock >
}> = ({notes}) => {
    const { todos ,  forms } = useTicket();
    const [ search , setSearch] = useState('');    
    const [filter, setFilter] = useState<string[]>(['ognote', 'email', 'form' ,'timeclock']);

    
    const handleFilterChange = (
        event: React.MouseEvent<HTMLElement>,
        newFilters: string[],
    ) => {
        setFilter(newFilters);
    };

    const sortedNotes = useMemo( () => {
        const notesCoreItems : Array<TTimelineItem> = notes.map( note => {
            if( isTimeclock( note ) ){
                return {
                    type : 'timeclock',
                    timeclock: note,
                    sortTime : new Date( note.start ).getTime()
                } 
            }
            if( isAzureEmail( note ) ){
                return {
                    type : 'email',
                    email: note,
                    sortTime : new Date( note.createdDateTime ).getTime()
                } 
            }
            if( isOgNote( note ) ){
                return {
                    type : 'ognote',
                    note,
                    sortTime : new Date( note.createDateTime ).getTime()
                } 
            }
            return null;
        } 
    ).filter( note => note !== null ) as Array<TTimelineItem>;


        const formItems : Array<TTimelineItem> = forms.map( form => ({
            type : 'form',
            form,
            sortTime : new Date( form.createdAt ).getTime()
        }));
        return [...notesCoreItems , ...formItems ].sort( ( a , b ) => {
            return b.sortTime - a.sortTime;
        })
    } , [JSON.stringify( notes )] );

    const filteredNotes = useMemo( () => {
        return sortedNotes.filter( note => {
            let type : any = note.type;
            if( type === 'ognote' && note.note ){
                type = isTicketTime( note.note ) ? 'timeentry' : 'ognote';
            }
            const parts = search.toLowerCase().split(" ");
            const json = JSON.stringify( note , null , 2 ).toLowerCase();
            const typeMatch = filter.includes(type);
            if( !typeMatch ){
                return false;
            }
            return parts.every( part => json.includes( part ) ) && typeMatch;
        });
    } , [search, sortedNotes, filter]);

    return <>
    <TextField label="Search" value={search} onChange={(e) => setSearch(e.target.value)} fullWidth size="small" />
    <ToggleButtonGroup
        value={filter}
        onChange={handleFilterChange}
        aria-label="note type filter"
        size="small"
        sx={{ mt: 2, mb: 2 }}
    >
        <StyledToggleButton value="timeentry" aria-label="time entry">
            <Icon icon="material-symbols:time-auto" color={filter.includes('timeentry') ? 'white' : TIME_ICON_DEFAULT_COLOR} />
        </StyledToggleButton>
        <StyledToggleButton value="ognote" aria-label="note">
            <Icon icon={NOTE_ICON} color={filter.includes('ognote') ? 'white' : NOTE_ICON_DEFAULT_COLOR} />
        </StyledToggleButton>
        <StyledToggleButton value="email" aria-label="email">
            <Icon icon={EMAIL_ICON} color={filter.includes('email') ? 'white' : EMAIL_ICON_DEFAULT_COLOR} />
        </StyledToggleButton>
        <StyledToggleButton value="form" aria-label="form">
            <Icon icon={FORM_ICON} color={filter.includes('form') ? 'white' : FORM_ICON_DEFAULT_COLOR} />
        </StyledToggleButton>
        <StyledToggleButton value="timeclock" aria-label="timeclock">
            <Icon icon={TIME_ICON} color={filter.includes('timeclock') ? 'white' : TIME_ICON_DEFAULT_COLOR} />
        </StyledToggleButton>
    </ToggleButtonGroup>
    <List>
        {filteredNotes.map( ( item ) => <TimelineItem key={`${item.type}-${item.note?.id || item.form?.id}`} item={item} />
        )}
    </List>
    </>
}