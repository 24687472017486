export type AutotaskCompany = {
    "id": number,
    "coreCompanyId": number,
    "additionalAddressInformation"?: string | null;
    "address1"?: string | null;
    "address2"?: string | null;
    "alternatePhone1"?: string | null;
    "alternatePhone2"?: string | null;
    "apiVendorID"?: number | null;
    "assetValue": null,
    "billToCompanyLocationID"?: string | null;
    "billToAdditionalAddressInformation"?: string | null;
    "billingAddress1"?: string | null;
    "billingAddress2"?: string | null;
    "billToAddressToUse"?: string | null;
    "billToAttention"?: string | null;
    "billToCity"?: string | null;
    "billToCountryID"?: number | null;
    "billToState"?: string | null;
    "billToZipCode"?: string | null;
    "city"?: string | null;
    "classification"?: string | null;
    "companyCategoryID": number,
    "companyName"?: string | null;
    "companyNumber"?: string | null;
    "companyType": number,
    "competitorID": null,
    "countryID": number,
    "createDate"?: string | null;
    "createdByResourceID": number,
    "currencyID": 1,
    "fax": "8339580142",
    "impersonatorCreatorResourceID": null,
    "invoiceEmailMessageID": 1,
    "invoiceMethod": null,
    "invoiceNonContractItemsToParentCompany": null,
    "invoiceTemplateID": 103,
    "isActive"?: boolean;
    "isClientPortalActive"?: boolean;
    "isEnabledForComanaged"?: boolean;
    "isTaskFireActive"?: boolean;
    "isTaxExempt"?: boolean;
    "lastActivityDate"?: string | null;
    "lastTrackedModifiedDateTime"?: string | null;
    "marketSegmentID": null,
    "ownerResourceID": number,
    "parentCompanyID": null,
    "phone": "8777420770",
    "postalCode": "54603",
    "purchaseOrderTemplateID": null,
    "quoteEmailMessageID": number,
    "quoteTemplateID": number;
    "sicCode": "",
    "state": string,
    "stockMarket": string,
    "stockSymbol": string,
    "surveyCompanyRating": null,
    "taxID": "",
    "taxRegionID": null,
    "territoryID": null,
    "webAddress"?: string | null;
    "userDefinedFields": []
}

export type AutotaskContact =  {
    "id": number,
    "additionalAddressInformation"?: string | null;
    "addressLine"?: string | null;
    "addressLine1"?: string | null;
    "alternatePhone"?: string | null;
    "apiVendorID": null,
    "bulkEmailOptOutTime": null,
    "city": "La Crosse",
    "companyID": number;
    "companyLocationID": null,
    "countryID": number;
    "createDate"?: string | null;
    "emailAddress"?: string | null;
    "emailAddress2": null,
    "emailAddress3": null,
    "extension": "",
    "externalID": "",
    "facebookUrl": "",
    "faxNumber"?: string | null;
    "firstName"?: string | null;
    "impersonatorCreatorResourceID": null,
    "isActive": number;
    "isOptedOutFromBulkEmail": boolean;
    "lastActivityDate"?: string | null;
    "lastModifiedDate"?: string | null;
    "lastName"?: string | null;
    "linkedInUrl"?: string | null;
    "middleInitial": null,
    "mobilePhone"?: string | null;
    "namePrefix": 1,
    "nameSuffix": null,
    "note": "",
    "receivesEmailNotifications": boolean;
    "phone"?: string | null;
    "primaryContact": boolean;
    "roomNumber": "",
    "solicitationOptOut": boolean;
    "solicitationOptOutTime": null,
    "state": "WI",
    "surveyOptOut": boolean;
    "title"?: string | null;
    "twitterUrl"?: string | null;
    "zipCode"?: string | null;
    "userDefinedFields": []
}

export type AutotaskTicketNotes = {
    "id": number;
    "createDateTime": string;
    "createdByContactID": number | null;
    "creatorResourceID": number | null;
    "description": string;
    "impersonatorCreatorResourceID": number | null;
    "impersonatorUpdaterResourceID": number | null;
    "lastActivityDate": string;
    "noteType": number | null;
    "publish": number | null;
    "ticketID": number | null;
    "title": string;
    "contacts_firstName": string | null;
    "contacts_lastName": string | null;
    "contacts_emailAddress": string | null;
    "resources_firstName": string | null;
    "resources_lastName": string | null;
    "resources_email": string | null;
};

export const isTicketNote = ( obj : AutotaskTicketNotes | AutotaskTimeEntries ) : obj is AutotaskTicketNotes => {
    return !obj.hasOwnProperty('dateWorked');
}

export type AutotaskTimeEntries  = {
    id: number;
    billingApprovalDateTime: null | number;
    billingApprovalLevelMostRecent: number;
    billingApprovalResourceID: null | number;
    billingCodeID: null | number;
    contractID: null | number;
    contractServiceBundleID: null | number;
    contractServiceID: null | number;
    createDateTime: string;
    creatorUserID: number;
    dateWorked: string;
    endDateTime: null | string;
    hoursToBill: number;
    hoursWorked: number;
    impersonatorCreatorResourceID: null | number;
    impersonatorUpdaterResourceID: null | number;
    internalBillingCodeID: null | number;
    internalNotes: null | string;
    isInternalNotesVisibleToComanaged: boolean;
    isNonBillable: boolean;
    lastModifiedDateTime: string;
    lastModifiedUserID: number;
    offsetHours: number;
    resourceID: number;
    roleID: number;
    showOnInvoice: boolean;
    startDateTime: null | string;
    summaryNotes: null | string;
    taskID: null | number;
    ticketID: null | number;
    timeEntryType: number;    
    "resources_firstName": string | null;
    "resources_lastName": string | null;
    "resources_email": string | null;
}
export const isTicketTime = ( obj : AutotaskTicketNotes | AutotaskTimeEntries ) : obj is AutotaskTimeEntries => {
    return !obj.hasOwnProperty('noteType');
}

export type AutotaskTodo = {
    id: number;
    actionType: number;
    activityDescription: string;
    assignedToResourceID: number | null ;
    companyID: number | null;
    completedDate: null | Date;
    contactID: number | null;
    contractID: null | number;
    createDateTime: Date;
    creatorResourceID: number | null;
    endDateTime:  Date | null;
    impersonatorCreatorResourceID: null | number;
    lastModifiedDate: Date;
    opportunityID: null | number;
    startDateTime: Date | null;
    ticketID: number | null;
    "resources_email": string;
    "resources_firstName": string;
    "resources_lastName": string;
}