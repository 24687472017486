import { Grid, MenuItem, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { Alerts } from "./pages/Alerts";
import { CoreUser } from "../../react-submodules/types/core";
import { MainOverview } from "./pages/MainOverview/MainOverview";
import { OldManagerView } from "./pages/OldManagerView";
import { QueueTicket } from "../../react-submodules/types/ticketqueue";
import { StyledTable } from "../../react-submodules/components/Table"
import { TicketApiProvider } from "../../react-submodules/providers/TicketApiProvider";
import { TicketRouting } from "./pages/TicketRouting/TicketRouting";
import { TimeclockProvider } from "../../react-submodules/providers/Timeclock";
import { UserCard } from "./UserCard";
import { useApi } from "../../react-submodules/providers/Api/Api";
import { useMounted } from "../../react-submodules/hooks/useMounted";
import { useQueueParams } from "../../react-submodules/hooks/useQueueParams";

const tabs : Array<{
    key: string;
    label: string;
    component: JSX.Element;
}> = [
    {
        key: 'alerts',
        label: "Alerts",
        component: <Alerts />
    },
    {
        key: 'overview',
        label: 'Overview',
        component: <MainOverview />
    },
    {
        key: 'old_manager',
        label: 'Old Manager View',
        component: <OldManagerView />
    },
    {
        key: 'ticket_routing',
        label: 'Ticket Routing',
        component: <TicketRouting />
    }
];

export const ManagerView = () => {  

    const [ tab , setTab] = useState( 'alerts' );
    const SelectedTabComponent = useMemo( () => {
        return tabs.find( ( t ) => t.key === tab )?.component || <></>;
    } , [ tab ]);
    
    return <TicketApiProvider queueId="all" getNextTicket={()=>{}}>
        <TimeclockProvider>
    <Typography variant="h4">Manager View</Typography>
    <Tabs value={tab} onChange={(e,v)=>setTab(v)} >
                {tabs.map( ( t ) => <Tab key={t.key} value={t.key} label={t.label} />)}
            </Tabs>
            {SelectedTabComponent}
            </TimeclockProvider>
            </TicketApiProvider>
}