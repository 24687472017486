import { PropsWithChildren, useEffect, useState } from "react";
import KeycloakFactory from "keycloak-js";
import { KeycloakContext } from "./Keycloak.common";

export const KeycloakProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [keycloak, setKeycloak] = useState<any>(null);
    const [ready, setReady] = useState(false);
    const [token, setToken] = useState('');
    const [tokenParsed, setTokenParsed] = useState<Record<string, any>>({});
    const [initialized, setInitialized] = useState(false);

    // Initialize Keycloak only once within the component lifecycle
    useEffect(() => {
        if (initialized) return;
        
        const kc = new KeycloakFactory({
            url: 'https://login.enlightenedmsp.com/auth/',
            realm: 'enlightenedmsp',
            clientId: 'core-frontend'
        });
        
        kc.init({
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
            checkLoginIframe: false // Disable iframe checking to reduce errors
        }).then(authenticated => {
            setKeycloak(kc);
            setReady(authenticated || false);
            if (authenticated) {
                setToken(kc.token || '');
                setTokenParsed(kc.tokenParsed || {});
            }
        }).catch(error => {
            console.error("Keycloak initialization error:", error);
            // After error, still set the keycloak instance so login can be tried again
            setKeycloak(kc);
        }).finally(() => {
            setInitialized(true);
        });
    }, [initialized]);

    // Handle login timeout
    useEffect(() => {
        if (!keycloak || initialized && ready) return;
        
        const loginTimeout = setTimeout(() => {
            if (!ready && keycloak && !keycloak.authenticated) {
                keycloak.login();
            }
        }, 1500);
        
        return () => clearTimeout(loginTimeout);
    }, [ready, keycloak, initialized]);

    // Token refresh and monitoring
    useEffect(() => {
        if (!keycloak) return;
        
        let loaded = true;
        
        // Setup token expiry handler
        keycloak.onTokenExpired = () => {
            if (keycloak.updateToken) {
                keycloak.updateToken(60 * 15);
            }
        };
        
        // Periodic check for token state
        const iv = setInterval(() => {
            if (!loaded) return;
            
            if (keycloak.authenticated !== ready) {
                setReady(keycloak.authenticated || false);
            }
            
            if (keycloak.token !== token) {
                setToken(keycloak.token || '');
            }
            
            if (JSON.stringify(keycloak.tokenParsed) !== JSON.stringify(tokenParsed)) {
                setTokenParsed(keycloak.tokenParsed || {});
            }
            
            // Refresh token if it's about to expire
            const now = Math.round(new Date().getTime() / 1000);
            if ((keycloak.tokenParsed?.exp || 0) - (now + 60) <= 0) {
                keycloak.updateToken && keycloak.updateToken(60 * 15);
            }
        }, 1000);
        
        return () => {
            loaded = false;
            clearInterval(iv);
        };
    }, [keycloak, token, ready, tokenParsed]);

    const login = () => {
        keycloak && keycloak.login();
    };
    
    const logout = () => {
        keycloak && keycloak.logout();
    };

    return (
        <KeycloakContext.Provider value={{ login, logout, ready, token, tokenParsed }}>
            {children}
        </KeycloakContext.Provider>
    );
};

export default KeycloakProvider;