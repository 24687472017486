import { AzureEmail } from "../../types/azure"
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material"
import { FC } from "react"
import { AnyBadge } from "../AnyBadge"
import { HtmlView } from "../HtmlView"
import { EmailView } from "../EmailView/EmailView"
import { Icon } from "@iconify/react"
import { EMAIL_ICON, EMAIL_ICON_DEFAULT_COLOR, EMAIL_ICON_WITH_ATTACHMENTS } from "../../constances/icons"


const EmailBadge : FC<{email : AzureEmail}> = ({email}) => {
    const from = email.participants.find( p => p.userRole === "from" );
    
    return <AnyBadge name={from?.emailAddress || "Unknown"} colorkey={from?.emailAddress || "Unknown"} />
    
}

export const EmailItem = ( { email } : { email : AzureEmail } ) => {
    const attachments = email.attachments.filter( a => a.isInline === false );
    return <Accordion onChange={() => {
    }}>
        <AccordionSummary>
                <Box sx={{ display: 'flex', alignItems: 'center' , paddingRight: 1 , color : EMAIL_ICON_DEFAULT_COLOR }}>
                    <Icon icon={attachments.length > 0 ? EMAIL_ICON_WITH_ATTACHMENTS : EMAIL_ICON} />
                </Box>
                    <EmailBadge email={email}  /> 
                
                <Typography sx={{ paddingLeft: 1, whiteSpace: 'nowrap' }}>
                    {new Date(email.createdDateTime).toLocaleString('en-US', { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(",", "")}
                </Typography>
                <Typography sx={{ paddingLeft: 1 , fontWeight: 'bold'}}>{email.subject}</Typography></AccordionSummary>
        
        <AccordionDetails><EmailView email={email} /></AccordionDetails>
    </Accordion>
}