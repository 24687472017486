import { FC, useState } from "react";
import { IconButton, useTheme } from "@mui/material";

import { Icon } from "@iconify/react";
import { MyTicketSearch } from "../../react-submodules/components/MyTicketSearch";

export const TicketSearch : FC = () => {
    const theme = useTheme();
    const [ addTicket , setAddTicket ] = useState<boolean>(false);
    return <>
    <MyTicketSearch open={addTicket} onClose={()=>{
        setAddTicket( false)
    }}  />
    <IconButton  onClick={()=>{
        setAddTicket( true );
    }}><Icon color={theme.palette.primary.contrastText}  icon="solar:card-search-bold"  /></IconButton>
    </>
}