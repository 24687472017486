import { Box, Switch } from "@mui/material";
import { useEffect, useState } from "react";

import { useUserProfile } from "../../react-submodules/providers/UserProfileProvider";

export const ActiveQueueToggle = () => {
    const { userProfile , updateUserProfile , refresh } = useUserProfile();   
    const [ dispatchPaused , setDispatchPaused ] = useState<boolean | null>( null );
    const setIsDispatchable = ( isDispatchable : boolean ) => {
        setDispatchPaused( !isDispatchable);
        updateUserProfile( { isDispatchable });
    } 
    useEffect( () => {
        if( !userProfile ) return;
        
        if( dispatchPaused !== !userProfile?.isDispatchable ){
            setDispatchPaused( !userProfile?.isDispatchable );
        } 
    }, [ userProfile?.isDispatchable ]);

    return <Box>
    <Switch color={dispatchPaused === !userProfile?.isDispatchable ? 'secondary' : 'warning' } disabled={dispatchPaused === null} checked={!dispatchPaused} onChange={(e)=>{                         
        setIsDispatchable( e.target.checked );
        // setDispatchPaused( !e.target.checked )
    }} />
</Box>
}