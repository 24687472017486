import { Alert, Box, Button, ButtonGroup, Card, CardContent, CardHeader, Grid, IconButton, MenuItem, TextField, Typography } from "@mui/material"
import { Handle, Position, useEdges, useEdgesState, useNodes } from "reactflow"
import { useNodeContext } from "../../useNodeContext";
import { useCallback, useEffect, useState } from "react";
import { z } from "zod";
import { JsonSchema7Type, zodToJsonSchema } from "zod-to-json-schema";
import { useNodeDataProperty, useRecipie } from "../../../../providers/Recipies.provider";
import { v4 as uuidv4 } from 'uuid';
import { Icon } from "@iconify/react";


const FIRST_EVENT_POSTITION = 160;
const ADDITIONAL_EVENT_POSTITION = FIRST_EVENT_POSTITION - 32;
export const SingleTriggerNode = ({ id , data } : {id : string , data : any  } ) => {
  
    const [ target , setTarget ] = useNodeDataProperty( id , 'target' , 'ticket' );
    const handleCopy = () => {
      navigator.clipboard.writeText( JSON.stringify( { defaultData : data , id : 'singleTrigger' , title : data.title } , null , 2 ) );
    }
    return <Box>
        <Card sx={{maxWidth: '600px' , width: '400px' , position: 'relative'}}>
            <CardHeader className="drag-handle" title="Single Trigger Node"     action={
          <IconButton aria-label="copy" onClick={handleCopy}>
            <Icon icon="mdi:content-copy" />
          </IconButton>
        } />
            <CardContent sx={{ position: 'relative' }}>
                 <Grid container spacing={2}>
                    
                    <Grid item xs={12}>
                        <TextField fullWidth size="small" label="Target" value={target} onChange={(e) => setTarget(e.target.value)} select>
                            <MenuItem value="ticket">Ticket</MenuItem>
                            <MenuItem value="email">Email</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={8}>
                    <Typography>This will make sure that this event is only processed once</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Alert severity="warning">If you delete this node and recreate it, all events will be considered new and processed again</Alert>
                    </Grid>
                 </Grid>
                 <Box sx={{ position: 'absolute' , bottom: FIRST_EVENT_POSTITION - 4, right: 8 }}>First Event</Box>
                 <Box sx={{ position: 'absolute' , bottom: ADDITIONAL_EVENT_POSTITION - 4, right: 8 }}>All other events</Box>
            </CardContent>
        </Card>
        <Handle
          type="target"
          position={Position.Left}
          id={`handle-target`}
        />
          <Handle
            type="source"
            position={Position.Right}
            id={`handle-source-first`}
            style={{ bottom: FIRST_EVENT_POSTITION  , top: 'auto' }}
          />
            <Handle
            type="source"
            position={Position.Right}
            id={`handle-source-additional`}
            style={{ bottom: ADDITIONAL_EVENT_POSTITION  , top: 'auto' }}
          />
          
    </Box>
}
