import { Dialog, DialogContent, DialogTitle, Grid, Step, StepButton, Stepper } from '@mui/material';
import { FC, useEffect, useState} from 'react';
import { SelectComputer } from './SelectComputer';
import { MinimumInventory } from '../../types/inventory.dto';
import { SelectAccessories } from './SelectAccessories';
import { Review } from './Review';
import { DeviceQueryItem } from '../../types/dattormm.dto';
import { useApi } from '../../providers/Api/Api';


export type NewComputerType = 'laptop' | 'desktop';


export type Accessories = 'monitor' | 'dock' | 'cable';


export const NewComputerWizard : FC<{
    open: boolean,
    
    enableDownload?: boolean;
    companyID: number;
    device?: DeviceQueryItem;
    allowMicro?: boolean;
    onClose: (ticketNumber?: string ) => any;
}> = ({
    open , onClose , enableDownload , companyID, device , allowMicro
}) => {
    const type = device?.category?.toLowerCase() === "laptop" ? 'laptop' : 'desktop';
    
    const [activeStep, setActiveStep] = useState(0);    
    const [ computerId , setComputerId ] = useState<number| null>( null );
    const [ inventory , setInventory ] = useState<MinimumInventory[] | null >(null);

    const [ accessories , setAccessories ] = useState< Record< Accessories , number > >({
        monitor: 0,
        dock: 0,
        cable: 1 
    });
    const updateAccessory = ( key : Accessories , value : number ) => {
        setAccessories(( acc)=>{
            return {
                ...acc,
                [key] : value 
            }
        })
    }

    const api = useApi();
    useEffect( () => {
        api.get('/inventorymanagement/inventory/simple').then( ( { data}) => {
            setInventory( data );
        })
    } , [api.ready])
    
    return <Dialog open={open} maxWidth="lg" fullWidth onClose={()=>{
        onClose();
    }}>
        <DialogTitle>New Computer</DialogTitle>
        <DialogContent>
        <Grid container sx={{minWidth: '500px', paddingLeft: 2 , paddingRight: 2, paddingBottom: 2}} >
            <Grid item sm={12}>
                <Stepper nonLinear activeStep={activeStep}>        
                    <Step  key="Computer" completed={computerId !== null }>
                        <StepButton color="green" onClick={() => setActiveStep(0)}>
                            Computer
                        </StepButton>
                    </Step>
                    <Step key="Accessories" completed={false}>
                        <StepButton color="inherit" onClick={() => setActiveStep(1)}>
                        Accessories
                        </StepButton>
                    </Step>
                    <Step key="Review" completed={false}>
                        <StepButton color="inherit" onClick={() => setActiveStep(2)}>
                        Review
                        </StepButton>
                    </Step>
                </Stepper>
            </Grid>
            <Grid item sm={12}>
                { activeStep === 0 && <>
                { inventory && <SelectComputer
                allowMicro={allowMicro}
                    onSelect={(id) => { 
                        setComputerId( id ); 
                        setActiveStep(1 );
                    }}
                    type={type || 'desktop'}
                    inventory={inventory}
                /> }</>}
                { activeStep === 1 && inventory && computerId && <SelectAccessories 
                    computerId={computerId}
                    inventory={inventory}
                    accessories={accessories}
                    updateAccessory={updateAccessory}
                    next={()=>{ setActiveStep( 2 )}}
                />}
                { activeStep === 2 && inventory && computerId && <Review
                    device={device}
                    enableDownload={enableDownload}
                    computerId={computerId}
                    inventory={inventory}
                    accessories={accessories}
                    updateAccessory={updateAccessory}
                    companyID={companyID}
                    onClose={onClose}
                />}
            </Grid>
        </Grid>
        </DialogContent>
    </Dialog>
}