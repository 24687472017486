import { FC, useEffect , useState ,useMemo  } from "react";
import { useApi } from "../../providers/Api/Api"
import { CoreBillingDTO, CoreBillingTicketItemDTO } from "../../types/billing.dto";
import { useParams } from "react-router-dom";
import dayjs, { Dayjs } from 'dayjs';
import { StyledTable, TableColumnDef } from "../../components/Table";
import { Button , Dialog , DialogTitle , List , ListItem , ListItemText , Paper , Box, Tab, Tabs } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ContractOverview } from "../../components/ContractOverview/ContractOverview";
import { CoreServices } from "../../types/services.dto";
const hoursToTime = ( time : number ) => {
    const hours = Math.floor( time );
    const minutes = Math.floor( (time - hours ) * 60 );
    return `${hours}:${( minutes / 100 ).toFixed(2).replace("0." , "")}`
}

const columns : TableColumnDef<CoreBillingTicketItemDTO>[] = [
    {
        field: 'at_ticketNumber',
        headerName: 'Ticket #',
        flex: 1
    },
    {
        field: 'at_title',
        headerName: 'Ticket #',
        flex: 1
    },
    {
        field: 'billing.isBillable',
        headerName: 'Billable',
        flex: 1,
        Element: ( { row } ) => {
            return <>{row.billing.isBillable ? 'Yes' : 'No'}</>
        }
    },
    {
        field: 'hours_worked',
        headerName: 'Hours',
        flex: 1,
        Element: ( { cell } ) => {
            return <>{hoursToTime(cell)}</>
        }
    },
    {
        field: 'cost',
        headerName: 'Cost',
        flex: 1,
        Element: ( { cell } ) => {
            return <>${cell.toFixed(2)}</>
        }
    },
    {
        field: 'entries',
        headerName: 'Entries',
        flex: 1,
        Element: ( { cell , row  } ) => {
            const [open, setOpen] = useState(false);
            return <>
                <Dialog open={open} onClose={()=>{
                    setOpen(false);
                }}>
                    <DialogTitle>{row.at_ticketNumber}</DialogTitle>
                    <List>
                        {row.entries.map( ( entry ) => {
                            return <ListItem>
                                <ListItemText primary={`${entry.ar_firstName} ${entry.ar_lastName} ${hoursToTime(entry.ate_hoursWorked)}`} secondary={`${new Date(entry.ate_startDateTime).toLocaleDateString()} ${new Date(entry.ate_startDateTime).toLocaleTimeString()}`} />
                            </ListItem>
                        })}
                    </List>
                </Dialog>
                <Button onClick={()=>{
                    setOpen(true);
                }}>View</Button>
            </>
        }
    }
]


const TicketSummary : FC<{
    tickets: CoreBillingTicketItemDTO[] | null
}> = ({ tickets }) => {

    return <>
    {tickets && <StyledTable
            title="Tickets"
            columns={columns}
            rows={tickets || []}
        /> }</>
}


export const Billing = () => {
    const { id } = useParams<{id: string}>();
    const [ month , setMonth  ] = useState<string>(new Date().toISOString().substr(0,7));
    const api = useApi();
    const [ tab , setTab ] = useState<'summary' | 'managed' | 'tickets'>('summary');
    const [ tickets, setTickets ] = useState<CoreBillingTicketItemDTO[] | null >( null );
    useEffect( () => {
        if( api.ready){
            api.get<CoreBillingDTO>( `/core/billing/${id}` , { month }).then( ( { data } ) => {
                setTickets( data.tickets );
            })
        }
    } , [ api.ready , month ] );
    const [ services , setServices ] = useState<null |  Record<string,CoreServices> >( null );

    useEffect( () => {
        if( api.ready ){
            api.get<{ data : CoreServices}>( `/core/services/${id}` ).then( ( results ) => {
                setServices( results.data);
            } )
        }
    } , [ id , api.ready ]);
    
    const totalManaged = useMemo( () => {
        if( services === null ){
            return 0;
        }
        return Object.values( services ).reduce( ( prev , service ) => {
            if( typeof service.totalCost === "number" ){
                return prev + service.totalCost;
            }
            return prev + ( service.units * service.unitPrice );
        } , 0 );
    } , [ JSON.stringify(services) ] );
    const totalLaborCost = useMemo( () => {
        return tickets?.reduce( ( prev , ticket ) => {
            return prev + (ticket.cost || 0 );
        } , 0 )
    } , [JSON.stringify(tickets)]);

    return <>
         <LocalizationProvider dateAdapter={AdapterDayjs}><Paper sx={{padding: 2}}>
        <DatePicker value={dayjs(month)} label={'Billing Month'} views={['year', 'month']} onChange={(v)=>{
            if( v ){
                setMonth( v.format('YYYY-MM') );
            }
            
        }} />
        </Paper>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={(t , value )=>{setTab( value as any);} }>
            <Tab label="Summary" value={'summary'} />
            <Tab label="Managed" value={'managed'} />
            <Tab label="Tickets" value={'tickets'} />
        </Tabs>
        </Box>
        <Paper sx={{padding: 2}}>
        { tab === "summary" && <>
            <StyledTable rows={[
                {
                    label: 'Managed Services',
                    value: totalManaged,
                    tab: 'managed'
                }, 
                {
                    label: 'Hourly Tickets',
                    value: totalLaborCost,
                    tab: 'tickets'
                }, 
            ]} columns={[{
                field: 'label',
                headerName: 'Service',
                flex: 1
            },
            {
                field: 'value',
                headerName: 'Cost',
                flex: 1,
                Element: ( { cell } ) => {
                    return <>${(cell || 0 ).toFixed(2)}</>
                }
            },
            ]} onClick={(row)=>{
                setTab(row.tab);
            }}></StyledTable>
        </> }
        { tab === 'managed' && id && <ContractOverview companyId={id} /> }
        {tab === 'tickets' && <TicketSummary tickets={tickets?.filter( c => !!c.cost ) || []} />}
        </Paper>
        </LocalizationProvider>

    </>
}