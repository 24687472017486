import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchCoreUsersQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SearchCoreUsersQuery = { __typename?: 'Query', findCoreUsersPaginated: { __typename?: 'PaginatedCoreUsers', data?: Array<{ __typename?: 'CoreUsers', id?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null }> | null } };


export const SearchCoreUsersDocument = gql`
    query SearchCoreUsers($search: String) {
  findCoreUsersPaginated(search: $search, email: {like: "%coulee.tech"}) {
    data {
      id
      email
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useSearchCoreUsersQuery__
 *
 * To run a query within a React component, call `useSearchCoreUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCoreUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCoreUsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchCoreUsersQuery(baseOptions?: Apollo.QueryHookOptions<SearchCoreUsersQuery, SearchCoreUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCoreUsersQuery, SearchCoreUsersQueryVariables>(SearchCoreUsersDocument, options);
      }
export function useSearchCoreUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCoreUsersQuery, SearchCoreUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCoreUsersQuery, SearchCoreUsersQueryVariables>(SearchCoreUsersDocument, options);
        }
export function useSearchCoreUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchCoreUsersQuery, SearchCoreUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchCoreUsersQuery, SearchCoreUsersQueryVariables>(SearchCoreUsersDocument, options);
        }
export type SearchCoreUsersQueryHookResult = ReturnType<typeof useSearchCoreUsersQuery>;
export type SearchCoreUsersLazyQueryHookResult = ReturnType<typeof useSearchCoreUsersLazyQuery>;
export type SearchCoreUsersSuspenseQueryHookResult = ReturnType<typeof useSearchCoreUsersSuspenseQuery>;
export type SearchCoreUsersQueryResult = Apollo.QueryResult<SearchCoreUsersQuery, SearchCoreUsersQueryVariables>;