import { FC,  useMemo, useState } from "react";
import { useGetAllUsersQuery } from "../../../Schedule/DailySchedule/gql/GetAllUsers.generated";
import FullCalendar from "@fullcalendar/react";
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import luxon3Plugin from "@fullcalendar/luxon3";

import {  Theme, Tooltip, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { ReportData } from "./ReportActivity.types";
import { ReportItemDialog } from "./ReportItemDialog";
import { useReportData } from "./UseReportData/UseReportData";
import { Icon } from "@iconify/react";
import { useSearchParam } from "../../../../react-submodules/utils/reactrouter";

const TimeLabel = ({ date }: { date: Date }) => {
    const cdt =  DateTime.fromJSDate(date).setZone('America/Chicago').toFormat('h a');
    const edt = DateTime.fromJSDate(date).setZone('America/New_York').toFormat('h a');
    const pht = DateTime.fromJSDate(date).setZone('Asia/Manila').toFormat('h a');
  return <Tooltip title={`${cdt} CDT / ${edt} EDT / ${pht} PHT`}><span className="custom-time-label">{cdt}</span></Tooltip>;
};



const EventContent: FC<{ event: any }> = ({ event }) => {
  const instance : ReportData & Record<string,any> = event.extendedProps;
  /*const percentage = instance.userAnswerableInboundCalls 
    ? Math.round(instance.userTotalInboundCallsAnswered / instance.userAnswerableInboundCalls * 100)
    : '--';*/

  return (
    <div className="fc-event-main-frame">
      <div className="fc-event-title-container">
        <div className="fc-event-title fc-sticky">
          {instance.isTriage && <Icon icon="line-md:phone-filled" />}
          {instance.isT1FirstUp && <Icon icon="icon-park-outline:database-first" />}
          {instance.isT2FirstUp && <Icon icon="streamline:hotel-two-star-solid" />}
        </div>
      </div>
    </div>
  );
};

const updateGrade = ( currentGrade : number , nextGrade : number ) => {
    return Math.max( currentGrade , nextGrade );
}

const gradeToColor = ( grade : number  , theme : Theme ) => {
  switch( grade ){
    case -1 : return theme.palette.grey[400];
    case 0 : return theme.palette.success.main;
    case 1 : return theme.palette.info.main;
    case 2 : return "#b3b300";
    case 3 : return theme.palette.warning.main;
    case 4 : return theme.palette.error.main;
  }
}

const gradeTriage = ( data : ReportData ) => {
  let grade = -1;
  const percentage = data.userAnswerableInboundCalls 
        ? Math.round(data.userTotalInboundCallsAnswered / data.userAnswerableInboundCalls * 100)
        : null;
  if( percentage !== null && percentage >= 50 ){
    grade = updateGrade( grade , 0 );
  }
  if( data.unansweredAnswerableInboundCalls > 0 ){
    grade = updateGrade( grade , 4 );
  }
  if( percentage !== null && percentage < 75 ){
    grade = updateGrade( grade , 3 );
  }
  if( percentage !== null && percentage < 90 ){
    grade = updateGrade( grade , 2 );
  }
  return grade;
}
const gradeT1FirstUp = ( data : ReportData ) => {
  let grade = -1;
  if( data.unansweredAnswerableInboundCalls > 0 ){
    grade = updateGrade( grade , 2 );
  }
  return grade;
}
const gradeT2FirstUp = ( data : ReportData ) => {
  let grade = -1;
  if( data.unansweredAnswerableInboundCalls > 0 ){
    grade = updateGrade( grade , 2 );
  }
  return grade;
}

const mapReportData = ( data : ReportData , theme : Theme ) => {
    let grade = -1; // -1 is unknown, 0 is good, 1 info , 2 warning , 3 error

    if( data.isTriage ){
        grade = updateGrade( grade , gradeTriage( data ) );
    }
    
    if( data.isT1FirstUp ){
        grade = updateGrade( grade , gradeT1FirstUp( data ) );
    }
    if( data.isT2FirstUp ){
        grade = updateGrade( grade , gradeT2FirstUp( data ) );
    }

    // grade = updateGrade( grade , percentage < 80 ? 2 : 0 );
    
    return {
        ...data,
        color : gradeToColor( grade , theme ),
        extendedProps : {...data , grade },
    }
}


export const UserActivity : FC<{}> = () => {
    const [ openReportItemDialog , setOpenReportItemDialog ] = useState<ReportData | null>(null);
    const [ dateTrue , setDateTrue ] = useSearchParam<string | null >( "date" );
    const date = useMemo( () => dateTrue ? DateTime.fromISO(dateTrue) : DateTime.now().minus({ days : 1 }) , [ dateTrue ] );
    const setDate = ( d : DateTime ) => {
        setDateTrue( d.toISO()?.split('T')[0] || null );
    }

    const theme = useTheme();
    const [ reportData ] = useReportData( date );
    const { data } = useGetAllUsersQuery({
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: false
    });
    const resources = useMemo(() => {
        const r =  data?.findCoreUsersPaginated?.data?.map( d => ({
            id : d.id || 'unset',
            title : (d.firstName ? `${d.firstName} ${d.lastName}` : d.email ) || 'unset',
        })) ?? [];
        return [ { id : 'unset', title : 'Unscheduled' } ,  ...r , { id: 'total', title : 'Total' } ];
    }, [ JSON.stringify( data ) ]);
    return  <>
    <ReportItemDialog resources={resources} item={openReportItemDialog} onClose={() => setOpenReportItemDialog(null)} />
    <FullCalendar
    nowIndicator
    initialView={"resourceTimeGridDay"}
    slotLabelContent={(arg) => <TimeLabel date={arg.date} />}
    headerToolbar={{
      right:
        "prev,next today resourceTimeGridDay,resourceTimeGridDayFull, resourceTimeGridDay10AM",
    }}
    eventClick={(info) => {
      
        setOpenReportItemDialog( info.event.extendedProps as ReportData );
    }}
    views={{
      resourceTimeGridDay: {
        type: 'resourceTimeGrid',
        slotMinTime: "05:00:00",
        slotMaxTime: "18:00:00",
        buttonText: 'Day (Business Hours)'
      },
      resourceTimeGridDayFull: {
        type: 'resourceTimeGrid',
        slotMinTime: "00:00:00",
        slotMaxTime: "24:00:00",
        buttonText: 'Day (24 Hours)'
      },
      resourceTimeGridDay10AM: {
        type: 'resourceTimeGrid',
        slotMinTime: "09:00:00",
        slotMaxTime: "10:00:00",
        buttonText: 'Day (10AM-11AM)',
        slotDuration: '00:01:00',
      },
      resourceTimelineTwoDay: {
        type: "resourceTimeline",
        dayCount: 2,
      },
      resourceTimelineThreeDay: {
        type: "resourceTimeline",
        dayCount: 3,
      },
      resourceTimelineFourDay: {
        type: "resourceTimeline",
        dayCount: 4,
      },
    }}
    editable={false}
    initialDate={date.toJSDate()}
    datesSet={(dateInfo) => {
        setDate(DateTime.fromJSDate(dateInfo.start));
    }}
    plugins={[resourceTimeGridPlugin, luxon3Plugin, interactionPlugin]}
    resources={resources}
    contentHeight="auto"
    stickyFooterScrollbar={true}
    stickyHeaderDates={true}
    resourceOrder="title"
    
    events={reportData.map( d => mapReportData(d, theme) )}
    resourceAreaWidth="20%"
    schedulerLicenseKey="0535070809-fcs-1715174460"
    eventContent={(eventInfo) => (
      <EventContent event={eventInfo.event} />
    )}
  />
  </>
}