// import { writeFileSync } from "fs";
import { DateTime } from "luxon";
import { CoreUser } from "../../../../../react-submodules/types/core";
import { PhoneCallLeg, PhoneCalls, ReportData, ScheduleInstance, UserProps } from "../ReportActivity.types";

type CallInfo = {
    answered: boolean;
    answeredBy: string;
    callMinutes: Record<string, number>;
    totalDuration: number;
}

const callInfo = ( call : PhoneCalls ) => {
    const not800legs = call?.PhoneCallLegs && call?.PhoneCallLegs?.filter( l => l.extention !== '800') || [];
    const answered = not800legs.length > 0;
    const answeredBy = call.CoreUser?.id || not800legs[0]?.CoreUser?.id;
    const callMinutes = not800legs.reduce( (acc, d) => {
        const userId = d.CoreUser?.id;
        const duration = DateTime.fromISO(d.endTime).diff(DateTime.fromISO(d.startTime), 'minutes').minutes;
        if( userId ){
            return { ...acc , [userId] : (acc[userId] || 0) + duration };
        }
        return acc;
    } , {} as Record<string, number>);
    const totalDuration = call?.PhoneCallLegs?.reduce( (acc, d) => {
        const duration = DateTime.fromISO(d.endTime).diff(DateTime.fromISO(d.startTime), 'minutes').minutes;
        return acc + duration;
    } , 0 );
    return { answered , answeredBy , callMinutes , totalDuration } as CallInfo;
}

export const extractCallLegsPerUser = ( calls : PhoneCalls[] , userId : string ) => {
    return calls.reduce( (acc, d) => {
        const legs = d.PhoneCallLegs?.filter( l => l.CoreUser?.id === userId ) || [];
        return [ ...acc , ...legs ];
    } , [] as PhoneCallLeg[] );
}

export const extractAnswerableCalls = ( calls : Array< PhoneCalls  & CallInfo>  , userId : string , currentTime : DateTime , nextTime : DateTime ) => {
    const callLegsForUser = extractCallLegsPerUser( calls , userId );

    return calls.filter( c => {
        if( c.direction?.toLowerCase() !== 'inbound' ){
            return false;
        }

        if( c.answered === true && c.answeredBy === userId ){
            return true;
        }
        
        if( !c || !c?.PhoneCallLegs?.length ){
            return false;
        }
        
        if( c.answered === false && c.totalDuration < 0.75 ){
            return false;
        }
        const callStart = DateTime.fromISO( c.startTime );
        const callEnd = DateTime.fromISO( c.endTime );
        const currentLegs = callLegsForUser.filter( l => {
            const legStart = DateTime.fromISO(l.startTime);
            const legEnd = DateTime.fromISO(l.endTime);
            
            // Check if there's any overlap between the call and the leg
            const hasOverlap = (
                (legStart <= callEnd && legEnd >= callStart) ||  // leg overlaps with call
                (callStart <= legEnd && callEnd >= legStart)     // call overlaps with leg
            );
            
            return hasOverlap 
                && c.id !== l.phonesCallId 
                && l.CoreUser?.id === userId;
        });
        return currentLegs.length === 0;
    })
}

export const buildReportData = ( date : DateTime , callsProp : PhoneCalls[] , users : UserProps[]  , schedule : ScheduleInstance[] ) => {
    let currentTime = date.startOf('day');
    let i = 0;

    const updatedReportData : ReportData[] = [];
    while( currentTime.toJSDate() < date.endOf('day').toJSDate() || i < 1000 ){
        i++;
        const nextTime = currentTime.plus({ minutes : 30 });

        const calls : Array<PhoneCalls & CallInfo> = callsProp?.filter( d => {
            return DateTime.fromISO(d.startTime) >= currentTime && DateTime.fromISO(d.startTime) < nextTime;
        }).map( d => ({ ...d , ...callInfo(d) })) || [];

        const triage : string[] = [];
        const t1FirstUp : string[] = [];
        const t2FirstUp : string[] = [];
        const timeBlocks : ReportData[] = [];
        
        for( const user of users ){
            const isAvailable = schedule.find( d => d.coreUserId === user.id 
                && DateTime.fromISO(d.startDateTime) <= currentTime
                && DateTime.fromISO(d.endDateTime) >= nextTime
                && d.available );
            const scheduledItems = schedule.filter( d => d.coreUserId === user.id 
                && DateTime.fromISO(d.startDateTime) <= currentTime
                && DateTime.fromISO(d.endDateTime) >= nextTime ).reduce( (acc, d) => {
                if( d.schedulePlanId){
                    return { ...acc , [d.schedulePlanId] : true };
                }
                return acc;
                } , {} as Record<number, boolean>);
            // const callLegsForUser = extractCallLegsPerUser( calls , user.id || '' );
            const answerableCalls = extractAnswerableCalls( calls , user.id || '' , currentTime , nextTime );
            const isTriage = scheduledItems[13] || scheduledItems[16] || false;
            const isT1FirstUp = scheduledItems[12] || false;
            const isT2FirstUp = scheduledItems[14] || false;
            if( isTriage ){
                triage.push( user.id || '' );
            }
            if( isT1FirstUp ){
                t1FirstUp.push( user.id || '' );
            }
            if( isT2FirstUp ){
                t2FirstUp.push( user.id || '' );
            }

            
            if( isAvailable ){
                const rd : ReportData = {
                    id: `${user.id}-${currentTime.toFormat('yyyy-MM-dd-HH-mm')}-${nextTime.toFormat('yyyy-MM-dd-HH-mm')}`,
                    title: '',
                    resourceId : user.id || 'unset',
                    start : currentTime.toJSDate().toISOString(),
                    end : nextTime.toJSDate().toISOString(),
                    blockTotalCalls: calls.length,
                    blockTotalCallMinutes: calls.reduce( (acc, d) => acc + (d.endTime ? DateTime.fromISO(d.endTime).diff(DateTime.fromISO(d.startTime), 'minutes').minutes : 0), 0),
                    userTotalInboundCalls: calls.filter( d => d.direction?.toLowerCase() === 'inbound').length,
                    userTotalInboundCallsAnswered: calls.filter( d => d.answeredBy === user.id && d.direction?.toLowerCase() === 'inbound').length,
                    userTotalCallMinutes: calls.filter( d => d.answeredBy === user.id).reduce( (acc, d) => acc + (d.callMinutes[user.id || '--'] || 0), 0),
                    userAnswerableInboundCalls: answerableCalls.length,
                    userAnswerableInboundCallsIds: answerableCalls.map( d => d.id ),
                    unansweredAnswerableInboundCallsIds: answerableCalls.filter( d => d.answered === false && d.direction?.toLowerCase() === 'inbound').map( d => d.id ),
                    isTriage,
                    isT1FirstUp,
                    isT2FirstUp,
                    unansweredInboundCalls: calls.filter( d => d.answered === false && d.direction?.toLowerCase() === 'inbound').length,
                    unansweredAnswerableInboundCalls: answerableCalls.filter( d => d.answered === false && d.direction?.toLowerCase() === 'inbound' && answerableCalls.includes(d)).length,
                    debug: { answerableCalls , unansweredAnswerableInboundCalls: answerableCalls.filter( d => d.answered === false && d.direction?.toLowerCase() === 'inbound' && answerableCalls.includes(d)) },
                    triage : [],
                    t1FirstUp : [],
                    t2FirstUp: [],
                    calls
                }
                timeBlocks.push( rd );
            }
        }
        updatedReportData.push( ...timeBlocks.map( d => { return { ...d , triage , t1FirstUp , t2FirstUp } as ReportData }).map( ( r : ReportData ) => {

            if( r.isT1FirstUp && r.triage.length === 0 ){
                r.isTriage = true;
            }
            if( r.isT2FirstUp && r.t1FirstUp.length === 0 ){
                r.isT1FirstUp = true;
            }
            if( r.isT2FirstUp && r.triage.length === 0 && r.t1FirstUp.length === 0 ){
                r.isTriage = true;
            }
            return r;
        }));

        currentTime = nextTime;

    }   
    return updatedReportData;
}