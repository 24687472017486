import { FC } from "react";
import { CoreLocations } from "../../../coretypes/locations";
import { Box, Typography } from "@mui/material";

export const Location : FC<{
    location: CoreLocations
}> = ({ location }) => {
    return <Box sx={{ minWidth: 200 }}>
        <Box sx={{ lineHeight: 0.5 , height: '16px' }}><Typography variant="subtitle2">{location.name}</Typography></Box>
        <Box sx={{ lineHeight: 0.5 , height: '13px' }}><Typography variant="caption">{location.address1}</Typography></Box>
        <Box sx={{ lineHeight: 0.5 , height: '12px' }}><Typography variant="caption">{location.city}, {location.state} {location.zip}</Typography></Box>
    </Box>;
}