import { Box, Button } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { SchedulerWizardProps, SchedulingWizardState } from "./types"
import Page1 from "./pages/Page1"
import { QueueTicket } from "../../types/ticketqueue"
import { useApi } from "../../providers/Api"
import Page2 from "./pages/Page2"
import { getBaseTimes, roundDriveTime } from "./utils"
import Submitting from "./pages/Submitting"
import { useSchedulingWizard, withSchedulingWizard } from "./SchedulingWizardProvider"


const PAGES = 2;

const SchedulerWizardCore : FC<SchedulerWizardProps> = () => {
    const { state , setItem , submit , submittingStatus , setState , ticket , submittingSteps } = useSchedulingWizard();
    
    return <>
        {Boolean(state.page === 1 && !submittingStatus) && <Page1 state={state} setState={setState} setItem={setItem} ticket={ticket} />}
        {Boolean(state.page === 2 && !submittingStatus) && <Page2 state={state} setState={setState} setItem={setItem} ticket={ticket} />}
        {Boolean( submittingStatus) && <Submitting  submittingStatus={submittingStatus} submittingSteps={submittingSteps} />}
        <Box sx={{display:'flex'}}>
            <Box sx={{flexGrow:1}}>&nbsp;</Box>
            <Box sx={{flexGrow:0 , mr:1}}>
                <Button onClick={() => { setItem( 'page' , state.page - 1 ) }} variant="contained" size="small" disabled={state.page === 1}>Back</Button>
            </Box>
            <Box sx={{flexGrow:0 , mr: 1}}>
                { state.page !== PAGES && <Button onClick={() => { 
                    setItem( 'page' , state.page + 1 );
                    setItem( 'readyForNextPage' , false );
                }} color="success" variant="contained" disabled={!state.readyForNextPage} size="small">Next</Button> }
                { state.page === PAGES && <Button onClick={() => { 
                    submit();
                }} color="success" disabled={!state.readyForSubmit} variant="contained" size="small">Submit</Button> }
            </Box>
        </Box>
    </>
}
export const SchedulerWizard = withSchedulingWizard(SchedulerWizardCore);
