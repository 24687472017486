import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCoreContactsQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
}>;


export type GetCoreContactsQuery = { __typename?: 'Query', findCoreContactPaginated: { __typename?: 'PaginatedCoreContact', totalCount: number, page: number, limit: number, hash?: string | null, data?: Array<{ __typename?: 'CoreContact', id?: number | null, firstName?: string | null, lastName?: string | null, email?: string | null, phone?: string | null, alternatePhone?: string | null, mobilePhone?: string | null, coreCompanyId: number, active?: boolean | null }> | null } };


export const GetCoreContactsDocument = gql`
    query GetCoreContacts($search: String, $page: Int!, $limit: Int!) {
  findCoreContactPaginated(
    search: $search
    page: $page
    limit: $limit
    active: {eq: true}
  ) {
    data {
      id
      firstName
      lastName
      email
      phone
      alternatePhone
      mobilePhone
      coreCompanyId
      active
    }
    totalCount
    page
    limit
    hash
  }
}
    `;

/**
 * __useGetCoreContactsQuery__
 *
 * To run a query within a React component, call `useGetCoreContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoreContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoreContactsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCoreContactsQuery(baseOptions: Apollo.QueryHookOptions<GetCoreContactsQuery, GetCoreContactsQueryVariables> & ({ variables: GetCoreContactsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCoreContactsQuery, GetCoreContactsQueryVariables>(GetCoreContactsDocument, options);
      }
export function useGetCoreContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoreContactsQuery, GetCoreContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCoreContactsQuery, GetCoreContactsQueryVariables>(GetCoreContactsDocument, options);
        }
export function useGetCoreContactsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCoreContactsQuery, GetCoreContactsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCoreContactsQuery, GetCoreContactsQueryVariables>(GetCoreContactsDocument, options);
        }
export type GetCoreContactsQueryHookResult = ReturnType<typeof useGetCoreContactsQuery>;
export type GetCoreContactsLazyQueryHookResult = ReturnType<typeof useGetCoreContactsLazyQuery>;
export type GetCoreContactsSuspenseQueryHookResult = ReturnType<typeof useGetCoreContactsSuspenseQuery>;
export type GetCoreContactsQueryResult = Apollo.QueryResult<GetCoreContactsQuery, GetCoreContactsQueryVariables>;