import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteCoreFileMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteCoreFileMutation = { __typename?: 'Mutation', deleteCoreFile: boolean };


export const DeleteCoreFileDocument = gql`
    mutation DeleteCoreFile($id: Int!) {
  deleteCoreFile(id: $id)
}
    `;
export type DeleteCoreFileMutationFn = Apollo.MutationFunction<DeleteCoreFileMutation, DeleteCoreFileMutationVariables>;

/**
 * __useDeleteCoreFileMutation__
 *
 * To run a mutation, you first call `useDeleteCoreFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCoreFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCoreFileMutation, { data, loading, error }] = useDeleteCoreFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCoreFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCoreFileMutation, DeleteCoreFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCoreFileMutation, DeleteCoreFileMutationVariables>(DeleteCoreFileDocument, options);
      }
export type DeleteCoreFileMutationHookResult = ReturnType<typeof useDeleteCoreFileMutation>;
export type DeleteCoreFileMutationResult = Apollo.MutationResult<DeleteCoreFileMutation>;
export type DeleteCoreFileMutationOptions = Apollo.BaseMutationOptions<DeleteCoreFileMutation, DeleteCoreFileMutationVariables>;