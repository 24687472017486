import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, CardHeader, Checkbox, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Skeleton, TextField, Typography } from "@mui/material"
import { Handle, Position } from "reactflow"
import { useEffect, useMemo, useState } from "react";
import { useNodeDataProperty, useRecipie } from "../../../providers/Recipies.provider";

import { Form } from "../../../coretypes/form";
import { useApi } from "../../../react-submodules/providers/Api/Api";
import { MarkdownView } from "../../../react-submodules/MarkdownView";
const days = [
    {
        name: 'Sunday',
        value: '0'
    },
    {
        name: 'Monday',
        value: '1'
    },
    {
        name: 'Tuesday',
        value: '2'
    },
    {
        name: 'Wednesday',
        value: '3'
    },
    {
        name: 'Thursday',
        value: '4'
    },
    {
        name: 'Friday',
        value: '5'
    },
    {
        name: 'Saturday',
        value: '6'
    }
]
const months = [
    {
        name: 'January',
        value: '1'
    },
    {
        name: 'February',
        value: '2'
    },
    {
        name: 'March',
        value: '3'
    },
    {
        name: 'April',
        value: '4'
    },
    {
        name: 'May',
        value: '5'
    },
    {
        name: 'June',
        value: '6'
    },
    {
        name: 'July',
        value: '7'
    },
    {
        name: 'August',
        value: '8'
    },
    {
        name: 'September',
        value: '9'
    },
    {
        name: 'October',
        value: '10'
    },
    {
        name: 'November',
        value: '11'
    },
    {
        name: 'December',
        value: '12'
    }
]


export const ScheduleNode = ({ id , data } : {id : string , data : any  } ) => {
    const api = useApi();
    const [ refreshForms , setRefreshForms ] = useState(1);
    const [ title , setTitle ] = useNodeDataProperty( id , 'title');
    const [ hour , setHour ] = useNodeDataProperty( id , 'hour');
    const [ minute , setMinute ] = useNodeDataProperty( id , 'minute');
    const [ dayOfWeek , setDayOfWeek ] = useNodeDataProperty( id , 'dayOfWeek');
    const [ dayOfMonth , setDayOfMonth ] = useNodeDataProperty( id , 'dayOfMonth');
    const [ month , setMonth ] = useNodeDataProperty( id , 'month');
    const selectedDayOfTheWeek = useMemo(() => {
        if( dayOfWeek === '*' ) return ['0' , '1' , '2' , '3' , '4' , '5' , '6'];
        return dayOfWeek.split(',').map((day : string) => day.trim());
    } , [dayOfWeek]);
    const addChangedDayOfWeek = (e : any) => {
        const v = e.target.value as string[];
        if( v.length === 7 ){
            setDayOfWeek('*');
        }else{
            setDayOfWeek(v.join(','));
        }
    }
    const selectedMonths = useMemo(() => {
        if( month === '*' ) return ['1' , '2' , '3' , '4' , '5' , '6' , '7' , '8' , '9' , '10' , '11' , '12'];
        return month.split(',').map((month : string) => month.trim());
    } , [month]);
    const addChangedMonths = (e : any) => {
        const v = e.target.value as string[];
        if( v.length === 12 ){
            setMonth('*');
        }else{
            setMonth(v.join(','));
        }
    }

    return <Box>
        <Card sx={{maxWidth: '600px'}}>
            <CardHeader className="drag-handle" title="Schedule Trigger" />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item sm={4}>
                        <TextField size="small" label="Hour" value={hour} onChange={(e) => setHour(e.target.value)} />
                        
                    </Grid>
                    <Grid item sm={4}>
                        <TextField size="small" label="Minute" value={minute} onChange={(e) => setMinute(e.target.value)} />
                    </Grid>
                    <Grid item sm={4}>
                        <TextField size="small" label="Day of Month" value={dayOfMonth} onChange={(e) => setDayOfMonth(e.target.value)} />
                    </Grid>
                    <Grid item sm={6}>
                        <InputLabel >Day of Week</InputLabel>
                        <Select
                        fullWidth
                        size="small"
                        multiple
                        value={selectedDayOfTheWeek}
                        onChange={addChangedDayOfWeek}
                        input={<OutlinedInput label="Day of Week" />}
                        renderValue={(selected) => {
                            if( selected.length === 7){
                                return "Everyday";
                            }
                            const v = selected.map( (s : string) => days.find( (d : any) => d.value === s )?.name?.substring(0 , 3 ) ).join(', ');                            
                            return v;
                        }}
                        >
                        {days.map((day) => (
                            <MenuItem key={day.value} value={day.value}>
                            <Checkbox checked={selectedDayOfTheWeek.indexOf(day.value) > -1} />
                            <ListItemText primary={day.name} />
                            </MenuItem>
                        ))}
                        </Select>
                    </Grid>
                    <Grid item sm={6}>
                        <InputLabel >Month</InputLabel>
                        <Select
                        fullWidth
                        size="small"
                        multiple
                        value={selectedMonths}
                        onChange={addChangedMonths}
                        renderValue={(selected) => {
                            if( selected.length === 12){
                                return "Every Month";
                            }
                            return selected.map((s : string) => months.find( (m : any) => m.value === s )?.name?.substring(0 , 3 ) ).join(', ');
                        }}
                        >
                            {months.map((month) => (
                                <MenuItem key={month.value} value={month.value}>
                                    <Checkbox checked={selectedMonths.indexOf(month.value) > -1} />
                                    <ListItemText primary={month.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item sm={12}>
                    <Accordion>
                    
                    <AccordionSummary>
                        Advanced Usage Examples
                    </AccordionSummary>
                    <AccordionDetails>
                        <MarkdownView>{`
This uses Cron behind the scenes. [see package here](https://www.npmjs.com/package/cron-parser)

so for a simple task, to run every hour, you would set the hour to * and the minute to 0.

Lets say you want to run a task every 5 minutes. You would set the hour to * and the minute to */5.

If you want it to run every 3 days at 12:00 AM, you would set the hour to 0 and the minute to 0 and the day of month to */3. ( you may have weird gaps or double runs depending on how the end on the day of the end of the month)

`}</MarkdownView>
                    </AccordionDetails>
                </Accordion>
                    </Grid>
                </Grid>
                
            </CardContent>
        </Card>
        
        <Handle
          type="source"
          position={Position.Right}
          id={`handle-source`}
        />
    </Box>
}
