import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import luxon3Plugin from "@fullcalendar/luxon3";
import { useGetAllUsersQuery } from "./GetAllUsers.generated";
import { useEffect, useMemo, useState } from "react";
import { useGetAllGroupsQuery } from "./GetAllGroups.generated";
import { ButtonGroup, Button } from "@mui/material";
import { useGetScheduleInstancesQuery } from "./GetScheduleInstances.generated";
import { DateTime } from "luxon";
import { gql, useMutation } from "@apollo/client";

const AUTOSCHEDULE = gql`
mutation AutoSchedule {
    autoScheduleDate(date: "2024-11-15")
}
`
const RESET_QUERY = gql`
mutation UpdateSchedulePlan {    
    rescheduleScheduleDate(date: "2024-11-15")
}
`

const OPTIMIZE_QUERY = gql`
mutation OptimizeSchedule {
    optimizeSchedule(date: "2024-11-15")
}
`
export const DailySchedule = () => {
    const [ date , setDate ] = useState<DateTime>(DateTime.now());
    const [ resetQuery ] = useMutation(RESET_QUERY);
    const [ optimizeQuery ] = useMutation(OPTIMIZE_QUERY);
    const [ autoScheduleQuery ] = useMutation(AUTOSCHEDULE);
    const { data } = useGetAllUsersQuery({
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: false
    });
    const { data : rawGroups } = useGetAllGroupsQuery({
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: false
    });
    const [ selectedGroups , setSelectedGroups ] = useState<number[]>([]);

    const groups = useMemo(() => {
        return rawGroups?.findCoreGroupPaginated?.data?.map( d => ({
            id : d.id || NaN,
            title : d.name || 'unset',
        })) ?? [];
    }, [rawGroups]);

    const toggleGroup = ( groupId : number ) => {
        setSelectedGroups( prev => prev.includes(groupId) ? prev.filter( id => id !== groupId ) : [ ...prev, groupId ] );
    }

    useEffect(() => {
        if( selectedGroups.length === 0 ) {
            setSelectedGroups(groups.map( g => g.id ));
        }
    }, [groups]);

    const resources = useMemo(() => {
        return data?.findCoreUsersPaginated?.data?.filter( d => {
            const usersGroups = d.groups?.map( g => g.group.id ) || [];
            if( selectedGroups.length === 0 ) {
                return false;
            }
            return selectedGroups.some( groupId => usersGroups.includes(groupId) );
        } ).map( d => ({
            id : d.id || 'unset',
            title : (d.firstName ? `${d.firstName} ${d.lastName}` : d.email ) || 'unset',
        })) ?? [];
    }, [data , selectedGroups]);

    const userIds = useMemo(() => {
        return resources.map( r => r.id );
    }, [resources]);


    const { data : scheduleInstances , refetch } = useGetScheduleInstancesQuery({
        variables : {
            users : userIds,
            startDateTime : date.startOf('day').toJSDate(),
            endDateTime : date.endOf('day').toJSDate(),
        },
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: false
    });

    const instances = useMemo(() => {
        return scheduleInstances?.findScheduleInstancePaginated?.data?.map( d => {
            const extra : Record<string,any> = {};
            if( d.available ){
                extra.display = 'background';
                extra.backgroundColor = '#ffffff';
            }
            if( d.AzureCalendar){
                extra.backgroundColor = '#5DE2E7';
            }
            if( d.conflictDetected ){
                extra.backgroundColor = '#ff0000d';
            }
            
            return {
                id : d.id || NaN,
                title : d.SchedulePlan?.name || d.AzureCalendar?.subject || 'unset',
                start : d.startDateTime,
                end : d.endDateTime,
                resourceId : d.coreUserId || 'unset',
                ...extra,
            }
        }) ?? [];
    }, [scheduleInstances]);



    return <>
    <Button onClick={() => {
        autoScheduleQuery().then( () => refetch() );
    }}>Auto Schedule</Button>
    <Button onClick={() => {
        resetQuery().then( () => refetch() );
    }}>Reset</Button>
    <Button onClick={() => {
        optimizeQuery().then( () => refetch() );
    }}>Optimize</Button>
    <ButtonGroup>
        {groups.map( g => <Button key={g.id} variant="contained" color={selectedGroups.includes(g.id) ? 'success' : 'inherit'} onClick={() => {
            toggleGroup(g.id);
        }}>{g.title}</Button>)}
    </ButtonGroup>
  <FullCalendar
        initialView={"resourceTimeGridDay"}
        headerToolbar={{
          right:
            "prev,next today resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth",
        }}
        editable={true}
        plugins={[resourceTimeGridPlugin, luxon3Plugin, interactionPlugin]}
        resources={resources}
        contentHeight="auto"
        stickyFooterScrollbar={true}
        stickyHeaderDates={true}
        resourceOrder="title"
        eventClick={(...p) => {
            /* setSelectedEvent( p[0].event ); */
        }}
        events={instances as any }
        resourceAreaWidth="20%"
        schedulerLicenseKey="0535070809-fcs-1715174460"
        
        views={{
          resourceTimelineTwoDay: {
            type: "resourceTimeline",
            dayCount: 2,
          },
          resourceTimelineThreeDay: {
            type: "resourceTimeline",
            dayCount: 3,
          },
          resourceTimelineFourDay: {
            type: "resourceTimeline",
            dayCount: 4,
          },
        }}
      />
  </>;
}