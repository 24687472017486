import { useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Paper, Typography } from "@mui/material";
import { StyledTable, TableColumnDef } from "../../components/Table/Table";
import { FilterProvider, useFilter, useFilters } from "../../providers/FilterProvider";
import { useApi } from "../../providers/Api/Api";

const columns : TableColumnDef[] = [
    {
        field: 'company_companyName',
        flex: 1,
        headerName: 'Company Name',
        sortable: true,
        minWidth: 180,
        filter: 'string'

    },
    {
        field: 'ticket_ticketNumber',
        flex: 1,
        headerName: 'Ticket Number',
        sortable: true,
        filter: 'string',
        minWidth: 180,
        Element: ( { cell , row } ) => {
            return <Typography variant="h6"><Button
                onClick={()=>{
                    window.open(`/ticketqueue/all/${row.ticket_id}` , `ticket_${row.ticket_id}`)
                }}
            size="small" variant="contained">{row.ticket_ticketNumber}</Button></Typography>
        }

    },
    {
        field: 'ticketStatus_name',
        flex: 1,
        headerName: 'Status',
        filter: 'options',
        sortable: true,
        minWidth: 110,
        

    },
    {
        field: 'queue',
        flex: 1,
        headerName: 'Queue',
        filter: 'options',
        sortable: true,
        minWidth: 120

    },
    {
        field: 'ticket_title',
        flex: 1,
        headerName: 'Title',
        filter: 'string',
        sortable: true

    },
    {
        field: 'time_hours',
        flex: 1,
        headerName: 'Hours',
        sortable: true,
        minWidth: 120,
        filter: 'number'

    },
    {
        field: 'ticket_createDate',
        flex: 1,
        headerName: 'Created',
        sortable: true,
        minWidth: 125,
        filter:'date',
        Element: ( { cell , row } ) => {
            const d = new Date( cell );
            return <>{d.toLocaleDateString()}</>
        }
    },
    {
        field: 'ticket_completedDate',
        flex: 1,
        filter:'date',
        headerName: 'Completed',
        sortable: true,
        minWidth: 140,
        Element: ( { cell , row } ) => {
            const d = new Date( cell );
            if( d.getFullYear() === 1969 ){
                return <></>
            }
            return <>{d.toLocaleDateString()}</>
        }
    }
]
function debounce(func : any , wait : number ) {
    let timeout : any;
    return function(...args : any[]) {
        // @ts-ignore
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

const CoreTickets = () => {
    
    const { id } = useParams<{id: string}>();
    const [ openTicketId , setOpenTicketId ] = useState<string|null>(null);
    const api = useApi();
    const [ tickets , setTickets ] = useState<any[]>( [] );
    const {fitlerParams , setSelectOption , updateFilterValue  } = useFilters();
    
    useEffect( () => {
        let loaded = true;
        const OriginalFilters = JSON.stringify( fitlerParams );
        api.get( `/reports/tickets/${id}` , fitlerParams ).then( ( results ) => {
            if( loaded && OriginalFilters === JSON.stringify( fitlerParams )){
                setTickets( results.data );
            }
        } );
        return() =>{
            loaded = false;
        }
    } , [api.ready , JSON.stringify( fitlerParams )]);
    useEffect( () => {
        api.get('/reports/ticket/queues').then( ( {data} ) => {
            setSelectOption( 'queue' , data.map( ( d: any ) => {
                return {
                    label: d.label,
                    value: d.value
                }
            }) );
            updateFilterValue( 'queue' , 'in' , data.map( ( d: any ) => d.value) );

        });
        api.get('/reports/ticket/statuses').then( ( {data} ) => {
            setSelectOption( 'ticketStatus_name' , data.map( ( d: any ) => {
                return {
                    label: d.label,
                    value: d.label
                }
            }) );
            updateFilterValue( 'ticketStatus_name' , 'in' , data.map( ( d: any ) => d.label));

        });
    } , [api.ready]);
    const openTicket = useMemo(()=>{
        if( openTicketId ){
            return tickets.find( ( d ) => d.ticket_ticketNumber === openTicketId );
        }
        return null;
    } , [openTicketId])
    return <>
    <Paper>
        <StyledTable title="Tickets" onClick={(row)=>{
        setOpenTicketId(row.ticket_ticketNumber);
    }} rows={tickets} columns={columns} /></Paper>
        <Dialog open={openTicketId ? true : false } onClose={()=>{
            setOpenTicketId(null);
        }}>
            <DialogTitle>{openTicket?.ticket_title}</DialogTitle>
            <DialogContent>
                <Box component="pre" sx={{wordWrap: 'break-word' , whiteSpace: 'pre-wrap'}}>{openTicket?.ticket_description}</Box>
            </DialogContent>
        </Dialog>
    </>
}
export const Tickets = () => {
    return <FilterProvider><CoreTickets /></FilterProvider>
}