import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActiveAssignedMessageChannelsQueryVariables = Types.Exact<{
  assignedToUserId: Types.Scalars['String']['input'];
}>;


export type ActiveAssignedMessageChannelsQuery = { __typename?: 'Query', findCoreMessageChannelPaginated: { __typename?: 'PaginatedCoreMessageChannel', totalCount: number, page: number, limit: number, data?: Array<{ __typename?: 'CoreMessageChannel', id?: number | null, expireTime?: any | null, expired?: boolean | null, result?: Types.CoreMessageChannelResult | null, acknowledged?: boolean | null, message?: string | null, type?: Types.CoreMessageChannelType | null, CreatedByUser?: { __typename?: 'CoreUsers', email?: string | null, firstName?: string | null, lastName?: string | null, id?: string | null } | null, AutotaskTicket?: { __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, title: string } | null }> | null } };


export const ActiveAssignedMessageChannelsDocument = gql`
    query ActiveAssignedMessageChannels($assignedToUserId: String!) {
  findCoreMessageChannelPaginated(
    limit: 200
    expired: {eq: false}
    assignedToUserId: {eq: $assignedToUserId}
    acknowledged: {eq: false}
  ) {
    totalCount
    page
    limit
    data {
      id
      expireTime
      expired
      result
      acknowledged
      message
      CreatedByUser {
        email
        firstName
        lastName
        id
      }
      type
      AutotaskTicket {
        id
        ticketNumber
        title
      }
    }
  }
}
    `;

/**
 * __useActiveAssignedMessageChannelsQuery__
 *
 * To run a query within a React component, call `useActiveAssignedMessageChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveAssignedMessageChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveAssignedMessageChannelsQuery({
 *   variables: {
 *      assignedToUserId: // value for 'assignedToUserId'
 *   },
 * });
 */
export function useActiveAssignedMessageChannelsQuery(baseOptions: Apollo.QueryHookOptions<ActiveAssignedMessageChannelsQuery, ActiveAssignedMessageChannelsQueryVariables> & ({ variables: ActiveAssignedMessageChannelsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveAssignedMessageChannelsQuery, ActiveAssignedMessageChannelsQueryVariables>(ActiveAssignedMessageChannelsDocument, options);
      }
export function useActiveAssignedMessageChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveAssignedMessageChannelsQuery, ActiveAssignedMessageChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveAssignedMessageChannelsQuery, ActiveAssignedMessageChannelsQueryVariables>(ActiveAssignedMessageChannelsDocument, options);
        }
export function useActiveAssignedMessageChannelsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ActiveAssignedMessageChannelsQuery, ActiveAssignedMessageChannelsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ActiveAssignedMessageChannelsQuery, ActiveAssignedMessageChannelsQueryVariables>(ActiveAssignedMessageChannelsDocument, options);
        }
export type ActiveAssignedMessageChannelsQueryHookResult = ReturnType<typeof useActiveAssignedMessageChannelsQuery>;
export type ActiveAssignedMessageChannelsLazyQueryHookResult = ReturnType<typeof useActiveAssignedMessageChannelsLazyQuery>;
export type ActiveAssignedMessageChannelsSuspenseQueryHookResult = ReturnType<typeof useActiveAssignedMessageChannelsSuspenseQuery>;
export type ActiveAssignedMessageChannelsQueryResult = Apollo.QueryResult<ActiveAssignedMessageChannelsQuery, ActiveAssignedMessageChannelsQueryVariables>;