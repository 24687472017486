import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, CardMedia,  List, ListItem, Typography } from "@mui/material";
import { Possible } from "../../../../types/generics"
import { GetPhonesCallsForTimelineQuery, useGetPhonesCallsForTimelineQuery  } from "./GetPhoneCall.generated";
import { FC } from "react";
import { PHONE_ICON, PHONE_ICON_DEFAULT_COLOR } from "../../../../constances/icons";
import { Icon } from "@iconify/react";

const AudioPlayer : FC<{url: string}> = ({url}) => {
    return <Box><audio controls src={url} /></Box>
}
const padZero = (num: number) => num < 10 ? `0${num}` : `${num}`;
const Duration : FC<{ startTime: string, endTime: string }> = ({ startTime, endTime }) => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const duration = end.getTime() - start.getTime();
    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((duration % (1000 * 60)) / 1000);
    if( hours > 0 ) return <>{padZero(hours)}h {padZero(minutes)}m</>;
    if( minutes > 0 ) return <>{padZero(minutes)}m {padZero(seconds)}s</>;
    return <>{padZero(seconds)}s</>;
}
type PhoneCallLegItem = NonNullable<GetPhonesCallsForTimelineQuery['getPhonesCallsById']['PhoneCallLegs']>[number];
const DisplayExtention : FC<{leg? : PhoneCallLegItem | null }> = ( {leg} ) => {
    if( !leg ) return <></>;
    if( leg.CoreUser ) return <>{leg.CoreUser.firstName} {leg.CoreUser.lastName}</>
    
    return <>{leg.extention}</>
}
const DisplayContact : FC<{call? : GetPhonesCallsForTimelineQuery['getPhonesCallsById'] | null }> = ( {call} ) => {
    if( call?.Contact){
        return <> {call.Contact.firstName} {call.Contact.lastName}</>
    }
    if( call?.CoreCompany){
        return <> {call.CoreCompany.name}</>
    }
    return <>{call?.direction === "inbound" ? call?.fromNumber : call?.toNumber}</>
}
const DisplayMainExtention : FC<{call? : GetPhonesCallsForTimelineQuery['getPhonesCallsById'] | null }> = ( {call} ) => {
    if( call?.CoreUser ){
        return <> {call.CoreUser.firstName} {call.CoreUser.lastName}</>
    }
    return <>{call?.extention}</>
}

const DisplayFrom : FC<{call? : GetPhonesCallsForTimelineQuery['getPhonesCallsById'] | null }> = ( {call} ) => {
    return call?.direction === "inbound" ? <DisplayContact call={call} /> : <DisplayMainExtention call={call} />
}
const DisplayTo : FC<{call? : GetPhonesCallsForTimelineQuery['getPhonesCallsById'] | null }> = ( {call} ) => {
    return call?.direction === "inbound" ? <DisplayMainExtention call={call} /> : <DisplayContact call={call} />
}
const FormatDate : FC<{date: string}> = ( {date} ) => {
    const dateString = new Date(date).toLocaleString('en-US', { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(",", "")
    return <>{dateString}</>
}

 const CallItem : FC<{ call : GetPhonesCallsForTimelineQuery['getPhonesCallsById'] }> = ( { call } ) => {
    //const attachments = email.attachments.filter( a => a.isInline === false );
    return <Accordion onChange={() => {
    }}>
        <AccordionSummary>
                
        <Box sx={{ display: 'flex', alignItems: 'center' , paddingRight: 1 , color : PHONE_ICON_DEFAULT_COLOR }}>
            <Icon icon={PHONE_ICON} />
        </Box>
                <Typography sx={{ paddingLeft: 1, whiteSpace: 'nowrap' }}>
                    <FormatDate date={call.startTime} /> <DisplayFrom call={call} /> to  <DisplayTo call={call} />  :: <Duration startTime={call.startTime} endTime={call.endTime} />
                </Typography>
        </AccordionSummary>
        
        <AccordionDetails>
            <List>
                {call.PhoneCallLegs?.map( leg => <ListItem key={leg.id}>
                    <Card>
                        
                        <CardContent>
                            
                            <Typography gutterBottom variant="h5" component="div">
                                <DisplayExtention leg={leg} /> :: <Duration startTime={leg.startTime} endTime={leg.endTime} />
                            </Typography>
                            {leg.downloadUrl && <AudioPlayer url={leg.downloadUrl} />}
                            <Typography variant="body2" color="text.secondary">{leg.transcription}</Typography>
                        </CardContent>
                    </Card>
                    

                    
                    </ListItem> )}
            </List>
        </AccordionDetails>
    </Accordion>
}



export const PhoneCall = ( { phoneCallId } : { phoneCallId : Possible<number> } ) => {
    const { data } = useGetPhonesCallsForTimelineQuery({ 
        variables: { id: phoneCallId || 0  }, 
        skip : !phoneCallId 
    });

    if( !data?.getPhonesCallsById ) return <></>;

    return <CallItem call={data.getPhonesCallsById} />
}