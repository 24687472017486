import { ExtendedUserItem, ScheduleInstance, TimeclockItem } from './types';

// Helper function to check if a user is "First Up"
export const isUserFirstUp = (user: ExtendedUserItem): boolean => {
  return user?.activeScheduleInstances?.some((si: ScheduleInstance) => 
    String(si?.SchedulePlan?.name).toLocaleLowerCase()?.includes('first up')
  ) || false;
};

// Helper function to get the highest priority ticket for a user
export const getHighestPriority = (user: ExtendedUserItem): number => {
  if (!user.activeTimeclocks || user.activeTimeclocks.length === 0) {
    return 999; // No tickets, lowest priority
  }
  
  // Find the lowest priority number (highest priority) in the user's tickets
  // Lower number = higher priority
  return Math.min(
    ...user.activeTimeclocks
      .filter((tc: TimeclockItem) => tc.ticket?.priority !== undefined)
      .map((tc: TimeclockItem) => tc.ticket?.priority || 999)
  );
};

// Get color for user badge
export const getColor = (user: ExtendedUserItem) => {
  if (user.activeScheduleInstances?.filter(si => si.available !== true).length || 0 > 0) {
    return '#0000ff';
  }

  if ((user.activeTimeclocks?.length || 0) > 0) return "yellow";
  if (user.activeScheduleInstances?.filter(si => si.available === true).length || 0 > 0) return 'yellow';
  
  return '#ccc';
}; 