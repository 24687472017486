import { FC, Fragment, useState } from "react"
import { DeviceQueryItem } from "../../../../react-submodules/types/dattormm.dto"
import { Dialog , DialogTitle , DialogContent , DialogContentText, Grid, Typography, ButtonGroup, Button, Box, Badge, LinearProgress, DialogActions } from "@mui/material"
import { isServer } from "./utils"
import { ContractsInstances } from "../../../../coretypes/contracts/contracts.dto"
import { InlineIcon } from "@iconify/react"
import { useApi } from "../../../../react-submodules/providers/Api"

const sleep = (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const BulkActions : FC<{
    rows: DeviceQueryItem[]
    onClose: () => any
    contract : ContractsInstances[]
}>= ({rows , onClose , contract}) => {
    const servers = rows.filter( r => isServer( r ) );
    const api = useApi();
    const workstations = rows.filter( r => !isServer( r ) );
    const serverContracts = contract.filter( c => c.contract_unitsMeasured === 'SERVERS' );
    const [ progress , setProgress ] = useState( 0 );
    const [ updating , setUpdating ] = useState( false );
    const workstationContracts = contract.filter( c => c.contract_unitsMeasured === 'WORKSTATIONS' );
    const [ contractChanges , setContractChanges ] = useState<Array<{
        contractInstanceId: number;
        type: 'server' | 'workstation';
        set: boolean;
    }>>([]);

    const setContract = ( contractInstanceId: number , set: boolean | null , type: 'server' | 'workstation' ) => {
        if( set === null ){
            setContractChanges( cc => cc.filter( c => c.contractInstanceId !== contractInstanceId ) );
        } else {
            setContractChanges( cc => [ ...cc.filter( c => c.contractInstanceId !== contractInstanceId ) , { contractInstanceId , set , type } ] );
        }
    }
    const isContractState = ( contractInstanceId: number , set : boolean | null  ) => {
        if( set === null ){
            return contractChanges.find( c => c.contractInstanceId === contractInstanceId ) === undefined;
        }
        return contractChanges.find( c => c.contractInstanceId === contractInstanceId )?.set === set;
    }

    const applyActions = async () => {
        setUpdating( true );
        const actions : Array<{ url : string , deviceId : number }> = [];
        for( const change of contractChanges ){
            if( change.type === 'server' ){
                for( const server of servers ){
                    actions.push( { url: `/contracts/instances/${change.contractInstanceId}/${change.set ? 'assign' : 'unassign'}` , deviceId: server.device_id } );
                }
            } else {
                for( const workstation of workstations ){
                    actions.push( { url: `/contracts/instances/${change.contractInstanceId}/${change.set ? 'assign' : 'unassign'}` , deviceId: workstation.device_id } );
                }
            }
        }
        if( actions.length === 0 ){
            setUpdating( false );
            return;
        }
        setProgress( 0 );
        let i = 0;
        for await ( const action of actions ){
            i++;
            await api.post( action.url , { deviceId: action.deviceId } );
            setProgress( i / actions.length );
        }
        setUpdating( false );
        onClose();
    }

    return <Dialog open={rows.length !== 0 } onClose={onClose}>
        <DialogTitle>Bulk Actions</DialogTitle>
        <DialogContent>
            <DialogContentText>
                <Grid container spacing={2} sx={{marginTop: 2}}>
                    <Grid item sm={12}>
                        <Badge badgeContent={servers.length} color="secondary" sx={{paddingRight: 1}}>
                            <Box sx={{ fontWeight: 'bold' }}>Server Contracts</Box>
                        </Badge>
                    </Grid>
                    {serverContracts.map( c => {
                        return <Fragment key={c.id}>
                            <Grid item sm={6}>
                                <Typography variant="body1">{c.contract_name}</Typography>
                            </Grid>
                            <Grid item sm={6}>
                                <ButtonGroup>
                                    <Button variant="contained" color={isContractState( c.id , false ) ? 'error' : 'inherit'} onClick={() => setContract( c.id , false , 'server' )}><InlineIcon icon="mdi:close-circle-outline"></InlineIcon></Button>
                                    <Button variant="contained" color={isContractState( c.id , null ) ? 'info' : 'inherit'} onClick={() => setContract( c.id , null , 'server' )}><InlineIcon icon="mdi:checkbox-blank-circle"></InlineIcon></Button>
                                    <Button variant="contained" color={isContractState( c.id , true ) ? 'success' : 'inherit'} onClick={() => setContract( c.id , true , 'server' )}><InlineIcon icon="mdi:check-circle-outline"></InlineIcon></Button>
                                </ButtonGroup>
                            </Grid>
                        </Fragment>
                    })}
                    <Grid item sm={12}>
                        <Badge badgeContent={workstations.length} color="secondary" sx={{paddingRight: 1}}>
                            <Box sx={{ fontWeight: 'bold' }}>Workstation Contracts</Box>
                        </Badge>
                    </Grid>
                    {workstationContracts.map( c => {
                        return <Fragment key={c.id}>
                            <Grid item sm={6}>
                                <Typography variant="body1">{c.contract_name}</Typography>
                            </Grid>
                            <Grid item sm={6}>
                                <ButtonGroup>
                                    <Button variant="contained" color={isContractState( c.id , false ) ? 'error' : 'inherit'} onClick={() => setContract( c.id , false , 'workstation' )}><InlineIcon icon="mdi:close-circle-outline"></InlineIcon></Button>
                                    <Button variant="contained" color={isContractState( c.id , null  ) ? 'info' : 'inherit'} onClick={() => setContract( c.id , null , 'workstation' )}><InlineIcon icon="mdi:checkbox-blank-circle"></InlineIcon></Button>
                                    <Button variant="contained" color={isContractState( c.id , true ) ? 'success' : 'inherit'} onClick={() => setContract( c.id , true , 'workstation' )}><InlineIcon icon="mdi:check-circle-outline"></InlineIcon></Button>
                                </ButtonGroup>
                            </Grid>
                        </Fragment>
                    })}
                </Grid>
                { updating && <Box sx={{marginTop: 2}}>
                    <LinearProgress variant="determinate" value={progress * 100} />
                </Box> }
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                applyActions();
            }}>Apply</Button>
        </DialogActions>
    </Dialog>
}