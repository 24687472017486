import { AutotaskTicketNotes, AutotaskTimeEntries } from "../../types/autotask.dto";

import { FormInstance } from "../../../coretypes/form.dto";
import { AzureEmail } from "../../types/azure";
import { CoreTimeclock } from "../../providers/Timeclock";

export type TimelineItemCore = {
    type : 'form' | 'ognote' | 'email' | 'timeclock';
    sortTime : number;
    note?: AutotaskTicketNotes | AutotaskTimeEntries;
    email?: AzureEmail;
    form?: FormInstance;
}
export type TimelineItemForm = TimelineItemCore & {
    type : 'form';
    form : FormInstance;
}
export type TimelineItemEmail = TimelineItemCore & {
    type : 'email';
    email : AzureEmail;
}
export type TimelineItemOgNote = TimelineItemCore & {
    type : 'ognote';
    note : AutotaskTicketNotes | AutotaskTimeEntries;
}
export type TimelineItemTimeclock = TimelineItemCore & {
    type : 'timeclock';
    timeclock : CoreTimeclock;
}
export type TTimelineItem = TimelineItemForm | TimelineItemOgNote | TimelineItemEmail | TimelineItemTimeclock;


export const isFormItem = ( item : TTimelineItem ) : item is TimelineItemForm => item.type === 'form';
export const isOgNoteItem = ( item : TTimelineItem ) : item is TimelineItemOgNote => item.type === 'ognote';
export const isEmailItem = ( item : TTimelineItem ) : item is TimelineItemEmail => item.type === 'email';
export const isTimeclockItem = ( item : TTimelineItem ) : item is TimelineItemTimeclock => item.type === 'timeclock';


