import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import { ApiProvider } from './react-submodules/providers/Api/Api';
import { AppRoutes } from './routes';
import { Auth0Provider } from '@auth0/auth0-react';
import { Companies } from './pages/Companies/Companies';
import { FormActionWatcher } from './react-submodules/components/FormActionWatcher';
import { KeycloakProvider } from './react-submodules/providers/Keycloak';
import { LayoutProvider } from './react-submodules/providers/LayoutProvider';
import LogRocket from 'logrocket';
import { MainLayout } from './layouts/MainLayout';
import { RecipeApiProvider } from './react-submodules/providers/Recipe';
import { UserProfileProvider } from './react-submodules/providers/UserProfileProvider';
import { UserProvider } from './react-submodules/providers/User';
import { UserTheme } from './react-submodules/providers/UserTheme';
import setupLogRocketReact from 'logrocket-react';
import { useEffect } from 'react';
import { CoreNotificationProvider } from './react-submodules/providers/CoreNotificationProvider';
import { CoreNotificationWatcher } from './react-submodules/components/CoreNotificationWatcher/CoreNotificationWatcher';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Apollo } from './react-submodules/providers/Apollo/Apollo';
import { SocketProvider } from './providers/SocketIo';
import { Messaging } from './embedings/Messaging/Messaging';
const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        index: true,
        
        element: <Companies />,
      },
      ...AppRoutes
    ]
  },
  
]);

function App() {
  useEffect( () => {
    LogRocket.init('enlightened/enlightenedmsp');
    setupLogRocketReact(LogRocket);
  } , []);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <KeycloakProvider>
        <UserProvider>
          <ApiProvider>
            <SocketProvider>
              <Apollo>
              <UserProfileProvider>
                <UserTheme>
                  <LayoutProvider>
                    <RecipeApiProvider>
                      <CoreNotificationProvider>
                        <Messaging />
                        <FormActionWatcher />
                        <RouterProvider router={router} />
                      </CoreNotificationProvider>
                    </RecipeApiProvider>
                  </LayoutProvider>
                </UserTheme>
              </UserProfileProvider>
              </Apollo>
            </SocketProvider>
          </ApiProvider>
        </UserProvider>
      </KeycloakProvider>
    </LocalizationProvider>
  );
}

export default App;
