import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentTimepunchQueryVariables = Types.Exact<{
  ticketId: Types.Scalars['Float']['input'];
  userId: Types.Scalars['String']['input'];
}>;


export type CurrentTimepunchQuery = { __typename?: 'Query', timeclocks: { __typename?: 'PaginatedTimeclocks', data?: Array<{ __typename?: 'CoreTimeclock', id?: number | null, start?: any | null, end?: any | null, ticketId?: number | null, todoId?: number | null, coreUserId?: string | null, user?: { __typename?: 'CoreUsers', id?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null } | null }> | null } };


export const CurrentTimepunchDocument = gql`
    query CurrentTimepunch($ticketId: Float!, $userId: String!) {
  timeclocks(
    ticketId: {eq: $ticketId}
    end: {null: true}
    user: {id: {eq: $userId}}
  ) {
    data {
      id
      start
      end
      ticketId
      todoId
      coreUserId
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useCurrentTimepunchQuery__
 *
 * To run a query within a React component, call `useCurrentTimepunchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentTimepunchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentTimepunchQuery({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCurrentTimepunchQuery(baseOptions: Apollo.QueryHookOptions<CurrentTimepunchQuery, CurrentTimepunchQueryVariables> & ({ variables: CurrentTimepunchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentTimepunchQuery, CurrentTimepunchQueryVariables>(CurrentTimepunchDocument, options);
      }
export function useCurrentTimepunchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentTimepunchQuery, CurrentTimepunchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentTimepunchQuery, CurrentTimepunchQueryVariables>(CurrentTimepunchDocument, options);
        }
export function useCurrentTimepunchSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CurrentTimepunchQuery, CurrentTimepunchQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentTimepunchQuery, CurrentTimepunchQueryVariables>(CurrentTimepunchDocument, options);
        }
export type CurrentTimepunchQueryHookResult = ReturnType<typeof useCurrentTimepunchQuery>;
export type CurrentTimepunchLazyQueryHookResult = ReturnType<typeof useCurrentTimepunchLazyQuery>;
export type CurrentTimepunchSuspenseQueryHookResult = ReturnType<typeof useCurrentTimepunchSuspenseQuery>;
export type CurrentTimepunchQueryResult = Apollo.QueryResult<CurrentTimepunchQuery, CurrentTimepunchQueryVariables>;