import { DateTime } from "luxon";
import { useSearchParam } from "../../react-submodules/utils/reactrouter";
import { useApiSearchResults } from "../../hooks/useApiSearchResults";
import { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { BillingPrintViewReportCore } from "./BillingPrint";
import './printview.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMounted } from "../../react-submodules/hooks/useMounted";

export const BillingPrintAll = () => {
    const mounted = useMounted();
    const [ printed , setPrinted ] = useState( false );
    const params = useSearchParams();
    //const [ month , setMonth ] = useSearchParam<number>( 'month' , { typeProp: 'number' , defaultValue: Number( DateTime.now().toFormat('MM') ) } );
    //const [ year , setYear ] = useSearchParam<number>( 'year' , { typeProp: 'number' , defaultValue: Number( DateTime.now().toFormat('yyyy') ) } );

    const [ searchParams , setSearchParams ] = useSearchParams();
    let month = searchParams.get('month');
    let year = searchParams.get('year');

    const [ tickets , { loading: ticketsLoading }] = useApiSearchResults( "/reports/billing/tickets", 
        {  month , year , billable: "Hourly" }
    );
    const [ contracts , { loading: contractsLoading }] = useApiSearchResults( "/reports/billing/contracts", 
        {  month , year }
    );
    const [ companies , { loading: companiesLoading }] = useApiSearchResults( "/reports/billing/companies", 
        {  month , year , active: true  }
    );

    const items = useMemo(() => {
        return companies.map( c => {
            return {
                company: c,
                tickets: tickets.filter( ticket => ticket.coreCompanyId === c.id ),
            }
        } );
    }, [ JSON.stringify( {tickets , contracts , companies }  ) ]);

    const loading = ticketsLoading || contractsLoading || companiesLoading;
    useEffect(() => {
        if (!loading && mounted()) {
            const handleAfterPrint = () => {
                window.close();
                // window.open('')
            };

            window.addEventListener('afterprint', handleAfterPrint);

            setTimeout(() => {
                if( !printed && mounted() ){
                    setPrinted( true );
                    window.print();
                }
                
            }, 1000);

            return () => {
                window.removeEventListener('afterprint', handleAfterPrint);
            };
        }
    }, [loading]);

    if( loading ) return <>loading</>

    return (
        
        <div className="print-container">
            <section id="testing">
            <div className="printheader">Billing Report</div>
            {items.map(item => (
                <Box 
                    key={item.company.id} 
                    id={item.company.id} 
                    sx={{ pageBreakAfter: 'always' }}
                >
                    <BillingPrintViewReportCore 
                        reportItem={item.company} 
                        contracts={contracts} 
                        tickets={item.tickets} 
                    />
                </Box>
            ))}
            </section>
        </div>
    );
}