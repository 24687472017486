import { useNavigate, useParams } from "react-router-dom";
import { reports } from "./reports";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";

const DefaultReport = () => {
    const nav = useNavigate();
    return <><List>{Object.keys(reports).map((reportId) => {
        return <ListItem key={reportId} ><ListItemButton color="secondary" onClick={() => nav(`/reports/${reportId}`)} >{reportId}</ListItemButton></ListItem>
    })}</List></>
}

export const Reports = () => {
    const { reportId } = useParams();
    const ReportComponent = reports[reportId as keyof typeof reports] || DefaultReport;
    return (
        <div>
            <h1>Reports</h1>
            { ReportComponent ? <ReportComponent /> : <div>Report not found</div> }
        </div>
    )
}