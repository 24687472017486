import { FC, useMemo, useState } from "react";
import { Box, Paper, Typography, IconButton, Tooltip, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { Icon } from "@iconify/react";
import { FindCoreFilePaginatedQuery } from "./gql/GetFiles.generated";
import { useDownloadFileLazyQuery } from "./gql/DownloadFile.generated";
import { useFileSizeQuery } from "./gql/FileSize.generated";
import { useDeleteCoreFileMutation } from "./gql/DeleteCoreFile.generated";

type FileType = NonNullable<NonNullable<FindCoreFilePaginatedQuery["findCoreFilePaginated"]["data"]>[number]>;
interface FileViewProps {
    file: FileType;
    onFileUpdated?: () => void;
    isClient?: boolean;
}

// Get appropriate icon based on file type (moved outside component for efficiency)
const getFileIcon = (contentType: string, fileName?: string | null): string => {
    if (!contentType) return "mdi:file-outline";
    
    const contentTypeLower = contentType.toLowerCase();
    
    // Use switch for main type detection
    switch (true) {
        case contentTypeLower.startsWith("image/"):
            return "mdi:file-image";
        case contentTypeLower.startsWith("video/"):
            return "mdi:file-video";
        case contentTypeLower.startsWith("audio/") || contentTypeLower.includes("audio"):
            return "mdi:file-music";
        case contentTypeLower.includes("pdf"):
            return "mdi:file-pdf-box";
        case contentTypeLower.includes("excel") || contentTypeLower.includes("spreadsheet") || contentTypeLower.includes("csv"):
            return "mdi:file-excel";
        case contentTypeLower.includes("word") || contentTypeLower.includes("document"):
            return "mdi:file-document";
        case contentTypeLower.includes("zip") || contentTypeLower.includes("compressed") || contentTypeLower.includes("archive"):
            return "mdi:folder-zip";
        case contentTypeLower.includes("text") || contentTypeLower.includes("json") || contentTypeLower.includes("xml"):
            return "mdi:file-document-outline";
        default:
            // If content type didn't match, try to determine by file extension
            if (fileName) {
                const extension = fileName.split('.').pop()?.toLowerCase();
                switch (extension) {
                    case 'exe':
                        return "mdi:application";
                    case 'msi':
                        return "mdi:package-variant-closed";
                    case 'pdf':
                        return "mdi:file-pdf-box";
                    case 'docx':
                    case 'doc':
                        return "mdi:file-word";
                    case 'xlsx':
                    case 'xls':
                        return "mdi:file-excel";
                    case 'pptx':
                    case 'ppt':
                        return "mdi:file-powerpoint";
                    case 'zip':
                    case 'rar':
                    case '7z':
                    case 'tar':
                    case 'gz':
                        return "mdi:folder-zip";
                    case 'mp3':
                    case 'wav':
                    case 'ogg':
                        return "mdi:file-music";
                    case 'mp4':
                    case 'avi':
                    case 'mov':
                    case 'wmv':
                        return "mdi:file-video";
                    case 'txt':
                    case 'rtf':
                        return "mdi:file-document-outline";
                    case 'html':
                    case 'htm':
                        return "mdi:language-html5";
                    case 'js':
                    case 'ts':
                        return "mdi:language-javascript";
                    case 'css':
                        return "mdi:language-css3";
                }
            }
            return "mdi:file-outline";
    }
};
// Function to check if a file key indicates a multipart file
const isMultipartFile = (key: string): boolean => {
    return key.endsWith('.part1');
};

const formatSize = (bytes: number): string => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const FileSizeView: FC<{ file: FileType }> = ({ file }) => {
    const isMultipart = useMemo( () => isMultipartFile( file.key || '' ), [ file.key ] );
    const { data } = useFileSizeQuery({
        variables: { key: file.key.replace( /\.part1$/, '' ) },
        skip: !isMultipart,
        fetchPolicy: 'cache-and-network'
    });
    const size = useMemo( () =>{
        if( !isMultipart ) return file.size;
        if( !data?.findCoreFilePaginated?.data?.length ) return 0;
        console.log( file.key );
        return data.findCoreFilePaginated.data.reduce( ( acc, curr ) => acc + ( curr.size || 0 ), 0 );
    }, [ data?.findCoreFilePaginated.hash , file.size, isMultipart ] );
    return <Typography variant="body2" color="text.secondary" noWrap>
        {formatSize(size)} { isMultipart ? ' (multipart)' : '' }
    </Typography>
}



// Function to get the base key for a multipart file
const getBaseKey = (key: string): string => {
    return key.replace(/\.part1$/, '');
};

export const FileView: FC<FileViewProps> = ({ file, onFileUpdated, isClient = false }) => {
    const [downloading, setDownloading] = useState<boolean>(false);
    const [downloadFile, { loading }] = useDownloadFileLazyQuery();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [deleteFile] = useDeleteCoreFileMutation({
        refetchQueries: ['FindCoreFilePaginated'],
        onCompleted: () => {
            if (onFileUpdated) {
                onFileUpdated();
            }
        }
    });

    // Try to format the date nicely
    const formatDate = (dateString: any): string => {
        if (!dateString) return "Unknown";
        
        try {
            const date = new Date(dateString);
            return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
        } catch (e) {
            return String(dateString);
        }
    };

    // Handle downloading a regular file
    const handleRegularFileDownload = async (key: string) => {
        try {
            const { data } = await downloadFile({
                variables: { key }
            });

            const url = data?.findCoreFilePaginated.data?.[0]?.signedUrl?.url;
            if (url) {
                // Force download using fetch API
                setDownloading(true);
                
                try {
                    const response = await fetch(url);
                    const blob = await response.blob();
                    const downloadUrl = window.URL.createObjectURL(blob);
                    
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = downloadUrl;
                    a.download = file.name || key.split('/').pop() || 'download';
                    document.body.appendChild(a);
                    a.click();
                    
                    // Clean up
                    window.URL.revokeObjectURL(downloadUrl);
                    document.body.removeChild(a);
                } catch (fetchError) {
                    console.error('Error fetching file:', fetchError);
                    // Fallback to window.open if fetch fails
                    window.open(url, '_blank');
                    alert('Direct download failed. The file may open in a new tab instead.');
                }
            } else {
                console.error('No download URL found');
                alert('Download failed: No URL available');
            }
        } catch (error) {
            console.error('Error downloading file:', error);
            alert('Download failed');
        } finally {
            setDownloading(false);
        }
    };

    // Handle downloading and merging multipart files
    const handleMultipartFileDownload = async (baseKey: string) => {
        try {
            // Search for all parts of the file
            const { data } = await downloadFile({
                variables: { key: baseKey }
            });

            if (!data?.findCoreFilePaginated.data?.length) {
                throw new Error('No file parts found');
            }

            const parts = data.findCoreFilePaginated.data.filter(part => part.signedUrl?.url);
            
            if (parts.length === 0) {
                throw new Error('No downloadable parts found');
            }

            const firstPartUrl = parts[0]?.signedUrl?.url;
            
            if (firstPartUrl) {
                alert('This is a multipart file. Due to browser limitations, only the first part will be downloaded.');
                
                // Force download using fetch API
                try {
                    const response = await fetch(firstPartUrl);
                    const blob = await response.blob();
                    const downloadUrl = window.URL.createObjectURL(blob);
                    
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = downloadUrl;
                    // Use original filename without the .part1
                    a.download = (file.name ? file.name.replace(/\.part1$/, '') : 'download');
                    document.body.appendChild(a);
                    a.click();
                    
                    // Clean up
                    window.URL.revokeObjectURL(downloadUrl);
                    document.body.removeChild(a);
                } catch (fetchError) {
                    console.error('Error fetching file:', fetchError);
                    // Fallback to window.open if fetch fails
                    window.open(firstPartUrl, '_blank');
                    alert('Direct download failed. The file may open in a new tab instead.');
                }
            } else {
                throw new Error('Failed to get download URL');
            }
        } catch (error) {
            console.error('Error handling multipart file:', error);
            alert(`Failed to download multipart file: ${error instanceof Error ? error.message : 'Unknown error'}`);
        } finally {
            setDownloading(false);
        }
    };

    const handleFileClick = async () => {
        if (!file.key || downloading) return;
        
        setDownloading(true);

        try {
            if (isMultipartFile(file.key)) {
                const baseKey = getBaseKey(file.key);
                await handleMultipartFileDownload(baseKey);
            } else {
                await handleRegularFileDownload(file.key);
            }
        } catch (error) {
            console.error('Error in download process:', error);
            setDownloading(false);
        }
    };

    // Determine if the delete button should be shown
    const showDeleteButton = useMemo(() => {
        // For non-client users (isClient is false or undefined), always show delete button
        if (!isClient) return true;
        
        // For client users, only show if clientDeletable is explicitly true
        return Boolean(file.clientDeletable);
    }, [file.clientDeletable, isClient]);

    const handleDeleteClick = (e: React.MouseEvent) => {
        e.stopPropagation(); // Prevent triggering the download
        setDeleteDialogOpen(true);
    };

    const handleDelete = async () => {
        if (!file.id) return;
        
        try {
            setDeleting(true);
            await deleteFile({
                variables: {
                    id: file.id
                }
            });
            setDeleteDialogOpen(false);
        } catch (error) {
            console.error('Error deleting file:', error);
            alert('Failed to delete file');
        } finally {
            setDeleting(false);
        }
    };

    return (
        <>
            <Tooltip 
                title={file.name || "Unnamed file"} 
                placement="top"
                arrow
                enterDelay={500}
            >
                <Paper 
                    elevation={2}
                    sx={{ 
                        p: 2, 
                        width: 220, 
                        height: 200,
                        display: 'flex', 
                        flexDirection: 'column', 
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                        '&:hover': { 
                            transform: 'translateY(-4px)',
                            boxShadow: 4
                        },
                        position: 'relative'
                    }}
                    onClick={handleFileClick}
                >
                    {showDeleteButton && (
                        <Box sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}>
                            <IconButton 
                                size="small" 
                                onClick={handleDeleteClick}
                                sx={{ 
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)', 
                                    '&:hover': { 
                                        backgroundColor: 'rgba(255, 0, 0, 0.1)' 
                                    }
                                }}
                            >
                                <Icon icon="mdi:delete" width={18} height={18} color="#f44336" />
                            </IconButton>
                        </Box>
                    )}
                    
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, position: 'relative' }}>
                        {(downloading || deleting) ? (
                            <CircularProgress size={48} />
                        ) : (
                            <Icon 
                                icon={getFileIcon(file.contentType, file.name)} 
                                width={48} 
                                height={48} 
                                color="#1976d2"
                            />
                        )}
                        {isMultipartFile(file.key || '') && (
                            <Box 
                                sx={{ 
                                    position: 'absolute', 
                                    bottom: -8, 
                                    right: -8, 
                                    backgroundColor: 'warning.main',
                                    color: 'warning.contrastText',
                                    borderRadius: '50%',
                                    width: 24,
                                    height: 24,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Icon icon="mdi:file-multiple" width={16} height={16} />
                            </Box>
                        )}
                    </Box>
                    
                    <Box sx={{ mt: 1 }}>
                        <Typography 
                            variant="subtitle1" 
                            noWrap 
                            sx={{ fontWeight: 'medium' }}
                        >
                            {file.name || "Unnamed file"}
                        </Typography>
                        
                        <Typography variant="body2" color="text.secondary" noWrap>
                            <FileSizeView file={file} />
                        </Typography>
                        
                        <Typography variant="caption" color="text.secondary" noWrap>
                            {formatDate(file.createdDateTime)}
                        </Typography>
                    </Box>
                </Paper>
            </Tooltip>

            <Dialog
                open={deleteDialogOpen}
                onClose={() => !deleting && setDeleteDialogOpen(false)}
            >
                <DialogTitle>Delete File</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete "{file.name}"? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setDeleteDialogOpen(false)} 
                        disabled={deleting}
                    >
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleDelete} 
                        color="error" 
                        disabled={deleting}
                        startIcon={deleting ? <CircularProgress size={16} /> : <Icon icon="mdi:delete" />}
                    >
                        {deleting ? 'Deleting...' : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}; 