import { Alert, Button, Chip, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { useApi } from "../../providers/Api/Api";
import { useTicket } from "../../providers/TicketProvider";
import { useTimeclock } from "../../providers/Timeclock";
import { MarkdownEditor } from "../MarkdownEditor/MarkdownEditor";

const prependZero = ( value: number ) => {
    if( value < 0 ){
        return "00"
    }
    if( value < 10 ) return `0${value}`;
    return value;
}

export const Timeclock = () => {
    const {ticket} = useTicket();
    const { refresh , activePunches } = useTimeclock();
    const [ clockOutDialog , setClockOutDialog ] = useState( false );
    const [ message , setMessage] = useState( '' );

    const [ now , setNow ] = useState( new Date().getTime() );
    const [ tick , setTick ] = useState( 1 );

    useEffect( () => {
        const interval = setInterval( () => {
            setNow( new Date().getTime() );
        }, 15000);
        return () => {
            clearInterval( interval );
        }
    }, []);


    const api = useApi();

    const ticketPunch = useMemo( () => {
        if( !ticket ) return null;
        return activePunches.find( pv => pv.ticketId === ticket.id );
    }, [ JSON.stringify(activePunches) , ticket?.id ]);

    useEffect( () => {
        if( !ticketPunch ) return;
        if( ticketPunch.end ) return;
        api.post('/core/timeclock/tick' , {
            id: ticketPunch.id
        });
    } , [tick ])


    useEffect( () => {
        
        const interval = setInterval( () => {
            setTick( pv => pv * -1 );
        }, 15000);
        return () => {
            clearInterval( interval );
        }
    }, [ ticketPunch ]);


    const punchTime = useMemo( () => {
        if( !ticketPunch ) return null;
        const start = ticketPunch?.start ? new Date( ticketPunch.start ).getTime() : 0;
        const seconds = (now - start) / 1000;
        const minutes = Math.floor( seconds / 60 ) % 60;
        const hours = Math.floor( seconds / 60 / 60 );
        if( hours ) {
            return `${hours}h ${minutes}m`
        }
        return `${minutes}m`;
      } , [ ticketPunch?.start , now ] );


    const clockIn = () => {
        if( !ticket ) return;
        api.post('/core/timeclock/clockin' , {
            ticketId: ticket.id
        }).then( () => {
            refresh();
        });
    }
    
    if( ticketPunch ){
        return <>
            <Chip size="small" color="warning"  label={punchTime} onClick={() => setClockOutDialog( true )} />
            <Dialog onClose={()=>{
                setClockOutDialog( false );
            }} maxWidth="lg" open={clockOutDialog}>
                <DialogContent sx={{width: '600px'}} >
                <MarkdownEditor label="Time Entry Message"  markdown={message} onChange={setMessage} />
                </DialogContent>
                <DialogActions>
                <Button  variant="contained" color="error" onClick={() => {
                    api.post('/core/timeclock/delete' , {
                        id: ticketPunch.id
                    }).then( () => {
                        refresh();
                        setClockOutDialog( false );
                    });
                }}>Delete Punch</Button>
                <Button  variant="contained" color="success" onClick={() => {
                    api.post('/core/timeclock/clockout' , {
                        id: ticketPunch.id,
                        message
                    }).then( () => {
                        refresh();
                        setClockOutDialog( false );
                    });
                }}>Clock Out</Button>
                
                </DialogActions>
            </Dialog>
        </>
    }
    return <Button  size="small" variant="contained" color="success" onClick={clockIn}>Clock In</Button>
    
    
    /*return <><Button  size="small" variant="contained" color="error" onClick={()=>{
        changeTicket(`${activePunches[0].ticketId}`);
    }}>Currently Clocked into {activePunches[0].at_ticketNumber}</Button></>  */
}