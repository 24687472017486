import { Container } from "@mui/material"
import * as reports from "./report/index";
import { useParams } from "react-router-dom";
import { FunctionComponent } from "react";

const DynamicReport : FunctionComponent<{reportName?: string}> = ( {reportName}  ) => {
    if( !reportName){
        return <></>
    }
    const reportElements = reports as any;
    const Report : any = reportElements[reportName as any];
    if( Report ){
        return <Report />;
    } else {
        return <>Unknown {reportName}</>;
    }
}


export const Report = () =>{
    const params = useParams<{id: string, reportId: string}>();
    
    return <DynamicReport reportName={params.reportId} />
}