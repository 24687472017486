import { Alert, Badge, Box, Chip, Divider, Typography, useTheme } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react"

import { Label } from "@mui/icons-material";
import { useApi } from "../../react-submodules/providers/Api/Api";

type ItglueSummaryType = {
    flexableAssets: Array<{
        flexibleAssetTypeName: string;
        count: number;
    }>,
}

const SectionDevicer : FunctionComponent<{ title : string}> = ({ title }) => {
    const theme = useTheme();
    return <Box sx={{
        background: theme.palette.secondary.main, color: theme.palette.secondary.contrastText, padding: 1, borderRadius: 1

    }}><Typography variant="body1">{title}</Typography></Box>
}

export const ItglueWidget : FunctionComponent<{ itglueOrganizationId: number|null }> = ({ itglueOrganizationId }) => {
    const api = useApi();
    
    const [ summary , setSummary ] = useState<ItglueSummaryType | null>( null );
    useEffect( () => {
        if( api.ready && itglueOrganizationId ){
            api.get( `/itglue/summary` , {
                organizationId : itglueOrganizationId
            } ).then( ( results ) => {
                setSummary( results.data );
            });
        }
    } , [itglueOrganizationId , api.ready]);
    if( itglueOrganizationId === null){
        return <Alert severity="warning">ITGlue is not configured</Alert>
    }
    return <>
        <Box >
        <SectionDevicer title="Flexable Assets" />
        <Divider />
        <Box sx={{margin: 2}}>
        {summary?.flexableAssets.map( ( asset ) => {
          return <Badge  max={9999} sx={{margin: 1}} badgeContent={asset.count} color="primary"><Chip color="info" label={asset.flexibleAssetTypeName} /></Badge>
        }) }
        </Box>
        </Box>
    </>
}