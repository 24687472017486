import { Checkbox, Dialog, DialogContent, DialogTitle, TextField, FormControlLabel, Grid, DialogActions, Button, Tabs, Tab } from "@mui/material"
import { FC, useEffect, useMemo, useState } from "react"
import { GraphqlGenericColumn, GridWidth } from "../../types/graphql.generics"
import { getGraphqlEndpoints, getGridWidthProps, mapGraphqlGenericColumns, mapGraphqlGenericColumnsToInnerSelect } from "../../utils/graphql.generics"
import { gql, useMutation } from "@apollo/client"
export const columnToPropDef = (column: GraphqlGenericColumn) => {
    if( column.customType ) {
        return`$${column.field}: ${column.customType}${column.createRequired ? '!' : ''}`;
    }
    return `$${column.field}: ${column.type}${column.createRequired ? '!' : ''}`;
}

const ColumnField : FC<{
    column: GraphqlGenericColumn
    value: any
    onChange: (value: any) => void
    onChangeField: (field: string, value: any) => void
} > = ({column, value, onChange, onChangeField }) => {
    if(column.CustomEdit) {
        return <column.CustomEdit value={value} onSave={onChange} column={column} onChangeField={onChangeField} />
    }
    switch(column.type) {
        case 'String':
            return <TextField fullWidth label={column.label} value={typeof value === 'number' ? value : value || ''} onChange={(e) => onChange(e.target.value)} />
        case 'Float':
        case 'Int':
            const parseValue = column.type === 'Int' ? parseInt : parseFloat;
            return <TextField fullWidth type="number" label={column.label} value={typeof value === 'number' ? value : value || ''} onChange={(e) => onChange(parseValue(e.target.value))} />
        case 'Boolean':
            return <FormControlLabel
                control={<Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />}
                label={column.label}
            />
        default:
            return <></>
    }
}

const generateInitialFormData = (columns: Array<GraphqlGenericColumn>) => {
    const initialFormData = columns.reduce((acc, column) => {
        if(typeof column.initialValue !== 'undefined') {
            acc[column.field] = column.initialValue;
        }
        return acc;
    }, {} as Record<string, any>);
    return initialFormData;
}


export const GraphqlNewItemDialog : FC<{
    open: boolean,
    baseName: string,
    onClose: () => void,
    afterSubmit?: (data: any) => void
    columns: Array<GraphqlGenericColumn | string >
    createEndpoint?: string
} > = ({open, baseName, onClose, afterSubmit, columns : columnProps, ...rest}) => {
    const { createEndpoint } = { ...getGraphqlEndpoints(baseName), ...rest };
    const columns = useMemo(() => {
        return mapGraphqlGenericColumns(columnProps);
    }, [columnProps]);
    const [tab, setTab] = useState('Main');

    const tabs = useMemo(() => {
        const t =  columns.filter(column => column.tab).map(column => column.tab).filter((tab, index, self) => self.indexOf(tab) === index);
        return [ "Main" , ...t ];
    }, [columns]);

    
    const createItemGql = useMemo(() => {
        const propDef = columns.filter(column => ( column.visible || column.updatePropField ) && !column.autoIncrement).map(columnToPropDef).join(', ');

        const columnsToProps = columns.filter(column => ( column.visible || column.updatePropField ) && !column.autoIncrement).map(column => `${column.field}: $${column.field}`).join(', ');
        return gql`
            mutation Create${createEndpoint}(  ${propDef} ) {
                ${createEndpoint}( ${columnsToProps} ) {
                    ${mapGraphqlGenericColumnsToInnerSelect(columns)}
                }
            }
        `; 
    }, [createEndpoint, columns]);
    
    const [createItem, {loading, error}] = useMutation(createItemGql);
    
    const [formData, setFormData] = useState<Record<string, any>>(generateInitialFormData(columns));
    useEffect(() => {
        if( Object.keys(formData).length === 0 ) {
            setFormData(generateInitialFormData(columns));
        }
    }, [columns]);
    const setFormDataField = (field: string, value: any) => {
        setFormData( fd => ({
            ...fd,
            [field]: value
        }));
    }
    const handleSubmit = async () => {
        const result = await createItem({ variables: formData });
        afterSubmit?.(result.data[createEndpoint]);
        onClose();
    }
    return <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
        <DialogTitle>Create New Item</DialogTitle>
        <DialogContent>
            { tabs.length > 1 && <Tabs sx={{mb: 2}} value={tab} onChange={(e, value) => setTab(value)}>
                {tabs.map(t => <Tab key={t} label={t} value={t} />)}
            </Tabs>}
            <Grid container spacing={2}>
            {columns.filter(column => !column.autoIncrement && column.editable && ( column.tab === tab || (!column.tab && tab === 'Main') )).map((column) => {
                const gw = getGridWidthProps(column);
                return <Grid key={column.field} item {...gw} sx={{marginTop: 1}} ><ColumnField 
                key={column.field} 
                column={column} 
                value={formData[column.field]} 
                onChange={(value) => {
                    setFormDataField(column.field, value);
                }} 
                onChangeField={setFormDataField}
                /></Grid>
            })}
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
    </Dialog>
}