import { useEffect, useState } from "react";
import { useCreateAutotaskTicketsMutation, CreateAutotaskTicketsMutationVariables } from "./gql/CreateNewTicket.generated";
import { GqlCompany } from "../../react-submodules/components/TicketPartialGraphql/Company/GqlCompany";
import { GqlContact } from "../../react-submodules/components/TicketPartialGraphql/Contact/GqlContact";
import { GqlTitle } from "../../react-submodules/components/TicketPartialGraphql/Title/Title";
import { Box, Button, Grid, Paper, Tabs } from "@mui/material";
import { TicketSearchTab } from "../../react-submodules/components/TicketFullCard/TicketSearchTab/TicketSearchTab";
import { TicketSearchTabPanel } from "../../react-submodules/components/TicketFullCard/TicketSearchTab/TicketSearchTabPanel";
import { TabList, TabContext } from "@mui/lab";
import { useApi } from "../../react-submodules/providers/Api";
import { useNavigate } from "react-router-dom";

export const NewTicket = () => {
    const api = useApi();
    const navigate = useNavigate();
    const [ createTicket , { loading , error } ] = useCreateAutotaskTicketsMutation(
        {
            onCompleted: (data) => {
                api.post('/core/timeclock/clockin' , {
                    ticketId: data.createAutotaskTickets.id
                }).finally( () => {
                    navigate(`/ticket/new/${data.createAutotaskTickets.id}`);
                });
            }
        }
    );
    const [ tab , setTab ] = useState<"contact" | "company">("company");
    const [ ticket , setTicket ] = useState<CreateAutotaskTicketsMutationVariables>({
        coreCompanyId: 0,
        title: "",
        status: 1,
        queueID: 29682833,
        priority: 2,
        contactlessStart: false,
        coreContactId: null
    });

    return <Grid container spacing={2}>
        <Grid item xs={6}>
           <Paper sx={{padding: 2}}> <Grid container spacing={2}>
                <Grid item xs={6}>
                    <GqlContact 
                        ticket={ticket}
                    edit={true} 
                    onChange={setTicket}
                />
                </Grid>
                <Grid item xs={6}>
                <GqlCompany 
                    ticket={ticket}
                    edit={true} 
                    onChange={setTicket}
                />
                </Grid>
                <Grid item xs={12}>
                <GqlTitle 
                        ticket={ticket}
                        edit={true} 
                        onChange={setTicket}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Box  sx={{display: 'flex', justifyContent: 'flex-end' , marginTop: 2 }}>
                <Button variant="contained" color="success" onClick={() => createTicket({ variables: ticket })}>Create Ticket</Button>
                </Box>
            </Grid>
            </Paper>
        </Grid>
        <Grid item xs={6}>
            <Paper sx={{padding: 2}}>
            <TabContext  value={tab}>
            <TabList       
                onChange={(e , v) => { setTab(v) }} 
                centered 
                variant="standard" 
                textColor="secondary"
            >
                <TicketSearchTab label="Contact" value="contact" coreCompanyId={ticket.coreCompanyId} coreContactId={ticket?.coreContactId || undefined} />
                <TicketSearchTab label="Company" value="company" coreCompanyId={ticket.coreCompanyId} />
                
            </TabList>
            <TicketSearchTabPanel skipWidth value="contact" coreCompanyId={ticket.coreCompanyId} coreContactId={ticket?.coreContactId || undefined} />
                <TicketSearchTabPanel skipWidth value="company" coreCompanyId={ticket.coreCompanyId} />
            </TabContext>
            </Paper>
        </Grid>
    </Grid>
}
