export const StatusToColor = (status: string) => {
    switch( ( status || '' ).toLowerCase().replace(/ /g , '_') ){
        case "new":
        case "customer_updated":
        case "customer_note_added":
            return "#f4b900";
        case "scheduled":
            return "#63cdd4";
        case "ready_for_delivery":
            return "#ff9c37";
        case "in_progress":
            return "#2297ed";
        case "waiting_materials":
            return "#748f90";
        case "waiting_customer":
            return "#90d36d";
        case "waiting_vendor":
            return "#ff9c37";
        case "complete":
            return "#42a567";
        case "ready": 
            return "#90d36d";
        case "watching":
            return "#3e6ca4";
        

    }
    return "#FF0000";
}

export const StatusToIcon = (status: string) => {
    switch( ( status || '' ).toLowerCase().replace(/ /g , '_') ){
        case "new":
            return "grommet-icons:new";
        case "scheduled":
            return "gridicons:scheduled";
        case "ready_for_delivery":
            return "carbon:delivery";
        case "in_progress":
            return "material-symbols:work-history-rounded";
        case "waiting_materials":
        case "waiting_customer":
        case "waiting_vendor":
            case "watching":
            return "line-md:watch";
        case "complete":
            return "fluent-mdl2:completed-solid";
        case "customer_updated":
            return "icon-park-solid:message";
        case "customer_note_added":
            return "fa6-solid:message";
        case "ready": 
        case "ready_-_customer_waiting":
            return "material-symbols:work";   
        

    }
    return "ph:question-fill";
}