import { useEffect, useMemo, useState } from "react";
import { GqlCompany } from "../../react-submodules/components/TicketPartialGraphql/Company/GqlCompany";
import { GqlContact } from "../../react-submodules/components/TicketPartialGraphql/Contact/GqlContact";
import { GqlTitle } from "../../react-submodules/components/TicketPartialGraphql/Title/Title";
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Paper, Typography } from "@mui/material";
import {  useParams } from "react-router-dom";
import { GetTicketQuery, useGetTicketQuery } from "./gql/GetTicket.generated";
import { useUpdateAutotaskTicketsMutation } from "./gql/UpdateTicket.generated";
import { GqlDescription } from "../../react-submodules/components/TicketPartialGraphql/Description/Description";
import { GqlPriority } from "../../react-submodules/components/TicketPartialGraphql/Priority";
import { GqlBillable } from "../../react-submodules/components/TicketPartialGraphql/Billable";
import { GqlTechMenu } from "../../react-submodules/components/TicketPartialGraphql/TechMenu";
import { GqlNextActionNote } from "../../react-submodules/components/TicketPartialGraphql/NextActionNote";
import { GqlNextActionDate } from "../../react-submodules/components/TicketPartialGraphql/NextActionDate";
import { GqlQueue } from "../../react-submodules/components/TicketPartialGraphql/Queue/GqlQueue";
import { GqlContactlessStart } from "../../react-submodules/components/TicketPartialGraphql/ContactlessStart/GqlContactlessStart";
import { GqlDevice } from "../../components/GqlDevice";
import { GqlStatus } from "../../react-submodules/components/TicketPartialGraphql/Status";
import { TechFinder } from "../../react-submodules/components/TechFinder";
import { TechFound } from "../../react-submodules/components/TechFinder/TechFound";
import { useTransferToTechMutation } from "./gql/CreateTransferMessage.generated";
import { useGetTransferMessageQuery } from "./gql/GetTransferMessage.generated";
import { TicketTimeclock } from "../../react-submodules/components/GraphqlComponents/TicketTimeclock/TicketTimeclock";
import { useClockOutMutation } from "./gql/Clockout.generated";
import { useCurrentTimepunchQuery } from "./gql/CurrentTimepunch.generated";
import { useUser } from "../../react-submodules/providers/User";

type Ticket = GetTicketQuery['getAutotaskTicketsById'];

export const NewTicketPageTwo = () => {
    const { id } = useParams();
    const user = useUser();
    const { data: currentTimepunchData } = useCurrentTimepunchQuery({
        variables: {
            ticketId: Number(id),
            userId: user.tokenParsed?.sub || ''
        },
        skip: !user.tokenParsed?.sub
    });
    const currentTimepunch = currentTimepunchData?.timeclocks?.data?.[0];
    const [ techFound , setTechFound ] = useState<string | null>(null);
    const { data : ticketData } = useGetTicketQuery({
        variables: {
            id: parseInt(id || '0')
        },
        fetchPolicy: 'cache-and-network'
    });
    const [ clockoutTicket  ] = useClockOutMutation();
    const [ ticket , setTicket ] = useState<Partial<Omit<Ticket, '__typename'>>>({ ...ticketData?.getAutotaskTicketsById || {} });
    
    const [ dirty , setDirty ] = useState(false);
    useEffect( () => {
        if( !dirty && JSON.stringify(ticket) !== JSON.stringify(ticketData?.getAutotaskTicketsById) ){
            setDirty( true );
        }
    }, [JSON.stringify(ticket)]);
    
    useEffect(() => {
        if (ticketData?.getAutotaskTicketsById) {
            setTicket(ticketData.getAutotaskTicketsById);
        }
    }, [JSON.stringify(ticketData)]);
    
    const [ updateTicket , { loading , error } ] = useUpdateAutotaskTicketsMutation({ onCompleted: () => setDirty(false) });
    const [ transferToTech , { loading: createTransferMessageLoading , error: createTransferMessageError } ] = useTransferToTechMutation();
    const [ showCompleteDialog , setShowCompleteDialog ] = useState(false);
    const [ transferRefetchPoolingInterval , setTransferRefetchPoolingInterval ] = useState<number>(15000);
    const { data: transferMessageData , refetch: refetchTransferMessage } = useGetTransferMessageQuery({
        variables: {
            ticketId: Number(id)
        },
        skip: !techFound,
        pollInterval: transferRefetchPoolingInterval,
        fetchPolicy: 'cache-and-network'
    });
    const accepted = useMemo( () => {
        const transferMessage = transferMessageData?.findCoreMessageChannelPaginated.data?.find( (message) => message.type === 'TICKET_TRANSFER' && message.result === 'ACCEPTED' );
        return Boolean(transferMessage && transferMessage.result === 'ACCEPTED');
    }, [transferMessageData?.findCoreMessageChannelPaginated?.data?.map( t => t.result ).join(',')]);
    useEffect( () => {
        if( accepted ){
            setTransferRefetchPoolingInterval(15000);
        } else {
            setTransferRefetchPoolingInterval(3000);
        }
    }, [accepted]);

    useEffect( () => {
        if( accepted ){
            setShowCompleteDialog(true);
        }
    }, [accepted]);

    useEffect( () => {
        if( techFound ){
            setTicket(prevTicket => ({
                ...prevTicket,
                assignedUserId: techFound 
            }));
        }
    }, [techFound]);

    const handleUpdateTicket = ( transfer: boolean = false ) => {
        if (!id) return;
        updateTicket({ 
            variables: {
                id: Number(id) as any,
                ...ticket
            } 
        }).then( () => {
            if( techFound && transfer ){
                transferToTech({
                    variables: {
                        assignedToUserId: techFound,
                        atTicketId: Number(id),
                        message: `Here is the ticket you accepted the transfer for`
                    }
                }).then( () => {
                    refetchTransferMessage();
                });
            }
        });
    };
    const handleCompleteDialog = () => {
        clockoutTicket({
            variables: {
                id: Number(currentTimepunch?.id),
                end: new Date().toISOString()
            }
        }).then( () => {
            window.location.href = '/ticketqueue/all';
        });
    }
    
    return <>
    {showCompleteDialog && <Dialog open={showCompleteDialog} onClose={() => setShowCompleteDialog(false)}>
        <DialogContent>
            <Typography>
                Ticket has been accepted
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="success" onClick={handleCompleteDialog}>
                Clock Out and Next
            </Button>
        </DialogActions>
    </Dialog>}
    <Grid container spacing={2}>
        <Grid item sm={9} xs={6} >
           <Paper sx={{padding: 2}}> <Grid container spacing={2}>
                <Grid item xs={6}>
                    <GqlContact 
                        ticket={ticket}
                    edit={true} 
                    onChange={setTicket}
                />
                </Grid>
                <Grid item xs={6}>
                <GqlCompany 
                    ticket={ticket}
                    edit={true} 
                    onChange={setTicket}
                />
                </Grid>
                <Grid item xs={12}>
                <GqlTitle 
                        ticket={ticket}
                        edit={true} 
                        onChange={setTicket}
                    />
                </Grid>
                <Grid item xs={12}>
                    <GqlDescription
                        ticket={ticket}
                        edit={true} 
                        onChange={setTicket}
                    />
                </Grid>
                <Grid item xs={12}>
                    <GqlNextActionNote
                        ticket={ticket}
                        edit={true} 
                        onChange={setTicket}
                    />
                </Grid>
                <Grid item xs={12}>
                    <GqlNextActionDate
                        ticket={ticket}
                        edit={true} 
                        onChange={setTicket}
                    />
                </Grid>
                <Grid item xs={6}>
                    <GqlQueue
                        ticket={ticket}
                        edit={true} 
                        onChange={setTicket}
                    />
                </Grid>
                <Grid item xs={6}>
                    <GqlStatus
                        ticket={ticket}
                        edit={true} 
                        onChange={setTicket}
                    />
                </Grid>
                <Grid item xs={6}>
                    <GqlPriority
                        ticket={ticket}
                        edit={true} 
                        onChange={setTicket}
                    />
                </Grid>
                <Grid item xs={6}>
                    <GqlBillable
                        ticket={ticket}
                        edit={true} 
                        onChange={setTicket}
                    />
                </Grid>
                <Grid item xs={6}>
                    <GqlTechMenu
                        ticket={ticket}
                        edit={true}
                        onChange={setTicket}
                    />
                </Grid>
                <Grid item xs={12}>
                    <GqlContactlessStart
                        ticket={ticket}
                        edit={true}
                        onChange={setTicket}
                    />
                </Grid>
                <Grid item xs={12}>
                    <GqlDevice
                        ticket={ticket}
                        edit={true}
                        onChange={setTicket}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2 }}>
                    <TicketTimeclock ticketId={Number(id)} />
                    
                    <Button disabled={dirty} variant="contained" color="info" onClick={() => {
                        window.location.href = `/ticketqueue/all/${id}`;
                    }}>
                        Goto Ticket
                    </Button>
                    <Button 
                        variant="contained" 
                        color="success" 
                        onClick={() => handleUpdateTicket( (techFound && !accepted ) ? true : false)}
                    >
                        Update Ticket{ (techFound && !accepted ) ? ` and Transfer` : '' }
                    </Button>
                    
                </Box>
            </Grid>
            </Paper>
        </Grid>
        <Grid item sm={3} xs={6} >
            <Paper sx={{padding: 2}}>
                { !techFound && <TechFinder ticketId={Number(id)} messageEpicId={`ticket-${Number(id)}-4`} onTechFound={setTechFound} /> }
                { techFound && <TechFound techFound={techFound} accepted={accepted} resend={() => {
                    transferToTech({
                        variables: {
                            assignedToUserId: techFound,
                            atTicketId: Number(id),
                            message: `Here is the ticket you accepted the transfer for`
                        }
                    }).then( () => {
                        refetchTransferMessage();
                    });
                }} /> }
            </Paper>
        </Grid>
    </Grid></>
}
