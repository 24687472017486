import { Box, FormControl, InputAdornment, InputLabel, ListSubheader, MenuItem, Select, TextField } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import { useFindAutotaskConfigurationItemsQuery } from "./gql/FindAutotaskConfigurationItems.generated";
import { useGetDeviceCoreContactByIdQuery } from "./gql/GetCoreContactById.generated";
import { useGetDeviceCoreCompanyByIdQuery } from "./gql/GetCoreCompanyById.generated";

// Define a type for devices with likeliness rank
type DeviceWithLikelynessRank = {
  id: number;
  referenceTitle: string;
  DattormmDevice?: {
    hostname?: string | null;
    lastLoggedInUser?: string | null;
    operatingSystem?: string | null;
  } | null;
  likelynessRank: 'likely' | 'possible' | 'unmatched';
};

// Define the type for the ticket prop
type WithConfigurationItemID = {
  configurationItemID?: number | null;
  companyID?: number | null;
  coreContactId?: number | null;
  coreCompanyId?: number | null;
};

export const GqlDevice = <T extends WithConfigurationItemID>({ 
  ticket,
  edit = false,
  onChange
}: {
  ticket: T;
  edit?: boolean;
  onChange?: (current: T | ((prev: T) => T)) => void;
}) => {
  // Use coreCompanyId to get company information
  const { data: companyData } = useGetDeviceCoreCompanyByIdQuery({
    variables: {
      id: Number(ticket?.coreCompanyId || 0)
    },
    skip: !ticket?.coreCompanyId,
    fetchPolicy: 'cache-and-network'
  });

  // Use coreContactId to get contact information
  const { data: contactData } = useGetDeviceCoreContactByIdQuery({
    variables: {
      id: Number(ticket?.coreContactId || 0)
    },
    skip: !ticket?.coreContactId,
    fetchPolicy: 'cache-and-network'
  });

  // Get the autotaskCompanyId from the company data
  const companyID = companyData?.getCoreCompaniesById?.autotaskCompanyId || ticket?.companyID || null;
  const contact = contactData?.getCoreContactById;

  const [searchTextDebounced, setSearchTextDebounced] = useState("");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchTextDebounced(searchText);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [searchText]);

  const { data, loading } = useFindAutotaskConfigurationItemsQuery({
    variables: {
      page: 0,
      limit: 10000,
      companyID: companyID ? { eq: companyID } : undefined
    },
    skip: !companyID,
    fetchPolicy: 'cache-and-network'
  });

  // Helper function to determine likeliness rank
  const likelynessRank = (device: any) => {
    const usernames = [];
    
    const emailEnding = contact?.email?.split("@")[0]?.toLowerCase() || "";
    if (emailEnding) {
      usernames.push(emailEnding);
    }
    
    const firstName = contact?.firstName?.toLowerCase();
    const lastName = contact?.lastName?.toLowerCase();

    if (firstName && lastName) {
      usernames.push(`${firstName}.${lastName}`.toLowerCase());
      usernames.push(`${firstName}${lastName}`.toLowerCase());
      usernames.push(`${firstName[0]}${lastName}`.toLowerCase());
      usernames.push(`${firstName}${lastName[0]}`.toLowerCase());
    }
    
    const lastUser = (
      device?.DattormmDevice?.lastLoggedInUser?.split("\\")[1] || 
      device?.DattormmDevice?.lastLoggedInUser?.split("\\")[0] || 
      ""
    ).toLowerCase();
    
    if (usernames.includes(lastUser)) {
      return 'likely';
    }
    
    if (!lastName || !firstName) {
      return 'unmatched';
    }

    if (lastUser.includes(lastName) || lastUser.includes(firstName)) {
      return 'possible';
    }
    
    return 'unmatched';
  };

  const devices = useMemo(() => {
    return data?.findAutotaskConfigurationItemsPaginated.data || [];
  }, [JSON.stringify(data)]);

  const filteredDevices = useMemo(() => {
    const lowerSearchText = searchTextDebounced?.toLowerCase() || "";
    
    return devices
      .filter((device) => {
        return (
          device.DattormmDevice?.hostname?.toLowerCase().includes(lowerSearchText) || 
          device.DattormmDevice?.lastLoggedInUser?.toLowerCase().includes(lowerSearchText) || 
          device.id === ticket.configurationItemID
        );
      })
      .map(device => ({
        ...device,
        likelynessRank: likelynessRank(device)
      })) as DeviceWithLikelynessRank[];
  }, [JSON.stringify(devices), searchTextDebounced, ticket.configurationItemID, contact]);

  // Group devices by likeliness rank
  const likelyDevices = useMemo(() => {
    return filteredDevices.filter(d => d.likelynessRank === 'likely');
  }, [filteredDevices]);

  const possibleDevices = useMemo(() => {
    return filteredDevices.filter(d => d.likelynessRank === 'possible');
  }, [filteredDevices]);

  const unmatchedDevices = useMemo(() => {
    return filteredDevices.filter(d => d.likelynessRank === 'unmatched').slice(0, 10);
  }, [filteredDevices]);

  // Helper function to extract username from lastLoggedInUser
  const getUsername = (lastLoggedInUser?: string | null) => {
    if (!lastLoggedInUser) return "";
    const parts = lastLoggedInUser.split("\\");
    return parts.length > 1 ? parts[1] : parts[0];
  };

  // Render the username with a null check
  const renderUsername = (lastLoggedInUser?: string | null) => {
    if (!lastLoggedInUser) return null;
    return (
      <Box component="span" sx={{ marginLeft: 1 }}>
        ({getUsername(lastLoggedInUser)})
      </Box>
    );
  };

  if (!edit) {
    const selectedDevice = devices.find(d => d.id === ticket.configurationItemID);
    if (!selectedDevice || !selectedDevice.DattormmDevice) {
      return <Box>No device selected</Box>;
    }
    
    return (
      <Box>
        {selectedDevice.DattormmDevice.hostname}
        {renderUsername(selectedDevice.DattormmDevice.lastLoggedInUser)}
      </Box>
    );
  }

  return (
    <FormControl variant='outlined' style={{ width: '100%' }}>
      <InputLabel sx={{ backgroundColor: 'white', margin: '2px' }}>Device</InputLabel>
      <Select 
        onChange={(e) => {
          const deviceId = e.target.value as number;
          const selectedDevice = devices.find(d => d.id === deviceId);
          
          if (onChange) {
            onChange(current => ({
              ...current,
              configurationItemID: selectedDevice?.id || null
            }));
          }
        }} 
        value={ticket.configurationItemID || ''} 
        onClose={() => setSearchText("")} 
        fullWidth
      >
        <ListSubheader>
          <TextField
            size="small"
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="material-symbols:search" />
                </InputAdornment>
              )
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setSearchTextDebounced(searchText);
              }
              if (e.key !== "Escape") {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        
        <ListSubheader>Likely Match</ListSubheader>
        {likelyDevices.map((device) => (
          <MenuItem sx={{ marginLeft: 2 }} key={device.id} value={device.id || ''}>
            {device.DattormmDevice?.hostname}
            {renderUsername(device.DattormmDevice?.lastLoggedInUser)}
          </MenuItem>
        ))}
        
        <ListSubheader>Possible Match</ListSubheader>
        {possibleDevices.map((device) => (
          <MenuItem sx={{ marginLeft: 2 }} key={device.id} value={device.id || ''}>
            {device.DattormmDevice?.hostname}
            {renderUsername(device.DattormmDevice?.lastLoggedInUser)}
          </MenuItem>
        ))}
        
        <ListSubheader>Everything Else</ListSubheader>
        {unmatchedDevices.map((device) => (
          <MenuItem sx={{ marginLeft: 2 }} key={device.id} value={device.id || ''}>
            {device.DattormmDevice?.hostname}
            {renderUsername(device.DattormmDevice?.lastLoggedInUser)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}; 