import { Checkbox, Dialog, DialogContent, DialogTitle, TextField, FormControlLabel, Grid, DialogActions, Button, Tabs, Tab } from "@mui/material"
import { FC, useMemo, useState } from "react"
import { GraphqlGenericColumn } from "../../types/graphql.generics"
import { columnToPropDef, getGraphqlEndpoints, getGridWidthProps, mapGraphqlGenericColumns, mapGraphqlGenericColumnsToInnerSelect } from "../../utils/graphql.generics"
import { gql, useMutation } from "@apollo/client"


const ColumnField : FC<{
    column: GraphqlGenericColumn
    row: Record<string, any>
    value: any
    onChange: (value: any) => void
    onChangeField: (field: string, value: any) => void
} > = ({column, row, value, onChange , onChangeField}) => {
    if( column.CustomEdit ){
        return <column.CustomEdit column={column} row={row} value={value} onSave={(value : any ) => onChange(value)} onChangeField={onChangeField} />
    }
    switch(column.type) {
        case 'String':
            return <TextField disabled={column.primaryKey} fullWidth label={column.label} value={value || ''} onChange={(e) => onChange(e.target.value)} />
        case 'Float':
        case 'Int':
            const parseValue = column.type === 'Float' ? parseFloat : parseInt;
            return <TextField  disabled={column.primaryKey} fullWidth type="number" label={column.label} value={value || ''} onChange={(e) => onChange( parseValue(e.target.value) )} />
        case 'Boolean':
            return <FormControlLabel
                control={<Checkbox  disabled={column.primaryKey} checked={value} onChange={(e) => onChange(e.target.checked)} />}
                label={column.label}
            />
        default:
            return <></>
    }
}

const buildProps = (columns: Array<GraphqlGenericColumn> ) => {
    return columns.filter(column => column.visible|| column.updatePropField).map(columnToPropDef).join(', ');
}

const buildColumnsToProps = (columns: Array<GraphqlGenericColumn> ) => {
    // const columnsToProps = columns.filter(column => column.visible).map(column => `${column.field}: $${column.field}`).join(', ');
    return columns.filter(column => column.visible || column.updatePropField).map(column => `${column.field}: $${column.field}`).join(', ');
}

export const GraphqlEditItemDialog : FC<{
    open: boolean,
    baseItem: Record<string, any>,
    baseName: string,
    onClose: () => void,
    afterSubmit?: (data: any) => void
    columns: Array<GraphqlGenericColumn | string >
    editEndpoint?: string
} > = ({baseItem , open, baseName, onClose, afterSubmit, columns : columnProps, ...rest}) => {
    const { editEndpoint } = { ...getGraphqlEndpoints(baseName), ...rest };
    const columns = useMemo(() => {
        return mapGraphqlGenericColumns(columnProps);
    }, [columnProps]);
    const [tab, setTab] = useState('Main');

    const tabs = useMemo(() => {
        const t =  columns.filter(column => column.tab).map(column => column.tab).filter((tab, index, self) => self.indexOf(tab) === index);
        return [ "Main" , ...t ];
    }, [columns]);
    
    const createItemGql = useMemo(() => {
        const propDef = buildProps(columns);
        const columnsToProps = buildColumnsToProps(columns);
        console.log( { propDef , columnsToProps } );
        return gql`
            mutation Edit${editEndpoint}(  ${propDef} ) {
                ${editEndpoint}( ${columnsToProps} ) {
                    ${mapGraphqlGenericColumnsToInnerSelect(columns)}
                }
            }
        `; 
    }, [editEndpoint, columns]);
    
    const [createItem, {loading, error}] = useMutation(createItemGql);
    
    const [formData, setFormData] = useState<Record<string, any>>(baseItem);
    const setFormDataField = (field: string, value: any) => {
        console.log( { field , value } );
        setFormData( fd => ({
            ...fd,
            [field]: value
        }));
    }
    const handleSubmit = async () => {
        const result = await createItem({ variables: formData });
        afterSubmit?.(result.data[editEndpoint]);
        onClose();
    }
    return <Dialog maxWidth="lg" open={open} onClose={onClose}>
        <DialogTitle>Edit Item</DialogTitle>
        <DialogContent>
        { tabs.length > 1 && <Tabs sx={{mb: 2}} value={tab} onChange={(e, value) => setTab(value)}>
                {tabs.map(t => <Tab key={t} label={t} value={t} />)}
            </Tabs>}
            <Grid container spacing={2}>
            {columns.filter(column => column.editable !== false && ( column.tab === tab || (!column.tab && tab === 'Main') )).map((column) => {
                const gw = getGridWidthProps(column);
                return <Grid key={column.field} item {...gw} sx={{marginTop: 1}} ><ColumnField key={column.field} column={column} row={formData} value={formData[column.field]} onChange={(value) => {
                    setFormDataField(column.field, value);
                }} onChangeField={setFormDataField} /></Grid>
})}
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
    </Dialog>
}