import { gql, useQuery } from "@apollo/client"
import { Box, Button, Chip, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material"
import { PieChart } from "@mui/x-charts";
import { DateTime } from "luxon";
import { useMemo, useState } from "react"
import { LabeledBox } from "../../../react-submodules/components/LabeldBox";
import { StyledTable, TableColumnDef } from "../../../react-submodules/components/Table";

const FULL_MANAGED_CONTRACT_IDS = [1 , 4];
const SECURITY_MANAGED_CONTRACT_IDS = [2 , 6];

interface PatchingPolicy {
    __typename: string;
    name: string;
    lastRun: string;
    lastSync: string;
}

interface DattormmDevice {
    __typename: string;
    site: {
        coreCompanyId: number;
        coreCompany: {
            name: string;
        }
    }
    hostname: string;
    lastSeen: string;
    patchStatus: string;
    antivirusStatus: string;
    antivirusProduct: string | null;
    patchesInstalled: number;
    lastReboot: string;
    patchesApprovedPending: number;
    patchesNotApproved: number;
    rebootRequired: boolean;
    online: boolean;
    patchingPolicies: PatchingPolicy[];
    assignments: Assignment[];
}
interface Assignment {
    instanceId: number;
    instance: Instance;
}

interface Instance {
    contractId: number;
}
interface FindDattormmDevicesPaginatedData {
    findDattormmDevicesPaginated: {
        data: DattormmDevice[];
        totalCount: number;
        page: number;
        limit: number;
    }
}

const GET_PATCHES = gql`
query FindDattormmDevicesPaginated($limit: Int!, $page: Int! , $date : Date) {
    findDattormmDevicesPaginated(operatingSystem: { like: "windows" }, archived: { eq: false }, lastSeen: { gte: $date }, limit: $limit, page: $page) {
        data {
            hostname
            lastSeen
            site {
                coreCompanyId
                coreCompany {
                    name
                }
            }
            patchStatus
            operatingSystem
            antivirusStatus
            antivirusProduct
            patchesInstalled
            lastReboot
            rebootRequired
            online
            patchesApprovedPending
            patchesNotApproved
            patchingPolicies {
                name
                lastRun
                lastSync
            }
            assignments {
                instanceId
                instance {
                    contractId
                }
            }
        }
        totalCount
        page
        limit
    }
}
`;
const buildPatchingPolicies = (devices : DattormmDevice[]) : { id : string , label : string , value : number , color : string }[] => {
    if (!devices) return [];

        const policyCount: { [key: string]: number } = {};

        devices.forEach(device => {
            let policyName: string;

            if (device.patchingPolicies.length === 0) {
                policyName = "None";
            } else if (device.patchingPolicies.length === 1) {
                policyName = device.patchingPolicies[0].name;
            } else {
                policyName = "Multiple";
            }

            policyCount[policyName] = (policyCount[policyName] || 0) + 1;
        });

        const patchingPolicies =  Object.entries(policyCount).map(([name, count]) => ({ id : name, label: name, value : count , color : name === "None" ? "red" : name === "Multiple" ? "#5DE2E7" : "green" }));
        return patchingPolicies;
}
const buildRebootRequired = (devices : DattormmDevice[]) : { id : string , label : string , value : number , color : string }[] => {
    if (!devices) return [];
    const rebootRequiredCount: { 
        "Reboot Required": number, 
        "Reboot Required but Recent Reboot": number , 
        "No Reboot Required": number 
    } = { 
        "Reboot Required": 0 , 
        "Reboot Required but Recent Reboot": 0 , 
        "No Reboot Required": 0 
    };

    devices.forEach(device => {
        const lastReboot = DateTime.fromISO(device.lastReboot);
        const lastOnline = device.online ? DateTime.now() : DateTime.fromISO(device.lastSeen);
        const daysSinceReboot = lastOnline.diff(lastReboot, 'days').days;
        if (device.rebootRequired) {
            if (daysSinceReboot <= 7) {
                rebootRequiredCount["Reboot Required but Recent Reboot"] += 1;
            } else {
                rebootRequiredCount["Reboot Required"] += 1;
            }
        } else {
            rebootRequiredCount["No Reboot Required"] += 1;
        }
        
    });
    return [
        { id : "Reboot Required" , label : "Reboot Required" , value : rebootRequiredCount["Reboot Required"] , color : "red" },
        { id : "Reboot Required but Recent Reboot" , label : "Reboot Required but Recent Reboot" , value : rebootRequiredCount["Reboot Required but Recent Reboot"] , color : "#BFD641" },
        { id : "No Reboot Required" , label : "No Reboot Required" , value : rebootRequiredCount["No Reboot Required"] , color : "green" }
    ]
}


type ContractCount = {
    count: number;
    companies: {
        [key: number | string]: { 
            count: number;
            name: string;
            id: number;
        };
    }
}

const buildNoContracts = (devices : DattormmDevice[])  => {
    if (!devices) return { "all" : [] , "byCompany" : [] };
    const noContractsCount: {         
        "No Contracts": ContractCount,
        "Full Managed": ContractCount,
        "Security Managed": ContractCount,
        "Other": ContractCount

    } = { 
        "Full Managed": { count: 0 , companies: {} }, 
        "Security Managed": { count: 0 , companies: {} }, 
        "No Contracts": { count: 0 , companies: {} },
        "Other": { count: 0 , companies: {} }
    };
    const updateCount = ( contractType : keyof typeof noContractsCount , companyId : number , companyName : string ) => {
        noContractsCount[contractType].count += 1;
        if( !noContractsCount[contractType].companies[companyId]){
            noContractsCount[contractType].companies[companyId] = { count: 0 , name: companyName , id: companyId };
        }
        noContractsCount[contractType].companies[companyId].count += 1;
    }
    devices.forEach(device => {
        if(device.assignments.length === 0){
            updateCount("No Contracts", device.site.coreCompanyId, device?.site?.coreCompany?.name || "Unknown");
        } else {
            if(device.assignments.some(assignment => FULL_MANAGED_CONTRACT_IDS.includes(assignment.instance.contractId))){
                updateCount("Full Managed", device.site.coreCompanyId, device?.site?.coreCompany?.name || "Unknown");
            } else if(device.assignments.some(assignment => SECURITY_MANAGED_CONTRACT_IDS.includes(assignment.instance.contractId))){
                updateCount("Security Managed", device.site.coreCompanyId, device?.site?.coreCompany?.name || "Unknown");
            } else {
                updateCount("Other", device.site.coreCompanyId, device?.site?.coreCompany?.name || "Unknown");
            }
        }
    });
    return { "all" : [
        { id : "Full Managed" , label : "Full Managed" , value : noContractsCount["Full Managed"].count , color : "green" , companies: noContractsCount["Full Managed"].companies },
        { id : "Security Managed" , label : "Security Managed" , value : noContractsCount["Security Managed"].count , color : "blue" , companies: noContractsCount["Security Managed"].companies },
        { id : "No Contracts" , label : "No Contracts" , value : noContractsCount["No Contracts"].count , color : "red" , companies: noContractsCount["No Contracts"].companies },
        { id : "Other" , label : "Other" , value : noContractsCount["Other"].count , color : "#5DE2E7" , companies: noContractsCount["Other"].companies }
    ] , "byCompany" : [
        ...Object.keys( noContractsCount["Full Managed"].companies ).map( companyId => {
            const company = noContractsCount["Full Managed"].companies[companyId];
            return { id : `"FullManaged"-${companyId}` , label : `${company.name} F(${company.count})` , value : company.count , companyId : companyId  }
        }),
        ...Object.keys( noContractsCount["Security Managed"].companies ).map( companyId => {
            const company = noContractsCount["Security Managed"].companies[companyId];
            return { id : `"SecurityManaged"-${companyId}` , label : `${company.name} S(${company.count})` , value : company.count , companyId : companyId  }
        }),
        ...Object.keys( noContractsCount["No Contracts"].companies ).map( companyId => {
            const company = noContractsCount["No Contracts"].companies[companyId];
            return { id : `"NoContracts"-${companyId}` , label : `${company.name} N(${company.count})` , value : company.count , companyId : companyId  }
        }),
        ...Object.keys( noContractsCount["Other"].companies ).map( companyId => {
            const company = noContractsCount["Other"].companies[companyId];
            return { id : `"Other"-${companyId}` , label : `${company.name} O(${company.count})` , value : company.count , companyId : companyId  }
        })
    ] }
}

const filters = {
    "Reboot Required but Recent Reboot" : ( devices : DattormmDevice[] ) => {
        return devices.filter( device => {
            const lastReboot = DateTime.fromISO(device.lastReboot);
            const lastOnline = device.online ? DateTime.now() : DateTime.fromISO(device.lastSeen);
            const daysSinceReboot = lastOnline.diff(lastReboot, 'days').days;
            return device.rebootRequired && daysSinceReboot <= 7;
        })
    },
    "Reboot Required" : ( devices : DattormmDevice[] ) => {
        return devices.filter( device => {
            const lastReboot = DateTime.fromISO(device.lastReboot);
            const lastOnline = device.online ? DateTime.now() : DateTime.fromISO(device.lastSeen);
            const daysSinceReboot = lastOnline.diff(lastReboot, 'days').days;
            return device.rebootRequired && daysSinceReboot > 7;
        })
    },
    "No Reboot Required" : ( devices : DattormmDevice[] ) => {
        return devices.filter( device => {
            const lastReboot = DateTime.fromISO(device.lastReboot);
            const now = DateTime.now();
            const daysSinceReboot = now.diff(lastReboot, 'days').days;
            return !device.rebootRequired;
        })
    },
    "None": ( devices : DattormmDevice[] ) => {
        return devices.filter( device => {
            return device.patchingPolicies.length === 0;
        })
    },
    "Multiple": ( devices : DattormmDevice[] ) => {
        return devices.filter( device => {
            return device.patchingPolicies.length > 1;
        })
    },
    "default": ( devices : DattormmDevice[] , filterName : string ) => {
        return devices.filter( d  => {
            const policyName = d?.patchingPolicies?.[0]?.name;
            return policyName === filterName;
        });
    }
}

const deviceColumns : Array<TableColumnDef<DattormmDevice>> = [
    { field : 'hostname', headerName : 'Hostname' , flex: 1 },
    
    { field : 'patchStatus', headerName : 'Patch Status' , flex: 1 },
    { field : 'operatingSystem', headerName : 'Operating System' , flex: 1 },
    { field : 'lastReboot', headerName : 'Last Reboot' , flex: 1 },
    { field : 'rebootRequired', headerName : 'Reboot Required' , flex: 1 , Element: ( { cell } : { cell : any })  => {
        return <Chip label={cell ? "Yes" : "No" } color={cell ? "warning" : "success"} />
    }},
    { field : 'lastSeen', headerName : 'Last Seen' , flex: 1 , Element: ( { cell , row } ) => {
        if( row.online){
            return <Chip label="Online" color="success" />
        }
        return <Chip label={cell} color="info" />
    }},
]

const contractColumns : Array<TableColumnDef<{ id: string | number , name: string , count: number }>> = [
    { field : 'id', headerName : 'ID' , flex: 1 },
    { field : 'name', headerName : 'Name' , flex: 1 },
    { field : 'count', headerName : 'Count' , flex: 1 , sortable: true },
    { field : 'links', headerName : 'Links' , flex: 1 , sortable: false , Element: ( { cell , row } : { cell : any , row : any })  => {
        return <><a href={`/company/${row.id}/report/DeviceV2`} target="_blank"><Button>Devices</Button></a><a href={`/company/${row.id}/contracts`} target="_blank"><Button>Contracts</Button></a></>
    }},
]

export const Patches = () => {
    const date = DateTime.now().minus({ days: 30 }).toFormat("yyyy-MM-dd");
    const { data} = useQuery<FindDattormmDevicesPaginatedData>(GET_PATCHES , { variables: { limit : 10000 , page : 0 , date : date}})
    const [filter , setFilter] = useState<keyof typeof filters | null>(null);
    const [ contractCompanies , setContractCompanies ] = useState< Array<{ id: string , name: string , count: number }> | null>(null);
    const filteredDevices = useMemo(() => {
        if (!filter) return data?.findDattormmDevicesPaginated?.data || [] as DattormmDevice[];
        return filters[filter || "default"](data?.findDattormmDevicesPaginated?.data || [] as DattormmDevice[] , filter);
    }, [filter, data]);

    const fullyManagedDevices = useMemo(() => {
        
        return (data?.findDattormmDevicesPaginated?.data || [] as DattormmDevice[]).filter(device => device.assignments.some(assignment => FULL_MANAGED_CONTRACT_IDS.includes(assignment.instance.contractId)));
    }, [data]);
    
    const {patchingPolicies , rebootRequired , noContracts} = useMemo(() => {
        const patchingPolicies = buildPatchingPolicies(fullyManagedDevices || [] as DattormmDevice[])
        const rebootRequired = buildRebootRequired(fullyManagedDevices || [] as DattormmDevice[])
        const noContracts = buildNoContracts(data?.findDattormmDevicesPaginated?.data || [] as DattormmDevice[])
        return { patchingPolicies , rebootRequired , noContracts }
    }, [data , fullyManagedDevices ]);

    return <Box>
        <Dialog fullWidth maxWidth="lg" open={!!contractCompanies} onClose={() => setContractCompanies(null)}>
            <DialogTitle>
                Contracts
            </DialogTitle>
            <DialogContent>
                <StyledTable defaultSort={[{field: 'count', direction: 'desc'}]} rows={contractCompanies || []} columns={contractColumns} />
            </DialogContent>
        </Dialog>
        <Grid container spacing={2}>
            <Dialog fullWidth maxWidth="lg" open={!!filter} onClose={() => setFilter(null)}>
                <DialogTitle>
                    {filter}
                </DialogTitle>
                <DialogContent>
                    <StyledTable rows={filteredDevices} columns={deviceColumns} />
                </DialogContent>
            </Dialog>
            <Grid item xs={12}   sx={{ position: "relative" , display: "flex" , justifyContent: "center" , alignItems: "center" , height: "100%"}}>
                <LabeledBox label={`Contracts (${(data?.findDattormmDevicesPaginated?.data || [] as DattormmDevice[]).length} devices)`}>
                    <Box sx={{ width: "600px" , height: "600px" , display: 'flex'}}>
                        <PieChart
                        height={600}
                         slotProps={{
                            legend: { hidden: true },
                          }}
                        onItemClick={( e , g , item : any ) => {
                            console.log( item )
                            if(item.companyId){
                                //@ts-ignore
                                window.open(`/company/${item.companyId}/report/DeviceV2`, `CONTRACTS-${item.companyId}`)

                            } if( item.companies ){
                                setContractCompanies(Object.values(item.companies))
                            }
                            // setFilter(item.id as keyof typeof filters)
                        }}
                            series={[
                                {
                                    data: noContracts.all,
                                    innerRadius: 0,
                                    outerRadius: 180,
                                },
                                {
                                
                                    data: noContracts.byCompany,
                                    innerRadius: 80,
                                    outerRadius: 160
                                }
                            ]}
                        />
                    </Box>
                </LabeledBox>
            </Grid>
            <Grid item xs={12}   sx={{ position: "relative" , display: "flex" , justifyContent: "center" , alignItems: "center" , height: "100%"}}>
                <LabeledBox label={`Patching Policies (${fullyManagedDevices.length} devices)`}>
                    <Box sx={{ width: "800px" , height: "300px" , display: 'flex', alignItems: 'center' }}>
                        <PieChart
                        onItemClick={( e , g , item ) => {
                            setFilter(item.id as keyof typeof filters)
                        }}
                            series={[
                                {
                                    data: patchingPolicies
                                },
                            ]}
                        />
                    </Box>
                </LabeledBox>
            </Grid>
            <Grid item xs={12} sx={{ position: "relative" , display: "flex" , justifyContent: "center" , alignItems: "center" , height: "100%"}}>
                <LabeledBox label={`Reboot Required (${fullyManagedDevices.length} devices)`}>
                    <Box sx={{ width: "800px" , height: "300px" , display: 'flex', alignItems: 'center' }}>
                        <PieChart
                            onItemClick={( e , g , item ) => {
                                setFilter(item.id as keyof typeof filters)
                            }}
                            series={[
                                {
                                    data: rebootRequired
                                },
                            ]}
                        />
                    </Box>
                </LabeledBox>
            </Grid>
        </Grid>
    </Box>
}