import { useCallback, useState } from "react";
import { debounce } from "lodash";
import { Autocomplete, TextField } from "@mui/material";
import { useSearchCoreUsersQuery } from "../../../GraphqlEditItemDialog/CoreUserSearch.generated";
import { useSearchCoreGroupsQuery } from "./CoreGroupSearch.generated";


interface CoreGroupTemplateEditProps {
    value: number | null;
    onSave: (newValue: number | null) => void;
    label?: string;
    disabled?: boolean;
  }
  
  interface GroupOption {
    id: number;
    label: string;
  }

export function CoreGroupTemplateEdit({ value, onSave, label = 'Group', disabled = false }: CoreGroupTemplateEditProps) {
    const [searchTerm, setSearchTerm] = useState('');
    const { data, loading } = useSearchCoreGroupsQuery({
      variables: { search: searchTerm },
    });
  
    // Convert users data to options format
    const options: GroupOption[] = data?.findCoreGroupPaginated.data?.map(group => ({
      id: group.id || 0,
      label: `${group.name || ''}`,
    })).filter(g=>g.id !== 0) || [];
  
    // Find the selected option based on value
    const selectedOption = options.find(option => option.id === value) || null;
  
    // Debounced search handler
    const debouncedSearch = useCallback(
      debounce((term: string) => {
        setSearchTerm(term);
      }, 300),
      []
    );
    return (
      <Autocomplete
        value={selectedOption}
        onChange={(_, newValue) => {
            onSave(newValue?.id || null);
        }}
        options={options}
        loading={loading}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            onChange={(e) => debouncedSearch(e.target.value)}
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.label}
        filterOptions={(x) => x} // Disable client-side filtering
        noOptionsText={searchTerm ? 'No groups found' : 'Start typing to search'}
      />
    );
  }