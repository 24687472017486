import { FC, useEffect, useState, useRef } from "react";
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, CircularProgress, Typography } from '@mui/material';
import { InlineIcon } from '@iconify/react';
import { useTicketStatusesQuery } from "./gql/TicketStatuses.generated";
import { StatusToColor, StatusToIcon } from "../../../components/TicketPartials/status.util";

type WithStatus = {
  status?: number | null;
}

export const GqlStatus = <T extends WithStatus>({ 
  ticket,
  edit = false,
  onChange,
  iconOnly = false
}: {
  ticket: T;
  edit?: boolean;
  onChange?: (current: T | ((prev: T) => T)) => void;
  iconOnly?: boolean;
}) => {
  const { data, loading: loadingStatuses, error } = useTicketStatusesQuery({
    fetchPolicy: 'cache-and-network'
  });
  const loading = loadingStatuses && (data?.TicketStatuses || []).length === 0;
  const [value, setValue] = useState<string>('');
  const prevTicketIdRef = useRef<string | number | null>(null);
  
  useEffect(() => {
    // Only reset when ticket ID changes (different ticket loaded)
    const currentTicketId = (ticket as any)?.id || null;
    if (prevTicketIdRef.current !== currentTicketId) {
      // Convert status to string if it exists
      setValue(ticket?.status ? String(ticket.status) : '');
      prevTicketIdRef.current = currentTicketId;
    }
  }, [ticket]);
  
  // Filter active statuses and sort by sortOrder
  const statuses = (data?.TicketStatuses || [])
    .filter(status => status.isActive)
    .sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0));

  const handleChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    setValue(newValue);
    
    if (onChange) {
      onChange(current => ({
        ...current,
        status: newValue === '' ? null : Number(newValue)
      }));
    }
  };

  if (!edit) {
    const statusValue = ticket.status !== undefined ? String(ticket.status) : '';
    const selectedStatus = statuses.find(s => String(s.value) === statusValue);
    
    return (
      <Typography>
        <InlineIcon 
          color={StatusToColor(selectedStatus?.label || '')} 
          icon={StatusToIcon(selectedStatus?.label || '')} 
        />
        &nbsp;{!iconOnly && (selectedStatus?.label || 'None')}
      </Typography>
    );
  }

  return (
    <>
      {loading ? (
        <CircularProgress size={24} />
      ) : error ? (
        <div>Error loading statuses</div>
      ) : (
        <FormControl fullWidth>
          <InputLabel id="status-select-label">Status</InputLabel>
          <Select
            labelId="status-select-label"
            id="status-select"
            value={value}
            onChange={handleChange}
            label="Status"
            size="small"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {statuses.map(status => (
              <MenuItem 
                key={status.value} 
                value={status.value}
                sx={{display: 'flex', flexDirection: 'row'}}
              >
                <Typography>
                  <InlineIcon 
                    color={StatusToColor(status.label)} 
                    icon={StatusToIcon(status.label)} 
                  />
                  &nbsp;{status.label}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
}; 