import { Box, Button, ButtonGroup, Container, Grid, Paper, Switch, TextField, Tooltip, Typography } from "@mui/material"
import axios from "axios";
import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
import { StyledTable, TableColumnDef } from "../../react-submodules/components/Table";
import { useApi } from "../../react-submodules/providers/Api";
import { useSearchParam } from "../../react-submodules/utils/reactrouter";
import { DateTime } from "luxon";
import { LabeledBox } from "../../react-submodules/components/LabeldBox";
import { useMounted } from "../../react-submodules/hooks/useMounted";
import { DurationView } from "../../react-submodules/components/DurationView";
import { BillingDialogButton } from "./BillingDialog";
import { CompanyBillingReport, CompanyBillingReportItem } from "../../coretypes/reports/billing";

const TicketSearchLink : FC<{ month: number , year: number , companyId: number , billable : string } & PropsWithChildren> = ( { children, month , year , companyId ,  billable } ) => {
    return <Button onClick={(e)=>{
        e.stopPropagation();
        e.preventDefault();
        window.open( `/billing/tickets?month=${month}&year=${year}&companyId=${companyId}&billable=${billable}` , `${billable}_${companyId}_${month}_${year}` );
    }} size="small" variant="outlined">{children}</Button>
}

const columns : TableColumnDef< CompanyBillingReportItem , { month: number , year: number }>[] = [
    {
        field: 'name',
        flex: 1,
        sortable: true,
        headerName: 'Name',
        Element: ( { cell , row } ) => {
            return <Link to={`/company/${row.id}`}>{cell}</Link>
        }
    },
    {
        field: 'billingConfigured',
        flex: 1,
        sortable: true,
        headerName: 'Billing Configured',
        Element: ( { cell , row } ) => {
            return <Typography>{cell ? "Yes" : "No"}</Typography>
        }
    },
    {
        field: 'defaultHourlyRate',
        flex: 1,
        sortable: true,
        headerName: 'Hourly Rate',
        Element: ( { cell , row } ) => {
            return <Typography>${ Number( cell ).toFixed( 2 ) }</Typography>
        }
    },
    
    {
        field: 'defaultTicketLevel',
        flex: 1,
        sortable: true,
        headerName: 'Old Ticket Level'
    },
    {
        field: 'billableMinutes',
        flex: 1,
        sortable: true,
        headerName: 'Billable Minutes',
        Element: ( { cell , row , month , year } ) => {
            return <>{!cell && "-"}{ Boolean( cell ) && <TicketSearchLink month={month} year={year} companyId={row.id} billable="Hourly" ><DurationView duration={cell} durationUnit="m" /></TicketSearchLink>}</>
        }
    },
    {
        field: 'nonBillableMinutes',
        flex: 1,
        sortable: true,
        headerName: 'Non-Billable Minutes',
        Element: ( { cell , row , month , year } ) => {
            return <>{!cell && "-"}{ Boolean( cell ) && <TicketSearchLink month={month} year={year} companyId={row.id} billable="Managed" ><DurationView duration={cell} durationUnit="m" /></TicketSearchLink>}</>
        }
    },
    {
        field: 'unspecifiedMinutes',
        flex: 1,
        sortable: true,
        headerName: 'Unspecified Minutes',
        Element: ( { cell , row , month , year } ) => {
            return <>{!cell && "-"}{ Boolean( cell ) && <TicketSearchLink month={month} year={year} companyId={row.id} billable="TBD" ><DurationView duration={cell} durationUnit="m" /></TicketSearchLink>}</>
        }
    },
    {
        field: 'laborCost',
        flex: 1,
        sortable: true,
        headerName: 'Labor Cost',
        Element: ( { cell , row } ) => {
            return <Typography>${ Number( cell ).toFixed( 2 ) }</Typography>
        }
    },
    {
        field: 'contractCost',
        flex: 1,
        sortable: true,
        headerName: 'Contract Cost',
        Element: ( { cell , row } ) => {
            return <Button size="small" variant="outlined" onClick={(e)=>{
                e.stopPropagation();
                e.preventDefault();
                window.open( `/company/${row.id}/contracts` , `contracts_${row.id}` );
            }}>${ Number( cell ).toFixed( 2 ) }</Button>
        }
    },
    {
        field: 'totalCost',
        flex: 1,
        sortable: true,
        headerName: 'Total Cost',
        Element: ( { cell , row } ) => {
            return <Typography>${ Number( cell ).toFixed( 2 ) }</Typography>
        }
    },{
        field: 'actions',
        flex: 1,
        sortable: true,
        headerName: 'Actions',
        Element: ( { row  } ) => {
            return <BillingDialogButton reportItem={row} />
        }
    }
]

const prependZero = ( value: number ) => {
    return value < 10 ? `0${value}` : `${value}`;
}
export const BillingReport = () => {
    const nav = useNavigate();
    const mounted = useMounted()
    const [ search, setSearch ] = useSearchParam( 'search' );
    const [ companies  , setCompanies ] = useState<CompanyBillingReport>( [] );
    const [ contracts  , setContracts ] = useState<any[]>( [] );
    const [ active , setActive ] = useSearchParam<boolean>( 'active' , { typeProp: 'boolean' , defaultValue: true } );
    const [ detailed , setDetailed ] = useSearchParam<boolean>( 'detailed' , { typeProp: 'boolean' , defaultValue: false } );
    // const [ date , setDate ] = useSearchParam<string>( 'date' , { typeProp: 'string' , defaultValue: DateTime.now().toISODate() } );
    const [ month , setMonth ] = useSearchParam<number>( 'month' , { typeProp: 'number' , defaultValue: Number( DateTime.now().toFormat('MM') ) } );
    const [ year , setYear ] = useSearchParam<number>( 'year' , { typeProp: 'number' , defaultValue: Number( DateTime.now().toFormat('yyyy') ) } );
    const [ billingConfigured , setBillingConfigured ] = useSearchParam<boolean | null | undefined >( 'billingConfigured' , { typeProp: 'boolean'  } );
    const [ syncing , setSyncing ] = useState<boolean>( false );
    const [ refresh , setRefresh ] = useState<number>( 1 );
    const api = useApi();
    useEffect(() => {
        if( !month || !year ){
            setCompanies( [] );
            setContracts( [] );
            return;
        }
        const getData = setTimeout(() => {
            let query : Record<string, any> = {  'active' : active , month , year  };
            if( search ){
                query['name:like'] = search;
            }
          api.get( '/reports/billing/companies' , { ...query , billingConfigured: billingConfigured === null ? undefined : billingConfigured } ).then( ( results ) => {
            if( mounted() ){
                setCompanies( results.data );
                setSyncing( false );
            }
          });
          api.get( '/reports/billing/contracts' , {month , year} ).then( ( results ) => {
            if( mounted() ){
                setContracts( results.data );
            }
          });
        }, 500)
    
        return () => clearTimeout(getData)
    }, [search , month , year , active , refresh , billingConfigured ]);
    const snapshotContracts = () => {
        setSyncing( true );
        api.get('/core/sync/contracts_snapshot').then( ( results ) => {
            setRefresh( refresh * -1 );
            
        });
    }
    const snapshotCompanies = () => {
        setSyncing( true );
        api.get('/core/sync/snapshot_companies').then( ( results ) => {
            setRefresh( refresh * -1 );
        });
    }

    const fixedActive = useMemo( () => active === true ? true : false , [ active ] );
    const date = useMemo( () => {
        return `${year}-${prependZero( Number( month ) )}-01`
    } , [ year , month ] );
    const setDate = ( date: string ) => {
        const [ year , month ] = date.split( '-' );
        setYear( parseInt( year ) );
        setMonth( parseInt( month ) );
    }

    const tableColumns = useMemo( () => {
        if( !detailed ) return columns;
        const newColumns = [ ...columns ];
        const contractColumns = contracts.map( c => {
            return {
                field: `custom_contract_${c.id}`,
                flex: 1,
                sortable: true,
                headerName: <Tooltip title={c.name}><Typography sx={{ color : 'white'}}><Icon icon={c.icon} /></Typography></Tooltip>,
                Element: ( { row } : any  ) => {
                    const value = row.contracts[c.id]?.totalPrice;
                    if( !value ) return <Typography>-</Typography>
                    return <Tooltip title={`${c.name}`}><Typography>${ Number( value ).toFixed( 2 ) }</Typography></Tooltip>
                }
            }
        });
        const lastColumn = newColumns.pop();
        return [ ...newColumns , ...contractColumns , lastColumn ];
        // return detailed ? columns : columns.filter( c => c.field !== 'contractCost' );
    } , [ detailed , contracts ] );
    return <><Box sx={{marginTop: 2 }}>
        <Paper elevation={2} sx={{padding: 1}}>
            <Grid container spacing={2}>
                <Grid item sm={12} md={6} lg={4}>
                    <TextField fullWidth label="Search" value={search} onChange={( e)=>{ setSearch( e.target.value)}} />
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                    <TextField fullWidth label="Month" type="date" value={date} onChange={( e)=>{ setDate( e.target.value)}} />     
                </Grid>
                <Grid item sm={12} md={3} lg={2}>
                    <LabeledBox label="Active" >
                        <Switch checked={fixedActive} onChange={( e)=>{ setActive( e.target.checked)}} />
                    </LabeledBox>
                </Grid>
                <Grid item sm={12} md={3} lg={2}>
                    <LabeledBox label="Detailed" >
                        <Switch checked={detailed || false} onChange={( e)=>{ setDetailed( e.target.checked)}} />
                    </LabeledBox>
                </Grid>
                <Grid item sm={12} md={3} lg={2}>
                <LabeledBox label="Billing Configured" >
                    <ButtonGroup>
                        <Button variant="contained" color={[null , undefined].includes( billingConfigured as any  ) ? "primary" : "inherit"} onClick={()=>{ setBillingConfigured( null )}}>All</Button>
                        <Button variant="contained" color={billingConfigured === true ? "primary" : "inherit"} onClick={()=>{ setBillingConfigured( true )}}>Yes</Button>
                        <Button variant="contained" color={billingConfigured === false ? "primary" : "inherit"} onClick={()=>{ setBillingConfigured( false )}}>No</Button>
                    </ButtonGroup>
                    </LabeledBox>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{marginTop: 0}}>
                <Grid item sm={12} md={6} lg={4}>
                    <Button variant="contained" color="primary" onClick={()=>{
                        nav( `/billing/tickets?month=${month}&year=${year}` );
                    }}>Billing Ticket Report</Button>
                </Grid>
                <Grid item sm={12} md={3} lg={2}>
                    <Button variant="contained" color="primary" onClick={()=>{
                        snapshotContracts();
                    }} disabled={syncing}>{syncing ? "Syncing..." : "Snapshot Contracts"}</Button>
                </Grid>
                <Grid item sm={12} md={3} lg={2}>
                    <Button variant="contained" color="primary" onClick={()=>{
                        snapshotCompanies();
                    }} disabled={syncing}>{syncing ? "Syncing..." : "Snapshot Companies"}</Button>
                </Grid>
                <Grid item sm={12} md={3} lg={2}>
                    <Button variant="contained" color="primary" onClick={()=>{
                        window.open(`/billing/printall?month=${month}&year=${year}` , `printall_${month}_${year}` );
                        // nav(`/billing/printall?month=${month}&year=${year}`)
                    }}>Print All</Button>
                </Grid>
            </Grid>
        </Paper>
        </Box>
        { companies.length > 0 && (
            <Box sx={{marginTop: 2 }}>
                    <Paper>
                        <StyledTable
                            elementProps={{ month , year}}
                            size="small"
                            
                            rows={JSON.parse( JSON.stringify( companies )    )}
                            columns={tableColumns as any }
                        />
                    </Paper>
            </Box>
        )
        }
    </>
}