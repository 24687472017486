import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTransferMessageQueryVariables = Types.Exact<{
  ticketId: Types.Scalars['Float']['input'];
}>;


export type GetTransferMessageQuery = { __typename?: 'Query', findCoreMessageChannelPaginated: { __typename?: 'PaginatedCoreMessageChannel', data?: Array<{ __typename?: 'CoreMessageChannel', id?: number | null, acknowledged?: boolean | null, result?: Types.CoreMessageChannelResult | null, type?: Types.CoreMessageChannelType | null, assignedToUserId?: string | null, expireTime?: any | null }> | null } };


export const GetTransferMessageDocument = gql`
    query GetTransferMessage($ticketId: Float!) {
  findCoreMessageChannelPaginated(
    limit: 100
    atTicketId: {eq: $ticketId}
    type: TICKET_TRANSFER
  ) {
    data {
      id
      acknowledged
      result
      type
      assignedToUserId
      expireTime
    }
  }
}
    `;

/**
 * __useGetTransferMessageQuery__
 *
 * To run a query within a React component, call `useGetTransferMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransferMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransferMessageQuery({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useGetTransferMessageQuery(baseOptions: Apollo.QueryHookOptions<GetTransferMessageQuery, GetTransferMessageQueryVariables> & ({ variables: GetTransferMessageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransferMessageQuery, GetTransferMessageQueryVariables>(GetTransferMessageDocument, options);
      }
export function useGetTransferMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransferMessageQuery, GetTransferMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransferMessageQuery, GetTransferMessageQueryVariables>(GetTransferMessageDocument, options);
        }
export function useGetTransferMessageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTransferMessageQuery, GetTransferMessageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTransferMessageQuery, GetTransferMessageQueryVariables>(GetTransferMessageDocument, options);
        }
export type GetTransferMessageQueryHookResult = ReturnType<typeof useGetTransferMessageQuery>;
export type GetTransferMessageLazyQueryHookResult = ReturnType<typeof useGetTransferMessageLazyQuery>;
export type GetTransferMessageSuspenseQueryHookResult = ReturnType<typeof useGetTransferMessageSuspenseQuery>;
export type GetTransferMessageQueryResult = Apollo.QueryResult<GetTransferMessageQuery, GetTransferMessageQueryVariables>;