import './ReportItemDialog.css';
import 'react-json-view-lite/dist/index.css';
import { JsonView, allExpanded, darkStyles, defaultStyles } from 'react-json-view-lite';


import { Dialog, DialogContent, DialogTitle, Tab, Tabs } from "@mui/material"
import { FC, useEffect, useMemo, useState } from "react"
import { ReportData } from "./ReportActivity.types"
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import luxon3Plugin from "@fullcalendar/luxon3";
import { DateTime } from "luxon";
import FullCalendar from "@fullcalendar/react";
import { getColorFromString } from "../../../../react-submodules/utils/colors";
import { L } from "@fullcalendar/resource/internal-common";
import { ReportItemDialogPhoneTab } from './ReportItemDialogPhoneTab';
import { ReportItemDialogDebugCallsTab } from './ReportItemDialogDebugCallsTab';


export const ReportItemDialog : FC<{ item : ReportData | null , onClose : () => void , resources : Array<{ id : string , title : string }> }> = ({ item , onClose , resources }) => {
    const [ tab , setTab ] = useState<string>( 'phone' );
    useEffect( () => {
        if( item === null ){
            setTab( 'phone' );
        }
    } , [ item ] );
    return <Dialog maxWidth="xl" sx={{ '& .MuiDialog-paper': { width: '100%' } }} open={item !== null} onClose={onClose}>
        <DialogTitle>Report Item</DialogTitle>
        <DialogContent>
            <Tabs value={tab} onChange={(e, v) => setTab(v)}>
                <Tab label="Phone" value="phone" />
                <Tab label="Debug Calls" value="debug_calls" />
                <Tab label="Raw Debug" value="debug_raw" />
                
            </Tabs>
            {tab === 'phone' && <ReportItemDialogPhoneTab item={item} resources={resources} />}
            { tab === 'debug_calls' && <ReportItemDialogDebugCallsTab item={item} /> }
            {tab === 'debug_raw' && <div><JsonView data={item || { item }} shouldExpandNode={allExpanded} style={darkStyles} /></div>}
        </DialogContent>
    </Dialog>
}