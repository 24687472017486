import { Box, Dialog, DialogContent, DialogTitle, IconButton, Palette, PaletteColor, Typography, createTheme } from "@mui/material";

import { CoreForm } from "../CoreForm/CoreForm";
import { FormInstance } from "../../../coretypes/form.dto"
import { Icon } from "@iconify/react";
import { useRecipeApi } from "../../providers/Recipe";
import { useState } from "react";

const formTheme = createTheme({
    palette: {
        primary: {
          main: '#AC92EC',
        },
        secondary: {
            main: '#C90',
            contrastText: '#fff'
          },
      }
  });
  const greyTheme = createTheme({
    palette: {
        primary: {
          main: '#fff',
          contrastText: '#444'
        },
        secondary: {
          main: '#C90',
          contrastText: '#444'
        },
      }
  })
  const getColor = ( form : FormInstance ) : Palette  => {
    
    if( form.complete ){
        return greyTheme.palette;
    }
    return formTheme.palette
    // const mainColor : PaletteColor = todo?.complete ? theme.palette.success : theme.palette.info;
}
export const FormItem : React.FC<{
    formInstance: FormInstance
}> = ({ formInstance }) => {
    const colorTheme = getColor(formInstance);
    const { values , form } = formInstance;
    const [ open , setOpen ] = useState( false );
    const { addAction } = useRecipeApi();
    const handleOpen = () => {
        if( formInstance.complete){
            setOpen( true);
        } else {
            addAction( {
                action: 'form' ,
                formInstanceId: formInstance.id
            } );
        }
    }
    return <Box sx={
        {
            display: 'flex' , 
            background: colorTheme.primary.main , 
            color: colorTheme.primary.contrastText ,
            margin: 0.5,
            
            padding: 0.25,
            borderRadius: 2
        }}>
            
            <IconButton sx={{
            color: colorTheme.secondary.contrastText,
        }} size="small" onClick={()=>{}}><Icon icon="fa:wpforms" /></IconButton>
            
            
            <Typography onClick={()=>{
                handleOpen();
            }} sx={{ cursor: 'pointer', flexGrow: 1 ,  marginLeft: 1 , textDecoration: formInstance.complete ? 'line-through' : 'none', fontWeight: formInstance.complete ? 'normal' : 'bold',}} variant="body1" >{formInstance.form_name}</Typography>&nbsp;&nbsp;

            
        
            <Dialog open={open} onClose={()=>{ setOpen( false )}}>
                <DialogTitle >{ formInstance.form_name }</DialogTitle>
                <DialogContent sx={{margin: 2}}>
                    <CoreForm form={form} values={values} onChange={()=>{}} readonly />
                </DialogContent>
            </Dialog>
        
    </Box>
}

