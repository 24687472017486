import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTicketFromTodoMutationVariables = Types.Exact<{
  coreCompanyId: Types.Scalars['Int']['input'];
  coreContactId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  title: Types.Scalars['String']['input'];
  status: Types.Scalars['Int']['input'];
  queueID: Types.Scalars['Int']['input'];
  priority: Types.Scalars['Int']['input'];
  contactlessStart: Types.Scalars['Boolean']['input'];
  nextActionDate?: Types.InputMaybe<Types.Scalars['String']['input']>;
  nextActionTime?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  nextActionType?: Types.InputMaybe<Types.Scalars['String']['input']>;
  description?: Types.InputMaybe<Types.Scalars['String']['input']>;
  parentTicketId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type CreateTicketFromTodoMutation = { __typename?: 'Mutation', createAutotaskTickets: { __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, coreCompanyId?: number | null, coreContactId?: number | null, contactID?: number | null, companyID?: number | null } };


export const CreateTicketFromTodoDocument = gql`
    mutation CreateTicketFromTodo($coreCompanyId: Int!, $coreContactId: Int, $title: String!, $status: Int!, $queueID: Int!, $priority: Int!, $contactlessStart: Boolean!, $nextActionDate: String, $nextActionTime: Float, $nextActionType: String, $description: String, $parentTicketId: Int) {
  createAutotaskTickets(
    coreCompanyId: $coreCompanyId
    coreContactId: $coreContactId
    title: $title
    status: $status
    queueID: $queueID
    priority: $priority
    contactlessStart: $contactlessStart
    nextActionDate: $nextActionDate
    nextActionTime: $nextActionTime
    nextActionType: $nextActionType
    description: $description
    parentTicketId: $parentTicketId
  ) {
    id
    ticketNumber
    coreCompanyId
    coreContactId
    contactID
    companyID
  }
}
    `;
export type CreateTicketFromTodoMutationFn = Apollo.MutationFunction<CreateTicketFromTodoMutation, CreateTicketFromTodoMutationVariables>;

/**
 * __useCreateTicketFromTodoMutation__
 *
 * To run a mutation, you first call `useCreateTicketFromTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketFromTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketFromTodoMutation, { data, loading, error }] = useCreateTicketFromTodoMutation({
 *   variables: {
 *      coreCompanyId: // value for 'coreCompanyId'
 *      coreContactId: // value for 'coreContactId'
 *      title: // value for 'title'
 *      status: // value for 'status'
 *      queueID: // value for 'queueID'
 *      priority: // value for 'priority'
 *      contactlessStart: // value for 'contactlessStart'
 *      nextActionDate: // value for 'nextActionDate'
 *      nextActionTime: // value for 'nextActionTime'
 *      nextActionType: // value for 'nextActionType'
 *      description: // value for 'description'
 *      parentTicketId: // value for 'parentTicketId'
 *   },
 * });
 */
export function useCreateTicketFromTodoMutation(baseOptions?: Apollo.MutationHookOptions<CreateTicketFromTodoMutation, CreateTicketFromTodoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTicketFromTodoMutation, CreateTicketFromTodoMutationVariables>(CreateTicketFromTodoDocument, options);
      }
export type CreateTicketFromTodoMutationHookResult = ReturnType<typeof useCreateTicketFromTodoMutation>;
export type CreateTicketFromTodoMutationResult = Apollo.MutationResult<CreateTicketFromTodoMutation>;
export type CreateTicketFromTodoMutationOptions = Apollo.BaseMutationOptions<CreateTicketFromTodoMutation, CreateTicketFromTodoMutationVariables>;