import { Box, Card, CardContent, CardHeader, Checkbox, FormControlLabel, Grid, Switch, TextField, Typography } from "@mui/material"
import { Handle, Position } from "reactflow"
import { useNodeDataProperty } from "../../../../providers/Recipies.provider";

export const EmailReceivedNode = ({ id , data } : {id : string , data : any  } ) => {

    const [ title , setTitle ] = useNodeDataProperty( id , 'title');
    const [ active , setActive ] = useNodeDataProperty( id , 'active' , true );
    
    return <Box>
        <Card sx={{maxWidth: '600px'}}>
            <CardHeader className="drag-handle" title="Email Received" />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Email Received</Typography>
                        <TextField
                            label="Title"
                            value={title}
                            onChange={(e)=>setTitle(e.target.value)}
                        />
                        
                    </Grid>
                    <Grid item xs={12}>
                        <Switch color="secondary" checked={active} onChange={(e)=>setActive(e.target.checked)} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        <Handle
          type="source"
          position={Position.Right}
          id={`handle-source`}
        />
    </Box>
}
