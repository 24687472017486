import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyTimepunchesQueryVariables = Types.Exact<{
  ticketId: Types.Scalars['Float']['input'];
  userId: Types.Scalars['String']['input'];
}>;


export type MyTimepunchesQuery = { __typename?: 'Query', timeclocks: { __typename?: 'PaginatedTimeclocks', data?: Array<{ __typename?: 'CoreTimeclock', id?: number | null, start?: any | null, end?: any | null, ticketId?: number | null, coreUserId?: string | null, user?: { __typename?: 'CoreUsers', id?: string | null, firstName?: string | null, lastName?: string | null } | null }> | null } };


export const MyTimepunchesDocument = gql`
    query MyTimepunches($ticketId: Float!, $userId: String!) {
  timeclocks(
    ticketId: {eq: $ticketId}
    end: {null: true}
    user: {id: {eq: $userId}}
  ) {
    data {
      id
      start
      end
      ticketId
      coreUserId
      user {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useMyTimepunchesQuery__
 *
 * To run a query within a React component, call `useMyTimepunchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTimepunchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTimepunchesQuery({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMyTimepunchesQuery(baseOptions: Apollo.QueryHookOptions<MyTimepunchesQuery, MyTimepunchesQueryVariables> & ({ variables: MyTimepunchesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyTimepunchesQuery, MyTimepunchesQueryVariables>(MyTimepunchesDocument, options);
      }
export function useMyTimepunchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyTimepunchesQuery, MyTimepunchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyTimepunchesQuery, MyTimepunchesQueryVariables>(MyTimepunchesDocument, options);
        }
export function useMyTimepunchesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MyTimepunchesQuery, MyTimepunchesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyTimepunchesQuery, MyTimepunchesQueryVariables>(MyTimepunchesDocument, options);
        }
export type MyTimepunchesQueryHookResult = ReturnType<typeof useMyTimepunchesQuery>;
export type MyTimepunchesLazyQueryHookResult = ReturnType<typeof useMyTimepunchesLazyQuery>;
export type MyTimepunchesSuspenseQueryHookResult = ReturnType<typeof useMyTimepunchesSuspenseQuery>;
export type MyTimepunchesQueryResult = Apollo.QueryResult<MyTimepunchesQuery, MyTimepunchesQueryVariables>;