import { Accordion, AccordionDetails, AccordionSummary, Box, ListItem, Tooltip, Typography } from "@mui/material"
import { AutotaskTicketNotes, AutotaskTimeEntries, isTicketTime } from "../../types/autotask.dto"

import { AnyBadge } from "../AnyBadge"
import { CoreForm } from "../CoreForm/CoreForm"
import { FC } from "react"
import { FormInstance } from "../../../coretypes/form.dto"
import { Icon } from "@iconify/react"
import { FORM_ICON, FORM_ICON_DEFAULT_COLOR } from "../../constances/icons"

export const FormItem : React.FC<{form : FormInstance }> = ({ form  }) => {
    return <Accordion onChange={() => {
    }}>
        <AccordionSummary>
            <Box sx={{ display: 'flex', alignItems: 'center' , paddingRight: 1 , color : FORM_ICON_DEFAULT_COLOR }}>
                <Icon icon={FORM_ICON} />
            </Box>
                <Typography sx={{ paddingLeft: 1, whiteSpace: 'nowrap' }}>
                    {new Date(form.createdAt).toLocaleString('en-US', { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(",", "")}
                </Typography>
                <Typography sx={{ paddingLeft: 1 , fontWeight: 'bold'}}>{form.form_name}</Typography></AccordionSummary>
        
        <AccordionDetails>
        <CoreForm form={form.form} values={form.values} onChange={()=>{}} readonly />
        </AccordionDetails>
    </Accordion>
}

