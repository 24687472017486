
import { TableColumnDef } from "../Table";
import { DataGrid, GridColDef , gridClasses} from "@mui/x-data-grid";
import { FunctionComponent, useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import { alpha, styled } from '@mui/material/styles';
import { Box, Button, IconButton, InputAdornment, Popover, SxProps, Table, TableBody, TableCell, TableHead, TableRow, TextField, Theme, Typography, useTheme } from "@mui/material";
import { InlineIcon } from "@iconify/react";
import { useFilter } from "../../../providers/FilterProvider";
import { FilterMenuString } from "./FilterMenuString";
import { FilterMenuNumber } from "./FilterMenuNumber";
import { FilterMenuDate } from "./FilterMenuDate";
import { FilterMenuOptions } from "./FilterMenuOptions";

 

 




const FilterMenuInner = ( { column , onClose } : { column : TableColumnDef , onClose: ()=> void  }  ) => {
    switch( column.filter ){
      case 'string':
        return <FilterMenuString column={column} onClose={onClose} />
      case 'number':
        return <FilterMenuNumber column={column} onClose={onClose} />
      case 'date':
        return <FilterMenuDate column={column} onClose={onClose} />
      case 'options':
        return <FilterMenuOptions column={column} onClose={onClose} />
      default:
        return <></>
    }
  };

export const FilterMenu = ( { column , onClose } : { column : TableColumnDef , onClose: ()=> void  }  ) => {
    const theme = useTheme();
    return <Box sx={{padding: 1 , background: theme.palette.primary.light}}>
      <Typography sx={{color: theme.palette.primary.contrastText, fontSize: '16px'}} variant="h6">{column.headerName || column.field}</Typography>
      <FilterMenuInner column={column} onClose={onClose} /></Box>
  }