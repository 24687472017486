import { Box, Button, Grid , Typography } from "@mui/material"
import { FC, PropsWithChildren, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { CoreTagsLinksConfig } from "./pages/CoreTagLinkssConfig";
import { CoreGroupMembersConfig } from "./pages/CoreGroupMembers";
import { CoreGroupsConfig } from "./pages/CoreGroups";
import { FeedbackScores } from "./pages/FeedbackScores";
import { FeedbackInstances } from "./pages/FeedbackInstances";

const SGrid : FC<PropsWithChildren> = ({children}) => {
    return (
        <Grid item xs={6} sm={4} md={3} lg={2}  >
            {children}
        </Grid>
    )
}

const groups = ["core" , "feedback"];

export const configPages : Array<{name : string , group : string , item : string , Component : () => JSX.Element}> = [
    {name : "Core Tag Links" , group : "core" , item : "taglinks" , Component : CoreTagsLinksConfig},
    {name : "Core Groups" , group : "core" , item : "groups" ,  Component : CoreGroupsConfig},
    {name : "Core Group Members" , group : "core" , item : "groupmembers" ,  Component : CoreGroupMembersConfig},
    {name : "Feedback Scores" , group : "feedback" , item : "feedbackscores" ,  Component : FeedbackScores},
    {name : "Feedback Instances" , group : "feedback" , item : "feedbackinstances" ,  Component : FeedbackInstances}
]

export const Config = () => {
    const nav = useNavigate();
    return (
        <Box>
            <Box>
                {groups.map((group) => (
                    <><Typography variant="h6">{group}</Typography>
                <Grid container spacing={2}>
                    {configPages.filter((page) => page.group === group).map((page) => (
                        <SGrid key={page.item}>
                            <Button variant="contained" onClick={() => {
                                nav(`/config/${group}/${page.item}`);
                            }}>
                                {page.name}
                            </Button>
                        </SGrid>
                ))}
                </Grid></>
                ))}
                
            </Box>
        </Box>
    )
}