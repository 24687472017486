import { Box, Card,  TextField, CardContent, Typography, Tab, TabProps, Badge } from "@mui/material";
import { FC } from "react";
import { useTicketSearchTabQuery } from "./gql/TicketSearchTab.generated";
import { useCustomerWaitingTicketsQuery } from "../../../../generated/graphql";

export const TicketSearchTab : FC<TabProps & {
    label : string | null;
    value : string | null;
    coreCompanyId? : number;
    autotaskContactId? : number;
    coreContactId? : number;
    ticketId? : number;

}> = ({ label , value , coreCompanyId , autotaskContactId , coreContactId , ticketId , ...props }) => {
    const { data : customerWaitingTickets } = useCustomerWaitingTicketsQuery({
        variables: {
            coreCompanyId,
            autotaskContactId,
            coreContactId,
            limit: 1
        },
        fetchPolicy: 'cache-and-network',
    });
    const { data , loading } = useTicketSearchTabQuery({
        variables: {
            coreCompanyId,
            autotaskContactId,
            coreContactId,
            limit: 1
        },
        fetchPolicy: 'cache-and-network',
    });
    const hasCustomerWaitingTickets = (customerWaitingTickets?.findAutotaskTicketsPaginated?.totalCount || 0 ) > 0;
    return <Tab color={ hasCustomerWaitingTickets ? 'warning' : 'default' } label={`${label} (${data?.findAutotaskTicketsPaginated?.totalCount || 0})`} value={value} {...props} />;
}