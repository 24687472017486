import { Box, Grid, Paper, TextField, Typography, Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import { useSearchParam } from "../../react-submodules/utils/reactrouter";
import { DateTime } from "luxon";
import { useGetTimeclocksQuery } from "../../generated/graphql";
import { useMemo, useState } from "react";
import { StyledTable, TableColumnDef } from "../../react-submodules/components/Table";

interface UserTimeData {
  id: string;
  name: string;
  email?: string;
  totalHours: number;
  autotaskId?: string;
  dailyBreakdown: {
    [date: string]: number;
  };
}

const defaultStartDate = DateTime.now().startOf('month').toISODate();
const defaultEndDate = DateTime.now().endOf('month').toISODate();

export const Payroll = () => {
  const [startDate, setStartDate] = useSearchParam<string>('startDate', { 
    typeProp: 'string', 
    defaultValue: defaultStartDate
  });
  const [endDate, setEndDate] = useSearchParam<string>('endDate', { 
    typeProp: 'string', 
    defaultValue: defaultEndDate
  });
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const currentStartDate = startDate || defaultStartDate;
  const currentEndDate = endDate || defaultEndDate;

  const handleUserClick = (userId: string) => {
    setSelectedUser(userId);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedUser(null);
  };

  const handleExportCSV = () => {
    setIsExporting(true);
    try {
      // Convert userTimeData to CSV format with one line per user per day
      const header = ["Name", "Email", "Date", "Hours"];
      const rows = userTimeData.flatMap(user =>
        Object.entries(user.dailyBreakdown).map(([date, hours]) => [
          user.name,
          user.email || '',
          date,
          hours.toFixed(2)
        ])
      );

      const csvContent = [header, ...rows].map(row => row.join(",")).join("\n");
      
      // Create and download the CSV file
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `payroll_${currentStartDate}_to_${currentEndDate}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error exporting payroll data:", error);
    } finally {
      setIsExporting(false);
    }
  };

  const { data, loading } = useGetTimeclocksQuery({
    variables: {
      startDate: { gte: currentStartDate },
      endDate: { lte: currentEndDate }
    }
  });

  const userTimeData = useMemo(() => {
    if (!data?.timeclocks.data) return [];

    const userMap = new Map<string, UserTimeData>();

    data.timeclocks.data.forEach(timeclock => {
      if (!timeclock.user || !timeclock.start) return;
      if (!timeclock.user.firstName || !timeclock.user.lastName || !timeclock.user.id) return;

      const userId = timeclock.user.id;
      const userName = `${timeclock.user.firstName} ${timeclock.user.lastName}`;
      const startDateTime = DateTime.fromISO(timeclock.start);
      if (!startDateTime.isValid) return;

      const date = startDateTime.toISODate();
      if (!date) return;
      let hours = 0;

      if (!timeclock.end) return;
      const endDateTime = DateTime.fromISO(timeclock.end);
      if (!endDateTime.isValid) return;
      hours = endDateTime.diff(startDateTime, 'hours').hours;

      if (!userMap.has(userId)) {
        userMap.set(userId, {
          id: userId,
          name: userName,
          email: timeclock.user.email || '',
          totalHours: 0,
          autotaskId: timeclock.user.autotaskId?.toString(),
          dailyBreakdown: {}
        });
      }

      const userData = userMap.get(userId)!;
      userData.totalHours += hours;
      userData.dailyBreakdown[date] = (userData.dailyBreakdown[date] || 0) + hours;
    });

    return Array.from(userMap.values());
  }, [data]);

  const userColumns: TableColumnDef[] = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { 
      field: 'totalHours', 
      headerName: 'Total Hours', 
      flex: 1,
      Element: ({ cell }) => <>{Number(cell).toFixed(2)}</>
    }
  ];

  const dailyColumns: TableColumnDef[] = useMemo(() => {
    return [
      { field: 'date', headerName: 'Date', flex: 1 },
      { 
        field: 'hours', 
        headerName: 'Hours', 
        flex: 1,
        Element: ({ cell }) => <>{Number(cell).toFixed(2)}</>
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        Element: ({ row }) => {
          const user = userTimeData.find(u => u.id === selectedUser);
          if (!user?.autotaskId) return null;

          const date = DateTime.fromFormat(row.date, 'MM/dd/yyyy').toFormat('yyyy-MM-dd');
          const url = `/timepunches?limit=25&page=0&enddate=${date}&startdate=${date}&resourceId=${user.autotaskId}&isPayroll=true`;

          return (
            <Button
              variant="contained"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                window.open(url, '_blank');
              }}
            >
              View Details
            </Button>
          );
        }
      }
    ];
  }, [selectedUser, userTimeData]);

  const dailyRows = useMemo(() => {
    if (!selectedUser) return [];
    const user = userTimeData.find(u => u.id === selectedUser);
    if (!user) return [];

    return Object.entries(user.dailyBreakdown)
      .map(([date, hours]) => {
        const dateTime = DateTime.fromISO(date);
        if (!dateTime.isValid) return null;
        return {
          id: date,
          date: dateTime.toFormat('MM/dd/yyyy'),
          hours
        };
      })
      .filter((row): row is { id: string; date: string; hours: number } => row !== null)
      .sort((a, b) => a.date.localeCompare(b.date));
  }, [selectedUser, userTimeData]);

  return (
    <Box sx={{ marginTop: 2 }}>
      <Paper elevation={2} sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <TextField
              fullWidth
              label="Start Date"
              type="date"
              value={currentStartDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              fullWidth
              label="End Date"
              type="date"
              value={currentEndDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleExportCSV}
              disabled={isExporting || userTimeData.length === 0}
              fullWidth
            >
              {isExporting ? "Exporting..." : "Export CSV"}
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Box sx={{ marginTop: 2 }}>
        <Paper>
          <StyledTable
            rows={userTimeData}
            columns={userColumns}
            onClick={(row) => handleUserClick(row.id)}
          />
        </Paper>
      </Box>

      <Dialog 
        open={dialogOpen} 
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Daily Breakdown for {userTimeData.find(u => u.id === selectedUser)?.name}
        </DialogTitle>
        <DialogContent>
          <StyledTable
            rows={dailyRows}
            columns={dailyColumns}
            hideHeader={false}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};