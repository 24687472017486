import { TextField } from "@mui/material";

type WithTitle = {
    title?: string | null | undefined;
}
export const GqlTitle = <T extends WithTitle>({ 
    ticket,
    edit,
    onChange
  }: {
    ticket: T;
    edit?: boolean;
    onChange?: (current: T | ((prev: T) => T)) => void;
  }) => {
    if( !edit ){
        return <>{ticket.title}</>
    }
    return <TextField
    sx={{}}
        label="Title"
    value={ticket.title}
        InputLabelProps={{ shrink: true }}
        onChange={(e) => onChange?.({ ...ticket, title: e.target.value })}
        fullWidth
        size="small"
    />
  }