import { Button, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material"
import { FC, useEffect, useState } from "react";
import { TicketUpdateField, allUpdateFields } from "../../react-submodules/components/TicketUpdateForm/Fields";

import { CompanyTicketList } from "../../react-submodules/components/CompanyTicketList/CompanyTicketList";
import { LoadingButton } from "@mui/lab";
import { QueueTicket } from "../../react-submodules/types/ticketqueue";
import { useApi } from "../../react-submodules/providers/Api/Api";
import { useLayout } from "../../react-submodules/providers/LayoutProvider";

const getWidth = ( field : string ) : number => {
    switch( field ){
        case 'title':
            return 12;
        case 'status':
            return 6;
        case 'nextActionNote':
            return 6;
        case 'description':
            return 12;
        case 'nextActionDate':
            return 6;
        case 'nextActionTime':
            return 6;
        case 'queue':
            return 6;
        case 'priority':
            return 6;
        case 'billable':
            return 6;
        case 'companyID':
            return 6;
        case 'contactID':
            return 12;
    }
    return 4;
}

const fieldOrder = ['companyID' , 'status','contactID' ,'title' , 'description' , 'contactID' , 'title' , 'description' , 'priority' , 'billable']

const getSort = ( field : string ) : number => {
    const index = fieldOrder.indexOf( field );
    if( index > -1 ) return index;
    return 100;

}


export const CreateTicket : FC<{ updates? : Record<string,any>}>  = ( { updates: propUpdates }) => {
    const [ open , setOpen ] = useState( false );
    const [ ticket , setTicket ] = useState<QueueTicket | null >( null );
    const [ updates , setUpdates] = useState< Record<string,any>>(propUpdates || {});
    const [ loading , setLoading ] = useState( false );
    const { addButton , removeButton} = useLayout();
    useEffect( () => {
        addButton( {
            label: "Add Ticket", 
            link: '/ticket/new',
            key: 'queue_add_ticket',
            contextMenu: [
                {
                    label: 'Old Add Ticket',
                    onClick: () => {
                        setOpen( true );
                    }
                }
            ]
        });
        return () => {
            removeButton( 'queue_add_ticket' );
        }
    }, [ ticket ]);
    const api = useApi();
    useEffect( () => {
        setTicket( null );
        setUpdates( propUpdates || {
            queue: 29683494,
            status: 30,
            nextActionDate : new Date().toISOString(),
            nextActionType: 'FLEXABLE',
            contactlessStart: true
        });
    }, [open ] )
    const fields = allUpdateFields.sort( ( a , b ) => {        
        return getSort(a) - getSort(b);
    });
    const createTicket = () => {
        setLoading( true );
        api.post(`/autotask/createTicketRaw` , updates ).then( ( { data , error } ) => {   
            if( !error ){
                setTicket( data );
            }        
            setLoading( false );
        })
    }

    return <Dialog open={open} onClose={()=>{
        setOpen( false );
    }} maxWidth="lg" >
        <DialogTitle>Create Ticket</DialogTitle>
        <DialogContent sx={{ width: '1200px'}}>
            <Grid container >
                <Grid item sm={8}>
                    <Grid container spacing={1} sx={{marginTop: 1}}>
                    {!ticket && <>
                    {fields.map( ( field ) => {
                        return <Grid  sx={{marginBottom: 2}} key={field} item xs={getWidth( field)}><TicketUpdateField 
                        currentTicket={{ ...updates}}  field={field} 
                        value={updates[field]} 
                        onChange={( value ) => {
                            setUpdates( u => { return { ...u , [field] : value } })
                        }} 
                        updateField={( ufield , value ) => {
                            setUpdates( u => { return { ...u , [ufield] : value } })
                        }}
                        /></Grid>
                    
                    })}
                    </> }
                    {ticket && <>
                    <LoadingButton loading={loading} onClick={()=>{
                        window.open(`/ticketqueue/all/${ticket?.id}` , '_blank');
                    }} variant="contained">{ticket?.ticketNumber} Created</LoadingButton>
                    </>}
                        <Grid sx={{marginBottom: 2}} item xs={12}>
                            {!ticket && <LoadingButton loading={loading} onClick={()=>{createTicket();}}>Create Ticket</LoadingButton>}
                            {ticket && <Button onClick={()=>{ setOpen( false )}}>Close</Button>}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={4}>
                    <CompanyTicketList companyID={updates.companyID} contactID={updates?.contactID} />
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>
}