import { FC, useState } from "react";
import { Button } from "@mui/material";
import { useGetTicketSummaryQuery } from "../gql/GetTicketSummary.generated";
import { AutotaskTickets } from "../../../generated/graphql";
import { QueryProps } from "../../../coretypes/queries";
import { DateTime } from "luxon";

type TicketForCSV = Pick<AutotaskTickets, 'id' | 'ticketNumber' | 'status'> & {
  contactEmail?: string;
  contactName?: string;
  statusName?: string;
  aiSummary?: string;
};

export const TicketsDownload: FC<{
  companyId?: number | null;
  startDate: string | null;
}> = ({ companyId, startDate }) => {
  const [isLoading, setIsLoading] = useState(false);

  const startDateQuery: QueryProps = startDate ? {
    gte: DateTime.fromISO(startDate).startOf('day').toISO()
  } : {};

  const { data } = useGetTicketSummaryQuery({
    variables: {
      coreCompanyId: companyId ? { eq: companyId } : undefined,
      startDate: startDateQuery,
      page: 0,
      limit: 1000 // Get a large number of tickets
    }
  });

  const handleDownload = async () => {
    setIsLoading(true);
    try {
      const tickets = data?.findAutotaskTicketsPaginated?.data || [];

      // Convert tickets to CSV
      const csvContent = convertToCSV(tickets);

      // Create and download the CSV file
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "tickets_summary.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading tickets:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const convertToCSV = (tickets: any[]) => {
    const header = [
      "Ticket ID",
      "Ticket Number",
      "Status",
      "Contact Email",
      "Contact Name",
      "AI Summary"
    ];
    const rows = tickets.map(ticket => [
      ticket.id,
      ticket.ticketNumber,
      ticket.autotaskTicketStatus?.name || 'Unknown',
      ticket.contact?.emailAddress || '',
      ticket.contact ? `${ticket.contact.firstName} ${ticket.contact.lastName}` : '',
      ticket.aisummary || ''
    ]);
    return [header, ...rows].map(row => 
      row.map(cell => 
        typeof cell === 'string' ? `"${cell.replace(/"/g, '""')}"` : cell
      ).join(",")
    ).join("\n");
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleDownload}
      disabled={isLoading}
      sx={{ ml: 2 }}
    >
      {isLoading ? "Downloading..." : "Download Tickets CSV"}
    </Button>
  );
};