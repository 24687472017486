import { Edge, Node } from "reactflow"
import { z } from "zod"
import { AzureEmailSchema } from "../../../../react-submodules/types/azure"
import { GetContext } from "../../types"
import { baseCoreRecipeContextSchema } from "../utils"
import { RocketcyberIncidentsSchema } from "../../../../react-submodules/types/rocketcyber"
// RocketcyberIncidentsSchema
export const getEventListenerContext : GetContext = ( nodeId: string , nodes: Node[] , edges: Edge[] ) => {
    const node = nodes.find( n => n.id === nodeId );
    switch( node?.data?.event){
        case 'rocketcyber.incident.created':
            return baseCoreRecipeContextSchema.extend({
                context: z.literal('rocketCyberIncident'),
                rocketCyberIncidentId: z.number(),
                rocketCyberIncident: RocketcyberIncidentsSchema.describe('If there is a rocket cyber incident tied to the context')
            });
        default:
            return baseCoreRecipeContextSchema;
    }
    
}