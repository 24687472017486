import { Grid } from "@mui/material"
import { FC } from "react"
import { MarkdownView } from "../../../react-submodules/MarkdownView"

export const ModuleLinks : FC<{companyId: string}> = ({companyId}) => {
    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <MarkdownView>{`This will be the place to link modules like 
* Autotask Company
* DattoRMM Sites
* Datto Backup Devices
* Azure / M365 Tenants 

to company ${companyId}`}
            </MarkdownView>
        </Grid>
    </Grid>
}