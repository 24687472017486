import { Box, Button, Card, CardActionArea, CardContent, CardHeader, Grid, Tooltip, Typography, useTheme, } from "@mui/material";
import { Icon, InlineIcon } from "@iconify/react";

import { FC, useEffect, useState } from "react";
import { LastUser } from "../LastUser";
import { useTicket } from "../../providers/TicketProvider";
import { LabeledBox } from "../LabeldBox";
import { useApi } from "../../providers/Api";
import { DeviceQueryItem } from "../../types/dattormm.dto";
import { ContractsInstances } from "../../../coretypes/contracts/contracts.dto";
import { useMounted } from "../../hooks/useMounted";
import { isServer } from "../../../pages/Company/report/DeviceV2/utils";
import { DEFAULT_CONTRACT_ICON, DEFAULT_CONTRACT_ICON_COLOR } from "../../constances/icons";


export const ContractCell : FC<{ contract : ContractsInstances[] , device : DeviceQueryItem  }> = ({
    contract , device 
}) => {
    const um : string = isServer( device ) ? 'SERVERS' : 'WORKSTATIONS';
    const usableContracts = contract.filter( c => c.contract_unitsMeasured == um );

    return <>
        { usableContracts.map( c => {
            let color = device.contracts[c.id] ? (c.contract_iconColor || DEFAULT_CONTRACT_ICON_COLOR) : "#ccc";
            let active = device.contracts[c.id] ? `Covered by ${c.contract_name}` : `NOT Covered by ${c.contract_name}`;
            return <Tooltip title={active}>
                    <Typography variant="caption" sx={{ color: color }}>
                        <Icon icon={c.contract_icon || DEFAULT_CONTRACT_ICON} fontSize={24} />
                    </Typography>
                </Tooltip>
        })}
    </>
}


export const DevicePreview : FC = () => {
    const { ticket } = useTicket();
    const api = useApi();
    const [ device , setDevice ] = useState<DeviceQueryItem | null>(null);
    const [ contractInstances , setContractInstances ] = useState<ContractsInstances[]>( [] );
    useEffect(() => {
        if( ticket?.device?.id ){
            api.get(`/reports/devices/${ticket.companies_coreCompanyId}` , {
                'deviceId' : ticket.device.id
            }).then( ( { data } ) => {
                setDevice( data.pop() || null );
            } );
        } else { 
            setDevice( null );
        }
    } , [ ticket?.device?.id ]);
    useEffect( () => {
        api.get<ContractsInstances[]>( `/contracts/instances/search` , { coreCompanyId : ticket?.companies_coreCompanyId } ).then( ( results ) => {
            setContractInstances( results.data.filter( c => [ 'SERVERS' , 'WORKSTATIONS' ].includes(c.contract_unitsMeasured) ) );
        } );
    } , [api.ready , ticket?.companies_coreCompanyId ]);

    if( !ticket ){
        return <></>
    }
    return <Card>
        <CardHeader title={<><Typography component="span" sx={{color: ticket?.device?.online ? 'green' : '#333' , paddingRight: 2}}><InlineIcon icon="fluent-mdl2:status-circle-inner"  /></Typography>{ticket?.device?.hostname || "No Device"}</>} />
        <CardContent>
            <Grid container spacing={2} sx={{marginBottom: 2}}>
                <Grid item xs={12} lg={6}><LabeledBox label="Last User" ><Box sx={{
                    minHeight: 31
                }}><LastUser device={ticket.device} /></Box></LabeledBox></Grid>
                { contractInstances.length === 0 && <Grid item xs={12} lg={6}>
                    <LabeledBox label="Legacy Contract"><Box sx={{
                    minHeight: 31
                }}>{ticket.device.udf11 || ''}</Box></LabeledBox>
                </Grid>}
                { contractInstances.length > 0 && <Grid item xs={12} lg={6} >
                    <LabeledBox label="Contracts">
                    {device && <ContractCell contract={contractInstances} device={device} /> }
                </LabeledBox>
                </Grid>}
            </Grid>
            
            <Button sx={{marginRight: 1}} size="small" variant="contained" onClick={() => {
                    window.open(`https://concord.centrastage.net/csm/remote/rto/${ticket.device.id}` , `remote_${ticket.device.id}`)
                }}>Web Remote</Button>
            <Button size="small"  variant="contained" onClick={() => {
                window.open(`https://concord.rmm.datto.com/device/${ticket.device.id}` , `device_${ticket.device.id}`)
            }}>Device in Datto</Button>
        </CardContent>
    </Card>
}