import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TechFinderUpdateCoreMessageChannelMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  acknowledged?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  result?: Types.InputMaybe<Types.CoreMessageChannelResult>;
}>;


export type TechFinderUpdateCoreMessageChannelMutation = { __typename?: 'Mutation', updateCoreMessageChannel: { __typename?: 'CoreMessageChannel', id?: number | null, result?: Types.CoreMessageChannelResult | null, acknowledged?: boolean | null, updatedDateTime: any } };


export const TechFinderUpdateCoreMessageChannelDocument = gql`
    mutation TechFinderUpdateCoreMessageChannel($id: Int!, $acknowledged: Boolean, $result: CoreMessageChannelResult) {
  updateCoreMessageChannel(id: $id, acknowledged: $acknowledged, result: $result) {
    id
    result
    acknowledged
    updatedDateTime
  }
}
    `;
export type TechFinderUpdateCoreMessageChannelMutationFn = Apollo.MutationFunction<TechFinderUpdateCoreMessageChannelMutation, TechFinderUpdateCoreMessageChannelMutationVariables>;

/**
 * __useTechFinderUpdateCoreMessageChannelMutation__
 *
 * To run a mutation, you first call `useTechFinderUpdateCoreMessageChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTechFinderUpdateCoreMessageChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [techFinderUpdateCoreMessageChannelMutation, { data, loading, error }] = useTechFinderUpdateCoreMessageChannelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      acknowledged: // value for 'acknowledged'
 *      result: // value for 'result'
 *   },
 * });
 */
export function useTechFinderUpdateCoreMessageChannelMutation(baseOptions?: Apollo.MutationHookOptions<TechFinderUpdateCoreMessageChannelMutation, TechFinderUpdateCoreMessageChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TechFinderUpdateCoreMessageChannelMutation, TechFinderUpdateCoreMessageChannelMutationVariables>(TechFinderUpdateCoreMessageChannelDocument, options);
      }
export type TechFinderUpdateCoreMessageChannelMutationHookResult = ReturnType<typeof useTechFinderUpdateCoreMessageChannelMutation>;
export type TechFinderUpdateCoreMessageChannelMutationResult = Apollo.MutationResult<TechFinderUpdateCoreMessageChannelMutation>;
export type TechFinderUpdateCoreMessageChannelMutationOptions = Apollo.BaseMutationOptions<TechFinderUpdateCoreMessageChannelMutation, TechFinderUpdateCoreMessageChannelMutationVariables>;