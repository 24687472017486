import { FC } from "react";
import { TicketForPartials } from "../types";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import { useTicketPrioritiesQuery } from "./gql/TicketPriorities.generated";
import { Typography } from "@mui/material";
import { InlineIcon } from "@iconify/react";

type WithPriority = {
  priority?: number | null | undefined;
}

// Helper functions for priority icons and colors
export const priorityToIcon = (priority: number) => {
  switch (priority) {
    // Critical
    case 4:
      return 'tabler:urgent';
    // Unspecified
    case 5:
      return 'mingcute:file-unknown-fill';
    // High
    case 1:
      return 'healthicons:high-bars';
    // Med High
    case 6:
      return 'uiw:warning';
    // Medium
    case 2:
      return 'healthicons:medium-bars';
    // Low
    case 3:
      return 'healthicons:low-bars';
    case 7:
      return 'material-symbols:interactive-space-rounded';
    default:
      return 'mingcute:file-unknown-fill';
  }
};

export const priorityToColor = (priority: number) => {
  switch (priority) {
    // Critical
    case 4:
      return '#df1f3b';
    // Unspecified
    case 5:
      return '#666666';
    // High
    case 1:
      return '#df1f3b';
    // Med High
    case 6:
      return '#f4b900';
    // Medium
    case 2:
      return '#0cc433';
    // Low
    case 3:
      return '#558fcc';
    case 7:
      return '#558fcc';
    default:
      return 'error';
  }
};

export const GqlPriority = <T extends WithPriority>({ 
  ticket,
  edit,
  onChange,
  iconOnly
}: {
  ticket: T;
  edit?: boolean;
  onChange?: (current: T | ((prev: T) => T)) => void;
  iconOnly?: boolean;
}) => {
  const { data, loading: loadingPriorities, error } = useTicketPrioritiesQuery({
    fetchPolicy: 'cache-and-network'
  });
  const loading = loadingPriorities && (data?.TicketPriorities || []).length === 0;
  
  // Filter active priorities and sort by sortOrder
  const priorities = (data?.TicketPriorities || [])
    .filter(priority => priority.isActive)
    .sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(current => ({
        ...current,
        priority: Number(event.target.value)
      }));
    }
  };

  if (!edit) {
    const selectedPriority = priorities.find(p => Number(p.value) === ticket.priority);
    const priorityValue = selectedPriority ? Number(selectedPriority.value) : (ticket.priority || 0);
    
    return (
      <Typography>
        <InlineIcon 
          color={priorityToColor(priorityValue)} 
          icon={priorityToIcon(priorityValue)} 
        />
        &nbsp;{!iconOnly && (selectedPriority?.label || ticket.priority)}
      </Typography>
    );
  }

  return (
    <>
      {loading ? (
        <CircularProgress size={24} />
      ) : error ? (
        <div>Error loading priorities</div>
      ) : (
        <TextField
          select
          size="small"
          fullWidth
          label="Priority"
          value={ticket.priority?.toString() || ''}
          error={!ticket.priority || ticket.priority === 5}
          onChange={handleChange}
        >
          {priorities.map((priority) => (
            <MenuItem 
              key={priority.value} 
              value={priority.value}
              sx={{display: 'flex', flexDirection: 'row'}}
            >
              <Typography>
                <InlineIcon 
                  color={priorityToColor(Number(priority.value))} 
                  icon={priorityToIcon(Number(priority.value))} 
                />
                &nbsp;{priority.label}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );
}; 