import { FC } from "react";
import { TicketForPartials } from "../types";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetCoreCompaniesQuery } from "./gql/GetCoreCompanies.generated";
import { InputAdornment } from "@mui/material";
import { AnyBadge } from "../../AnyBadge";

type WithCoreCompanyId = {
  coreCompanyId?: number | null | undefined;
}

export const GqlCompany = <T extends WithCoreCompanyId>({ 
  ticket,
  edit,
  onChange
}: {
  ticket: T;
  edit?: boolean;
  onChange?: (current: T | ((prev: T) => T)) => void;
}) => {
  const { data, loading : loadingCompanies , error } = useGetCoreCompaniesQuery({
    fetchPolicy: 'cache-and-network',
  });
  // Use the "results" field returned by the query and filter out autotask companies on the client side.
  const options = (data?.findCoreCompaniesPaginated?.data || []);
  const selectedCompany = options.find((option: any) => option.id === ticket.coreCompanyId) || null;
  const handleChange = (event: any, newValue: any) => {
    if (onChange) {
      onChange(current => ({
        ...current,
        coreCompanyId: newValue ? newValue.id : undefined
      }));
    }
  };

  if (!edit) {
    return <div>{ticket.coreCompanyId}</div>;
  }
 const loading = loadingCompanies && options.length === 0 && !selectedCompany;
  return (
    <>
      {loading ? (
        <CircularProgress size={24} />
      ) : error ? (
        <div>Error loading companies</div>
      ) : (
        <Autocomplete
          size="small"
          fullWidth
          options={options}
          getOptionLabel={(option: any) => option.name}
          value={
            options.find((option: any) => option.id === ticket.coreCompanyId) || null
          }
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Company"
              fullWidth
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    {ticket.coreCompanyId && selectedCompany?.id === ticket.coreCompanyId && (
                      <AnyBadge 
                        name={`${selectedCompany.name}`} 
                        colorkey={selectedCompany.id || undefined} 
                      />
                    )}
                  </InputAdornment>
                ),
                endAdornment: (
                  <>
                    {loading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      )}
    </>
  );
};