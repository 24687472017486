import { useEffect, useState } from "react";
import { useApi } from "../react-submodules/providers/Api";
import { useMounted } from "../react-submodules/hooks/useMounted";

export const useApiResults = <T extends any>( apiEndpoint: string , query?: Record<string, any> ) => {
    const api = useApi();
    const [ results , setResults ] = useState<T | null>( null );
    const [ loading , setLoading ] = useState( false );
    const [ refresh , setRefresh ] = useState( 1 );
    const mounted = useMounted();
    useEffect( () => {
        const to = setTimeout( () => {
            api.get<T>( apiEndpoint , query )
            .then( ( response ) => {
                if ( mounted() ) {
                    setResults( response.data );
                    setLoading( false );
                }
            } )
            .catch( ( error ) => {
                if ( mounted() ) {
                    setLoading( false );
                }
            } );
        } , 50);
        return () => {
            clearTimeout( to );
        }
    }, [ JSON.stringify( query ) , refresh ] );
    return  [ JSON.parse( JSON.stringify( results ) ) ,  { loading , refresh: () => {
        setRefresh( refresh * -1 );
    } } ];
}