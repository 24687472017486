import { FC, useEffect, useState } from "react";
import { useTicketTodoGroupsQuery } from "./gql/TicketTodoGroups.generated";
import { TTodosTodo } from "../../types/todos.dto";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Icon } from "@iconify/react";
import { TodoItem } from "./TodoItem";
import { MarkdownView } from "../../MarkdownView";
import BulkTodoDialog from "../BulkTodoDialog";

export const TodoGroupDisplay : FC<{ticketId? : number | null , todos : TTodosTodo[], refresh?: () => void}> = ({ticketId , todos, refresh}) => {
    const theme = useTheme();
    const { data: todoGroupsData } = useTicketTodoGroupsQuery({
        variables: {
            ticketId: ticketId || 0
        },
        skip: !ticketId,
        fetchPolicy: 'cache-and-network',
    });
    
    const todoGroups = todoGroupsData?.findTodosGroupPaginated.data || [];
    
    // No need to render anything if there are no groups
    if (todoGroups.length === 0) {
        return null;
    }
    
    return (
        <Box sx={{
            margin: 1,
            padding: 1,
            borderBottom: '1px solid rgba(0,0,0,.4)'
        }}>
            <Typography 
                variant="body2" 
                sx={{ 
                    fontWeight: 'bold',
                    marginBottom: 1,
                    paddingBottom: 0.5,
                    borderBottom: '2px solid #FF8C00'
                }}
            >
                Group Tasks
            </Typography>
            <Box sx={{ marginLeft: 0.5, marginRight: 0.5 }}>
                {todoGroups.map(group => (
                    <TodoGroupSection 
                        key={group.id} 
                        group={{
                            id: group.id || 0,
                            title: group.title || '',
                            description: group.description || ''
                        }} 
                        todos={todos.filter(todo => Number(todo.todoGroupId) === Number(group.id))} 
                        refresh={refresh}
                    />
                ))}
            </Box>
        </Box>
    );
};

type TodoGroupType = {
    id: number;
    title: string;
    description: string;
};

const TodoGroupSection: FC<{
    group: TodoGroupType,
    todos: TTodosTodo[],
    refresh?: () => void
}> = ({ group, todos, refresh }) => {
    const theme = useTheme();
    const [expanded, setExpanded] = useState(todos.filter(t => !t.complete).length > 0);
    const [ addGroupTasksId , setAddGroupTasksId ] = useState<number | null>( null );
    useEffect(() => {
        if (todos.filter(t => !t.complete).length > 0) {
            setExpanded(true);
        }
    }, [todos.filter(t => !t.complete).map( t => t.id).sort().join("-")]);
    
    if (todos.length === 0) {
        return null;
    }
    
    return <>
    <BulkTodoDialog todoGroupId={addGroupTasksId || undefined} open={addGroupTasksId !== null} onClose={()=>{
        setAddGroupTasksId( null );
        refresh?.();    
    }} />
    <Box sx={{
            border: '1px solid #FF8C00',
            borderRadius: 2,
            margin: '8px 0',
            overflow: 'hidden'
        }}>
            <Box sx={{
                display: 'flex',
                background: '#FF8C00', // Dark Orange
                color: theme.palette.common.white,
                borderRadius: 0,
                borderTopLeftRadius: 'inherit',
                borderTopRightRadius: 'inherit'
            }}>
                <IconButton 
                    size="small" 
                    sx={{
                        color: theme.palette.common.white,
                        background: '#FF8C00'
                    }} 
                    onClick={() => setExpanded(!expanded)}
                >
                    <Icon 
                        color="white" 
                        icon={expanded ? 'icon-park-outline:down-c' : 'icon-park-outline:right-c'} 
                    />
                </IconButton>
                <Typography 
                    onClick={() => setExpanded(!expanded)} 
                    sx={{ 
                        cursor: 'pointer', 
                        flexGrow: 1, 
                        marginLeft: 1, 
                        fontWeight: 'bold' 
                    }} 
                    variant="body1"
                >
                    {group.title}
                </Typography>
                <IconButton 
                    size="small" 
                    sx={{
                        color: theme.palette.common.white,
                        background: '#FF8C00'
                    }} 
                    onClick={() => setAddGroupTasksId( group.id )}
                >
                    <Icon 
                        color="white" 
                        icon="icon-park-outline:add" 
                    />
                </IconButton>
            </Box>
            
            {expanded && (
                <Box sx={{ 
                    marginLeft: 2,
                    marginRight: 2,
                    marginBottom: 1,
                    paddingTop: 1
                }}>
                    {group.description && (
                        <Box sx={{ 
                            marginBottom: 1, 
                            padding: 0.5, 
                            background: 'rgba(180, 180, 180, .2)',
                            borderRadius: 1
                        }}>
                            <MarkdownView size="small">{group.description}</MarkdownView>
                        </Box>
                    )}
                    
                    {todos.sort((a, b) => {
                        if (a.complete === b.complete) {
                            return new Date(b.createdAt || '').getTime() - new Date(a.createdAt || '').getTime();
                        }
                        return a.complete ? 1 : -1;
                    }).map(todo => (
                        <TodoItem 
                            todo={todo} 
                            key={todo.id} 
                            onChange={() => { if (refresh) refresh(); }} 
                        />
                    ))}
                </Box>
            )}
        </Box>
    </>
};