import { FC } from "react"
import { ContractDTO } from "../../../react-submodules/types/contracts.dto"
import { Chip } from "@mui/material"

export const UnitPrice: FC<{
    row: ContractDTO    
}> = ({ row }) => {
    const price = row.unitPrice !== null ? row.unitPrice : row.contract_unitPrice
    if (price === null) {
        return <></>
    }
    const chipColor = price === 0 ? "default" : (row.unitPrice !== null ? "info" : "success")
    return <Chip color={chipColor} label={`$${price.toFixed(2)}`} variant="filled" />
}