import { CoreTimeclock, useTimeclock } from "../Timeclock";
import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from "react";

import { FormInstance } from "../../../coretypes/form.dto";
import { QueueTicket } from "../../types/ticketqueue";
import { TTodosTodo } from "../../types/todos.dto";
import { useApi } from "../Api/Api";
import { useRecipeApi } from "../Recipe";
import { AzureTeamsChat } from "../../types/azure";

export type FieldPicklist = {
    "label": string;
    "value": string
    "isActive": boolean
    "isSystem": boolean,
    "sortOrder": number,
    "parentValue": string,
    "isDefaultValue": boolean,
}

export interface ITicketProvider {
    ticket: QueueTicket | null
    ticketPunch: null | CoreTimeclock
    todos: TTodosTodo[]
    forms: FormInstance[]
    chats: AzureTeamsChat[]
    refresh: () => void
}
export const TicketContext = createContext<ITicketProvider>({
    ticket: {} as any,
    ticketPunch: null,
    todos: [],
    forms: [],
    chats: [],
    refresh: () => {}
});
export const useTicket = () => useContext(TicketContext);