import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateFileUploadMutationVariables = Types.Exact<{
  fileId: Types.Scalars['Float']['input'];
}>;


export type ValidateFileUploadMutation = { __typename?: 'Mutation', validateFile: { __typename?: 'CoreFile', id?: number | null, name?: string | null, verified: boolean } };


export const ValidateFileUploadDocument = gql`
    mutation ValidateFileUpload($fileId: Float!) {
  validateFile(fileId: $fileId) {
    id
    name
    verified
  }
}
    `;
export type ValidateFileUploadMutationFn = Apollo.MutationFunction<ValidateFileUploadMutation, ValidateFileUploadMutationVariables>;

/**
 * __useValidateFileUploadMutation__
 *
 * To run a mutation, you first call `useValidateFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateFileUploadMutation, { data, loading, error }] = useValidateFileUploadMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useValidateFileUploadMutation(baseOptions?: Apollo.MutationHookOptions<ValidateFileUploadMutation, ValidateFileUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateFileUploadMutation, ValidateFileUploadMutationVariables>(ValidateFileUploadDocument, options);
      }
export type ValidateFileUploadMutationHookResult = ReturnType<typeof useValidateFileUploadMutation>;
export type ValidateFileUploadMutationResult = Apollo.MutationResult<ValidateFileUploadMutation>;
export type ValidateFileUploadMutationOptions = Apollo.BaseMutationOptions<ValidateFileUploadMutation, ValidateFileUploadMutationVariables>;