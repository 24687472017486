import { FC } from "react";
import { AzureTeamsChat } from "../../types/azure";
import { useTicket } from "../../providers/TicketProvider";
import { Box, Button, ThemeProvider, Typography, createTheme } from "@mui/material";
import { Icon } from "@iconify/react";

const teamsTheme = createTheme({
    palette: {
        primary: {
            main: '#4b54bd'
        },
    },
});


export const TeamsSidebarCore : FC<{
    chats : AzureTeamsChat[]
}> = ({ chats }) => {
    return <><Box sx={{
        margin: 1,
        borderBottom: '1px solid rgba(0,0,0,.4 )'
    }}><Typography variant="body2">Teams Channels</Typography></Box>
    <ThemeProvider theme={teamsTheme}>
    {chats.map( ( chat ) => {
        return <Box sx={{
            margin: 1,
            borderBottom: '1px solid rgba(0,0,0,.4 )'
        }}><Button onClick={()=>{
            window.open( chat.webUrl, `teams_${chat.id}` )
        }} sx={{width: '100%'}} variant="contained" ><Icon icon="mdi:microsoft-teams" /> &nbsp; {chat.topic}</Button></Box>
    })}
    </ThemeProvider>
    </>
}

export const TeamsSidebar = () => {
    const { chats } = useTicket();
    return <TeamsSidebarCore chats={chats} />
}
