import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
type EditTimepunchProps = {
    id : number | null;
    onClose : () => void;
};

const GET_TIMECLOCK = gql`
    query GetTimeclock($id: Float!) {
        timeclock(id: $id) {
            id
            start
            end
            message
            ticket {
                id
                title
            }
        }
    }
`;

const UPDATE_TIMECLOCK = gql`
    mutation UpdateTimeclock($id: Float!, $start: Date, $end: Date) {
        updateTimeclock(id: $id, start: $start, end: $end) {
            id
            start
            end
        }
    }
`;


export const EditTimepunch : FC<EditTimepunchProps> = ({ id , onClose }) => {
    const { data , loading , error } = useQuery(GET_TIMECLOCK, { variables: { id: Number(id) } , skip: !id });
    const [updateTimeclock] = useMutation(UPDATE_TIMECLOCK);
    const [start, setStart] = useState<any>(dayjs(  data?.timeclock?.start));
    const [ startDirty, setStartDirty ] = useState(false);
    const [end, setEnd] = useState<any>(dayjs(data?.timeclock?.end));
    const [ endDirty, setEndDirty ] = useState(false);
    const onSave = () => {
        const props : Partial<{start: string, end: string}> = {};
        if( startDirty){
            props.start = start.toISOString();
        }
        if( endDirty){
            props.end = end.toISOString();
        }
        console.log({ props });
        if( Object.keys(props).length > 0 ){
            try {
            updateTimeclock({ variables: { id: Number(id), ...props } }).then( () => {
                    onClose();
                }).catch( (error) => {
                    console.error(error);
                });
            } catch (error) {
                console.error(error);
            }
        }
    };
    useEffect(() => {
        if( !loading && data?.timeclock ){
            setStart(dayjs(data?.timeclock?.start));
            setEnd(dayjs(data?.timeclock?.end));
            setStartDirty(false);
            setEndDirty(false);
        }
    }, [loading]);
    return <Dialog open={id !== null} onClose={onClose}>
        <DialogTitle>Edit Timepunch</DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {data?.timeclock?.ticket?.title}
                </Grid>
                <Grid item xs={12}>
                <DateTimePicker
                    sx={{ width: 'calc( 100% - 16px )' , margin: 1 }}
                    label="Start"
                    value={start}    
                    onChange={(newValue) => { setStartDirty(true); setStart(newValue) }}
                    />
                </Grid>
                <Grid item xs={12}>
                <DateTimePicker
                    sx={{ width: 'calc( 100% - 16px )' , margin: 1 }}
                    label="End"
                    value={end}    
                    onChange={(newValue) => { setEndDirty(true); setEnd(newValue) }}
                    />
                </Grid>
            </Grid>
            
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="success" variant="contained" onClick={onSave}>Save</Button>
        </DialogActions>
    </Dialog>
}