import { FC, useState } from "react";
import { AddEditContactDialog } from "./AddEditContactDialog"
import { CoreContact } from "../../../../coretypes/contacts/contacts.dto";
import { useApi } from "../../../../react-submodules/providers/Api";
import { useMounted } from "../../../../react-submodules/hooks/useMounted";

export const EditContactDialog : FC<{
    element : CoreContact | null
    onClose : () => void
    
}> = ({ element, onClose }) => {
    const open = Boolean(element);
    const api = useApi();
    const mounted = useMounted();
    const onSave = (data : Partial<CoreContact> ) => {
        api.patch(`/core/contacts/${element?.id}`, data).then(() => {
            mounted() && onClose();
        });
    }
    return  <> { element && <AddEditContactDialog initalValues={element} open={open} coreCompanyId={element.coreCompanyId} title="Edit Contact" onClose={onClose} onSave={( data) => {
        onSave(data);
    }} onSaveText="Save" />} </>
}