import { Dialog, DialogTitle, DialogContent, TextField, Grid, MenuItem, InputAdornment, DialogActions, Button } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { MarkdownEditor } from "../../react-submodules/components/MarkdownEditor/MarkdownEditor";
import { useApi } from "../../react-submodules/providers/Api";
import { measuringUnits } from "./types";



export const AddContract : FC<{open: boolean, onClose: () => void}> = ({open, onClose}) => {

    const api = useApi();

    

    const [name, setName] = useState('');
    const [ internalDescription, setInternalDescription ] = useState('');
    const [ publicDescription, setPublicDescription ] = useState('');
    const [ unitsMeasured, setUnitsMeasured ] = useState('');
    const [ unitPrice, setUnitPrice ] = useState('');
    const [ additionalPrice, setAdditionalPrice ] = useState('');
    useEffect( () => {
        if ( open ) {
            setName('');
            setInternalDescription('');
            setPublicDescription('');
            setUnitsMeasured('');
            setUnitPrice('');
            setAdditionalPrice('');
        }
    }, [ open])
    const addContract = () => {
        api.post('/contracts/create', {
            name,
            internalDescription,
            publicDescription,
            unitsMeasured,
            unitPrice,
            additionalPrice
        }).then(() => {
            onClose();
        })
    }
    return <Dialog open={open} onClose={onClose} maxWidth="lg">
        <DialogTitle>Add Contract</DialogTitle>
        <DialogContent sx={{width: '650px'}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{marginTop: 2 }}>
                    <TextField size="small" fullWidth label="Name" value={name} onChange={( e)=>{ setName( e.target.value)}} />
                </Grid>
                <Grid item xs={12}>
                    <TextField select size="small" fullWidth label="Units Measured" value={unitsMeasured} onChange={( e)=>{ setUnitsMeasured( e.target.value)}} >
                        {measuringUnits.map(( unit ) => <MenuItem key={unit} value={unit}>{unit}</MenuItem>)}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        size="small" type="number" fullWidth label="Each Unit Price" value={unitPrice} onChange={( e)=>{ setUnitPrice( e.target.value)}} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        size="small" type="number" fullWidth label="Additional Price" value={additionalPrice} onChange={( e)=>{ setAdditionalPrice( e.target.value)}} />
                </Grid>
                <Grid item xs={12}>
                    <MarkdownEditor label="Internal Description" markdown={internalDescription} onChange={( value)=>{ setInternalDescription( value )}} />  
                </Grid>
                <Grid item xs={12}>
                    <MarkdownEditor label="Public Description" markdown={publicDescription} onChange={( value)=>{ setPublicDescription( value )}} />
                </Grid>
            </Grid>

        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="primary" onClick={()=>{
                addContract();
            }}>Add</Button>
        </DialogActions>
    </Dialog>
}