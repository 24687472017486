import { TableColumnDef } from "../Table";
import { DataGrid, GridColDef , gridClasses} from "@mui/x-data-grid";
import { FunctionComponent, useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import { alpha, styled } from '@mui/material/styles';
import { Box, Button, IconButton, InputAdornment, Popover, SxProps, Table, TableBody, TableCell, TableHead, TableRow, TextField, Theme, Typography, useTheme } from "@mui/material";
import { InlineIcon } from "@iconify/react";
import { useFilter } from "../../../providers/FilterProvider";
const prependNumber = ( num : number ) => {
    if( num < 10 ){
      return `0${num}`;
    }
    return `${num}`;
   }
const isoToDate = ( iso? : string | null ) => {
    if( !iso ){
      return null;
    }
    const date = new Date( iso );
    return `${date.getFullYear()}-${prependNumber(date.getMonth() + 1)}-${prependNumber(date.getDate())}`;
   }

export const FilterMenuDate = ( { column , onClose } : { column : TableColumnDef , onClose: ()=> void } ) => {
    const {filter , updateFilter , clearFilter } = useFilter( column.field );
    const [min, setMin] = useState<string| null >( typeof filter?.min  === "string" ? filter?.min : null );
    const [max, setMax] = useState<string| null >( typeof filter?.max  === "string" ? filter?.max : null );
    const theme = useTheme();
    const handleClose = ()=>{ 
  
      const minDate = min ? isoToDate( min ) : null;
      const maxDate = max?  isoToDate( max ): null;
      if( max === null && min === null ){
        clearFilter();
        onClose();
        return;
      } 
      if( minDate && maxDate){
        updateFilter( {
          method: 'between' , min : minDate, max : maxDate
        } );
        onClose();
          return;
      }
      if( typeof min === "number" ){
        updateFilter( {
          method: 'gte' , value: minDate ,min : minDate, max : maxDate
        } );
        onClose();
          return;
      }
      if( typeof max === "number" ){
        updateFilter( {
          method: 'lte'  , value: maxDate ,min : minDate, max : maxDate
        } );
        onClose();
          return;
      }
      onClose();
    }
    
    return <>
    <Box sx={{background: theme.palette.background.paper , borderRadius: 1 }}><Box sx={{padding: 1 }}>
      <TextField InputLabelProps={{ ...(min !== "" && { shrink: true }) }}  label="min" size="small" type="date" sx={{  borderRadius: 1}} value={isoToDate(min)} onChange={( e ) => {
      
      setMin( e.target.value || null  );
    } } /></Box>
    <Box sx={{padding: 1 }}>
    <TextField InputLabelProps={{ ...(max !== "" && { shrink: true }) }}  label="Max" size="small" type="date" sx={{ background: theme.palette.background.paper , borderRadius: 1}} value={isoToDate(max)} onChange={( e ) => {
      
      setMax(e.target.value || null  );
    } } />
    </Box>
    </Box>
    <Box sx={{padding: 1 , position: 'relative' , width: '100%' , height: '48px' }}>
      <Button size="small"  onClick={()=>{ clearFilter(); onClose(); }} color="warning" sx={{ position: 'absolute' , right: '75px'}} variant="contained">Clear</Button>
      <Button size="small" onClick={handleClose} color="secondary" sx={{ position: 'absolute' , right: '0'}} variant="contained">Save</Button>
    </Box>
    </>
   }