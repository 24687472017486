import { Button, Container, Dialog, DialogContent, DialogTitle, IconButton, Paper, Switch, TextField, Tooltip } from "@mui/material"
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useApi } from "../../react-submodules/providers/Api/Api";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { StyledTable, TableColumnDef } from "../../react-submodules/components/Table/Table";
import { AddCompanyDialog } from "./AddCompanyDialog";
import { gql, useMutation } from "@apollo/client";
import { MergeCompaniesDialog } from "./MergeCompaniesDialog";
import { LinkAzureDialog } from "./LinkAzureDialog";

const columns : TableColumnDef[] = [
    {
        field: 'id',
        flex: 1,
        headerName: 'ID',
        sortable: true
    },
    {
        field: 'name',
        flex: 1,
        sortable: true,
        headerName: 'Name'
        
    },
    {
        field: 'autotaskIds',
        flex: 1,
        headerName: 'Autotask',
        Element: ( { cell , row } ) => {
            if( cell !== null ){
                return <Icon style={{color: 'green'}} icon="mdi:check-outline" />
            } else {
                return <Icon style={{color: 'red'}} icon="mdi:alert-circle-outline" />
            }
        }
    }
    ,
    {
        field: 'dattoRmmSiteIds',
        flex: 1,
        headerName: 'DattoRmm',
        Element: ( { cell , row } ) => {
            if( cell !== null ){
                return <Icon style={{color: 'green'}} icon="mdi:check-outline" />
            } else {
                return <Icon style={{color: 'red'}} icon="mdi:alert-circle-outline" />
            }
        }
    },
    {
        field: 'itglueIds',
        flex: 1,
        headerName: 'ITGlue',
        Element: ( { cell , row } ) => {
            if( cell !== null ){
                return <Icon style={{color: 'green'}} icon="mdi:check-outline" />
            } else {
                return <Icon style={{color: 'red'}} icon="mdi:alert-circle-outline" />
            }
        }
    },
    {
        field: 'azureTenantIds',
        flex: 1,
        headerName: 'Azure',
        Element: ( { cell , row , setLinkAzureDialogCompanyId , setUnlicenseDialogCompanyId } ) => {
            const verified = row?.azureTenantsVerified?.length > 0 && row?.azureTenantsVerified?.every( ( verified : any ) => verified );
            const licensed = row?.azureTenantsLicensed?.length > 0 && row?.azureTenantsLicensed?.every( ( licensed : any ) => licensed );
            
            const itGlue = row.itglueIds?.length > 0 ? row.itglueIds[0] : null;
            const link = itGlue ? `https://couleetech.itglue.com/${itGlue}/assets/300127-azure-active-directory` : null;
            if( cell !== null && verified && licensed ){
                return <Icon style={{color: 'green'}} icon="mdi:check-outline" />
            }
            if( cell !== null && verified && !licensed ){
                return <><Icon style={{color: 'green'}} icon="mdi:check-outline" />/<Tooltip title="Azure Tenant needs a P1 license"><IconButton onClick={(e)=>{
                    e.stopPropagation();
                    setUnlicenseDialogCompanyId( row.id );
                }}><Icon style={{color: 'orange'}} icon="material-symbols:unlicense" /></IconButton></Tooltip></>
            }
            if( cell === null ){
                return <IconButton onClick={(e)=>{
                    e.stopPropagation();
                    link && window.open( link , `${itGlue}_itglue` );
                }}><Icon style={{color: 'red'}} icon="mdi:alert-circle-outline" /></IconButton>
            }
            return <IconButton onClick={(e)=>{
                e.stopPropagation();
                console.log( row );
                setLinkAzureDialogCompanyId( row.id );
            }}><Icon style={{color: 'orange'}} icon="ic:baseline-warning" /></IconButton>
        }
    },
    /*{
        field: 'actions',
        flex: 1,
        headerName: 'Actions',
        Element: ( { row , setMergeCompany , setLinkAzureDialogCompanyId } ) => {
            const verified = row?.azureTenantsVerified?.length > 0 && row?.azureTenantsVerified?.every( ( verified : any ) => verified );
            return <>
            {row.duplicate && <Button onClick={(e)=>{e.stopPropagation(); setMergeCompany( row.id )}}>Merge</Button>}
            {!row.azureTenantIds && <Button onClick={(e)=>{e.stopPropagation(); setLinkAzureDialogCompanyId( row.id )}} >Link Azure</Button>}
            <Button onClick={(e)=>{e.stopPropagation(); console.log(row , verified  , row?.azureTenantsVerified )}}>Log</Button></>;
        }
    } */

]



export const Companies = () => {
    const nav = useNavigate();
    
    const [ search, setSearch ] = useState( '' );
    const [ coreCompanies  , setCompanies ] = useState( [] );
    const [ linkAzureDialogCompanyId , setLinkAzureDialogCompanyId ] = useState<number | null>( null );
    const [ unlicenseDialogCompanyId , setUnlicenseDialogCompanyId ] = useState<number | null>( null );
    const companies = useMemo(()=>{
        return coreCompanies.map( ( company : any  , index , arr : any[] ) => {

            const foundindex = arr.findIndex( ( c ) => c.name.trim().toLowerCase() === company.name.trim().toLowerCase() );
            return {
                ...company,
                duplicate: foundindex !== index
            }
        })
    }, [JSON.stringify(coreCompanies)])
    const [ active , setActive ] = useState( true );
    const api = useApi();
    const [ open , setOpen ] = useState( false );
    const [ mergeCompany , setMergeCompany ] = useState<number | null>( null );
    useEffect(() => {
        const getData = setTimeout(() => {
            let query : Record<string, any> = {  'active' : active  };
            if( search ){
                query['name:like'] = search;
            }
          api.get( '/core/companies' , query ).then( ( results ) => {
            setCompanies( results.data );
          });
        }, 500)
    
        return () => clearTimeout(getData)
        }, [search])


    return <>
    <AddCompanyDialog open={open} onClose={(id)=>{setOpen(false); if( id ){ nav(`/company/${id}`) }}} />
    <LinkAzureDialog coreCompanyId={linkAzureDialogCompanyId} onClose={()=>{setLinkAzureDialogCompanyId(null)}} />
    <Dialog open={unlicenseDialogCompanyId !== null} onClose={()=>{setUnlicenseDialogCompanyId(null)}}>
        <DialogTitle>Unlicense Azure</DialogTitle>
        <DialogContent>
            Azure Tenant needs a P1 license.
        </DialogContent>
    </Dialog>
    <MergeCompaniesDialog mergeCompany={mergeCompany} onClose={()=>{setMergeCompany(null)}} />
    <Container sx={{marginTop: 2 }}>
        <Paper elevation={2} sx={{padding: 1}}>
            <TextField fullWidth label="Search" value={search} onChange={( e)=>{ setSearch( e.target.value)}} />
                <Switch checked={active} onChange={( e)=>{ setActive( e.target.checked)}} />
        </Paper>
        </Container>
        { companies.length > 0 && (
            <Container sx={{marginTop: 2 }}>
                    <Paper>
                        <StyledTable
                            elementProps={{ setMergeCompany, setLinkAzureDialogCompanyId , setUnlicenseDialogCompanyId }}
                            actions={<Button onClick={()=>{setOpen(true)}} variant="contained" color="secondary">Add</Button>}
                            onClick={( row)=>{
                                nav(`/company/${row.id}`);
                            }}
                            rows={companies}
                            columns={columns}
                        />
                    </Paper>
            </Container>
        )
        }
    </>
}