import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { CoreUser } from "../../../react-submodules/types/core";
import { QueueTicket } from "../../../react-submodules/types/ticketqueue";
import { UserCard } from "../UserCard";
import { useApi } from "../../../react-submodules/providers/Api/Api";
import { useMounted } from "../../../react-submodules/hooks/useMounted";

export type UserReportData = {
    user: CoreUser;
    dispatch: {
        id : number| null;
        coreUserId: string| null;
        ticketId: number| null;
        dispatchedAt: string| null;
        lastSeen: string| null;
        accepted: boolean| null;
        acceptedAt: string| null;
        active: boolean | null;
    }
    dispatchticket: {
        id: number| null;
        title: string| null;
        ticketNumber: string| null;
    } & Record<string, string | null | number | boolean>;
    timeclockticket: {
        id: number| null;
        title: string| null;
        ticketNumber: string| null;
    } & Record<string, string | null | number | boolean>;
    queue: QueueTicket[];

}

export const OldManagerView = () => {
    
    const loaded = useMounted();
    const [ tickets , setTickets ] = useState< UserReportData[] >( [] );
    const api = useApi();
    const [ userId , setUserId ] = useState<  string | null >( null );
    const [ tick , setTick ] = useState( 1 );


    
    const refreshQueueData = () => {
        api.get<UserReportData[]>(`/ticketqueue/report/useroverview` ).then( ( { data }) => {
            loaded() && setTickets( data.sort( ( a , b ) => {
                return a?.user?.email?.localeCompare( b?.user?.email || '' );
            }) );
        });
    }

    useEffect( () => {
        refreshQueueData();
    } , [ tick]);

    useEffect( () => {
        setTick( pv => pv * -1 );
        const iv = setInterval( () => {
            setTick( pv => pv * -1 );
        }, 15000);
        return () => {
            clearInterval( iv );
        }
    }, [ api.ready ]);

    
    return <>
    <Typography variant="h4">Manager View</Typography>
    <Grid container>
        {tickets.map( item => {
            return <Grid item sm={12} md={6} lg={4} xl={3} key={item.user.id}>
                <UserCard item={item} />
                </Grid>
        } )}
    </Grid>
</>
}