import { Accordion, AccordionDetails, AccordionSummary, Box, ListItem, Tooltip, Typography } from "@mui/material"
import { AutotaskTicketNotes, AutotaskTimeEntries, isTicketTime } from "../../types/autotask.dto"

import { AnyBadge } from "../AnyBadge"
import { FC } from "react"
import { MarkdownView } from "../../MarkdownView"
import { Icon } from "@iconify/react"
import { NOTE_ICON, NOTE_ICON_DEFAULT_COLOR, TIME_ICON, TIME_ICON_DEFAULT_COLOR } from "../../constances/icons"

const TicketTimeItem = ({ note } : { note: AutotaskTimeEntries }) => {
    return <Accordion>
       
        <AccordionSummary>
        <Box sx={{ display: 'flex', alignItems: 'center' , paddingRight: 1 , color : TIME_ICON_DEFAULT_COLOR }}>
            <Icon icon={TIME_ICON} />
        </Box>
            <AnyBadge name={`${note.resources_firstName} ${note.resources_lastName}`} colorkey={note.resources_email||undefined} />
        <Typography sx={{ paddingLeft: 1, whiteSpace: 'nowrap' }}>
                    {new Date(note.createDateTime).toLocaleString('en-US', { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(",", "")}
                </Typography>
        <Typography sx={{fontWeight: 'bold', marginLeft: 1}}> Time Entery ({note.hoursWorked}h)</Typography></AccordionSummary>
        <AccordionDetails><MarkdownView>{note.summaryNotes}</MarkdownView></AccordionDetails>
    </Accordion>
    
    
}

/* 

<ListItem onClick={()=>{ console.log( note )}} sx={{border: '1px solid black' , borderRadius: 3 , display: 'flex' , flexDirection : 'column'}}  key={note.id} >
        <Box>TIME: {note.createDateTime} {note.id} {note.ticketID}</Box>
    <Box whiteSpace='pre-line'>{note.summaryNotes}</Box></ListItem>


<ListItem onClick={()=>{ console.log( note )}} sx={{border: '1px solid black' , borderRadius: 3 , display: 'flex' , flexDirection : 'column'}}  key={note.id} >
        <Box>TYPE: {note.noteType} {note.createDateTime} {note.id} {note.ticketID}</Box>
        <Box whiteSpace='pre-line'>{note.description}</Box>
    </ListItem>
*/

const NoteBadge : FC<{note: AutotaskTicketNotes}> = ({note}) => {
    if( note.contacts_firstName){
        return <AnyBadge name={`${note.contacts_firstName} ${note.contacts_lastName}`} colorkey={note.contacts_emailAddress||undefined} />
    }
    if( note.resources_firstName){
        return <AnyBadge name={`${note.resources_firstName} ${note.resources_lastName}`} colorkey={note.resources_email||undefined} />
    }
    return <></>
    
}


export const NoteItem : FC<{note: AutotaskTicketNotes | AutotaskTimeEntries }> = ({ note }) => {
    if( isTicketTime( note ) ) return <TicketTimeItem note={note} />

    return <Accordion onChange={() => {
    }}>
        <AccordionSummary>
            <Box sx={{ display: 'flex', alignItems: 'center' , paddingRight: 1 , color : NOTE_ICON_DEFAULT_COLOR }}>
                <Icon icon={NOTE_ICON} />
            </Box>
                <NoteBadge note={note}  /> 
                <Typography sx={{ paddingLeft: 1, whiteSpace: 'nowrap' }}>
                    {new Date(note.createDateTime).toLocaleString('en-US', { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(",", "")}
                </Typography>
                <Typography sx={{ paddingLeft: 1 , fontWeight: 'bold'}}>{note.title}</Typography></AccordionSummary>
        
        <AccordionDetails><MarkdownView>{note.description}</MarkdownView></AccordionDetails>
    </Accordion>
    
    
}