import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TicketStatusesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TicketStatusesQuery = { __typename?: 'Query', TicketStatuses: Array<{ __typename?: 'PicklistEnteryGql', label: string, value: string, isActive: boolean, isSystem: boolean, sortOrder: number, parentValue: string, isDefaultValue: boolean }> };


export const TicketStatusesDocument = gql`
    query TicketStatuses {
  TicketStatuses {
    label
    value
    isActive
    isSystem
    sortOrder
    parentValue
    isDefaultValue
  }
}
    `;

/**
 * __useTicketStatusesQuery__
 *
 * To run a query within a React component, call `useTicketStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTicketStatusesQuery(baseOptions?: Apollo.QueryHookOptions<TicketStatusesQuery, TicketStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TicketStatusesQuery, TicketStatusesQueryVariables>(TicketStatusesDocument, options);
      }
export function useTicketStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketStatusesQuery, TicketStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TicketStatusesQuery, TicketStatusesQueryVariables>(TicketStatusesDocument, options);
        }
export function useTicketStatusesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TicketStatusesQuery, TicketStatusesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TicketStatusesQuery, TicketStatusesQueryVariables>(TicketStatusesDocument, options);
        }
export type TicketStatusesQueryHookResult = ReturnType<typeof useTicketStatusesQuery>;
export type TicketStatusesLazyQueryHookResult = ReturnType<typeof useTicketStatusesLazyQuery>;
export type TicketStatusesSuspenseQueryHookResult = ReturnType<typeof useTicketStatusesSuspenseQuery>;
export type TicketStatusesQueryResult = Apollo.QueryResult<TicketStatusesQuery, TicketStatusesQueryVariables>;