import { PropsWithChildren, createContext, useContext, useEffect, useState, useMemo } from "react";

type TButtonTypes = 'active_queue_toggle' | 'user_roles' | 'my_questions' | 'ticket_search';

type ContextMenuItem = {
    label: string;
    onClick: () => any;
}

type LayoutButtonProps = {
    key: string;
    label?: string;
    onClick?: () => any;
    link?: string;
    button_type?: TButtonTypes;
    contextMenu?: ContextMenuItem[]
}
type LayoutButtonTrueClick = {
    key: string;
    label: string;
    onClick: () => any;
    contextMenu?: ContextMenuItem[]
}
type LayoutButtonTrueLink = {
    key: string;
    label: string;
    link: string;
    contextMenu?: ContextMenuItem[]
}

type LayoutButtonRaw = {
    key: string;
    button_type: TButtonTypes
}


export type LayoutButton = LayoutButtonProps & (LayoutButtonTrueClick | LayoutButtonRaw | LayoutButtonTrueLink );
interface LayoutProvider {
    buttons: LayoutButton[];
    addButton: ( button: LayoutButton ) => any;
    removeButton: ( key: string ) => any;
}
const LayoutContext = createContext<LayoutProvider>({
    buttons: [],
    addButton: () => {},
    removeButton: () => {}
});
export const useLayout = () => useContext(LayoutContext);

export const LayoutProvider :React.FC<PropsWithChildren > = ( { children}) => {
    const [ buttons , setButtons ] = useState<LayoutProvider['buttons']>([]);
    const addButton = ( button: LayoutButton ) => {
        setButtons( ( buttons ) => {
            return [
                ...buttons,
                button
            ]
        })
    }
    const removeButton = ( key: string ) => {
        setButtons( ( buttons ) => {
            return buttons.filter( ( button ) => button.key !== key )
        })
    }

    // Memoize the provider value to prevent unnecessary re-renders
    const providerValue = useMemo(() => ({
        buttons, addButton, removeButton
    }), [buttons]);

    return (
        <LayoutContext.Provider value={providerValue}>{children}</LayoutContext.Provider>
    )
}