export const ADD_ICON = 'fluent:vehicle-car-24-filled';
export const SAVE_ICON = 'material-symbols:save';
export const TASK_ICON = "clarity:tasks-solid";
export const QUESTION_ICON = "mdi:message-question";
export const RECIPE_ICON = "game-icons:cook";
export const ONSITE_ICON = "fluent:vehicle-car-24-filled";

export const ADD_CONTACT_ICON = "mdi:user-add";
export const ADD_LOCATION_ICON = "ic:round-add-location";


export const DELETE_ICON = "material-symbols:delete";
export const EDIT_ICON = "flowbite:edit-solid";

export const DEFAULT_CONTRACT_ICON = "ri:contract-fill";
export const DEFAULT_CONTRACT_ICON_COLOR="#2e7d32";

export const FORM_ICON="clarity:form-line";
export const FORM_ICON_DEFAULT_COLOR = "#4A90E2"; // Blue, often associated with forms and data entry

export const EMAIL_ICON="material-symbols:mark-email-unread";
export const EMAIL_ICON_WITH_ATTACHMENTS="material-symbols:attach-email";
export const EMAIL_ICON_DEFAULT_COLOR = "#4285F4";



export const NOTE_ICON="ph:note-fill";
export const NOTE_ICON_DEFAULT_COLOR = "#FFC107"; // Amber, reminiscent of sticky notes

export const TIME_ICON="mingcute:time-fill";
export const TIME_ICON_DEFAULT_COLOR = "#E53935"; // Red, often used in clock or time-related icons


export const PHONE_ICON="fa:phone";
export const PHONE_ICON_DEFAULT_COLOR = "#1aabb0"; // Red, often used in clock or time-related icons

export const MENU_ICON="iconamoon:menu-kebab-vertical-circle-fill";
export const MERGE_TICKETS_ICON="bi:sign-merge-right-fill";

export const PRINT_ICON="dashicons:printer"

export const HOURLY_BILLING_ICON="simple-icons:cashapp"; // ion:thumbs-up-sharp
export const MANAGED_BILLING_ICON="material-symbols:contract"; //ion:thumbs-down-sharp

export const CONFIG_ICON="fluent-mdl2:configuration-solid";

export const LINK_ICON="fa-solid:link";

export const COPY_ICON="mdi:content-copy";

export const CHECK_ICON="material-symbols:check-box-sharp";
export const UNCHECK_ICON="icomoon-free:checkbox-unchecked";