import { useParams } from "react-router-dom";
import { FunctionComponent, useEffect, useState } from "react";
import { Paper, Chip, Box, Typography, IconButton, Tooltip, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Grid } from "@mui/material";
import { Icon } from "@iconify/react";
import { StyledTable } from "../../../../react-submodules/components/Table/Table";
import { useApi } from "../../../../react-submodules/providers/Api";
import { CoreContact, CreateNewContactDTO } from "../../../../coretypes/contacts/contacts.dto";
import { ContractCell } from "./ContractCell";
import { ContractsInstances } from "../../../../coretypes/contracts/contracts.dto";
import { BulkActions } from "./BulkActions";
import { ADD_CONTACT_ICON } from "../../../../react-submodules/constances/icons";
import { NewContactButton } from "./NewContactDialog";
import { CoreLocations } from "../../../../coretypes/locations";
import { Location } from "../../../../react-submodules/components/Location/Location";
import { EditContactDialog } from "./EditContactDialog";

export const Contacts: FunctionComponent = () => {
    const { id } = useParams<{id: string}>();
    const [contacts, setContacts] = useState<CoreContact[]>([]);
    const [refreshKey, setRefreshKey] = useState(1);
    const api = useApi();
    const [contractInstances, setContractInstances] = useState<ContractsInstances[]>([]);
    const [bulkActionRows, setBulkActionRows] = useState<CoreContact[]>([]);

    const [ editConact , setEditConact ] = useState<CoreContact | null>(null);

    useEffect( () => {
        api.get<ContractsInstances[]>( `/contracts/instances/search` , { coreCompanyId : id } ).then( ( results ) => {
            setContractInstances( results.data.filter( c => [ 'EMAIL_ACCOUNT' ].includes(c.contract_unitsMeasured) ) );
        } );
    } , [api.ready , id ]);

    const refresh = () => {
        setRefreshKey(-1 * refreshKey);
    };

    useEffect(() => {
        api.get<CoreContact[]>(`/core/contacts/search`, { coreCompanyId: id }).then((results) => {
            setContacts(results.data);
        });
    }, [api.ready, id, refreshKey]);


    
    

    return (
        <><Paper>
            <EditContactDialog element={editConact} onClose={() => {setEditConact(null); refresh(); }} />
            <BulkActions
                contacts={bulkActionRows}
                onClose={() => {
                    console.log("Onclose");
                    setBulkActionRows([]);
                    refresh();
                }}
                contractInstances={contractInstances}
            />
            <StyledTable
                size="small"
                title="Contacts"
                rows={contacts}
                enableBulkActions
                indexField="id"
                onBulkAction={(selectedItems) => {
                    setBulkActionRows(selectedItems);
                }}
                columns={[
                    {
                        field: 'id',
                        flex: 1,
                        headerName: 'ID',
                        sortable: true
                    },
                    {
                        field: 'firstName',
                        flex: 1,
                        headerName: 'First Name',
                        sortable: true
                    },
                    {
                        field: 'lastName',
                        flex: 1,
                        headerName: 'Last Name',
                        sortable: true
                    },
                    {
                        field: 'email',
                        flex: 1,
                        headerName: 'Email',
                        sortable: true,
                        Element: ({ cell }: { cell: string }) => (
                            <Tooltip title={`Send email to ${cell}`}>
                                <Typography
                                    component="a"
                                    href={`mailto:${cell}`}
                                    sx={{ textDecoration: 'none', color: 'inherit' }}
                                >
                                    {cell}
                                </Typography>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'phone',
                        flex: 1,
                        headerName: 'Phone',
                        sortable: true
                    },
                    {
                        field: 'phoneExtension',
                        flex: 1,
                        headerName: 'Phone Extension',
                        sortable: true
                    },
                    {
                        field: 'alternatePhone',
                        flex: 1,
                        headerName: 'Alternate Phone',
                        sortable: true
                    },
                    {
                        field: 'mobilePhone',
                        flex: 1,
                        headerName: 'Mobile Phone',
                        sortable: true
                    },
                    {
                        field: 'contracts',
                        flex: 1,
                        headerName: 'Contracts',
                        sortable: true,
                        Element: ({ row }: { row: CoreContact }) => (
                            <ContractCell contract={contractInstances} contact={row} refresh={refresh} />
                        )
                    },
                    {
                        field: 'saas_billable',
                        flex: 1,
                        headerName: 'Saas Backup',
                        sortable: true,
                        sortRemap: (cell) => Number(cell || 0 ),
                        Element: ({ row }: { row: CoreContact }) => {
                            const isBillable : boolean = Number(row.saas_billable) === 1;
                            if( isBillable){
                                return <IconButton size="small" color="success" ><Icon icon="ic:round-check-box" /></IconButton>
                            }
                            return <></>
                        }
                    },
                    {
                        field: 'location',
                        flex: 1,
                        headerName: 'Location',
                        sortable: true,
                        sortRemap: (cell) => Number(cell || 0 ),
                        Element: ({ cell }: { row: CoreContact , cell : CoreLocations | null }) => {
                            if( cell ){
                                return <Location location={cell} />
                            }
                            return <></>
                        }
                    },
                    {
                        field: 'id',
                        flex: 1,
                        headerName: 'Actions',
                        sortable: false,
                        Element: ({ row }: { row: CoreContact }) => (
                            <Box>
                                <Tooltip title="Edit Contact">
                                    <IconButton onClick={() => setEditConact(row)}>
                                        <Icon icon="mdi:pencil" />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )
                    },
                ]}
                actions={
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <NewContactButton refresh={refresh} />
                    </Box>
                }
            />
        </Paper>
        
        </>
    );
};