import { Box, Button, DialogActions, DialogContentText, Typography } from "@mui/material";
import { DialogContent } from "@mui/material";
import { DialogTitle } from "@mui/material";
import { Dialog } from "@mui/material";
import { ActiveMessageChannel } from "./types";
import { useUpdateCoreMessageChannelMutation } from "./gql/UpdateMessageChannel.generated";
import { CoreMessageChannelResult } from "../../generated/graphql";
import { LabeledBox } from "../../react-submodules/components/LabeldBox";
import useSound from "use-sound";
import { useEffect, useRef, useState } from "react";
import { useMounted } from "../../react-submodules/hooks/useMounted";
import { Icon } from "@iconify/react/dist/iconify.js";

const TIMEOUT_TIME = 5000;

export const PrepareForTransferMessage = ({ messageChannel , refresh }: { messageChannel: ActiveMessageChannel , refresh : () => any}) => {
    const [play , { pause } ] = useSound('/alien-alert-noise.mp3' , { volume: 0.5 });
    const [ shouldPlay , setShouldPlay ] = useState(false);
    const [ muted , setMuted ] = useState(false);
    const [ msUntilPlay , setMsUntilPlay ] = useState<Number>(TIMEOUT_TIME);
    const isLoaded = useMounted()
    useEffect( () => {
        if( shouldPlay && !muted ){
            play();
        } else {
            pause();
        }
        return () => {
            pause();
        }
    } , [shouldPlay , muted ]);

    useEffect( () => {                
        const interval = setInterval( () => {
            if( !muted && isLoaded() ){
                if( Number(msUntilPlay) > 900 ){
                    setMsUntilPlay( Number(msUntilPlay) - 1000 );
                } else {
                    setMsUntilPlay( 0 );
                    setShouldPlay(true);
                    clearInterval(interval);
                }
            } else {
                clearInterval(interval);
            }
        } , 1000 );

        
        
        return () => {
            clearInterval(interval);
        }
    } , [messageChannel.id , msUntilPlay , muted , isLoaded ]);

    const [updateMessageChannel] = useUpdateCoreMessageChannelMutation({
        onCompleted: () => {
            refresh();
        }
    });
    const fromUser = messageChannel.CreatedByUser;
    return <>
    <Dialog open={true} onClose={() => {}}>
        <DialogTitle>Are you available for a transfer?</DialogTitle>
        <DialogContent>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography>{Boolean(fromUser) ? `${fromUser?.firstName} ${fromUser?.lastName} is on a call with a customer and you're` : "You're"} the next available agent. Do you have a moment to take the call?</Typography>
                
            </Box>
            <DialogContentText>
                
                <LabeledBox label={`Message From ${fromUser?.firstName} ${fromUser?.lastName}`} sx={{ width: "100%" }}><Typography>{messageChannel.message}</Typography></LabeledBox>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
        { !muted && <Button variant="contained" color="warning" onClick={() => {
                setMuted(true);
            }}>{ Number(msUntilPlay) / 1000 > 0 && <> <Icon icon="entypo:sound" /> {Number(msUntilPlay) / 1000}s </> } Mute </Button> }
            <Button variant="contained" color="error" onClick={() => {
                updateMessageChannel({
                    variables: {
                        id: messageChannel.id || 0,
                        result: CoreMessageChannelResult.Rejected
                    }
                });
            }}>Decline</Button>
            <Button variant="contained" color="success" onClick={() => {
                updateMessageChannel({
                    variables: {
                        id: messageChannel.id || 0,
                        result: CoreMessageChannelResult.Accepted
                    }
                });
            }}>Accept</Button>
        </DialogActions>
    </Dialog>
    </>;
}