import { gql, useQuery } from "@apollo/client";
import { Box,  styled, TablePagination, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { FC, useState } from "react"
import { EmailItem } from "../../TicketNoteGrid/EmailItem";
import { TimeclockItem } from "../../TicketNoteGrid/TimeclockItem";
import { FindTicketTimelinePaginatedQuery, useFindTicketTimelinePaginatedQuery } from "./FindTicketTimelinePaginated.generated";
import { PhoneCall } from "./items/PhoneCall";
import { FormItem } from "../../TicketNoteGrid/FormItem";
import { FormInstance } from "../../../../coretypes/form.dto";
import { EMAIL_ICON, EMAIL_ICON_DEFAULT_COLOR, FORM_ICON, FORM_ICON_DEFAULT_COLOR, PHONE_ICON, PHONE_ICON_DEFAULT_COLOR, TIME_ICON, TIME_ICON_DEFAULT_COLOR } from "../../../constances/icons";
import { Icon } from "@iconify/react";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    '&.Mui-selected': {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.info.dark,
        },
    },
}));


const migrateAzureEmail = ( email : any ) => {
    return {
        ...email,
        participants : email.AzureEmailParticipants,
        attachments : email.AzureEmailAttachments
    }
}

type CoreTimeclockType = NonNullable<
  NonNullable<
    FindTicketTimelinePaginatedQuery['findTicketTimelinePaginated']['data']
  >[number]['CoreTimeclock']
>;

type FormInstanceType = NonNullable<
  NonNullable<
    FindTicketTimelinePaginatedQuery['findTicketTimelinePaginated']['data']
  >[number]['FormInstance']
>;

const migrateTimeclock = ( timeclock : CoreTimeclockType ) => {
    return {
        ...timeclock,
        user_firstName : timeclock.user?.firstName || '',
        user_lastName : timeclock.user?.lastName || '',
        user_email : timeclock.user?.email || ''
    }
}

const migrateFormInstance = ( formInstance : FormInstanceType ) : FormInstance => {

    return {
        id: formInstance.id || 0,
        formId: String(formInstance.Form?.id || 0 ),
        values: formInstance.values,
        recipeInstanceId: formInstance.recipeInstanceId || 0,
        
        createdAt: formInstance.created_at || '',
        updated_at: formInstance.updated_at,
        context: formInstance.context,
        complete: formInstance.complete ? 1 : 0,
        autotaskTicketId: formInstance.autotaskTicketId || 0,
        todoId: formInstance.todoId || 0,
        recipe_epicId: '',
        recipe_nodeId: '',
        recipe_node_data: {
            title: '',
            formId: String(formInstance.Form?.id || 0 )
        },
        recipe_node_recipeId: 0,
        recipe_title: '',
        form_name: formInstance.Form?.identifier || '',
        form_identifier: formInstance.Form?.identifier || '',
        form_id: String(formInstance.Form?.id || 0 ),
        form: {
            id: String(formInstance.Form?.id || 0 ),
            name: formInstance.Form?.name || '',
            identifier: formInstance.Form?.identifier || '',
            formFields: formInstance.Form?.FormFields || [] as any[]
        }
    };
}


export const Timeline : FC<{ticketId : number}> = ({ ticketId }) => {
    const [ search , setSearch ] = useState( "" );
    const [ page , setPage ] = useState( 0 );
    const [filter, setFilter] = useState<string[]>(['email', 'form' ,'timeclock' , 'phone_call']);
    const { data } = useFindTicketTimelinePaginatedQuery({ variables: { ticketId , search , page , type: filter } });
    
    const handleFilterChange = (
        event: React.MouseEvent<HTMLElement>,
        newFilters: string[],
    ) => {
        setFilter(newFilters);
    };
    return <>
    <Box>
    <TextField label="Search" value={search} onChange={(e) => setSearch(e.target.value)} fullWidth size="small" />
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box flexGrow={1}>
            <ToggleButtonGroup
                value={filter}
                onChange={handleFilterChange}
                aria-label="note type filter"
                size="small"
        sx={{ mt: 2, mb: 2 }}
    >
        <StyledToggleButton value="email" aria-label="email">
            <Icon icon={EMAIL_ICON} color={filter.includes('email') ? 'white' : EMAIL_ICON_DEFAULT_COLOR} />
        </StyledToggleButton>
        <StyledToggleButton value="form" aria-label="form">
            <Icon icon={FORM_ICON} color={filter.includes('form') ? 'white' : FORM_ICON_DEFAULT_COLOR} />
        </StyledToggleButton>
        <StyledToggleButton value="timeclock" aria-label="timeclock">
            <Icon icon={TIME_ICON} color={filter.includes('timeclock') ? 'white' : TIME_ICON_DEFAULT_COLOR} />
        </StyledToggleButton>
        <StyledToggleButton value="phone_call" aria-label="phone call">
            <Icon icon={PHONE_ICON} color={filter.includes('phone_call') ? 'white' : PHONE_ICON_DEFAULT_COLOR} />
        </StyledToggleButton>
            </ToggleButtonGroup>
            </Box>
        <TablePagination
            component="div"
                count={data?.findTicketTimelinePaginated.totalCount || 0}
                page={page}
                onPageChange={(_, newPage) => setPage(newPage)}
                rowsPerPage={data?.findTicketTimelinePaginated.limit || 25}
                rowsPerPageOptions={[25]}
            />
            </Box>
            { data?.findTicketTimelinePaginated.data?.map( ( item  ) => {
                switch( item.type ){
                    case "email": return <EmailItem key={item.id} email={migrateAzureEmail( item.AzureEmail )} />
                    case "timeclock": return <TimeclockItem key={item.id} timeclock={migrateTimeclock( item.CoreTimeclock as any ) as any } />
                    case "phone_call": return <PhoneCall key={item.id} phoneCallId={item?.PhoneCall?.id} />
                    case "form": return <FormItem key={item.id} form={migrateFormInstance( item.FormInstance as any ) as any } />
                    default: return <>{item.type}</>

                }
            } ) }
    </Box>
    </>
}

