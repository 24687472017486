import { FC, useEffect, useMemo, useState } from "react";
import { Autocomplete, TextField, InputAdornment, CircularProgress, Button, IconButton } from "@mui/material";
import { useGetCoreContactsQuery, GetCoreContactsQuery } from "./gql/GetCoreContacts.generated";
import { AnyBadge } from "../../../components/AnyBadge";
import { BadgeAndName } from "../../../components/TicketPartials/BadgeAndName";
import debounce from 'lodash/debounce';
import { useGetCoreContactByIdQuery } from "./gql/GetCoreContactById.generated";
import { useGetCompanyCoreContactsQuery } from "./gql/GetCompanyCoreContacts.generated";
import { AddEditContactDialog } from "../../../../pages/Company/report/Contacts/AddEditContactDialog";
import { useApi } from "../../../providers/Api";
import { ADD_CONTACT_ICON } from "../../../constances/icons";
import { Icon } from "@iconify/react/dist/iconify.js";

type CoreContact = NonNullable<NonNullable<GetCoreContactsQuery['findCoreContactPaginated']['data']>[number]>;

type WithCoreContactId = {
  coreContactId?: number | null | undefined;
  coreCompanyId?: number | null | undefined;
}

const LIMIT = 20;


export const GqlContact = <T extends WithCoreContactId>({ 
  ticket,
  edit,
  onChange
}: {
  ticket: T;
  edit?: boolean;
  onChange?: (current: T | ((prev: T) => T)) => void;
}) => {
  const api = useApi();


  const [search, setSearch] = useState("");
  const [ addContactDialogOpen, setAddContactDialogOpen ] = useState(false);
  const { data, loading : loadingContacts , refetch } = useGetCoreContactsQuery({
    variables: {
      search,
      page: 0,
      limit: LIMIT
    },
    fetchPolicy: 'cache-and-network',
    skip: !edit
  });
  
  const { data: companyContacts, loading: companyContactsLoading } = useGetCompanyCoreContactsQuery({    
    variables: {
      search,
      page: 0,
      limit: LIMIT,
      coreCompanyId: ticket.coreCompanyId
    },
    fetchPolicy: 'cache-and-network',
    skip: !edit
  });
  const { data: contactData, loading: contactLoading } = useGetCoreContactByIdQuery({
    variables: {
      id: Number(ticket.coreContactId),
    },
    fetchPolicy: 'cache-and-network',
    skip: !ticket.coreContactId
  });

  // Get contacts, first the one's in the company, then fill the rest with the other contacts, making sure to remove duplicates.
  const contacts = useMemo(() => {
    const idsInCompany = [...(companyContacts?.findCoreContactPaginated?.data?.map(c => c.id) || [])];
    return [      
      ...(companyContacts?.findCoreContactPaginated?.data || []),
      ...(data?.findCoreContactPaginated?.data?.filter(c => !idsInCompany.includes(c.id)) || []).slice(0, LIMIT - (companyContacts?.findCoreContactPaginated?.data?.length || 0))
    ]
    
  }, [ JSON.stringify(data?.findCoreContactPaginated.data) , JSON.stringify(companyContacts?.findCoreContactPaginated.data) ]);
  const selectedContact = contactData?.getCoreContactById;

  const debouncedSetSearch = debounce((value: string) => {
    setSearch(value);
  }, 300);
  const coreCompanyId = ticket.coreCompanyId;
  const createNewContact = ( params : any ) => {
    api.post('/core/contacts/create', params ).then( (data ) => {
        setAddContactDialogOpen(false);
        onChange?.(current => {
          return {
            ...current,
            // @ts-ignore
            coreContactId: data.id
          }
        });
        refetch();
    } );
};
  if (!edit) {
    return selectedContact ? (
      <BadgeAndName 
        phone={selectedContact.phone || ''} 
        displayName={`${selectedContact.firstName} ${selectedContact.lastName}`} 
        colorkey={`${selectedContact.id}`} 
      />
    ) : null;
  }
  const loading = loadingContacts && contacts.length === 0 && !selectedContact;
  return <>
    <Autocomplete
      value={selectedContact || null}
      options={contacts}
      loading={ loading}
      fullWidth
      size="small"
      getOptionLabel={(option: CoreContact) => 
        `${option.firstName} ${option.lastName} (${option.email})`
      }
      onChange={(event, contact) => {
        if (onChange) {
          onChange(current => {
            let coreCompanyId = current.coreCompanyId;
            if( contact && contact.coreCompanyId !== current.coreCompanyId && contact.coreCompanyId  ) {
              coreCompanyId = contact.coreCompanyId;
            }
            return {
            ...current,
            coreContactId: contact ? contact.id : undefined,
            coreCompanyId: coreCompanyId
          }
        });
        }
      }}
      onInputChange={(event, value) => {
        debouncedSetSearch(value);
      }}
      groupBy={(option: CoreContact) => {
        return ticket.coreCompanyId === option.coreCompanyId ? 'Current Company' : 'Other Companies';
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Contact"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                {selectedContact && (
                  <AnyBadge 
                    name={`${selectedContact.firstName} ${selectedContact.lastName}`} 
                    colorkey={selectedContact.email || undefined} 
                  />
                )}
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
                { !selectedContact && coreCompanyId && <IconButton size="small" onClick={() => setAddContactDialogOpen(true)}>
                  <Icon icon={ADD_CONTACT_ICON} />
                </IconButton>}
                
              </>
            )
          }}
        />
      )}
    />
        { coreCompanyId && <AddEditContactDialog 
        open={addContactDialogOpen}
        onClose={() => setAddContactDialogOpen(false)}
        onSave={createNewContact}
        title="Create New Contact"
        onSaveText="Create"
        coreCompanyId={coreCompanyId}
        /> }
  </>
};