import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material"
import { createRef, FC, ForwardedRef, forwardRef, useMemo, useRef, useState } from "react"
import { PRINT_ICON } from "../../react-submodules/constances/icons";
import { InlineIcon } from "@iconify/react";
import { CompanyBillingReportItem } from "../../coretypes/reports/billing";
import { useApiSearchResults } from "../../hooks/useApiSearchResults";
import { StyledTable, TableColumnDef } from "../../react-submodules/components/Table";
import { DurationView } from "../../react-submodules/components/DurationView";
import { useReactToPrint } from "react-to-print";
import { styled } from "@mui/system";

const ticketColumns : TableColumnDef[] = [
    {
        field: 'ticketNumber',
        flex: 1,
        sortable: true,
        headerName: 'Ticket Number'
        
    },
    {
        field: 'title',
        flex: 1,
        sortable: true,
        headerName: 'Title'
    },
    {
        field: 'minutesWorked',
        flex: 1,
        sortable: true,
        headerName: 'Time Worked',
        Element: ( { cell , row  } : any ) => {
            return <DurationView duration={cell} durationUnit="m" />
        }
    },
    {
        field: 'cost',
        flex: 1,
        headerName: 'Cost',
        Element: ( { cell , row  } : any ) => {
            return <Typography>${ Number( cell ).toFixed( 2 ) }</Typography>
        }
    }
]
const contractColumns : TableColumnDef[] = [
    {
        field: 'name',
        headerName: 'Service'
    },
    {
        field: 'unitPrice',
        headerName: 'Unit Price',
        Element: ( { cell , row } ) => {
            if( Number(cell) < 0 ) return <Typography >(${ Math.abs( Number( cell ) ).toFixed( 2 ) })</Typography>
            return <Typography >${ Number( cell ).toFixed( 2 ) }</Typography>
        }
    },
    {
        field: 'approvedUnits',
        headerName: 'Billed Units',
        Element: ( { cell , row } ) => {
            return <Typography>{ Number( cell ).toFixed( 0 ) }</Typography>
        }
    },
    {
        field: 'totalPrice',
        headerName: 'Total',
        sortable: true,
        Element: ( { cell , row } ) => {
            if( Number(cell) < 0 ) return <Typography sx={{ fontWeight: 'bold'  }}>(${ Math.abs( Number( cell ) ).toFixed( 2 ) })</Typography>
            return <Typography sx={{ fontWeight: 'bold' }}>${ Number( cell ).toFixed( 2 ) }</Typography>
        }
    }
]
  
  const PrintContainer = styled('div')({
    '@media print': {
      marginTop: '40px',
    },
  });
  
  const PageContainer = styled('div')({
    '@media print': {
      pageBreakInside: 'avoid',
      pageBreakAfter: 'always',
    },
  });

const TableContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '@media print': {
        pageBreakInside: 'avoid',
        overflow: 'hidden',
    }
   } ) )

export const BillingPrintViewReport : FC<{
    reportItem: CompanyBillingReportItem
}> = ({ reportItem }) => {
    const { snapshot_month : month , snapshot_year : year , id: companyId , contracts : usedContracts  } = reportItem;
    const [ tickets ] = useApiSearchResults( "/reports/billing/tickets", 
        {  month , year , companyId  , billable: "Hourly" }
    );
    
    const [ contracts  ] = useApiSearchResults( 
        "/reports/billing/contracts", { companyId , month , year } );

    return <BillingPrintViewReportCore reportItem={ reportItem } contracts={ contracts } tickets={ tickets } />
}

// TODO: type these
type Contract = any;
type Ticket = any;


export const BillingPrintViewReportCore : FC<{
    reportItem: CompanyBillingReportItem
    contracts: Contract[]
    tickets: Ticket[]
}> = ({ reportItem , contracts , tickets }) => {
    const { snapshot_month : month , snapshot_year : year , id: companyId , contracts : usedContracts  } = reportItem;
   

    
        const mappedContracts = useMemo( () => {
            return contracts.filter( ( contract : any ) => contract.id in usedContracts ).map( ( contract : any ) => {
                const usedContract = usedContracts[ contract.id ];
                const unitPrice = [ null , undefined ].includes(usedContract?.unitPrice as any) ? contract.unitPrice : usedContract.unitPrice;
                return { ...contract , ...usedContract , unitPrice }
            } )
        } , [ JSON.stringify( contracts ) , JSON.stringify( usedContracts ) ] );
        return (
          <>
            <PrintContainer>
              <PageContainer>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h6">{ reportItem.name }</Typography>
                        <Typography variant="body1">Month: { month } / { year } </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} >
                    
                    <Grid item xs={6}>
                        <Typography  sx={{ textAlign: 'right' }}  variant="h6">Total: ${ reportItem.totalCost.toFixed( 2 ) }</Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <StyledTable Container={TableContainer} size="small" title="Services" rows={ mappedContracts } columns={ contractColumns } sort={ [{ field: 'totalPrice' , direction: 'desc' }] } />
                        <Box sx={{ m: 2 ,  display: 'flex', justifyContent: 'flex-end'}}>
                            <Typography variant="h6">Contracts Total: ${ mappedContracts.reduce( ( acc : number , c : any ) => acc + c.totalPrice , 0 ).toFixed( 2 ) }</Typography>
                        </Box>
                    </Grid>
                    { tickets.length > 0 && <Grid item xs={12} >
                        <StyledTable  Container={TableContainer} size="small" title="Hourly Tickets" rows={ tickets } columns={ ticketColumns }  />
                        <Box sx={{ m: 2 ,  display: 'flex', justifyContent: 'flex-end'}}>
                            <Typography variant="h6">Labor Cost: ${ reportItem.laborCost }</Typography>
                        </Box>
                    </Grid> }
                </Grid>
              </PageContainer>
            </PrintContainer>
          </>
        );
}