import { Dialog, DialogTitle, DialogContent, TextField, Grid, MenuItem, InputAdornment, DialogActions, Button, Switch, FormControlLabel } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { useApi } from "../../../react-submodules/providers/Api";
import { useParams } from "react-router-dom";
import { Contract } from "../../Contracts/types";

export const AddContractInstance: FC<{open: boolean, onClose: () => void}> = ({open, onClose}) => {
    const api = useApi();
    const { id : coreCompanyId } = useParams<{ id: string }>();
    const [contractId, setContractId] = useState('');
    const [approvedUnits, setApprovedUnits] = useState('');
    const [unitPrice, setUnitPrice] = useState('');
    const [additionalPrice, setAdditionalPrice] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [autoApproveUnits, setAutoApproveUnits] = useState(false);
    const [ contracts, setContracts ] = useState<Contract[]>([]);

    useEffect(() => {
        if (open) {
            api.get(`/contracts/search`).then((res) => {
                setContracts(res.data);
            });
            setContractId('');
            setApprovedUnits('');
            setUnitPrice('');
            setAdditionalPrice('');
            setIsActive(true);
            setAutoApproveUnits(false);
        }
    }, [open]);

    const addContractInstance = () => {
        api.post('/contracts/instances/create', {
            contractId: parseInt(contractId),
            coreCompanyId: parseInt(`${coreCompanyId}`),
            approvedUnits: parseFloat(approvedUnits),
            unitPrice: unitPrice ? parseFloat(unitPrice) : null,
            additionalPrice: additionalPrice ? parseFloat(additionalPrice) : null,
            isActive,
            autoAproveUnits: autoApproveUnits
        }).then(() => {
            onClose();
        });
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg">
            <DialogTitle>Add Contract</DialogTitle>
            <DialogContent sx={{width: '650px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{marginTop: 2}}>
                        <TextField select size="small" fullWidth label="Contract" value={contractId} onChange={(e) => setContractId(e.target.value)}>
                            {contracts.map((contract) => (
                                <MenuItem key={contract.id} value={contract.id}>{contract.name}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField size="small" fullWidth type="number" label="Approved Units" value={approvedUnits} onChange={(e) => setApprovedUnits(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            size="small" type="number" fullWidth label="Unit Price Overide" value={unitPrice} onChange={(e) => setUnitPrice(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            size="small" type="number" fullWidth label="Additional Price Override" value={additionalPrice} onChange={(e) => setAdditionalPrice(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Switch checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />}
                            label="Is Active"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Switch checked={autoApproveUnits} onChange={(e) => setAutoApproveUnits(e.target.checked)} />}
                            label="Auto Approve Unit Count Changes"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={addContractInstance}>Add</Button>
            </DialogActions>
        </Dialog>
    );
};
