import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetScheduleInstanceQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetScheduleInstanceQuery = { __typename?: 'Query', getScheduleInstanceById: { __typename?: 'ScheduleInstance', id?: number | null, title?: string | null, scheduleInstanceDay?: string | null, startDateTime?: any | null, endDateTime?: any | null, available?: boolean | null, description?: string | null, overidePlan?: boolean | null, conflictDetected?: boolean | null, schedulerLog?: string | null, lockedDateTime?: boolean | null, lockedUser?: boolean | null, CoreUser?: { __typename?: 'CoreUsers', id?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null } | null, AutotaskTickets?: { __typename?: 'AutotaskTickets', title: string } | null, SchedulePlan?: { __typename?: 'SchedulePlan', name?: string | null, description?: string | null } | null, AzureCalendar?: { __typename?: 'AzureCalendar', id?: string | null, subject?: string | null, mailbox: string, start: any, end: any, webLink?: string | null, bodyPreview: string } | null } };


export const GetScheduleInstanceDocument = gql`
    query GetScheduleInstance($id: Int!) {
  getScheduleInstanceById(id: $id) {
    id
    title
    scheduleInstanceDay
    startDateTime
    endDateTime
    available
    description
    overidePlan
    conflictDetected
    schedulerLog
    lockedDateTime
    lockedUser
    CoreUser {
      id
      email
      firstName
      lastName
    }
    AutotaskTickets {
      title
    }
    SchedulePlan {
      name
      description
    }
    AzureCalendar {
      id
      subject
      mailbox
      start
      end
      webLink
      bodyPreview
    }
  }
}
    `;

/**
 * __useGetScheduleInstanceQuery__
 *
 * To run a query within a React component, call `useGetScheduleInstanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduleInstanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduleInstanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScheduleInstanceQuery(baseOptions: Apollo.QueryHookOptions<GetScheduleInstanceQuery, GetScheduleInstanceQueryVariables> & ({ variables: GetScheduleInstanceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduleInstanceQuery, GetScheduleInstanceQueryVariables>(GetScheduleInstanceDocument, options);
      }
export function useGetScheduleInstanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduleInstanceQuery, GetScheduleInstanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduleInstanceQuery, GetScheduleInstanceQueryVariables>(GetScheduleInstanceDocument, options);
        }
export function useGetScheduleInstanceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetScheduleInstanceQuery, GetScheduleInstanceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetScheduleInstanceQuery, GetScheduleInstanceQueryVariables>(GetScheduleInstanceDocument, options);
        }
export type GetScheduleInstanceQueryHookResult = ReturnType<typeof useGetScheduleInstanceQuery>;
export type GetScheduleInstanceLazyQueryHookResult = ReturnType<typeof useGetScheduleInstanceLazyQuery>;
export type GetScheduleInstanceSuspenseQueryHookResult = ReturnType<typeof useGetScheduleInstanceSuspenseQuery>;
export type GetScheduleInstanceQueryResult = Apollo.QueryResult<GetScheduleInstanceQuery, GetScheduleInstanceQueryVariables>;