import { Box, Card, CardContent, CardHeader, MenuItem, Skeleton, TextField, Typography } from "@mui/material"
import { Handle, Position } from "reactflow"
import { useEffect, useState } from "react";
import { useNodeDataProperty, useRecipie } from "../../../../providers/Recipies.provider";


export const EventEmitterNode = ({ id , data } : {id : string , data : any  } ) => {
    
    const [ title , setTitle ] = useNodeDataProperty( id , 'title');
    const [ eventName , setEventName ] = useNodeDataProperty( id , 'eventName' , 'custom');
    const [ payload , setPayload ] = useNodeDataProperty( id , 'payload' , `{
        "somedata": "{{context_value}}"
}`);
    return <Box>
        <Card sx={{maxWidth: '600px'}}>
            <CardHeader className="drag-handle" title="Event Emitter" />
            <CardContent>
                <TextField sx={{padding: '2px'}} size="small" label="Title" value={title} onChange={(e) => {                    
                    setTitle( e.target.value );
                }} fullWidth />
                <TextField sx={{ marginTop: 2 , padding: '2px'}} size="small" label="Event Name" value={eventName} onChange={(e) => {
                    setEventName( e.target.value );
                }} fullWidth />

                <TextField multiline minRows={10} sx={{ marginTop: 2 , padding: '2px'}} size="small" label="Payload" value={payload} onChange={(e) => {
                    setPayload( e.target.value );
                }} fullWidth />
            </CardContent>
        </Card>
        <Handle
          type="target"
          position={Position.Left}
          id={`handle-target`}
        />
        <Handle
          type="source"
          position={Position.Right}
          id={`handle-source`}
        />
    </Box>
}
