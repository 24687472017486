import { Box, Typography } from "@mui/material";

import { FC } from "react";
import { InlineIcon } from "@iconify/react";
import { TDevice } from "../types/dattormm.dto";

export const LastUser : FC<{device: Pick<TDevice , 'lastLoggedInUser' | 'hostname' | 'operatingSystem'> & Record<string,any>}> = ({ device }) => {
    let icon = 'icon-park:network-tree';
    
    const parts = device.lastLoggedInUser?.split( "\\" );
    const hostname = device?.hostname;

    if( !parts || parts.length === 0 ){
        return <></>
    }
    
    if( parts[0]?.toLowerCase() === "azuread" ){
        icon = "logos:microsoft-azure";
    }
    if( (hostname?.toLowerCase().startsWith( parts[0]?.toLowerCase()) && parts[0]?.length === 15 ) || parts[0]?.toLowerCase() === hostname?.toLowerCase()  ){
        icon = "icon-park-twotone:computer";
    }
    if( parts.length === 2 ){
        return <Box sx={{}}>
            <Box sx={{margin: 'auto'}}>
                <Box sx={{ marginLeft: 2 }}><Typography sx={{fontWeight: 'bold' , fontSize: 14}} >{icon && <InlineIcon icon={icon} />} {parts[1]}</Typography></Box>
            </Box>
        </Box>;
    }
    if( parts.length === 1  && device.operatingSystem?.toLowerCase().includes("macos") ){
        return <Box sx={{}}>
            <Box sx={{margin: 'auto'}}>
                <Box sx={{ marginLeft: 2 }}><Typography sx={{fontWeight: 'bold' , fontSize: 14}} >{icon && <InlineIcon icon="wpf:mac-os" />} {parts[0]}</Typography></Box>
            </Box>
        </Box>;
    }
    if( parts.length === 1  && device.operatingSystem?.toLowerCase().includes("linux") ){
        return <Box sx={{}}>
            <Box sx={{margin: 'auto'}}>
                <Box sx={{ marginLeft: 2 }}><Typography sx={{fontWeight: 'bold' , fontSize: 14}} >{icon && <InlineIcon icon="devicon:linux" />} {parts[0]}</Typography></Box>
            </Box>
        </Box>;
    }
    return <>{device.lastLoggedInUser}</>
}