import { createContext, useContext, useEffect, useState } from "react";
import { CoreNotificationDto } from "../../coretypes/core.dto";
import { useApi } from "./Api/Api";

interface CoreNotificationProvider {
    notifications: CoreNotificationDto[];
    refresh: () => void;
}

const CoreNotificationContext = createContext<CoreNotificationProvider>(
    {
        notifications: [],
        refresh: () => {}
    }
);
export const useCoreNotifications = () => {
    return useContext(CoreNotificationContext);
}
export const CoreNotificationProvider = ({children}: {children: React.ReactNode}) => {
    const api = useApi();
    const [notifications, setNotifications] = useState<CoreNotificationDto[]>([]);
    const [ tick , setTick ] = useState(1);
    useEffect(() => {
        api.get<CoreNotificationDto[]>('/core/notifications' ).then( ({data}) => {
            setNotifications(data);
        });
    },[tick]);
    useEffect( () => {
        const intv = setInterval( () => { setTick(tick => tick * -1); } , 60 * 1000 );
        return () => {
            clearInterval(intv);
        }
    },[]);

    return <CoreNotificationContext.Provider value={{
        notifications,
        refresh: () => {
            setTick(tick => tick * -1);
        }
    }}>
        {children}
    </CoreNotificationContext.Provider>
}

