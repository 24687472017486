
import { InlineIcon } from "@iconify/react";
import {useTheme, Button, ButtonProps, ListItemIcon, ListItemText, Menu, MenuItem, Theme, Checkbox } from "@mui/material"
import { FC, useState } from "react";
import { CoreUser } from "../../../types/core";


export type MenuItemProps = {
    label: string
    onClick: () => void,
    icon?: string;
    iconColor?: string;
}

const getColor = (color: string , theme : Theme) => {
    
    switch( color ){
        case 'primary':
            return theme.palette.primary.main;
        case 'secondary':
            return theme.palette.secondary.main;
        case 'error':
            return theme.palette.error.main;
        case 'warning':
            return theme.palette.warning.main;
        case 'info':
            return theme.palette.info.main;
        case 'success':
            return theme.palette.success.main;
        case 'text':
            return theme.palette.text.primary;
        default:
            return color;
    }
}


export const TechMenu : FC<{
 users: CoreUser[];
 selectedTechs: string[];
 setSelectedTechs: (techs: string[]) => void
} & Omit<ButtonProps, 'onClick'> > = ({users, selectedTechs, setSelectedTechs}) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return <>
        <div>
      <Button
        startIcon={ <InlineIcon icon="el:user" /> }
        variant="contained"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >View Techs ({selectedTechs.length})</Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {users.map((item) => (
            <MenuItem key={item.id}>
                <Checkbox checked={selectedTechs.includes(item.email)} onChange={()=>{
                    if(selectedTechs.includes(item.email)){
                        setSelectedTechs(selectedTechs.filter((tech) => tech !== item.email));
                    }else{
                        setSelectedTechs([...selectedTechs, item.email]);
                    }
                }} />
                <ListItemText onClick={()=>{
                    if(selectedTechs.includes(item.email)){
                        setSelectedTechs(selectedTechs.filter((tech) => tech !== item.email));
                    }else{
                        setSelectedTechs([...selectedTechs, item.email]);
                    }
                }} secondary={item.email}>{item.firstName} {item.lastName}</ListItemText>
            </MenuItem>
        ))}
      </Menu>
    </div>
    </>
}

