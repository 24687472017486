import { FC, useEffect, useMemo, useState} from 'react';
import { MinimumInventory } from '../../types/inventory.dto';
import { InventoryCard } from '../InventoryCard/InventoryCard';
import { Button, DialogActions, Grid, MenuItem, Select, Typography } from '@mui/material';
import { Accessories } from './NewComputerWizard';

const CountDropdown : FC<{
    max: number
    value: number;
    onChange: ( val : number ) => any 
    readonly?: boolean
}> = ({ max , value , onChange , readonly}) => {
    const values = Array.from( { length : max + 1 } , ( e , i ) => i );
    return <Select onChange={(e )=>{ onChange( typeof e.target.value === "string" ? parseInt( e.target.value ) : e.target.value  )}} size="small" readOnly={readonly} value={value}>
        {values.map( v => <MenuItem key={v} value={v}>{v}</MenuItem> )}
    </Select>
}


export const SelectAccessories : FC<{
    onSelect?: ( id : number ) => any,
    computerId : number,
    inventory: MinimumInventory[],
    accessories: Record< Accessories , number >,
    updateAccessory: ( key : Accessories , value : number ) => any,
    next: () => any

}> = ({ inventory , computerId , accessories , updateAccessory , next }) => {
    

    const setMonitorCount = ( ct : number ) => updateAccessory( 'monitor' , ct );
    const setDockCount = ( ct : number ) => updateAccessory( 'dock' , ct );
    const setCableCount = ( ct : number ) => updateAccessory( 'cable' , ct );
    const { monitor : monitorCount , dock : dockCount , cable : cableCount  } = accessories;
    const monitor = useMemo( () => {
        const monitor= inventory.find( i => i.id === 8);
        return monitor &&  { image: 'https://s3.us-central-1.wasabisys.com/enlightenedmsp/se2722h_cfp_00030rf090_bk.png', ...monitor};
    } , [JSON.stringify( inventory)]);
    const dock = useMemo( () => {
        const dock = inventory.find( i => i.id === 9);
        return dock &&  { image: 'https://s3.us-central-1.wasabisys.com/enlightenedmsp/dock-wd22tb4-black-gallery-4.png', ...dock};

    } , [JSON.stringify( inventory)]);
    const cable = useMemo( () => {
        const cable = inventory.find( i => i.id === 3);
        return cable && { image: 'https://s3.us-central-1.wasabisys.com/enlightenedmsp/Web%20capture_28-11-2023_13492_www.cablestogo.com.jpeg', ...cable}
    } , [JSON.stringify( inventory)]);
    useEffect( () => {
        if( monitorCount > 1 ){
            setCableCount( monitorCount);
        } else { 
            setCableCount( 1 );
        }
    } , [ monitorCount]);
    return <>
    <Grid container>
        <Grid item sm={6}>
        { monitor && <InventoryCard size="medium"
        customAction={<CountDropdown max={computerId === 6 && dockCount === 0 ? 1 : 2 } value={monitorCount} onChange={( v ) => setMonitorCount( v )} />}
        inventory={monitor} /> }
    </Grid>
    { dock && computerId === 6 && <Grid item sm={6}>
      <InventoryCard size="medium" inventory={dock} 
    customAction={<CountDropdown max={1} value={dockCount} onChange={( v ) => {setDockCount( v );console.log( v ); }} />}
    /> 
</Grid>}<Grid item sm={6}>
{ cable && <InventoryCard size="medium" inventory={cable}
    customAction={<><Typography variant="h6">{cableCount}</Typography></>}
/> }
    </Grid>
    
    </Grid>
    <DialogActions><Button variant='contained' color="secondary" onClick={()=>{
        next();
    }}>Next</Button></DialogActions>
    </>
}