import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTicketViewQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetTicketViewQuery = { __typename?: 'Query', getAutotaskTicketsById: { __typename?: 'AutotaskTickets', id?: number | null, priority?: number | null, status: number, ticketNumber: string, aisummary?: string | null, description?: string | null, title: string, queueID?: number | null, companyID?: number | null, nextActionDate?: any | null, nextActionTime?: number | null, nextActionType?: string | null, contact?: { __typename?: 'AutotaskContacts', emailAddress: string, mobilePhone?: string | null, phone?: string | null, Contact?: { __typename?: 'CoreContact', firstName?: string | null, lastName?: string | null, email?: string | null } | null } | null, autotaskTicketQueue?: { __typename?: 'AutotaskTicketQueues', name: string } | null, autotaskTicketStatus?: { __typename?: 'AutotaskTicketStatuses', name: string } | null, autotaskCompany?: { __typename?: 'AutotaskCompanies', companyName: string } | null } };


export const GetTicketViewDocument = gql`
    query GetTicketView($id: Int!) {
  getAutotaskTicketsById(id: $id) {
    id
    priority
    status
    contact {
      emailAddress
      mobilePhone
      phone
      Contact {
        firstName
        lastName
        email
      }
    }
    autotaskTicketQueue {
      name
    }
    autotaskTicketStatus {
      name
    }
    autotaskCompany {
      companyName
    }
    ticketNumber
    aisummary
    description
    title
    queueID
    status
    companyID
    nextActionDate
    nextActionTime
    nextActionType
  }
}
    `;

/**
 * __useGetTicketViewQuery__
 *
 * To run a query within a React component, call `useGetTicketViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTicketViewQuery(baseOptions: Apollo.QueryHookOptions<GetTicketViewQuery, GetTicketViewQueryVariables> & ({ variables: GetTicketViewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTicketViewQuery, GetTicketViewQueryVariables>(GetTicketViewDocument, options);
      }
export function useGetTicketViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTicketViewQuery, GetTicketViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTicketViewQuery, GetTicketViewQueryVariables>(GetTicketViewDocument, options);
        }
export function useGetTicketViewSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTicketViewQuery, GetTicketViewQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTicketViewQuery, GetTicketViewQueryVariables>(GetTicketViewDocument, options);
        }
export type GetTicketViewQueryHookResult = ReturnType<typeof useGetTicketViewQuery>;
export type GetTicketViewLazyQueryHookResult = ReturnType<typeof useGetTicketViewLazyQuery>;
export type GetTicketViewSuspenseQueryHookResult = ReturnType<typeof useGetTicketViewSuspenseQuery>;
export type GetTicketViewQueryResult = Apollo.QueryResult<GetTicketViewQuery, GetTicketViewQueryVariables>;