import { FC, useEffect, useState } from "react";
import { FieldPicklist, useTicketApi } from "../../providers/TicketApiProvider";
import { MenuItem, TextField, Typography } from "@mui/material";
import { StatusToColor, StatusToIcon } from "./status.util";

import { InlineIcon } from "@iconify/react";

export const Status : FC<{ iconOnly?: boolean , edit?: boolean; status: string , onChange?: ( field : FieldPicklist ) => any}> = ({ status , edit , onChange , iconOnly }) => {
    const statuses = useTicketApi().statuses;
    const [ selectedStatus , setSelectedStatus ] = useState< FieldPicklist | null >( statuses.find( ( s ) => {
        return s.value === status || s.label === status;
    } ) || null );
    useEffect( () => {
        const newStatus = statuses.find( ( s ) => {
            return s.value === status || s.label === status;
        });
        if( newStatus ){
            setSelectedStatus( newStatus );
        }
    } , [ status , JSON.stringify( statuses )]);
    if( statuses.length === 0 ) return <></>;

    if( !edit ){
      return <Typography><InlineIcon color={StatusToColor(selectedStatus?.label || "")} icon={StatusToIcon( selectedStatus?.label || "" )} />&nbsp;{!iconOnly && (selectedStatus?.label || status)}</Typography>
  }
  
    return <TextField
    fullWidth
    size="small"
    select
    label="Status"
    value={selectedStatus?.value}
    onChange={(val)=>{
      const newStatus = statuses.find( ( s ) => {
        return s.value === val.target.value || s.label === val.target.value;
    } );
      onChange && onChange( newStatus as any );
    }}
  >
    
    {statuses.map((option) => (
      <MenuItem key={option.value} value={option.value} sx={{display: 'flex' , flexDirection: 'row'}}>
        <Typography><InlineIcon  color={StatusToColor(option.label)} icon={StatusToIcon( option.label )} />&nbsp;{option.label}</Typography> 
      </MenuItem>
    ))}
  </TextField>
}