import { Chip } from "@mui/material";
import { AzureEmailAttachment } from "../types/azure";
import { FC } from "react";
import { Icon } from "@iconify/react";


const AttachmentFileIcon : FC<{ attachment : AzureEmailAttachment}> = ({ attachment }) => {
    const fileExtention = attachment.name.split('.').pop();
    switch( fileExtention ) {
        case 'pdf':
            return <Icon icon="bxs:file-pdf" />
        case 'doc':
        case 'docx':
            return <Icon icon="ant-design:file-word-outlined" />
        case 'xls':
        case 'xlsx':
            return <Icon icon="mdi:file-excel-outline" />
        case 'csv':
            return <Icon icon="teenyicons:csv-solid" />
        default:
            return <Icon icon="gg:attachment" />
    }
}

const colorMap = {
    'pdf': { background: '#DC2626', color: '#FFFFFF' },  // Adobe PDF red
    'docx': { background: '#2B579A', color: '#FFFFFF' }, // Microsoft Word blue
    'doc': { background: '#2B579A', color: '#FFFFFF' }, // Microsoft Word blue
    'xlsx': { background: '#217346', color: '#FFFFFF' }, // Excel green (unchanged)
    'xls': { background: '#217346', color: '#FFFFFF' }, // Excel green (unchanged)
    'csv': { background: '#217346', color: '#FFFFFF' }   // Excel green (updated)
}



export const AttachmentChip : FC<{ attachment : AzureEmailAttachment}> = ({ attachment }) => {
    const colorStyle = colorMap[attachment.name.split('.').pop() as keyof typeof colorMap] || {};
    const handleAttachmentClick = (contentLocation: string, fileName: string) => {
        const link = document.createElement('a');
        link.href = contentLocation;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    return  <Chip
    icon={<AttachmentFileIcon attachment={attachment} />}
    label={attachment.name}
    variant="outlined"
    size="small"
    sx={{ pl: 1, mr: 1, mb: 1, cursor: 'pointer', ...colorStyle  }}
    onClick={() => handleAttachmentClick(attachment.contentLocation, attachment.name)}
  />
}